/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Select, Avatar, Spin } from 'antd';
import { uniqBy } from 'lodash';
/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/
import { getTranslation } from './../../../../shared';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export class ProductSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => ({
    selectedItems: [],
    selectedItem: null,
    filterInput: null,
  });

  render() {
    return <this.SelectField />;
  }

  SelectField = () => {
    const { placeholder, disabled, loading } = this.props;
    return (
      <Select
        showSearch
        allowClear
        optionFilterProp="children"
        disabled={disabled}
        value={this.getValue()}
        filterOption={this.filterOption}
        placeholder={placeholder}
        onChange={this.onChange}
        onSearch={this.onSearch}
        style={{ width: 785 }}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {this.getOptions()}
      </Select>
    );
  };

  getOptions = () => {
    const { fromWarehouseItems } = this.props;
    const { filterInput } = this.state;
    if (!fromWarehouseItems || !fromWarehouseItems.length) {
      return null;
    }
    let options = uniqBy(
      fromWarehouseItems,
      `item.fullName.${getTranslation.local()}`,
    ).filter((item) => item);

    if (!filterInput) {
      options = options.slice(0, 10);
    } else {
      options = options.filter((item) => {
        return (
          filterInput &&
          filterInput.length >= 3 &&
          (item.item.fullName[getTranslation.local()]
            .toLowerCase()
            .includes(filterInput.toLowerCase()) ||
            item.item.id ||
            (item.item.barcodes &&
              item.item.barcodes.length &&
              item.item.barcodes.includes(filterInput)))
        );
      });
    }

    return options.map((item, index) => (
      <Select.Option value={item.item.fullName.tr} key={index} rawvalue={item}>
        <this.SelectOptionItem item={item} key={index} />
      </Select.Option>
    ));
  };

  SelectOptionItem = (props) => {
    const { item } = props;
    const settings = {
      label: item.item.fullName[getTranslation.local()],
      icon: {
        src:
          (item.item.picURL && item.item.picURL[getTranslation.local()]) ||
          null,
        size: 20,
        className: 'product-icon',
      },
    };
    return (
      <span rawvalue={item}>
        <Avatar {...settings.icon} /> {settings.label}
      </span>
    );
  };

  getValue = () => {
    const { selectedItem } = this.state;
    const { fromWarehouseItems } = this.props;
    if (!selectedItem || !fromWarehouseItems || !fromWarehouseItems.length) {
      return;
    }
    return fromWarehouseItems
      .filter((item) => item.item.id === selectedItem.id)
      .map((item) => item.item.fullName.tr)[0];
  };

  filterOption = (input, option) =>
    option.props.rawvalue.item.fullName[getTranslation.local()]
      .toLowerCase()
      .indexOf(input.toLowerCase()) >= 0 ||
    option.props.rawvalue.item.id.toString().indexOf(input.toString()) >= 0 ||
    (option.props.rawvalue.item.barcodes &&
      option.props.rawvalue.item.barcodes.length &&
      option.props.rawvalue.item.barcodes.includes(input.toString()));

  onChange = (value, option) => {
    try {
      const selectedItem = option.props.rawvalue;
      this.setState({
        selectedItem,
      });
    } catch (error) {
      this.setState({
        selectedItem: null,
      });
    }
    setTimeout(() => {
      this.props.onChange(this.state.selectedItem);
      this.setState(this.stateFactory());
    });
  };

  clearField = () => {
    this.setState(this.stateFactory());
  };

  onSearch = (value, option) => {
    this.setState({
      filterInput: value,
    });
  };
}
