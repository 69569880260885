import React, { Component } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { authAction, countryAction } from './../../actions';
import { getLangKey, changeLanguage } from './../../i18n';
import { CountrySelectModal } from './../../components';
import { LocalDatabase } from './../../shared';
import { getDefaultCountryLanguage } from './../../shared/utility';
import { supportedLanguages } from '../../shared/language';
import { track } from '../../services/segment';

class DefaultHeaderDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownAccountOpen: false,
      dropdownLanguageOpen: false,
      countrySelectModalVisible: false,
      allowedLanguages: {
        en: supportedLanguages.en,
        tr: supportedLanguages.tr,
      },
    };
  }

  componentDidMount() {
    this.handleLanguageInitialization();
    this.props.filterCountryList();
  }

  handleLanguageInitialization = () => {
    const countryDefaultLanguage = getDefaultCountryLanguage();
    if (
      countryDefaultLanguage &&
      supportedLanguages[countryDefaultLanguage] &&
      !this.state.allowedLanguages[countryDefaultLanguage]
    ) {
      this.setState(
        {
          allowedLanguages: {
            ...this.state.allowedLanguages,
            [countryDefaultLanguage]:
              supportedLanguages[countryDefaultLanguage],
          },
        },
        // eslint-disable-next-line func-names
        function () {
          const currentLanguage = getLangKey();
          if (!this.state.allowedLanguages[currentLanguage]) {
            changeLanguage(countryDefaultLanguage || 'en');
          }
        },
      );
    } else {
      const currentLanguage = getLangKey();
      if (!this.state.allowedLanguages[currentLanguage]) {
        changeLanguage(countryDefaultLanguage || 'en');
      }
    }
  };

  toggleAccount = () => {
    this.setState((prevState) => ({
      dropdownAccountOpen: !prevState.dropdownAccountOpen,
    }));
  };

  toggleLanguage = () => {
    this.setState((prevState) => ({
      dropdownLanguageOpen: !prevState.dropdownLanguageOpen,
    }));
  };

  dropAccount() {
    const { t, user, logout } = this.props;
    const country = JSON.parse(localStorage.getItem('country'));

    return (
      <>
        <Dropdown
          nav
          isOpen={this.state.dropdownAccountOpen}
          toggle={this.toggleAccount}
        >
          <DropdownToggle nav>{user && user.name}</DropdownToggle>
          <DropdownMenu right>
            {country ? (
              <DropdownItem
                onClick={() => {
                  this.setState({ countrySelectModalVisible: true });
                }}
                disabled={!user.isAdmin}
              >
                <i className="fa fa-flag" />
                {country.name[getLangKey()]}
              </DropdownItem>
            ) : null}
            <DropdownItem
              onClick={() => {
                LocalDatabase.clear().finally(() => logout());
              }}
            >
              <i className="fa fa-lock" />
              {t('main.LOGOUT')}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Dropdown
          nav
          isOpen={this.state.dropdownLanguageOpen}
          toggle={this.toggleLanguage}
        >
          <DropdownToggle nav>
            <i
              className="fa fa-language"
              aria-hidden="true"
              style={{ fontSize: 20 }}
            />
          </DropdownToggle>
          <DropdownMenu right>
            {Object.keys(this.state.allowedLanguages).map((languageKey) => {
              const language = this.state.allowedLanguages[languageKey];
              return (
                <DropdownItem
                  onClick={() => {
                    changeLanguage(languageKey);
                    track('HOME_LANGUAGE_SETTING_CLICKED', languageKey);
                  }}
                  disabled={getLangKey() === languageKey}
                  key={languageKey}
                >
                  <span
                    role="img"
                    aria-label={language.text}
                    style={{ marginRight: '3px' }}
                  >
                    {language.flag}
                  </span>
                  <span>{language.text}</span>
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </>
    );
  }

  render() {
    const { account } = this.props;
    return (
      <>
        <CountrySelectModal
          list={this.props.countryList}
          visible={this.state.countrySelectModalVisible}
          handleOk={(country) => {
            localStorage.setItem('country', JSON.stringify(country));
            this.setState({ countrySelectModalVisible: false });
            LocalDatabase.clear().finally(() => window.location.reload());
          }}
          handleCancel={() => {
            this.setState({ countrySelectModalVisible: false });
          }}
        />
        {account ? this.dropAccount() : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  tokenCode: state.auth.tokenCode,
  currentWarehouseId: state.auth.warehouseId,
  countryList: state.country.list,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(authAction.logout()),
  filterCountryList: () => dispatch(countryAction.filterCountryList()),
});

const DefaultHeaderDropdownConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(DefaultHeaderDropdown));

export { DefaultHeaderDropdownConnected as DefaultHeaderDropdown };
