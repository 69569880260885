import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = Date.now();

const switcher = {
  [actionType.timerTick]: timerTick,
};

export const timerReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function timerTick(state, payload) {
  return Date.now();
}
