import React, { Component } from 'react';
import { Table, Card, Descriptions, Avatar, Tag, Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { orderAction } from './../../../actions';

// --------------------------------------------------
import './OrderDetailModal.scss';
// --------------------------------------------------
import { getTranslation } from './../../../shared';
import { Spinner } from './../../../components';
// --------------------------------------------------

class OrderDetailModal extends Component {
  prepareColumns = () => [
    {
      title: '#',
      dataIndex: 'picURL',
      key: 'picURL',
      render: (picURL) => {
        if (!picURL) {
          return null;
        }
        return (
          <img
            src={picURL}
            alt="product title"
            className="grow"
            style={{
              width: 30,
              height: 30,
              borderRadius: 50,
            }}
          />
        );
      },
    },
    {
      title: this.props.t('pages.Home.orderDetailModal.orderProduct'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: this.props.t('pages.Home.orderDetailModal.productCount'),
      dataIndex: 'count',
      key: 'count',
    },
  ];

  prepareData = () => {
    const { products } = this.props.order;
    if (!products) {
      return null;
    }
    return products.map((product) => ({
      name: product.name,
      picURL: product.picURL,
      count: product.orderCount,
      key: product.id,
    }));
  };

  prepareSplitedData = () => {
    const products = this.prepareData();
    if (!products || !products.length) {
      return null;
    }
    const middle = Math.ceil(products.length / 2);
    const firstPart = products.slice(0, middle);
    const secondPart = products.slice(middle);
    return [firstPart, secondPart];
  };

  render() {
    const { order } = this.props;
    if (!order) {
      return <Spinner />;
    }
    const isReportedAsFraud =
      order.isFraudSuspicion ||
      (this.props.reportFraudResponse &&
        this.props.reportFraudResponse.data &&
        this.props.reportFraudResponse.data.isFraudSuspicion);
    return (
      <div className="OrderDetailModal_inner">
        <Card className="order-detail-card">
          <Descriptions column={{ md: 3, sm: 2, xs: 1 }}>
            <Descriptions.Item
              label={this.props.t('pages.Home.orderDetailModal.clientName')}
            >
              {order.clientName}
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t(
                'pages.Home.orderDetailModal.deliveryAddress',
              )}
            >
              {order.deliveryAddress.address}
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t(
                'pages.Home.orderDetailModal.deliveryAddressDescription',
              )}
            >
              {order.deliveryAddress.description || '-'}
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t('pages.Home.orderDetailModal.orderStatus')}
            >
              {getTranslation.getOrderStatus(order.status)}
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t(
                'pages.Home.orderDetailModal.productLocations',
              )}
            >
              {order.productLocations && order.productLocations.length
                ? order.productLocations.map((productLocation) => (
                    <Tag key={productLocation.location} style={{ padding: 2 }}>
                      <span
                        style={{
                          background: 'transparent',
                          margin: 5,
                          padding: 2,
                          border: '1px solid gray',
                          borderRadius: '0',
                        }}
                      >
                        {productLocation.productCount}
                      </span>

                      {productLocation.locationBarcode}
                    </Tag>
                  ))
                : '-'}
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t('pages.Home.orderDetailModal.pickerName')}
            >
              <Avatar
                src={order.picker.picURL || '-'}
                size={20}
                icon="user"
                className="picker-photo_img"
              />{' '}
              {order.picker.name || ' - '}
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t('pages.Home.orderDetailModal.courierName')}
            >
              <Avatar
                src={order.courier.picURL || '-'}
                size={20}
                icon="user"
                className="courier-photo_img"
              />{' '}
              {order.courier.name || ' - '}
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t(
                'pages.Home.orderDetailModal.totalBagUsageCount',
              )}
            >
              {order.totalBagUsageCount || '-'}
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t(
                'pages.Home.orderDetailModal.basketProductCount',
              )}
            >
              {order.basketProductCount || '-'}
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t('pages.Home.orderDetailModal.clientNote')}
            >
              {order.clientNote || '-'}
            </Descriptions.Item>
          </Descriptions>
          <div className="fraud-button">
            <Button
              onClick={() => this.props.reportFraud(order.id, order.domainType)}
              loading={this.props.reportFraudResponse.loading}
              disabled={isReportedAsFraud}
              type="primary"
            >
              {isReportedAsFraud
                ? this.props.t('pages.Home.orderDetailModal.reportedAsFraud')
                : this.props.t('pages.Home.orderDetailModal.reportAsFraud')}
            </Button>
          </div>
          <this.OrderTable />
        </Card>
      </div>
    );
  }

  OrderTable = () => {
    const splitedData = this.prepareSplitedData();
    if (!splitedData) {
      return null;
    }
    return (
      <Row>
        <Col span={12} xs={24} md={12}>
          <Table
            bordered
            pagination={false}
            rowKey={(record) => record.key}
            dataSource={splitedData[0]}
            columns={this.prepareColumns()}
            size="small"
          />
        </Col>
        <Col span={12} xs={24} md={12}>
          <Table
            bordered
            pagination={false}
            rowKey={(record) => record.key}
            dataSource={splitedData[1]}
            columns={this.prepareColumns()}
            size="small"
          />
        </Col>
      </Row>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  reportFraud: (orderId, domainType) => {
    return dispatch(orderAction.reportFraud(orderId, domainType));
  },
});

const mapStateToProps = (state) => ({
  reportFraudResponse: state.order.reportFraud,
  currentWarehouseId: state.auth.currentWarehouse.id,
});

const OrderDetailModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(OrderDetailModal));

export { OrderDetailModalConnected as OrderDetailModal };
