import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';
import { identify } from '../services/segment';
import i18n from '../i18n';

export const authAction = {
  login,
  checkSession,
  logout,
  updateWarehouseStatus,
  googleLogin,
  otpVerify,
  otpResend,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function login({ username, password, recaptchaValue }) {
  return (dispatch) => {
    dispatch({ type: actionType.login_InProgress });
    return http
      .userLogin({
        username,
        password,
        googleRecaptchaResponse: recaptchaValue,
      })
      .then((response) => {
        if (!response.data.data.otp) {
          const { user, tokenCode } = response.data.data;

          const { warehouseId } = user;

          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('tokenCode', tokenCode);
          localStorage.setItem('warehouseId', warehouseId);
          identify({ traits: { ...user } });
          const config = {
            headers: {
              token: tokenCode,
            },
          };
          http.getWarehouseDetail(warehouseId, config).then((response) => {
            const currentWarehouse = response.data.data.warehouse;
            localStorage.setItem(
              'currentWarehouse',
              JSON.stringify(currentWarehouse),
            );
            localStorage.setItem(
              'country',
              JSON.stringify(currentWarehouse.country),
            );
            dispatch({
              type: actionType.login_Succeeded,
              payload: {
                user,
                tokenCode,
                currentWarehouse,
                redirectPath: `/r/${warehouseId}/dashboard/orders`,
              },
            });
          });
        } else {
          const { username, ttl, otp, maskedGSM } = response.data.data;
          return dispatch({
            type: actionType.login_Otp_Required,
            payload: {
              username,
              ttl,
              otp,
              maskedGSM,
            },
          });
        }
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.login_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.login_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function logout() {
  return (dispatch) => {
    dispatch({ type: actionType.logout_InProgress });
    http
      .userLogout()
      .then(() => {
        localStorage.clear();
        if (window.location.pathname !== '/login') {
          window.location.pathname = '/login';
        }
        dispatch({ type: actionType.logout_Succeeded });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.logout_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.logout_Failed,
          payload: error,
        });
      });
  };
}

function updateWarehouseStatus({ status }) {
  return (dispatch) => {
    dispatch({ type: actionType.updateWarehouseStatus_InProgress });
    http
      .updateWarehouseStatus({ status })
      .then((response) => {
        const { warehouse } = response.data.data;
        localStorage.setItem('currentWarehouse', JSON.stringify(warehouse));
        dispatch({
          type: actionType.updateWarehouseStatus_Succeeded,
          payload: warehouse,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.updateWarehouseStatus_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.updateWarehouseStatus_Failed,
          payload: error,
        });
      });
  };
}

function checkSession() {
  return (dispatch) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const roles = JSON.parse(localStorage.getItem('roles'));
    const tokenCode = localStorage.getItem('tokenCode');
    const warehouseId = localStorage.getItem('warehouseId');
    const currentWarehouse = JSON.parse(
      localStorage.getItem('currentWarehouse'),
    );
    const country = JSON.parse(localStorage.getItem('country'));

    if (user && tokenCode && warehouseId && currentWarehouse && country) {
      identify({ traits: { ...user } });
      dispatch({ type: actionType.login_InProgress });
      dispatch({
        type: actionType.login_Succeeded,
        payload: {
          user,
          roles,
          tokenCode,
          warehouseId,
          currentWarehouse,
          redirectPath: `/r/${warehouseId}/dashboard/orders`,
        },
      });
    } else {
      dispatch(logout());
    }
  };
}

function googleLogin({ tokenId }) {
  return (dispatch) => {
    dispatch({ type: actionType.login_InProgress });
    return http
      .userGoogleLogin({
        googleClientToken: tokenId,
      })
      .then((response) => {
        const { user, tokenCode } = response.data.data;

        const { warehouseId } = user;

        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('tokenCode', tokenCode);
        localStorage.setItem('warehouseId', warehouseId);
        const config = {
          headers: {
            token: tokenCode,
          },
        };
        http.getWarehouseDetail(warehouseId, config).then((response) => {
          const currentWarehouse = response.data.data.warehouse;
          localStorage.setItem(
            'currentWarehouse',
            JSON.stringify(currentWarehouse),
          );
          localStorage.setItem(
            'country',
            JSON.stringify(currentWarehouse.country),
          );
          dispatch({
            type: actionType.login_Succeeded,
            payload: {
              user,
              tokenCode,
              currentWarehouse,
              redirectPath: `/r/${warehouseId}/dashboard/orders`,
            },
          });
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.login_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.login_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function otpVerify({ username, otpCode }) {
  return (dispatch) => {
    dispatch({ type: actionType.otpVerify_InProgress });
    return http
      .userOtpVerify({
        username,
        otpCode,
      })
      .then((response) => {
        const { user, tokenCode } = response.data.data;

        const { warehouseId } = user;

        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('tokenCode', tokenCode);
        localStorage.setItem('warehouseId', warehouseId);
        identify({ traits: { ...user } });
        const config = {
          headers: {
            token: tokenCode,
          },
        };
        http
          .getWarehouseDetail(warehouseId, config)
          .then((response) => {
            const currentWarehouse = response.data.data.warehouse;
            localStorage.setItem(
              'currentWarehouse',
              JSON.stringify(currentWarehouse),
            );
            localStorage.setItem(
              'country',
              JSON.stringify(currentWarehouse.country),
            );
            dispatch({
              type: actionType.otpVerify_Succeeded,
              payload: {
                user,
                tokenCode,
                currentWarehouse,
                redirectPath: `/r/${warehouseId}/dashboard/orders`,
              },
            });
          })
          .catch((error) => {
            notification.error({
              duration: 4,
              message: i18n.t('main.userHasNoWarehouse'),
              description: ErrorInfo.description(error),
            });
            dispatch({
              type: actionType.otpVerify_Failed,
              payload: error,
            });
            throw error;
          });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.otpVerify_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.otpVerify_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function otpResend({ username }) {
  return (dispatch) => {
    dispatch({ type: actionType.otpResend_InProgress });
    http
      .userOtpResend({ username })
      .then((response) => {
        notification.success({
          duration: 4,
          message: i18n.t('main.resendSuccessful'),
        });
        dispatch({
          type: actionType.otpResend_Succeeded,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.otpResend_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.otpResend_Failed,
          payload: error,
        });
      });
  };
}
