import React, { Component } from 'react';
import { Select, Spin } from 'antd';
/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/
import {
  statuses,
  getTranslation,
  constants,
} from './../../../../../../../shared';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export class BsRequestStatusMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
    this.list = this.getList();
  }

  getList = () => {
    const { blockedStockRequestStatus } = statuses;
    const list = [];
    for (const key in blockedStockRequestStatus) {
      if (blockedStockRequestStatus.hasOwnProperty(key)) {
        const element = { key, value: blockedStockRequestStatus[key] };
        list.push(element);
      }
    }
    return list;
  };

  stateFactory = () => {
    return {};
  };

  componentDidMount() {}

  render() {
    return <this.SelectField />;
  }

  SelectField = () => {
    const { loading, disabled, placeholder } = this.props;
    return (
      <Select
        mode="multiple"
        showSearch
        allowClear
        optionFilterProp="children"
        disabled={disabled}
        placeholder={placeholder}
        value={this.getValue()}
        filterOption={this.filterOption}
        onChange={this.onChange}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {this.getOptions()}
      </Select>
    );
  };

  getOptions = () => {
    const options = this.list.map((item) => {
      return (
        <Select.Option
          value={getTranslation.blockedStockRequestStatus(item.key)}
          key={item.key}
          rawvalue={item}
          // eslint-disable-next-line
          disabled={item.key == constants.BLOCKED_STOCK_STATUSES.CANCELED}
        >
          {getTranslation.blockedStockRequestStatus(item.key)}
        </Select.Option>
      );
    });
    return options;
  };

  getValue = () => {
    const { value } = this.props;
    if (!value || !value.length) {
      return [];
    }
    return value.map((item) =>
      getTranslation.blockedStockRequestStatus(item.key),
    );
  };

  filterOption = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  onChange = (value, options) => {
    let selectedItems = [];
    try {
      selectedItems = options.map((o) => o.props.rawvalue);
    } catch (error) {
      selectedItems = [];
    }
    this.props.onChange(selectedItems);
  };
}
