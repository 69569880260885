import React, { Component } from 'react';
import { Upload, Button, Icon } from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class ReportUpload extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    return {};
  };

  componentDidMount() {}

  render() {
    return (
      <Upload
        onChange={this.onChange}
        accept="image/*"
        beforeUpload={() => false}
        fileList={this.props.fileList}
      >
        <Button>
          <Icon type="upload" />
          {this.props.t(
            'pages.Stock.BlockedStock.list.UploadModal.upload.file',
          )}
        </Button>
      </Upload>
    );
  }

  onChange = (info) => {
    this.props.onChange(info);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const ReportUploadTranslated = withNamespaces('translation')(ReportUpload);

export { ReportUploadTranslated as ReportUpload };
