import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Button, Card, Modal, Avatar } from 'antd';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './DisposalTab.scss';
import { ReportUpload } from './../../UploadModal';
import { DisposalTabFilter } from './DisposalTabFilter';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard } from './../../../../../../components';
import { disposalAction } from './../../../../../../actions';
import { getTranslation, constants, getDateTimeFormatString } from './../../../../../../shared';
import i18n from '../../../../../../i18n';
import { pageView } from '../../../../../../services/segment';
import { pageLoadEvents } from '../../../../../../services/events';

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class DisposalTab extends Component {
  settings = {
    main: {
      className: 'DisposalTab_main',
    },
    card: {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.DisposalTab.title',
      ),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  dateFormat = getDateTimeFormatString();

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.STOCK_RFS_DISPOSAL });
    this.getList();
  }

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      isOpenModalReportUpload: false,
      reportBulk: [],
      files: null,
      pagination,
      query,
      filter: this.getDefaultFilter(),
    };
  };

  getDefaultFilter = () => null;

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageFilter />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => <this.ReportButton />;

  ReportButton = (props) => {
    const { reportBulk, files } = this.state;
    return (
      <div>
        <Button
          block
          disabled={!reportBulk.length}
          onClick={() => {
            this.setState({ isOpenModalReportUpload: true });
          }}
        >
          {this.props.t(
            'pages.Stock.BlockedStock.list.tabs.DisposalTab.button.reportBulk',
          )}
        </Button>
        <Modal
          okButtonProps={{
            disabled: !files || !files.length,
          }}
          title={this.props.t(
            'pages.Stock.BlockedStock.list.tabs.DisposalTab.modal.reportBulk',
          )}
          visible={this.state.isOpenModalReportUpload}
          onOk={() => {
            console.log('onOk', this.state);
            this.completeReportBulk();
            this.setState(this.stateFactory());
          }}
          onCancel={() => {
            console.log('onCancel', this.state);
            this.setState(this.stateFactory());
          }}
        >
          <ReportUpload
            fileList={this.state.files}
            onChange={(info) => {
              console.log(info.file, info.fileList);
              this.setState({ files: info.fileList });
            }}
          />
        </Modal>
      </div>
    );
  };

  completeReportBulk = () => {
    const { reportBulk, files } = this.state;
    const query = this.getQuery();
    const filter = this.getFilter();
    this.props.onCompleteReportBulk(reportBulk, files, query, filter);
  };

  PageFilter = () => (
    <DisposalTabFilter
      defaultDateRange={this.defaultDateRange}
      onChange={this.setTableFilter}
      onClear={this.clearTableFilter}
    />
  );

  PageTable = (props) => (
    <div>
      <Table
        size="small"
        rowSelection={this.getRowSelection()}
        dataSource={this.prepareData(this.props)}
        columns={this.prepareColumns(this.props)}
        rowKey={(record) => record.id}
        pagination={{
          ...this.state.pagination,
          total: this.props.totalCount,
        }}
        loading={{ indicator: <Spinner />, spinning: this.props.loading }}
        onChange={this.handleTableChange}
      />
    </div>
  );

  getRowSelection = () => {
    const { reportBulk } = this.state;
    return {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          'selectedRows: ',
          selectedRows,
        );
        this.setState({ reportBulk: [...selectedRows] });
      },
      selectedRowKeys: reportBulk.map((bs) => bs.id),
    };
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      (state) => ({
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      }),
      this.getList,
    );
  };

  prepareData = (props) => {
    const { list } = this.props;
    if (!list || !list.length) {
      return [];
    }
    return list;
  };

  prepareColumns = (props) => [
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.DisposalTab.table.id',
      ),
      dataIndex: 'id',
      key: 'id',
      render: (id) => <CopyToClipboard message={id} />,
    },
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.DisposalTab.table.image',
      ),
      dataIndex: 'product.picURL',
      key: 'picURL',
      render: (picURL) => (
        <Avatar
          className="grow"
          shape="square"
          src={getTranslation.obj(picURL)}
        />
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.DisposalTab.table.product',
      ),
      dataIndex: 'product.fullName',
      key: 'fullName',
      render: (fullName) => (
        <CopyToClipboard message={getTranslation.obj(fullName)} />
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.DisposalTab.table.supplier',
      ),
      dataIndex: 'product',
      key: 'table.supplier',
      width: 200,
      render: (product) => {
        if (!product.suppliers || !product.suppliers.length) {
          return '-';
        }
        return product.suppliers.map((supplier) => (
          <CopyToClipboard
            message={getTranslation.obj(supplier.name)}
            key={supplier.id}
            style={{ margin: 2 }}
          />
        ));
      },
    },
    {
      dataIndex: 'status',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.DisposalTab.table.status',
      ),
      render: (status) => getTranslation.blockedStockStatus(status),
    },
    {
      dataIndex: 'count',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.DisposalTab.table.quantity',
      ),
    },
    {
      dataIndex: 'createdAt',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.DisposalTab.table.createdAt',
      ),
      render: (date) =>
        date
          ? moment(new Date(date))
              .local()
              .format(getDateTimeFormatString())
          : '-',
    },
  ];

  DetailButton = (props) => {
    const { CWId } = this.props;
    const { id } = props;
    return (
      <Link to={`/r/${CWId}/block-stock/detail/${id}`}>
        <Button size="small">
          {this.props.t(
            'pages.Stock.BlockedStock.list.tabs.DisposalTab.table.detail',
          )}
        </Button>
      </Link>
    );
  };

  getList = () => {
    const query = this.getQuery();
    const filter = this.getFilter();

    if (
      filter &&
      filter.createdAt &&
      filter.createdAt.startDate &&
      filter.createdAt.endDate
    ) {
      this.props.onGetDisposalTab(query, filter);
    }
  };

  getQuery = () => this.state.query;

  getFilter = () => {
    const filter = this.state.filter || {};

    if (!filter.createdAt) {
      filter.createdAt = this.defaultDateRange;
    }

    filter.warehouses = [this.props.CWId];

    const {
      BLOCKED_STOCK_STATUSES,
      BLOCKED_STOCK_CONCLUSION_TYPES,
    } = constants;
    filter.conclusionTypes = [BLOCKED_STOCK_CONCLUSION_TYPES.DISPOSAL];
    filter.statuses = [BLOCKED_STOCK_STATUSES.CONCLUDED];

    return filter;
  };

  setTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
        reportBulk: [],
      }),
      this.getList,
    );
  };

  clearTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
        reportBulk: [],
      }),
      this.getList,
    );
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.disposal.list.loading,
  list: state.disposal.list.data,
  totalCount: state.disposal.list.total,
});

const mapDispatchToProps = (dispatch) => ({
  onGetDisposalTab: (query, filter) => {
    dispatch(disposalAction.filterDisposalList(query, filter));
  },
  onCompleteReportBulk: (reportBulk, files, query, filter) => {
    const errorMessage = i18n.t(
      'pages.Stock.BlockedStock.list.tabs.DisposalTab.notification.anErrorOccurred',
    );
    dispatch(
      disposalAction.completeDisposalBulk(
        reportBulk,
        files,
        query,
        filter,
        errorMessage,
      ),
    );
  },
});

const DisposalTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(DisposalTab));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { DisposalTabConnected as DisposalTab };
