import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
  confirmLoading: false,
};

const switcher = {
  [actionType.filterDisposalList_InProgress]: filterDisposalList_InProgress,
  [actionType.filterDisposalList_Succeeded]: filterDisposalList_Succeeded,
  [actionType.filterDisposalList_Failed]: filterDisposalList_Failed,

  [actionType.completeDisposalBulk_InProgress]: completeDisposalBulk_InProgress,
  [actionType.completeDisposalBulk_Succeeded]: completeDisposalBulk_Succeeded,
  [actionType.completeDisposalBulk_Failed]: completeDisposalBulk_Failed,
};

export const disposalReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function filterDisposalList_InProgress(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function filterDisposalList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload.blockedStocks,
      error: null,
      total: payload.total,
    },
  });
}

function filterDisposalList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

function completeDisposalBulk_InProgress(state, payload) {
  return Object.assign({}, state, {
    confirmLoading: false,
  });
}

function completeDisposalBulk_Succeeded(state, payload) {
  return Object.assign({}, state, {
    confirmLoading: true,
  });
}

function completeDisposalBulk_Failed(state, payload) {
  return Object.assign({}, state, {
    confirmLoading: true,
  });
}
