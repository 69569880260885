import React, { Component } from 'react';
import { Select, Spin } from 'antd';
import { uniqBy } from 'lodash';
/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export class SupplierSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => {
    return {};
  };

  render() {
    return <this.SelectField />;
  }

  SelectField = () => {
    const { loading, disabled, placeholder } = this.props;
    return (
      <Select
        {...this.props}
        showSearch
        allowClear
        optionFilterProp="children"
        disabled={disabled}
        placeholder={placeholder}
        value={this.getValue()}
        filterOption={this.filterOption}
        onChange={this.onChange}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {this.getOptions()}
      </Select>
    );
  };

  getOptions = () => {
    const { list } = this.props;
    if (!list || !list.length) {
      return null;
    }
    const options = uniqBy(list, 'name').map((item) => {
      return (
        <Select.Option value={item.name} key={item._id} rawvalue={item}>
          {item.name}
        </Select.Option>
      );
    });
    return options;
  };

  getValue = () => {
    const { value } = this.props;
    if (!value) {
      return;
    }
    return value.name;
  };

  filterOption = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  onChange = (value, option) => {
    let selectedItem = null;
    try {
      selectedItem = option.props.rawvalue;
    } catch (error) {
      selectedItem = null;
    }
    setTimeout(() => {
      this.props.onChange(selectedItem);
    });
  };
}
