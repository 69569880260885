import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  YMaps,
  Map,
  Placemark,
  ZoomControl,
  TypeSelector,
} from 'react-yandex-maps';
import { uniqBy, isEmpty, get } from 'lodash';
import { Row, Col } from 'antd';
// --------------------------------------------------
// --------------------------------------------------
import { withNamespaces } from 'react-i18next';
import warehouseMarker from './../../../assets/markers/warehouse.png';
import courierMarker from './../../../assets/markers/courier.png';
import orderMarker from './../../../assets/markers/client.png';
import { config } from './../../../config';
import {
  Spinner,
  CourierBalloonContent,
  OrderBalloonContent,
} from './../../../components';
import { createHashMap } from './../../../shared';
import { getLangKey } from '../../../i18n';
import { pageView } from '../../../services/segment';
import { pageLoadEvents } from '../../../services/events';
// --------------------------------------------------

class YandexMap extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory(props);
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.HOME_MAP, props: { yandex: true } });
    setTimeout(() => {
      this.updatePlacemarks();
    }, 2000);
    this.updatePlacemarkInterval = setInterval(() => {
      this.updatePlacemarks();
    }, 4000);
  }

  componentWillUnmount() {
    clearInterval(this.updatePlacemarkInterval);
  }

  stateFactory = (props) => {
    const { coordinates } = props.warehouse.location;
    const newState = {
      config: {
        lang: this.getLanguageYandexMap(),
        apikey: config.YANDEX_JS_API_KEY,
      },
      options: {
        size: 'small',
        zoomDuration: 500,
      },
      defaultState: { expanded: false },
      map: {
        center: [...coordinates].reverse(),
        zoom: 10,
      },
      loading: true,
      warehousePlacemarks: [],
      courierPlacemarks: [],
      orderPlacemarks: [],
    };
    return newState;
  };

  getLanguageYandexMap = () => {
    const currentLanguage = getLangKey();
    if (currentLanguage === 'tr') {
      return 'tr_TR';
    }
    if (currentLanguage === 'en') {
      return 'en_US';
    }
    if (currentLanguage === 'nl') {
      return 'nl_NL';
    }
    return 'en_US';
  };

  setCourierBalloonContent = ({ ref, courier, courierOrder }) => {
    if (ref) {
      ref.properties.set(
        'balloonContentHeader',
        ReactDOMServer.renderToString(
          <>
            {this.props.t('pages.Home.balloonContent.courierLabel')}
            {courier.marketOrder && (
              <>
                {'/'}
                {this.props.t('pages.Home.balloonContent.orderLabel')}
              </>
            )}
          </>,
        ),
      );
      ref.properties.set(
        'balloonContent',
        ReactDOMServer.renderToString(
          <>
            <Row gutter={10}>
              {isEmpty(courierOrder) ? (
                <Col span={24}>
                  <CourierBalloonContent courier={courier} />
                </Col>
              ) : (
                <Col span={12}>
                  <CourierBalloonContent courier={courier} />
                </Col>
              )}
              {!isEmpty(courierOrder) ? (
                <Col span={12}>
                  <OrderBalloonContent order={courierOrder} />
                </Col>
              ) : null}
            </Row>
          </>,
        ),
      );
    }
  };

  setOrderBalloonContent = ({ ref, order, orderCourier }) => {
    if (ref) {
      ref.properties.set(
        'balloonContentHeader',
        ReactDOMServer.renderToString(
          <>
            {this.props.t('pages.Home.balloonContent.courierLabel')}
            <>
              {'/'}
              {this.props.t('pages.Home.balloonContent.orderLabel')}
            </>
          </>,
        ),
      );
      ref.properties.set(
        'balloonContent',
        ReactDOMServer.renderToString(
          <>
            <Row gutter={10}>
              <Col span={12}>
                {!isEmpty(orderCourier) ? (
                  <CourierBalloonContent courier={orderCourier} />
                ) : (
                  <div>
                    <h4>{this.props.t('pages.Home.balloonContent.courierNotAssigned')}</h4>
                  </div>
                )}
              </Col>
              <Col span={12}>
                <OrderBalloonContent order={order} />
              </Col>
            </Row>
          </>,
        ),
      );
    }
  };

  generateWarehouseMapMarkers = (warehouse) => {
    if (!warehouse) {
      return null;
    }
    const icon = warehouseMarker;
    const { coordinates } = warehouse.location;
    return this.generateMapMarker(
      icon,
      [...coordinates].reverse(),
      warehouse._id,
    );
  };

  generateCourierMapMarker = (options) => {
    const { courier } = options;

    return (
      <Placemark
        key={options.key}
        properties={{
          hintContent: options.hint,
        }}
        geometry={options.coordinates}
        options={{
          visible: true,
          iconLayout: 'default#image',
          iconImageHref: options.icon,
          iconImageSize: [40, 40],
          iconImageOffset: [-20, -40],
          openEmptyBalloon: true,
        }}
        modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
        instanceRef={(ref) => {
          const { ordersMap } = options;
          const courierOrderId = courier.marketOrder;
          const courierOrder = ordersMap[courierOrderId] || {};
          this.setCourierBalloonContent({ ref, courier, courierOrder });
        }}
      />
    );
  };

  generateCourierMapMarkers = (couriers, ordersMap) => {
    if (!couriers.data) {
      return null;
    }
    const icon = courierMarker;
    const courierMarkers = couriers.data.map((courier) => {
      const { coordinates } = courier.location;
      return this.generateCourierMapMarker({
        icon,
        coordinates: [...coordinates].reverse(),
        key: courier._id || courier.id,
        hint: courier.name,
        courier,
        ordersMap,
      });
    });
    return courierMarkers;
  };

  generateOrderMapMarkers = (orders, couriersMap) => {
    if (!orders.data) {
      return null;
    }
    const icon = orderMarker;
    const orderMarkers = uniqBy(orders.data, 'clientId').map((order) => {
      const { coordinates } = order.deliveryAddress.location;
      return this.generateOrderMapMarker({
        icon,
        coordinates: [...coordinates].reverse(),
        key: `${order._id} - ${order.clientId}`,
        hint: order.clientName,
        order,
        couriersMap,
      });
    });
    return orderMarkers;
  };

  generateOrderMapMarker = (options) => {
    const { order } = options;
    return (
      <Placemark
        key={options.key}
        properties={{
          hintContent: options.hint,
        }}
        geometry={options.coordinates}
        options={{
          visible: true,
          iconLayout: 'default#image',
          iconImageHref: options.icon,
          iconImageSize: [40, 40],
          iconImageOffset: [-20, -40],
          openEmptyBalloon: true,
        }}
        modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
        instanceRef={(ref) => {
          const { couriersMap } = options;
          const orderCourierId = get(order, ['courier', 'id']);
          const orderCourier = couriersMap[orderCourierId] || {};
          this.setOrderBalloonContent({ ref, order, orderCourier });
        }}
      />
    );
  };

  generateMapMarker = (options) => (
    <Placemark
      key={options.key}
      properties={{
        hintContent: options.hint,
      }}
      geometry={options.coordinates}
      options={{
        iconLayout: 'default#image',
        iconImageHref: options.icon,
        iconImageSize: [40, 40],
        iconImageOffset: [-20, -40],
      }}
      modules={['geoObject.addon.hint']}
    />
  );

  updatePlacemarks() {
    const { warehouse, couriers, orders } = this.props;
    const couriersMap = createHashMap(couriers.data || []);
    const ordersMap = createHashMap(orders.data || [], { field: 'id' });
    const warehousePlacemarks = this.generateWarehouseMapMarkers(warehouse);
    const courierPlacemarks = this.generateCourierMapMarkers(
      couriers,
      ordersMap,
    );
    const orderPlacemarks = this.generateOrderMapMarkers(orders, couriersMap);

    this.setState({
      warehousePlacemarks,
      courierPlacemarks,
      orderPlacemarks,
    });
  }

  render() {
    const {
      loading,
      warehousePlacemarks,
      courierPlacemarks,
      orderPlacemarks,
    } = this.state;
    const spinner = loading ? <Spinner /> : null;
    return (
      <div className="YandexMap_inner">
        {spinner}
        <YMaps version="2.1" query={this.state.config}>
          <Map state={this.state.map} width="100%" height="86vh">
            <TypeSelector defaultState={this.state.defaultState} />
            <ZoomControl options={this.state.options} />
            {warehousePlacemarks}
            {courierPlacemarks}
            {orderPlacemarks}
          </Map>
        </YMaps>
      </div>
    );
  }
}

const YandexMapTranslated = withNamespaces('translation')(YandexMap);

export { YandexMapTranslated as YandexMap };
