import { uniqBy } from 'lodash';
import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  productList: {
    loading: true,
    data: null,
    error: null,
  },
  productListV2: {
    loading: true,
    data: null,
    error: null,
  },
  warehouseProductList: {
    loading: false,
    data: null,
    error: null,
    total: 0,
  },
  locationList: {
    loading: true,
    data: null,
    error: null,
  },
};

const switcher = {
  [actionType.getWarehouseProductList_InProgress]: getWarehouseProductList_InProgress,
  [actionType.getWarehouseProductList_Succeeded]: getWarehouseProductList_Succeeded,
  [actionType.getWarehouseProductList_Failed]: getWarehouseProductList_Failed,
  [actionType.resetWarehouseProductList]: resetWarehouseProductList,
  //
  [actionType.filterProductList_InProgress]: filterProductList_InProgress,
  [actionType.filterProductList_Succeeded]: filterProductList_Succeeded,
  [actionType.filterProductList_Failed]: filterProductList_Failed,

  [actionType.filterProductListV2_InProgress]: filterProductListV2_InProgress,
  [actionType.filterProductListV2_Succeeded]: filterProductListV2_Succeeded,
  [actionType.filterProductListV2_Failed]: filterProductListV2_Failed,
};

export const productReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getWarehouseProductList_InProgress(state, payload) {
  return Object.assign({}, state, {
    warehouseProductList: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
    locationList: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getWarehouseProductList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    warehouseProductList: {
      loading: false,
      data: payload.products,
      error: null,
      total: payload.total,
    },
    locationList: {
      loading: false,
      data: uniqBy(payload.locations, 'id'),
      error: null,
    },
  });
}

function getWarehouseProductList_Failed(state, payload) {
  return Object.assign({}, state, {
    warehouseProductList: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
    locationList: {
      loading: false,
      data: null,
      error: null,
    },
  });
}

function resetWarehouseProductList(state, payload) {
  return Object.assign({}, state, {
    warehouseProductList: {
      loading: false,
      data: null,
      error: null,
      total: 0,
    },
    locationList: {
      loading: false,
      data: null,
      error: null,
    },
  });
}

function filterProductList_InProgress(state, payload) {
  return Object.assign({}, state, {
    productList: {
      loading: true,
      data: null,
      error: null,
    },
    locationList: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function filterProductList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    productList: {
      loading: false,
      data: payload.products,
      error: null,
    },
    locationList: {
      loading: false,
      data: null,
      error: null,
    },
  });
}

function filterProductList_Failed(state, payload) {
  return Object.assign({}, state, {
    productList: {
      loading: false,
      data: null,
      error: payload,
    },
    locationList: {
      loading: false,
      data: null,
      error: null,
    },
  });
}


function filterProductListV2_InProgress(state, payload) {
  return Object.assign({}, state, {
    productListV2: {
      loading: true,
      data: null,
      error: null,
    },
    locationList: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function filterProductListV2_Succeeded(state, payload) {
  return Object.assign({}, state, {
    productListV2: {
      loading: false,
      data: payload.products,
      error: null,
    },
    locationList: {
      loading: false,
      data: null,
      error: null,
    },
  });
}

function filterProductListV2_Failed(state, payload) {
  return Object.assign({}, state, {
    productListV2: {
      loading: false,
      data: null,
      error: payload,
    },
    locationList: {
      loading: false,
      data: null,
      error: null,
    },
  });
}
