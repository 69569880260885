import { config } from '../config';

export const constants = {
  ENV: {
    LOCAL: 'local',
    PROD: 'production',
    DEV: 'dev',
  },
  COURIER_STATUS: {
    FREE: 100,
    BUSY: 200,
    BROWSING: 300,
    RESERVED: 350,
    RESERVED_QUEUE: 375,
    VERIFYING: 400,
    REACHED_TO_WAREHOUSE: 450,
    PREPARING: 500,
    GATHERING: 600,
    ONWAY: 700,
    REACHED: 800,
    RETURNING: 900,
    CANCELED: 1000,
  },
  PICKER_STATUS: {
    FREE: 100,
    BUSY: 200,
    WORKING: 300,
    CANCELED: 1000,
  },
  ORDER_STATUS: {
    INCOMPLETE: 100,
    ABORTED: 200,
    BROWSING: 300,
    RESERVED: 350,
    WAITING_FOR_PICKER: 375,
    VERIFYING: 400,
    PREPARING: 500,
    PREPARED: 550,
    HANDOVER: 600,
    ONWAY: 700,
    REACHED: 800,
    DELIVERED: 900,
    RATED: 1000,
    CANCELED_COURIER: 1100,
    CANCELED_CLIENT: 1200,
    CANCELED_STAFF: 1300,
    CANCELED_SYSTEM: 1400,
    CANCELED_ADMIN: 1500,
  },
  INVENTORY_CHECK_TYPE: {
    LOCATION_BASED: 100,
    PRODUCT_BASED: 200,
  },
  INVENTORY_CHECK_SCOPE: {
    GENERAL: 100,
    PARTIAL: 200,
  },
  INVENTORY_CHECK_STATUS: {
    NEW: 100,
    READY: 400,
    ONGOING: 500,
    COMPLETED: 600,
    CANCELED: 700,
    TERMINATED: 800,
  },
  JOB_TASK_STATUSE: {
    READY: 400,
    ONGOING: 500,
    COMPLETED: 600,
    CANCELED: 700,
  },
  TRANSFER_STATUS: {
    CREATED: 100,
    APPROVED: 200,
    SHIPPING_PICKING_STARTED: 300,
    SHIPPING_PICKING_COMPLETED: 310,
    SHIPPED: 320,
    ARRIVED: 400,
    RECEIVING_STARTED: 500,
    COMPLETED: 600,
    DELETED: 700,
    CANCEL_REQUEST_SENT: 710,
  },
  TRANSFER_TYPE: {
    NORMAL: 100,
    CARBOY: 200,
    RESERVED_FOR_MISSING: 300,
    RESERVED_FOR_EXTRA: 400,
  },
  INTEGRATION_TYPE: {
    NORMAL: 100,
    CARBOY: 101,
    FRESH_FOOD: 102,
    COLD_FOOD: 103,
    FIXTURE: 104,
    FAST_DEPART: 105,
    CASE_PALLET: 106,
    ULTRA_FRESH: 107,
    BAKERY: 108,
    REFUND: 200,
    DISPOSAL: 300,
    PRODUCT_SAMPLING: 400,
    CONSUMABLE: 500,
    VIRTUAL_DEPART: 600,
    BLOCKED_STOCK: 700,
    EQUIPMENT: 800,
  },
  WAREHOUSE_STATUS: {
    BUSY: 100,
    OUT_OF_COURIER: 200,
    FREE: 300,
  },
  PURCHASE_ORDER_STATUS: {
    CREATED: 1,
    ORDERED: 2,
    CANCELED: 3,
    COMPLETED: 4,
    DELETED: 5,
  },
  PURCHASE_ORDER_ITEM_STATUS: {
    ACTIVE: 1,
    COMPLETED: 2,
    CANCELED: 3,
  },
  PURCHASE_ORDER_ACCEPTANCE_STATUS: {
    STARTED: 100,
    COMPLETED: 200,
    CANCELLED: 300,
  },
  PURCHASE_ORDER_CALCULATE_ITEM_PROGRESS: {
    IN_PROGRESS: 200,
    REQUESTED: 300,
  },
  PURCHASE_ORDER_GET_CALCULATED_ITEM_PROGRESS: {
    CALCULATION_REQUIRED: 100,
    COMPLETED: 400,
  },
  BLOCKED_STOCK_STATUSES: {
    CREATED: 100,
    TO_BE_TRANSFERRED: 300,
    TRANSFER_SHIPPED: 320,
    TRANSFER_COMPLETED: 350,
    CONCLUDED: 500,
    COMPLETED: 600,
    CANCELED: 700,
  },
  BLOCKED_STOCK_REASON_OPERATION_TYPES: {
    STOCK_MISSING: 71,
    EXPIRED: 72,
    DAMAGED: 73,
    BAD: 74,
    INCOMING_DAMAGED: 75,
    CUSTOMER_SATISFACTION: 76,
    CONSUMPTION: 77,
    VEFA_PROJECT: 78,
    STOCK_ORDER_MISSING: 79,
  },
  BLOCKED_STOCK_CONCLUSION_TYPES: {
    REFUND: 10,
    DISPOSAL: 20,
    TRANSFER: 30,
  },
  DIRECTION_TYPES: {
    CENTRAL_TO_WAREHOUSE: 10,
    WAREHOUSE_TO_CENTRAL: 20,
    WAREHOUSE_TO_WAREHOUSE: 30,
    CENTRAL_TO_CENTRAL: 40,
    SUPPLY_CHAIN_OPS_TO_WAREHOUSE: 60,
    SUPPLY_CHAIN_OPS_TO_SUPPLY_CHAIN_OPS: 70,
    WAREHOUSE_TO_SUPPLY_CHAIN_OPS: 80,
  },
  PRODUCT_COUNT_PER_WAYBILL: 20,
  WAREHOUSE_LOCATION_OPERATION_TYPE: {
    NONE: 10,
    PICKING: 20,
    FEEDING: 30,
    INCOMING: 40,
    INCOMING_DAMAGED: 41,
    OUTGOING: 50,
    PICKING_BASKET: 60,
    REFUND_DISPOSAL: 70,
    REFUND_DISPOSAL_STOCK_MISSING: 71,
    REFUND_DISPOSAL_EXPIRED: 72,
    REFUND_DISPOSAL_DAMAGED: 73,
    REFUND_DISPOSAL_BAD: 74,
    REFUND_DISPOSAL_INCOMING_DAMAGED: 75,
    REFUND_DISPOSAL_CUSTOMER_SATISFACTION: 76,
    REFUND_DISPOSAL_CONSUMPTION: 77,
    REFUND_DISPOSAL_VEFA_PROJECT: 78,
    FROZEN: 80,
    CONSUMPTION: 90,
    CANCEL: 100,
    EMPTY_CARBOY: 110,
    NON_AGREEMENT: 120,
    TRANSFER_PALLET: 130,
    WATER_VEHICLE: 140,
    TURKCELL_ORDER: 900,
  },
  WAREHOUSE_PURCHASE_ORDER_REJECTION_TYPES: {
    INCONSISTENT_PRODUCT: 1,
    INCONSISTENT_QUANTITY: 2,
    INCONSISTENT_WAYBILL_IMAGE: 3,
    INCONSISTENT_WAYBILL_NUMBER: 4,
    INCONSISTENT_PURCHASE_ORDER: 5,
    INCONSISTENT_LOCATION: 6,
  },
  PURCHASE_ORDER_STATUSES: {
    CREATED: 100,
    ORDERED: 200,
    RECEIVING_STARTED: 300,
    COMPLETED: 600,
    CANCELED: 700,
  },
  WAREHOUSE_PURCHASE_ORDER_STATUSES: {
    CREATED: 100,
    ORDERED: 200,
    RECEIVING_STARTED: 300,
    WAITING_FOR_APPROVAL: 400,
    REJECTED: 500,
    COMPLETED: 600,
    CANCELED: 700,
  },
  SUPPLIER_API_STATUSES: {
    NONE: 0,
    SENDING: 1,
    SENT: 2,
    ERR: 3,
  },
  LOGGER_MODEL_TYPE: {
    Transfer: 'Transfer',
    WarehousePurchaseOrder: 'WarehousePurchaseOrder',
    Order: 'Order',
    InventoryCheck: 'InventoryCheck',
    BlockedStock: 'BlockedStock',
  },
  PICKER_WORKING_TYPES: {
    PICKING_FOR_ORDER: 100,
    INVENTORY_CHECK: 200,
    TRANSFER: 300,
    PURCHASE_ORDER: 400,
  },
  DOMAIN_TYPE: {
    GETIR10: 1,
    FOOD: 2,
    MARKET: 3,
    WATER: 4,
  },
  TTL_FOR_CACHE: {
    PRODUCTS: 1000 * 60 * 10,
    WAREHOUSES: 1000 * 60 * 10,
    STORES: 1000 * 60 * 10,
    SUPPLIERS: 1000 * 60 * 10,
    BLOCKEDSTOCKREASONS: 1000 * 60 * 10,
  },
  ALLOWED_MAX_WAREHOUSE_COUNT_FOR_SML_FILTER: 5,
  ALLOWED_MAX_TIME_INTERVAL_FOR_SML_FILTER: 86400000 * 7,
  ALLOWED_MAX_TIME_INTERVAL_FOR_TRANSFER_FILTER: 86400000 * 30,
  BLOCKED_STOCK_REQUEST_STATUS: {
    CREATED: 100,
    IN_PROGRESS: 200,
    COMPLETED: 300,
    DELETED: 400,
  },
  BLOCKED_STOCK_TRANSFER_STATUS: {
    SHIPPED: 320,
    COMPLETED: 350,
  },
  KUZEYDEN_CARBOY_STOCK_PRODUCT_ID: '593e8ab7d0a2ba0004080cfb',
  WAREHOUSE_TYPE: {
    MAIN_WAREHOUSE: 1,
    REGULAR_WAREHOUSE: 2,
    VIRTUAL_WAREHOUSE: 3,
    SC_GROCER_WAREHOUSE: 4,
    FACTORY_WAREHOUSE: 5,
    SUPPLY_CHAIN_OPERATIONS: 6,
    VEHICLE_PARK: 7,
    STORE_CONVERSION: 8,
    NONAGREEMENT_WAREHOUSE: 9,
    OTHER: 10,
  },
  INVENTORY_CHECK_REASONS: {
    STOCK_CONTROL: 10,
    PURCHASE_ORDER: 20,
    TRANSFER: 30,
    STOCK_RESET: 40,
    OTHER: 50,
  },
  REMOVED_FROM_SALES_IN_ORDERS: {
    CREATED: 100,
    REMOVED_FROM_SALES: 600,
    CANCELLED: 700,
  },
  TRANSACTION_TYPES: {
    INBOX: 1,
    OUTBOX: 2,
  },
  TRANSACTION_STATUS: {
    FAILED: 500,
    FAILED_INDEFINITELY: 600,
  },
  PALLET_ACTION_TYPES: {
    PALLET_COMPLETED: 100,
    PALLET_ACCEPTANCE_CANCELED: 200,
    PALLET_CREATED: 300,
    PALLET_SHIPPED: 400,
    PALLET_ARRIVED: 500,
    TRANSFER_DEPARTED: 600,
  },
  PRODUCT_SHIPMENT_TYPE: {
    FROM_MAIN_STORE: 1,
    DIRECT_SHIPMENT: 2,
  },
  WALK_IN_ORDER_CANCEL_REASONS: {
    MISSING_ITEMS: 10,
    PHYSICALLY_MISSING_ITEMS: 20,
    NO_PAYMENT: 30,
    CUSTOMER_LEFT: 40,
    AGE_RESTRICTION_FAILED: 50,
    REFUND: 1000,
  },
  WALK_IN_ORDER_STATUS: {
    WAITING_FOR_STOCK_DECREASE: 100,
    WAITING_TO_BE_DELIVERED: 200,
    COMPLETED: 300,
    CANCELLED: 1000,
  },
  // REFER THIS IN V2 AS WELL https://getirdev.atlassian.net/jira/software/c/projects/SR/boards/101?modal=detail&selectedIssue=SR-1213
  EQUIPMENT_WAREHOUSE:
    config.ENV !== 'production'
      ? '621f820893e480af2a18a304'
      : '61a4b31ab6888a3e272bc0ca',
};
