import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Popover } from 'antd';
import { constants } from './../../shared';
import './TabPaneContentWithNumbers.scss';

const { COURIER_STATUS, PICKER_STATUS } = constants;

const TabPaneContentWithNumbers = ({ list, type, t }) => {
  if (list && list.length) {
    const notBusy = list.filter(
      (item) =>
        item.status !==
        (type === 'courier' ? COURIER_STATUS.BUSY : PICKER_STATUS.BUSY),
    );
    return (
      <div className="TabPaneContentWithNumbers">
        <Popover
          content={`
            ${t('common.onShift')}
            /
            ${t('pages.Operation.StockMovementLog.detail.productStock.total')}`}
        >
          <span>{`${notBusy.length}/${list.length}`}</span>
        </Popover>
        <div>
          {type === 'courier'
            ? t(`pages.Home.sidePanel.courier`)
            : t(`pages.Home.sidePanel.picker`)}
        </div>
      </div>
    );
  }
  return (
    <div>
      {type === 'courier'
        ? t(`pages.Home.sidePanel.courier`)
        : t(`pages.Home.sidePanel.picker`)}
    </div>
  );
};

const TabPaneContentWithNumbersConnected = withNamespaces('translation')(
  TabPaneContentWithNumbers,
);

export { TabPaneContentWithNumbersConnected as TabPaneContentWithNumbers };
