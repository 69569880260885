import React from 'react';
import { Tag } from 'antd';
import { withNamespaces } from 'react-i18next';

const YesNoTag = (props) => {
  return props.flag ? (
    <Tag color="blue">{props.t('components.YesNoTag.yes')}</Tag>
  ) : (
    <Tag color="red">{props.t('components.YesNoTag.no')}</Tag>
  );
};

const YesNoTagTranslated = withNamespaces('translation')(YesNoTag);

export { YesNoTagTranslated as YesNoTag };
