import axios from 'axios';
import { supportedLanguages } from '../shared';
import { config } from './../config';
import { getLangKey } from './../i18n';

export const createHttpService = (path) => {
  const langCode = getLangKey();
  const countryLocalStorage = localStorage.getItem('country');
  const country = countryLocalStorage ? JSON.parse(countryLocalStorage) : null;
  const httpService = axios.create({
    baseURL: path ? `${config.BACKEND_URI}${path}` : config.BACKEND_URI,
    headers: {
      token: localStorage.getItem('tokenCode'),
      language: supportedLanguages[langCode] ? langCode : 'en',
      countryCode: country && country.code ? country.code.alpha2 : null,
    },
  });

  httpService.interceptors.request.use(
    (config) => {
      if (!config.headers.countryCode && !config.ignoreHeaders) {
        return Promise.reject();
      }
      const langCode = getLangKey();
      if (langCode) {
        config.headers.language = supportedLanguages[langCode] ? langCode : 'en';
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return httpService;
};
