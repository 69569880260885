import { edi } from './endpoints/edi';
import { user } from './endpoints/user';
import { order } from './endpoints/order';
import { refund } from './endpoints/refund';
import { picker } from './endpoints/picker';
import { dincer } from './endpoints/dincer';
import { waybill } from './endpoints/waybill';
import { courier } from './endpoints/courier';
import { product } from './endpoints/product';
import { disposal } from './endpoints/disposal';
import { supplier } from './endpoints/supplier';
import { transfer } from './endpoints/transfer';
import { warehouse } from './endpoints/warehouse';
import { blockedStock } from './endpoints/blocked-stock';
import { purchaseOrder } from './endpoints/purchase-order';
import { dincerConsumer } from './endpoints/dincer-consumer';
import { inventoryCheck } from './endpoints/inventory-check';
import { incomingTransfer } from './endpoints/incoming-transfer';
import { outgoingTransfer } from './endpoints/outgoing-transfer';
import { stockMovementLog } from './endpoints/stock-movement-log';
import { warehouseLocation } from './endpoints/warehouse-location';
import { heatingEstimation } from './endpoints/heating-estimation';
import { country } from './endpoints/country';
import { productionModule } from './endpoints/productionModule';
import { city } from './endpoints/city';
import { transactions } from './endpoints/transactions';
import { productsV2 } from './endpoints/v2/products';

// prettier-ignore
export const http = {
  ...edi,
  ...user,
  ...order,
  ...refund,
  ...picker,
  ...dincer,
  ...waybill,
  ...courier,
  ...product,
  ...disposal,
  ...supplier,
  ...transfer,
  ...warehouse,
  ...blockedStock,
  ...purchaseOrder,
  ...dincerConsumer,
  ...inventoryCheck,
  ...incomingTransfer,
  ...outgoingTransfer,
  ...stockMovementLog,
  ...warehouseLocation,
  ...heatingEstimation,
  ...country,
  ...city,
  ...productionModule,
  ...transactions,
  ...productsV2,
};
