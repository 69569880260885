import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  heatingEstimationList: {
    loading: true,
    data: null,
    error: null,
  },
};

const switcher = {
  [actionType.getHeatingEstimationList_InProgress]: getHeatingEstimationList_InProgress,
  [actionType.getHeatingEstimationList_Succeeded]: getHeatingEstimationList_Succeeded,
  [actionType.getHeatingEstimationList_Failed]: getHeatingEstimationList_Failed,
};

export const heatingEstimationReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getHeatingEstimationList_InProgress(state, payload) {
  return Object.assign({}, state, {
    heatingEstimationList: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getHeatingEstimationList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    heatingEstimationList: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getHeatingEstimationList_Failed(state, payload) {
  return Object.assign({}, state, {
    heatingEstimationList: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}
