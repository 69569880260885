import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Table,
  Button,
  Card,
  Modal,
  Icon,
  Avatar,
  Form,
  // notification,
} from 'antd';
import moment from 'moment';
import './RefundTab.scss';
import { SupplierSelect, SupplierAccountSelect } from './filterFields';
import { WaybillUpload } from './../../UploadModal';
import { RefundTabFilter } from './RefundTabFilter';
/*----------------------------------------------------------------------------*/
import {
  Spinner,
  PrintButton,
  CopyToClipboard,
} from './../../../../../../components';
import { refundAction, supplierAction } from './../../../../../../actions';
import { getTranslation, constants, getDateTimeFormatString } from './../../../../../../shared';
import i18n from './../../../../../../i18n';
import { pageLoadEvents } from '../../../../../../services/events';
import { pageView } from '../../../../../../services/segment';

class RefundTab extends Component {
  settings = {
    main: {
      className: 'RefundTab_main',
    },
    card: {
      title: this.props.t('pages.Stock.BlockedStock.list.tabs.RefundTab.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  formItemLayout = {
    style: {
      marginTop: 15,
      marginBottom: 15,
    },
    labelCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 5, offset: 0 },
    },
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 19, offset: 0 },
    },
  };

  dateFormat = getDateTimeFormatString();

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.STOCK_RFS_RETURN });
    this.getList();
    this.props.onGetSupplierList();
  }

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 8760 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      isOpenModalRefundBulk: false,
      isModalRefundBulkSaveClicked: false,
      supplier: null,
      supplierAccount: null,
      refundBulk: [],
      waybills: [],
      printed: false,
      pagination,
      query,
      filter: this.getDefaultFilter(),
    };
  };

  getDefaultFilter = () => null;


  onRefundClick = () => {
    const { refundBulk, supplier, supplierAccount } = this.state;

    const payload = {
      blockedStockIds: refundBulk.map((refund) => refund.id),
      // waybills: uploadedFiles,
      supplier: supplier._id,
      accountCode: supplierAccount.code,
    };

    const redirectUrl = `/r/${this.props.CWId}/stock/blocked-stock/refund/detail`;
    this.props.createRefund(payload, redirectUrl);
  }

  RefundButton = (props) => {
    const { refundBulk } = this.state;
    console.log("refundBulk", refundBulk);
    return (
      <div>
        <Button
          block
          disabled={
            !refundBulk.length
            || !(this.state.supplier && this.state.supplier._id)
            || !(this.state.supplierAccount && this.state.supplierAccount.code)
          }
          onClick={this.onRefundClick}
        >
          {this.props.t(
            'pages.Stock.BlockedStock.list.tabs.RefundTab.button.enterWaybill',
          )}
        </Button>
        <Modal
          width={734}
          okButtonProps={{}}
          title={this.props.t(
            'pages.Stock.BlockedStock.list.tabs.RefundTab.modal.enterWaybill',
          )}
          visible={this.state.isOpenModalRefundBulk}
          footer={[
            <PrintButton
              key="print"
              changeParentState={() => {
                console.log('onPrint', this.state);
                this.setState({ printed: true });
              }}
              items={this.getPrintData()}
              settings={{
                label: (
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Icon type="printer" style={{ marginRight: 5 }} />
                    {this.props.t(
                      'pages.Stock.BlockedStock.list.tabs.RefundTab.button.printWaybill',
                    )}
                  </span>
                ),
              }}
            />,
            <Button
              key="cancel"
              onClick={() => {
                console.log('onCancel', this.state);
                this.setState((state) => ({
                  ...state,
                  isOpenModalRefundBulk: false,
                  isModalRefundBulkSaveClicked: false,
                  refundBulk: [],
                  waybills: [],
                  printed: false,
                }));
              }}
            >
              {this.props.t(
                'pages.Stock.BlockedStock.list.tabs.RefundTab.button.cancel',
              )}
            </Button>,
            <Button
              key="save"
              disabled={
                !this.state.waybills.length ||
                !this.state.printed ||
                this.state.isModalRefundBulkSaveClicked
              }
              type="primary"
              onClick={() => {
                console.log('onOk', this.state);
                this.setState({ isModalRefundBulkSaveClicked: true });
                this.completeRefundBulk()
                  .then(() => {
                    this.setState((state) => ({
                      ...this.stateFactory(),
                      filter: state.filter,
                    }));
                  })
                  .catch(() => {
                    this.setState((state) => ({
                      ...this.stateFactory(),
                      filter: state.filter,
                    }));
                  });
              }}
            >
              {this.props.t(
                'pages.Stock.BlockedStock.list.tabs.RefundTab.button.save',
              )}
            </Button>,
          ]}
          onCancel={() => {
            console.log('onCancel', this.state);
            this.setState((state) => ({
              ...state,
              isOpenModalRefundBulk: false,
              isModalRefundBulkSaveClicked: false,
              refundBulk: [],
              waybills: [],
              printed: false,
            }));
          }}
        >
          <Form {...this.formItemLayout}>
            <this.SupplierField />
            <this.SupplierAccountField />
          </Form>

          <WaybillUpload
            waybills={this.state.waybills}
            onChange={(waybills) => {
              this.setState({ waybills });
            }}
          />
        </Modal>
      </div>
    );
  };

  getPrintData = () => {
    const { refundBulk } = this.state;
    const printData = refundBulk.map((refund) => ({
      fromWarehouse: refund.warehouse.name,
      barcode: refund.product.barcodes[0],
      name: getTranslation.obj(refund.product.fullName),
      count: refund.count,
    }));
    return printData;
  };

  SupplierField = (props) => {
    const { supplier } = this.state;
    const { supplierList } = this.props;
    return (
      <Form.Item
        label={this.props.t(
          'pages.Stock.BlockedStock.list.tabs.RefundTab.modal.supplier',
        )}
      >
        <SupplierSelect
          onChange={(supplier) => {
            this.setState({ supplier });
            this.setState({ supplierAccount: null });
          }}
          value={supplier}
          placeholder={this.props.t(
            'pages.Stock.BlockedStock.list.tabs.RefundTab.modal.supplier',
          )}
          list={supplierList}
          disabled
        />
      </Form.Item>
    );
  };

  SupplierAccountField = (props) => {
    const { supplier, supplierAccount } = this.state;
    return (
      <Form.Item
        label={this.props.t(
          'pages.Stock.BlockedStock.list.tabs.RefundTab.modal.account',
        )}
      >
        <SupplierAccountSelect
          onChange={(supplierAccount) => {
            this.setState({ supplierAccount });
          }}
          value={supplierAccount}
          placeholder={this.props.t(
            'pages.Stock.BlockedStock.list.tabs.RefundTab.modal.account',
          )}
          list={supplier && supplier.accounts}
          disabled={
            !supplier || !supplier.accounts || !supplier.accounts.length
          }
        />
      </Form.Item>
    );
  };

  completeRefundBulk = () => {
    const { waybills, refundBulk, supplier, supplierAccount } = this.state;
    const query = this.getQuery();
    const filter = this.getFilter();

    return this.props.onCompleteRefundBulk(
      refundBulk,
      waybills,
      supplier,
      supplierAccount,
      query,
      filter,
    );
  };


  getRowSelection = () => {
    const { refundBulk } = this.state;
    return {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          'selectedRows: ',
          selectedRows,
        );
        this.setState({ refundBulk: [...selectedRows] });
      },
      selectedRowKeys: refundBulk.map((bs) => bs.id),
    };
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      (state) => ({
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      }),
      this.getList,
    );
  };

  prepareData = (props) => {
    const { list } = this.props;
    if (!list || !list.length) {
      return [];
    }
    return list;
  };

  prepareColumns = (props) => [
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.RefundTab.table.id',
      ),
      dataIndex: 'id',
      key: 'id',
      render: (id) => <CopyToClipboard message={id} />,
    },
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.RefundTab.table.image',
      ),
      dataIndex: 'product.picURL',
      key: 'picURL',
      render: (picURL) => (
        <Avatar
          className="grow"
          shape="square"
          src={getTranslation.obj(picURL)}
        />
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.RefundTab.table.product',
      ),
      dataIndex: 'product.fullName',
      key: 'fullName',
      render: (fullName) => (
        <CopyToClipboard message={getTranslation.obj(fullName)} />
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.RefundTab.table.supplier',
      ),
      dataIndex: 'supplier',
      key: 'supplier',
      width: 200,
      render: (supplier) => {
        if (!supplier || !supplier.name) {
          return '-';
        }
        return (
          <CopyToClipboard
            message={getTranslation.obj(supplier.name)}
            style={{ margin: 2 }}
          />
        );
      },
    },
    {
      dataIndex: 'count',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.RefundTab.table.quantity',
      ),
    },
    {
      dataIndex: 'createdAt',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.RefundTab.table.createdAt',
      ),
      render: (date) =>
        date
          ? moment(new Date(date))
            .local()
            .format(getDateTimeFormatString())
          : '-',
    },
  ];

  DetailButton = (props) => {
    const { CWId } = this.props;
    const { id } = props;
    return (
      <Link to={`/r/${CWId}/block-stock/detail/${id}`}>
        <Button size="small">
          {this.props.t(
            'pages.Stock.BlockedStock.list.tabs.RefundTab.table.detail',
          )}
        </Button>
      </Link>
    );
  };

  getList = () => {
    const query = this.getQuery();
    const filter = this.getFilter();

    if (
      filter &&
      filter.createdAt &&
      filter.createdAt.startDate &&
      filter.createdAt.endDate
    ) {
      this.props.onGetRefundTab(query, filter);
    }
  };

  getQuery = () => this.state.query;

  getFilter = () => {
    const filter = this.state.filter || {};

    delete filter.account;
    if (!filter.createdAt) {
      filter.createdAt = this.defaultDateRange;
    }

    filter.warehouses = [this.props.CWId];

    const {
      BLOCKED_STOCK_STATUSES,
      BLOCKED_STOCK_CONCLUSION_TYPES,
    } = constants;
    filter.conclusionTypes = [BLOCKED_STOCK_CONCLUSION_TYPES.REFUND];
    filter.statuses = [BLOCKED_STOCK_STATUSES.CONCLUDED];

    return filter;
  };

  setTableFilter = (filter) => {
    const supplier = this.getSupplierFromFilter(filter);
    const supplierAccount = this.getSupplierAccountFromFilter(filter);

    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
        supplier,
        supplierAccount,
        refundBulk: [],
      }),
      this.getList,
    );
  };

  clearTableFilter = (filter) => {
    const supplier = this.getSupplierFromFilter(filter);
    const supplierAccount = this.getSupplierAccountFromFilter(filter);
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
        supplier,
        supplierAccount,
        refundBulk: [],
      }),
      this.getList,
    );
  };

  getSupplierFromFilter = (filter) => {
    const { supplierList } = this.props;
    const supplierId = filter.suppliers && filter.suppliers[0];
    if (!supplierId) {
      return null;
    }
    const supplier = supplierList.find((s) => s._id === supplierId);
    if (!supplier) {
      return null;
    }
    return supplier;
  };

  getSupplierAccountFromFilter = (filter) => {
    const supplier = this.getSupplierFromFilter(filter);
    const accountCode = filter.account;
    if (!supplier || !supplier.accounts || !accountCode) {
      return null;
    }
    const account = supplier.accounts.find((acc) => acc.code === accountCode);
    if (!account) {
      return null;
    }
    return account;
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.RefundButton />}>
          <RefundTabFilter
            defaultDateRange={this.defaultDateRange}
            onChange={this.setTableFilter}
            onClear={this.clearTableFilter}
          />
          <Table
            size="small"
            dataSource={this.prepareData(this.props)}
            rowSelection={this.getRowSelection()}
            columns={this.prepareColumns(this.props)}
            rowKey={(record) => record.id}
            pagination={{
              ...this.state.pagination,
              total: this.props.totalCount,
            }}
            loading={{ indicator: <Spinner />, spinning: this.props.loading }}
            onChange={this.handleTableChange}
          />
        </Card>
      </div>
    );
  }
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.refund.list.loading,
  list: state.refund.list.data,
  supplierList: state.supplier.list.data,
  totalCount: state.refund.list.total,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSupplierList: () => {
    dispatch(supplierAction.getSupplierList());
  },
  onGetRefundTab: (query, filter) => {
    dispatch(refundAction.filterRefundList(query, filter));
  },
  onCompleteRefundBulk: (
    refundBulk,
    waybills,
    supplier,
    supplierAccount,
    query,
    filter,
  ) => {
    const errorMessage = i18n.t(
      'pages.Stock.BlockedStock.list.tabs.RefundTab.notification.anErrorOccurred',
    );
    return dispatch(
      refundAction.completeRefundBulk(
        refundBulk,
        waybills,
        supplier,
        supplierAccount,
        query,
        filter,
        errorMessage,
      ),
    );
  },
  createRefund: (refundId, payload) => dispatch(refundAction.createRefund(refundId, payload)),
});

const RefundTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(RefundTab));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { RefundTabConnected as RefundTab };
