import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Button, Card } from 'antd';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './PurchaseOrderList.scss';
import { PurchaseOrderFilter } from './PurchaseOrderFilter';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard } from './../../../../components';
import { getTranslation, getDateTimeFormatString } from './../../../../shared';
import { warehousePurchaseOrderAction } from './../../../../actions';
import { pageView } from '../../../../services/segment';
import { pageLoadEvents } from '../../../../services/events';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class PurchaseOrderList extends Component {
  settings = {
    main: {
      className: 'TransferList_main',
    },
    card: {
      title: this.props.t('pages.Stock.PurchaseOrder.list.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.STOCK_OUTGOING_TRANSFER_LIST });
    this.getList();
  }

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      filter: this.getDefaultFilter(),
    };
  };

  getDefaultFilter = () => null;

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageFilter />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => (
    <div style={{ display: 'flex' }}>
      <Link to={`/r/${this.props.CWId}/stock/purchase-order/create`}>
        <Button type="danger" size="small">
          {this.props.t(
            'pages.Stock.PurchaseOrder.list.extra.createNewPurchaseOrder',
          )}
        </Button>
      </Link>
    </div>
  );

  PageFilter = () => (
    <PurchaseOrderFilter
      defaultDateRange={this.defaultDateRange}
      onChange={this.setTableFilter}
      onClear={this.clearTableFilter}
    />
  );

  PageTable = () => (
    <Table
      size="small"
      dataSource={this.prepareData(this.props)}
      columns={this.prepareColumns(this.props)}
      rowKey={(record) => record.id}
      pagination={{ ...this.state.pagination, total: this.props.totalCount }}
      loading={{ indicator: <Spinner />, spinning: this.props.loading }}
      onChange={this.handleTableChange}
    />
  );

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      (state) => ({
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      }),
      this.getList,
    );
  };

  prepareColumns = (props) => [
    {
      title: this.props.t(
        'pages.Stock.PurchaseOrder.list.purchaseOrderTable.date',
      ),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: this.props.t(
        'pages.Stock.PurchaseOrder.list.purchaseOrderTable.id',
      ),
      dataIndex: 'id',
      key: 'id',
      render: (id) => <CopyToClipboard message={id} />,
    },
    {
      title: this.props.t(
        'pages.Stock.PurchaseOrder.list.purchaseOrderTable.no',
      ),
      dataIndex: 'no',
      key: 'no',
      render: (no) => <CopyToClipboard message={no} />,
    },
    {
      title: this.props.t(
        'pages.Stock.PurchaseOrder.list.purchaseOrderTable.source',
      ),
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: this.props.t(
        'pages.Stock.PurchaseOrder.list.purchaseOrderTable.status',
      ),
      dataIndex: 'status',
      key: 'status',
      render: (status) => getTranslation.operationPurchaseOrderStatus(status),
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'detail',
      render: (id) => (
        <Link to={`/r/${props.CWId}/stock/purchase-order/detail/${id}`}>
          <Button size="small">
            {this.props.t(
              'pages.Stock.PurchaseOrder.list.purchaseOrderTable.detail',
            )}
          </Button>
        </Link>
      ),
    },
  ];

  prepareData = (props) => {
    const { list } = props;
    if (!list || !list.length) {
      return [];
    }
    return list.map((item) => ({
      ...item,
      table: {},
      date: moment(new Date(item.createdAt))
        .local()
        .format(getDateTimeFormatString()),
      source: item.supplier.name,
      status: item.status,
      no: item.no,
      id: item.id,
      key: item.id,
    }));
  };

  getList = () => {
    const filter = this.getFilter();
    const query = this.getQuery();
    if (
      filter &&
      filter.createdAt &&
      filter.createdAt.startDate &&
      filter.createdAt.endDate
    ) {
      this.props.onGetPurchaseOrderList(query, filter);
    }
  };

  getFilter = () => {
    const filter = this.state.filter || {};
    if (!filter.createdAt) {
      filter.createdAt = this.defaultDateRange;
    }
    filter.warehouses = [this.props.CWId];
    return filter;
  };

  getQuery = () => {
    const { query } = this.state;
    return query;
  };

  setTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      }),
      this.getList,
    );
  };

  clearTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      }),
      this.getList,
    );
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  list: state.warehousePurchaseOrder.list.data,
  totalCount: state.warehousePurchaseOrder.list.total,
  loading: state.warehousePurchaseOrder.list.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onGetPurchaseOrderList: (query, filter) =>
    dispatch(
      warehousePurchaseOrderAction.filterWarehousePurchaseOrderList(
        query,
        filter,
      ),
    ),
});

const PurchaseOrderListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(PurchaseOrderList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { PurchaseOrderListConnected as PurchaseOrderList };
