import React, { Component } from 'react';
import { Card, Tabs } from 'antd';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import './BlockedStockList.scss';
import {
  TransferTab,
  ListTab,
  RefundTab,
  DisposalTab,
  CustomerSatisfactionTab,
} from './tabs';
import { pageView } from '../../../../services/segment';
import { pageLoadEvents } from '../../../../services/events';
/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class BlockedStockList extends Component {
  settings = {
    main: {
      className: 'BlockedStockList_main',
    },
    card: {
      title: this.props.t('pages.Stock.BlockedStock.list.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.STOCK_RFS_TRANSFER_TO_MW });
  }

  stateFactory = () => ({
    tabKey: 'list',
  });

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageLayout />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => <div />;

  PageLayout = (props) => (
    <Tabs
      defaultActiveKey="list"
      onChange={(tabKey) => {
        this.changeTab(tabKey);
      }}
    >
      <Tabs.TabPane tab={this.props.t('pages.Stock.BlockedStock.list.tab.list')} key="list">
        <this.ListTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab={this.props.t('pages.Stock.BlockedStock.list.tab.transfer')} key="transfer">
        <this.TransferTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab={this.props.t('pages.Stock.BlockedStock.list.tab.refund')} key="refund">
        <this.RefundTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab={this.props.t('pages.Stock.BlockedStock.list.tab.disposal')} key="disposal">
        <this.DisposalTab />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={this.props.t('pages.Stock.BlockedStock.list.tab.customerSatisfaction')}
        key="customerSatisfaction"
      >
        <this.CustomerSatisfactionTab />
      </Tabs.TabPane>
    </Tabs>
  );

  changeTab = (tabKey) => {
    this.setState({ tabKey });
  };

  ListTab = (props) => {
    if (this.state.tabKey === 'list') {
      return <ListTab />;
    }
    return <div />;
  };

  TransferTab = (props) => {
    if (this.state.tabKey === 'transfer') {
      return <TransferTab />;
    }
    return <div />;
  };

  RefundTab = (props) => {
    if (this.state.tabKey === 'refund') {
      return <RefundTab />;
    }
    return <div />;
  };

  DisposalTab = (props) => {
    if (this.state.tabKey === 'disposal') {
      return <DisposalTab />;
    }
    return <div />;
  };

  CustomerSatisfactionTab = (props) => {
    if (this.state.tabKey === 'customerSatisfaction') {
      return <CustomerSatisfactionTab />;
    }
    return <div />;
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
});

const mapDispatchToProps = (dispatch) => ({});

const BlockedStockListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(BlockedStockList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { BlockedStockListConnected as BlockedStockList };
