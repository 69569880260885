/* export const getSmlDescription = (description) => {
  return smlDescriptions[description];
}; */
const t = (s) => s;

export const smlDescriptions = {
  FromPickingToPicking: t('shared.smlDescriptions.FromPickingToPicking'),
  FromPickingToRefundAndDisposal: t(
    'shared.smlDescriptions.FromPickingToRefundAndDisposal',
  ),
  FromPickingToNonagreement: t(
    'shared.smlDescriptions.FromPickingToNonagreement',
  ),
  FromPickingToFeeding: t('shared.smlDescriptions.FromPickingToFeeding'),
  FromPickingToOutgoingTransferPallet: t(
    'shared.smlDescriptions.FromPickingToOutgoingTransferPallet',
  ),
  FromPickingToWaterVehicle: t(
    'shared.smlDescriptions.FromPickingToWaterVehicle',
  ),
  FromPickingToPickingBasket: t(
    'shared.smlDescriptions.FromPickingToPickingBasket',
  ),
  FromPickingToBasket: t('shared.smlDescriptions.FromPickingToBasket'),
  PickingBasketDeliver: t('shared.smlDescriptions.PickingBasketDeliver'),
  FromAcceptanceToPicking: t('shared.smlDescriptions.FromAcceptanceToPicking'),
  FromAcceptanceToRefundAndDisposal: t(
    'shared.smlDescriptions.FromAcceptanceToRefundAndDisposal',
  ),
  FromAcceptanceToFrozen: t('shared.smlDescriptions.FromAcceptanceToFrozen'),
  FromAcceptanceToOutgoingTransferPallet: t(
    'shared.smlDescriptions.FromAcceptanceToOutgoingTransferPallet',
  ),
  FromRefundAndDisposalToPicking: t(
    'shared.smlDescriptions.FromRefundAndDisposalToPicking',
  ),
  FromRefundAndDisposalToAcceptance: t(
    'shared.smlDescriptions.FromRefundAndDisposalToAcceptance',
  ),
  FromRefundAndDisposalToCancel: t(
    'shared.smlDescriptions.FromRefundAndDisposalToCancel',
  ),
  FromRefundAndDisposalToFeeding: t(
    'shared.smlDescriptions.FromRefundAndDisposalToFeeding',
  ),
  FromRefundAndDisposalToFrozen: t(
    'shared.smlDescriptions.FromRefundAndDisposalToFrozen',
  ),
  FromRefundAndDisposalToEmptyCarboy: t(
    'shared.smlDescriptions.FromRefundAndDisposalToEmptyCarboy',
  ),
  FromRefundAndDisposalToRefundAndDisposal: t(
    'shared.smlDescriptions.FromRefundAndDisposalToRefundAndDisposal',
  ),
  FromRefundAndDisposalToOutgoingTransferPallet: t(
    'shared.smlDescriptions.FromRefundAndDisposalToOutgoingTransferPallet',
  ),
  FromRefundAndDisposalToWaterVehicle: t(
    'shared.smlDescriptions.FromRefundAndDisposalToWaterVehicle',
  ),
  FromCancelToPicking: t('shared.smlDescriptions.FromCancelToPicking'),
  FromCancelToRefundAndDisposal: t(
    'shared.smlDescriptions.FromCancelToRefundAndDisposal',
  ),
  FromCancelToNonagreement: t(
    'shared.smlDescriptions.FromCancelToNonagreement',
  ),
  FromNonagreementToPicking: t(
    'shared.smlDescriptions.FromNonagreementToPicking',
  ),
  FromNonagreementToCancel: t(
    'shared.smlDescriptions.FromNonagreementToCancel',
  ),
  FromNonagreementToFrozen: t(
    'shared.smlDescriptions.FromNonagreementToFrozen',
  ),
  FromNonagreementToEmptyCarboy: t(
    'shared.smlDescriptions.FromNonagreementToEmptyCarboy',
  ),
  FromNonagreementToWaterVehicle: t(
    'shared.smlDescriptions.FromNonagreementToWaterVehicle',
  ),
  FromFeedingToPicking: t('shared.smlDescriptions.FromFeedingToPicking'),
  FromFeedingToRefundAndDisposal: t(
    'shared.smlDescriptions.FromFeedingToRefundAndDisposal',
  ),
  FromFeedingToFeeding: t('shared.smlDescriptions.FromFeedingToFeeding'),
  FromFeedingToPickingBasket: t(
    'shared.smlDescriptions.FromFeedingToPickingBasket',
  ),
  FromFeedingToOutgoingTransferPallet: t(
    'shared.smlDescriptions.FromFeedingToOutgoingTransferPallet',
  ),
  FromFeedingToBasket: t('shared.smlDescriptions.FromFeedingToBasket'),
  FromPickingBasketToCancel: t(
    'shared.smlDescriptions.FromPickingBasketToCancel',
  ),
  FromPickingBasketToWaterVehicle: t(
    'shared.smlDescriptions.FromPickingBasketToWaterVehicle',
  ),
  FromFrozenToPicking: t('shared.smlDescriptions.FromFrozenToPicking'),
  FromFrozenToRefundAndDisposal: t(
    'shared.smlDescriptions.FromFrozenToRefundAndDisposal',
  ),
  FromFrozenToNonagreement: t(
    'shared.smlDescriptions.FromFrozenToNonagreement',
  ),
  FromFrozenToFrozen: t('shared.smlDescriptions.FromFrozenToFrozen'),
  FromFrozenToOutgoingTransferPallet: t(
    'shared.smlDescriptions.FromFrozenToOutgoingTransferPallet',
  ),
  FromEmptyCarboyToRefundAndDisposal: t(
    'shared.smlDescriptions.FromEmptyCarboyToRefundAndDisposal',
  ),
  FromEmptyCarboyToNonagreement: t(
    'shared.smlDescriptions.FromEmptyCarboyToNonagreement',
  ),
  FromEmptyCarboyToOutgoingTransferPallet: t(
    'shared.smlDescriptions.FromEmptyCarboyToOutgoingTransferPallet',
  ),
  FromOutgoingTransferPalletToPicking: t(
    'shared.smlDescriptions.FromOutgoingTransferPalletToPicking',
  ),
  FromOutgoingTransferPalletToAcceptance: t(
    'shared.smlDescriptions.FromOutgoingTransferPalletToAcceptance',
  ),
  FromOutgoingTransferPalletToRefundAndDisposal: t(
    'shared.smlDescriptions.FromOutgoingTransferPalletToRefundAndDisposal',
  ),
  FromOutgoingTransferPalletToFeeding: t(
    'shared.smlDescriptions.FromOutgoingTransferPalletToFeeding',
  ),
  FromOutgoingTransferPalletToFrozen: t(
    'shared.smlDescriptions.FromOutgoingTransferPalletToFrozen',
  ),
  FromOutgoingTransferPalletToEmptyCarboy: t(
    'shared.smlDescriptions.FromOutgoingTransferPalletToEmptyCarboy',
  ),
  FromWaterVehicleToPicking: t(
    'shared.smlDescriptions.FromWaterVehicleToPicking',
  ),
  FromWaterVehicleToRefundAndDisposal: t(
    'shared.smlDescriptions.FromWaterVehicleToRefundAndDisposal',
  ),
  FromWaterVehicleToPickingBasket: t(
    'shared.smlDescriptions.FromWaterVehicleToPickingBasket',
  ),
  FromWaterVehicleToNonagreement: t(
    'shared.smlDescriptions.FromWaterVehicleToNonagreement',
  ),
  Refund: t('shared.smlDescriptions.Refund'),
  Disposal: t('shared.smlDescriptions.Disposal'),
  StockReservation: t('shared.smlDescriptions.StockReservation'),
  CancelStockReservation: t('shared.smlDescriptions.CancelStockReservation'),
  SelfPurchaseOrder: t('shared.smlDescriptions.SelfPurchaseOrder'),
  SelfOutgoingTransfer: t('shared.smlDescriptions.SelfOutgoingTransfer'),
  EmptyCarboyAcceptance: t('shared.smlDescriptions.EmptyCarboyAcceptance'),
  IncomingTransferAcceptance: t(
    'shared.smlDescriptions.IncomingTransferAcceptance',
  ),
  WarehousePurchaseOrderAcceptance: t(
    'shared.smlDescriptions.WarehousePurchaseOrderAcceptance',
  ),
  BlockedStockTransfer: t('shared.smlDescriptions.BlockedStockTransfer'),
  FixDisagreements: t('shared.smlDescriptions.FixDisagreements'),
  LockStock: t('shared.smlDescriptions.LockStock'),
  UnlockStock: t('shared.smlDescriptions.UnlockStock'),
  OutgoingTransferApprove: t('shared.smlDescriptions.OutgoingTransferApprove'),
  OutgoingTransferShip: t('shared.smlDescriptions.OutgoingTransferShip'),
  OutgoingTransferCancel: t('shared.smlDescriptions.OutgoingTransferCancel'),
  EmptyCancelLocationByIntegrator: t(
    'shared.smlDescriptions.EmptyCancelLocationByIntegrator',
  ),
  StockIncreaseByIntegrator: t(
    'shared.smlDescriptions.StockIncreaseByIntegrator',
  ),
  StockDecreaseByIntegrator: t(
    'shared.smlDescriptions.StockDecreaseByIntegrator',
  ),
  CompleteCancelledOrder: t('shared.smlDescriptions.CompleteCancelledOrder'),
  TransferDifferenceWithIntegrator: t(
    'shared.smlDescriptions.TransferDifferenceWithIntegrator',
  ),
  ProductionInput: t('shared.smlDescriptions.ProductionInput'),
  ProductionOutput: t('shared.smlDescriptions.ProductionOutput'),
  IncrementBagStock: t('shared.smlDescriptions.IncrementBagStock'),
  DecrementBagStock: t('shared.smlDescriptions.DecrementBagStock'),
  RefundOrder: t('shared.smlDescriptions.RefundOrder'),
  GetirWater: t('shared.smlDescriptions.GetirWater'),
  IncreaseReserve: t('shared.smlDescriptions.IncreaseReserve'),
  DecreaseReserve: t('shared.smlDescriptions.DecreaseReserve'),
  IncreaseAvailable: t('shared.smlDescriptions.IncreaseAvailable'),
  DecreaseAvailable: t('shared.smlDescriptions.DecreaseAvailable'),
  IncreaseDead: t('shared.smlDescriptions.IncreaseDead'),
  DecreaseDead: t('shared.smlDescriptions.DecreaseDead'),
  IncreaseTotal: t('shared.smlDescriptions.IncreaseTotal'),
  DecreaseTotal: t('shared.smlDescriptions.DecreaseTotal'),
  IncreaseLock: t('shared.smlDescriptions.IncreaseLock'),
  DecreaseLock: t('shared.smlDescriptions.DecreaseLock'),
  IncreaseTransfer: t('shared.smlDescriptions.IncreaseTransfer'),
  DecreaseTransfer: t('shared.smlDescriptions.DecreaseTransfer'),
  IncreaseAcceptance: t('shared.smlDescriptions.IncreaseAcceptance'),
  DecreaseAcceptance: t('shared.smlDescriptions.DecreaseAcceptance'),
  IncreaseCancel: t('shared.smlDescriptions.IncreaseCancel'),
  DecreaseCancel: t('shared.smlDescriptions.DecreaseCancel'),
  IncreaseReservedForTransfer: t(
    'shared.smlDescriptions.IncreaseReservedForTransfer',
  ),
  DecreaseReservedForTransfer: t(
    'shared.smlDescriptions.DecreaseReservedForTransfer',
  ),
  IncreaseFrozen: t('shared.smlDescriptions.IncreaseFrozen'),
  DecreaseFrozen: t('shared.smlDescriptions.DecreaseFrozen'),
  IncreaseConsumption: t('shared.smlDescriptions.IncreaseConsumption'),
  DecreaseConsumption: t('shared.smlDescriptions.DecreaseConsumption'),
  IncreaseNonagreement: t('shared.smlDescriptions.IncreaseNonagreement'),
  DecreaseNonagreement: t('shared.smlDescriptions.DecreaseNonagreement'),
  IncreaseProductLocationQuantityForPicking: t(
    'shared.smlDescriptions.IncreaseProductLocationQuantityForPicking',
  ),
  FixDisagreementsAvailable: t(
    'shared.smlDescriptions.FixDisagreementsAvailable',
  ),
  FixDisagreementsDead: t('shared.smlDescriptions.FixDisagreementsDead'),
  FixDisagreementsReserve: t('shared.smlDescriptions.FixDisagreementsReserve'),
  FixDisagreementsLock: t('shared.smlDescriptions.FixDisagreementsLock'),
  FixDisagreementsNonagreement: t(
    'shared.smlDescriptions.FixDisagreementsNonagreement',
  ),
  FixDisagreementsTransfer: t(
    'shared.smlDescriptions.FixDisagreementsTransfer',
  ),
  FixDisagreementsAcceptance: t(
    'shared.smlDescriptions.FixDisagreementsAcceptance',
  ),
  FixDisagreementsCancel: t('shared.smlDescriptions.FixDisagreementsCancel'),
  FixDisagreementsFrozen: t('shared.smlDescriptions.FixDisagreementsFrozen'),
  FixDisagreementsConsumption: t(
    'shared.smlDescriptions.FixDisagreementsConsumption',
  ),
  FixDisagreementsReservedForTransfer: t(
    'shared.smlDescriptions.FixDisagreementsReservedForTransfer',
  ),
  FromEquipmentToOutgoingTransferPallet: t(
    'shared.smlDescriptions.FromEquipmentToOutgoingTransferPallet',
  ),
  FromOutgoingTransferPalletToEquipment: t(
    'shared.smlDescriptions.FromOutgoingTransferPalletToEquipment',
  ),
  RetailDecrease: t('shared.smlDescriptions.RetailDecrease'),
  RetailRefund: t('shared.smlDescriptions.RetailRefund'),
  RetailCancel: t('shared.smlDescriptions.RetailCancel'),
  CancelOutgoingTransfer: t('shared.smlDescriptions.CancelOutgoingTransfer'),
  FromPickingToDamaged: t('shared.smlDescriptions.FromPickingToDamaged'),
  FromPickingToExpiredGoods: t(
    'shared.smlDescriptions.FromPickingToExpiredGoods',
  ),
  FromPickingToDispatchBlocked: t(
    'shared.smlDescriptions.FromPickingToDispatchBlocked',
  ),
  FromPickingToQualityControl: t(
    'shared.smlDescriptions.FromPickingToQualityControl',
  ),
  FromPickingToStockDiscrepancy: t(
    'shared.smlDescriptions.FromPickingToStockDiscrepancy',
  ),
  FromReturnedGoodsToDamaged: t(
    'shared.smlDescriptions.FromReturnedGoodsToDamaged',
  ),
  FromReturnedGoodsToExpiredGoods: t(
    'shared.smlDescriptions.FromReturnedGoodsToExpiredGoods',
  ),
  FromReturnedGoodsToPicking: t(
    'shared.smlDescriptions.FromReturnedGoodsToPicking',
  ),
  FromReturnedGoodsToQualityControl: t(
    'shared.smlDescriptions.FromReturnedGoodsToQualityControl',
  ),
  FromReturnedGoodsToStockDiscrepancy: t(
    'shared.smlDescriptions.FromReturnedGoodsToStockDiscrepancy',
  ),
  FromDamagedToStockDiscrepancy: t(
    'shared.smlDescriptions.FromDamagedToStockDiscrepancy',
  ),
  FromDamagedToExpiredGoods: t(
    'shared.smlDescriptions.FromDamagedToExpiredGoods',
  ),
  FromDamagedToPicking: t('shared.smlDescriptions.FromDamagedToPicking'),
  FromDamagedToQualityControl: t(
    'shared.smlDescriptions.FromDamagedToQualityControl',
  ),
  FromExpiredGoodsToQualityControl: t(
    'shared.smlDescriptions.FromExpiredGoodsToQualityControl',
  ),
  FromExpiredGoodsToPicking: t(
    'shared.smlDescriptions.FromExpiredGoodsToPicking',
  ),
  FromDispatchBlockedToDamaged: t(
    'shared.smlDescriptions.FromDispatchBlockedToDamaged',
  ),
  FromDispatchBlockedToExpiredGoods: t(
    'shared.smlDescriptions.FromDispatchBlockedToExpiredGoods',
  ),
  FromDispatchBlockedToPicking: t(
    'shared.smlDescriptions.FromDispatchBlockedToPicking',
  ),
  FromDispatchBlockedToQualityControl: t(
    'shared.smlDescriptions.FromDispatchBlockedToQualityControl',
  ),
  FromDispatchBlockedToStockDiscrepancy: t(
    'shared.smlDescriptions.FromDispatchBlockedToStockDiscrepancy',
  ),

  FromMainWarehouseAcceptanceToPicking: t(
    'shared.smlDescriptions.FromMainWarehouseAcceptanceToPicking',
  ),
  FromMainWarehouseAcceptanceToQualityControl: t(
    'shared.smlDescriptions.FromMainWarehouseAcceptanceToQualityControl',
  ),
  FromQualityControlToDamaged: t(
    'shared.smlDescriptions.FromQualityControlToDamaged',
  ),
  FromQualityControlToExpiredGoods: t(
    'shared.smlDescriptions.FromQualityControlToExpiredGoods',
  ),
  FromQualityControlToPicking: t(
    'shared.smlDescriptions.FromQualityControlToPicking',
  ),
  FromQualityControlToDispatchBlocked: t(
    'shared.smlDescriptions.FromQualityControlToDispatchBlocked',
  ),
  FromQualityControlToStockDiscrepancy: t(
    'shared.smlDescriptions.FromQualityControlToStockDiscrepancy',
  ),
  CompleteCancelledNotDepartedOrder: t(
    'shared.smlDescriptions.CompleteCancelledNotDepartedOrder',
  ),
};
