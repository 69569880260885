import React, { Component } from 'react';
import { Card, notification } from 'antd';
import './ProductsInfoPanel.scss';
import { uniqBy } from 'lodash';
import { WarehouseControl, ProductControl } from './control';
import { PurchaseOrderTable } from './PurchaseOrderTable';
import { DataTable } from './DataTable';
import i18n from './../../../../../i18n';
import { track } from '../../../../../services/segment';
import { PurchaseOrderCreateEvents } from '../../../../../services/events';

class ProductsInfoPanel extends Component {
  settings = {
    main: {
      className: 'ProductsInfoPanel_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
    this.warehouseControlRef = React.createRef();
    this.productControlRef = React.createRef();
    this.purchaseOrderTableRef = React.createRef();
  }

  componentDidMount() {}

  stateFactory = () => {
    return {
      selectedWarehouseList: [],
      selectedProductList: [],
      imported: false,
      dataSource: null,
      columns: [],
    };
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card
          size="small"
          title={i18n.t(
            'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.title',
          )}
        >
          <this.TableToolbar />
          <this.TableComponent />
        </Card>
      </div>
    );
  }

  TableToolbar = (props) => {
    return (
      <div className="TableToolbar">
        <this.WarehouseControl />
        <this.ProductControl />
      </div>
    );
  };

  TableComponent = (props) => {
    const { dataSource, columns, selectedProductList, imported } = this.state;
    return (
      <PurchaseOrderTable
        ref={this.purchaseOrderTableRef}
        onRowDelete={(dataSource, key) => {
          this.setState({ dataSource: DataTable.recalculateTotal(dataSource) });
          if (!imported) {
            this.setState({
              selectedProductList: selectedProductList.filter(
                (sp) => sp.id !== key,
              ),
            });
          }
        }}
        onCellSave={(dataSource) => {
          this.setState({ dataSource: DataTable.recalculateTotal(dataSource) });
        }}
        onColumnDelete={(columns) => {
          this.setState({ columns });
          const { selectedWarehouseList, dataSource } = this.state;
          if (selectedWarehouseList && selectedWarehouseList.length) {
            const dispaliedWarehouses = columns
              .filter((item) => item.warehouseId)
              .map((item) => item.warehouseId);
            const updatedList = selectedWarehouseList.filter((warehouse) =>
              dispaliedWarehouses.includes(warehouse.id),
            );
            this.setState({ selectedWarehouseList: updatedList });
          }
          this.setState({ dataSource: DataTable.recalculateTotal(dataSource) });
        }}
        dataSource={dataSource}
        columns={columns}
      />
    );
  };

  WarehouseControl = (props) => {
    const { warehouseList, imported, supplierInfo, productList } = this.props;
    return (
      <WarehouseControl
        disabled={
          imported ||
          !supplierInfo ||
          !supplierInfo.supplier ||
          (!supplierInfo.supplier._id && !supplierInfo.supplier.id) ||
          !productList ||
          !productList.length ||
          !warehouseList ||
          !warehouseList.length
        }
        ref={this.warehouseControlRef}
        list={warehouseList}
        placeholder={i18n.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.toolbar.warehouseControl',
        )}
        onAdd={(warehouse) => {
          this.setState(
            {
              selectedWarehouseList: uniqBy(
                [...this.state.selectedWarehouseList, warehouse],
                'id',
              ),
            },
            this.setManualData,
          );
          track(PurchaseOrderCreateEvents.PO_CREATE_WAREHOUSE_ADDED);
        }}
        onAddAll={(list) => {
          this.setState(
            {
              selectedWarehouseList: [...list],
            },
            this.setManualData,
          );
          track(PurchaseOrderCreateEvents.PO_CREATE_ALL_WAREHOUSES_ADDED);
        }}
      />
    );
  };

  ProductControl = (props) => {
    const { productList, imported, supplierInfo, warehouseList } = this.props;
    return (
      <ProductControl
        disabled={
          imported ||
          !supplierInfo ||
          !supplierInfo.supplier ||
          (!supplierInfo.supplier._id && !supplierInfo.supplier.id) ||
          !productList ||
          !productList.length ||
          !warehouseList ||
          !warehouseList.length
        }
        ref={this.productControlRef}
        list={productList}
        placeholder={i18n.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.toolbar.productControl',
        )}
        onAdd={(product) => {
          this.setState(
            {
              selectedProductList: uniqBy(
                [...this.state.selectedProductList, product],
                'id',
              ),
            },
            this.setManualData,
          );
          track(PurchaseOrderCreateEvents.PO_CREATE_PRODUCT_ADDED);
        }}
      />
    );
  };

  clearState = () => {
    this.setState(this.stateFactory(), () => {
      this.clearWarehouseControl();
      this.clearProductControl();
      this.clearPurchaseOrderTable();
    });
  };

  clearWarehouseControl = () => {
    this.warehouseControlRef.current.clearState();
  };

  clearProductControl = () => {
    this.productControlRef.current.clearState();
  };

  clearPurchaseOrderTable = () => {
    this.purchaseOrderTableRef.current.clearState();
  };

  getTransformedData = () => {
    const rawData = this.purchaseOrderTableRef.current.getTransformedData();
    const { columns } = this.state;
    const data = DataTable.makeData(rawData, columns);
    return data;
  };

  setImportedData = (importedData) => {
    try {
      const { warehouseList, productList } = this.props;
      const dataSource = DataTable.transformImportedData(
        importedData,
        warehouseList,
        productList,
      );
      const columns = [
        ...DataTable.getImportedColunms(
          productList,
          warehouseList,
          importedData,
        ),
      ];
      this.setState({ ...this.state, dataSource, columns, imported: true });
    } catch (error) {
      notification.error({
        duration: 4,
        message: (
          <div style={{ fontWeight: 'bold' }}>
            {i18n.t(
              'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.error.title',
            )}
          </div>
        ),
        description: (
          <div>
            <div style={{ color: 'red' }}>{error.message}</div>
          </div>
        ),
      });
      this.setState(this.stateFactory());
    }
  };

  setManualData() {
    const { selectedWarehouseList, selectedProductList } = this.state;
    const dataSource = DataTable.transfromManualData(
      selectedWarehouseList,
      selectedProductList,
    );
    const columns = [
      ...DataTable.getManualColunms(selectedProductList, selectedWarehouseList),
    ];
    this.setState({ ...this.state, dataSource, columns });
  }
}

const ProductsInfoPanelTranslated = ProductsInfoPanel;

export { ProductsInfoPanelTranslated as ProductsInfoPanel };
