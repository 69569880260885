import React, { Component } from 'react';
import { Button, notification } from 'antd';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { withNamespaces } from 'react-i18next';
import i18n from '../../../src/i18n';
/*----------------------------------------------------------------------------*/
import './ExportToExcel.scss';
/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/

class ExportToExcel extends Component {
  render() {
    const { loading, disabled, buttonText } = this.props;
    return (
      <Button
        className="ExportToExcelButton"
        onClick={() => this.exportToXlsx()}
        loading={loading}
        disabled={disabled}
        icon="export"
      >
        {buttonText || this.props.t('components.ExportToExcel.exportToExcel')}
      </Button>
    );
  }

  exportToXlsx = () => {
    const {
      action,
      query,
      getFilter,
      fileName,
      dataFormatter,
      local,
      data,
    } = this.props;
    if (!local) {
      return action(query, getFilter(), fileName, dataFormatter);
    } else {
      return action({ fileName, data });
    }
  };

  static fileSave = ({ fileName, data }) => {
    const maxRecordLines = 100000;
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    if (!data || !data.length) {
      notification.error({
        duration: 4,
        message: (
          <div style={{ fontWeight: 'bold' }}>
            {i18n.t('components.ExportToExcel.noDataToExport')}
          </div>
        ),
      });
      return null;
    }
    if (data.length > maxRecordLines) {
      notification.error({
        duration: 4,
        message: (
          <div style={{ fontWeight: 'bold' }}>
            {i18n.t('components.ExportToExcel.fileLimitExceeded')}
          </div>
        ),
      });
      return null;
    }
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const savingData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(savingData, fileName + fileExtension);
    notification.success({
      duration: 4,
      message: (
        <div>
          <div style={{ fontWeight: 'bold' }}>
            {i18n.t('components.ExportToExcel.exportSuccess')}
          </div>
          <div>
            <span style={{ color: 'green' }}>{fileName + fileExtension}</span>
          </div>
        </div>
      ),
    });
  };
}

const ExportToExcelTranslated = withNamespaces('translation')(ExportToExcel);

export { ExportToExcelTranslated as ExportToExcel };
