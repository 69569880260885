import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { courierReducer } from './courier';
import { purchaseOrderReducer } from './purchaseOrder';
import { transferReducer } from './transfer';
import { outgoingTransferReducer } from './outgoingTransfer';
import { orderReducer } from './order';
import { pickerReducer } from './picker';
import { productReducer } from './product';
import { inventoryReducer } from './inventory';
import { stockMovementLogReducer } from './stockMovementLog';
import { warehouseReducer } from './warehouse';
import { supplierReducer } from './supplier';
import { blockedStockReducer } from './blockedStock';
import { warehousePurchaseOrderReducer } from './warehousePurchaseOrder';
import { disposalReducer } from './disposal';
import { refundReducer } from './refund';
import { warehouseLocationReducer } from './warehouseLocation';
import { timerReducer } from './timer';
import { heatingEstimationReducer } from './heatingEstimation';
import { countryReducer } from './country';
import { cityReducer } from './city';
import { cancelledOrdersReducer } from './cancelledOrders';
import { productionModuleReducer } from './productionModule';
import { returnedOrdersReducer } from './returnedOrders';
import { missingProductsOrdersReducer } from './missingProductsOrders';
import { transactionsReducer } from './transactions';

export const reducer = combineReducers({
  auth: authReducer,
  courier: courierReducer,
  transfer: transferReducer,
  outgoingTransfer: outgoingTransferReducer,
  purchaseOrder: purchaseOrderReducer,
  order: orderReducer,
  picker: pickerReducer,
  product: productReducer,
  inventory: inventoryReducer,
  stockMovementLog: stockMovementLogReducer,
  warehouse: warehouseReducer,
  supplier: supplierReducer,
  blockedStock: blockedStockReducer,
  warehousePurchaseOrder: warehousePurchaseOrderReducer,
  warehouseLocation: warehouseLocationReducer,
  disposal: disposalReducer,
  refund: refundReducer,
  timer: timerReducer,
  heatingEstimation: heatingEstimationReducer,
  country: countryReducer,
  city: cityReducer,
  cancelledOrders: cancelledOrdersReducer,
  productionModule: productionModuleReducer,
  returnedOrders: returnedOrdersReducer,
  missingProductsOrders: missingProductsOrdersReducer,
  transactions: transactionsReducer,
});
