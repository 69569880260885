import React, { Component } from 'react';
import { Select, Spin } from 'antd';
/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/
import { getTranslation } from './../../../../shared';
import { statuses } from '../../../../shared/statuses';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export class ConclusionTypeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
    this.list = this.getList();
  }

  getList = () => {
    const { blockedStockConclusionType } = statuses;
    const list = [];
    for (const key in blockedStockConclusionType) {
      if (blockedStockConclusionType.hasOwnProperty(key)) {
        const element = { key, value: blockedStockConclusionType[key] };
        list.push(element);
      }
    }
    return list;
  };

  stateFactory = () => {
    return {};
  };

  componentDidMount() {}

  render() {
    return <this.SelectField />;
  }

  SelectField = () => {
    const { loading, disabled, placeholder } = this.props;
    return (
      <Select
        showSearch
        allowClear
        optionFilterProp="children"
        disabled={disabled}
        placeholder={placeholder}
        value={this.getValue()}
        filterOption={this.filterOption}
        onChange={this.onChange}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {this.getOptions()}
      </Select>
    );
  };

  getOptions = () => {
    const options = this.list.map((item) => {
      return (
        <Select.Option
          value={getTranslation.blockedStockConclusionType(item.key)}
          key={item.key}
          rawvalue={item}
        >
          {getTranslation.blockedStockConclusionType(item.key)}
        </Select.Option>
      );
    });
    return options;
  };

  getValue = () => {
    const { value } = this.props;
    if (!value) {
      return;
    }
    return getTranslation.blockedStockConclusionType(value.key);
  };

  filterOption = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  clearField = () => {
    this.setState(this.stateFactory());
  };

  onChange = (value, option) => {
    let selectedItem = null;
    try {
      selectedItem = option.props.rawvalue;
    } catch (error) {
      selectedItem = null;
    }
    this.props.onChange(selectedItem);
  };
}
