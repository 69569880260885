export const permission = {
  outgoingTransferList: {
    url: '/stock/transfer/outgoing/list',
    permission: { action: 'read' },
  },
  transferDetail: {
    url: '/stock/transfer/:id',
    permission: { action: 'read' },
  },
  orderList: {
    url: '/dashboard/orders',
    permission: { action: 'read' },
  },
  orderMap: {
    url: '/dashboard/map',
    permission: { action: 'read' },
  },
  homeExpirationDate: {
    url: '/dashboard/expiration-date',
    permission: { action: 'read' },
  },
  warehouseStatus: {
    url: '/warehouse-status',
    permission: { action: 'update' },
  },
  cancelledOrders: {
    url: '/stock/cancelled-orders',
    permission: { action: 'read' },
  },
  shipTransfer: {
    url: '/ship-transfer',
    permission: { action: 'update' },
  },
  returnedOrders: {
    url: '/stock/returned-orders',
    permission: { action: 'read' },
  },
  missingProductOrders: {
    url: '/stock/order-missing-products',
    permission: { action: 'update' },
  },
};
