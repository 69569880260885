import React, { Component } from 'react';
import { Select, Spin } from 'antd';
import i18n from './../../../../../i18n';
/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export class SendToSupplierSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
    this.listOptions = {
      notSelected: {
        value: null,
        name: i18n.t('components.YesNoTag.notSelected'),
      },
      no: { value: false, name: i18n.t('components.YesNoTag.no') },
      yes: { value: true, name: i18n.t('components.YesNoTag.yes') },
    };
  }

  componentDidMount() {}

  stateFactory = () => {
    return {};
  };

  render() {
    return <this.SelectField />;
  }

  SelectField = () => {
    const { loading, disabled, placeholder } = this.props;
    return (
      <Select
        {...this.props}
        showSearch
        optionFilterProp="children"
        disabled={disabled}
        placeholder={placeholder}
        value={this.getValue()}
        filterOption={this.filterOption}
        onChange={this.onChange}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {this.getOptions()}
      </Select>
    );
  };

  getOptions = () => {
    const options = Object.entries(this.listOptions).map(([key, item]) => {
      return (
        <Select.Option value={item.value} key={key} rawvalue={item}>
          {item.value === null ? (
            <span style={{ color: 'red' }}>{item.name}</span>
          ) : (
            <span>{item.name}</span>
          )}
        </Select.Option>
      );
    });
    return options;
  };

  getValue = () => {
    const { value, supplier } = this.props;
    if (supplier && !supplier.isApiEnabled) {
      return this.listOptions.no.value;
    }
    if (value === null) {
      return this.listOptions.notSelected.value;
    }
    if (value === false) {
      return this.listOptions.no.value;
    }
    return this.listOptions.yes.value;
  };

  filterOption = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  onChange = (value, option) => {
    let selectedItem = null;
    try {
      selectedItem = option.props.rawvalue;
    } catch (error) {
      selectedItem = null;
    }
    setTimeout(() => {
      this.props.onChange(selectedItem.value);
    });
  };
}
