import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo, ExportToExcel } from './../components';

export const stockMovementLogAction = {
  getStockMovementLogList,
  exportToXlsxStockMovementLogList,
  getStockMovementLogDetail,
  resetStockMovementLogList,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getStockMovementLogList(query, filter) {
  return (dispatch) => {
    dispatch({ type: actionType.getStockMovementLogList_InProgress });
    http
      .getStockMovementLogList(query, filter)
      .then((response) => {
        const { stockMovementLogs } = response.data.data;
        dispatch({
          type: actionType.getStockMovementLogList_Succeeded,
          payload: stockMovementLogs,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getStockMovementLogList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getStockMovementLogList_Failed,
          payload: error,
        });
      });
  };
}

function exportToXlsxStockMovementLogList(query, filter, fileName, formatter) {
  return (dispatch) => {
    dispatch({ type: actionType.exportToXlsx_InProgress });
    getDataForExport(query, filter)
      .then((responses) => {
        ExportToExcel.fileSave({
          fileName,
          data: formatter(responses),
        });
        dispatch({
          type: actionType.exportToXlsx_Succeeded,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.exportToXlsx_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.exportToXlsx_Failed,
          payload: error,
        });
      });
  };
}

function getDataForExport(query, filter) {
  const limit = 50;
  const offset = 0;
  const total = query.limit;
  return getCallOneByOne(total, filter, limit, offset, []);
}

function getCallOneByOne(total, filter, limit, offset, acc = []) {
  if (offset >= total) {
    return http
      .getStockMovementLogList({ limit, offset }, filter)
      .then((res) => {
        acc.push(...res.data.data.stockMovementLogs.data);
        return acc;
      })
      .catch((err) => {
        return acc;
      });
  }
  return http
    .getStockMovementLogList({ limit, offset }, filter)
    .then((res) => {
      acc.push(...res.data.data.stockMovementLogs.data);
      return getCallOneByOne(total, filter, limit, offset + limit, acc);
    })
    .catch((err) => {
      return getCallOneByOne(total, filter, limit, offset + limit, acc);
    });
}

function getStockMovementLogDetail(id) {
  return (dispatch) => {
    dispatch({ type: actionType.getStockMovementLogDetail_InProgress });
    http
      .getStockMovementLogDetail(id)
      .then((response) => {
        dispatch({
          type: actionType.getStockMovementLogDetail_Succeeded,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.getStockMovementLogDetail_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getStockMovementLogDetail_Failed,
          payload: error,
        });
      });
  };
}

function resetStockMovementLogList() {
  return (dispatch) =>
    dispatch({
      type: actionType.resetStockMovementLogList,
      payload: {},
    });
}
