import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Avatar, Modal } from 'antd';
import { withNamespaces } from 'react-i18next';
import classNames from 'classnames';
import { PickerJobDetail } from './PickerJobDetail';
// --------------------------------------------------
import './PickerCard.scss';
// --------------------------------------------------
// --------------------------------------------------
import pickerMarker from './../../../assets/markers/picker.png';
import { constants, getTranslation } from './../../../shared';
// --------------------------------------------------
const { PICKER_STATUS } = constants;

class PickerCard extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory(props);
  }

  stateFactory = () => {
    const newState = {
      modalVisible: false,
    };
    return newState;
  };

  openPickerJobDetailModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      modalVisible: false,
    });
  };

  render() {
    const { picker } = this.props;
    if (!picker) {
      return null;
    }
    const picker_cssClass = classNames({
      'picker-card': true,
      free: picker.status === PICKER_STATUS.FREE,
      busy: picker.status === PICKER_STATUS.BUSY,
      canceled: picker.status === PICKER_STATUS.CANCELED,
      working: picker.status === PICKER_STATUS.WORKING,
      'on-order':
        picker.status >= PICKER_STATUS.VERIFYING &&
        picker.status <= PICKER_STATUS.REACHED,
    });
    return (
      <div className="PickerCard_inner">
        <Modal
          destroyOnClose
          title={this.props.t('pages.Home.pickerList.assignedJob')}
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          className="modal-picker-status"
          footer={null}
        >
          <PickerJobDetail
            pickerId={this.props.picker._id}
            pickerName={this.props.picker.name}
          />
        </Modal>
        <Card className={picker_cssClass}>
          <div className="picker-card_main">
            <div className="picker-info">
              <div className="picker-name">
                <img
                  className="picker-marker_img"
                  src={pickerMarker}
                  alt="picker marker"
                />
                <div className="picker-name_text">{picker.name}</div>
              </div>
              <div className="picker-detail">
                <div className="picker-status">
                  <Button>{getTranslation.pickerStatus(picker.status)}</Button>
                </div>
              </div>
            </div>
            <div className="picker-photo">
              <Avatar
                src={picker.picURL}
                size={50}
                shape="circle"
                icon="user"
                className="picker-photo_img"
              />
            </div>
          </div>
          {this.props.picker.status === PICKER_STATUS.WORKING && (
            <div className="show-picker-job-button">
              <Button type="primary" onClick={this.openPickerJobDetailModal}>
                {this.props.t('pages.Home.pickerList.showPickerJob')}
              </Button>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const PickerCardConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(PickerCard));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { PickerCardConnected as PickerCard };
