import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import uniqid from 'uniqid';

import { Table, Button, Card, notification } from 'antd';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './TransferList.scss';
import { TransferListFilter } from './TransferListFilter';
/*----------------------------------------------------------------------------*/
import { transferAction } from './../../../../actions';
import {
  Spinner,
  ExportToExcel,
  CopyToClipboard,
} from './../../../../components';
import { tableHelper, getTranslation, constants, getDateTimeFormatString, getDefaultDateRange } from './../../../../shared';
import { pageLoadEvents } from '../../../../services/events';
import { pageView } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class TransferList extends Component {
  settings = {
    main: {
      className: 'TransferList_main',
    },
    card: {
      title: this.props.t('pages.Operation.Transfer.list.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  dateFormat = getDateTimeFormatString();

  constructor(props) {
    super(props);
    this.defaultDateRange = getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_TRANSFER_LIST });
    this.getList();
  }

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      filter: this.getDefaultFilter(),
      selectedTransfers: [],
      selectedRowKeys: [],
    };
  };

  getDefaultFilter = () => {
    return null;
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageFilter />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    return (
      <div style={{ display: 'flex' }}>
        <ExportToExcel
          action={this.props.onExportToXlsxTransferList}
          query={{ limit: this.props.totalCount, offset: 0 }}
          getFilter={this.getFilter}
          fileName="transferList"
          dataFormatter={this.prepareForExport}
          loading={this.props.isExporting}
          disabled={false}
        />
        <Button
          block
          onClick={this.onBulkSendToWmsClick}
          disabled={!this.state.selectedTransfers.length}
          style={{ marginLeft: 5 }}
        >
          {this.props.t('pages.Operation.Transfer.list.button.sendToWms')}
        </Button>
      </div>
    );
  };

  onBulkSendToWmsClick = async () => {
    const { selectedTransfers } = this.state;
    this.bulkSendToWmsTransfer(selectedTransfers)
      .then(() => {
        this.setState((state) => {
          return {
            ...state,
            selectedTransfers: [],
            selectedRowKeys: [],
          };
        });

        setTimeout(() => {
          this.getList();
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState((state) => {
          return {
            ...state,
            selectedTransfers: [],
            selectedRowKeys: [],
          };
        });
      });
  };

  bulkSendToWmsTransfer = (selectedTransfers) => {
    return this.props.onBulkSendToWMS(selectedTransfers);
  };

  PageFilter = (props) => {
    return (
      <TransferListFilter
        defaultDateRange={this.defaultDateRange}
        onChange={this.setPageFilter}
        onClear={this.clearPageFilter}
      />
    );
  };

  isSendableToWMS = (transfer) => {
    const { DIRECTION_TYPES, TRANSFER_STATUS } = constants;
    if (
      (transfer.status === TRANSFER_STATUS.APPROVED &&
        [
          DIRECTION_TYPES.CENTRAL_TO_WAREHOUSE,
          DIRECTION_TYPES.CENTRAL_TO_CENTRAL,
        ].includes(transfer.directionType)) ||
      (transfer.status === TRANSFER_STATUS.SHIPPED &&
        [
          DIRECTION_TYPES.CENTRAL_TO_WAREHOUSE,
          DIRECTION_TYPES.WAREHOUSE_TO_CENTRAL,
          DIRECTION_TYPES.CENTRAL_TO_CENTRAL,
        ].includes(transfer.directionType))
    ) {
      return true;
    }
    return false;
  };

  PageTable = () => {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleTableChecboxChange,
      getCheckboxProps: (record) => {
        return { disabled: !this.isSendableToWMS(record) };
      },
    };
    return (
      <Table
        size="small"
        rowKey={(record) => {
          return record.id;
        }}
        dataSource={this.prepareData()}
        columns={this.prepareColumns()}
        pagination={{ ...this.state.pagination, total: this.props.totalCount }}
        loading={{ indicator: <Spinner />, spinning: this.props.loading }}
        onChange={this.handleTableChange}
        rowSelection={rowSelection}
      />
    );
  };

  handleTableChecboxChange = (selectedRowKeys) => {
    this.setState((state) => {
      return {
        ...state,
        selectedRowKeys,
        selectedTransfers: selectedRowKeys,
      };
    });
  };

  prepareColumns = (props) => {
    return [
      {
        title: this.props.t('pages.Operation.Transfer.list.table.date'),
        dataIndex: 'table.date',
        key: 'table.date',
        width: 150,
        ...tableHelper.fieldSorter.getDateSortProps('table.date'),
      },
      {
        title: this.props.t('pages.Operation.Transfer.list.table.transferId'),
        dataIndex: 'table.id',
        key: `table.id${uniqid()}`,
        width: 200,
        render: (id) => <CopyToClipboard message={id} />,
        ...tableHelper.fieldSorter.getStringSortProps('table.id'),
      },
      {
        title: this.props.t(
          'pages.Operation.Transfer.list.table.fromWarehouse',
        ),
        dataIndex: 'table.fromWarehouse',
        key: 'table.fromWarehouse',
        width: 200,
        render: (fromWarehouse) => <CopyToClipboard message={fromWarehouse} />,
        ...tableHelper.fieldSorter.getStringSortProps('table.fromWarehouse'),
      },
      {
        title: this.props.t('pages.Operation.Transfer.list.table.toWarehouse'),
        dataIndex: 'table.toWarehouse',
        key: 'table.toWarehouse',
        width: 205,
        render: (toWarehouse) => <CopyToClipboard message={toWarehouse} />,
        ...tableHelper.fieldSorter.getStringSortProps('table.toWarehouse'),
      },
      {
        title: this.props.t('pages.Operation.Transfer.list.table.status'),
        dataIndex: 'table.status',
        key: 'table.status',
        width: 150,
        ...tableHelper.fieldSorter.getNumberSortProps('table.status'),
        render: (status) => getTranslation.transferStatus(status),
      },
      {
        title: this.props.t('pages.Operation.Transfer.list.table.type'),
        dataIndex: 'table.integrationType',
        key: 'table.integrationType',
        width: 150,
        ...tableHelper.fieldSorter.getNumberSortProps('table.integrationType'),
        render: (type) => getTranslation.transferIntegrationType(type),
      },
      {
        title: this.props.t(
          'pages.Operation.Transfer.list.table.transferNumber',
        ),
        dataIndex: 'table.no',
        key: 'table.no',
        render: (no) => <CopyToClipboard message={no} />,
        ...tableHelper.fieldSorter.getStringSortProps('table.no'),
      },
      {
        title: '',
        dataIndex: 'table.id',
        key: 'table.id',
        render: (id) => {
          return <this.DetailButton id={id} />;
        },
      },
    ];
  };

  DetailButton = (props) => {
    const { id } = props;
    const url = `/stock-operation/transfer/detail/${id}`;
    return (
      <Link to={url}>
        <Button size="small">
          {this.props.t('pages.Operation.Transfer.list.table.detail')}
        </Button>
      </Link>
    );
  };

  prepareDataForXlsx = ({ transferList }) => {
    if (!transferList || !transferList.length) {
      return [];
    }
    return transferList.map((transfer) => {
      return {
        ...transfer,
        table: {
          id: transfer.id,
          no: transfer.no,
          key: transfer.id,
          status: transfer.status,
          integrationType: transfer.integrationType,
          fromWarehouse:
            (transfer.fromWarehouse && transfer.fromWarehouse.name) || '-',
          toWarehouse:
            (transfer.toWarehouse && transfer.toWarehouse.name) || '-',
          date: moment(new Date(transfer.createdAt))
            .local()
            .format(this.dateFormat),
        },
      };
    });
  };

  prepareData = () => {
    const { transferList } = this.props;
    if (!transferList || !transferList.length) {
      return [];
    }
    return transferList.map((transfer) => {
      return {
        ...transfer,
        table: {
          id: transfer.id,
          no: transfer.no,
          key: transfer.id,
          status: transfer.status,
          integrationType: transfer.integrationType,
          fromWarehouse:
            (transfer.fromWarehouse && transfer.fromWarehouse.name) || '-',
          toWarehouse:
            (transfer.toWarehouse && transfer.toWarehouse.name) || '-',
          date: moment(new Date(transfer.createdAt))
            .local()
            .format(this.dateFormat),
        },
      };
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState((state) => {
      return {
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      };
    }, this.getList);
  };

  getList = () => {
    const query = this.getQuery();
    const filter = this.getFilter();
    const msForStartDate = new Date(filter.createdAt.startDate).getTime();
    const msForEndDate = new Date(filter.createdAt.endDate).getTime();
    const msTimeDiff = msForEndDate - msForStartDate;

    if (msTimeDiff > constants.ALLOWED_MAX_TIME_INTERVAL_FOR_TRANSFER_FILTER) {
      notification.error({
        duration: 3,
        message: this.props.t(
          'pages.Operation.Transfer.list.notification.timeIntervalError',
        ),
      });
    } else if (
      filter &&
      filter.createdAt &&
      filter.createdAt.startDate &&
      filter.createdAt.endDate
    ) {
      this.props.onGetTransferList(query, filter);
    }
  };

  getQuery = () => {
    return this.state.query;
  };

  getFilter = () => {
    const filter = this.state.filter || {};
    if (!filter.createdAt) {
      filter.createdAt = this.defaultDateRange;
    }
    return filter;
  };

  setPageFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      };
    }, this.getList);
  };

  clearPageFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      };
    }, this.getList);
  };

  prepareForExport = (rawData) => {
    const data = this.prepareDataForXlsx({ transferList: rawData });
    if (!data || !data.length) {
      return null;
    }
    return data.map((item) => {
      return {
        [this.props.t('pages.Operation.Transfer.list.table.date')]: item.table
          .date,
        [this.props.t('pages.Operation.Transfer.list.table.transferId')]: item
          .table.id,
        [this.props.t(
          'pages.Operation.Transfer.list.table.fromWarehouse',
        )]: item.table.fromWarehouse,
        [this.props.t('pages.Operation.Transfer.list.table.toWarehouse')]: item
          .table.toWarehouse,
        [this.props.t('pages.Operation.Transfer.list.table.transferType')]: item
          .table.integrationType,
        [this.props.t(
          'pages.Operation.Transfer.list.table.transferNumber',
        )]: item.table.no,
        [this.props.t('pages.Operation.Transfer.list.table.status')]: item.table
          .status,
      };
    });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  transferList: state.transfer.list.data,
  loading: state.transfer.list.loading,
  totalCount: state.transfer.list.total,
  isExporting: state.transfer.isExporting,
});

const mapDispatchToProps = (dispatch) => ({
  onGetTransferList: (query, filter = {}) => {
    return dispatch(transferAction.getTransferList(query, filter));
  },
  onExportToXlsxTransferList: (query, filter, fileName, formatter) => {
    return dispatch(
      transferAction.exportToXlsxTransferList(
        query,
        filter,
        fileName,
        formatter,
      ),
    );
  },
  onBulkSendToWMS: (transferIds) => {
    return dispatch(transferAction.bulkSendToWmsTransfer(transferIds));
  },
});

const TransferListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(TransferList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { TransferListConnected as TransferList };
