/* eslint-disable eqeqeq */
import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { history } from './../history';
import { ErrorInfo } from './../components';
import { productAction } from './product';
import { constants } from '../shared';
import { config } from './../config';
import i18n from './../i18n';

export const purchaseOrderAction = {
  getPurchaseOrderList,
  getPurchaseOrderDetail,
  getOperationPurchaseOrderDetail,
  getOperationPurchaseOrderParentDetail,
  createPurchaseOrderAcceptance,
  createPurchaseOrder,
  filterPurchaseOrderList,
  createSelfPurchaseOrder,
  orderPurchaseOrder,
  sendToSupplier,
  cancelPurchaseOrder,
  updatePurchaseOrder,
  sendToWmsPurchaseOrder,
  calculateItemProgress,
  getCalculatedItemProgress,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getPurchaseOrderList(query) {
  return (dispatch) => {
    dispatch({ type: actionType.getPurchaseOrderList_InProgress });
    http
      .getPurchaseOrderList(query)
      .then((response) => {
        dispatch({
          type: actionType.getPurchaseOrderList_Succeeded,
          payload: response.data.data.purchaseOrders,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getPurchaseOrderList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getPurchaseOrderList_Failed,
          payload: error,
        });
      });
  };
}

function getPurchaseOrderDetail(id) {
  return (dispatch) => {
    dispatch({ type: actionType.getPurchaseOrderDetail_InProgress });
    http
      .getPurchaseOrderDetail(id)
      .then((response) => {
        dispatch({
          type: actionType.getPurchaseOrderDetail_Succeeded,
          payload: response.data.data.purchaseOrder,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getPurchaseOrderDetail_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getPurchaseOrderDetail_Failed,
          payload: error,
        });
      });
  };
}

function getOperationPurchaseOrderDetail(id) {
  return (dispatch) => {
    dispatch({ type: actionType.getOperationPurchaseOrderDetail_InProgress });
    http
      .getOperationPurchaseOrderDetail(id)
      .then((response) => {
        dispatch({
          type: actionType.getOperationPurchaseOrderDetail_Succeeded,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.getOperationPurchaseOrderDetail_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getOperationPurchaseOrderDetail_Failed,
          payload: error,
        });
      });
  };
}

function getOperationPurchaseOrderParentDetail(id) {
  return (dispatch) => {
    dispatch({ type: actionType.getOperationPurchaseOrderDetail_InProgress });
    http
      .getOperationPurchaseOrderDetail(id)
      .then((response) => {
        dispatch({
          type: actionType.getOperationPurchaseOrderDetail_Succeeded,
          payload: response.data.data,
        });
        const supplierId = response.data.data.purchaseOrder.supplier.id;
        dispatch(
          productAction.filterProductList({
            fields: 'barcodes picURL fullName packagingInfo wholesaleVat',
            includeDefaultFields: false,
            supplierIds: [supplierId],
          }, false),
        );
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.getOperationPurchaseOrderDetail_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getOperationPurchaseOrderDetail_Failed,
          payload: error,
        });
      });
  };
}

function createPurchaseOrderAcceptance(purchaseOrderId, params) {
  return () => {
    const { waybills, products, barcode } = params;
    const waybillPromises = waybills.map(
      (waybill) =>
        new Promise((resolve, reject) => {
          http
            .uploadWaybill({
              file: {
                name: waybill.imageFile.name,
                type: waybill.imageFile.type,
                size: waybill.imageFile.size,
              },
              folderName: 'waybill/',
            })
            .then((response) => {
              http
                .uploadToS3(
                  response.data.data.uploadUrls.signedUrl,
                  waybill.imageFile.originFileObj,
                )
                .then(() => resolve(response.data));
            });
        }),
    );
    let formattedWaybills = [];
    Promise.all(waybillPromises).then((values) => {
      formattedWaybills = waybills.map((waybill, i) => ({
        ss: waybill.ss,
        date: waybill.date,
        image: values[i].data.uploadUrls.imageUrl,
      }));
      http
        .createPurchaseOrderAcceptance(purchaseOrderId, {
          waybills: formattedWaybills,
          products,
          barcode,
        })
        .then(() => {
          const CWId = localStorage.getItem('warehouseId');
          history.push(`/r/${CWId}/stock/purchase-order/list`);
        })
        .catch((error) => {
          notification.error({
            duration: 4,
            message: ErrorInfo.message('createPurchaseOrderAcceptance'),
            description: ErrorInfo.description(error),
          });
          const CWId = localStorage.getItem('warehouseId');
          history.push(`/r/${CWId}/stock/purchase-order/list`);
        });
    });
  };
}

function createPurchaseOrder(data) {
  return (dispatch) => {
    dispatch({ type: actionType.createPurchaseOrder_InProgress });
    return http
      .createPurchaseOrder(data)
      .then((response) => {
        dispatch({
          type: actionType.createPurchaseOrder_Succeeded,
          payload: response.data.data,
        });
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.createPurchaseOrder_Succeeded),
        });
        const { id } = response.data.data;
        if (!id) {
          history.push(`/stock-operation/purchase-order/list`);
        }
        history.push(`/stock-operation/purchase-order/detail/${id}`);
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.createPurchaseOrder_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.createPurchaseOrder_Failed,
          payload: error,
        });
      });
  };
}

function filterPurchaseOrderList(query, data) {
  return (dispatch) => {
    dispatch({ type: actionType.filterPurchaseOrderList_InProgress });
    http
      .filterPurchaseOrderList(query, data)
      .then((response) => {
        dispatch({
          type: actionType.filterPurchaseOrderList_Succeeded,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.filterPurchaseOrderList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.filterPurchaseOrderList_Failed,
          payload: error,
        });
      });
  };
}

function createSelfPurchaseOrder(data) {
  return (dispatch) => {
    dispatch({ type: actionType.createSelfPurchaseOrder_InProgress });
    return http
      .createSelfPurchaseOrder(data)
      .then((response) => {
        dispatch({
          type: actionType.createSelfPurchaseOrder_Succeeded,
          payload: response.data.data.purchaseOrders,
        });
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.createPurchaseOrder_Succeeded),
        });
        const CWId = localStorage.getItem('warehouseId');
        history.push(`/r/${CWId}/stock/purchase-order/list`);
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.createSelfPurchaseOrder_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.createSelfPurchaseOrder_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function orderPurchaseOrder(id) {
  return (dispatch) => {
    dispatch({ type: actionType.orderPurchaseOrder_InProgress });
    return http
      .orderPurchaseOrder(id)
      .then((response) => {
        dispatch({
          type: actionType.orderPurchaseOrder_Succeeded,
          payload: response.data.data.purchaseOrders,
        });
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.orderPurchaseOrder_Succeeded),
        });
        dispatch(getOperationPurchaseOrderDetail(id));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.orderPurchaseOrder_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.orderPurchaseOrder_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function sendToSupplier(purchaseOrderId) {
  return (dispatch) => {
    dispatch({
      type: actionType.sendToSupplier_InProgress,
    });
    return http
      .sendToSupplier(purchaseOrderId)
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.sendToSupplier_Succeeded),
        });
        dispatch({
          type: actionType.sendToSupplier_Succeeded,
          payload: response,
        });
        dispatch(getOperationPurchaseOrderDetail(purchaseOrderId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.sendToSupplier_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.sendToSupplier_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function cancelPurchaseOrder(purchaseOrderId, isMainWarehousePurchaseOrder) {
  return (dispatch) => {
    dispatch({
      type: actionType.cancelPurchaseOrder_InProgress,
    });
    return http
      .cancelPurchaseOrder(purchaseOrderId)
      .then((response) => {
        if (isMainWarehousePurchaseOrder) {
          notification.warning({
            duration: 4,
            message: i18n.t('shared.messages.purchaseOrderCanceledAsync'),
          });
        } else {
          notification.success({
            duration: 4,
            message: ErrorInfo.message(
              actionType.cancelPurchaseOrder_Succeeded,
            ),
          });
        }
        dispatch({
          type: actionType.cancelPurchaseOrder_Succeeded,
          payload: response,
        });
        dispatch(getOperationPurchaseOrderDetail(purchaseOrderId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.cancelPurchaseOrder_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.cancelPurchaseOrder_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function updatePurchaseOrder(purchaseOrderId, data) {
  return (dispatch) => {
    dispatch({
      type: actionType.updatePurchaseOrder_InProgress,
    });
    http
      .updatePurchaseOrder(purchaseOrderId, data)
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.updatePurchaseOrder_Succeeded),
        });
        dispatch({
          type: actionType.updatePurchaseOrder_Succeeded,
          payload: response,
        });
        dispatch(getOperationPurchaseOrderDetail(purchaseOrderId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.updatePurchaseOrder_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.updatePurchaseOrder_Failed,
          payload: error,
        });
      });
  };
}

function sendToWmsPurchaseOrder(id) {
  return (dispatch) => {
    dispatch({
      type: actionType.sendToWmsPurchaseOrder_InProgress,
    });
    http
      .sendToWmsPurchaseOrder(id)
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.sendToWmsPurchaseOrder_Succeeded,
          ),
        });
        dispatch({
          type: actionType.sendToWmsPurchaseOrder_Succeeded,
          payload: response,
        });
        dispatch(getOperationPurchaseOrderDetail(id));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.sendToWmsPurchaseOrder_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.sendToWmsPurchaseOrder_Failed,
          payload: error,
        });
      });
  };
}

function calculateItemProgress(purchaseOrderId) {
  return (dispatch) => {
    dispatch({ type: actionType.calculateItemProgress_InProgress });
    return http
      .calculateItemProgress(purchaseOrderId, {
        emailAddress: config.ENV !== 'production' ? 'ogulcan.davran@getir.com' : 'planlama@getir.com',
      })
      .then((response) => {
        dispatch({
          type: actionType.calculateItemProgress_Succeeded,
        });
        if (
          response.data.data.status ==
          constants.PURCHASE_ORDER_CALCULATE_ITEM_PROGRESS.IN_PROGRESS
        ) {
          notification.warning({
            duration: 4,
            message: i18n.t(
              'shared.messages.purchaseOrderItemCalculationInProgress',
            ),
          });
        }
        if (
          response.data.data.status ==
          constants.PURCHASE_ORDER_CALCULATE_ITEM_PROGRESS.REQUESTED
        ) {
          notification.success({
            duration: 4,
            message: i18n.t(
              'shared.messages.purchaseOrderItemCalculationRequested',
            ),
          });
        }
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.calculateItemProgress_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.calculateItemProgress_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function getCalculatedItemProgress(purchaseOrderId) {
  return (dispatch) => {
    dispatch({ type: actionType.getCalculatedItemProgress_InProgress });
    return http
      .getCalculatedItemProgress(purchaseOrderId)
      .then((response) => {
        dispatch({
          type: actionType.getCalculatedItemProgress_Succeeded,
          payload: response.data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getCalculatedItemProgress_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getCalculatedItemProgress_Failed,
          payload: error,
        });
        throw error;
      });
  };
}
