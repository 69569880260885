import { createHttpService } from '../createHttpService';

const httpService = createHttpService();

export const supplier = {
  getSupplierList: (filter) => {
    if (!filter) {
      return httpService.post('/suppliers/filter', {
        isActive: true,
      });
    }
    return httpService.post('/suppliers/filter', {
      isActive: true,
      ...filter,
    });
  },
  getSupplierDetail: (supplierId) => httpService.get(`/suppliers/${supplierId}`),
};
