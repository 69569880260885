import React, { Component } from 'react';
import { Select } from 'antd';


export class TagMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    return {};
  };

  componentDidMount() {}

  render() {
    return <this.InputField />;
  }

  InputField = () => {
    const { placeholder } = this.props;
    return (
      <Select
        mode="tags"
        placeholder={placeholder}
        value={this.getValue()}
        allowClear
        onChange={this.onChange}
      />
    );
  };

  getValue = () => {
    const { value } = this.props;
    if (!value || !value.length) {
      return;
    }
    let tagList = value
      .toString()
      .trim()
      .split(',')
      .map((tagItem) => tagItem.trim());

    if (!tagList.length) {
      tagList = [value.trim()];
    }

    return tagList;
  };

  onChange = (value) => {
    let tagList = value
      .toString()
      .trim()
      .split(',')
      .map((transfer) => transfer.trim());

    if (!tagList.length) {
      tagList = [value.trim()];
    }

    const validTagList = tagList.filter((tagItem) => tagItem.length);

    this.props.onChange(validTagList);
  };
}
