import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import {
  Card, Descriptions, Table, Avatar,
} from 'antd';
/*----------------------------------------------------------------------------*/
import './PurchaseOrderDetail.scss';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard } from './../../../../components';
import { getTranslation, getDateTimeFormatString } from './../../../../shared';
import { warehousePurchaseOrderAction } from './../../../../actions';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class PurchaseOrderDetail extends Component {
  settings = {
    main: {
      className: 'PurchaseOrderDetail_main',
    },
    card: {
      title: this.props.t('pages.Stock.PurchaseOrder.detail.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => ({});

  componentDidMount() {
    this.getDetail();
  }

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {loading ? <Spinner /> : <this.PageDetail />}
        </Card>
      </div>
    );
  }

  CardExtra = () => <div />;

  PageDetail = (props) => (
    <div>
      <this.Description />
      <this.Table />
    </div>
  );

  DetailJson = () => <pre>{JSON.stringify(this.props.detail, null, 2)}</pre>;

  Description = (props) => {
    const { detail } = this.props;
    if (!detail) {
      return null;
    }
    return (
      <Descriptions column={1}>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.PurchaseOrder.detail.description.id',
          )}
        >
          <CopyToClipboard message={detail.id} />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.PurchaseOrder.detail.description.no',
          )}
        >
          <CopyToClipboard message={detail.no} />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.PurchaseOrder.detail.description.status',
          )}
        >
          {getTranslation.operationPurchaseOrderStatus(detail.status)}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.PurchaseOrder.detail.description.warehouse',
          )}
        >
          {detail.warehouse.name}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.PurchaseOrder.detail.description.supplier',
          )}
        >
          {detail.supplier.name}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.PurchaseOrder.detail.description.createdAt',
          )}
        >
          {moment(detail.createdAt)
            .local()
            .format(getDateTimeFormatString())}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  Table = () => {
    const { detail } = this.props;
    if (!detail || !detail.items || !detail.items.length) {
      return null;
    }
    const { items } = detail;
    return (
      <Table
        size="small"
        columns={this.prepareColumns(items)}
        dataSource={this.prepareData(items)}
        rowKey={(record) => record.item.id}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  prepareColumns = (props) => [
    {
      title: this.props.t('pages.Stock.PurchaseOrder.detail.table.image'),
      dataIndex: 'item.picURL',
      key: 'picURL',
      width: 72,
      render: (picURL) => (
        <Avatar
          className="product-image"
          shape="square"
          src={getTranslation.obj(picURL)}
        />
      ),
    },
    {
      title: this.props.t('pages.Stock.PurchaseOrder.detail.table.id'),
      dataIndex: 'item.id',
      key: 'item.id',
      render: (id) => <CopyToClipboard message={id} />,
    },
    {
      title: this.props.t('pages.Stock.PurchaseOrder.detail.table.name'),
      dataIndex: 'item.fullName',
      key: 'item.fullName',
      render: (fullName) => (
        <CopyToClipboard message={getTranslation.obj(fullName)} />
      ),
    },
    {
      title: this.props.t('pages.Stock.PurchaseOrder.detail.table.barcodes'),
      dataIndex: 'item.barcodes',
      key: 'item.barcodes',
      render: (barcodes) =>
        barcodes.map((barcode) => (
          <CopyToClipboard key={barcode} message={barcode} />
        )),
    },
    {
      title: this.props.t('pages.Stock.PurchaseOrder.detail.table.count'),
      dataIndex: 'orderedCount',
      key: 'orderedCount',
    },
  ];

  prepareData = (props) => props;

  getDetail = () => {
    const { id, onGetPurchaseOrderDetail } = this.props;
    onGetPurchaseOrderDetail(id);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.warehousePurchaseOrder.detail.loading,
  detail: state.warehousePurchaseOrder.detail.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetPurchaseOrderDetail: (id) => {
    dispatch(warehousePurchaseOrderAction.getWarehousePurchaseOrderDetail(id));
  },
});

const PurchaseOrderDetailConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(PurchaseOrderDetail));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { PurchaseOrderDetailConnected as PurchaseOrderDetail };
