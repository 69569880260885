import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
  detail: {
    loading: true,
    data: null,
    error: null,
  },
  productList: {
    loading: true,
    data: null,
    error: null,
  },
  emptyCarboy: {
    loading: false,
    data: null,
    error: null,
  },
  caseAndPallet: {
    loading: false,
    data: null,
    error: null,
  },
};

const switcher = {
  [actionType.getOutgoingTransferList_InProgress]: getOutgoingTransferList_InProgress,
  [actionType.getOutgoingTransferList_Succeeded]: getOutgoingTransferList_Succeeded,
  [actionType.getOutgoingTransferList_Failed]: getOutgoingTransferList_Failed,

  [actionType.getOutgoingTransferDetail_InProgress]: getOutgoingTransferDetail_InProgress,
  [actionType.getOutgoingTransferDetail_Succeeded]: getOutgoingTransferDetail_Succeeded,
  [actionType.getOutgoingTransferDetail_Failed]: getOutgoingTransferDetail_Failed,

  [actionType.createEmptyCarboyTransfer_InProgress]: createEmptyCarboyTransfer_InProgress,
  [actionType.createEmptyCarboyTransfer_Succeeded]: createEmptyCarboyTransfer_Succeeded,
  [actionType.createEmptyCarboyTransfer_Failed]: createEmptyCarboyTransfer_Failed,

  [actionType.createCaseAndPalletTransfer_InProgress]: createCaseAndPalletTransfer_InProgress,
  [actionType.createCaseAndPalletTransfer_Succeeded]: createCaseAndPalletTransfer_Succeeded,
  [actionType.createCaseAndPalletTransfer_Failed]: createCaseAndPalletTransfer_Failed,

  [actionType.createSelfOutgoingTransferProductList_InProgress]: createSelfOutgoingTransferProductList_InProgress,
  [actionType.createSelfOutgoingTransferProductList_Succeeded]: createSelfOutgoingTransferProductList_Succeeded,
  [actionType.createSelfOutgoingTransferProductList_Failed]: createSelfOutgoingTransferProductList_Failed,
};

export const outgoingTransferReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getOutgoingTransferList_InProgress(state) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function getOutgoingTransferList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload.transfers,
      error: null,
      total: payload.total,
    },
  });
}

function getOutgoingTransferList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getOutgoingTransferDetail_InProgress(state) {
  return Object.assign({}, state, {
    detail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getOutgoingTransferDetail_Succeeded(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getOutgoingTransferDetail_Failed(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function createEmptyCarboyTransfer_InProgress(state) {
  return Object.assign({}, state, {
    emptyCarboy: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function createEmptyCarboyTransfer_Succeeded(state, payload) {
  return Object.assign({}, state, {
    emptyCarboy: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function createEmptyCarboyTransfer_Failed(state, payload) {
  return Object.assign({}, state, {
    emptyCarboy: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function createCaseAndPalletTransfer_InProgress(state) {
  return Object.assign({}, state, {
    caseAndPallet: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function createCaseAndPalletTransfer_Succeeded(state, payload) {
  return Object.assign({}, state, {
    caseAndPallet: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function createCaseAndPalletTransfer_Failed(state, payload) {
  return Object.assign({}, state, {
    caseAndPallet: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function createSelfOutgoingTransferProductList_InProgress(state) {
  return Object.assign({}, state, {
    productList: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function createSelfOutgoingTransferProductList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    productList: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function createSelfOutgoingTransferProductList_Failed(state, payload) {
  return Object.assign({}, state, {
    productList: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}
