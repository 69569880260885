import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Descriptions,
  Button,
  Table,
  Modal,
  Avatar,
  Collapse,
  Dropdown,
  Icon,
  Menu,
} from 'antd';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import QRCode from 'qrcode';
/*----------------------------------------------------------------------------*/
import './OutgoingTransferDetail.scss';
import { WaybillUpload } from './UploadModal';
/*----------------------------------------------------------------------------*/
import {
  Spinner,
  JsonButton,
  PrintButton,
  CopyToClipboard,
} from './../../../../components';
/*----------------------------------------------------------------------------*/
import { outgoingTransferAction } from './../../../../actions';
import { constants, getTranslation, getDateTimeFormatString } from './../../../../shared';
import i18n from '../../../../i18n';
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class OutgoingTransferDetail extends Component {
  settings = {
    main: {
      className: 'OutgoingTransferDetail_main',
    },
    card: {
      title: this.props.t('pages.Stock.OutgoingTransfer.detail.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    this.getDetail();
  }

  stateFactory = () => {
    return {
      modalGenerateQrVisible: false,
      palletBarcode: null,
      modalShipVisible: false,
      waybillPrinted: false,
      clickedTransferShipButton: false,
      waybills: [],
      selectedPalletBarcode: null,
    };
  };

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {loading ? <Spinner /> : <this.PageDetail />}
        </Card>
      </div>
    );
  }

  CardExtra = () => {
    return (
      <Dropdown overlay={this.MenuAction}>
        <Button style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.t('pages.Stock.OutgoingTransfer.detail.button.actions')}{' '}
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  MenuAction = () => {
    return (
      <Menu>
        <Menu.Item key="JsonButton">
          <JsonButton data={this.props.detail} block />
        </Menu.Item>
        <Menu.Item key="ShipButton">
          <this.ShipButton />
        </Menu.Item>
        <Menu.Item key="ContinueButton">
          <this.ContinueButton />
        </Menu.Item>
        <Menu.Item key="GenerateQrButton">
          <this.GenerateQrButton />
        </Menu.Item>
      </Menu>
    );
  };

  ShipButton = (props) => {
    const items = this.getWaybillPrintData();

    const { detail: transfer } = this.props;
    if (!transfer || !transfer.status || !items) {
      return null;
    }
    const isEnable =
      transfer.status === constants.TRANSFER_STATUS.SHIPPING_PICKING_COMPLETED;
    return (
      <div>
        <Button
          block
          disabled={!isEnable}
          onClick={() => {
            this.setState({ modalShipVisible: true });
          }}
        >
          {this.props.t(
            'pages.Stock.OutgoingTransfer.detail.button.shipTransfer',
          )}
        </Button>
      </div>
    );
  };

  WaybillModal = () => {
    const items = this.getWaybillPrintData();

    const { detail: transfer } = this.props;
    if (!transfer || !transfer.status || !items) {
      return null;
    }
    return (
      <Modal
        width={734}
        okButtonProps={{}}
        title={this.props.t(
          'pages.Stock.OutgoingTransfer.detail.modalWaybill.title',
        )}
        visible={this.state.modalShipVisible}
        onCancel={() => {
          this.setState((state) => {
            return {
              ...state,
              modalShipVisible: false,
              waybills: [],
              waybillPrinted: false,
            };
          });
        }}
        footer={[
          <PrintButton
            key="print"
            items={items}
            changeParentState={() => {
              this.setState({ waybillPrinted: true });
            }}
            settings={{
              label: (
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Icon type="printer" style={{ marginRight: 5 }} />
                  {this.props.t(
                    'pages.Stock.OutgoingTransfer.detail.modalWaybill.button.printWaybill',
                  )}
                </span>
              ),
            }}
          />,
          <Button
            key="cancel"
            onClick={() => {
              this.setState((state) => {
                return {
                  ...state,
                  modalShipVisible: false,
                  waybills: [],
                  waybillPrinted: false,
                };
              });
            }}
          >
            {this.props.t(
              'pages.Stock.OutgoingTransfer.detail.modalWaybill.button.cancelWaybill',
            )}
          </Button>,
          <Button
            key="ship"
            disabled={
              !this.state.waybills.length ||
              !this.state.waybillPrinted ||
              this.state.clickedTransferShipButton
            }
            loading={this.state.clickedTransferShipButton}
            type="danger"
            onClick={() => {
              this.setState({ clickedTransferShipButton: true });
              this.setShip();
            }}
          >
            {this.props.t(
              'pages.Stock.OutgoingTransfer.detail.modalWaybill.button.shipTransfer',
            )}
          </Button>,
        ]}
      >
        <WaybillUpload
          waybills={this.state.waybills}
          disabled={!this.state.waybillPrinted}
          onChange={(waybills) => {
            this.setState({ waybills });
          }}
        />
      </Modal>
    );
  };

  getWaybillPrintData = () => {
    const { detail } = this.props;
    if (!detail || !detail.items || !detail.items.length) {
      return null;
    }
    const printData = detail.items.map((item) => {
      return {
        fromWarehouse: detail.fromWarehouse.name,
        toWarehouse: detail.toWarehouse.name,
        barcode: item.item.barcodes[0],
        name: getTranslation.obj(item.item.fullName),
        count: item.count,
      };
    });
    return printData;
  };

  ContinueButton = (props) => {
    const { detail: transfer } = this.props;
    if (!transfer || !transfer.status) {
      return null;
    }
    const isEnable =
      transfer.status === constants.TRANSFER_STATUS.SHIPPING_PICKING_COMPLETED;

    return (
      <Button
        block
        disabled={!isEnable}
        onClick={() => {
          this.setContinue();
        }}
      >
        {this.props.t(
          'pages.Stock.OutgoingTransfer.detail.button.continuePreparation',
        )}
      </Button>
    );
  };

  GenerateQrButton = (props) => {
    const { detail: transfer } = this.props;
    if (!transfer || !transfer.status) {
      return null;
    }
    const isEnable =
      transfer.status === constants.TRANSFER_STATUS.APPROVED ||
      transfer.status === constants.TRANSFER_STATUS.SHIPPING_PICKING_STARTED;

    return (
      <Button
        block
        disabled={!isEnable}
        onClick={() => {
          this.setState({ modalGenerateQrVisible: true });
          setTimeout(() => {
            this.generateQr();
          });
        }}
      >
        {this.props.t(
          'pages.Stock.OutgoingTransfer.detail.button.generatePalletQr',
        )}
      </Button>
    );
  };

  ModalGenerateQr = (props) => {
    return (
      <Modal
        title={this.props.t(
          'pages.Stock.OutgoingTransfer.detail.modalQr.title',
        )}
        visible={this.state.modalGenerateQrVisible}
        onOk={() => {
          this.setState({ modalGenerateQrVisible: false });
        }}
        onCancel={() => {
          this.setState({ modalGenerateQrVisible: false });
        }}
        footer={<this.ModalFooter />}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <canvas id="canvas" width="300" height="300" />
        </div>
      </Modal>
    );
  };

  modalGenerateQrForPallet = (props) => {
    return (
      <Modal
        title={`${this.props.t(
          'pages.Stock.OutgoingTransfer.detail.modalQr.qrTitle',
        )} (${this.state.selectedPalletBarcode})`}
        visible={this.state.modalGenerateQrVisibleForPallet}
        centered
        onOk={() => {
          this.setState({ modalGenerateQrVisibleForPallet: false });
        }}
        onCancel={() => {
          this.setState({ modalGenerateQrVisibleForPallet: false });
        }}
        footer={[]}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <canvas id="canvasForPallet" width="300" height="300" />
        </div>
      </Modal>
    );
  };

  ModalFooter = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span>
          <Button
            key="close"
            type="danger"
            onClick={() => {
              this.setState({ modalGenerateQrVisible: false });
            }}
          >
            {this.props.t(
              'pages.Stock.OutgoingTransfer.detail.modalQr.button.close',
            )}
          </Button>
        </span>
        <span>
          <Button
            key="print"
            type="primary"
            onClick={() => {
              this.printCanvas();
              this.setState({ modalGenerateQrVisible: false });
            }}
          >
            {this.props.t(
              'pages.Stock.OutgoingTransfer.detail.modalQr.button.print',
            )}
          </Button>
        </span>
      </div>
    );
  };

  generateQr = () => {
    QRCode.toCanvas(
      document.getElementById('canvas'),
      this.getPalletQrStr(),
      { errorCorrectionLevel: 'H' },
      (error) => {
        if (error) {
          console.error(error);
        }
      },
    );
  };

  generateQrForPallet = (barcode) => {
    const qr = { type: 130, data: { code: barcode } };
    QRCode.toCanvas(
      document.getElementById('canvasForPallet'),
      JSON.stringify(qr),
      { errorCorrectionLevel: 'H' },
      (error) => {
        if (error) {
          console.error(error);
        }
      },
    );
  };

  getPalletQrStr = () => {
    const { fromWarehouse, toWarehouse } = this.props.detail;
    const from = this.transformStr(fromWarehouse.name);
    const to = this.transformStr(toWarehouse.name);
    const timestamp = Date.now().toString();
    const palletBarcode = `${from}.${to}.${timestamp}`;
    this.setState({ palletBarcode });
    return palletBarcode;
  };

  transformStr = (str) => {
    return this.replaceTurkish(str)
      .toUpperCase()
      .split(' ')
      .join('')
      .split("'")
      .join('')
      .slice(0, 3)
      .normalize();
  };

  replaceTurkish = (str) => {
    return str
      .replace('ü', 'u')
      .replace('ı', 'i')
      .replace('ö', 'o')
      .replace('ş', 's')
      .replace('ğ', 'g')
      .replace('ç', 'c')
      .replace('İ', 'I')
      .replace('Ö', 'O')
      .replace('Ü', 'U')
      .replace('Ş', 'S')
      .replace('Ğ', 'G')
      .replace('Ç', 'C');
  };

  printCanvas = () => {
    const { palletBarcode } = this.state;
    this.addTransferPallet(palletBarcode);
    const dataUrl = document.getElementById('canvas').toDataURL();
    let windowContent = '<!DOCTYPE html>';
    windowContent += '<html>';
    windowContent += '<head><title>Pallet QR</title></head>';
    windowContent += '<body>';
    windowContent += `<img src="${dataUrl}">`;
    windowContent += '</body>';
    windowContent += '</html>';
    const printWin = window.open('', '', 'width=400,height=400');
    const printEvent = new Event('print');
    printWin.document.open();
    printWin.document.write(windowContent);
    printWin.document.addEventListener(
      'print',
      () => {
        printWin.focus();
        printWin.print();
        printWin.document.close();
        printWin.close();
      },
      true,
    );
    printWin.document.addEventListener(
      'load',
      () => {
        printWin.document.dispatchEvent(printEvent);
      },
      true,
    );
  };

  PageDetail = (props) => {
    return (
      <div>
        <this.Description />
        <this.Table />
        <this.PalletPanel />
        <this.ModalGenerateQr />
        <this.modalGenerateQrForPallet />
        <this.WaybillModal />
      </div>
    );
  };

  Description = (props) => {
    const { detail } = this.props;
    if (!detail) {
      return null;
    }
    return (
      <Descriptions column={1}>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.OutgoingTransfer.detail.description.id',
          )}
        >
          <CopyToClipboard message={detail.id} />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.OutgoingTransfer.detail.description.type',
          )}
        >
          {getTranslation.transferIntegrationType(detail.integrationType)}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.OutgoingTransfer.detail.description.status',
          )}
        >
          {getTranslation.transferStatus(detail.status)}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.OutgoingTransfer.detail.description.fromWarehouse',
          )}
        >
          {detail.fromWarehouse.name}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.OutgoingTransfer.detail.description.toWarehouse',
          )}
        >
          {detail.toWarehouse.name}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.OutgoingTransfer.detail.description.updatedAt',
          )}
        >
          {moment(new Date(detail.updatedAt))
            .local()
            .format(getDateTimeFormatString())}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  Table = (props) => {
    const { detail } = this.props;
    if (!detail || !detail.items) {
      return null;
    }
    const { items: products = [] } = detail;
    return (
      <Table
        size="small"
        columns={this.prepareColumns(products)}
        dataSource={this.prepareData(products)}
        rowKey={(record) => record.item.id}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  prepareColumns = (props) => {
    return [
      {
        title: this.props.t('pages.Stock.OutgoingTransfer.detail.table.image'),
        dataIndex: 'item.picURL',
        key: 'picURL',
        width: 72,
        render: (picURL) => {
          return (
            <Avatar
              className="product-image"
              shape="square"
              src={getTranslation.obj(picURL)}
            />
          );
        },
      },
      {
        title: this.props.t(
          'pages.Stock.OutgoingTransfer.detail.table.fullName',
        ),
        dataIndex: 'item.fullName',
        key: 'item.fullName',
        render: (fullName) => getTranslation.obj(fullName),
      },
      {
        title: this.props.t('pages.Stock.OutgoingTransfer.detail.table.number'),
        dataIndex: 'count',
        key: 'count',
      },
    ];
  };

  prepareData = (props) => {
    return props;
  };

  CardExtraForPallet = (props) => {
    const { selectedPalletBarcode } = props;
    return (
      <Button
        type="dashed"
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={() => {
          this.setState({
            modalGenerateQrVisibleForPallet: true,
            selectedPalletBarcode,
          });
          setTimeout(() => {
            this.generateQrForPallet(selectedPalletBarcode);
          });
        }}
      >
        {this.props.t(
          'pages.Stock.OutgoingTransfer.detail.button.showPalletQr',
        )}
      </Button>
    );
  };

  PalletPanel = (props) => {
    const { detail: transfer } = this.props;
    if (!transfer || !transfer.pallets || !transfer.pallets.length) {
      return null;
    }
    const pannelInner = [];
    transfer.pallets.forEach((pallet) => {
      const comp = (
        <Collapse.Panel
          header={`${pallet.palletBarcode}`}
          key={pallet.palletBarcode}
          extra={(
            <this.CardExtraForPallet
              selectedPalletBarcode={pallet.palletBarcode}
            />
          )}
        >
          <this.PalletTable pallet={pallet} />
        </Collapse.Panel>
      );
      pannelInner.push(comp);
    });
    return <Collapse>{pannelInner}</Collapse>;
  };

  PalletTable = (props) => {
    const { pallet } = props;
    return (
      <Table
        size="small"
        columns={this.preparePalletColumns(pallet)}
        dataSource={this.preparePalletData(pallet)}
        rowKey={(record) => record.id}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  preparePalletColumns = (props) => {
    return [
      {
        title: this.props.t('pages.Stock.OutgoingTransfer.detail.table.image'),
        dataIndex: 'picURL',
        key: 'picURL',
        width: 72,
        render: (picURL) => {
          return (
            <Avatar
              className="product-image"
              shape="square"
              src={getTranslation.obj(picURL)}
            />
          );
        },
      },
      {
        title: this.props.t(
          'pages.Stock.OutgoingTransfer.detail.table.fullName',
        ),
        dataIndex: 'fullName',
        key: 'fullName',
        render: (fullName) => getTranslation.obj(fullName),
      },
      {
        title: this.props.t(
          'pages.Stock.OutgoingTransfer.detail.table.collectedCount',
        ),
        dataIndex: 'collectedCount',
        key: 'collectedCount',
      },
      {
        title: this.props.t(
          'pages.Stock.OutgoingTransfer.detail.table.requiredCount',
        ),
        dataIndex: 'requiredCount',
        key: 'requiredCount',
      },
      {
        title: this.props.t('pages.Stock.OutgoingTransfer.detail.table.expiry'),
        dataIndex: 'expiry',
        key: 'expiry',
        render: (expiry) => {
          if (!expiry) {
            return '-';
          }
          return moment(new Date(expiry))
            .local()
            .format(getDateTimeFormatString());
        },
      },
    ];
  };

  preparePalletData = (pallet) => {
    if (!pallet || !pallet.items || !pallet.items.length) {
      return null;
    }
    return pallet.items.map((record) => {
      const product = this.getProductById(record.item);
      return {
        picURL: product.picURL,
        fullName: product.fullName,
        collectedCount: record.collectedCount,
        id: product.id,
        expiry: record.expiry,
        requiredCount: record.requiredCount,
      };
    });
  };

  getProductById = (id) => {
    const { detail: transfer } = this.props;
    if (!transfer || !transfer.items || !transfer.items.length) {
      return null;
    }
    return transfer.items.filter((record) => record.item.id === id)[0].item;
  };

  getDetail = () => {
    const { id, onGetOutgoingTransferDetail } = this.props;
    onGetOutgoingTransferDetail(id);
  };

  setContinue = () => {
    const { id, onSetOutgoingTransferContinue } = this.props;
    onSetOutgoingTransferContinue(id);
  };

  setShip = () => {
    const { id, onSetOutgoingTransferShip } = this.props;
    const { waybills } = this.state;
    return onSetOutgoingTransferShip(id, waybills)
      .then(() => {
        this.setState(this.stateFactory());
      })
      .catch(() => {
        this.setState(this.stateFactory());
      });
  };

  addTransferPallet = (palletBarcode) => {
    const { id, onAddOutgoingTransferPallet } = this.props;
    onAddOutgoingTransferPallet(id, palletBarcode);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  currentWarehouse: state.auth.currentWarehouse,
  loading: state.outgoingTransfer.detail.loading,
  detail: state.outgoingTransfer.detail.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetOutgoingTransferDetail: (id) => {
    dispatch(outgoingTransferAction.getOutgoingTransferDetail(id));
  },
  onSetOutgoingTransferContinue: (id) => {
    dispatch(outgoingTransferAction.setOutgoingTransferContinue(id));
  },
  onSetOutgoingTransferShip: (transferId, waybills) => {
    const errorMessage = i18n.t(
      'pages.Stock.OutgoingTransfer.detail.notification.anErrorOccurred',
    );
    return dispatch(
      outgoingTransferAction.setOutgoingTransferShip(
        transferId,
        waybills,
        errorMessage,
      ),
    );
  },
  onAddOutgoingTransferPallet: (transferId, palletBarcode) => {
    dispatch(
      outgoingTransferAction.addOutgoingTransferPallet(
        transferId,
        palletBarcode,
      ),
    );
  },
});

const OutgoingTransferDetailConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(OutgoingTransferDetail));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { OutgoingTransferDetailConnected as OutgoingTransferDetail };
