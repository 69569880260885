import { history } from './../history';
import { actionType } from './../actions';
import { LocalDatabase } from './../shared';

const invalidPickerToken = 33001;

const expiredPickerToken = 31613;

export const redirectMiddleware = () => (next) => (action) => {
  const { payload, type } = action;
  let code = null;
  try {
    code = payload.response.data.code;
  } catch (error) {
    code = null;
  }
  if (
    type === actionType.logout_Failed ||
    code === invalidPickerToken ||
    code === expiredPickerToken
  ) {
    LocalDatabase.clear();
    localStorage.clear();
    history.push('/login');
  }
  return next(action);
};
