/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Card, Table } from 'antd';
import './TransferPreviewPanel.scss';
import i18n from './../../../../../i18n';
/*----------------------------------------------------------------------------*/
import { EditableCell, EditableFormRow } from '../../../../../components';
/*----------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------
------------------------------------------------------------------------------*/

class TransferPreviewPanel extends Component {
  settings = {
    main: {
      className: 'TransferPreviewPanel_main',
    },
    card: {
      title: i18n.t(
        'pages.Operation.Transfer.create.TransferPreview.previewPageTitle',
      ),

      className: 'no-padding-card',
      size: 'small',
    },
  };

  components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    return {};
  };

  render() {
    const { previewData, columns } = this.props;
    if (!previewData || !previewData.length || !columns || !columns.length) {
      return null;
    }
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<div />}>
          <this.PageTable />
        </Card>
      </div>
    );
  }

  PageTable = (props) => {
    const dataColumns = this.prepareColumns(this.props.columns);

    return (
      <Table
        size="small"
        scroll={{
          x: dataColumns.length * 200,
          y: 500,
        }}
        bordered
        dataSource={this.prepareData()}
        columns={dataColumns}
        rowKey={(record) => record.direction}
        components={this.components}
        rowClassName={() => 'editable-row'}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  prepareColumns = (tableColumns) => {
    const transferDirection = {
      title: i18n.t(
        'pages.Operation.Transfer.create.TransferPreview.warehouse',
      ),
      dataIndex: 'direction',
      width: 200,
      fixed: 'left',
      render: (direction) => direction,
    };
    const productColumns = tableColumns.map((item) => ({
      title: item.name,
      dataIndex: `${item.productId}`,
      key: item.productId,
      editable: true,
      width: 200,
      align: 'left',
    }));
    const columns = [transferDirection, ...productColumns].map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          min: 0,
          handleCellSave: this.onPreviewProductListChange,
        }),
      };
    });
    return columns;
  };

  prepareData = () => {
    const { previewData } = this.props;
    return previewData;
  };

  onPreviewProductListChange = (row) => {
    const { previewData } = this.props;
    const preparedData = previewData.map((prepareItem) => {
      if (row.direction === prepareItem.direction) {
        return row;
      }
      return prepareItem;
    });
    this.props.onChange(preparedData);
  };

  clearState = () => {
    this.setState(this.stateFactory());
  };
}

const TransferPreviewPanelTranslated = TransferPreviewPanel;

export { TransferPreviewPanelTranslated as TransferPreviewPanel };
