import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form, Button, Row, Col,
} from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import {
  DateRangeSelect,
  IcScopeMultiSelect,
  IcStatusMultiSelect,
  IcTypeMultiSelect,
  LocationMultiSelect,
  ProductMultiSelect,
  WarehouseMultiSelect,
  JobIdInput,
  BooleanSwitch,
} from './filterFields';
/*----------------------------------------------------------------------------*/
import {
  warehouseAction,
  warehouseLocationAction,
  productAction,
} from './../../../../actions';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class InventoryCheckFilter extends Component {
  settings = {
    main: {
      className: 'InventoryCheckFilter_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    const {
      onGetWarehouseList,
      onGetProductList,
      onGetLocationList,
      CWId,
    } = this.props;
    onGetWarehouseList();
    onGetProductList();
    onGetLocationList(CWId);
  }

  stateFactory = () => ({
    ...this.emptyTableFilter(),
  });

  emptyTableFilter = () => ({
    startDate: this.props.defaultDateRange.startDate,
    endDate: this.props.defaultDateRange.endDate,
    scopes: [],
    statuses: [],
    types: [],
    locations: [],
    products: [],
    warehouses: [this.props.currentWarehouse],
    jobId: null,
    expiryDateRequired: false,
    expiryDateRequiredChecked: false,
    handover: false,
    handoverChecked: false,
    isAutoInventoryCheck: false,
    isAutoInventoryCheckChecked: false,
    isIncludedToSTS: false,
    isIncludedToSTSChecked: false,
  });

  isAutoInventoryCheckFilterTrue = () => (
    this.state.isAutoInventoryCheck && this.state.isAutoInventoryCheckChecked
  );

  render() {
    return (
      <div {...this.settings.main}>
        <this.TableFilterForm />
      </div>
    );
  }

  TableFilterForm = (props) => (
    <div>
      <Form>
        <Row>
          <Col span={6} style={{ padding: '0px 5px' }}>
            <this.DateRangeField />
          </Col>
          <Col span={5} style={{ padding: '0px 5px' }}>
            <this.IcScopeField />
          </Col>
          <Col span={7} style={{ padding: '0px 5px' }}>
            <this.IcTypeField />
          </Col>
          <Col span={6} style={{ padding: '0px 5px' }}>
            <this.IcStatusField />
          </Col>
        </Row>

        <Row>
          <Col span={11} style={{ padding: '0px 5px' }}>
            <this.WarehouseField />
          </Col>
          <Col span={7} style={{ padding: '0px 5px' }}>
            <this.JobIdField />
          </Col>
          <Col span={6} style={{ padding: '0px 5px' }}>
            <this.LocationField />
          </Col>
        </Row>

        <Row>
          <Col span={11} style={{ padding: '0px 5px' }}>
            <this.ProductField />
          </Col>
          <Col span={7} style={{ padding: '0px 5px' }}>
            <this.ExpiryDateRequiredSwitchField />
          </Col>
          <Col span={5} style={{ padding: '0px 5px' }}>
            <this.HandoverSwitchField />
          </Col>
        </Row>

        <Row>
          <Col span={6} style={{ padding: '0px 5px' }}>
            <this.IsAutoInventorySwitchField />
          </Col>
          <Col span={7} style={{ padding: '0px 5px' }}>
            <this.isIncludedToSTSSwitchField />
          </Col>
        </Row>

        <Row>
          <Col span={2} style={{ padding: '0px 5px' }}>
            <this.ClearFilterButton />
          </Col>
          <Col offset={20} span={2} style={{ padding: '0px 5px' }}>
            <this.FilterButton />
          </Col>
        </Row>
      </Form>
    </div>
  );

  DateRangeField = () => (
    <Form.Item>
      <DateRangeSelect
        defaultDateRange={this.props.defaultDateRange}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        onChange={(dateRange) => {
          this.setState({
            ...this.state,
            ...dateRange,
          });
        }}
      />
    </Form.Item>
  );

  WarehouseField = () => {
    const { warehouseList } = this.props;
    const { warehouses } = this.state;
    const list = (warehouseList && warehouseList.data) || null;
    return (
      <Form.Item>
        <WarehouseMultiSelect
          list={list}
          disabled
          onChange={(warehouses) => {
            this.setState({ warehouses }, this.getDependentFields);
          }}
          value={warehouses}
          loading={warehouseList.loading}
          placeholder={this.props.t('pages.Stock.InventoryCheck.list.filter.warehouse')}
        />
      </Form.Item>
    );
  };

  getDependentFields = () => {
    const { warehouses } = this.state;
    if (warehouses && warehouses.length && warehouses.length === 1) {
      const wid = warehouses[0].id;
      this.props.onGetLocationList(wid);
      return;
    }
    this.setState({
      ...this.state,
      locations: [],
    });
  };

  LocationField = () => {
    const { locationList } = this.props;
    const { locations } = this.state;
    const list = (locationList && locationList.data) || null;
    return (
      <Form.Item>
        <LocationMultiSelect
          placeholder={this.props.t('pages.Stock.InventoryCheck.list.filter.location')}
          list={list}
          disabled={!this.isSelectableField()}
          onChange={(locations) => {
            this.setState({ locations });
          }}
          value={locations}
          loading={locationList.loading}
        />
      </Form.Item>
    );
  };

  isSelectableField = () => {
    const { warehouses } = this.state;
    if (warehouses && warehouses.length && warehouses.length === 1) {
      return true;
    }
    return false;
  };

  ProductField = () => {
    const { productList } = this.props;
    const list = (productList && productList.data) || null;
    return (
      <Form.Item>
        <ProductMultiSelect
          placeholder={this.props.t('pages.Stock.InventoryCheck.list.filter.product')}
          list={list}
          onChange={(products) => {
            this.setState({ products });
          }}
          value={this.state.products}
          loading={productList.loading}
        />
      </Form.Item>
    );
  };

  IcStatusField = () => {
    const { statuses } = this.state;
    return (
      <Form.Item>
        <IcStatusMultiSelect
          onChange={(statuses) => {
            this.setState({ statuses });
          }}
          placeholder={this.props.t('pages.Stock.InventoryCheck.list.filter.status')}
          value={statuses}
        />
      </Form.Item>
    );
  };

  IcScopeField = () => {
    const { scopes } = this.state;
    return (
      <Form.Item>
        <IcScopeMultiSelect
          onChange={(scopes) => {
            this.setState({ scopes });
          }}
          disabled={this.isAutoInventoryCheckFilterTrue()}
          placeholder={this.props.t('pages.Stock.InventoryCheck.list.filter.scope')}
          value={scopes}
        />
      </Form.Item>
    );
  };

  IcTypeField = () => {
    const { types } = this.state;
    return (
      <Form.Item>
        <IcTypeMultiSelect
          onChange={(types) => {
            this.setState({ types });
          }}
          disabled={this.isAutoInventoryCheckFilterTrue()}
          placeholder={this.props.t('pages.Stock.InventoryCheck.list.filter.type')}
          value={types}
        />
      </Form.Item>
    );
  };

  JobIdField = () => {
    const { jobId } = this.state;
    return (
      <Form.Item>
        <JobIdInput
          placeholder={this.props.t('pages.Stock.InventoryCheck.list.filter.job')}
          disabled={false}
          onChange={(jobId) => {
            this.setState({ jobId: jobId.trim() });
          }}
          value={jobId}
        />
      </Form.Item>
    );
  };

  ExpiryDateRequiredSwitchField = () => (
    <Form.Item>
      <BooleanSwitch
        label={this.props.t('pages.Stock.InventoryCheck.list.filter.expiryDateRequired')}
        checkedChildren={this.props.t('pages.Stock.InventoryCheck.list.filter.enable')}
        unCheckedChildren={this.props.t('pages.Stock.InventoryCheck.list.filter.disable')}
        onChange={(expiryDateRequired) => {
          this.setState({ expiryDateRequired });
        }}
        onCheckChange={(expiryDateRequiredChecked) => {
          this.setState({ expiryDateRequiredChecked });
        }}
        value={this.state.expiryDateRequired}
        checked={this.state.expiryDateRequiredChecked}
      />
    </Form.Item>
  );

  HandoverSwitchField = () => (
    <Form.Item>
      <BooleanSwitch
        label={this.props.t('pages.Stock.InventoryCheck.list.filter.handover')}
        checkedChildren={this.props.t('pages.Stock.InventoryCheck.list.filter.enable')}
        unCheckedChildren={this.props.t('pages.Stock.InventoryCheck.list.filter.disable')}
        onChange={(handover) => {
          this.setState({ handover });
        }}
        onCheckChange={(handoverChecked) => {
          this.setState({ handoverChecked });
        }}
        value={this.state.handover}
        checked={this.state.handoverChecked}
      />
    </Form.Item>
  );

  isIncludedToSTSSwitchField = () => (
    <Form.Item>
      <BooleanSwitch
        label={this.props.t('pages.Stock.InventoryCheck.list.filter.isIncludedToSTS')}
        checkedChildren={this.props.t('pages.Stock.InventoryCheck.list.filter.enable')}
        unCheckedChildren={this.props.t('pages.Stock.InventoryCheck.list.filter.disable')}
        onChange={(isIncludedToSTS) => {
          this.setState({ isIncludedToSTS });
        }}
        onCheckChange={(isIncludedToSTSChecked) => {
          this.setState({ isIncludedToSTSChecked });
        }}
        value={this.state.isIncludedToSTS}
        checked={this.state.isIncludedToSTSChecked}
      />
    </Form.Item>
  );

  IsAutoInventorySwitchField = () => (
    <Form.Item>
      <BooleanSwitch
        label={this.props.t('pages.Stock.InventoryCheck.list.filter.isAutoInventoryCheck')}
        checkedChildren={this.props.t('pages.Stock.InventoryCheck.list.filter.enable')}
        unCheckedChildren={this.props.t('pages.Stock.InventoryCheck.list.filter.disable')}
        onChange={(isAutoInventoryCheck) => {
          this.setState({ isAutoInventoryCheck });
        }}
        onCheckChange={(isAutoInventoryCheckChecked) => {
          this.setState({ isAutoInventoryCheckChecked });
        }}
        value={this.state.isAutoInventoryCheck}
        checked={this.state.isAutoInventoryCheckChecked}
      />
    </Form.Item>
  );

  ClearFilterButton = () => (
    <Form.Item>
      <Button
        block
        onClick={() => {
          this.clearFilter();
        }}
      >
        {this.props.t('pages.Stock.InventoryCheck.list.filter.clean')}
      </Button>
    </Form.Item>
  );

  clearFilter = () => {
    this.setState(
      {
        ...this.emptyTableFilter(),
      },
      this.props.onClear,
    );
  };

  FilterButton = () => (
    <Form.Item>
      <Button
        type="primary"
        block
        onClick={() => {
          const filter = this.getTableFilter();
          this.filterChanged(filter);
        }}
      >
        {this.props.t('pages.Stock.InventoryCheck.list.filter.filter')}
      </Button>
    </Form.Item>
  );

  getTableFilter = () => {
    const {
      startDate,
      endDate,
      scopes,
      statuses,
      types,
      locations,
      products,
      warehouses,
      jobId,
      expiryDateRequired,
      expiryDateRequiredChecked,
      handover,
      handoverChecked,
      isAutoInventoryCheck,
      isAutoInventoryCheckChecked,
      isIncludedToSTS,
      isIncludedToSTSChecked,
    } = this.state;
    const filter = {};

    filter.createdAt = {
      startDate: startDate || this.props.defaultDateRange.startDate,
      endDate: endDate || this.props.defaultDateRange.endDate,
    };

    if (jobId) {
      filter.jobIds = [jobId];
    }

    if (warehouses && warehouses.length) {
      filter.warehouseIds = warehouses.map((w) => w.id);
    }
    if (statuses && statuses.length) {
      filter.statuses = statuses.map((statuse) => statuse.key);
    }

    if (scopes && scopes.length && !this.isAutoInventoryCheckFilterTrue()) {
      filter.scopes = scopes.map((scope) => scope.key);
    }
    if (types && types.length && !this.isAutoInventoryCheckFilterTrue()) {
      filter.types = types.map((type) => type.key);
    }
    if (products && products.length) {
      filter.productIds = products.map((product) => product.id);
    }

    if (locations && locations.length) {
      filter.locationIds = locations.map((location) => location.id);
    }

    if (expiryDateRequiredChecked) {
      filter.expiryDateRequired = expiryDateRequired;
    }

    if (handoverChecked) {
      filter.handover = handover;
    }

    if (isAutoInventoryCheckChecked) {
      filter.isAutoInventoryCheck = isAutoInventoryCheck;
    }

    if (isIncludedToSTSChecked) {
      filter.isIncludedToSTS = isIncludedToSTS;
    }

    return filter;
  };

  filterChanged = (filter) => {
    this.props.onChange(filter);
  };

  clearFilter = () => {
    this.setState({ ...this.state, ...this.stateFactory() }, () => {
      this.props.onClear(this.getTableFilter());
    });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse.id,
  currentWarehouse: state.auth.currentWarehouse,
  locationList: state.warehouseLocation.warehouseLocationList,
  warehouseList: state.warehouse.warehouseList,
  productList: state.product.productList,
});

const mapDispatchToProps = (dispatch) => ({
  onGetWarehouseList: () => {
    dispatch(warehouseAction.getWarehouseList());
  },
  onGetLocationList: (warehouseId) => {
    dispatch(warehouseLocationAction.filterWarehouseLocations({ warehouseId }));
  },
  onGetProductList: () => {
    dispatch(
      productAction.filterProductList({
        fields: 'barcodes picURL fullName packagingInfo',
        includeDefaultFields: false,
      }),
    );
  },
});

const InventoryCheckFilterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(InventoryCheckFilter));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { InventoryCheckFilterConnected as InventoryCheckFilter };
