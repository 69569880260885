import React, { Component } from 'react';
import { Avatar } from 'antd';
// --------------------------------------------------
import '../BalloonContent.scss';
// --------------------------------------------------
import { withNamespaces } from 'react-i18next';
import { fromNow, statuses } from './../../../shared';
// --------------------------------------------------

class CourierBalloonContent extends Component {
  render() {
    const { courier } = this.props;
    const { fleetVehicleType, courierStatus } = statuses;
    return (
      <>
        <div>
          <div className="avatarContainer">
            <Avatar aria-haspopup src={courier.picURL} variant="square" />
            <div className="nameContainer">
              <span>{courier.name}</span>
              <span>{courier.gsm}</span>
            </div>
          </div>
          <div>
            <strong>{this.props.t('components.BalloonContent.status')}</strong>
            {': '}
            {courierStatus[courier.status]
              ? this.props.t(courierStatus[courier.status])
              : '-'}
            {` (${fromNow({
              time: courier.statusLastChangedAt,
              shouldAddSecond: true,
            })})`}
          </div>
          {courier.fleetVehicleType && (
            <div>
              <strong>
                {this.props.t('components.BalloonContent.vehicle')}
              </strong>
              {': '}
              {this.props.t(fleetVehicleType[courier.fleetVehicleType])}
              {` (${courier.fleetVehiclePlate})`}
            </div>
          )}
        </div>
      </>
    );
  }
}

const CourierBalloonContentTranslated = withNamespaces('translation')(
  CourierBalloonContent,
);

export { CourierBalloonContentTranslated as CourierBalloonContent };
