import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Row, Col } from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import { ProductMultiSelect, IcTaskStatusMultiSelect } from './filterFields';
/*----------------------------------------------------------------------------*/
import { productAction } from './../../../../actions';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class InventoryCheckDetailFilter extends Component {
  settings = {
    main: {
      className: 'InventoryCheckDetailFilter_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    const { onGetProductList } = this.props;
    onGetProductList();
  }

  stateFactory = () => {
    return {
      ...this.emptyTableFilter(),
    };
  };

  emptyTableFilter = () => {
    return {
      statuses: [],
      products: [],
    };
  };

  render() {
    return (
      <div {...this.settings.main}>
        <this.TableFilterForm />
      </div>
    );
  }

  TableFilterForm = (props) => (
    <div>
      <Form>
        <Row>
          <Col span={12} style={{ padding: '0px 5px' }}>
            <this.StatusField />
          </Col>
          <Col span={12} style={{ padding: '0px 5px' }}>
            <this.ProductField />
          </Col>
        </Row>

        <Row>
          <Col span={2} style={{ padding: '0px 5px' }}>
            <this.ClearFilterButton />
          </Col>
          <Col offset={20} span={2} style={{ padding: '0px 5px' }}>
            <this.FilterButton />
          </Col>
        </Row>
      </Form>
    </div>
  );

  ProductField = () => {
    const { productList } = this.props;
    const list = (productList && productList.data) || null;
    return (
      <Form.Item>
        <ProductMultiSelect
          placeholder={this.props.t(
            'pages.Operation.InventoryCheck.detail.filter.product',
          )}
          list={list}
          onChange={(products) => {
            this.setState({ products });
          }}
          value={this.state.products}
          loading={productList.loading}
        />
      </Form.Item>
    );
  };

  StatusField = () => {
    const { statuses } = this.state;
    return (
      <Form.Item>
        <IcTaskStatusMultiSelect
          onChange={(statuses) => {
            this.setState({ statuses });
          }}
          placeholder={this.props.t(
            'pages.Operation.InventoryCheck.detail.filter.status',
          )}
          value={statuses}
        />
      </Form.Item>
    );
  };

  ClearFilterButton = () => {
    return (
      <Form.Item>
        <Button
          block
          onClick={() => {
            this.clearFilter();
          }}
        >
          {this.props.t('pages.Operation.InventoryCheck.detail.filter.clean')}
        </Button>
      </Form.Item>
    );
  };

  clearFilter = () => {
    this.setState(
      {
        ...this.emptyTableFilter(),
      },
      this.props.onClear,
    );
  };

  FilterButton = () => {
    return (
      <Form.Item>
        <Button
          type="primary"
          block
          onClick={() => {
            const filter = this.getTableFilter();
            this.filterChanged(filter);
          }}
        >
          {this.props.t('pages.Operation.InventoryCheck.detail.filter.filter')}
        </Button>
      </Form.Item>
    );
  };

  getTableFilter = () => {
    const { statuses, products } = this.state;
    const filter = {};

    if (statuses && statuses.length) {
      filter.statuses = statuses.map((statuse) => statuse.key);
    }

    if (products && products.length) {
      filter.productIds = products.map((product) => product.id);
    }

    return filter;
  };

  filterChanged = (filter) => {
    this.props.onChange(filter);
  };

  clearFilter = () => {
    this.setState({ ...this.state, ...this.stateFactory() }, () => {
      this.props.onClear(this.getTableFilter());
    });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse.id,
  currentWarehouse: state.auth.currentWarehouse,
  productList: state.product.productList,
});

const mapDispatchToProps = (dispatch) => ({
  onGetProductList: () => {
    dispatch(
      productAction.filterProductList({
        fields: 'barcodes picURL fullName packagingInfo',
        includeDefaultFields: false,
      }),
    );
  },
});

const InventoryCheckDetailFilterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(InventoryCheckDetailFilter));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { InventoryCheckDetailFilterConnected as InventoryCheckDetailFilter };
