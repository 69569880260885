import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Button,
  Row,
  Col,
  Dropdown,
  Icon,
  Menu,
  notification,
} from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import './TransferCreate.scss';
import { TransferInfoPanel } from './TransferInfo';
import { TransferProductListPanel } from './TransferProductList';
import { TransferPreviewPanel } from './TransferPreview';
import { TransferDataFormatter } from './TransferDataFormatter';

/*----------------------------------------------------------------------------*/
import { ImportCsv, Spinner } from './../../../../components';
import {
  transferAction,
  warehouseAction,
  productAction,
} from './../../../../actions';
import i18n from '../../../../i18n';
import { pageLoadEvents } from '../../../../services/events';
import { pageView, track } from '../../../../services/segment';
import { CreateTransferEvents } from './track';
import { constants } from '../../../../shared';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class TransferCreate extends Component {
  settings = {
    main: {
      className: 'TransferCreate_main',
    },
    card: {
      className: 'no-padding-card',
      title: this.props.t('pages.Operation.Transfer.create.title'),
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
    this.infoRef = React.createRef();
    this.productListRef = React.createRef();
    this.previewRef = React.createRef();
  }

  stateFactory = () => ({
    info: null,
    itemList: [],
    previewData: null,
    previewColumns: null,
    imported: false,
    isClickedCreateTransferButton: false,
  });

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_TRANSFER_CREATE });
    const { onGetWarehouseList, onGetProductList } = this.props;
    onGetWarehouseList();
    onGetProductList();
  }

  render() {
    const { warehouseLoading } = this.props;
    if (warehouseLoading) {
      return <Spinner />;
    }
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageLayout />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => (
    <Dropdown overlay={this.MenuAction}>
      <Button style={{ display: 'flex', alignItems: 'center' }}>
        {this.props.t('pages.Operation.Transfer.create.button.actions')}{' '}
        <Icon type="down" />
      </Button>
    </Dropdown>
  );

  MenuAction = (props) => (
    <Menu>
      <Menu.Item key="ImportFromCsvButton">
        <this.ImportFromCsvButton />
      </Menu.Item>
      <Menu.Item key="ClearButton" onClick={() => track(CreateTransferEvents.OPS_TRANSFER_CLEAR)}>
        <this.ClearButton />
      </Menu.Item>
      <Menu.Item key="CreateButton">
        <this.CreateButton />
      </Menu.Item>
    </Menu>
  );

  ImportFromCsvButton = (props) => (
    <ImportCsv
      block
      transformData={this.getPreparedCsvPreviewData}
      disabled={this.isImportButtonDisabled()}
    />
  );

  ClearButton = (props) => (
    <Button block onClick={this.clearState}>
      {this.props.t('pages.Operation.Transfer.create.button.clean')}
    </Button>
  );

  CreateButton = (props) => (
    <Button
      type="danger"
      block
      onClick={this.createTransfer}
      loading={this.state.isTransferLoading || this.state.isEquipmentTransferLoading}
      disabled={this.isCreateButtonDisabled()}
    >
      {this.props.t('pages.Operation.Transfer.create.button.save')}
    </Button>
  );

  PageLayout = (props) => (
    <div>
      <Row>
        <Col span={7}>
          <this.TransferInfo />
        </Col>
        <Col span={17}>
          <this.TransferProductList />
        </Col>
      </Row>
      <Row>
        <this.TransferPreview />
      </Row>
    </div>
  );

  TransferInfo = (props) => {
    const { warehouseList } = this.props;
    const { imported } = this.state;
    return (
      <TransferInfoPanel
        ref={this.infoRef}
        warehouseList={warehouseList}
        onChange={this.onTransferInfoChange}
        imported={imported}
      />
    );
  };

  TransferProductList = (props) => {
    const { productList, productLoading } = this.props;
    return (
      <TransferProductListPanel
        ref={this.productListRef}
        productList={productList.data}
        loading={productLoading}
        onChange={this.onTransferProductListChange}
        disabled={this.isProductCountDisabled()}
      />
    );
  };

  TransferPreview = (props) => {
    const { previewData, previewColumns } = this.state;
    return (
      <TransferPreviewPanel
        ref={this.previewRef}
        previewData={previewData}
        columns={previewColumns}
        onChange={this.onTransferPreviewChange}
      />
    );
  };

  onTransferInfoChange = (info) => {
    const { imported } = this.state;
    this.setState({ info }, () => {
      if (imported) {
        return;
      }
      if (!this.isPreviewDisabled()) {
        this.getPreparedPreviewData();
      } else {
        this.clearPreviewPanel();
      }
    });
  };

  onTransferProductListChange = (itemList) => {
    this.setState({ itemList });
    if (itemList && itemList.length) {
      this.getPreparedPreviewData();
    } else {
      this.clearPreviewPanel();
    }
  };

  onTransferPreviewChange = (previewData) => {
    this.setState({ previewData });
  };

  getPreparedCsvPreviewData = (data) => {
    const {
      productList: { data: productListData },
      warehouseList: { data: warehouseListData },
    } = this.props;

    try {
      const warehouseList = [...warehouseListData];
      const {
        columns,
        preparedCsvPreviewData,
      } = TransferDataFormatter.preparedCsvPreviewData(
        warehouseList,
        productListData,
        data,
      );

      notification.success({
        duration: 3,
        message: this.props.t(
          'pages.Operation.Transfer.create.notification.csvİmported',
        ),
      });

      track(CreateTransferEvents.OPS_TRANSFER_IMPORT_CSV_SUCCESS);

      this.setState(
        {
          ...this.state,
          info: {
            ...this.state.info,
            fromWarehouses: [],
            toWarehouses: [],
          },
          itemList: [],
          previewColumns: columns,
          previewData: preparedCsvPreviewData,
          imported: true,
        },
        () => {
          this.infoRef.current.clearDirectionsState();
          this.productListRef.current.clearState();
        },
      );
    } catch (error) {
      notification.error({
        duration: 3,
        message: this.props.t(
          'pages.Operation.Transfer.create.notification.csvFileHasInvalidRecords',
        ),
      });
    }
  };

  getPreparedPreviewData = () => {
    const {
      info: { fromWarehouses, toWarehouses },
      itemList: productListData,
    } = this.state;
    const {
      columns,
      preparedPreviewData,
    } = TransferDataFormatter.preparedPreviewData(
      fromWarehouses,
      toWarehouses,
      productListData,
    );

    this.setState({
      ...this.state,
      previewColumns: columns,
      previewData: preparedPreviewData,
    });
  };

  clearState = () => {
    this.infoRef.current.clearState();
    this.productListRef.current.clearState();
    this.setState(this.stateFactory(), this.clearPreviewPanel);
  };

  clearPreviewPanel = () => {
    this.setState({
      ...this.state,
      previewColumns: null,
      previewData: null,
    });
    if (this.previewRef.current) {
      this.previewRef.current.clearState();
    }
  };

  createTransfer = async () => {
    const {
      info: { transferType },
      previewData,
    } = this.state;

    const {
      warehouseList: { data: warehouseListData },
    } = this.props;

    const warehouseList = [...warehouseListData];

    const preparedTransferData = TransferDataFormatter.prepareTransferData(
      transferType,
      previewData,
      warehouseList,
    );

    const { CWId } = this.props.CWId;
    console.log("preparedTransferData", preparedTransferData);
    if (Number(transferType.key) === constants.INTEGRATION_TYPE.EQUIPMENT) {
      const transfer = preparedTransferData[0] && preparedTransferData[0].transfer;
      if (!transfer) { return; }
      const payload = {
        toWarehouse: transfer.toWarehouse,
        fromWarehouse: transfer.fromWarehouse,
        items: transfer.items,
        redirectUrl: `/stock-operation/transfer/list`,
      };
      this.props.createEquipmentTransfer(payload);
    } else {
    this.props.onCreateTransfer(preparedTransferData, CWId);
    }

    setTimeout(track(CreateTransferEvents.OPS_TRANSFER_CREATE_NEW, {
      transferData: preparedTransferData,
    }));
  };

  isImportButtonDisabled = () => {
    const { warehouseLoading, productLoading } = this.props;
    const { info } = this.state;

    const noInfo = !info;

    return noInfo || warehouseLoading || productLoading;
  };

  isCreateButtonDisabled = () => {
    const { info, previewData, isClickedCreateTransferButton } = this.state;
    return (
      !info ||
      !info.transferType ||
      !previewData ||
      isClickedCreateTransferButton
    );
  };

  isPreviewDisabled = () => {
    const { info, itemList } = this.state;
    return (
      !info ||
      !info.transferType ||
      !info.fromWarehouses ||
      !info.fromWarehouses.length ||
      !info.toWarehouses ||
      !info.toWarehouses.length ||
      !itemList ||
      !itemList.length
    );
  };

  isProductCountDisabled = () => {
    const { info, imported } = this.state;
    return (
      !info ||
      !info.transferType ||
      !info.fromWarehouses ||
      !info.fromWarehouses.length ||
      !info.toWarehouses ||
      !info.toWarehouses.length ||
      imported
    );
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  currentWarehouseId: state.auth.currentWarehouse._id,
  productList: state.product.productList,
  warehouseList: state.warehouse.warehouseList,
  warehouseLoading: state.warehouse.warehouseList.loading,
  productLoading: state.product.productList.loading,
  CWId: state.auth.currentWarehouse._id,
  isTransferLoading: state.transfer.transferCreate.loading,
  isEquipmentTransferLoading: state.transfer.createEquipmentStatus.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onGetProductList: () => {
    dispatch(
      productAction.filterProductList({
        fields: 'barcodes picURL fullName packagingInfo isEquipment',
        includeDefaultFields: false,
      }),
    );
  },
  onGetWarehouseList: () => {
    dispatch(warehouseAction.getWarehouseList());
  },
  onCreateTransfer: (data, CWId) => {
    dispatch(
      transferAction.createTransfer(
        data,
        CWId,
        i18n.t(
          'pages.Operation.Transfer.create.notification.errorMessageForCreateTransfer',
        ),
      ),
    );
  },
  createEquipmentTransfer: (transfer) => {
    dispatch(
      transferAction.createEquipmentTransfer(transfer),
    );
  },
});

const TransferCreateConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(TransferCreate));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { TransferCreateConnected as TransferCreate };
