import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Card, Table, Popconfirm, Avatar } from 'antd';
import moment from 'moment';
// --------------------------------------------------
import './CancelledOrders.scss';
// --------------------------------------------------
import { getTranslation } from './../../../shared';
import { cancelledOrdersAction } from '../../../actions';
import { Spinner, CopyToClipboard, YesNoTag } from '../../../components';
import { pageView, track } from '../../../services/segment';
import { pageLoadEvents } from '../../../services/events';
// --------------------------------------------------

class CancelledOrders extends Component {
  settings = {
    main: {
      className: 'CancelledOrders_main',
    },
    card: {
      title: this.props.t('pages.Stock.CancelledOrders.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  dateFormat = 'YYYY-MM-DD HH:mm:ss';

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    const state = {
      isMoveToStockDisabled: false,
    };
    return state;
  };

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.HOME_CANCEL_ORDER });
    this.props.onGetCancelledOrders();
  }

  PageTable = (props) => (
    <div>
      <Table
        size="small"
        columns={this.prepareColumns()}
        dataSource={this.prepareData()}
        expandedRowRender={this.expandedRowRender}
        rowKey={(record) => record.id}
        pagination={false}
        loading={{ indicator: <Spinner />, spinning: this.props.loading }}
        onExpand={track('HOME_CANCEL_ORDER_TABLE_ROW_EXPAND')}
      />
    </div>
  );

  prepareColumns = () => {
    const columns = [
      {
        title: this.props.t('pages.Stock.CancelledOrders.table.clientName'),
        dataIndex: 'clientName',
      },
      {
        title: this.props.t('pages.Stock.CancelledOrders.table.courierName'),
        dataIndex: 'courierName',
      },
      {
        title: this.props.t('pages.Stock.CancelledOrders.table.pickerName'),
        dataIndex: 'pickerName',
      },
      {
        title: this.props.t('pages.Stock.CancelledOrders.table.checkoutDate'),
        dataIndex: 'checkoutDate',
        render: (checkoutDate) =>
          moment(checkoutDate).isValid()
            ? moment(new Date(checkoutDate))
              .local()
              .format(this.dateFormat)
            : '-',
      },
      {
        title: this.props.t(
          'pages.Stock.CancelledOrders.table.courierReceived',
        ),
        dataIndex: 'isDeparted',
        render: (isDeparted) => <YesNoTag flag={isDeparted} />,
      },
      {
        title: this.props.t('pages.Stock.CancelledOrders.table.itemCount'),
        dataIndex: 'itemCount',
      },
      {
        title: this.props.t('pages.Stock.CancelledOrders.table.moveToStock'),
        dataIndex: 'id',
        render: (id) => <this.MoveToStockButton id={id} />,
      },
    ];

    return columns;
  };

  prepareData = () => {
    const { cancelledOrders } = this.props;
    if (!cancelledOrders || !cancelledOrders.length) {
      return [];
    }
    return cancelledOrders.map((order) => ({
      clientName: order.client.clientName || '-',
      courierName: order.courier.name || '-',
      pickerName: order.picker.name || '-',
      checkoutDate: new Date(order.createdAt),
      itemCount: order.products.reduce((acc, product) => {
        // eslint-disable-next-line no-param-reassign
        acc += product.totalCount;
        return acc;
      }, 0),
      id: order.id,
      products: order.products,
      isDeparted: order.isDeparted,
    }));
  };

  expandedRowRender = (record, index, indent, expanded) => (
    <Table
      size="small"
      columns={this.prepareExtendedColumns(record)}
      dataSource={this.prepareExtendedData(record)}
      rowKey={(record) => record.id}
      pagination={false}
    />
  );

  prepareExtendedData = (record) => {
    if (!record || !record.products || !record.products.length) {
      return null;
    }
    return record.products.map((product) => ({
      productName: product.fullName,
      productImage: product.picURL,
      count: product.totalCount,
      pickedCount: product.pickedCount,
      id: product.id,
    }));
  };

  prepareExtendedColumns = (record) => {
    const columns = [
      {
        title: this.props.t(
          'pages.Stock.CancelledOrders.expandedTable.productImage',
        ),
        dataIndex: 'productImage',
        width: 72,
        render: (picURL) => (
          <Avatar
            className="grow"
            shape="square"
            src={getTranslation.obj(picURL)}
          />
        ),
      },
      {
        title: this.props.t(
          'pages.Stock.CancelledOrders.expandedTable.productName',
        ),
        dataIndex: 'productName',
        render: (productName, record) => (
          <CopyToClipboard message={record.id} inner={productName} />
        ),
      },
      {
        title: this.props.t(
          'pages.Stock.CancelledOrders.expandedTable.pickedCount',
        ),
        dataIndex: 'pickedCount',
        width: 80,
      },
      {
        title: this.props.t('pages.Stock.CancelledOrders.expandedTable.count'),
        dataIndex: 'count',
        width: 80,
      },
    ];

    return columns;
  };

  MoveToStockButton = (props) => (
    <Popconfirm
      title={this.props.t('pages.Stock.CancelledOrders.confirmMessage')}
      onConfirm={() => this.confirmMoveToStock(props.id)}
    >
      <Button size="small" disabled={this.state.isMoveToStockDisabled}>
        {this.props.t('pages.Stock.CancelledOrders.moveToStockButton')}
      </Button>
    </Popconfirm>
  );

  confirmMoveToStock = (id) => {
    this.setState({ isMoveToStockDisabled: true });
    track('HOME_CANCEL_ORDER_MOVE_TO_STOCK_CLICKED');
    this.props
      .onMoveToStockCancelledOrder(id)
      .then(() => {
        this.setState({ isMoveToStockDisabled: false });
      })
      .catch(() => {
        this.setState({ isMoveToStockDisabled: false });
      });
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card}>
          <this.PageTable />
        </Card>
      </div>
    );
  }
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  cancelledOrders: state.cancelledOrders.list.data,
  loading: state.cancelledOrders.list.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCancelledOrders: () =>
    dispatch(cancelledOrdersAction.getCancelledOrders()),
  onMoveToStockCancelledOrder: (id) =>
    dispatch(cancelledOrdersAction.completeCancelledOrder(id)),
});

const CancelledOrdersConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(CancelledOrders));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { CancelledOrdersConnected as CancelledOrders };
