import React, { Component } from 'react';
import { Modal, Select, Input, Avatar, Table } from 'antd';
// --------------------------------------------------
import { withNamespaces } from 'react-i18next';
import { CourierCard } from './CourierCard';
// --------------------------------------------------
import { constants, getTranslation } from './../../../shared';
// --------------------------------------------------

const { TextArea } = Input;
const Option = Select.Option;
const { COURIER_STATUS } = constants;

class CourierList extends Component {
  state = {
    visible: false,
    courier: null,
    selectedStatus: null,
    busyReason: null,
    busyReasonComment: '',
    visibleStockModal: false,
    courierStock: null,
  };

  busyReasonOtherId = '555a25f9b4ff93f311ffe0fd';

  getDefaultStatusForChange = (courier) => {
    let defaultStatus = COURIER_STATUS.BUSY;
    if (!courier || courier.status === COURIER_STATUS.FREE) {
      return defaultStatus;
    }
    if (courier.status === COURIER_STATUS.BUSY) {
      defaultStatus = COURIER_STATUS.FREE;
    }
    return defaultStatus;
  };

  openModal = (courier) => {
    this.setState({
      courier,
      visible: true,
      selectedStatus: this.getDefaultStatusForChange(courier),
      busyReason: null,
      busyReasonComment: '',
    });
  };

  handleOk = (e) => {
    const {
      selectedStatus,
      courier,
      busyReason,
      busyReasonComment,
    } = this.state;
    const { makeCourierBusy, makeCourierFree } = this.props;
    if (
      selectedStatus === COURIER_STATUS.BUSY &&
      courier.status !== COURIER_STATUS.BUSY
    ) {
      makeCourierBusy(courier._id, {
        reason: busyReason,
        comment: busyReasonComment || '',
      });
    }
    if (
      selectedStatus === COURIER_STATUS.FREE &&
      courier.status !== COURIER_STATUS.FREE
    ) {
      makeCourierFree(courier._id);
    }
    this.setState({
      visible: false,
      courier: null,
      selectedStatus: null,
      busyReason: null,
      busyReasonComment: '',
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      courier: null,
      selectedStatus: null,
      busyReason: null,
      busyReasonComment: '',
    });
  };

  getCouriersWithAvailableStock = () => {
    const { couriers, availableStockOfLocations } = this.props;
    if (!couriers || !couriers.length) {
      return null;
    }
    if (!availableStockOfLocations || !availableStockOfLocations.length) {
      return couriers;
    }
    const courierWithStock = couriers.map((courier) => {
      let availableStock = null;
      let carboyCount = null;
      try {
        availableStock = availableStockOfLocations.find(
          (stock) => stock.code === courier.fleetVehiclePlate,
        ).products;
        carboyCount = availableStock.reduce((count, stock) => {
          if (stock.product.id === constants.KUZEYDEN_CARBOY_STOCK_PRODUCT_ID) {
            return count + stock.quantity;
          }
          return count;
        }, 0);
      } catch (error) {
        availableStock = null;
      }

      return {
        ...courier,
        availableStock,
        carboyCount,
      };
    });
    return courierWithStock;
  };

  StockModal = (props) => (
    <Modal
      title={this.props.t('pages.Home.carboyStockModal.title')}
      footer={null}
      visible={this.state.visibleStockModal}
      onOk={() => {
        this.setState((state) => ({
          ...state,
          visibleStockModal: false,
          courierStock: null,
          courier: null,
        }));
      }}
      onCancel={() => {
        this.setState((state) => ({
          ...state,
          visibleStockModal: false,
          courierStock: null,
          courier: null,
        }));
      }}
    >
      {props.courierStock ? (
        <this.CourierStockTable courierStock={props.courierStock} />
      ) : null}
      <br />
      {props.courier ? (
        <div>
          Tel:
          {props.courier.gsm}
        </div>
      ) : null}
    </Modal>
  );

  CourierStockTable = (props) => {
    const dataSource = props.courierStock.map((stock) => ({
      picURL: stock.product.picURL,
      key: stock.product.fullName,
      fullName: stock.product.fullName,
      quantity: stock.quantity,
    }));
    const columns = [
      {
        dataIndex: 'picURL',
        key: 'picURL',
        render: (picUrl) => (
          <Avatar className="grow" shape="square" src={picUrl} />
        ),
      },
      {
        dataIndex: 'fullName',
        key: 'fullName',
      },
      {
        dataIndex: 'quantity',
        key: 'quantity',
      },
    ];
    return (
      <Table
        dataSource={dataSource}
        columns={columns}
        bordered
        pagination={false}
        showHeader={false}
        size="small"
      />
    );
  };

  render() {
    const { selectedFilters, courierBusyReasons, t } = this.props;
    const couriers = this.getCouriersWithAvailableStock();
    if (!couriers) {
      return null;
    }
    let sortedList = couriers;
    if (this.props.isWaterWarehouse) {
      sortedList = sortedList.sort((c1, c2) => c1.carboyCount - c2.carboyCount);
    } else {
      sortedList = sortedList.sort((c1, c2) => c2.status - c1.status);
    }
    const list = sortedList.map((item) => {
      if (!selectedFilters || !selectedFilters.length) {
        return (
          <CourierCard
            timer={this.props.timer}
            courier={item}
            key={item._id}
            openModal={this.openModal}
            isWaterWarehouse={this.props.isWaterWarehouse}
            courierBusyReasons={courierBusyReasons}
            makeVisibleStockModal={(courierStock, courier) => {
              this.setState((state) => ({
                ...state,
                visibleStockModal: true,
                courierStock,
                courier,
              }));
            }}
          />
        );
      }
      if (selectedFilters.includes(item.status.toString())) {
        return (
          <CourierCard
            timer={this.props.timer}
            courier={item}
            key={item._id}
            openModal={this.openModal}
            isWaterWarehouse={this.props.isWaterWarehouse}
            courierBusyReasons={courierBusyReasons}
            makeVisibleStockModal={(courierStock, courier) => {
              this.setState((state) => ({
                ...state,
                visibleStockModal: true,
                courierStock,
                courier,
              }));
            }}
          />
        );
      }
      return null;
    });

    const statusOptions = [
      <Option value={COURIER_STATUS.BUSY} key={COURIER_STATUS.BUSY}>
        {getTranslation.courierStatus(COURIER_STATUS.BUSY)}
      </Option>,
      <Option value={COURIER_STATUS.FREE} key={COURIER_STATUS.FREE}>
        {getTranslation.courierStatus(COURIER_STATUS.FREE)}
      </Option>,
    ];
    let busyReasonOptions = null;
    if (courierBusyReasons && courierBusyReasons.data) {
      busyReasonOptions = courierBusyReasons.data.map((reason) => (
        <Option value={reason._id} key={reason._id} rawvalue={reason}>
          {getTranslation.obj(reason.messages)}
        </Option>
      ));
    }

    return (
      <div className="CourierList_inner">
        {this.props.isWaterWarehouse ? (
          <this.StockModal
            courierStock={this.state.courierStock}
            courier={this.state.courier}
          />
        ) : null}
        <Modal
          destroyOnClose
          title={t('pages.Home.courierList.changeCourierStatus')}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="modal-courier-status"
          okButtonProps={{ disabled: this.isChangeStatusButtonDisabled() }}
        >
          <div style={{ marginBottom: 10 }}>
            <span>{t('pages.Home.courierList.newStatus')}</span>{' '}
            <Select
              style={{ width: '100%' }}
              labelInValue
              defaultValue={{ key: this.state.selectedStatus }}
              onChange={(value) => {
                this.setState((state) => ({
                  ...state,
                  selectedStatus: value.key,
                  busyReason: null,
                  busyReasonComment: null,
                }));
              }}
            >
              {statusOptions}
            </Select>
          </div>
          {this.state.selectedStatus === COURIER_STATUS.BUSY ? (
            <div style={{ marginBottom: 10 }}>
              <span>{t('pages.Home.courierList.reason')}</span>{' '}
              <Select
                labelInValue
                style={{ width: '100%' }}
                onChange={(value, options) => {
                  this.setState((state) => ({
                    ...state,
                    busyReason: value.key,
                    busyReasonComment: null,
                  }));
                }}
              >
                {busyReasonOptions}
              </Select>
            </div>
          ) : null}
          {this.state.busyReason === this.busyReasonOtherId ? (
            <div>
              <span>{t('pages.Home.courierList.comment')}</span>
              <TextArea
                autosize={{ minRows: 2, maxRows: 6 }}
                style={{ width: '100%' }}
                onChange={(event) => {
                  this.setState({
                    busyReasonComment: event.target.value,
                  });
                }}
              />
            </div>
          ) : null}
        </Modal>
        {list}
      </div>
    );
  }

  isChangeStatusButtonDisabled = () => {
    const { selectedStatus, busyReason } = this.state;
    if (
      (selectedStatus === COURIER_STATUS.BUSY && busyReason) ||
      selectedStatus === COURIER_STATUS.FREE
    ) {
      return false;
    }
    return true;
  };
}

const CourierListConnected = withNamespaces('translation')(CourierList);

export { CourierListConnected as CourierList };
