import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import {
  Button,
  Card,
  Table,
  Avatar,
  Modal,
  Input,
  Row,
  Col,
  Form,
} from 'antd';
import moment from 'moment';
import './MissingProductsOrders.scss';
import { missingProductsOrdersAction } from '../../../actions';
import { getTranslation } from './../../../shared';
import { Spinner, CopyToClipboard } from '../../../components';

import { constants } from './../../../shared/constants';
import { pageView, track } from '../../../services/segment';
import { pageLoadEvents } from '../../../services/events';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const formItemLayout = {
  style: {
    marginTop: 0,
    marginBottom: 0,
  },
};
class MissingProductsOrders extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.HOME_ORDER_MISSING_PRODUCTS });
    this.props.onGetMissingProductsOrders();
  }

  settings = {
    main: {
      className: 'missingProductsOrders_main',
    },
    card: {
      title: this.props.t('pages.Stock.MissingProductsOrders.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  stateFactory = () => {
    const state = {
      isCancelMissingProductsModalOpen: false,
      missingProductCount: undefined,
    };
    return state;
  };

  prepareColumns = () => {
    const columns = [
      {
        title: this.props.t(
          'pages.Stock.MissingProductsOrders.table.productImage',
        ),
        dataIndex: 'productImage',
        width: 72,
        render: (picURL) => (
          <Avatar
            className="grow"
            shape="square"
            src={getTranslation.obj(picURL)}
          />
        ),
      },
      {
        title: this.props.t(
          'pages.Stock.MissingProductsOrders.table.productName',
        ),
        dataIndex: 'productName',
        render: (productName, record) => (
          <CopyToClipboard message={record.productId} inner={productName} />
        ),
      },
      {
        title: this.props.t(
          'pages.Stock.MissingProductsOrders.table.dateOfOrder',
        ),
        dataIndex: 'dateOfOrder',
        render: (dateOfOrder) => {
          return moment(dateOfOrder).isValid()
            ? moment(new Date(dateOfOrder))
                .local()
                .format(dateFormat)
            : '-';
        },
      },
      {
        title: this.props.t('pages.Stock.MissingProductsOrders.table.status'),
        dataIndex: 'status',
        render: (status) => getTranslation.removedFromSalesInOrders(status),
      },
      {
        title: this.props.t(
          'pages.Stock.MissingProductsOrders.table.removedFromSalesProductsCount',
        ),
        dataIndex: 'removedFromSalesProductsCount',
        render: (productcount) => {
          return Number.isInteger(productcount) ? productcount : '-';
        },
      },
      {
        title: this.props.t(
          'pages.Stock.MissingProductsOrders.table.pickerName',
        ),
        dataIndex: 'pickerName',
        render: (name) => name || '-',
      },
      {
        title: this.props.t(
          'pages.Stock.MissingProductsOrders.table.processedBy',
        ),
        dataIndex: ['concludedBy', 'name'],
        render: (name) => name || '-',
      },
      {
        title: this.props.t(
          'pages.Stock.MissingProductsOrders.table.lastActionDate',
        ),
        dataIndex: 'updatedAt',
        render: (actionDate) => {
          return actionDate && moment(actionDate).isValid()
            ? moment(new Date(actionDate))
                .local()
                .format(dateFormat)
            : '-';
        },
      },
      {
        title: '',
        dataIndex: 'id',
        render: (id, record) => {
          return (
            <>
              <this.ActionButtons id={id} record={record} />
            </>
          );
        },
      },
    ];

    return columns;
  };

  prepareData = () => {
    const { missingProductsOrders } = this.props;
    if (!missingProductsOrders || !missingProductsOrders.length) {
      return [];
    }
    return missingProductsOrders.map((order) => {
      return {
        productImage: order.product.picURL,
        productName: order.product.fullName,
        dateOfOrder: new Date(order.createdAt),
        status: order.status,
        removedFromSalesProductsCount: order.count,
        pickerName: order.reportedBy ? order.reportedBy.name : undefined,
        id: order.id,
        count: order.count,
        productId: order.product.id,
        concludedBy: order.concludedBy,
        updatedAt: order.updatedAt,
      };
    });
  };

  ActionButtons = (props) => {
    if (
      props.record.status === constants.REMOVED_FROM_SALES_IN_ORDERS.CREATED
    ) {
      return (
        <div className="actionButtons">
          <div>
            <Button
              onClick={() => this.openShowRemoveFromSalesModal(props)}
              size="small"
            >
              {this.props.t(
                'pages.Stock.MissingProductsOrders.buttons.removeFromSales',
              )}
            </Button>
          </div>

          <div>
            <Button
              onClick={() => this.openApproveCancelMissingProductsModal(props)}
              size="small"
            >
              {this.props.t(
                'pages.Stock.MissingProductsOrders.buttons.moveToStock',
              )}
            </Button>
          </div>
        </div>
      );
    }
    return null;
  };

  ShowRemoveFromSalesModal = () => {
    const props = this.state.selectedRow;
    if (!this.state.isShowRemoveFromSalesModalOpen || !props) {
      return null;
    }

    return (
      <Modal
        okText={this.props.t('pages.Stock.MissingProductsOrders.buttons.yes')}
        cancelText={this.props.t(
          'pages.Stock.MissingProductsOrders.buttons.no',
        )}
        title={this.props.t(
          'pages.Stock.MissingProductsOrders.removeFromSalesModal.title',
        )}
        onCancel={this.closeShowRemoveFromSalesModal}
        visible={this.state.isShowRemoveFromSalesModalOpen}
        footer={
          <>
            <Button onClick={() => this.closeShowRemoveFromSalesModal()}>
              {this.props.t('pages.Stock.MissingProductsOrders.buttons.no')}
            </Button>
            <Button
              onClick={() => {
                this.removeFromSales(props);
              }}
            >
              {this.props.t('pages.Stock.MissingProductsOrders.buttons.yes')}
            </Button>
          </>
        }
      >
        <>
          <div>
            {this.props.t(
              'pages.Stock.MissingProductsOrders.removeFromSalesModal.content',
            )}
          </div>
          <div className="product">
            {`${this.props.t(
              'pages.Stock.MissingProductsOrders.table.productName',
            )} : ${props.record.productName}`}
          </div>
        </>
      </Modal>
    );
  };

  ApproveCancelMissingProductsModal = () => {
    const props = this.state.selectedRow;
    if (!this.state.isApproveCancelMissingProductsModalOpen || !props) {
      return null;
    }

    return (
      <Modal
        okText={this.props.t('pages.Stock.MissingProductsOrders.buttons.yes')}
        cancelText={this.props.t(
          'pages.Stock.MissingProductsOrders.buttons.no',
        )}
        title={this.props.t(
          'pages.Stock.MissingProductsOrders.cancelMissingProductsModal.title',
        )}
        onCancel={this.closeApproveCancelMissingProductsModal}
        visible={this.state.isApproveCancelMissingProductsModalOpen}
        footer={
          <>
            <Button
              onClick={() => this.closeApproveCancelMissingProductsModal()}
            >
              {this.props.t('pages.Stock.MissingProductsOrders.buttons.no')}
            </Button>
            <Button
              onClick={() => {
                this.approveCancelMissingProducts(props);
              }}
              disabled={
                !(
                  this.state.missingProductCount &&
                  this.state.missingProductCount >= 0
                )
              }
            >
              {this.props.t('pages.Stock.MissingProductsOrders.buttons.yes')}
            </Button>
          </>
        }
      >
        <>
          <Row>
            <Col>
              {this.props.t(
                'pages.Stock.MissingProductsOrders.cancelMissingProductsModal.content',
              )}
            </Col>
            <Form.Item
              {...formItemLayout}
              label={props.record.productName}
              required
            >
              <Input
                value={this.state.missingProductCount}
                onChange={(e) => {
                  this.setState({ missingProductCount: e.target.value });
                  track('HOME_ORDER_MISSING_PRODUCTS_STOCK_QUANTITY_CHANGED');
                }}
                type="number"
              />
            </Form.Item>
          </Row>
        </>
      </Modal>
    );
  };

  CancelMissingProductsModal = () => {
    const props = this.state.selectedRow;
    if (!this.state.isCancelMissingProductsModalOpen || !props) {
      return null;
    }

    return (
      <Modal
        okText={this.props.t('pages.Stock.MissingProductsOrders.buttons.yes')}
        cancelText={this.props.t(
          'pages.Stock.MissingProductsOrders.buttons.no',
        )}
        title={this.props.t(
          'pages.Stock.MissingProductsOrders.approveCancelMissingProductsModal.title',
        )}
        onCancel={this.closeCancelMissingProductsModal}
        footer={
          <>
            <Button onClick={() => this.closeCancelMissingProductsModal()}>
              {this.props.t('pages.Stock.MissingProductsOrders.buttons.no')}
            </Button>
            <Button
              onClick={() => {
                this.cancelMissingProducts({
                  orderMissingProductRecordId: props.id,
                  givenQuantity: Number(this.state.missingProductCount),
                });
              }}
            >
              {this.props.t('pages.Stock.MissingProductsOrders.buttons.yes')}
            </Button>
          </>
        }
        visible={this.state.isCancelMissingProductsModalOpen}
      >
        <div>
          {this.props.t(
            'pages.Stock.MissingProductsOrders.approveCancelMissingProductsModal.content',
          )}
        </div>
      </Modal>
    );
  };

  onMissingProductCountChange = (e) => {
    this.setState({ missingProductCount: e.target.value });
  };

  openShowRemoveFromSalesModal = (props) => {
    this.setState(
      {
        selectedRow: props,
      },
      () => {
        this.setState({
          isShowRemoveFromSalesModalOpen: true,
        });
      },
    );
    track('HOME_ORDER_MISSING_PRODUCTS_REMOVE_CLICKED');
  };

  closeShowRemoveFromSalesModal = () => {
    this.setState({
      isShowRemoveFromSalesModalOpen: false,
    });
  };

  openApproveCancelMissingProductsModal = (props) => {
    this.setState(
      {
        selectedRow: props,
      },
      () => {
        this.setState({
          isApproveCancelMissingProductsModalOpen: true,
          missingProductCount: undefined,
        });
      },
    );
    track('HOME_ORDER_MISSING_PRODUCTS_STOCK_CLICKED');
  };

  closeApproveCancelMissingProductsModal = () => {
    this.setState({
      isApproveCancelMissingProductsModalOpen: false,
    });
  };

  openCancelMissingProductsModal = () => {
    this.setState({
      isCancelMissingProductsModalOpen: true,
    });
  };

  closeCancelMissingProductsModal = () => {
    this.setState({
      isCancelMissingProductsModalOpen: false,
    });
  };

  removeFromSales = (props) => {
    this.closeShowRemoveFromSalesModal();
    track('HOME_ORDER_MISSING_PRODUCTS_REMOVE_CONFIRMED');
    return this.props.onRemoveFromSalesInMissingProductsOrders({
      orderMissingProductRecordId: props.id,
    });
  };

  cancelMissingProducts = (data) => {
    this.closeCancelMissingProductsModal();
    return this.props.onCancelMissingProducts(data);
  };

  removeFromSalesInMissingProductsOrders = async (data) => {
    return this.props.onRemoveFromSalesInMissingProductsOrders(data);
  };

  approveCancelMissingProducts = (props) => {
    this.closeApproveCancelMissingProductsModal();
    track('HOME_ORDER_MISSING_PRODUCTS_STOCK_QUANTITY_APPROVED');
    return this.props
      .onApproveCancelMissingProducts({
        productId: props.record.productId,
        quantity: Number(this.state.missingProductCount),
      })
      .then((response) => {
        if (!response.isEnough) {
          this.cancelMissingProducts({
            orderMissingProductRecordId: props.id,
            givenQuantity: Number(this.state.missingProductCount),
          });
        } else {
          this.openCancelMissingProductsModal();
        }
      });
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card}>
          <div>
            {this.props.missingProductsOrders &&
            this.props.missingProductsOrders.length ? (
              <div className="warning">
                {this.props.t('missingProductWarning')}
              </div>
            ) : null}
            <Table
              size="small"
              columns={this.prepareColumns()}
              dataSource={this.prepareData()}
              expandedRowRender={this.expandedRowRender}
              rowKey={(record) => {
                return record.id;
              }}
              pagination={false}
              loading={{ indicator: <Spinner />, spinning: this.props.loading }}
            />
            <this.CancelMissingProductsModal />
            <this.ShowRemoveFromSalesModal />
            <this.ApproveCancelMissingProductsModal />
          </div>
        </Card>
      </div>
    );
  }
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  missingProductsOrders: state.missingProductsOrders.list.data,
  loading: state.missingProductsOrders.list.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onGetMissingProductsOrders: () => {
    return dispatch(missingProductsOrdersAction.getMissingProductsOrders());
  },
  onCancelMissingProducts: (data) => {
    return dispatch(missingProductsOrdersAction.cancelMissingProducts(data));
  },
  onRemoveFromSalesInMissingProductsOrders: (data) => {
    return dispatch(
      missingProductsOrdersAction.removeFromSalesInMissingProductsOrders(data),
    );
  },
  onApproveCancelMissingProducts: (data) => {
    return dispatch(
      missingProductsOrdersAction.approveCancelMissingProducts(data),
    );
  },
});

const MissingProductsOrdersConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(MissingProductsOrders));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { MissingProductsOrdersConnected as MissingProductsOrders };
