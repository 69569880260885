import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Table, Card } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './StockMovementLogList.scss';
import { StockMovementLogFilter } from './StockMovementLogFilter';
/*----------------------------------------------------------------------------*/
import {
  Spinner,
  ExportToExcel,
  CopyToClipboard,
} from './../../../../components';
import { stockMovementLogAction } from './../../../../actions';
import {
  tableHelper,
  statuses,
  getTranslation,
  constants,
  smlDescriptions,
  getDateTimeFormatString,
  getDateFormatString,
} from './../../../../shared';
import { pageLoadEvents } from '../../../../services/events';
import { pageView } from '../../../../services/segment';

/*----------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------
------------------------------------------------------------------------------*/

class StockMovementLogList extends Component {
  settings = {
    main: {
      className: 'StockMovementLogList_main',
    },
    card: {
      title: this.props.t('pages.Stock.StockMovementLog.list.title'),
      className: 'no-padding-card',
      extra: '',
      size: 'small',
    },
  };

  dateFormat = getDateFormatString();

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.STOCK_PRODUCT_PLACEMENT });
    this.props.onResetStockMovementList();
  }

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    return {
      startDate: new Date(today.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      filter: this.getDefaultFilter(),
    };
  };

  getDefaultFilter = () => null;

  PageExtra = (props) => (
    <ExportToExcel
      action={this.props.onExportToXlsxStockMovementLogList}
      query={{ limit: this.props.totalCount, offset: 0 }}
      getFilter={this.getFilter}
      fileName="stockMovementLogList"
      dataFormatter={this.prepareForExport}
      loading={this.props.isExporting}
      disabled={false}
    />
  );

  PageTable = (props) => (
    <div>
      <Table
        locale={{ emptyText: this.calculateEmptyText() }}
        size="small"
        dataSource={this.prepareData(this.props)}
        columns={this.prepareColumns(this.props)}
        rowKey={(record) => record.id}
        pagination={{
          ...this.state.pagination,
          total: this.props.totalCount,
        }}
        loading={{ indicator: <Spinner />, spinning: this.props.loading }}
        onChange={this.handleTableChange}
      />
    </div>
  );

  calculateEmptyText = () => {
    if (this.state.filter) {
      return this.props.t('pages.Stock.StockMovementLog.list.noData');
    }
    return this.props.t('pages.Stock.StockMovementLog.list.noFilter');
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      (state) => ({
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      }),
      this.getList,
    );
  };

  prepareColumns = (props) => [
    {
      title: this.props.t('pages.Stock.StockMovementLog.list.table.warehouse'),
      dataIndex: 'warehouse',
      key: 'warehouse',
      render: (warehouse) => <CopyToClipboard message={warehouse.name} />,
    },
    {
      title: this.props.t('pages.Stock.StockMovementLog.list.table.picker'),
      dataIndex: 'picker',
      key: 'picker',
      ...tableHelper.fieldSorter.getStringSortProps('picker'),
    },
    {
      title: this.props.t(
        'pages.Stock.StockMovementLog.list.table.fromProductLocation',
      ),
      dataIndex: 'fromProductLocation',
      key: 'fromProductLocation',
      ...tableHelper.fieldSorter.getStringSortProps('fromProductLocation'),
    },
    {
      title: this.props.t(
        'pages.Stock.StockMovementLog.list.table.toProductLocation',
      ),
      dataIndex: 'toProductLocation',
      key: 'toProductLocation',
      ...tableHelper.fieldSorter.getStringSortProps('toProductLocation'),
    },
    {
      title: this.props.t('pages.Stock.StockMovementLog.list.table.product'),
      dataIndex: 'product',
      key: 'product',
      render: (product) =>
        product ? <CopyToClipboard message={product.fullName} /> : '-',
    },
    {
      title: this.props.t('pages.Stock.StockMovementLog.list.table.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      ...tableHelper.fieldSorter.getNumberSortProps('quantity'),
    },
    {
      title: this.props.t('pages.Stock.StockMovementLog.list.table.expiry'),
      dataIndex: 'expiry',
      key: 'expiry',
      ...tableHelper.fieldSorter.getDateSortProps('expiry'),
      render: (expiry) =>
        moment(expiry).isValid()
          ? moment(new Date(expiry))
              .local()
              .format(this.dateFormat)
          : '-',
    },
    {
      title: this.props.t('pages.Stock.StockMovementLog.list.table.logger'),
      dataIndex: 'logger',
      key: 'logger',
      render: (logger) =>
        logger !== '-' ? <CopyToClipboard message={logger} /> : logger,
      ...tableHelper.fieldSorter.getStringSortProps('logger'),
    },
    {
      title: this.props.t(
        'pages.Stock.StockMovementLog.list.table.loggerModel',
      ),
      dataIndex: 'loggerModel',
      key: 'loggerModel',
      render: (loggerModel, record) =>
        this.LoggerModelLink(loggerModel, record),
      ...tableHelper.fieldSorter.getStringSortProps('loggerModel'),
    },
    {
      title: this.props.t(
        'pages.Stock.StockMovementLog.list.table.description',
      ),
      dataIndex: 'description',
      key: 'description',
      render: (description) =>
        Object.keys(smlDescriptions).includes(description)
          ? getTranslation.descriptionType(description)
          : description,
      ...tableHelper.fieldSorter.getStringSortProps('description'),
    },
    {
      title: this.props.t('pages.Stock.StockMovementLog.list.table.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ...tableHelper.fieldSorter.getDateSortProps('createdAt'),
      render: (createdAt) =>
        moment(createdAt).isValid()
          ? moment(new Date(createdAt))
              .local()
              .format(getDateTimeFormatString())
          : '-',
    },
  ];

  LoggerModelLink = (loggerModel, record) => {
    const {
      Transfer,
      WarehousePurchaseOrder,
      InventoryCheck,
    } = constants.LOGGER_MODEL_TYPE;
    const { CWId } = this.props;
    let url = null;
    if (loggerModel === Transfer) {
      url = `/r/${CWId}/stock/transfer/${record.logger}`;
    }
    if (loggerModel === WarehousePurchaseOrder) {
      url = `/r/${CWId}/stock/purchase-order/detail/${record.logger}`;
    }
    if (loggerModel === InventoryCheck) {
      url = `/r/${CWId}/stock/inventory-check/detail/${record.logger}`;
    }
    if (!url) {
      return getTranslation.loggerModelType(loggerModel);
    }
    return <Link to={url}>{getTranslation.loggerModelType(loggerModel)}</Link>;
  };

  prepareData = (props) => {
    const { list } = props;
    if (!list) {
      return null;
    }
    return list.map((movementLog) => ({
      id: movementLog.id,
      warehouse: movementLog.warehouse || '-',
      picker: (movementLog.picker && movementLog.picker.name) || '-',
      logger: movementLog.logger || '-',
      loggerModel: movementLog.loggerModel || '-',
      fromProductLocation:
        (movementLog.fromProductLocation &&
          movementLog.fromProductLocation.warehouseLocation &&
          movementLog.fromProductLocation.warehouseLocation.barcode) ||
        '-',
      toProductLocation:
        (movementLog.toProductLocation &&
          movementLog.toProductLocation.warehouseLocation &&
          movementLog.toProductLocation.warehouseLocation.barcode) ||
        '-',
      product: movementLog.product || '-',
      quantity: movementLog.quantity || '-',
      expiry: movementLog.expiry || '-',
      description: movementLog.description || '-',
      createdAt: movementLog.createdAt || '-',
    }));
  };

  prepareForExport = (rawData) => {
    const data = this.prepareData({ list: rawData });
    if (!data || !data.length) {
      return null;
    }
    return data.map((item) => ({
      [this.props.t(
        'pages.Stock.StockMovementLog.list.table.warehouse',
      )]: item.warehouse,
      [this.props.t(
        'pages.Stock.StockMovementLog.list.table.picker',
      )]: item.picker,
      [this.props.t(
        'pages.Stock.StockMovementLog.list.table.fromProductLocation',
      )]: item.fromProductLocation,
      [this.props.t(
        'pages.Stock.StockMovementLog.list.table.toProductLocation',
      )]: item.toProductLocation,
      [this.props.t('pages.Stock.StockMovementLog.list.table.product')]:
        item.product && item.product.fullName ? item.product.fullName : '-',
      [this.props.t(
        'pages.Stock.StockMovementLog.list.table.quantity',
      )]: item.quantity,
      [this.props.t('pages.Stock.StockMovementLog.list.table.expiry')]: moment(
        item.expiry,
      ).isValid()
        ? moment(new Date(item.expiry))
            .local()
            .format(getDateFormatString())
        : '-',
      [this.props.t(
        'pages.Stock.StockMovementLog.list.table.logger',
      )]: item.logger,
      [this.props.t(
        'pages.Stock.StockMovementLog.list.table.loggerModel',
      )]: Object.keys(statuses.loggerModelType).includes(item.loggerModel)
        ? getTranslation.loggerModelType(item.loggerModel)
        : item.loggerModel,
      [this.props.t(
        'pages.Stock.StockMovementLog.list.table.description',
      )]: Object.keys(smlDescriptions).includes(item.description)
        ? getTranslation.descriptionType(item.description)
        : item.description,
      [this.props.t(
        'pages.Stock.StockMovementLog.list.table.createdAt',
      )]: moment(item.createdAt).isValid()
        ? moment(new Date(item.createdAt))
            .local()
            .format(getDateTimeFormatString())
        : '-',
    }));
  };

  getList = () => {
    const filter = this.getFilter();
    const query = this.getQuery();
    this.props.onGetStockMovementLogList(query, filter);
  };

  getFilter = () => {
    const filter = this.state.filter || {};

    filter.startDate = filter.startDate || this.defaultDateRange.startDate;
    filter.endDate = filter.endDate || this.defaultDateRange.endDate;

    filter.warehouses = [this.props.CWId];

    return filter;
  };

  getQuery = () => {
    const { query } = this.state;
    return query;
  };

  setTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      }),
      this.getList,
    );
  };

  clearTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      }),
      this.getList,
    );
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.PageExtra />}>
          <StockMovementLogFilter
            defaultDateRange={this.defaultDateRange}
            onChange={this.setTableFilter}
            onClear={this.clearTableFilter}
          />
          <this.PageTable />
        </Card>
      </div>
    );
  }
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse.id,
  loading: state.stockMovementLog.list.loading,
  list: state.stockMovementLog.list.data,
  totalCount: state.stockMovementLog.list.total,
  isExporting: state.stockMovementLog.isExporting,
});

const mapDispatchToProps = (dispatch) => ({
  onGetStockMovementLogList: (query, filter) =>
    dispatch(stockMovementLogAction.getStockMovementLogList(query, filter)),
  onExportToXlsxStockMovementLogList: (query, filter, fileName, formatter) =>
    dispatch(
      stockMovementLogAction.exportToXlsxStockMovementLogList(
        query,
        filter,
        fileName,
        formatter,
      ),
    ),
  onResetStockMovementList: () =>
    dispatch(stockMovementLogAction.resetStockMovementLogList()),
});

const StockMovementLogListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(StockMovementLogList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { StockMovementLogListConnected as StockMovementLogList };
