/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, Table, Button } from 'antd';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
// import './SubPoTable.scss';
/*----------------------------------------------------------------------------*/
import { CopyToClipboard } from './../../../../../components';
import {
  getDateTimeFormatString,
  getTranslation,
} from './../../../../../shared';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class SubPoTable extends Component {
  settings = {
    main: {
      className: 'SubPoTable_main',
    },
    card: {
      title: this.props.t(
        'pages.Operation.PurchaseOrder.detail.subPoTable.title',
      ),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  dateFormat = getDateTimeFormatString();

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    return {
      pagination,
    };
  };

  PageTable = () => {
    if (!this.props.subPurchaseOrders) {
      return null;
    }
    return (
      <Table
        size="small"
        dataSource={this.prepareData(this.props)}
        columns={this.prepareColumns(this.props)}
        rowKey={(record) => {
          return record.id;
        }}
        expandedRowRender={this.expandedRowRender}
        pagination={{ ...this.state.pagination, total: this.props.totalCount }}
        onChange={this.handleTableChange}
      />
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      };
    });
  };

  prepareColumns = (props) => {
    return [
      {
        title: this.props.t('pages.Operation.PurchaseOrder.list.table.id'),
        dataIndex: 'table.id',
        key: 'id',
        render: (id) => <CopyToClipboard message={id} />,
      },
      {
        title: this.props.t('pages.Operation.PurchaseOrder.list.table.no'),
        dataIndex: 'table.no',
        key: 'no',
        render: (no) => <CopyToClipboard message={no} />,
      },
      {
        title: this.props.t('pages.Operation.PurchaseOrder.list.table.status'),
        dataIndex: 'table.status',
        key: 'status',
        render: (status) => getTranslation.operationPurchaseOrderStatus(status),
      },
      {
        title: this.props.t('pages.Operation.PurchaseOrder.list.table.date'),
        dataIndex: 'table.date',
        key: 'date',
      },
      {
        title: '',
        dataIndex: 'table.id',
        key: 'detail',
        render: (id) => {
          return <this.DetailButton id={id} />;
        },
      },
    ];
  };

  DetailButton = (props) => {
    const { id } = props;
    const url = `/stock-operation/purchase-order/detail/${id}`;
    return (
      <Link to={url} target="_blank" rel="noopener noreferrer">
        <Button size="small">
          {this.props.t('pages.Operation.PurchaseOrder.list.table.detail')}
        </Button>
      </Link>
    );
  };

  prepareData = (props) => {
    const { subPurchaseOrders } = props;
    if (!subPurchaseOrders || !subPurchaseOrders.length) {
      return [];
    }
    return subPurchaseOrders.map((item) => {
      return {
        ...item,
        table: {
          date: moment(new Date(item.createdAt))
            .local()
            .format(this.dateFormat),
          status: item.status,
          no: item.no,
          id: item.id,
          key: item.id,
        },
      };
    });
  };

  expandedRowRender = (record, index, indent, expanded) => {
    return (
      <Table
        size="small"
        columns={this.prepareExtendedColumns(record)}
        dataSource={this.prepareExtendedData(record)}
        rowKey={(record) => {
          return record.id;
        }}
        pagination={false}
      />
    );
  };

  prepareExtendedColumns = (record) => {
    return [
      {
        title: this.props.t('pages.Operation.PurchaseOrder.list.subTable.id'),
        dataIndex: 'id',
        key: 'id',
        render: (id) => <CopyToClipboard message={id} />,
      },
      {
        title: this.props.t('pages.Operation.PurchaseOrder.list.subTable.no'),
        dataIndex: 'no',
        key: 'no',
        render: (no) => <CopyToClipboard message={no} />,
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.list.subTable.status',
        ),
        dataIndex: 'status',
        key: 'status',
        render: (status) => getTranslation.operationPurchaseOrderStatus(status),
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.list.subTable.warehouse',
        ),
        dataIndex: 'warehouse',
        key: 'warehouse',
        render: (warehouse) => warehouse.name,
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.list.subTable.supplier',
        ),
        dataIndex: 'supplier',
        key: 'supplier',
        render: (supplier) => supplier.name,
      },
      {
        title: this.props.t('pages.Operation.PurchaseOrder.list.subTable.date'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) => {
          return moment(new Date(createdAt))
            .local()
            .format(getDateTimeFormatString());
        },
      },
      {
        key: 'detail',
        dataIndex: 'id',
        render: (id) => {
          return <this.DetailWpoButton id={id} />;
        },
      },
    ];
  };

  DetailWpoButton = (props) => {
    const { id } = props;
    const url = `/stock-operation/warehouse-purchase-order/detail/${id}`;
    return (
      <Link to={url} target="_blank" rel="noopener noreferrer">
        <Button size="small">
          {this.props.t('pages.Operation.PurchaseOrder.list.table.detail')}
        </Button>
      </Link>
    );
  };

  prepareExtendedData = (record) => {
    if (
      !record.warehousePurchaseOrders ||
      !record.warehousePurchaseOrders.length
    ) {
      return [];
    }
    return record.warehousePurchaseOrders;
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card}>
          <this.PageTable />
        </Card>
      </div>
    );
  }
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const SubPoTableConnected = connect()(
  withNamespaces('translation')(SubPoTable),
);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { SubPoTableConnected as SubPoTable };
