import React, { Component } from 'react';
import { connect } from 'react-redux';
import { timerAction } from './../../actions';

class Timer extends Component {
  constructor(props) {
    super(props);
    this.timer = setInterval(props.onTimerTick, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <div className="Timer_inner" style={{ display: 'none' }}>
        {this.props.timer}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  timer: state.timer,
});

const mapDispatchToProps = (dispatch) => ({
  onTimerTick: () => dispatch(timerAction.timerTick()),
});

const TimerConnected = connect(mapStateToProps, mapDispatchToProps)(Timer);

export { TimerConnected as Timer };
