export class TransferDataFormatter {
  static prepareSaveTransferData(items) {
    return items.map((item) => ({
      item: item.item.id || item.item._id,
      count: item.count,
    }));
  }

  static prepareDepartTransferVehicleData(transfer) {
    const { id: documentId, items } = transfer;
    const sampleExpiryDate = new Date('2050-12-30');
    const formattedItems = items.map((item) => {
      const data = {
        itemId: item.item.id || item.item._id,
        count: item.count,
      };
      if (item.item.expActive) {
        data.expiryDate = sampleExpiryDate;
      }
      return data;
    });

    return {
      waybills: [
        {
          image: 'string',
          date: new Date('2019-12-17'),
          ss: 'AA-5234',
          documentId,
        },
      ],
      pallets: [
        {
          barcode: 'TP1',
          items: formattedItems,
        },
      ],
      plate: '34TS2019',
    };
  }

  static prepareSendDepartedOrderData(transfer) {
    const { palletBarcodes } = transfer;

    const pallets = palletBarcodes.map((barcode) => {
      return { barcode };
    });

    return { pallets };
  }
}
