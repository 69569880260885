import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Form, Button, Dropdown, Icon, Menu, Switch } from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import './InventoryCheckCreate.scss';
import {
  ScopeSelect,
  TypeSelect,
  WarehouseSelect,
  PickerMultiSelect,
  ProductMultiSelect,
  LocationMultiSelect,
} from './formFields';
/*----------------------------------------------------------------------------*/
import { Spinner, ImportCsv, ReasonSelect } from './../../../../components';
import { history } from './../../../../history';
import { constants, statuses } from './../../../../shared';
import {
  inventoryAction,
  warehouseAction,
  productAction,
  warehouseLocationAction,
} from './../../../../actions';
import { pageLoadEvents } from '../../../../services/events';
import { pageView } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/

const { INVENTORY_CHECK_TYPE, INVENTORY_CHECK_SCOPE } = constants;

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class InventoryCheckCreate extends Component {
  settings = {
    main: {
      className: 'InventoryCheckCreate_main',
    },
    card: {
      className: 'no-padding-card',
      title: this.props.t('pages.Stock.InventoryCheck.create.title'),
      extra: '',
      size: 'small',
    },
  };

  formItemLayout = {
    style: {
      marginTop: 15,
    },
    labelCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 5, offset: 0 },
    },
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 0 },
    },
  };

  tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 13,
        offset: 3,
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.STOCK_CREATE_STOCK_CONTROL });
    this.prepareToCreate();
  }

  prepareToCreate = () => {
    this.props.onGetWarehouseList();
    this.props.onGetLocationList(this.props.CWId);
    this.props.onGetProductListByWarehouseIds([this.props.CWId]);
  };

  stateFactory = () => {
    const { inventoryCheckType } = statuses;
    const { inventoryCheckScope } = statuses;
    const { PRODUCT_BASED } = constants.INVENTORY_CHECK_TYPE;
    const { PARTIAL } = constants.INVENTORY_CHECK_SCOPE;

    return {
      type: {
        key: PRODUCT_BASED,
        value: inventoryCheckType[PRODUCT_BASED],
      },
      scope: { key: PARTIAL, value: inventoryCheckScope[PARTIAL] },
      handover: false,
      expiryDateRequired: false,
      warehouses: [this.props.currentWarehouse],
      locations: [],
      products: [],
      pickers: [],
      isClickedSave: false,
      reason: null,
    };
  };

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {loading ? <Spinner /> : <this.PageCreate />}
        </Card>
      </div>
    );
  }

  CardExtra = () => (
    <Dropdown overlay={this.MenuAction}>
      <Button style={{ display: 'flex', alignItems: 'center' }}>
        {this.props.t('pages.Stock.InventoryCheck.create.button.actions')}{' '}
        <Icon type="down" />
      </Button>
    </Dropdown>
  );

  MenuAction = () => (
    <Menu>
      <Menu.Item key="CreateButton">
        <this.CreateButton />
      </Menu.Item>
      <Menu.Item key="ClearButton">
        <this.ClearButton />
      </Menu.Item>
      {/* <Menu.Item key="ImportFromCsvButton">
          <this.ImportFromCsvButton />
        </Menu.Item> */}
      <Menu.Item key="CancelButton">
        <this.CancelButton />
      </Menu.Item>
    </Menu>
  );

  CreateButton = () => (
    <Button
      type="danger"
      disabled={!this.getPayload() || this.state.isClickedSave}
      loading={this.state.isClickedSave}
      block
      onClick={() => {
        this.setState({ isClickedSave: true });
        const payload = this.getPayload();
        this.createInventoryCheck(payload)
          .then(() => {
            history.push(`/r/${this.props.CWId}/stock/inventory-check/list`);
          })
          .catch(() => {
            history.push(`/r/${this.props.CWId}/stock/inventory-check/list`);
          });
      }}
    >
      {this.props.t('pages.Stock.InventoryCheck.create.button.save')}
    </Button>
  );

  ClearButton = () => (
    <Button
      block
      onClick={() => {
        this.setState(this.stateFactory());
      }}
    >
      {this.props.t('pages.Stock.InventoryCheck.create.button.clean')}
    </Button>
  );

  ImportFromCsvButton = () => <ImportCsv block transformData={() => {}} />;

  CancelButton = () => (
    <Button
      block
      onClick={() => {
        history.goBack();
      }}
    >
      {this.props.t('pages.Stock.InventoryCheck.create.button.cancel')}
    </Button>
  );

  PageCreate = () => <this.PageForm />;

  PageForm = (props) => (
    <Form {...this.formItemLayout}>
      <this.TypeSelect />
      <this.ScopeSelect />
      <this.HandoverSwitch />
      <this.ExpiryDateRequiredSwitch />
      <this.WarehouseSelect />
      <this.LocationSelect />
      <this.ProductSelect />
      <this.ReasonSelect />

      {/* <this.PickerSelect /> */}
    </Form>
  );

  TypeSelect = () => (
    <Form.Item
      label={this.props.t('pages.Stock.InventoryCheck.create.form.type')}
    >
      <TypeSelect
        disabled={!this.props.isAdmin}
        placeholder={this.props.t(
          'pages.Stock.InventoryCheck.create.form.type',
        )}
        onChange={(type) => {
          this.setState((state) => ({
            ...state,
            type,
            products: [],
            locations: [],
          }));
        }}
        value={this.state.type}
      />
    </Form.Item>
  );

  ScopeSelect = () => (
    <Form.Item
      label={this.props.t('pages.Stock.InventoryCheck.create.form.scope')}
    >
      <ScopeSelect
        disabled={!this.props.isAdmin}
        placeholder={this.props.t(
          'pages.Stock.InventoryCheck.create.form.scope',
        )}
        onChange={(scope) => {
          this.setState((state) => ({
            ...state,
            scope,
            // warehouses: [],
            products: [],
            locations: [],
          }));
        }}
        value={this.state.scope}
      />
    </Form.Item>
  );

  HandoverSwitch = () => {
    const { handover } = this.state;
    return (
      <Form.Item
        label={this.props.t('pages.Stock.InventoryCheck.create.form.handover')}
      >
        <Switch
          disabled={!this.props.isAdmin}
          defaultChecked={handover}
          checkedChildren={this.props.t(
            'pages.Stock.InventoryCheck.create.switch.enable',
          )}
          unCheckedChildren={this.props.t(
            'pages.Stock.InventoryCheck.create.switch.disable',
          )}
          onChange={() => {
            this.setState({ handover: !handover });
          }}
        />
      </Form.Item>
    );
  };

  ExpiryDateRequiredSwitch = () => {
    const { expiryDateRequired } = this.state;
    return (
      <Form.Item
        label={this.props.t(
          'pages.Stock.InventoryCheck.create.form.expiryDateRequired',
        )}
      >
        <Switch
          disabled={!this.props.isAdmin}
          defaultChecked={expiryDateRequired}
          checkedChildren={this.props.t(
            'pages.Stock.InventoryCheck.create.switch.enable',
          )}
          unCheckedChildren={this.props.t(
            'pages.Stock.InventoryCheck.create.switch.disable',
          )}
          onChange={() => {
            this.setState({ expiryDateRequired: !expiryDateRequired });
          }}
        />
      </Form.Item>
    );
  };

  WarehouseSelect = () => {
    const { warehouseList } = this.props;
    const { type } = this.state;
    const list = (warehouseList && warehouseList.data) || [];

    if (!type) {
      return null;
    }
    // eslint-disable-next-line
    const isProductBased = type.key == INVENTORY_CHECK_TYPE.PRODUCT_BASED;
    const mode = isProductBased ? 'multiple' : 'default';

    return (
      <Form.Item
        label={this.props.t(
          'pages.Stock.InventoryCheck.create.form.warehouses',
        )}
      >
        <span>
          <WarehouseSelect
            disabled={!this.props.isAdmin}
            mode={mode}
            allowClear
            placeholder={this.props.t(
              'pages.Stock.InventoryCheck.create.form.warehouses',
            )}
            list={list}
            onChange={(warehouses) => {
              this.setState(
                (state) => ({
                  ...state,
                  warehouses,
                  locations: [],
                  products: [],
                }),
                () => {
                  this.getLocationsByWarehouse();
                  this.getProducts();
                },
              );
            }}
            value={this.state.warehouses}
            loading={warehouseList.loading}
          />
        </span>
      </Form.Item>
    );
  };

  ProductSelect = () => {
    const { productList } = this.props;
    const list = (productList && productList.data) || null;

    const { type, scope } = this.state;

    if (!scope || !type) {
      return null;
    }

    if (
      // eslint-disable-next-line
      scope.key == INVENTORY_CHECK_SCOPE.PARTIAL &&
      // eslint-disable-next-line
      type.key == INVENTORY_CHECK_TYPE.PRODUCT_BASED
    ) {
      return (
        <Form.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.create.form.products',
          )}
        >
          <ProductMultiSelect
            placeholder={this.props.t(
              'pages.Stock.InventoryCheck.create.form.products',
            )}
            list={list}
            onChange={(products) => {
              this.setState({ products });
            }}
            value={this.state.products}
            loading={productList.loading}
          />
        </Form.Item>
      );
    }
    return null;
  };

  ReasonSelect = () => {
    return (
      <Form.Item label={this.props.t('common.reason')}>
        <ReasonSelect
          onChange={(reason) => {
            this.setState(() => ({
              reason,
            }));
          }}
        />
      </Form.Item>
    );
  };

  LocationSelect = () => {
    const { locationList } = this.props;
    const list = (locationList && locationList.data) || null;

    const { type, scope, locations, warehouses } = this.state;

    if (!scope || !type) {
      return null;
    }

    if (
      // eslint-disable-next-line
      scope.key == INVENTORY_CHECK_SCOPE.PARTIAL &&
      // eslint-disable-next-line
      type.key == INVENTORY_CHECK_TYPE.LOCATION_BASED &&
      warehouses.length === 1
    ) {
      return (
        <Form.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.create.form.locations',
          )}
        >
          <LocationMultiSelect
            placeholder={this.props.t(
              'pages.Stock.InventoryCheck.create.form.locations',
            )}
            list={list}
            onChange={(locations) => {
              this.setState({ locations });
            }}
            value={locations}
            loading={locationList.loading}
          />
        </Form.Item>
      );
    }

    return null;
  };

  PickerSelect = () => {
    const { pickerList } = this.props;
    const list = (pickerList && pickerList.data) || null;
    return (
      <Form.Item
        label={this.props.t('pages.Stock.InventoryCheck.create.form.picker')}
      >
        <PickerMultiSelect
          placeholder={this.props.t(
            'pages.Stock.InventoryCheck.create.form.picker',
          )}
          list={list}
          onChange={(pickers) => {
            this.setState({ pickers });
          }}
          value={this.state.pickers}
          loading={pickerList.loading}
        />
      </Form.Item>
    );
  };

  getLocationsByWarehouse = () => {
    const { type, scope, warehouses } = this.state;

    if (!scope || !type) {
      return null;
    }

    if (
      // eslint-disable-next-line
      scope.key == INVENTORY_CHECK_SCOPE.PARTIAL &&
      // eslint-disable-next-line
      type.key == INVENTORY_CHECK_TYPE.LOCATION_BASED &&
      warehouses.length === 1
    ) {
      this.props.onGetLocationList(warehouses[0].id);
    }
  };

  getProducts = () => {
    const { type, scope } = this.state;

    if (!scope || !type) {
      return null;
    }

    const warehouseIds = this.state.warehouses
      ? this.state.warehouses.map((w) => w.id)
      : [];

    if (
      // eslint-disable-next-line
      scope.key == INVENTORY_CHECK_SCOPE.PARTIAL &&
      // eslint-disable-next-line
      type.key == INVENTORY_CHECK_TYPE.PRODUCT_BASED &&
      warehouseIds.length !== 0
    ) {
      this.props.onGetProductListByWarehouseIds(warehouseIds);
    }
  };

  getPayload = () => {
    const {
      type,
      scope,
      handover,
      expiryDateRequired,
      warehouses,
      locations,
      products,
      reason,
    } = this.state;

    const payload = {
      handover,
      expiryDateRequired,
    };

    if (
      !scope ||
      !type ||
      !warehouses.length ||
      !reason ||
      (reason.type === constants.INVENTORY_CHECK_REASONS.OTHER &&
        !reason.description)
    ) {
      return null;
    }
    payload.type = type.key;
    payload.scope = scope.key;
    payload.warehouseId = warehouses.map((w) => w.id);
    payload.reason = reason;

    if (
      // eslint-disable-next-line
      scope.key == INVENTORY_CHECK_SCOPE.PARTIAL &&
      // eslint-disable-next-line
      type.key == INVENTORY_CHECK_TYPE.PRODUCT_BASED
    ) {
      if (!products.length) {
        return null;
      }
      payload.products = products.map((p) => p.id);
    }

    if (
      // eslint-disable-next-line
      scope.key == INVENTORY_CHECK_SCOPE.PARTIAL &&
      // eslint-disable-next-line
      type.key == INVENTORY_CHECK_TYPE.LOCATION_BASED
    ) {
      if (!locations.length) {
        return null;
      }
      payload.locations = locations.map((l) => l.id);
    }

    return payload;
  };

  createInventoryCheck = (payload) =>
    this.props.onCreateInventoryCheck(payload);
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  currentWarehouse: state.auth.currentWarehouse,
  loading: state.warehouse.warehouseList.loading,
  warehouseList: state.warehouse.warehouseList,
  locationList: state.warehouseLocation.warehouseLocationList,
  productList: state.product.productList,
  pickerList: state.picker.list,
  isAdmin: state.auth.user.isAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  onGetWarehouseList: () => dispatch(warehouseAction.getWarehouseList()),

  onGetProductListByWarehouseIds: (warehouseIds) =>
    dispatch(
      productAction.filterProductListByWarehouseIds({
        fields: 'barcodes picURL fullName packagingInfo mainStoreSpecs',
        includeDefaultFields: false,
        warehouseIds,
      }),
    ),

  onGetLocationList: (warehouseId) => {
    const { WAREHOUSE_LOCATION_OPERATION_TYPE } = constants;
    const {
      CANCEL,
      PICKING,
      EMPTY_CARBOY,
      FROZEN,
      INCOMING,
    } = WAREHOUSE_LOCATION_OPERATION_TYPE;
    return dispatch(
      warehouseLocationAction.filterWarehouseLocations({
        warehouseId,
        operationTypes: [CANCEL, PICKING, EMPTY_CARBOY, FROZEN, INCOMING],
      }),
    );
  },

  onCreateInventoryCheck: (data) =>
    dispatch(inventoryAction.bulkCreateInventoryCheck(data)),
});

const InventoryCheckCreateConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(InventoryCheckCreate));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { InventoryCheckCreateConnected as InventoryCheckCreate };
