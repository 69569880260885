/* eslint-disable eqeqeq */
import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';
import { LocalDatabase, constants, supportedLanguages } from './../shared';

export const productAction = {
  getProductList,
  filterProductList,
  filterProductListV2,
  filterProductListByWarehouseIds,
  resetWarehouseProductList,
};

function getProductList(warehouseId, query, filter) {
  return async (dispatch) => {
    dispatch({
      type: actionType.getWarehouseProductList_InProgress,
    });
    http
      .getProductList(warehouseId, query, filter)
      .then(async (res) => {
        const response = res.data.data.data;
        const normalizedProducts = {
          ...response,
          ...{
            products: addMissingLocaleKeysAsEmptyStrings(response.products),
          },
        };
        dispatch({
          type: actionType.getWarehouseProductList_Succeeded,
          payload: normalizedProducts,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getWarehouseProductList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getWarehouseProductList_Failed,
          payload: error,
        });
      });
  };
}


function filterProductList(data, shouldStorage = true) {
  return async (dispatch) => {
    dispatch({
      type: actionType.filterProductList_InProgress,
    });
    const products = await LocalDatabase.getWithExpiry('products');

    if (shouldStorage && products) {
      const normalizedProducts = {
        ...products,
        ...{ products: addMissingLocaleKeysAsEmptyStrings(products.products) },
      };
      dispatch({
        type: actionType.filterProductList_Succeeded,
        payload: normalizedProducts,
      });
      return Promise.resolve(normalizedProducts);
    } else {
      return http
        .filterProductList(data)
        .then(async (res) => {
          const response = res.data.data;
          const normalizedProducts = {
            ...response,
            ...{
              products: addMissingLocaleKeysAsEmptyStrings(response.products),
            },
          };
          if (shouldStorage) {
            await LocalDatabase.setWithExpiry(
              'products',
              normalizedProducts,
              constants.TTL_FOR_CACHE.PRODUCTS,
            );
          }

          dispatch({
            type: actionType.filterProductList_Succeeded,
            payload: normalizedProducts,
          });
        })
        .catch((error) => {
          notification.error({
            duration: 4,
            message: ErrorInfo.message(actionType.filterProductList_Failed),
            description: ErrorInfo.description(error),
          });
          dispatch({
            type: actionType.filterProductList_Failed,
            payload: error,
          });
          throw error;
        });
    }
  };
}


function filterProductListV2(data) {
  return async (dispatch) => {
    dispatch({
      type: actionType.filterProductListV2_InProgress,
    });

    return http
      .filterProductListV2(data)
      .then(async (res) => {
        const response = res.data.data;
        const normalizedProducts = {
          ...response,
          ...{
            products: addMissingLocaleKeysAsEmptyStrings(response.products),
          },
        };

        dispatch({
          type: actionType.filterProductListV2_Succeeded,
          payload: normalizedProducts,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.filterProductListV2_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.filterProductListV2_Failed,
          payload: error,
        });
        throw error;
      });
  };
}


  function filterProductListByWarehouseIds(
    { warehouseIds, ...data },
    shouldStorage = true,
  ) {
    return async (dispatch) => {
      dispatch({
        type: actionType.filterProductList_InProgress,
      });
      const products = await LocalDatabase.getWithExpiry('products');

      if (shouldStorage && products) {
        const allProducts = products.products;
        return http
          .filterProductListByWarehouseIds({ warehouseIds })
          .then((resp) => {
            const warehouseProductsArray = resp.data.data;
            const warehouseProductsObject = {};
            warehouseProductsArray.forEach((product) => {
              warehouseProductsObject[product.product] = true;
            });
            const filteredProducts = allProducts
              ? allProducts.filter(
                (product) => warehouseProductsObject[product.id],
              )
              : [];
            dispatch({
              type: actionType.filterProductList_Succeeded,
              payload: { products: filteredProducts },
            });
            return Promise.resolve(filteredProducts);
          })
          .catch((error) => {
            notification.error({
              duration: 4,
              message: ErrorInfo.message(actionType.filterProductList_Failed),
              description: ErrorInfo.description(error),
            });
            dispatch({
              type: actionType.filterProductList_Failed,
              payload: error,
            });
            throw error;
          });
      }
      return http
        .filterProductList(data)
        .then(async (res) => {
          const allProducts = res.data.data.products;

          if (shouldStorage) {
            await LocalDatabase.setWithExpiry(
              'products',
              { ...res.data.data, products: allProducts },
              constants.TTL_FOR_CACHE.PRODUCTS,
            );
          }
          return http
            .filterProductListByWarehouseIds({ warehouseIds })
            .then(async (resp) => {
              const warehouseProductsArray = resp.data.data;
              const warehouseProductsObject = {};
              warehouseProductsArray.forEach((product) => {
                warehouseProductsObject[product.product] = true;
              });
              const filteredProducts = allProducts.filter(
                (product) => warehouseProductsObject[product.id],
              );
              dispatch({
                type: actionType.filterProductList_Succeeded,
                payload: { ...res.data, products: filteredProducts },
              });
            })
            .catch((error) => {
              notification.error({
                duration: 4,
                message: ErrorInfo.message(actionType.filterProductList_Failed),
                description: ErrorInfo.description(error),
              });
              dispatch({
                type: actionType.filterProductList_Failed,
                payload: error,
              });
              throw error;
            });
        })
        .catch((error) => {
          notification.error({
            duration: 4,
            message: ErrorInfo.message(actionType.filterProductList_Failed),
            description: ErrorInfo.description(error),
          });
          dispatch({
            type: actionType.filterProductList_Failed,
            payload: error,
          });
          throw error;
        });
    };
  }
  function resetWarehouseProductList() {
    return (dispatch) =>
      dispatch({
        type: actionType.resetWarehouseProductList,
        payload: {},
      });
  }
  const addMissingLocaleKeysAsEmptyStrings = (products) => {
    const newProducts = products
      ? products.map((product) => {
        Object.keys(supportedLanguages).map((languageKey) => {
          if (
            languageKey != 'en' &&
            languageKey != 'tr' &&
            product.fullName &&
            !product.fullName[languageKey]
          ) {
            product.fullName[languageKey] = '';
          }
          if (
            languageKey != 'en' &&
            languageKey != 'tr' &&
            product.picURL &&
            !product.picURL[languageKey]
          ) {
            product.picURL[languageKey] = '';
          }
        });
        return product;
      })
      : [];
    return newProducts;
  };
