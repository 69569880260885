import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form, Button, Row, Col,
} from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import './WarehouseStockList.scss';
import { ProductMultiSelect, LocationMultiSelect } from './filterFields';
/*----------------------------------------------------------------------------*/
import { productAction, warehouseLocationAction } from '../../../actions';

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class WarehouseStockFilter extends Component {
  settings = {
    main: {
      className: 'StockMovementLogFilter_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    const {
      CWId,
      onGetProductList,
      onGetLocationList,
      locationOperationTypes,
    } = this.props;
    onGetProductList(CWId);
    onGetLocationList(CWId, locationOperationTypes);
  }

  stateFactory = () => ({
    ...this.emptyTableFilter(),
  });

  emptyTableFilter = () => ({
    products: [],
    locations: [],
  });

  TableFilterForm = (props) => (
    <div>
      <Form {...this.formItemLayout}>
        <Row>
          <Col span={12} style={{ padding: '0px 5px' }}>
            <this.LocationTypeField />
          </Col>
          <Col span={12} style={{ padding: '0px 5px' }}>
            <this.ProductField />
          </Col>
        </Row>
        <Row>
          <Col span={2} style={{ padding: '0px 5px' }}>
            <this.ClearFilterButton />
          </Col>
          <Col offset={20} span={2} style={{ padding: '0px 5px' }}>
            <this.FilterButton />
          </Col>
        </Row>
      </Form>
    </div>
  );

  ProductField = () => {
    const { productList } = this.props;
    const list = (productList && productList.data) || null;
    return (
      <Form.Item>
        <ProductMultiSelect
          placeholder={this.props.t(
            'pages.Stock.WarehouseStockList.pageForm.products',
          )}
          list={list}
          onChange={(products) => {
            this.setState({ products });
          }}
          value={this.state.products}
          loading={productList.loading}
        />
      </Form.Item>
    );
  };

  LocationTypeField = () => {
    const { locationList } = this.props;
    const list = (locationList && locationList.data) || null;
    return (
      <Form.Item>
        <LocationMultiSelect
          placeholder={this.props.t(
            'pages.Stock.WarehouseStockList.pageForm.locations',
          )}
          list={list}
          onChange={(locations) => {
            this.setState({ locations });
          }}
          value={this.state.locations}
        />
      </Form.Item>
    );
  };

  ClearFilterButton = () => (
    <Form.Item>
      <Button
        block
        onClick={() => {
          this.clearFilter();
        }}
      >
        {this.props.t('pages.Stock.WarehouseStockList.button.clean')}
      </Button>
    </Form.Item>
  );

  clearFilter = () => {
    this.setState(
      {
        ...this.emptyTableFilter(),
      },
      this.props.onClear,
    );
  };

  FilterButton = () => (
    <Form.Item>
      <Button
        type="primary"
        block
        onClick={() => {
          const filter = this.getTableFilter();
          this.props.onChange(filter);
        }}
      >
        {this.props.t('pages.Stock.WarehouseStockList.button.filter')}
      </Button>
    </Form.Item>
  );

  getTableFilter = () => {
    const { products, locations } = this.state;
    const filter = {};

    if (products && products.length) {
      filter.products = products.map((p) => p.id);
    }

    if (locations && locations.length) {
      filter.locations = locations;
      filter.locationOperationTypes = locations.map((l) => l.operationType);
    }
    console.log(filter);
    return filter;
  };

  render() {
    return (
      <div {...this.settings.main}>
        <this.TableFilterForm />
      </div>
    );
  }
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse.id,
  currentWarehouse: state.auth.currentWarehouse,
  locationList: state.warehouseLocation.warehouseLocationList,
  productList: state.product.productList,
});

const mapDispatchToProps = (dispatch) => ({
  onGetProductList: (warehouseId) => {
    dispatch(
      productAction.filterProductList(
        {
          fields: 'barcodes picURL fullName packagingInfo',
          includeDefaultFields: false,
          warehouseId,
        },
        false,
      ),
    );
  },
  onGetLocationList: (warehouseId, operationTypes) => {
    dispatch(
      warehouseLocationAction.filterWarehouseLocations({
        warehouseId,
        operationTypes,
      }),
    );
  },
});

const WarehouseStockFilterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(WarehouseStockFilter));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { WarehouseStockFilterConnected as WarehouseStockFilter };
