/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import {
  Card,
  Descriptions,
  Table,
  Avatar,
  Dropdown,
  Button,
  Icon,
  Menu,
  Modal,
  Form,
  Row,
  Col,
  Tag,
  Collapse,
} from 'antd';
import { Link } from 'react-router-dom';
/*----------------------------------------------------------------------------*/
import './WarehousePurchaseOrderDetail.scss';
import { WpoRejectionTypeSelect } from './WpoRejectionTypeSelect';
/*----------------------------------------------------------------------------*/
import {
  Spinner,
  CopyToClipboard,
  ActionTimeline,
} from './../../../../components';
import {
  getTranslation,
  constants,
  getDateTimeFormatString,
} from './../../../../shared';
import { warehousePurchaseOrderAction } from './../../../../actions';
import { NextStep, NextStepDomainTypes } from '../../shared/NextStep/NextStep';
import { pageLoadEvents, WarehousePurchaseOrderDetailEvents } from '../../../../services/events';
import { pageView, track } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class WarehousePurchaseOrderDetail extends Component {
  settings = {
    main: {
      className: 'WarehousePurchaseOrderDetail_main',
    },
    card: {
      title: this.props.t('pages.Operation.PurchaseOrder.wpoDetail.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  formItemLayout = {
    style: {
      marginTop: 15,
      marginBottom: 15,
    },
    labelCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 8, offset: 0 },
    },
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 16, offset: 0 },
    },
  };

  supplierApiStatusTagColor = {
    [constants.SUPPLIER_API_STATUSES.ERR]: 'red',
    [constants.SUPPLIER_API_STATUSES.NONE]: 'blue',
    [constants.SUPPLIER_API_STATUSES.SENDING]: 'yellow',
    [constants.SUPPLIER_API_STATUSES.SENT]: 'green',
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    return {
      wpoRejectionType: null,
      waybill: null,
      isOpenModalConclude: false,
      isWaybillModalOpen: false,
      rotate: 0,
      isClickedCancelWpoButton: false,
      isClickedApproveWpoButton: false,
      isClickedRejectWpoButton: false,
    };
  };

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_WPO_DETAIL });
    this.getDetail();
  }

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {loading ? <Spinner /> : <this.PageDetail />}
        </Card>
      </div>
    );
  }

  CardExtra = () => {
    return (
      <Dropdown overlay={this.MenuAction}>
        <Button style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.t(
            'pages.Operation.PurchaseOrder.wpoDetail.button.actions',
          )}{' '}
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  MenuAction = () => {
    return (
      <Menu>
        <Menu.Item key="CreateButton">
          <this.ApproveButton />
        </Menu.Item>
        <Menu.Item key="ClearButton">
          <this.RejectButton />
        </Menu.Item>
        <Menu.Item key="CancelButton">
          <this.CancelWpoButton />
        </Menu.Item>
      </Menu>
    );
  };

  ApproveButton = () => {
    const { isClickedApproveWpoButton } = this.state;
    return (
      <Button
        block
        loading={isClickedApproveWpoButton}
        disabled={!this.isOperationAvailable() || isClickedApproveWpoButton}
        onClick={() => {
          this.setState({ isClickedApproveWpoButton: true });
          this.approveWpo();
          track(WarehousePurchaseOrderDetailEvents.WPO_DETAIL_APPROVE_CLICKED);
        }}
      >
        {this.props.t('pages.Operation.PurchaseOrder.wpoDetail.button.approve')}
      </Button>
    );
  };

  RejectButton = (props) => {
    const { wpoRejectionType, isClickedRejectWpoButton } = this.state;
    return (
      <div>
        <Button
          block
          loading={isClickedRejectWpoButton}
          disabled={!this.isOperationAvailable() || isClickedRejectWpoButton}
          onClick={() => {
            this.setState({
              isOpenModalConclude: true,
              isClickedRejectWpoButton: true,
            });
          track(WarehousePurchaseOrderDetailEvents.WPO_DETAIL_REJECT_CLICKED);
          }}
        >
          {this.props.t(
            'pages.Operation.PurchaseOrder.wpoDetail.button.reject',
          )}
        </Button>
        <Modal
          okButtonProps={{
            disabled: !wpoRejectionType,
          }}
          title={this.props.t(
            'pages.Operation.PurchaseOrder.wpoDetail.modal.reject',
          )}
          visible={this.state.isOpenModalConclude}
          onOk={() => {
            this.rejectWpo();
            this.setState(this.stateFactory());
            this.setState({ isClickedRejectWpoButton: true });
          }}
          onCancel={() => {
            this.setState(this.stateFactory());
          }}
        >
          <this.WpoRejectForm />
        </Modal>
      </div>
    );
  };

  WpoRejectForm = (props) => {
    return (
      <Form>
        <this.WpoRejectionTypeField />
      </Form>
    );
  };

  WpoRejectionTypeField = () => {
    const { wpoRejectionType } = this.state;
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.PurchaseOrder.wpoDetail.form.reject',
        )}
      >
        <WpoRejectionTypeSelect
          placeholder={this.props.t(
            'pages.Operation.PurchaseOrder.wpoDetail.form.reject',
          )}
          value={wpoRejectionType}
          onChange={(wpoRejectionType) => {
            this.setState({ wpoRejectionType });
          }}
        />
      </Form.Item>
    );
  };

  CancelWpoButton = () => {
    const { isClickedCancelWpoButton } = this.state;
    return (
      <Button
        block
        loading={isClickedCancelWpoButton}
        disabled={!this.isCancelWpoAvailable() || isClickedCancelWpoButton}
        onClick={() => {
          this.setState({ isClickedCancelWpoButton: true });
          this.cancelWpo();
          track(WarehousePurchaseOrderDetailEvents.WPO_DETAIL_CANCEL_CLICKED);
        }}
      >
        {this.props.t('pages.Operation.PurchaseOrder.wpoDetail.button.cancel')}
      </Button>
    );
  };

  cancelWpo = () => {
    const { onCancelWarehousePurchaseOrder, id } = this.props;
    onCancelWarehousePurchaseOrder(id)
      .then(() => {
        this.setState({ isClickedCancelWpoButton: false });
      })
      .catch(() => {
        this.setState({ isClickedCancelWpoButton: false });
      });
  };

  isCancelWpoAvailable = () => {
    const { detail } = this.props;
    try {
      if (
        detail.status === constants.PURCHASE_ORDER_STATUSES.CREATED ||
        detail.status === constants.PURCHASE_ORDER_STATUSES.ORDERED
      ) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  PageDetail = (props) => {
    return (
      <div>
        <this.Description />
        <Row>
          <Col span={14} style={{ overflow: 'auto' }}>
            <this.Table />
          </Col>
          <Col span={10}>
            <this.WaybillTable />
          </Col>
        </Row>

        <this.WaybillModal />
        <this.EWaybillInformation />

        <ActionTimeline
          actions={this.props.detail}
          translate={getTranslation.operationPurchaseOrderStatus}
        />
      </div>
    );
  };

  Description = (props) => {
    const { detail } = this.props;
    if (!detail) {
      return null;
    }

    return (
      <Row>
        <Col span={8}>
          <Descriptions column={1}>
            <Descriptions.Item
              label={this.props.t(
                'pages.Operation.PurchaseOrder.wpoDetail.description.id',
              )}
            >
              <CopyToClipboard message={detail.id} />
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t(
                'pages.Operation.PurchaseOrder.wpoDetail.description.no',
              )}
            >
              <CopyToClipboard message={detail.no} />
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t(
                'pages.Operation.PurchaseOrder.wpoDetail.description.status',
              )}
            >
              {getTranslation.operationPurchaseOrderStatus(detail.status)}
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t(
                'pages.Operation.PurchaseOrder.wpoDetail.description.supplierStatus',
              )}
            >
              {
                <Tag
                  color={
                    this.supplierApiStatusTagColor[detail.supplierApiStatus]
                  }
                >
                  {getTranslation.supplierApiStatus(detail.supplierApiStatus)}
                </Tag>
              }
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={8}>
          <Descriptions column={1}>
            <Descriptions.Item
              label={this.props.t(
                'pages.Operation.PurchaseOrder.wpoDetail.description.warehouse',
              )}
            >
              {detail.warehouse.name}
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t(
                'pages.Operation.PurchaseOrder.wpoDetail.description.location',
              )}
            >
              {(detail.job &&
                detail.job.tasks &&
                detail.job.tasks[0] &&
                detail.job.tasks[0].data &&
                detail.job.tasks[0].data.locationBarcode) ||
                '-'}
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t(
                'pages.Operation.PurchaseOrder.wpoDetail.description.rejectReason',
              )}
            >
              {(detail.job &&
                detail.job.tasks &&
                detail.job.tasks[0] &&
                detail.job.tasks[0].data &&
                detail.job.tasks[0].data.rejectionType &&
                getTranslation.warehousePurchaseOrderRejectionType(
                  detail.job.tasks[0].data.rejectionType,
                )) ||
                '-'}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={8}>
          <Descriptions column={1}>
            <Descriptions.Item
              label={this.props.t(
                'pages.Operation.PurchaseOrder.wpoDetail.description.supplier',
              )}
            >
              {detail.supplier.name}
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t(
                'pages.Operation.PurchaseOrder.wpoDetail.description.purchaseOrder',
              )}
            >
              <this.LinkToPurchaseOrder id={detail.purchaseOrder} />
            </Descriptions.Item>
            {detail.isMainWarehousePurchaseOrder &&
              <Descriptions.Item
                label={this.props.t(
                  'pages.Operation.PurchaseOrder.wpoDetail.description.nextStep',
                )}
              >
                <NextStep
                  transferStatus={detail.status}
                  domainType={NextStepDomainTypes.WPO}
                />
              </Descriptions.Item>
            }
            <Descriptions.Item
              label={this.props.t(
                'pages.Operation.PurchaseOrder.wpoDetail.description.createdAt',
              )}
            >
              {moment(detail.createdAt)
                .local()
                .format(getDateTimeFormatString())}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    );
  };

  LinkToPurchaseOrder = (props) => {
    const { id } = props;
    const url = `/stock-operation/purchase-order/detail/${id}`;
    return (
      <Link to={url}>
        {this.props.t(
          'pages.Operation.PurchaseOrder.wpoDetail.link.purchaseOrder',
        )}
      </Link>
    );
  };

  Table = () => {
    return (
      <Table
        size="small"
        columns={this.prepareColumns()}
        dataSource={this.prepareData()}
        rowKey={(record) => {
          return record.item.id;
        }}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  prepareColumns = (props) => {
    return [
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.wpoDetail.table.image',
        ),
        dataIndex: 'item.picURL',
        key: 'picURL',
        width: 72,
        render: (picURL) => {
          return (
            <Avatar
              className="product-image grow"
              shape="square"
              src={getTranslation.obj(picURL)}
            />
          );
        },
      },
      {
        title: this.props.t('pages.Operation.PurchaseOrder.wpoDetail.table.id'),
        dataIndex: 'item.id',
        key: 'item.id',
        render: (id) => (
          <CopyToClipboard message={id} inner={<Icon type="copy" />} />
        ),
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.wpoDetail.table.name',
        ),
        dataIndex: 'item.fullName',
        key: 'item.fullName',
        render: (fullName) => (
          <CopyToClipboard message={getTranslation.obj(fullName)} />
        ),
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.wpoDetail.table.barcodes',
        ),
        dataIndex: 'item.barcodes',
        key: 'item.barcodes',
        render: (barcodes) => {
          return (
            barcodes &&
            barcodes.map((barcode) => (
              <CopyToClipboard key={barcode} message={barcode} />
            ))
          );
        },
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.wpoDetail.table.orderedCount',
        ),
        dataIndex: 'orderedCount',
        key: 'orderedCount',
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.wpoDetail.table.receivedCount',
        ),
        dataIndex: 'receivedCount',
        key: 'receivedCount',
      },
    ];
  };

  prepareData = (props) => {
    const { detail } = this.props;
    if (!detail || !detail.items || !detail.items.length) {
      return null;
    }
    const { items } = detail;
    let data = [...items];
    if (detail && detail.job && detail.job.tasks && detail.job.tasks.length) {
      const task = detail.job.tasks[0];
      const taskItems = task.data.items;
      data = data.map((item) => {
        return {
          ...item,
          receivedCount: taskItems.find((taskItem) => {
            return taskItem.item === item.item.id;
          })
            ? taskItems.find((taskItem) => {
              return taskItem.item === item.item.id;
            }).receivedCount
            : '-',
        };
      });
    }

    return data;
  };

  EWaybillInformation = () => {
    const { detail } = this.props;
    if (
      detail.supplier &&
      detail.supplier.isEWaybillIntegrated &&
      detail.eWaybill
    ) {
      return (
        <Card
          style={{ padding: '20px' }}
          title={this.props.t(
            'pages.Operation.PurchaseOrder.wpoDetail.eWaybill.title',
          )}
        >
          <div style={{ width: '100%' }}>
            {detail.eWaybill &&
              detail.eWaybill.waybills &&
              detail.eWaybill.waybills.map((waybill, index) => {
                return (
                  <Collapse collapsible="header" style={{ margin: '20px 0' }}>
                    <Collapse.Panel
                      header={`${this.props.t(
                        'pages.Operation.PurchaseOrder.wpoDetail.eWaybill.eWaybillNo',
                      )}: ${waybill.ss}  -  ${this.props.t(
                        'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.date',
                      )}: ${moment(new Date(waybill.date))
                        .local()
                        .format(getDateTimeFormatString())}`}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    >
                      <img
                        className="wayBillImage"
                        src={waybill.imageUrl}
                      />
                      <Table
                        size="small"
                        columns={this.prepareEWaybillColumns()}
                        dataSource={waybill.items}
                        rowKey={(record) => {
                          return record.itemId;
                        }}
                      />
                    </Collapse.Panel>
                  </Collapse>
                );
              })}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                type="primary"
                onClick={this.getEWaybill}
                loading={this.props.eWaybillLoading}
                style={{ margin: '0 auto' }}
              >
                {this.props.t(
                  'pages.Operation.PurchaseOrder.wpoDetail.eWaybill.button',
                )}
              </Button>
            </div>
          </div>
        </Card>
      );
    }
    if (detail.supplier.isEWaybillIntegrated && !detail.eWaybill) {
      return (
        <Card
          style={{ margin: '20px 0' }}
          title={this.props.t(
            'pages.Operation.PurchaseOrder.wpoDetail.eWaybill.title',
          )}
        >
          <div className="eWaybillMessage">
            <Icon
              type="exception"
              style={{ fontSize: '50px', marginBottom: '20px' }}
            />
            {this.changeErrorText()}
            <Button
              type="primary"
              onClick={this.getEWaybill}
              loading={this.props.eWaybillLoading}
            >
              {this.props.t(
                'pages.Operation.PurchaseOrder.wpoDetail.eWaybill.button',
              )}
            </Button>
          </div>
        </Card>
      );
    }
    return null;
  };

  changeErrorText = () => {
    if (!this.props.eWaybillError) {
      return (
        <p>
          <strong>
            {this.props.t(
              'pages.Operation.PurchaseOrder.wpoDetail.eWaybill.message',
            )}{' '}
          </strong>
        </p>
      );
    }

    if (this.props.eWaybillError === -1) {
      return (
        <p>
          <strong>
            {this.props.t(
              'pages.Operation.PurchaseOrder.wpoDetail.eWaybill.messageForDincer',
            )}{' '}
          </strong>
        </p>
      );
    }

    return (
      <p>
        <strong>
          {this.props.t(
            'pages.Operation.PurchaseOrder.wpoDetail.eWaybill.messageForMicroserviceError',
          )}
        </strong>
      </p>
    );
  };

  prepareEWaybillColumns = (item) => {
    return [
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.detail.subTable.productId',
        ),
        dataIndex: 'itemId',
        key: 'id',
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.wpoDetail.eWaybill.table.unit',
        ),
        dataIndex: 'unit',
        key: 'unit',
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.wpoDetail.eWaybill.table.unitCount',
        ),
        dataIndex: 'unitCount',
        key: 'unitCount',
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.list.subTable.count',
        ),
        dataIndex: 'count',
        key: 'count',
      },
    ];
  };

  WaybillTable = () => {
    return (
      <Table
        size="small"
        columns={this.prepareWaybillColumns()}
        dataSource={this.prepareWaybillData()}
        rowKey={(record) => {
          return record.id;
        }}
        pagination={{
          defaultPageSize: 1,
          showSizeChanger: true,
          pageSizeOptions: ['1', '10', '20', '50', '100'],
        }}
      />
    );
  };

  prepareWaybillColumns = (props) => {
    return [
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.wpoDetail.table.image',
        ),
        dataIndex: 'image',
        key: 'image',
        width: 72,
        render: (image, record) => {
          return (
            <div
              style={{
                width: '100%',
                height: 'auto',
              }}
            >
              <div style={{ fontSize: 14, marginBottom: 5, fontWeight: 500 }}>
                {this.props.t(
                  'pages.Operation.PurchaseOrder.wpoDetail.table.no',
                )}
                : {record.ss} <this.RotateButton image={image} type="table" />
              </div>
              <div
                style={{
                  width: '100%',
                  height: 'auto',
                  overflow: 'auto',
                }}
              >
                <img
                  alt="waybill"
                  id={image}
                  style={{
                    maxWidth: 'initial',
                    width: '100%',
                    height: 'auto',
                    objectFit: 'scale-down',
                    transformOrigin: 'center center',
                    transform: `rotate(${this.state.rotate}deg)`,
                  }}
                  className="waybill-table-image"
                  src={image}
                  onClick={() => {
                    this.setState({ waybill: record });
                    this.setState({ isWaybillModalOpen: true });
                  }}
                />
              </div>
            </div>
          );
        },
      },
    ];
  };

  DetailWaybillButton = (props) => {
    const { waybill } = props;
    return (
      <Button
        size="small"
        onClick={() => {
          this.setState({ waybill });
          this.setState({ isWaybillModalOpen: true });
        }}
      >
        {this.props.t('pages.Operation.PurchaseOrder.wpoDetail.button.detail')}
      </Button>
    );
  };

  WaybillModal = () => {
    const { waybill } = this.state;
    if (!waybill) {
      return null;
    }
    return (
      <Modal
        width="70%"
        title={waybill.ss}
        visible={this.state.isWaybillModalOpen}
        footer={[
          <this.RotateButton
            key="rotate-button"
            image={waybill.image}
            type="modal"
          />,
        ]}
        onOk={() => {
          this.setState({ isWaybillModalOpen: false });
          this.setState({ waybill: null });
        }}
        onCancel={() => {
          this.setState({ isWaybillModalOpen: false });
          this.setState({ waybill: null });
        }}
      >
        <div style={{ overflow: 'auto', width: '100%', height: 'auto' }}>
          <img
            alt={`waybill-${waybill.ss}`}
            id={waybill.image}
            className="waybill-modal-image"
            src={waybill.image}
            style={{
              maxWidth: 'initial',
              width: '100%',
              height: 'auto',
              objectFit: 'scale-down',
              transformOrigin: 'center center',
              transform: `rotate(${this.state.rotate}deg)`,
            }}
          />
        </div>
      </Modal>
    );
  };

  prepareWaybillData = (props) => {
    const { detail } = this.props;
    if (
      !detail ||
      !detail.waybill ||
      !detail.waybill.waybillInfos ||
      !detail.waybill.waybillInfos.length
    ) {
      return [];
    }
    const { waybillInfos } = detail.waybill;
    return waybillInfos;
  };

  getDetail = () => {
    const { id, onGetWarehousePurchaseOrderDetail } = this.props;
    onGetWarehousePurchaseOrderDetail(id);
  };

  getEWaybill = () => {
    const { id, onGetWarehousePurchaseOrderEWaybill } = this.props;
    onGetWarehousePurchaseOrderEWaybill(id);
  };

  approveWpo = () => {
    const { id, onApproveWarehousePurchaseOrder } = this.props;
    onApproveWarehousePurchaseOrder(id)
      .then(() => {
        this.setState({ isClickedApproveWpoButton: false });
      })
      .catch(() => {
        this.setState({ isClickedApproveWpoButton: false });
      });
  };

  rejectWpo = () => {
    const { id, onRejectWarehousePurchaseOrder } = this.props;
    const payload = this.getRejectPayload();
    onRejectWarehousePurchaseOrder(id, payload)
      .then(() => {
        this.setState({ isClickedRejectWpoButton: false });
      })
      .catch(() => {
        this.setState({ isClickedRejectWpoButton: false });
      });
  };

  getRejectPayload = () => {
    const { wpoRejectionType } = this.state;
    const payload = {
      rejectionType: wpoRejectionType.key,
    };
    return payload;
  };

  isOperationAvailable = () => {
    const { detail } = this.props;
    if (
      detail &&
      detail.status ===
      constants.WAREHOUSE_PURCHASE_ORDER_STATUSES.WAITING_FOR_APPROVAL
    ) {
      return true;
    }
    return false;
  };

  RotateButton = (props) => {
    return (
      <Button
        onClick={() => {
          this.setState((state) => {
            return {
              ...state,
              rotate: state.rotate + 90,
            };
          });
        }}
      >
        <i className="fa fa-repeat" aria-hidden="true" />
      </Button>
    );
  };

  sendToWMS = () => {
    const { detail, onSendToWmsWarehousePurchaseOrder } = this.props;
    if (!detail) {
      return null;
    }
    onSendToWmsWarehousePurchaseOrder(detail.id);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.warehousePurchaseOrder.detail.loading,
  detail: state.warehousePurchaseOrder.detail.data,
  eWaybillError: state.warehousePurchaseOrder.eWaybill.error,
  eWaybillLoading: state.warehousePurchaseOrder.eWaybill.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onGetWarehousePurchaseOrderDetail: (id) => {
    dispatch(warehousePurchaseOrderAction.getWarehousePurchaseOrderDetail(id));
  },
  onApproveWarehousePurchaseOrder: (warehousePurchaseOrderId) => {
    return dispatch(
      warehousePurchaseOrderAction.approveWarehousePurchaseOrder(
        warehousePurchaseOrderId,
      ),
    );
  },
  onRejectWarehousePurchaseOrder: (warehousePurchaseOrderId, data) => {
    return dispatch(
      warehousePurchaseOrderAction.rejectWarehousePurchaseOrder(
        warehousePurchaseOrderId,
        data,
      ),
    );
  },
  onCancelWarehousePurchaseOrder: (warehousePurchaseOrderId) => {
    return dispatch(
      warehousePurchaseOrderAction.cancelWarehousePurchaseOrder(
        warehousePurchaseOrderId,
      ),
    );
  },
  onGetWarehousePurchaseOrderEWaybill: (id) => {
    dispatch(
      warehousePurchaseOrderAction.getWarehousePurchaseOrderEWaybill(id),
    );
  },
});

const WarehousePurchaseOrderDetailConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(WarehousePurchaseOrderDetail));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { WarehousePurchaseOrderDetailConnected as WarehousePurchaseOrderDetail };
