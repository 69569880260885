/* eslint-disable no-restricted-globals */
import _ from 'lodash';
import moment from 'moment';
import i18n from '../i18n';

export const createHashMap = (list = [], opts = { field: '_id' }) => {
  const map = {};
  list.forEach((elem) => {
    map[elem[opts.field]] = elem;
  });
  return map;
};

export const getErrorMessage = (payload) => {
  if (
    payload &&
    payload.response &&
    payload.response.data &&
    payload.response.data.message &&
    typeof payload.response.data.message === 'string'
  ) {
    return payload.response.data.message;
  }
  if (
    payload.error &&
    payload.error.response &&
    payload.error.response.data &&
    payload.error.response.data.message &&
    typeof payload.error.response.data.message === 'string'
  ) {
    return payload.error.response.data.message;
  }
  return i18n.t('main.unknown_error');
};

export const isEmptyOrEmptyString = (s) => (_.isString(s) ? _.trim(s) === '' : _.isEmpty(s));

export const getDataByKey = (obj, keyString) => keyString.split('.').reduce((o, i) => o[i], obj);

export const ENTER_KEY_CODE = 13;

export const msToTime = (duration, lang = 'tr') => {
  if (isNaN(duration)) {
    return null;
  }
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  const days = Math.floor((duration / (1000 * 60 * 60 * 24)) % 24);

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  const day = lang === 'tr' ? 'gün' : 'day';

  const time =
    days > 0
      ? `${days} ${day} - ${hours}:${minutes}:${seconds}`
      : `${hours}:${minutes}:${seconds}`;

  return time;
};

export function getCountry() {
  const country = JSON.parse(localStorage.getItem('country'));
  if (country && country.code && country.code.alpha2) {
    return country;
  }
  return null;
}

export function transfromReasonResponse(reasonsResponse) {
  const reasons = reasonsResponse.data.blockedStockReasons;
  let mainReasons = reasons.filter((reason) => reason.isMainReason);
  const subReasons = reasons.filter((reason) => !reason.isMainReason);

  subReasons.forEach((sr) => {
    mainReasons = mainReasons.map((mr) => {
      if (mr.id === sr.mainReason) {
        if (!mr.subReasons) {
          mr.subReasons = [];
        }
        mr.subReasons.push(sr);
      }
      return mr;
    });
  });
  return { blockedStockReasons: mainReasons };
}

export function getCountryCode() {
  const country = JSON.parse(localStorage.getItem('country'));
  if (country && country.code && country.code.alpha2) {
    return country.code.alpha2.toLowerCase();
  }
  return null;
}

export function getDateTimeFormatString() {
  const selectedCountryCode = getCountryCode();
  const defaultDateTimeFormat = 'Y-MM-DD HH:mm';

  if (!selectedCountryCode) {
    return defaultDateTimeFormat;
  }

  const countryDateTimeFormatMapping = {
    tr: 'Y-MM-DD HH:mm',
    gb: 'Y-MM-DD HH:mm',
    fr: 'Y-MM-DD HH:mm',
    de: 'Y-MM-DD HH:mm',
    nl: 'Y-MM-DD HH:mm',
    it: 'Y-MM-DD HH:mm',
    pt: 'Y-MM-DD HH:mm',
    es: 'Y-MM-DD HH:mm',
    xn: 'MMM D, YYYY, h:mm:ss a',
    xi: 'MMM D, YYYY, h:mm:ss a',
    xm: 'MMM D, YYYY, h:mm:ss a',
    xj: 'MMM D, YYYY, h:mm:ss a',
  };

  return (
    countryDateTimeFormatMapping[selectedCountryCode] || defaultDateTimeFormat
  );
}

export function getDateFormatString() {
  const selectedCountryCode = getCountryCode();
  const defaultDateFormat = 'Y-MM-DD';

  if (!selectedCountryCode) {
    return defaultDateFormat;
  }

  const countryDateFormatMapping = {
    tr: 'Y-MM-DD',
    gb: 'Y-MM-DD',
    fr: 'Y-MM-DD',
    de: 'Y-MM-DD',
    nl: 'Y-MM-DD',
    it: 'Y-MM-DD',
    pt: 'Y-MM-DD',
    es: 'Y-MM-DD',
    xn: 'MMM D, YYYY',
    xi: 'MMM D, YYYY',
    xm: 'MMM D, YYYY',
    xj: 'MMM D, YYYY',
  };

  return countryDateFormatMapping[selectedCountryCode] || defaultDateFormat;
}

export function getDefaultCountryLanguage() {
  const country = JSON.parse(localStorage.getItem('country'));
  return country && country.defaultLanguageCode;
}

export const getDefaultDateRange = () => {
  const today = new Date(Date.now());
  const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
  return {
    startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
    endDate: new Date(today.setHours(23, 59, 59, 999)),
  };
};

export const getDefaultDateDropdownRange = () => {
  return {
    startDate: moment().subtract(1, 'weeks').startOf('day'),
    endDate: moment().endOf('day'),
  };
};
