import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Button, Card } from 'antd';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './PurchaseOrderList.scss';
import { PurchaseOrderFilter } from './PurchaseOrderFilter';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard } from './../../../../components';
import { getTranslation, getDateTimeFormatString } from './../../../../shared';
import { purchaseOrderAction } from './../../../../actions';
import { pageLoadEvents, PurchaseOrderListEvents } from '../../../../services/events';
import { pageView, track } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class PurchaseOrderList extends Component {
  settings = {
    main: {
      className: 'PurchaseOrderList_main',
    },
    card: {
      title: this.props.t('pages.Operation.PurchaseOrder.list.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  dateFormat = getDateTimeFormatString();

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_PO_LIST });
    this.getList();
  }

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      filter: this.getDefaultFilter(),
    };
  };

  getDefaultFilter = () => {
    return null;
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageFilter />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    return (
      <div style={{ display: 'flex' }}>
        <this.CreateNewButton />
      </div>
    );
  };

  PageFilter = () => {
    return (
      <PurchaseOrderFilter
        defaultDateRange={this.defaultDateRange}
        onChange={this.setTableFilter}
        onClear={this.clearTableFilter}
      />
    );
  };

  CreateNewButton = (props) => {
    const url = '/stock-operation/purchase-order/create';
    return (
      <Link to={url}>
        <Button type="danger" style={{ marginLeft: 10 }} onClick={() => track(PurchaseOrderListEvents.PO_LIST_NEW_TPS_CLICKED)}>
          {this.props.t(
            'pages.Operation.PurchaseOrder.list.extra.createNewPurchaseOrder',
          )}
        </Button>
      </Link>
    );
  };

  PageTable = () => {
    return (
      <Table
        size="small"
        dataSource={this.prepareData(this.props)}
        columns={this.prepareColumns(this.props)}
        rowKey={(record) => {
          return record.id;
        }}
        expandedRowRender={this.expandedRowRender}
        pagination={{ ...this.state.pagination, total: this.props.totalCount }}
        loading={{ indicator: <Spinner />, spinning: this.props.loading }}
        onChange={this.handleTableChange}
      />
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState((state) => {
      return {
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      };
    }, this.getList);
  };

  prepareColumns = (props) => {
    return [
      {
        title: this.props.t('pages.Operation.PurchaseOrder.list.table.id'),
        dataIndex: 'table.id',
        key: 'id',
        render: (id) => <CopyToClipboard message={id} />,
      },
      {
        title: this.props.t('pages.Operation.PurchaseOrder.list.table.no'),
        dataIndex: 'table.no',
        key: 'no',
        render: (no) => <CopyToClipboard message={no} />,
      },
      {
        title: this.props.t('pages.Operation.PurchaseOrder.list.table.status'),
        dataIndex: 'table.status',
        key: 'status',
        render: (status) => getTranslation.operationPurchaseOrderStatus(status),
      },
      {
        title: this.props.t('pages.Operation.PurchaseOrder.list.table.date'),
        dataIndex: 'table.date',
        key: 'date',
      },
      {
        title: '',
        dataIndex: 'table.id',
        key: 'detail',
        render: (id) => {
          return <this.DetailButton id={id} onClick={() => track(PurchaseOrderListEvents.PO_LIST_DETAIL_CLICKED)}/>;
        },
      },
    ];
  };

  DetailButton = (props) => {
    const { id } = props;
    const url = `/stock-operation/purchase-order/detail/${id}`;
    return (
      <Link to={url}>
        <Button size="small">
          {this.props.t('pages.Operation.PurchaseOrder.list.table.detail')}
        </Button>
      </Link>
    );
  };

  prepareData = (props) => {
    const { list } = props;
    if (!list || !list.length) {
      return [];
    }
    return list.map((item) => {
      return {
        ...item,
        table: {
          date: moment(new Date(item.createdAt))
            .local()
            .format(this.dateFormat),
          status: item.status,
          no: item.no,
          id: item.id,
          key: item.id,
        },
      };
    });
  };

  expandedRowRender = (record, index, indent, expanded) => {
    return (
      <Table
        size="small"
        columns={this.prepareExtendedColumns(record)}
        dataSource={this.prepareExtendedData(record)}
        rowKey={(record) => {
          return record.id;
        }}
        pagination={false}
      />
    );
  };

  prepareExtendedColumns = (record) => {
    return [
      {
        title: this.props.t('pages.Operation.PurchaseOrder.list.subTable.id'),
        dataIndex: 'id',
        key: 'id',
        render: (id) => <CopyToClipboard message={id} />,
      },
      {
        title: this.props.t('pages.Operation.PurchaseOrder.list.subTable.no'),
        dataIndex: 'no',
        key: 'no',
        render: (no) => <CopyToClipboard message={no} />,
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.list.subTable.status',
        ),
        dataIndex: 'status',
        key: 'status',
        render: (status) => getTranslation.operationPurchaseOrderStatus(status),
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.list.subTable.warehouse',
        ),
        dataIndex: 'warehouse',
        key: 'warehouse',
        render: (warehouse) => warehouse.name,
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.list.subTable.supplier',
        ),
        dataIndex: 'supplier',
        key: 'supplier',
        render: (supplier) => supplier.name,
      },
      {
        title: this.props.t('pages.Operation.PurchaseOrder.list.subTable.date'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) => {
          return moment(new Date(createdAt))
            .local()
            .format(getDateTimeFormatString());
        },
      },
      {
        key: 'detail',
        dataIndex: 'id',
        render: (id) => {
          return <this.DetailWpoButton id={id} />;
        },
      },
    ];
  };

  DetailWpoButton = (props) => {
    const { id } = props;
    const url = `/stock-operation/warehouse-purchase-order/detail/${id}`;
    return (
      <Link to={url}>
        <Button size="small">
          {this.props.t('pages.Operation.PurchaseOrder.list.table.detail')}
        </Button>
      </Link>
    );
  };

  prepareExtendedData = (record) => {
    if (
      !record.warehousePurchaseOrders ||
      !record.warehousePurchaseOrders.length
    ) {
      return [];
    }
    return record.warehousePurchaseOrders;
  };

  getList = () => {
    const filter = this.getFilter();
    const query = this.getQuery();
    if (
      filter &&
      filter.createdAt &&
      filter.createdAt.startDate &&
      filter.createdAt.endDate
    ) {
      this.props.onFilterPurchaseOrderList(query, filter);
    }
  };

  getFilter = () => {
    const filter = this.state.filter || {};
    if (!filter.createdAt) {
      filter.createdAt = this.defaultDateRange;
    }
    return filter;
  };

  getQuery = () => {
    const { query } = this.state;
    return query;
  };

  setTableFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      };
    }, this.getList);
  };

  clearTableFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      };
    }, this.getList);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.purchaseOrder.list.loading,
  list: state.purchaseOrder.list.data,
  totalCount: state.purchaseOrder.list.total,
});

const mapDispatchToProps = (dispatch) => ({
  onFilterPurchaseOrderList: (query, data) =>
    dispatch(purchaseOrderAction.filterPurchaseOrderList(query, data)),
});

const PurchaseOrderListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(PurchaseOrderList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { PurchaseOrderListConnected as PurchaseOrderList };
