/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Card, Table, Button, Typography } from 'antd';
/*----------------------------------------------------------------------------*/
import './ItemsTable.scss';
/*----------------------------------------------------------------------------*/
import uniqid from 'uniqid';
import { CSVLink } from 'react-csv';
import { ExportToExcel, Spinner } from './../../../../../components';
import { constants, getTranslation } from './../../../../../shared';
import { purchaseOrderAction } from './../../../../../actions';
import { track } from '../../../../../services/segment';
import { PurchaseOrderDetailEvents } from '../../../../../services/events';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class ItemsTable extends Component {
  settings = {
    main: {
      className: 'ItemsTable_main',
    },
    calculateMessage: {
      className: 'ItemsTable_calculate_message',
    },
    coloredFields: {
      className: 'ItemsTable_colored_fields',
    },
    card: {
      title: this.props.t(
        'pages.Operation.PurchaseOrder.detail.itemsTable.title',
      ),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  TableConstants = {
    normalTable: 'normal',
    deficienciesTable: 'deficiencies',
  };

  componentDidMount() {
    this.getCalculatedItemProgress();
  }

  prepareData = (props) => props;

  prepareColumns = (data, rawData) => {
    const columns = [
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.product',
        ),
        dataIndex: 'item',
        key: 'item',
        render: (item) => getTranslation.obj(item.name) || '-',
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.detail.itemsTable.table.price',
        ),
        dataIndex: ['item', 'price'],
        key: 'price',
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.detail.itemsTable.table.vat',
        ),
        dataIndex: ['item', 'vat'],
        key: 'vat',
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.detail.itemsTable.table.packageCount',
        ),
        dataIndex: '',
        key: 'packageCount',
        render: (data) => data.totalPackingCount || '-',
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.total',
        ),
        dataIndex: '',
        key: 'total',
        render: (data) =>
          (
            <div className="fontSize_11">
              {`${data.totalReceivedCount} (${
                data.totalOrderedCount
              }/${data.totalPackingCount})`}
            </div>
          ) || '-',
      },
    ];
    return this.prepareWarehouseColumns(columns, rawData);
  };

  prepareWarehouseColumns = (columns, rawData) => {
    if (rawData.isMainWarehousePurchaseOrder) {
      rawData.warehouses.map((warehouse) => {
        columns.push({
          title: warehouse.name,
          key: uniqid(),
          dataIndex: '',
          render: (eachItem) => (
            <div>{this.getCalculatedCell(eachItem, warehouse)}</div>
          ),
        });
      });
    }
    return columns;
  };

  getCalculatedCell = (item, warehouse) => {
    const warehouseId = warehouse.id;
    const newCountDetails =
      item &&
      item.countDetails.reduce((acc, eachCountDetail) => {
        acc[eachCountDetail.warehouse] = eachCountDetail;
        return acc;
      }, {});
    const countDetail = newCountDetails && newCountDetails[warehouseId];
    if (countDetail) {
      return (
        <div
          {...this.settings.coloredFields}
          style={{
            backgroundColor: this.pickColor(
              countDetail.receivedCount,
              countDetail.orderedCount,
            ),
          }}
        >
          {countDetail.receivedCount} ({countDetail.orderedCount}/
          {countDetail.packingCount})
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>0 (0/0)</div>
    );
  };

  pickColor = (received, ordered) => {
    if (ordered > 0 && received === 0) {
      return 'grey';
    }
    if (ordered > received && received > 0) {
      return 'orange';
    }
    if (ordered === received) {
      return '#52c41a';
    }
  };

  prepareData = (props) => props;

  getCalculatedCell = (item, warehouse) => {
    const warehouseId = warehouse.id;
    const newCountDetails =
      item &&
      item.countDetails.reduce((acc, eachCountDetail) => {
        acc[eachCountDetail.warehouse] = eachCountDetail;
        return acc;
      }, {});
    const countDetail = newCountDetails && newCountDetails[warehouseId];

    if (countDetail) {
      return (
        <div
          {...this.settings.coloredFields}
          style={{
            backgroundColor: this.pickColor(
              countDetail.receivedCount,
              countDetail.orderedCount,
            ),
          }}
        >
          {countDetail.receivedCount} ({countDetail.orderedCount}/
          {countDetail.packingCount})
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>0 (0/0)</div>
    );
  };

  getCalculatedValue = (item, warehouse, type) => {
    const newCountDetails =
      item &&
      item.countDetails.reduce((acc, eachCountDetail) => {
        acc[eachCountDetail.warehouse] = eachCountDetail;
        return acc;
      }, {});

    if (type === this.TableConstants.normalTable) {
      const value =
        newCountDetails && newCountDetails[warehouse.id]
          ? `${newCountDetails[warehouse.id].receivedCount} (${
              newCountDetails[warehouse.id].orderedCount
            }/${newCountDetails[warehouse.id].packingCount})`
          : '0 (0/0)';
      return value;
    }

    if (type === this.TableConstants.deficienciesTable) {
      const value =
        newCountDetails && newCountDetails[warehouse.id]
          ? `${newCountDetails[warehouse.id].remainCount}`
          : 0;
      return value;
    }

    return null;
  };

  prepareForExport = (calculatedItems, type) => {
    const sheet =
      type === this.TableConstants.normalTable
        ? [
            {
              [this.props.t(
                'pages.Operation.PurchaseOrder.detail.subTable.itemId',
              )]: this.props.t(
                'pages.Operation.PurchaseOrder.detail.subTable.itemId',
              ),
              [this.props.t(
                'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.product',
              )]: this.props.t(
                'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.product',
              ),
              [this.props.t(
                'pages.Operation.PurchaseOrder.detail.itemsTable.table.price',
              )]: this.props.t(
                'pages.Operation.PurchaseOrder.detail.itemsTable.table.price',
              ),
              [this.props.t(
                'pages.Operation.PurchaseOrder.detail.itemsTable.table.vat',
              )]: this.props.t(
                'pages.Operation.PurchaseOrder.detail.itemsTable.table.vat',
              ),
              [this.props.t(
                'pages.Operation.PurchaseOrder.detail.itemsTable.table.packageCount',
              )]: this.props.t(
                'pages.Operation.PurchaseOrder.detail.itemsTable.table.packageCount',
              ),
              [this.props.t(
                'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.total',
              )]: this.props.t(
                'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.total',
              ),
            },
          ]
        : [
            {
              [this.props.t(
                'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.product',
              )]: '',
              [this.props.t(
                'pages.Operation.PurchaseOrder.detail.subTable.itemId',
              )]: this.props.t(
                'pages.Operation.PurchaseOrder.detail.subTable.itemId',
              ),
              [this.props.t(
                'pages.Stock.PurchaseOrder.detail.table.count',
              )]: this.props.t('pages.Stock.PurchaseOrder.detail.table.count'),
            },
          ];

    calculatedItems &&
      calculatedItems.warehouses.forEach(
        (warehouse) => (sheet[0][`warehouse.${warehouse.id}`] = warehouse.name),
      );

    calculatedItems &&
      calculatedItems.data.map((item) => {
        const tableColumns = {};
        if (type === this.TableConstants.normalTable) {
          tableColumns[
            `${this.props.t(
              'pages.Operation.PurchaseOrder.detail.subTable.itemId',
            )}`
          ] = item.item.id || '-';
          tableColumns[
            `${this.props.t(
              'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.product',
            )}`
          ] = getTranslation.obj(item.item.name);
          tableColumns[
            `${this.props.t(
              'pages.Operation.PurchaseOrder.detail.itemsTable.table.price',
            )}`
          ] = item.item.price || '-';
          tableColumns[
            `${this.props.t(
              'pages.Operation.PurchaseOrder.detail.itemsTable.table.vat',
            )}`
          ] = item.item.vat || '-';
          tableColumns[
            `${this.props.t(
              'pages.Operation.PurchaseOrder.detail.itemsTable.table.packageCount',
            )}`
          ] = item.totalPackingCount || '-';
          tableColumns[
            `${this.props.t(
              'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.total',
            )}`
          ] = `${item.totalReceivedCount} (${
            item.totalOrderedCount
          }/${item.totalPackingCount})`;
        }
        if (type === this.TableConstants.deficienciesTable) {
          tableColumns[
            `${this.props.t(
              'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.product',
            )}`
          ] = getTranslation.obj(item.item.name);
          tableColumns[
            `${this.props.t(
              'pages.Operation.PurchaseOrder.detail.subTable.itemId',
            )}`
          ] = item.item.id || '-';
          tableColumns[
            `${this.props.t('pages.Stock.PurchaseOrder.detail.table.count')}`
          ] = item.item.inboxQuantity || '-';
        }

        calculatedItems.warehouses.map((warehouse) => {
          tableColumns[`warehouse.${warehouse.id}`] = this.getCalculatedValue(
            item,
            warehouse,
            type,
          );
        });

        sheet.push(tableColumns);
      });
    return sheet;
  };

  CardExtra = () => (
    <div className="flex">
      {this.props.calculatedItems &&
        this.props.calculatedItems.status !=
          constants.PURCHASE_ORDER_GET_CALCULATED_ITEM_PROGRESS
            .CALCULATION_REQUIRED && (
          <div className="flex">
            <div className="mr_10">
              <ExportToExcel
                action={ExportToExcel.fileSave}
                fileName="PurchaseOrderProductsTable"
                dataFormatter={this.prepareForExport}
                disabled={false}
                local
                buttonText={this.props.t(
                  'pages.Operation.PurchaseOrder.detail.itemsTable.exportTable',
                )}
                data={this.prepareForExport(
                  this.props.calculatedItems,
                  this.TableConstants.normalTable,
                )}
              />
            </div>
            <div className="mr_10">
              <CSVLink
                data={this.prepareForExport(
                  this.props.calculatedItems,
                  this.TableConstants.deficienciesTable,
                )}
                separator=";"
                filename="POProductDeficienciesTable.csv"
                enclosingCharacter=""
              >
                <Button className="ExportToExcelButton" icon="export">
                  {this.props.t(
                    'pages.Operation.PurchaseOrder.detail.itemsTable.exportDeficienciesTable',
                  )}{' '}
                </Button>
              </CSVLink>
            </div>
          </div>
        )}

      <Button onClick={this.calculateItemProgress}>
        {this.props.t(
          'pages.Operation.PurchaseOrder.detail.itemsTable.calculate',
        )}
      </Button>
    </div>
  );

  Table = () => {
    const { calculatedItems, loading } = this.props;
    if (
      !calculatedItems ||
      !calculatedItems.data ||
      !calculatedItems.data.length
    ) {
      return null;
    }
    return (
      <Table
        size="small"
        columns={this.prepareColumns(calculatedItems.data, calculatedItems)}
        dataSource={this.prepareData(calculatedItems.data)}
        rowKey={uniqid()}
        loading={{ indicator: <Spinner />, spinning: loading }}
      />
    );
  };

  getCalculatedItemProgress = () => {
    if (this.props.id) {
      const { onGetCalculatedItemProgress, id } = this.props;
      onGetCalculatedItemProgress(id);
    }
  };

  calculateItemProgress = () => {
    track(PurchaseOrderDetailEvents.PO_DETAIL_CALCULATE_DEFICIENCIES_BUTTON_CLICKED);
    if (this.props.id) {
      const { onCalculateItemProgress, id } = this.props;
      onCalculateItemProgress(id);
    }
  };

  render() {
    const { calculatedItems } = this.props;
    const { Text } = Typography;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {calculatedItems && calculatedItems.status == 100 ? (
            <div {...this.settings.calculateMessage}>
              <Typography>
                <Text>
                  <strong>
                    {this.props.t(
                      'pages.Operation.PurchaseOrder.detail.itemsTable.emptyTableMessage',
                    )}
                  </strong>
                </Text>
              </Typography>
              <Button
                type="primary"
                className="mt_20"
                onClick={this.calculateItemProgress}
              >
                {this.props.t(
                  'pages.Operation.PurchaseOrder.detail.itemsTable.calculate',
                )}
              </Button>
            </div>
          ) : (
            <this.Table />
          )}
        </Card>
      </div>
    );
  }
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.purchaseOrder.calculatedItems.loading,
  calculatedItems: state.purchaseOrder.calculatedItems.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCalculatedItemProgress: (purchaseOrderId) =>
    dispatch(purchaseOrderAction.getCalculatedItemProgress(purchaseOrderId)),
  onCalculateItemProgress: (purchaseOrderId) =>
    dispatch(purchaseOrderAction.calculateItemProgress(purchaseOrderId)),
});

const ItemsTableConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(ItemsTable));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { ItemsTableConnected as ItemsTable };
