import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';

export const courierAction = {
  getCourierList,
  getBusyReasons,
  makeCourierBusy,
  makeCourierFree,
  courierLocationChanged,
  courierUpdated,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getCourierList() {
  return (dispatch) => {
    dispatch({
      type: actionType.getCourierList_InProgress,
    });
    http
      .getCourierList()
      .then((response) => {
        const { couriers } = response.data.data;
        dispatch({
          type: actionType.getCourierList_Succeeded,
          payload: couriers,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getCourierList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getCourierList_Failed,
          payload: error,
        });
      });
  };
}

function getBusyReasons() {
  return (dispatch) => {
    dispatch({
      type: actionType.getBusyReasons_InProgress,
    });
    http
      .getCourierBusyReasons()
      .then((response) => {
        const { reasons } = response.data.data;
        dispatch({
          type: actionType.getBusyReasons_Succeeded,
          payload: reasons,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getBusyReasons_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getBusyReasons_Failed,
          payload: error,
        });
      });
  };
}

function makeCourierBusy(courierId, data) {
  return (dispatch) => {
    dispatch({
      type: actionType.makeCourierBusy_InProgress,
    });
    http
      .makeCourierBusy(courierId, data)
      .then((response) => {
        dispatch({
          type: actionType.makeCourierBusy_Succeeded,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.makeCourierBusy_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.makeCourierBusy_Failed,
          payload: error,
        });
      });
  };
}

function makeCourierFree(courierId) {
  return (dispatch) => {
    dispatch({
      type: actionType.makeCourierFree_InProgress,
    });
    http
      .makeCourierFree(courierId)
      .then(({ data }) => {
        dispatch({
          type: actionType.makeCourierFree_Succeeded,
          payload: data.data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.makeCourierFree_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.makeCourierFree_Failed,
          payload: error,
        });
      });
  };
}

function courierLocationChanged(courierId, courierLocation) {
  return (dispatch) => {
    dispatch({
      type: actionType.courierLocationChanged,
      payload: { courierId, courierLocation },
    });
  };
}

function courierUpdated(courier) {
  return (dispatch) => {
    dispatch({
      type: actionType.courierUpdated,
      payload: { courier },
    });
  };
}
