import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Button, Card } from 'antd';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './BlockedStockList.scss';
import { BlockedStockFilter } from './BlockedStockFilter';
/*----------------------------------------------------------------------------*/
import {
  Spinner,
  ExportToExcel,
  CopyToClipboard,
} from './../../../../components';
import { getTranslation, tableHelper, constants, getDateTimeFormatString } from './../../../../shared';
import { blockedStockAction } from './../../../../actions';
import { pageLoadEvents } from '../../../../services/events';
import { pageView } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------
------------------------------------------------------------------------------*/

class BlockedStockList extends Component {
  settings = {
    main: {
      className: 'BlockedStockList_main',
    },
    card: {
      title: this.props.t('pages.Operation.BlockedStock.list.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  dateFormat = getDateTimeFormatString();

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_REMOVE_FROM_SALES_LIST });
    this.getList();
  }

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      filter: this.getDefaultFilter(),
    };
  };

  getDefaultFilter = () => {
    return null;
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageFilter />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    return (
      <ExportToExcel
        action={this.props.onExportToXlsxBlockedStockList}
        query={{ limit: this.props.totalCount, offset: 0 }}
        getFilter={this.getFilter}
        fileName="blockedStockedList"
        dataFormatter={this.prepareForExport}
        loading={this.props.isExporting}
        disabled={false}
      />
    );
  };

  PageFilter = () => {
    return (
      <BlockedStockFilter
        defaultDateRange={this.defaultDateRange}
        onChange={this.setTableFilter}
        onClear={this.clearTableFilter}
      />
    );
  };

  PageTable = (props) => {
    return (
      <div>
        <Table
          size="small"
          dataSource={this.prepareData()}
          columns={this.prepareColumns()}
          rowKey={(record) => {
            return record.id;
          }}
          pagination={{
            ...this.state.pagination,
            total: this.props.totalCount,
          }}
          loading={{ indicator: <Spinner />, spinning: this.props.loading }}
          onChange={this.handleTableChange}
        />
      </div>
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState((state) => {
      return {
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      };
    }, this.getList);
  };

  prepareData = (props) => {
    let { list } = this.props;
    if (props) {
      list = props;
    }

    if (!list) {
      return [];
    }

    const tableData = list.map((item) => {
      return {
        ...item,
        table: {
          id: item.id,
          status: getTranslation.blockedStockStatus(item.status),
          quantity: item.count,
          reason: item.reason && item.reason.values,
          subReason: item.subReason && item.subReason.values,
          product: item.product,
          concludedBy: this.getConcludedBy(item),
          createdBy: this.getCreatedBy(item),
          warehouse: item.warehouse,
          createdAt: moment(new Date(item.createdAt))
            .local()
            .format(this.dateFormat),
        },
      };
    });
    return tableData;
  };

  getCreatedBy = (item) => {
    const { statusChangeLog } = item;
    if (!statusChangeLog) {
      return '-';
    }
    const log = statusChangeLog.find(
      (scl) =>
        scl.status.toString() ===
        constants.BLOCKED_STOCK_STATUSES.CREATED.toString(),
    );
    if (!log || !log.picker || !log.picker.name) {
      return '-';
    }
    return log.picker.name;
  };

  getConcludedBy = (item) => {
    const { statusChangeLog } = item;
    if (!statusChangeLog) {
      return '-';
    }
    const logs = statusChangeLog.filter(
      (scl) =>
        scl.status.toString() ===
        constants.BLOCKED_STOCK_STATUSES.CONCLUDED.toString(),
    );
    if (
      !logs ||
      !logs.length ||
      !logs[logs.length - 1] ||
      !logs[logs.length - 1].picker ||
      !logs[logs.length - 1].picker.name
    ) {
      return '-';
    }
    return logs[logs.length - 1].picker.name;
  };

  prepareColumns = () => {
    return [
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.list.table.createdAt',
        ),
        dataIndex: 'table.createdAt',
        key: 'table.createdAt',
        width: 145,
        ...tableHelper.fieldSorter.getDateSortProps('table.createdAt'),
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.list.table.blockedStockId',
        ),
        dataIndex: 'table.id',
        key: 'table.id',
        width: 200,
        render: (id) => <CopyToClipboard message={id} />,
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.list.table.warehouse',
        ),
        dataIndex: 'table.warehouse',
        key: 'table.warehouse',
        width: 203,
        render: (warehouse) => <CopyToClipboard message={warehouse.name} />,
        ...tableHelper.fieldSorter.getStringSortProps('table.warehouse'),
      },
      {
        title: this.props.t('pages.Operation.BlockedStock.list.table.product'),
        dataIndex: 'table.product',
        key: 'table.product',
        width: 200,
        render: (product) => (
          <CopyToClipboard message={getTranslation.obj(product.fullName)} />
        ),
      },
      {
        title: this.props.t('pages.Operation.BlockedStock.list.table.supplier'),
        dataIndex: 'table.product',
        key: 'table.supplier',
        width: 200,
        render: (product) => {
          if (!product.suppliers || !product.suppliers.length) {
            return '-';
          }
          return product.suppliers.map((supplier) => {
            return (
              <CopyToClipboard
                message={getTranslation.obj(supplier.name)}
                key={supplier.id}
                style={{ margin: 2 }}
              />
            );
          });
        },
      },
      {
        title: this.props.t('pages.Operation.BlockedStock.list.table.reason'),
        dataIndex: 'table.reason',
        key: 'table.reason',
        width: 150,
        render: (reason) => <CopyToClipboard message={reason} />,
        ...tableHelper.fieldSorter.getStringSortProps('table.reason'),
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.list.table.subReason',
        ),
        dataIndex: 'table.subReason',
        key: 'table.subReason',
        width: 200,
        render: (subReason) => {
          return subReason ? <CopyToClipboard message={subReason} /> : '-';
        },
        ...tableHelper.fieldSorter.getStringSortProps('table.subReason'),
      },
      {
        title: this.props.t('pages.Operation.BlockedStock.list.table.quantity'),
        dataIndex: 'table.quantity',
        key: 'table.quantity',
        ...tableHelper.fieldSorter.getNumberSortProps('table.quantity'),
      },
      {
        title: this.props.t('pages.Operation.BlockedStock.list.table.status'),
        dataIndex: 'table.status',
        key: 'table.status',
        ...tableHelper.fieldSorter.getStringSortProps('table.status'),
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.list.table.createdBy',
        ),
        dataIndex: 'table.createdBy',
        key: 'table.createdBy',
        ...tableHelper.fieldSorter.getStringSortProps('table.createdBy'),
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.list.table.concludedBy',
        ),
        dataIndex: 'table.concludedBy',
        key: 'table.concludedBy',
        ...tableHelper.fieldSorter.getStringSortProps('table.concludedBy'),
      },
      {
        dataIndex: 'table.id',
        key: 'table.detail',
        render: (id) => <this.DetailButton id={id} />,
      },
    ];
  };

  DetailButton = (props) => {
    const { id } = props;
    return (
      <Link to={`/stock-operation/blocked-stock/detail/${id}`}>
        <Button size="small">
          {this.props.t('pages.Operation.BlockedStock.list.table.detail')}
        </Button>
      </Link>
    );
  };

  prepareForExport = (rawData) => {
    const data = this.prepareData(rawData);
    if (!data || !data.length) {
      return null;
    }

    return data.map((item) => {
      return {
        [this.props.t('pages.Operation.BlockedStock.list.table.id')]: item.table
          .id,
        [this.props.t('pages.Operation.BlockedStock.list.table.status')]: item
          .table.status,
        [this.props.t(
          'pages.Operation.BlockedStock.list.table.createdAt',
        )]: item.table.createdAt,
        [this.props.t(
          'pages.Operation.BlockedStock.list.table.warehouseId',
        )]: item.table.warehouse.id,
        [this.props.t(
          'pages.Operation.BlockedStock.list.table.warehouse',
        )]: item.table.warehouse.name,
        [this.props.t(
          'pages.Operation.BlockedStock.list.table.productId',
        )]: item.table.product.id,
        [this.props.t('pages.Operation.BlockedStock.list.table.product')]: item
          .table.product.fullName,
        [this.props.t('pages.Operation.BlockedStock.list.table.reason')]:
          item.table.reason || '-',
        [this.props.t('pages.Operation.BlockedStock.list.table.subReason')]:
          item.table.subReason || '-',
        [this.props.t('pages.Operation.BlockedStock.list.table.quantity')]: item
          .table.quantity,
        [this.props.t(
          'pages.Operation.BlockedStock.list.table.createdBy',
        )]: item.table.createdBy,
        [this.props.t(
          'pages.Operation.BlockedStock.list.table.concludedBy',
        )]: item.table.concludedBy,
      };
    });
  };

  getList = () => {
    const query = this.getQuery();
    const filter = this.getFilter();

    if (
      filter &&
      filter.createdAt &&
      filter.createdAt.startDate &&
      filter.createdAt.endDate
    ) {
      this.props.onGetBlockedStockList(query, filter);
    }
  };

  getQuery = () => {
    return this.state.query;
  };

  getFilter = () => {
    const filter = this.state.filter || {};
    if (!filter.createdAt) {
      filter.createdAt = this.defaultDateRange;
    }
    return filter;
  };

  setTableFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      };
    }, this.getList);
  };

  clearTableFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      };
    }, this.getList);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.blockedStock.blockedStockList.loading,
  list: state.blockedStock.blockedStockList.data,
  totalCount: state.blockedStock.blockedStockList.total,
  isExporting: state.blockedStock.isExporting,
});

const mapDispatchToProps = (dispatch) => ({
  onGetBlockedStockList: (query, filter) => {
    dispatch(blockedStockAction.filterBlockedStock(query, filter));
  },
  onExportToXlsxBlockedStockList: (query, filter, fileName, formatter) =>
    dispatch(
      blockedStockAction.exportToXlsxBlockedStockList(
        query,
        filter,
        fileName,
        formatter,
      ),
    ),
});

const BlockedStockListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(BlockedStockList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { BlockedStockListConnected as BlockedStockList };
