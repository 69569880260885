import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo, ExportToExcel } from './../components';

export const warehousePurchaseOrderAction = {
  getWarehousePurchaseOrderDetail,
  filterWarehousePurchaseOrderList,
  approveWarehousePurchaseOrder,
  rejectWarehousePurchaseOrder,
  cancelWarehousePurchaseOrder,
  exportToXlsxWarehousePurchaseOrder,
  getWarehousePurchaseOrderEWaybill,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getWarehousePurchaseOrderDetail(warehousePurchaseOrderId) {
  return (dispatch) => {
    dispatch({ type: actionType.getWarehousePurchaseOrderDetail_InProgress });
    http
      .getWarehousePurchaseOrderDetail(warehousePurchaseOrderId)
      .then((response) => {
        dispatch({
          type: actionType.getWarehousePurchaseOrderDetail_Succeeded,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.getWarehousePurchaseOrderDetail_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getWarehousePurchaseOrderDetail_Failed,
          payload: error,
        });
      });
  };
}

function filterWarehousePurchaseOrderList(query, data) {
  return (dispatch) => {
    dispatch({ type: actionType.filterWarehousePurchaseOrderList_InProgress });
    http
      .filterWarehousePurchaseOrderList(query, data)
      .then((response) => {
        dispatch({
          type: actionType.filterWarehousePurchaseOrderList_Succeeded,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.filterWarehousePurchaseOrderList_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.filterWarehousePurchaseOrderList_Failed,
          payload: error,
        });
      });
  };
}

function exportToXlsxWarehousePurchaseOrder(query, filter, fileName, formatter) {
  return (dispatch) => {
    dispatch({ type: actionType.exportToXlsx_InProgress });
    getDataForExport(query, filter)
      .then((responses) => {
        ExportToExcel.fileSave({
          fileName,
          data: formatter(responses),
        });
        dispatch({
          type: actionType.exportToXlsx_Succeeded,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.exportToXlsx_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.exportToXlsx_Failed,
          payload: error,
        });
      });
  };
}

function getDataForExport(query, filter) {
  const limit = 50;
  const offset = 0;
  const total = query.limit;
  return getCallOneByOne(total, filter, limit, offset, []);
}

function getCallOneByOne(total, filter, limit, offset, acc = []) {
  if (offset >= total) {
    return http
      .filterWarehousePurchaseOrderList({ limit, offset }, filter)
      .then((res) => {
        acc.push(...res.data.data.data);
        return acc;
      })
      .catch((err) => {
        return acc;
      });
  }
  return http
    .filterWarehousePurchaseOrderList({ limit, offset }, filter)
    .then((res) => {
      acc.push(...res.data.data.data);
      return getCallOneByOne(total, filter, limit, offset + limit, acc);
    })
    .catch((err) => {
      return getCallOneByOne(total, filter, limit, offset + limit, acc);
    });
}

function approveWarehousePurchaseOrder(warehousePurchaseOrderId) {
  return (dispatch) => {
    dispatch({ type: actionType.approveWarehousePurchaseOrder_InProgress });
    return http
      .approveWarehousePurchaseOrder(warehousePurchaseOrderId)
      .then((response) => {
        dispatch({
          type: actionType.approveWarehousePurchaseOrder_Succeeded,
          payload: response.data.data,
        });
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.approveWarehousePurchaseOrder_Succeeded,
          ),
        });
        dispatch(getWarehousePurchaseOrderDetail(warehousePurchaseOrderId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.approveWarehousePurchaseOrder_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.approveWarehousePurchaseOrder_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function rejectWarehousePurchaseOrder(warehousePurchaseOrderId, data) {
  return (dispatch) => {
    dispatch({ type: actionType.rejectWarehousePurchaseOrder_InProgress });
    return http
      .rejectWarehousePurchaseOrder(warehousePurchaseOrderId, data)
      .then((response) => {
        dispatch({
          type: actionType.rejectWarehousePurchaseOrder_Succeeded,
          payload: response.data.data,
        });
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.rejectWarehousePurchaseOrder_Succeeded,
          ),
        });
        dispatch(getWarehousePurchaseOrderDetail(warehousePurchaseOrderId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.rejectWarehousePurchaseOrder_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.rejectWarehousePurchaseOrder_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function cancelWarehousePurchaseOrder(wpoId) {
  return (dispatch) => {
    dispatch({
      type: actionType.cancelWarehousePurchaseOrder_InProgress,
    });
    return http
      .cancelWarehousePurchaseOrder(wpoId)
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.cancelWarehousePurchaseOrder_Succeeded),
        });
        dispatch({
          type: actionType.cancelWarehousePurchaseOrder_Succeeded,
          payload: response,
        });
        dispatch(getWarehousePurchaseOrderDetail(wpoId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.cancelWarehousePurchaseOrder_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.cancelWarehousePurchaseOrder_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function getWarehousePurchaseOrderEWaybill(warehousePurchaseOrderId) {
  return (dispatch) => {
    dispatch({ type: actionType.getWarehousePurchaseOrderEWaybill_InProgress });
    http
      .getWarehousePurchaseOrderEWaybill(warehousePurchaseOrderId)
      .then((response) => {
        dispatch(getWarehousePurchaseOrderDetail(warehousePurchaseOrderId));
        dispatch({
          type: actionType.getWarehousePurchaseOrderEWaybill_Succeeded,
          payload: response,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.getWarehousePurchaseOrderEWaybill_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getWarehousePurchaseOrderEWaybill_Failed,
          payload: error && error.data && error.data.code,
        });
      });
  };
}
