export class QueryBuilder {
  static getQueryStr(query) {
    if (!query) {
      return;
    }
    const queryStr = Object.keys(query)
      .filter((key) => {
        return !(
          typeof query[key] === 'undefined' ||
          encodeURIComponent(query[key]) === ''
        );
      })
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`;
      })
      .join('&');
    return queryStr;
  }
}
