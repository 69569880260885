import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Form } from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import './BlockedStockRuleCreate.scss';
import {
  ConclusionTypeSelect,
  ProductMultiSelect,
  ReasonMultiSelect,
  SupplierSelect,
  WarehouseMultiSelect,
  SupplierAccountSelect,
} from './formFields';
/*----------------------------------------------------------------------------*/
import { Spinner } from './../../../../../components';
import { history } from './../../../../../history';
import {
  supplierAction,
  warehouseAction,
  productAction,
  blockedStockAction,
} from './../../../../../actions';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class BlockedStockRuleCreate extends Component {
  settings = {
    main: {
      className: 'BlockedStockRuleCreate_main',
    },
    card: {
      className: 'no-padding-card',
      title: this.props.t(
        'pages.Operation.BlockedStock.decisionRule.create.title',
      ),
      size: 'small',
    },
  };

  formItemLayout = {
    style: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 15,
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    return {
      supplier: null,
      supplierAccount: null,
      products: [],
      warehouses: [],
      reasons: [],
      subReasons: [],
      conclusionType: null,
    };
  };

  componentDidMount() {
    this.loadFormData();
  }

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card}>
          {loading ? <Spinner /> : <this.Page />}
        </Card>
      </div>
    );
  }

  Page = (props) => {
    return (
      <>
        <this.PageForm />
        <this.Toolbar />
      </>
    );
  };

  PageForm = () => {
    return (
      <Form>
        <this.SupplierField />
        <this.SupplierAccountField />
        <this.ProductField />
        <this.WarehouseField />
        <this.ReasonField />
        <this.SubReasonField />
        <this.ConclusionTypeField />
      </Form>
    );
  };

  SupplierField = (props) => {
    const { supplier } = this.state;
    const { supplierList } = this.props;
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.decisionRule.create.form.supplier',
        )}
      >
        <SupplierSelect
          onChange={(supplier) => {
            this.setState((state) => {
              return {
                ...state,
                supplier,
                supplierAccount: null,
                products: [],
              };
            });
            if (supplier && supplier._id) {
              this.props.onGetProductList(supplier._id);
            }
          }}
          value={supplier}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.create.form.supplier',
          )}
          list={supplierList}
        />
      </Form.Item>
    );
  };

  SupplierAccountField = (props) => {
    const { supplier, supplierAccount } = this.state;
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.decisionRule.create.form.account',
        )}
      >
        <SupplierAccountSelect
          onChange={(supplierAccount) => {
            this.setState({ supplierAccount });
          }}
          value={supplierAccount}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.create.form.account',
          )}
          list={supplier && supplier.accounts}
          disabled={
            !supplier || !supplier.accounts || !supplier.accounts.length
          }
        />
      </Form.Item>
    );
  };

  ProductField = () => {
    const { productList } = this.props;
    const { products, supplier } = this.state;
    const list = (productList && productList.data) || null;
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.decisionRule.create.form.product',
        )}
      >
        <ProductMultiSelect
          list={list}
          disabled={!supplier}
          onChange={(products) => {
            this.setState({ products });
          }}
          value={products}
          loading={productList.loading}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.create.form.product',
          )}
        />
      </Form.Item>
    );
  };

  WarehouseField = () => {
    const { warehouseList } = this.props;
    const { warehouses } = this.state;
    const list = (warehouseList && warehouseList.data) || null;
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.decisionRule.create.form.warehouse',
        )}
      >
        <WarehouseMultiSelect
          list={list}
          onChange={(warehouses) => {
            this.setState({ warehouses });
          }}
          value={warehouses}
          loading={warehouseList.loading}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.create.form.warehouse',
          )}
        />
      </Form.Item>
    );
  };

  ReasonField = () => {
    const { reasonList } = this.props;
    const { reasons } = this.state;
    const list = (reasonList && reasonList.data) || null;
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.decisionRule.create.form.reason',
        )}
      >
        <ReasonMultiSelect
          list={list}
          onChange={(reasons) => {
            this.setState({ reasons });
          }}
          value={reasons}
          loading={reasonList.loading}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.create.form.reason',
          )}
        />
      </Form.Item>
    );
  };

  SubReasonField = () => {
    const { reasons, subReasons } = this.state;
    const list = [];
    reasons.forEach((reason) => {
      if (reason && reason.subReasons) {
        list.push(...reason.subReasons);
      }
    });
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.decisionRule.create.form.subReason',
        )}
      >
        <ReasonMultiSelect
          list={list}
          onChange={(subReasons) => {
            this.setState({ subReasons });
          }}
          value={subReasons}
          disabled={!list || !list.length}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.create.form.subReason',
          )}
        />
      </Form.Item>
    );
  };

  ConclusionTypeField = () => {
    const { conclusionType } = this.state;
    return (
      <Form.Item
        {...this.formItemLayout}
        style={{ ...this.formItemLayout.style, marginBottom: 15 }}
        label={this.props.t(
          'pages.Operation.BlockedStock.decisionRule.create.form.conclusionType',
        )}
      >
        <ConclusionTypeSelect
          onChange={(conclusionType) => {
            this.setState({ conclusionType });
          }}
          value={conclusionType}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.create.form.conclusionType',
          )}
        />
      </Form.Item>
    );
  };

  Toolbar = (props) => {
    return (
      <div className="Toolbar">
        <this.CancelButton />
        <span>
          <this.SaveButton />
        </span>
      </div>
    );
  };

  SaveButton = (props) => {
    return (
      <Button
        className="SaveButton"
        onClick={this.clickSave}
        disabled={this.isCreateButtonDisabled()}
      >
        {this.props.t(
          'pages.Operation.BlockedStock.decisionRule.create.button.save',
        )}
      </Button>
    );
  };

  CancelButton = (props) => {
    return (
      <Button className="CancelButton" type="danger" onClick={this.clickCancel}>
        {this.props.t(
          'pages.Operation.BlockedStock.decisionRule.create.button.cancel',
        )}
      </Button>
    );
  };

  isCreateButtonDisabled = () => {
    let disabled = true;
    const { supplier, supplierAccount, conclusionType } = this.state;
    if (supplier && supplierAccount && conclusionType) {
      disabled = false;
    }
    return disabled;
  };

  clickSave = () => {
    const payload = this.getPayload();
    this.createItem(payload);
  };

  clickCancel = () => {
    this.setState(this.stateFactory());
    history.push(`/stock-operation/blocked-stock/decision-rule/list`);
  };

  createItem = (payload) => {
    this.props.onCreateBlockedStockRule(payload);
  };

  getPayload = () => {
    const {
      supplier,
      supplierAccount,
      products,
      warehouses,
      reasons,
      subReasons,
      conclusionType,
    } = this.state;
    const payload = {
      blockedStockRule: {
        supplier: supplier._id,
        accountCode: supplierAccount.code,
        products: products.map((p) => p.id),
        warehouses: warehouses.map((w) => w._id),
        reasons: reasons.map((r) => r.id),
        conclusionType: conclusionType.key,
      },
    };
    if (subReasons && subReasons.length) {
      payload.blockedStockRule.subReasons = subReasons.map((sr) => sr.id);
    }
    return payload;
  };

  loadFormData = () => {
    this.props.onGetSupplierList();
    this.props.onGetWarehouseList();
    this.props.onGetReasonList();
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse.id,
  supplierList: state.supplier.list.data,
  warehouseList: state.warehouse.warehouseList,
  productList: state.product.productList,
  reasonList: state.blockedStock.reasonList,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSupplierList: () => {
    return dispatch(supplierAction.getSupplierList());
  },
  onGetWarehouseList: () => {
    return dispatch(warehouseAction.getWarehouseList());
  },
  onGetProductList: (id) => {
    return dispatch(
      productAction.filterProductList(
        {
          supplierIds: [id],
          fields: 'barcodes picURL fullName packagingInfo',
          includeDefaultFields: false,
        },
        false,
      ),
    );
  },
  onGetReasonList: () => {
    return dispatch(
      blockedStockAction.filterBlockedStockReasons({
        isVisibleByWarehouse: true,
      }),
    );
  },
  onCreateBlockedStockRule: (data) => {
    return dispatch(blockedStockAction.createBlockedStockRule(data));
  },
});

const BlockedStockRuleCreateConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(BlockedStockRuleCreate));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { BlockedStockRuleCreateConnected as BlockedStockRuleCreate };
