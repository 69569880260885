import './index.css';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LocaleProvider } from 'antd';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
// import * as Sentry from '@sentry/react';

import 'moment/locale/tr';
import 'moment/locale/nl';
import 'moment/locale/de';
import 'moment/locale/fr';

import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/pt';
import 'moment/locale/en-gb';

import trTR from 'antd/lib/locale-provider/tr_TR';
import enUS from 'antd/es/locale-provider/en_US';
import nlNL from 'antd/es/locale-provider/nl_NL';
import deDE from 'antd/es/locale-provider/de_DE';
import frFR from 'antd/es/locale-provider/fr_FR';

import enGB from 'antd/lib/calendar/locale/en_GB';
import esES from 'antd/lib/calendar/locale/es_ES';
import itIT from 'antd/lib/calendar/locale/it_IT';
import ptPT from 'antd/lib/calendar/locale/pt_PT';

import { App } from './App';
import { store } from './store';
import { history } from './history';
import i18n from './i18n';
import * as serviceWorker from './serviceWorker';
import { config } from './config';
import { constants } from './shared';

const AppRoot = () => {
  const locale = getLocale();
  moment.locale(i18n.language);
  return (
    <Provider store={store}>
      <Router history={history}>
        <LocaleProvider locale={locale}>
          <App />
        </LocaleProvider>
      </Router>
    </Provider>
  );
};

const Root = withNamespaces('translation')(AppRoot);

// eslint-disable-next-line no-console, prefer-destructuring
const log = console.log;

window.console.log = (...params) => {
  if ([constants.ENV.LOCAL, constants.ENV.DEV].includes(config.ENV)) {
    return log(...params);
    // eslint-disable-next-line no-else-return
  } else if (window.debug) {
    // for seeing logs on prod write to browser's console
    // debug = true;
    return log(...params);
  }
};

// Close sentry until we release V2

// if (
//   config.ENV === 'production' ||
//   config.ENV === 'dev'
// ) {
//   Sentry.init({
//     dsn: config.REACT_APP_SENTRY_DSN,
//     // integrations: [
//     //   new Integrations.BrowserTracing({
//     //     routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
//     //   }),
//     // ],
//     environment: config.ENV,

//     // we can grdaually increase sample rate for future
//     tracesSampleRate: 0.2,
//   });
// }

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA .
serviceWorker.unregister();

function getLocale() {
  const localeDict = {
    tr: trTR,
    'en-US': enUS,
    nl: nlNL,
    de: deDE,
    fr: frFR,
    es: esES,
    it: itIT,
    pt: ptPT,
    en: enGB,
  };
  if (i18n.language) {
    return localeDict[i18n.language];
  }
  return enGB;
}
