// prettier-ignore
export const actionType = {
  //----------------------------------------------------------------------------
  // export to xlsx
  //----------------------------------------------------------------------------
  exportToXlsx_InProgress: 'exportToXlsx_InProgress',
  exportToXlsx_Succeeded: 'exportToXlsx_Succeeded',
  exportToXlsx_Failed: 'exportToXlsx_Failed',

  //----------------------------------------------------------------------------
  // export to xlsx for blockedStock
  //----------------------------------------------------------------------------

  exportToXlsxBlockedStockList_InProgress: 'exportToXlsxBlockedStockList_InProgress',
  exportToXlsxBlockedStockList_Succeeded: 'exportToXlsxBlockedStockList_Succeeded',
  exportToXlsxBlockedStockList_Failed: 'exportToXlsxBlockedStockList_Failed',

  //----------------------------------------------------------------------------
  // export to xlsx for transferList
  //----------------------------------------------------------------------------

  exportToXlsxTransferList_InProgress: 'exportToXlsxTransferList_InProgress',
  exportToXlsxTransferList_Succeeded: 'exportToXlsxTransferList_Succeeded',
  exportToXlsxTransferList_Failed: 'exportToXlsxTransferList_Failed',

  //----------------------------------------------------------------------------
  // warehouses
  //----------------------------------------------------------------------------
  getWarehouseList_InProgress: 'getWarehouseList_InProgress',
  getWarehouseList_Succeeded: 'getWarehouseList_Succeeded',
  getWarehouseList_Failed: 'getWarehouseList_Failed',

  updateWarehouseStatus_InProgress: 'updateWarehouseStatus_InProgress',
  updateWarehouseStatus_Succeeded: 'updateWarehouseStatus_Succeeded',
  updateWarehouseStatus_Failed: 'updateWarehouseStatus_Failed',

  //----------------------------------------------------------------------------
  // blocked-stocks
  //----------------------------------------------------------------------------
  filterBlockedStockReasons_InProgress: 'filterBlockedStockReasons_InProgress',
  filterBlockedStockReasons_Succeeded: 'filterBlockedStockReasons_Succeeded',
  filterBlockedStockReasons_Failed: 'filterBlockedStockReasons_Failed',

  filterBlockedStock_InProgress: 'filterBlockedStock_InProgress',
  filterBlockedStock_Succeeded: 'filterBlockedStock_Succeeded',
  filterBlockedStock_Failed: 'filterBlockedStock_Failed',

  getBlockedStockDetail_InProgress: 'getBlockedStockDetail_InProgress',
  getBlockedStockDetail_Succeeded: 'getBlockedStockDetail_Succeeded',
  getBlockedStockDetail_Failed: 'getBlockedStockDetail_Failed',

  updateBlockedStockReason_InProgress: 'updateBlockedStockReason_InProgress',
  updateBlockedStockReason_Succeeded: 'updateBlockedStockReason_Succeeded',
  updateBlockedStockReason_Failed: 'updateBlockedStockReason_Failed',

  getBlockedStockRuleDetail_InProgress: 'getBlockedStockRuleDetail_InProgress',
  getBlockedStockRuleDetail_Succeeded: 'getBlockedStockRuleDetail_Succeeded',
  getBlockedStockRuleDetail_Failed: 'getBlockedStockRuleDetail_Failed',

  updateBlockedStockRule_InProgress: 'updateBlockedStockRule_InProgress',
  updateBlockedStockRule_Succeeded: 'updateBlockedStockRule_Succeeded',
  updateBlockedStockRule_Failed: 'updateBlockedStockRule_Failed',

  createBlockedStockRule_InProgress: 'createBlockedStockRule_InProgress',
  createBlockedStockRule_Succeeded: 'createBlockedStockRule_Succeeded',
  createBlockedStockRule_Failed: 'createBlockedStockRule_Failed',

  filterBlockedStockRule_InProgress: 'filterBlockedStockRule_InProgress',
  filterBlockedStockRule_Succeeded: 'filterBlockedStockRule_Succeeded',
  filterBlockedStockRule_Failed: 'filterBlockedStockRule_Failed',

  concludeBlockedStock_InProgress: 'concludeBlockedStock_InProgress',
  concludeBlockedStock_Succeeded: 'concludeBlockedStock_Succeeded',
  concludeBlockedStock_Failed: 'concludeBlockedStock_Failed',

  bulkConcludeBlockedStock_InProgress: 'bulkConcludeBlockedStock_InProgress',
  bulkConcludeBlockedStock_Succeeded: 'bulkConcludeBlockedStock_Succeeded',
  bulkConcludeBlockedStock_Failed: 'bulkConcludeBlockedStock_Failed',

  cancelBlockedStock_InProgress: 'cancelBlockedStock_InProgress',
  cancelBlockedStock_Succeeded: 'cancelBlockedStock_Succeeded',
  cancelBlockedStock_Failed: 'cancelBlockedStock_Failed',

  changeConclusionBlockedStock_InProgress: 'changeConclusionBlockedStock_InProgress',
  changeConclusionBlockedStock_Succeeded: 'changeConclusionBlockedStock_Succeeded',
  changeConclusionBlockedStock_Failed: 'changeConclusionBlockedStock_Failed',

  //----------------------------------------------------------------------------
  // courier
  //----------------------------------------------------------------------------
  getBusyReasons_InProgress: 'getBusyReasons_InProgress',
  getBusyReasons_Succeeded: 'getBusyReasons_Succeeded',
  getBusyReasons_Failed: 'getBusyReasons_Failed',

  makeCourierBusy_InProgress: 'makeCourierBusy_InProgress',
  makeCourierBusy_Succeeded: 'makeCourierBusy_Succeeded',
  makeCourierBusy_Failed: 'makeCourierBusy_Failed',

  makeCourierFree_InProgress: 'makeCourierFree_InProgress',
  makeCourierFree_Succeeded: 'makeCourierFree_Succeeded',
  makeCourierFree_Failed: 'makeCourierFree_Failed',

  courierLocationChanged: 'courierLocationChanged',
  courierUpdated: 'courierUpdated',

  //----------------------------------------------------------------------------
  // inventory-checks
  //----------------------------------------------------------------------------
  getInventoryCheckList_InProgress: 'getInventoryCheckList_InProgress',
  getInventoryCheckList_Succeeded: 'getInventoryCheckList_Succeeded',
  getInventoryCheckList_Failed: 'getInventoryCheckList_Failed',

  getInventoryCheckDetail_InProgress: 'getInventoryCheckDetail_InProgress',
  getInventoryCheckDetail_Succeeded: 'getInventoryCheckDetail_Succeeded',
  getInventoryCheckDetail_Failed: 'getInventoryCheckDetail_Failed',

  createInventoryCheck_InProgress: 'createInventoryCheck_InProgress',
  createInventoryCheck_Succeeded: 'createInventoryCheck_Succeeded',
  createInventoryCheck_Failed: 'createInventoryCheck_Failed',

  setInventoryCheckReady_InProgress: 'setInventoryCheckReady_InProgress',
  setInventoryCheckReady_Succeeded: 'setInventoryCheckReady_Succeeded',
  setInventoryCheckReady_Failed: 'setInventoryCheckReady_Failed',

  deleteInventoryCheck_InProgress: 'deleteInventoryCheck_InProgress',
  deleteInventoryCheck_Succeeded: 'deleteInventoryCheck_Succeeded',
  deleteInventoryCheck_Failed: 'deleteInventoryCheck_Failed',

  terminateInventoryCheck_InProgress: 'terminateInventoryCheck_InProgress',
  terminateInventoryCheck_Succeeded: 'terminateInventoryCheck_Succeeded',
  terminateInventoryCheck_Failed: 'terminateInventoryCheck_Failed',

  getInventoryCheckTaskDetail_InProgress: 'getInventoryCheckTaskDetail_InProgress',
  getInventoryCheckTaskDetail_Succeeded: 'getInventoryCheckTaskDetail_Succeeded',
  getInventoryCheckTaskDetail_Failed: 'getInventoryCheckTaskDetail_Failed',

  bulkApproveInventoryCheck_InProgress: 'bulkApproveInventoryCheck_InProgress',
  bulkApproveInventoryCheck_Succeeded: 'bulkApproveInventoryCheck_Succeeded',
  bulkApproveInventoryCheck_Failed: 'bulkApproveInventoryCheck_Failed',

  bulkTerminateInventoryCheck_InProgress: 'bulkTerminateInventoryCheck_InProgress',
  bulkTerminateInventoryCheck_Succeeded: 'bulkTerminateInventoryCheck_Succeeded',
  bulkTerminateInventoryCheck_Failed: 'bulkTerminateInventoryCheck_Failed',

  bulkIncludeInventoryChecksToSTS_InProgress: 'bulkIncludeInventoryChecksToSts_InProgress',
  bulkIncludeInventoryChecksToSTS_Succeeded: 'bulkIncludeInventoryChecksToSts_Succeeded',
  bulkIncludeInventoryChecksToSTS_Failed: 'bulkIncludeInventoryChecksToSts_Failed',

  bulkRemoveInventoryChecksFromSTS_InProgress: 'bulkRemoveInventoryChecksFromSts_InProgress',
  bulkRemoveInventoryChecksFromSTS_Succeeded: 'bulkRemoveInventoryChecksFromSts_Succeeded',
  bulkRemoveInventoryChecksFromSTS_Failed: 'bulkRemoveInventoryChecksFromSts_Failed',

  includeToSTS_InProgress: 'includeToSts_InProgress',
  includeToSTS_Succeeded: 'includeToSts_Succeeded',
  includeToSTS_Failed: 'includeToSts_Failed',

  removeFromSTS_InProgress: 'removeFromSts_InProgress',
  removeFromSTS_Succeeded: 'removeFromSts_Succeeded',
  removeFromSTS_Failed: 'removeFromSts_Failed',

  //----------------------------------------------------------------------------
  // picker
  //----------------------------------------------------------------------------
  getPickerBusyReasons_InProgress: 'getPickerBusyReasons_InProgress',
  getPickerBusyReasons_Succeeded: 'getPickerBusyReasons_Succeeded',
  getPickerBusyReasons_Failed: 'getPickerBusyReasons_Failed',

  changeStatusOfPickerToBusy_InProgress: 'changeStatusOfPickerToBusy_InProgress',
  changeStatusOfPickerToBusy_Succeeded: 'changeStatusOfPickerToBusy_Succeeded',
  changeStatusOfPickerToBusy_Failed: 'changeStatusOfPickerToBusy_Failed',

  changeStatusOfPickerToFree_InProgress: 'changeStatusOfPickerToFree_InProgress',
  changeStatusOfPickerToFree_Succeeded: 'changeStatusOfPickerToFree_Succeeded',
  changeStatusOfPickerToFree_Failed: 'changeStatusOfPickerToFree_Failed',

  getPickerDetail_InProgress: 'getPickerDetail_InProgress',
  getPickerDetail_Succeeded: 'getPickerDetail_Succeeded',
  getPickerDetail_Failed: 'getPickerDetail_Failed',

  getPickerJobDetail_InProgress: 'getPickerJobDetail_InProgress',
  getPickerJobDetail_Succeeded: 'getPickerJobDetail_Succeeded',
  getPickerJobDetail_Failed: 'getPickerJobDetail_Failed',

  pickerUpdated: 'pickerUpdated',

  //----------------------------------------------------------------------------
  // suppliers
  //----------------------------------------------------------------------------
  getSupplierList_InProgress: 'getSupplierList_InProgress',
  getSupplierList_Succeeded: 'getSupplierList_Succeeded',
  getSupplierList_Failed: 'getSupplierList_Failed',

  getSupplierDetail_InProgress: 'getSupplierDetail_InProgress',
  getSupplierDetail_Succeeded: 'getSupplierDetail_Succeeded',
  getSupplierDetail_Failed: 'getSupplierDetail_Failed',

  //----------------------------------------------------------------------------
  // warehouse
  //----------------------------------------------------------------------------
  // Product
  getWarehouseProductList_InProgress: 'getWarehouseProductList_InProgress',
  getWarehouseProductList_Succeeded: 'getWarehouseProductList_Succeeded',
  getWarehouseProductList_Failed: 'getWarehouseProductList_Failed',

  // Expiring Products
  getExpiringProducts_InProgress: 'getExpiringProducts_InProgress',
  getExpiringProducts_Succeeded: 'getExpiringProducts_Succeeded',
  getExpiringProducts_Failed: 'getExpiringProducts_Failed',

  // Picker
  getPickerList_InProgress: 'getPickerList_InProgress',
  getPickerList_Succeeded: 'getPickerList_Succeeded',
  getPickerList_Failed: 'getPickerList_Failed',

  // Courier
  getCourierList_Succeeded: 'getCourierList_Succeeded',
  getCourierList_InProgress: 'getCourierList_InProgress',
  getCourierList_Failed: 'getCourierList_Failed',

  // Order
  getOrderList_InProgress: 'getOrderList_InProgress',
  getOrderList_Succeeded: 'getOrderList_Succeeded',
  getOrderList_Failed: 'getOrderList_Failed',

  getOrderDetail_InProgress: 'getOrderDetail_InProgress',
  getOrderDetail_Succeeded: 'getOrderDetail_Succeeded',
  getOrderDetail_Failed: 'getOrderDetail_Failed',

  reportFraud_InProgress: 'reportFraud_InProgress',
  reportFraud_Succeeded: 'reportFraud_Succeeded',
  reportFraud_Failed: 'reportFraud_Failed',
  reportFraud_Reset: 'reportFraud_Reset',

  newMarketOrder: 'newMarketOrder',
  marketOrderChanged: 'marketOrderChanged',

  getWalkInOrders_InProgress: 'getWalkInOrders_InProgress',
  getWalkInOrders_Succeeded: 'getWalkInOrders_Succeeded',
  getWalkInOrders_Failed: 'getWalkInOrders_Failed',

  getWalkInOrderDetails_InProgress: 'getWalkInOrderDetails_InProgress',
  getWalkInOrderDetails_Succeeded: 'getWalkInOrderDetails_Succeeded',
  getWalkInOrderDetails_Failed: 'getWalkInOrderDetails_Failed',

  walkInOrderDecreaseStock_InProgress: 'walkInOrderDecreaseStock_InProgress',
  walkInOrderDecreaseStock_Succeeded: 'walkInOrderDecreaseStock_Succeeded',
  walkInOrderDecreaseStock_Failed: 'walkInOrderDecreaseStock_Failed',

  walkInOrderCancel_InProgress: 'walkInOrderCancel_InProgress',
  walkInOrderCancel_Succeeded: 'walkInOrderCancel_Succeeded',
  walkInOrderCancel_Failed: 'walkInOrderCancel_Failed',

  walkInOrderDeliver_InProgress: 'walkInOrderDeliver_InProgress',
  walkInOrderDeliver_Succeeded: 'walkInOrderDeliver_Succeeded',
  walkInOrderDeliver_Failed: 'walkInOrderDeliver_Failed',

  filterWalkInOrders_InProgress: 'filterWalkInOrders_InProgress',
  filterWalkInOrders_Succeeded: 'filterWalkInOrders_Succeeded',
  filterWalkInOrders_Failed: 'filterWalkInOrders_Failed',

  walkInOrderRefund_InProgress: 'walkInOrderRefund_InProgress',
  walkInOrderRefund_Succeeded: 'walkInOrderRefund_Succeeded',
  walkInOrderRefund_Failed: 'walkInOrderRefund_Failed',

  // PurchaseOrder
  getPurchaseOrderList_InProgress: 'getPurchaseOrderList_InProgress',
  getPurchaseOrderList_Succeeded: 'getPurchaseOrderList_Succeeded',
  getPurchaseOrderList_Failed: 'getPurchaseOrderList_Failed',

  getPurchaseOrderDetail_InProgress: 'getPurchaseOrderDetail_InProgress',
  getPurchaseOrderDetail_Succeeded: 'getPurchaseOrderDetail_Succeeded',
  getPurchaseOrderDetail_Failed: 'getPurchaseOrderDetail_Failed',

  // IncomingTransfer
  getIncomingTransferList_InProgress: 'getIncomingTransferList_InProgress',
  getIncomingTransferList_Succeeded: 'getIncomingTransferList_Succeeded',
  getIncomingTransferList_Failed: 'getIncomingTransferList_Failed',

  getIncomingTransferDetail_InProgress: 'getIncomingTransferDetail_InProgress',
  getIncomingTransferDetail_Succeeded: 'getIncomingTransferDetail_Succeeded',
  getIncomingTransferDetail_Failed: 'getIncomingTransferDetail_Failed',

  // OutgoingTransfer
  getOutgoingTransferList_InProgress: 'getOutgoingTransferList_InProgress',
  getOutgoingTransferList_Succeeded: 'getOutgoingTransferList_Succeeded',
  getOutgoingTransferList_Failed: 'getOutgoingTransferList_Failed',

  getOutgoingTransferDetail_InProgress: 'getOutgoingTransferDetail_InProgress',
  getOutgoingTransferDetail_Succeeded: 'getOutgoingTransferDetail_Succeeded',
  getOutgoingTransferDetail_Failed: 'getOutgoingTransferDetail_Failed',

  setOutgoingTransferShip_InProgress: 'setOutgoingTransferShip_InProgress',
  setOutgoingTransferShip_Succeeded: 'setOutgoingTransferShip_Succeeded',
  setOutgoingTransferShip_Failed: 'setOutgoingTransferShip_Failed',

  createEmptyCarboyTransfer_InProgress: 'createEmptyCarboyTransfer_InProgress',
  createEmptyCarboyTransfer_Succeeded: 'createEmptyCarboyTransfer_Succeeded',
  createEmptyCarboyTransfer_Failed: 'createEmptyCarboyTransfer_Failed',

  createCaseAndPalletTransfer_InProgress: 'createCaseAndPalletTransfer_InProgress',
  createCaseAndPalletTransfer_Succeeded: 'createCaseAndPalletTransfer_Succeeded',
  createCaseAndPalletTransfer_Failed: 'createCaseAndPalletTransfer_Failed',

  createSelfOutgoingTransferProductList_InProgress: 'createSelfOutgoingTransferProductList_InProgress',
  createSelfOutgoingTransferProductList_Succeeded: 'createSelfOutgoingTransferProductList_Succeeded',
  createSelfOutgoingTransferProductList_Failed: 'createSelfOutgoingTransferProductList_Failed',

  getAvailableStockOfLocationsByWarehouseId_InProgress: 'getAvailableStockOfLocationsByWarehouseId_InProgress',
  getAvailableStockOfLocationsByWarehouseId_Succeeded: 'getAvailableStockOfLocationsByWarehouseId_Succeeded',
  getAvailableStockOfLocationsByWarehouseId_Failed: 'getAvailableStockOfLocationsByWarehouseId_Failed',

  //----------------------------------------------------------------------------
  // transfers
  //----------------------------------------------------------------------------
  getTransferDetail_InProgress: 'getTransferDetail_InProgress',
  getTransferDetail_Succeeded: 'getTransferDetail_Succeeded',
  getTransferDetail_Failed: 'getTransferDetail_Failed',

  updateTransferItems_InProgress: 'updateTransferItems_InProgress',
  updateTransferItems_Succeeded: 'updateTransferItems_Succeeded',
  updateTransferItems_Failed: 'updateTransferItems_Failed',

  approveTransfer_InProgress: 'approveTransfer_InProgress',
  approveTransfer_Succeeded: 'approveTransfer_Succeeded',
  approveTransfer_Failed: 'approveTransfer_Failed',

  cancelTransfer_InProgress: 'cancelTransfer_InProgress',
  cancelTransfer_Succeeded: 'cancelTransfer_Succeeded',
  cancelTransfer_Failed: 'cancelTransfer_Failed',

  deleteTransfer_InProgress: 'deleteTransfer_InProgress',
  deleteTransfer_Succeeded: 'deleteTransfer_Succeeded',
  deleteTransfer_Failed: 'deleteTransfer_Failed',

  createTransfer_InProgress: 'createTransfer_InProgress',
  createTransfer_Succeeded: 'createTransfer_Succeeded',
  createTransfer_Failed: 'createTransfer_Failed',

  getTransferList_InProgress: 'getTransferList_InProgress',
  getTransferList_Succeeded: 'getTransferList_Succeeded',
  getTransferList_Failed: 'getTransferList_Failed',

  addOutgoingTransferPallet_InProgress: 'addOutgoingTransferPallet_InProgress',
  addOutgoingTransferPallet_Succeeded: 'addOutgoingTransferPallet_Succeeded',
  addOutgoingTransferPallet_Failed: 'addOutgoingTransferPallet_Failed',

  addOutgoingTransferPalletBarcode_InProgress: 'addOutgoingTransferPalletBarcode_InProgress',
  addOutgoingTransferPalletBarcode_Succeeded: 'addOutgoingTransferPalletBarcode_Succeeded',
  addOutgoingTransferPalletBarcode_Failed: 'addOutgoingTransferPalletBarcode_Failed',

  setOutgoingTransferContinue_InProgress: 'setOutgoingTransferContinue_InProgress',
  setOutgoingTransferContinue_Succeeded: 'setOutgoingTransferContinue_Succeeded',
  setOutgoingTransferContinue_Failed: 'setOutgoingTransferContinue_Failed',

  shipDirect_InProgress: 'directShipping_InProgress',
  shipDirect_Succeeded: 'directShipping_Succeeded',
  shipDirect_Failed: 'directShipping_Failed',

  bulkSendToWmsTransfer_InProgress: 'bulkSendToWmsTransfer_InProgress',
  bulkSendToWmsTransfer_Succeeded: 'bulkSendToWmsTransfer_Succeeded',
  bulkSendToWmsTransfer_Failed: 'bulkSendToWmsTransfer_Failed',

  addNoteToTransfer_InProgress: 'addNoteToTransfer_InProgress',
  addNoteToTransfer_Succeeded: 'addNoteToTransfer_Succeeded',
  addNoteToTransfer_Failed: 'addNoteToTransfer_Failed',

  //----------------------------------------------------------------------------
  // purchase-orders
  //----------------------------------------------------------------------------
  getOperationPurchaseOrderDetail_InProgress: 'getOperationPurchaseOrderDetail_InProgress',
  getOperationPurchaseOrderDetail_Succeeded: 'getOperationPurchaseOrderDetail_Succeeded',
  getOperationPurchaseOrderDetail_Failed: 'getOperationPurchaseOrderDetail_Failed',

  createPurchaseOrder_InProgress: 'createPurchaseOrder_InProgress',
  createPurchaseOrder_Succeeded: 'createPurchaseOrder_Succeeded',
  createPurchaseOrder_Failed: 'createPurchaseOrder_Failed',

  filterPurchaseOrderList_InProgress: 'filterPurchaseOrderList_InProgress',
  filterPurchaseOrderList_Succeeded: 'filterPurchaseOrderList_Succeeded',
  filterPurchaseOrderList_Failed: 'filterPurchaseOrderList_Failed',

  createSelfPurchaseOrder_InProgress: 'createSelfPurchaseOrder_InProgress',
  createSelfPurchaseOrder_Succeeded: 'createSelfPurchaseOrder_Succeeded',
  createSelfPurchaseOrder_Failed: 'createSelfPurchaseOrder_Failed',

  orderPurchaseOrder_InProgress: 'orderPurchaseOrder_InProgress',
  orderPurchaseOrder_Succeeded: 'orderPurchaseOrder_Succeeded',
  orderPurchaseOrder_Failed: 'orderPurchaseOrder_Failed',

  sendToSupplier_InProgress: 'sendToSupplier_InProgress',
  sendToSupplier_Succeeded: 'sendToSupplier_Succeeded',
  sendToSupplier_Failed: 'sendToSupplier_Failed',

  updatePurchaseOrder_InProgress: 'updatePurchaseOrder_InProgress',
  updatePurchaseOrder_Succeeded: 'updatePurchaseOrder_Succeeded',
  updatePurchaseOrder_Failed: 'updatePurchaseOrder_Failed',

  cancelPurchaseOrder_InProgress: 'cancelPurchaseOrder_InProgress',
  cancelPurchaseOrder_Succeeded: 'cancelPurchaseOrder_Succeeded',
  cancelPurchaseOrder_Failed: 'cancelPurchaseOrder_Failed',

  sendToWmsPurchaseOrder_InProgress: 'sendToWmsPurchaseOrder_InProgress',
  sendToWmsPurchaseOrder_Succeeded: 'sendToWmsPurchaseOrder_Succeeded',
  sendToWmsPurchaseOrder_Failed: 'sendToWmsPurchaseOrder_Failed',

  calculateItemProgress_InProgress: 'calculateItemProgress_InProgress',
  calculateItemProgress_Succeeded: 'calculateItemProgress_Succeeded',
  calculateItemProgress_Failed: 'calculateItemProgress_Failed',

  getCalculatedItemProgress_InProgress: 'getCalculatedItemProgress_InProgress',
  getCalculatedItemProgress_Succeeded: 'getCalculatedItemProgress_Succeeded',
  getCalculatedItemProgress_Failed: 'getCalculatedItemProgress_Failed',

  //----------------------------------------------------------------------------
  // warehouse-purchase-orders
  //----------------------------------------------------------------------------
  getWarehousePurchaseOrderDetail_InProgress: 'getWarehousePurchaseOrderDetail_InProgress',
  getWarehousePurchaseOrderDetail_Succeeded: 'getWarehousePurchaseOrderDetail_Succeeded',
  getWarehousePurchaseOrderDetail_Failed: 'getWarehousePurchaseOrderDetail_Failed',

  filterWarehousePurchaseOrderList_InProgress: 'filterWarehousePurchaseOrderList_InProgress',
  filterWarehousePurchaseOrderList_Succeeded: 'filterWarehousePurchaseOrderList_Succeeded',
  filterWarehousePurchaseOrderList_Failed: 'filterWarehousePurchaseOrderList_Failed',

  approveWarehousePurchaseOrder_InProgress: 'approveWarehousePurchaseOrder_InProgress',
  approveWarehousePurchaseOrder_Succeeded: 'approveWarehousePurchaseOrder_Succeeded',
  approveWarehousePurchaseOrder_Failed: 'approveWarehousePurchaseOrder_Failed',

  rejectWarehousePurchaseOrder_InProgress: 'rejectWarehousePurchaseOrder_InProgress',
  rejectWarehousePurchaseOrder_Succeeded: 'rejectWarehousePurchaseOrder_Succeeded',
  rejectWarehousePurchaseOrder_Failed: 'rejectWarehousePurchaseOrder_Failed',

  cancelWarehousePurchaseOrder_InProgress: 'cancelWarehousePurchaseOrder_InProgress',
  cancelWarehousePurchaseOrder_Succeeded: 'cancelWarehousePurchaseOrder_Succeeded',
  cancelWarehousePurchaseOrder_Failed: 'cancelWarehousePurchaseOrder_Failed',

  getWarehousePurchaseOrderEWaybill_InProgress: 'getWarehousePurchaseOrderEWaybill_InProgress',
  getWarehousePurchaseOrderEWaybill_Succeeded: 'getWarehousePurchaseOrderEWaybill_Succeeded',
  getWarehousePurchaseOrderEWaybill_Failed: 'getWarehousePurchaseOrderEWaybill_Failed',

  //----------------------------------------------------------------------------
  // products
  //----------------------------------------------------------------------------
  filterProductList_InProgress: 'filterProductList_InProgress',
  filterProductList_Succeeded: 'filterProductList_Succeeded',
  filterProductList_Failed: 'filterProductList_Failed',
  resetWarehouseProductList: 'resetWarehouseProductList',

  filterProductListV2_InProgress: 'filterProductListV2_InProgress',
  filterProductListV2_Succeeded: 'filterProductListV2_Succeeded',
  filterProductListV2_Failed: 'filterProductListV2_Failed',

  //----------------------------------------------------------------------------
  // heating-estimations
  //----------------------------------------------------------------------------
  getHeatingEstimationList_InProgress: 'getHeatingEstimationList_InProgress',
  getHeatingEstimationList_Succeeded: 'getHeatingEstimationList_Succeeded',
  getHeatingEstimationList_Failed: 'getHeatingEstimationList_Failed',

  //----------------------------------------------------------------------------
  // stock-movement-logs
  //----------------------------------------------------------------------------
  getStockMovementLogList_InProgress: 'getStockMovementLogList_InProgress',
  getStockMovementLogList_Succeeded: 'getStockMovementLogList_Succeeded',
  getStockMovementLogList_Failed: 'getStockMovementLogList_Failed',

  getStockMovementLogDetail_InProgress: 'getStockMovementLogDetail_InProgress',
  getStockMovementLogDetail_Succeeded: 'getStockMovementLogDetail_Succeeded',
  getStockMovementLogDetail_Failed: 'getStockMovementLogDetail_Failed',
  resetStockMovementLogList: 'resetStockMovementLogList',
  //----------------------------------------------------------------------------
  // user
  //----------------------------------------------------------------------------
  login_InProgress: 'login_InProgress',
  login_Succeeded: 'login_Succeeded',
  login_Failed: 'login_Failed',
  login_Otp_Required: 'login_Otp_Required',
  login_Otp_Reset: 'login_Otp_Reset',

  logout_InProgress: 'logout_InProgress',
  logout_Succeeded: 'logout_Succeeded',
  logout_Failed: 'logout_Failed',

  otpVerify_InProgress: 'otpVerify_InProgress',
  otpVerify_Succeeded: 'otpVerify_Succeeded',
  otpVerify_Failed: 'otpVerify_Failed',

  otpResend_InProgress: 'otpResend_InProgress',
  otpResend_Succeeded: 'otpResend_Succeeded',
  otpResend_Failed: 'otpResend_Failed',

  //----------------------------------------------------------------------------
  // blocked stock disposals
  //----------------------------------------------------------------------------
  completeDisposal_InProgress: 'completeDisposal_InProgress',
  completeDisposal_Succeeded: 'completeDisposal_Succeeded',
  completeDisposal_Failed: 'completeDisposal_Failed',

  completeDisposalBulk_InProgress: 'completeDisposalBulk_InProgress',
  completeDisposalBulk_Succeeded: 'completeDisposalBulk_Succeeded',
  completeDisposalBulk_Failed: 'completeDisposalBulk_Failed',

  filterDisposalList_InProgress: 'filterDisposalList_InProgress',
  filterDisposalList_Succeeded: 'filterDisposalList_Succeeded',
  filterDisposalList_Failed: 'filterDisposalList_Failed',

  //----------------------------------------------------------------------------
  // blocked stock refunds
  //----------------------------------------------------------------------------
  completeRefund_InProgress: 'completeRefund_InProgress',
  completeRefund_Succeeded: 'completeRefund_Succeeded',
  completeRefund_Failed: 'completeRefund_Failed',

  completeRefundBulk_InProgress: 'completeRefundBulk_InProgress',
  completeRefundBulk_Succeeded: 'completeRefundBulk_Succeeded',
  completeRefundBulk_Failed: 'completeRefundBulk_Failed',

  filterRefundList_InProgress: 'filterRefundList_InProgress',
  filterRefundList_Succeeded: 'filterRefundList_Succeeded',
  filterRefundList_Failed: 'filterRefundList_Failed',

  //----------------------------------------------------------------------------
  // blocked stock transfers
  //----------------------------------------------------------------------------

  completeTransferBulk_InProgress: 'completeTransferBulk_InProgress',
  completeTransferBulk_Succeeded: 'completeTransferBulk_Succeeded',
  completeTransferBulk_Failed: 'completeTransferBulk_Failed',

  //----------------------------------------------------------------------------
  // blocked stock customer satisfaction
  //----------------------------------------------------------------------------

  filterBlockedStockRequest_InProgress: 'filterBlockedStockRequest_InProgress',
  filterBlockedStockRequest_Succeeded: 'filterBlockedStockRequest_Succeeded',
  filterBlockedStockRequest_Failed: 'filterBlockedStockRequest_Failed',

  //----------------------------------------------------------------------------
  // blocked stock transfer disposal refund
  //----------------------------------------------------------------------------

  filterBlockedStockTransfers_InProgress: 'filterBlockedStockTransfers_InProgress',
  filterBlockedStockTransfers_Succeeded: 'filterBlockedStockTransfers_Succeeded',
  filterBlockedStockTransfers_Failed: 'filterBlockedStockTransfers_Failed',

  filterBlockedStockRefunds_InProgress: 'filterBlockedStockRefunds_InProgress',
  filterBlockedStockRefunds_Succeeded: 'filterBlockedStockRefunds_Succeeded',
  filterBlockedStockRefunds_Failed: 'filterBlockedStockRefunds_Failed',

  filterBlockedStockDisposals_InProgress: 'filterBlockedStockDisposals_InProgress',
  filterBlockedStockDisposals_Succeeded: 'filterBlockedStockDisposals_Succeeded',
  filterBlockedStockDisposals_Failed: 'filterBlockedStockDisposals_Failed',

  getBlockedStockTransfer_InProgress: 'getBlockedStockTransfer_InProgress',
  getBlockedStockTransfer_Succeeded: 'getBlockedStockTransfer_Succeeded',
  getBlockedStockTransfer_Failed: 'getBlockedStockTransfer_Failed',

  getBlockedStockRefund_InProgress: 'getBlockedStockRefund_InProgress',
  getBlockedStockRefund_Succeeded: 'getBlockedStockRefund_Succeeded',
  getBlockedStockRefund_Failed: 'getBlockedStockRefund_Failed',

  getBlockedStockDisposal_InProgress: 'getBlockedStockDisposal_InProgress',
  getBlockedStockDisposal_Succeeded: 'getBlockedStockDisposal_Succeeded',
  getBlockedStockDisposal_Failed: 'getBlockedStockDisposal_Failed',


  //----------------------------------------------------------------------------
  // dincer-consumer
  //----------------------------------------------------------------------------
  centralWarehouseOutgoingTransfer_InProgress: 'centralWarehouseOutgoingTransfer_InProgress',
  centralWarehouseOutgoingTransfer_Succeeded: 'centralWarehouseOutgoingTransfer_Succeeded',
  centralWarehouseOutgoingTransfer_Failed: 'centralWarehouseOutgoingTransfer_Failed',

  centralWarehouseIncomingTransfer_InProgress: 'centralWarehouseIncomingTransfer_InProgress',
  centralWarehouseIncomingTransfer_Succeeded: 'centralWarehouseIncomingTransfer_Succeeded',
  centralWarehouseIncomingTransfer_Failed: 'centralWarehouseIncomingTransfer_Failed',

  sendWarehousePurchaseOrders_InProgress: 'sendWarehousePurchaseOrders_InProgress',
  sendWarehousePurchaseOrders_Succeeded: 'sendWarehousePurchaseOrders_Succeeded',
  sendWarehousePurchaseOrders_Failed: 'sendWarehousePurchaseOrders_Failed',

  centralWarehouseIncomingBlockedStockTransfer_InProgress: 'centralWarehouseIncomingBlockedStockTransfer_InProgress',
  centralWarehouseIncomingBlockedStockTransfer_Succeeded: 'centralWarehouseIncomingBlockedStockTransfer_Succeeded',
  centralWarehouseIncomingBlockedStockTransfer_Failed: 'centralWarehouseIncomingBlockedStockTransfer_Failed',

  //----------------------------------------------------------------------------
  // warehouse-location
  //----------------------------------------------------------------------------
  filterWarehouseLocation_InProgress: 'filterWarehouseLocation_InProgress',
  filterWarehouseLocation_Succeeded: 'filterWarehouseLocation_Succeeded',
  filterWarehouseLocation_Failed: 'filterWarehouseLocation_Failed',

  //----------------------------------------------------------------------------
  // dincer
  //----------------------------------------------------------------------------
  departTransferVehicle_InProgress: 'departTransferVehicle_InProgress',
  departTransferVehicle_Succeeded: 'departTransferVehicle_Succeeded',
  departTransferVehicle_Failed: 'departTransferVehicle_Failed',

  sendDepartedOrder_InProgress: 'sendDepartedOrder_InProgress',
  sendDepartedOrder_Succeeded: 'sendDepartedOrder_Succeeded',
  sendDepartedOrder_Failed: 'sendDepartedOrder_Failed',

  //----------------------------------------------------------------------------
  // timer
  //----------------------------------------------------------------------------
  timerTick: 'timerTick',

  //----------------------------------------------------------------------------
  // country
  //----------------------------------------------------------------------------
  filterCountryList_InProgress: 'filterCountryList_InProgress',
  filterCountryList_Succeeded: 'filterCountryList_Succeeded',
  filterCountryList_Failed: 'filterCountryList_Failed',

  //----------------------------------------------------------------------------
  // city
  //----------------------------------------------------------------------------
  filterCityList_InProgress: 'filterCityList_InProgress',
  filterCityList_Succeeded: 'filterCityList_Succeeded',
  filterCityList_Failed: 'filterCityList_Failed',

  //----------------------------------------------------------------------------
  // cancelled-orders
  //----------------------------------------------------------------------------
  getCancelledOrders_InProgress: 'getCancelledOrders_InProgress',
  getCancelledOrders_Succeeded: 'getCancelledOrders_Succeeded',
  getCancelledOrders_Failed: 'getCancelledOrders_Failed',

  getCancelledOrdersCount_InProgress: 'getCancelledOrdersCount_InProgress',
  getCancelledOrdersCount_Succeeded: 'getCancelledOrdersCount_Succeeded',
  getCancelledOrdersCount_Failed: 'getCancelledOrdersCount_Failed',

  completeCancelledOrder_InProgress: 'completeCancelledOrder_InProgress',
  completeCancelledOrder_Succeeded: 'completeCancelledOrder_Succeeded',
  completeCancelledOrder_Failed: 'completeCancelledOrder_Failed',


  //----------------------------------------------------------------------------
  // production-module
  //----------------------------------------------------------------------------
  executeProductionModule_InProgress: 'executeProductionModule_InProgress',
  executeProductionModule_Succeeded: 'executeProductionModule_Succeeded',
  executeProductionModule_Failed: 'executeProductionModule_Failed',
  // returned-orders
  //----------------------------------------------------------------------------
  getReturnedOrders_InProgress: 'getReturnedOrders_InProgress',
  getReturnedOrders_Succeeded: 'getReturnedOrders_Succeeded',
  getReturnedOrders_Failed: 'getReturnedOrders_Failed',

  getReturnedOrdersCount_InProgress: 'getReturnedOrdersCount_InProgress',
  getReturnedOrdersCount_Succeeded: 'getReturnedOrdersCount_Succeeded',
  getReturnedOrdersCount_Failed: 'getReturnedOrdersCount_Failed',

  completeReturnedOrders_InProgress: 'completeReturnedOrders_InProgress',
  completeReturnedOrders_Succeeded: 'completeReturnedOrders_Succeeded',
  completeReturnedOrders_Failed: 'completeReturnedOrders_Failed',

  // missinproducts-orders
  //----------------------------------------------------------------------------
  getMissingProductsOrders_InProgress: 'getMissingProductsOrders_InProgress',
  getMissingProductsOrders_Succeeded: 'getMissingProductsOrders_Succeeded',
  getMissingProductsOrders_Failed: 'getMissingProductsOrders_Failed',

  getMissingProductsOrdersCount_InProgress: 'getMissingProductsOrdersCount_InProgress',
  getMissingProductsOrdersCount_Succeeded: 'getMissingProductsOrdersCount_Succeeded',
  getMissingProductsOrdersCount_Failed: 'getMissingProductsOrdersCount_Failed',

  cancelMissingProducts_InProgress: 'cancelMissingProducts_InProgress',
  cancelMissingProducts_Succeeded: 'cancelMissingProducts_Succeeded',
  cancelMissingProducts_Failed: 'cancelMissingProducts_Failed',

  removeFromSalesInMissingProductsOrders_InProgress: 'removeFromSalesInMissingProductsOrders_InProgress',
  removeFromSalesInMissingProductsOrders_Succeeded: 'removeFromSalesInMissingProductsOrders_Succeeded',
  removeFromSalesInMissingProductsOrders_Failed: 'removeFromSalesInMissingProductsOrders_Failed',

  approveCancelMissingProducts_InProgress: 'approveCancelMissingProducts_InProgress',
  approveCancelMissingProducts_Succeeded: 'approveCancelMissingProducts_Succeeded',
  approveCancelMissingProducts_Failed: 'approveCancelMissingProducts_Failed',

  getWarehouseStatusControl_InProgress: 'getWarehouseStatusControl_InProgress',
  getWarehouseStatusControl_Succeeded: 'getWarehouseStatusControl_Succeeded',
  getWarehouseStatusControl_Failed: 'getWarehouseStatusControl_Failed',

  // transactions
  //----------------------------------------------------------------------------
  getTransactionsList_InProgress: 'getTransactionsList_InProgress',
  getTransactionsList_Succeeded: 'getTransactionsList_Succeeded',
  getTransactionsList_Failed: 'getTransactionsList_Failed',

  publishTransactions_InProgress: 'publishTransactions_InProgress',
  publishTransactions_Succeeded: 'publishTransactions_Succeeded',
  publishTransactions_Failed: 'publishTransactions_Failed',

  getEquipmentTransferList_InProgress: 'getEquipmentTransferList_InProgress',
  getEquipmentTransferList_Succeeded: 'getEquipmentTransferList_Succeeded',
  getEquipmentTransferList_Failed: 'getEquipmentTransferList_Failed',

  getPickerFilteredList_InProgress: 'getPickerFilteredList_InProgress',
  getPickerFilteredList_Succeeded: 'getPickerFilteredList_Succeeded',
  getPickerFilteredList_Failed: 'getPickerFilteredList_Failed',

  createEquipmentTransfer_InProgress: 'createEquipmentTransfer_InProgress',
  createEquipmentTransfer_Succeeded: 'createEquipmentTransfer_Succeeded',
  createEquipmentTransfer_Failed: 'createEquipmentTransfer_Failed',

  changeTransferVisibility_InProgress: 'changeTransferVisibility_InProgress',
  changeTransferVisibility_Succeeded: 'changeTransferVisibility_Succeeded',
  changeTransferVisibility_Failed: 'changeTransferVisibility_Failed',

  generateEwaybill_InProgress: 'generateEwaybill_InProgress',
  generateEwaybill_Succeeded: 'generateEwaybill_Succeeded',
  generateEwaybill_Failed: 'generateEwaybill_Failed',

  createRefund_InProgress: 'createRefund_InProgress',
  createRefund_Succeeded: 'createRefund_Succeeded',
  createRefund_Failed: 'createRefund_Failed',

  createWaybill_InProgress: 'createWaybill_InProgress',
  createWaybill_Succeeded: 'createWaybill_Succeeded',
  createWaybill_Failed: 'createWaybill_Failed',

  createWaybillBulk_InProgress: 'createWaybillBulk_InProgress',
  createWaybillBulk_Succeeded: 'createWaybillBulk_Succeeded',
  createWaybillBulk_Failed: 'createWaybillBulk_Failed',

};
