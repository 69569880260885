import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Tabs, Row, Col, Card, Badge } from 'antd';
// --------------------------------------------------
import './Home.scss';
import { CourierPanel } from './CourierPanel';
import { PickerPanel } from './PickerPanel';
import { OrderBoard } from './OrderBoard';
import { YandexMap } from './YandexMap';
import { GoogleMap } from './GoogleMap';
import { HeatingEstimation } from '../Stock/HeatingEstimation';
import { CancelledOrders } from '../Stock/CancelledOrders';
import { MissingProductsOrders } from '../Stock/MissingProductsOrders';
import { HeatingEstimationAlert } from './HeatingEstimationAlert';
import { ReturnedOrders } from '../Stock/ReturnedOrders';

// --------------------------------------------------
import { history } from './../../history';
import { Spinner, TabPaneContentWithNumbers } from './../../components';
import { Can, permission, canAccess } from './../../guard';
import {
  courierAction,
  orderAction,
  pickerAction,
  cancelledOrdersAction,
  warehouseAction,
  returnedOrdersAction,
  missingProductsOrdersAction,
  heatingEstimationAction,
} from './../../actions';
import { constants, getCountryCode } from './../../shared';
import { getDefaultDateRange } from './../../shared/utility';
import { ExpirationDateList } from '../Stock/ExpirationDate';
import { track } from '../../services/segment';
// --------------------------------------------------

const { TabPane } = Tabs;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
    this.timerCanceledOrder = null;
    this.timer = null;
    this.defaultDateRange = getDefaultDateRange();
    this.expiryDateFilter = {
      removeFromSaleDateRange: {
        startDate: this.defaultDateRange.startDate,
        endDate: this.defaultDateRange.endDate,
      },
    };
  }

  componentDidMount() {
    this.prepareComponent();
  }

  componentDidUpdate(prevProps) {
    if (this.props.estimationProducts !== prevProps.estimationProducts) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        bakerySuggestionCount: this.getBakeryProductsSuggestionCount(),
      });
    }
  }

  componentWillUnmount() {
    if (this.timerCanceledOrder) {
      clearInterval(this.timerCanceledOrder);
    }
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.timerReturnedOrders) {
      clearInterval(this.timerReturnedOrders);
    }
    if (this.timerMissingProductsOrders) {
      clearInterval(this.timerMissingProductsOrders);
    }

    if (this.timerExpirationDate) {
      clearInterval(this.timerExpirationDate);
    }
  }

  stateFactory = () => ({
    activeWorkerTab: 'courier',
    bakerySuggestionCount: 0,
  });

  prepareComponent = () => {
    this.props.onGetCourierList();
    this.props.onGetOrderList();
    this.props.onGetCourierBusyReasons();
    this.props.onGetPickerBusyReasons();
    this.props.onGetPickerList();
    this.props.onGetHeatingEstimationList();
    if (this.props.returnedOrdersPermission) {
      this.props.onGetReturnedOrdersCount();
      this.timerReturnedOrders = setInterval(() => {
        this.props.onGetReturnedOrdersCount();
      }, 1000 * 60 * 10);
    }
    if (this.props.missingProductsPermission) {
      this.props.onGetMissingProductsOrdersCount();
      this.timerMissingProductsOrders = setInterval(() => {
        this.props.onGetMissingProductsOrdersCount();
      }, 1000 * 60 * 1);
    }

    if (this.props.cancelledOrdersPermission) {
      this.props.onGetCancelledOrdersCount();
      this.timerCanceledOrder = setInterval(() => {
        this.props.onGetCancelledOrdersCount();
      }, 1000 * 60 * 10);
    }
    if (this.isWarehouseDomainTypeWater()) {
      this.props.onGetAvaliableStockOfLocations();
      this.timer = setInterval(() => {
        this.props.onGetAvaliableStockOfLocations();
      }, 1000 * 60 * 5);
    }
    if (this.props.expiringProductsPermission) {
      this.props.onGetExpirationDateList(null, this.expiryDateFilter);
      this.timerExpirationDate = setInterval(() => {
        this.props.onGetExpirationDateList(null, this.expiryDateFilter);
      }, 1000 * 60 * 5);
    }
  };

  isWarehouseDomainTypeWater = () =>
    this.props.warehouse.domainTypes &&
    this.props.warehouse.domainTypes.includes(constants.DOMAIN_TYPE.WATER);

  onChangeTab = (activeTab) => {
    const { CWId } = this.props;
    if (activeTab === 'orders') {
      history.push(`/r/${CWId}/dashboard/orders`);
    }
    if (activeTab === 'map') {
      history.push(`/r/${CWId}/dashboard/map`);
    }
    if (activeTab === 'heatingEstimation') {
      history.push(`/r/${CWId}/stock/heating-estimation/list`);
    }
    if (activeTab === 'cancelledOrders') {
      history.push(`/r/${CWId}/stock/cancelled-orders`);
    }
    if (activeTab === 'returnedOrders') {
      history.push(`/r/${CWId}/stock/returned-orders`);
    }
    if (activeTab === 'missingProductsOrders') {
      history.push(`/r/${CWId}/stock/order-missing-products`);
    }
    if (activeTab === 'expirationDate') {
      history.push(`/r/${CWId}/dashboard/expiration-date`);
    }
  };

  getOrderDetail = (id, domainType) => {
    const { onGetOrderDetail } = this.props;
    onGetOrderDetail(id, domainType);
  };

  getBakeryProductsSuggestionCount = () => {
    let count = 0;
    if (this.props.estimationProducts) {
      this.props.estimationProducts.forEach((estimation) => {
        if (estimation.suggestion) {
          count += 1;
        }
      });
    }
    return count;
  };

  render() {
    const {
      activeTab,
      courier,
      loading,
      warehouse,
      order,
      picker,
    } = this.props;

    if (loading) {
      return <Spinner />;
    }

    const sidePanel = (
      <div className="SidePanelComponent">
        <Tabs
          defaultActiveKey="courier"
          activeKey={this.state.activeWorkerTab}
          onChange={(activeKey) => {
            this.setState({ activeWorkerTab: activeKey });
          }}
          onNextClick={() => {
            if (this.state.activeWorkerTab === 'courier') {
              this.setState({ activeWorkerTab: 'picker' });
              track('HOME_ORDERS_PICKERS_TAB_CLICKED');
            } else {
              this.setState({ activeWorkerTab: 'courier' });
              track('HOME_ORDERS_COURIERS_TAB_CLICKED');
            }
          }}
          onPrevClick={() => {
            if (this.state.activeWorkerTab === 'courier') {
              this.setState({ activeWorkerTab: 'picker' });
              track('HOME_ORDERS_PICKERS_TAB_CLICKED');
            } else {
              this.setState({ activeWorkerTab: 'courier' });
              track('HOME_ORDERS_COURIERS_TAB_CLICKED');
            }
          }}
        >
          <TabPane
            tab={
              <TabPaneContentWithNumbers
                list={this.props.courier.list.data}
                type="courier"
              />
            }
            key="courier"
          >
            <CourierPanel
              timer={this.props.timer}
              couriers={this.props.courier.list}
              makeCourierBusy={this.props.onMakeCourierBusy}
              makeCourierFree={this.props.onMakeCourierFree}
              courierBusyReasons={this.props.courierBusyReasons}
              availableStockOfLocations={this.props.availableStockOfLocations}
              isWaterWarehouse={this.isWarehouseDomainTypeWater()}
            />
          </TabPane>
          <TabPane
            tab={
              <TabPaneContentWithNumbers
                list={this.props.picker.list.data}
                type="picker"
              />
            }
            key="picker"
          >
            <PickerPanel pickers={this.props.picker.list} />
          </TabPane>
        </Tabs>
      </div>
    );

    return (
      <div className="Home_main">
        <Tabs defaultActiveKey={activeTab} onChange={this.onChangeTab}>
          <TabPane tab={this.props.t('pages.Home.tab.orders')} key="orders">
            <Can allow={permission.orderList}>
              <Row>
                <HeatingEstimationAlert />
                <Card className="OrderCard">
                  <Row>
                    <Col md={20}>
                      <OrderBoard
                        timer={this.props.timer}
                        orders={order.list}
                        selectedOrder={order.detail}
                        getOrderDetail={this.getOrderDetail}
                        couriers={courier.list}
                        pickers={picker.list}
                        changeOrderCourier={this.changeOrderCourier}
                        changeOrderPicker={this.changeOrderPicker}
                      />
                    </Col>
                    <Col md={4} className="SidePanel">
                      {sidePanel}
                    </Col>
                  </Row>
                </Card>
              </Row>
            </Can>
          </TabPane>
          <TabPane tab={this.props.t('pages.Home.tab.map')} key="map">
            <Can allow={permission.orderMap}>
              <Card className="MapCard">
                <Row>
                  <Col md={20}>
                    {getCountryCode() === 'tr' ? (
                      <YandexMap
                        warehouse={warehouse}
                        couriers={courier.list}
                        orders={order.list}
                      />
                    ) : (
                      <GoogleMap />
                    )}
                  </Col>
                  <Col md={4} className="SidePanel">
                    {sidePanel}
                  </Col>
                </Row>
              </Card>
            </Can>
          </TabPane>
          <TabPane
            tab={
              <Badge count={this.state.bakerySuggestionCount}>
                <span style={{ marginRight: 22 }}>
                  {this.props.t('pages.Home.tab.heatingEstimation')}
                </span>
              </Badge>
            }
            key="heatingEstimation"
          >
            <Card className="HeatingEstimationCard">
              <HeatingEstimation />
            </Card>
          </TabPane>
          {this.props.cancelledOrdersPermission ? (
            <TabPane
              tab={
                <Badge count={this.props.cancelledOrdersCount}>
                  <span style={{ marginRight: 22 }}>
                    {this.props.t('pages.Home.tab.cancelledOrders')}
                  </span>
                </Badge>
              }
              key="cancelledOrders"
            >
              <Can allow={permission.cancelledOrders}>
                <Card className="CancelledOrders">
                  <CancelledOrders />
                </Card>
              </Can>
            </TabPane>
          ) : null}

          {this.props.returnedOrdersPermission && (
            <TabPane
              tab={
                <Badge count={this.props.returnedOrdersCount}>
                  <span style={{ marginRight: 22 }}>
                    {this.props.t('pages.Home.tab.returnedOrders')}
                  </span>
                </Badge>
              }
              key="returnedOrders"
            >
              <Card className="ReturnedOrders">
                <ReturnedOrders />
              </Card>
            </TabPane>
          )}

          {this.props.missingProductsPermission && (
            <TabPane
              tab={
                <Badge count={this.props.missingProductsCount}>
                  <span style={{ marginRight: 22 }}>
                    {this.props.t('pages.Home.tab.missingProductsOrders')}
                  </span>
                </Badge>
              }
              key="missingProductsOrders"
            >
              <MissingProductsOrders />
            </TabPane>
          )}
          {this.props.expiringProductsPermission && (
            <TabPane
              tab={
                <Badge count={this.props.expiredProductsCount}>
                  <span style={{ marginRight: 22 }}>
                    {this.props.t('pages.Home.tab.expirationDate')}
                  </span>
                </Badge>
              }
              key="expirationDate"
            >
              <ExpirationDateList />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onGetCourierList: () => {
    return dispatch(courierAction.getCourierList());
  },
  onGetCourierBusyReasons: () => {
    return dispatch(courierAction.getBusyReasons());
  },
  onGetPickerBusyReasons: () => {
    return dispatch(pickerAction.getPickerBusyReasons());
  },
  onGetOrderList: () => {
    return dispatch(orderAction.getOrderList());
  },
  onGetPickerList: () => {
    return dispatch(pickerAction.getPickerList());
  },
  onGetOrderDetail: (id, domainType) => {
    return dispatch(orderAction.getOrderDetail(id, domainType));
  },
  onMakeCourierBusy: (courierId, data) => {
    return dispatch(courierAction.makeCourierBusy(courierId, data));
  },
  onMakeCourierFree: (courierId) => {
    return dispatch(courierAction.makeCourierFree(courierId));
  },
  onMakePickerBusy: (pickerId, data) => {
    return dispatch(
      pickerAction.changeStatusOfPickerToBusy({ pickerId }, data),
    );
  },
  onMakePickerFree: (pickerId) => {
    return dispatch(pickerAction.changeStatusOfPickerToFree({ pickerId }));
  },
  onGetMissingProductsOrdersCount: () => {
    return dispatch(
      missingProductsOrdersAction.getMissingProductsOrdersCount({
        statuses: [constants.REMOVED_FROM_SALES_IN_ORDERS.CREATED],
      }),
    );
  },
  onGetCancelledOrdersCount: () => {
    return dispatch(cancelledOrdersAction.getCancelledOrdersCount());
  },
  onGetAvaliableStockOfLocations: () => {
    return dispatch(
      warehouseAction.getAvailableStockOfLocationsByWarehouseId(),
    );
  },
  onGetReturnedOrdersCount: () =>
    dispatch(returnedOrdersAction.getReturnedOrdersCount()),
  onGetHeatingEstimationList: () =>
    dispatch(heatingEstimationAction.getHeatingEstimationList()),
  onGetExpirationDateList: (warehouseId, filter) =>
    dispatch(warehouseAction.getExpiringProducts(warehouseId, filter)),
});

const mapStateToProps = (state) => ({
  order: state.order,
  courier: state.courier,
  courierBusyReasons: state.courier.busyReasons,
  pickerBusyReasons: state.picker.busyReasons,
  warehouse: state.auth.currentWarehouse,
  CWId: state.auth.currentWarehouse._id,
  picker: state.picker,
  timer: state.timer,
  missingProductsCount: state.missingProductsOrders.list.count,
  cancelledOrdersCount: state.cancelledOrders.list.count,
  cancelledOrdersPermission: canAccess(
    permission.cancelledOrders,
    state.auth.user.permissions,
  ),
  returnedOrdersPermission: canAccess(
    permission.returnedOrders,
    state.auth.user.permissions,
  ),
  returnedOrdersCount: state.returnedOrders.list.count,
  availableStockOfLocations: state.warehouse.availableStockOfLocations.data,
  missingProductsPermission: canAccess(
    permission.missingProductOrders,
    state.auth.user.permissions,
  ),
  expiringProductsPermission: canAccess(
    permission.homeExpirationDate,
    state.auth.user.permissions,
  ),
  expiredProductsCount: state.warehouse.expiringProducts.count,
  estimationProducts: state.heatingEstimation.heatingEstimationList.data,
});

const HomeConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(Home));

export { HomeConnected as Home };
