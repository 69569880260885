/* eslint-disable react/self-closing-comp */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Modal,
  Button,
  Dropdown,
  Icon,
  Menu,
  Form,
  Descriptions,
  Table,
  Avatar,
} from 'antd';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './BlockedStockDetail.scss';
import { SupplierSelect } from './SupplierSelect';
import { SupplierAccountSelect } from './SupplierAccountSelect';
import { ReasonMultiSelect, ConclusionTypeSelect } from './formFields';
/*----------------------------------------------------------------------------*/
import {
  Spinner,
  CopyToClipboard,
  ActionTimeline,
} from './../../../../components';
import { blockedStockAction, supplierAction } from './../../../../actions';
import {
  getTranslation,
  constants,
  getDateTimeFormatString,
} from './../../../../shared';
import { pageLoadEvents } from '../../../../services/events';
import { pageView } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const { BLOCKED_STOCK_CONCLUSION_TYPES } = constants;
class BlockedStockDetail extends Component {
  settings = {
    main: {
      className: 'BlockedStockDetail_main',
    },
    card: {
      title: this.props.t('pages.Operation.BlockedStock.detail.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  formItemLayout = {
    style: {
      marginTop: 15,
      marginBottom: 15,
    },
    labelCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 8, offset: 0 },
    },
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 16, offset: 0 },
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_REMOVE_FROM_SALES_DETAIL });
    this.getDetail();
    this.props.onGetSupplierList();
  }

  stateFactory = () => {
    return {
      isOpenModalUpdateReason: false,
      isOpenModalConclude: false,
      isOpenModalConclusionType: false,
      reason: null,
      reasonList: [],
      subReason: null,
      subReasonList: [],
      conclusionType: null,
      supplier: null,
      account: null,
      isClickedCancelBlockedStock: false,
      isClickedConcudeBlockedStock: false,
      isClickedUpdateBlockedStockReasonButton: false,
      isClickedChangeConclusionBlockedStock: false,
    };
  };

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {loading ? <Spinner /> : <this.PageDetail />}
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    return (
      <Dropdown overlay={this.MenuAction}>
        <Button style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.t('pages.Operation.BlockedStock.detail.button.actions')}{' '}
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  PageDetail = (props) => {
    return (
      <div>
        <this.PageDescription />
        <this.ProductTable />
        <ActionTimeline
          actions={this.props.detail}
          translate={getTranslation.blockedStockStatus}
        />
      </div>
    );
  };

  MenuAction = () => {
    const { detail } = this.props;

    return (
      <Menu>
        <Menu.Item key="ChangeReasonButton">
          <this.ChangeReasonButton />
        </Menu.Item>
        <Menu.Item key="ConcludeButton">
          <this.ConcludeButton />
        </Menu.Item>
        <Menu.Item key="ChangeConclusionButton">
          <this.ChangeConclusionButton />
        </Menu.Item>
        {detail && (detail.status || detail.reason.isAutoComplete) ? (
          <Menu.Item key="CancelButton">
            <this.CancelButton />
          </Menu.Item>
        ) : null}
      </Menu>
    );
  };

  ChangeReasonButton = (props) => {
    const { isClickedUpdateBlockedStockReasonButton } = this.state;
    return (
      <div>
        <Button
          block
          loading={isClickedUpdateBlockedStockReasonButton}
          disabled={
            !this.isBlockedStockCreated() ||
            isClickedUpdateBlockedStockReasonButton
          }
          onClick={() => {
            this.setState({
              ...this.state,
              ...this.getReasonData(),
            });
            this.setState({
              isOpenModalUpdateReason: true,
              isClickedUpdateBlockedStockReasonButton: true,
            });
          }}
        >
          {this.props.t(
            'pages.Operation.BlockedStock.detail.button.changeReason',
          )}
        </Button>
        <Modal
          title={this.props.t(
            'pages.Operation.BlockedStock.detail.modal.changeReason',
          )}
          visible={this.state.isOpenModalUpdateReason}
          onOk={() => {
            this.updateReason();
            console.log('onOk', this.state);
            this.setState(this.stateFactory());
            this.setState({ isClickedUpdateBlockedStockReasonButton: true });
          }}
          onCancel={() => {
            console.log('onCancel', this.state);
            this.setState(this.stateFactory());
          }}
        >
          <this.ChangeReasonForm />
        </Modal>
      </div>
    );
  };

  ConcludeButton = (props) => {
    const { isClickedConcudeBlockedStock } = this.state;
    return (
      <div>
        <Button
          block
          loading={isClickedConcudeBlockedStock}
          disabled={
            !this.isBlockedStockCreated() || isClickedConcudeBlockedStock
          }
          onClick={() => {
            this.setState({
              ...this.state,
              ...this.getConcludeTypeValue(),
            });
            this.setState({ isOpenModalConclude: true });
            this.setState({ isClickedConcudeBlockedStock: false });
          }}
        >
          {this.props.t('pages.Operation.BlockedStock.detail.button.conclude')}
        </Button>
        <Modal
          title={this.props.t(
            'pages.Operation.BlockedStock.detail.modal.conclude',
          )}
          visible={this.state.isOpenModalConclude}
          okButtonProps={{
            loading: this.state.isClickedConcudeBlockedStock,
            disabled:
              !this.canBeDecided() || this.state.isClickedConcudeBlockedStock,
          }}
          cancelButtonProps={{
            disabled: this.state.isClickedConcudeBlockedStock,
          }}
          onOk={() => {
            this.concludeBlockedStock();
            console.log('onOk', this.state);
            this.setState(this.stateFactory());
            this.setState({ isClickedConcudeBlockedStock: true });
          }}
          onCancel={() => {
            console.log('onCancel', this.state);
            this.setState(this.stateFactory());
          }}
        >
          <this.ConcludeForm />
        </Modal>
      </div>
    );
  };

  isBlockedStockReasonStockMissing = () => {
    const { detail } = this.props;
    if (!detail || !detail.reason || !detail.reason.type) {
      return false;
    }
    if (
      detail.reason.type.toString() ===
        constants.BLOCKED_STOCK_REASON_OPERATION_TYPES.STOCK_MISSING.toString() ||
      detail.reason.type.toString() ===
        constants.BLOCKED_STOCK_REASON_OPERATION_TYPES.CUSTOMER_SATISFACTION.toString() ||
      detail.reason.type.toString() ===
        constants.BLOCKED_STOCK_REASON_OPERATION_TYPES.STOCK_ORDER_MISSING.toString()
    ) {
      return true;
    }
    return false;
  };

  ChangeConclusionButton = (props) => {
    const { isClickedChangeConclusionBlockedStock } = this.state;
    return (
      <div>
        <Button
          block
          loading={isClickedChangeConclusionBlockedStock}
          disabled={
            !this.isBlockedStockConcluded() ||
            this.isBlockedStockReasonStockMissing() ||
            isClickedChangeConclusionBlockedStock
          }
          onClick={() => {
            this.setState({
              ...this.state,
              ...this.getConcludeTypeValue(),
            });
            this.setState({ isOpenModalConclusionType: true });
            this.setState({ isClickedChangeConclusionBlockedStock: false });
          }}
        >
          {this.props.t(
            'pages.Operation.BlockedStock.detail.button.changeConclusionType',
          )}
        </Button>
        <Modal
          title={this.props.t(
            'pages.Operation.BlockedStock.detail.modal.changeConclusionType',
          )}
          visible={this.state.isOpenModalConclusionType}
          okButtonProps={{
            loading: this.state.isClickedChangeConclusionBlockedStock,
            disabled:
              !this.canBeDecided() ||
              this.state.isClickedChangeConclusionBlockedStock,
          }}
          cancelButtonProps={{
            disabled: this.state.isClickedChangeConclusionBlockedStock,
          }}
          onOk={() => {
            this.changeConclusionBlockedStock();
            console.log('onOk', this.state);
            this.setState(this.stateFactory());
            this.setState({ isClickedChangeConclusionBlockedStock: true });
          }}
          onCancel={() => {
            console.log('onCancel', this.state);
            this.setState(this.stateFactory());
          }}
        >
          <this.ConcludeForm />
        </Modal>
      </div>
    );
  };

  CancelButton = () => (
    <Button
      icon="delete"
      block
      loading={this.state.isClickedCancelBlockedStock}
      disabled={this.state.isClickedCancelBlockedStock}
      onClick={this.onCancelClick}
    >
      {this.props.t('pages.Operation.BlockedStock.detail.button.cancel')}
    </Button>
  );

  onCancelClick = async () => {
    const {
      onCancelBlockedStock,
      detail: { id },
    } = this.props;
    this.setState({ isClickedCancelBlockedStock: true });
    onCancelBlockedStock(id).catch(() => {
      this.setState({ isClickedCancelBlockedStock: false });
    });
  };

  ConcludeForm = (props) => {
    return (
      <Form>
        <this.ConclusionTypeField />
        <this.SupplierAndAccountFields />
      </Form>
    );
  };

  SupplierAndAccountFields = () => {
    if (this.isConcludeTypeRefund()) {
      return (
        <>
          <this.SupplierField />
          <this.SupplierAccountField />
        </>
      );
    }
    return null;
  };

  SupplierField = (props) => {
    const { detail } = this.props;
    const { suppliers } = detail.product;
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.detail.form.supplier',
        )}
      >
        <SupplierSelect
          onChange={(supplier) => {
            console.log('supplier', supplier);
            this.setState((state) => {
              return { ...state, supplier, account: null };
            });
          }}
          value={this.state.supplier}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.detail.form.supplier',
          )}
          list={suppliers}
        />
      </Form.Item>
    );
  };

  SupplierAccountField = (props) => {
    const { supplier } = this.state;
    if (!supplier) {
      return null;
    }
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t('pages.Operation.BlockedStock.detail.form.account')}
      >
        <SupplierAccountSelect
          onChange={(account) => {
            console.log('account', account);
            this.setState({ account });
          }}
          value={this.state.account}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.detail.form.account',
          )}
          list={supplier.accounts}
        />
      </Form.Item>
    );
  };

  canBeDecided = () => {
    const { supplier, account, conclusionType } = this.state;
    if (
      (this.isConcludeTypeRefund() && supplier && account) ||
      (conclusionType && !this.isConcludeTypeRefund())
    ) {
      return true;
    }
    return false;
  };

  isConcludeTypeRefund = () => {
    const { conclusionType } = this.state;
    if (
      conclusionType &&
      conclusionType.key === BLOCKED_STOCK_CONCLUSION_TYPES.REFUND.toString()
    ) {
      return true;
    }
    return false;
  };

  ChangeReasonForm = (props) => {
    return (
      <Form>
        <this.ReasonField />
        <this.SubReasonField />
      </Form>
    );
  };

  ReasonField = () => {
    const { reason, reasonList } = this.state;
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t('pages.Operation.BlockedStock.detail.form.reason')}
      >
        <ReasonMultiSelect
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.detail.form.reason',
          )}
          list={reasonList}
          value={reason}
          loading={!reasonList}
          onChange={(reason) => {
            this.setState({ reason });
            this.setState({ subReason: null });
            this.setState({ subReasonList: reason.subReasons });
          }}
        />
      </Form.Item>
    );
  };

  SubReasonField = () => {
    const { subReason, subReasonList } = this.state;
    if (!subReasonList || !subReasonList.length) {
      return null;
    }
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.detail.form.subReason',
        )}
      >
        <ReasonMultiSelect
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.detail.form.subReason',
          )}
          list={subReasonList}
          value={subReason}
          onChange={(subReason) => {
            this.setState({ subReason });
          }}
        />
      </Form.Item>
    );
  };

  ConclusionTypeField = () => {
    const { conclusionType } = this.state;
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.detail.form.conclusionType',
        )}
      >
        <ConclusionTypeSelect
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.detail.form.conclusionType',
          )}
          value={conclusionType}
          onChange={(conclusionType) => {
            console.log(conclusionType);
            this.setState({ conclusionType });
          }}
        />
      </Form.Item>
    );
  };

  getDetail = () => {
    const { id, onGetDetail, onGetReasonList } = this.props;
    onGetReasonList();
    onGetDetail(id);
  };

  getReasonData = () => {
    const { isOpenModalUpdateReason } = this.state;
    const { detail, reasonList } = this.props;
    if (!detail || !detail.reason || !reasonList || !reasonList.length) {
      return {
        reasonList,
        reason: null,
        subReason: null,
        subReasonList: [],
      };
    }
    if (isOpenModalUpdateReason) {
      return this.state;
    }
    const reason = reasonList.find((r) => r.id === detail.reason.id);

    const subReason = detail.subReason || null;
    if (subReason && subReason.values) {
      subReason.value = subReason.values;
    }
    const subReasonList = reason.subReasons || [];
    return { reasonList, reason, subReason, subReasonList };
  };

  getConcludeTypeValue = () => {
    const { isOpenModalConclude } = this.state;
    const { detail } = this.props;
    if (isOpenModalConclude) {
      return this.state;
    }
    return detail.conclusionType
      ? { conclusionType: { key: detail.conclusionType } }
      : null;
  };

  concludeBlockedStock = () => {
    const { conclusionType, supplier, account } = this.state;
    const payload = {
      conclusionType: conclusionType.key,
    };
    if (this.isConcludeTypeRefund()) {
      payload.supplier = supplier.id;
      payload.accountCode = account.code;
    }
    const { id, onConcludeBlockedStock } = this.props;
    onConcludeBlockedStock(id, payload, true)
      .then(() => {
        this.setState({ isClickedConcudeBlockedStock: false });
      })
      .catch(() => {
        this.setState({ isClickedConcudeBlockedStock: false });
      });
  };

  changeConclusionBlockedStock = () => {
    const { conclusionType, supplier, account } = this.state;
    const payload = {
      conclusionType: conclusionType.key,
    };
    if (this.isConcludeTypeRefund()) {
      payload.supplier = supplier.id;
      payload.accountCode = account.code;
    }
    const { id, onChangeConclusionBlockedStock } = this.props;
    onChangeConclusionBlockedStock(id, payload, true)
      .then(() => {
        this.setState({ isClickedChangeConclusionBlockedStock: false });
      })
      .catch(() => {
        this.setState({ isClickedChangeConclusionBlockedStock: false });
      });
  };

  updateReason = () => {
    const { reason, subReason } = this.state;
    const payload = {};
    if (reason && reason.id) {
      payload.reasonId = reason.id;
    } else {
      return;
    }
    if (subReason && subReason.id) {
      payload.subReasonId = subReason.id;
    }
    const { id, onUpdateBlockedStockReason } = this.props;
    onUpdateBlockedStockReason(id, payload)
      .then(() => {
        this.setState({ isClickedUpdateBlockedStockReasonButton: false });
      })
      .catch(() => {
        this.setState({ isClickedUpdateBlockedStockReasonButton: false });
      });
  };

  PageDescription = (props) => {
    const { detail } = this.props;
    if (!detail) {
      return null;
    }

    return (
      <Descriptions column={1}>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.detail.description.id',
          )}
        >
          <CopyToClipboard message={detail.id} />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.detail.description.status',
          )}
        >
          {getTranslation.blockedStockStatus(detail.status)}
        </Descriptions.Item>
        {this.isBlockedStockConcluded() ? (
          <Descriptions.Item
            label={this.props.t(
              'pages.Operation.BlockedStock.detail.description.conclusionType',
            )}
          >
            {getTranslation.blockedStockConclusionType(detail.conclusionType)}
          </Descriptions.Item>
        ) : (
          undefined
        )}
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.detail.description.quantity',
          )}
        >
          {detail.count}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.detail.description.damagedPicUrl',
          )}
        >
          <Button
            size="small"
            disabled={!detail.damagedPicUrl}
            onClick={() => {
              this.setState({ isPictureModalOpen: true });
            }}
          >
            {this.props.t('pages.Operation.BlockedStock.detail.button.detail')}
          </Button>
          <Modal
            title={this.props.t(
              'pages.Operation.BlockedStock.detail.description.image',
            )}
            visible={this.state.isPictureModalOpen}
            onOk={() => {
              this.setState({ isPictureModalOpen: false });
            }}
            onCancel={() => {
              this.setState({ isPictureModalOpen: false });
            }}
          >
            <img
              src={detail.damagedPicUrl}
              style={{ maxWidth: '100%', maxHeight: '100%', margin: 'auto' }}
              alt="damagedPic"
            />
          </Modal>
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.detail.description.dead',
          )}
        >
          {detail.dead || '-'}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.detail.description.expireDate',
          )}
        >
          {detail.expiryDate
            ? moment(new Date(detail.expiryDate))
                .local()
                .format('DD.MM.YYYY HH:mm')
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.detail.description.dateToBeWithdrawnFromSale',
          )}
        >
          {detail.expiryDate && detail.dead
            ? moment(
                new Date(detail.expiryDate).setHours(23, 0, 0, 0) -
                  detail.dead * 24 * 60 * 60 * 1000,
              )
                .local()
                .format('DD.MM.YYYY HH:mm')
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.detail.description.reason',
          )}
        >
          {detail.reason.values}
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.detail.description.subReason',
          )}
        >
          {(detail.subReason && detail.subReason.values) || '-'}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.detail.description.warehouse',
          )}
        >
          {detail.warehouse.name}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.detail.description.createdAt',
          )}
        >
          {moment(new Date(detail.createdAt))
            .local()
            .format(getDateTimeFormatString())}
        </Descriptions.Item>
        {detail && detail.supplier && detail.supplier.name ? (
          <Descriptions.Item
            label={this.props.t(
              'pages.Operation.BlockedStock.detail.description.supplier',
            )}
          >
            {detail.supplier.name}
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.detail.description.fromLocationBarcode',
          )}
        >
          <CopyToClipboard color="blue" message={detail.fromLocationBarcode} />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.detail.description.toLocationBarcode',
          )}
        >
          <CopyToClipboard
            color="geekblue"
            message={detail.toLocationBarcode}
          />
        </Descriptions.Item>
      </Descriptions>
    );
  };

  ProductTable = (props) => {
    return (
      <Table
        size="small"
        columns={this.prepareColumns()}
        dataSource={this.prepareData()}
        rowKey={(record) => {
          return record.id;
        }}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  prepareColumns = (props) => {
    return [
      {
        title: this.props.t('pages.Operation.BlockedStock.detail.table.image'),
        dataIndex: 'picURL',
        key: 'picURL',
        width: 72,
        render: (picURL) => {
          return (
            <Avatar className="product-image" shape="square" src={picURL} />
          );
        },
      },
      {
        title: this.props.t('pages.Operation.BlockedStock.detail.table.name'),
        dataIndex: 'fullName',
        key: 'fullName',
      },
    ];
  };

  prepareData = (props) => {
    const { detail } = this.props;
    if (detail) {
      return [detail.product];
    }
    return [];
  };

  isBlockedStockCreated = () => {
    const { detail } = this.props;
    return detail && detail.status === constants.BLOCKED_STOCK_STATUSES.CREATED;
  };

  isBlockedStockForDamagedProductFromCentralWarehouse = () => {
    const { detail } = this.props;
    return (
      detail &&
      detail.reason &&
      detail.reason.type ===
        constants.BLOCKED_STOCK_REASON_OPERATION_TYPES.INCOMING_DAMAGED
    );
  };

  isBlockedStockConcluded = () => {
    const { detail } = this.props;
    console.log(
      detail && detail.status === constants.BLOCKED_STOCK_STATUSES.CONCLUDED,
    );
    return (
      detail &&
      (detail.status === constants.BLOCKED_STOCK_STATUSES.CONCLUDED ||
        detail.status === constants.BLOCKED_STOCK_STATUSES.TO_BE_TRANSFERRED)
    );
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.blockedStock.blockedStockDetail.loading,
  detail: state.blockedStock.blockedStockDetail.data,
  reasonList: state.blockedStock.reasonList.data,
  supplierList: state.supplier.list.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetDetail: (id) => {
    return dispatch(blockedStockAction.getBlockedStockDetail(id));
  },
  onUpdateBlockedStockReason: (id, data) => {
    return dispatch(blockedStockAction.updateBlockedStockReason(id, data));
  },
  onConcludeBlockedStock: (id, data, refresh) => {
    return dispatch(blockedStockAction.concludeBlockedStock(id, data, refresh));
  },
  onChangeConclusionBlockedStock: (id, data, refresh) => {
    return dispatch(
      blockedStockAction.changeConclusionBlockedStock(id, data, refresh),
    );
  },
  onGetReasonList: () => {
    return dispatch(
      blockedStockAction.filterBlockedStockReasons({
        isVisibleByWarehouse: true,
      }),
    );
  },
  onCancelBlockedStock: (id) => {
    return dispatch(blockedStockAction.cancelBlockedStock(id));
  },
  onGetSupplierList: () => {
    return dispatch(supplierAction.getSupplierList());
  },
});

const BlockedStockDetailConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(BlockedStockDetail));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { BlockedStockDetailConnected as BlockedStockDetail };
