import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  loading: false,
  error: null,
};

const switcher = {
  [actionType.executeProductionModule_InProgress]: executeProductionModule_InProgress,
  [actionType.executeProductionModule_Succeeded]: executeProductionModule_Succeeded,
  [actionType.executeProductionModule_Failed]: executeProductionModule_Failed,
};

export const productionModuleReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function executeProductionModule_InProgress(state, payload) {
  return Object.assign({}, state, {
    loading: true,
    error: null,
  });
}

function executeProductionModule_Succeeded(state, payload) {
  return Object.assign({}, state, {
    loading: false,
    error: null,
  });
}

function executeProductionModule_Failed(state, payload) {
  return Object.assign({}, state, {
    loading: false,
    error: payload,
  });
}
