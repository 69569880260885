/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Form, InputNumber } from 'antd';
/*----------------------------------------------------------------------------*/
import { ENTER_KEY_CODE } from './../../shared';
/*----------------------------------------------------------------------------*/

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => {
  return (
    <EditableContext.Provider value={form}>
      <tr {...props} />
    </EditableContext.Provider>
  );
};

export const EditableFormRow = Form.create()(EditableRow);

export class EditableCell extends Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (event) => {
    const { record, handleCellSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error) {
        for (const key in values) {
          if (values.hasOwnProperty(key)) {
            if (values[key] === null) {
              values[key] = 0;
            }
          }
        }
      }
      this.toggleEdit();
      handleCellSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const {
      children, dataIndex, record, title, min, disabled,
    } = this.props;
    const { editing } = this.state;
    if (editing && !disabled) {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <InputNumber
              ref={(node) => (this.input = node)}
              min={min}
              type="number"
              // eslint-disable-next-line no-restricted-globals
              parser={(value) => (isNaN(value) ? 0 : value.replace(value, parseInt(value, 10)))}
              onKeyDown={(event) => {
                if (event.keyCode === ENTER_KEY_CODE) {
                  this.input.blur();
                }
              }}
              onBlur={this.save}
            />,
          )}
        </Form.Item>
      );
    }
    return (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleCellSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}
