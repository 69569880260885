import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';
import { history } from './../history';

export const outgoingTransferAction = {
  getOutgoingTransferList,
  getOutgoingTransferDetail,
  setOutgoingTransferContinue,
  setOutgoingTransferShip,
  addOutgoingTransferPallet,
  createSelfOutgoingTransferProductList,
  createSelfOutgoingTransferWithWaybills,
  createSelfOutgoingCaseAndPalletTransferWithWaybills,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getOutgoingTransferList(query) {
  return (dispatch) => {
    dispatch({ type: actionType.getOutgoingTransferList_InProgress });
    http
      .getOutgoingTransferList(query)
      .then((response) => {
        dispatch({
          type: actionType.getOutgoingTransferList_Succeeded,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getOutgoingTransferList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getOutgoingTransferList_Failed,
          payload: error,
        });
      });
  };
}

function getOutgoingTransferDetail(data) {
  return (dispatch) => {
    dispatch({ type: actionType.getOutgoingTransferDetail_InProgress });
    http
      .getOutgoingTransferDetail(data)
      .then((response) => {
        dispatch({
          type: actionType.getOutgoingTransferDetail_Succeeded,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.getOutgoingTransferDetail_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getOutgoingTransferDetail_Failed,
          payload: error,
        });
      });
  };
}

function setOutgoingTransferContinue(id) {
  return (dispatch) => {
    dispatch({ type: actionType.setOutgoingTransferContinue_InProgress });
    http
      .setOutgoingTransferContinue(id)
      .then((response) => {
        dispatch({
          type: actionType.setOutgoingTransferContinue_Succeeded,
          payload: response.data.data,
        });
        dispatch(getOutgoingTransferDetail(id));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.setOutgoingTransferContinue_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.setOutgoingTransferContinue_Failed,
          payload: error,
        });
      });
  };
}

function setOutgoingTransferShip(id, waybills, errorMessage) {
  return (dispatch) => {
    dispatch({ type: actionType.setOutgoingTransferShip_InProgress });
    return uploadWaybill(waybills, errorMessage)
      .then((uploadedWaybills) => {
        return http.setOutgoingTransferShip(id, uploadedWaybills);
      })
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.setOutgoingTransferShip_Succeeded,
          ),
        });
        dispatch({
          type: actionType.setOutgoingTransferShip_Succeeded,
          payload: response.data.data,
        });
        dispatch(getOutgoingTransferDetail(id));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.setOutgoingTransferShip_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.setOutgoingTransferShip_Failed,
          payload: error,
        });
        dispatch(getOutgoingTransferDetail(id));
        throw error;
      });
  };
}

function uploadWaybill(waybills, errorMessage) {
  const waybillPromises = waybills.map(
    (waybill) =>
      new Promise((resolve, reject) => {
        http
          .uploadWaybill({
            file: {
              name: waybill.imageFile.name,
              type: waybill.imageFile.type,
              size: waybill.imageFile.size,
            },
            folderName: 'waybill/',
          })
          .catch((error) => {
            notification.error({
              duration: 4,
              message: ErrorInfo.message(
                actionType.setOutgoingTransferShip_Failed,
              ),
              description: errorMessage,
            });
            throw error;
          })
          .then((response) => {
            return http
              .uploadToS3(
                response.data.data.uploadUrls.signedUrl,
                waybill.imageFile.originFileObj,
              )
              .then(() => resolve(response.data));
          });
      }),
  );
  return Promise.all(waybillPromises).then((values) => {
    const formattedWaybills = waybills.map((waybill, i) => ({
      ss: waybill.ss,
      date: waybill.date,
      image: values[i].data.uploadUrls.imageUrl,
    }));
    return formattedWaybills;
  });
}

function addOutgoingTransferPallet(transferId, palletBarcode) {
  return (dispatch) => {
    dispatch({ type: actionType.addOutgoingTransferPallet_InProgress });
    http
      .addOutgoingTransferPallet(transferId, {
        barcode: palletBarcode,
      })
      .then((response) => {
        dispatch({
          type: actionType.addOutgoingTransferPallet_Succeeded,
          payload: response.data.data,
        });
        dispatch(getOutgoingTransferDetail(transferId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.addOutgoingTransferPallet_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.addOutgoingTransferPallet_Failed,
          payload: error,
        });
      });
  };
}

function createSelfOutgoingTransferProductList(query) {
  return (dispatch) => {
    dispatch({
      type: actionType.createSelfOutgoingTransferProductList_InProgress,
    });
    http
      .createSelfOutgoingTransferProductList(query)
      .then((response) => {
        dispatch({
          type: actionType.createSelfOutgoingTransferProductList_Succeeded,
          payload: response.data.data.products,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.createSelfOutgoingTransferProductList_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.createSelfOutgoingTransferProductList_Failed,
          payload: error,
        });
      });
  };
}

function createSelfOutgoingTransferWithWaybills({
  items,
  waybills,
  palletBarcodes,
  errorMessage,
  toWarehouse,
}) {
  return () => {
    const waybillPromises = waybills.map(
      (waybill) =>
        new Promise((resolve, reject) => {
          http
            .uploadWaybill({
              file: {
                name: waybill.imageFile.name,
                type: waybill.imageFile.type,
                size: waybill.imageFile.size,
              },
              folderName: 'waybill/',
            })
            .catch((error) => {
              notification.error({
                duration: 4,
                message: ErrorInfo.message(
                  actionType.createSelfOutgoingTransferProductList_Failed,
                ),
                description: errorMessage,
              });
              const CWId = localStorage.getItem('warehouseId');
              history.push(`/r/${CWId}/stock/transfer/outgoing/create`);
              throw error;
            })
            .then((response) => {
              http
                .uploadToS3(
                  response.data.data.uploadUrls.signedUrl,
                  waybill.imageFile.originFileObj,
                )
                .then(() => resolve(response.data));
            });
        }),
    );

    let formattedWaybills = [];
    Promise.all(waybillPromises).then((values) => {
      formattedWaybills = waybills.map((waybill, i) => ({
        ss: waybill.ss,
        date: waybill.date,
        image: values[i].data.uploadUrls.imageUrl,
      }));
      http
        .createEmptyCarboyTransfer({
          items,
          waybills: formattedWaybills,
          palletBarcodes,
          toWarehouse,
        })
        .then(() => {
          notification.success({
            duration: 4,
            message: ErrorInfo.message(
              actionType.createEmptyCarboyTransfer_Succeeded,
            ),
          });
          const CWId = localStorage.getItem('warehouseId');
          history.push(`/r/${CWId}/stock/outgoing/transfer/list`);
        })
        .catch((error) => {
          notification.error({
            duration: 4,
            message: ErrorInfo.message(
              actionType.createEmptyCarboyTransfer_Failed,
            ),
            description: ErrorInfo.description(error),
          });
          const CWId = localStorage.getItem('warehouseId');
          history.push(`/r/${CWId}/outgoing/transfer/list`);
        });
    });
  };
}

function createSelfOutgoingCaseAndPalletTransferWithWaybills({
  items,
  waybills,
  palletBarcodes,
  errorMessage,
}) {
  return () => {
    const waybillPromises = waybills.map(
      (waybill) =>
        new Promise((resolve, reject) => {
          http
            .uploadWaybill({
              file: {
                name: waybill.imageFile.name,
                type: waybill.imageFile.type,
                size: waybill.imageFile.size,
              },
              folderName: 'waybill/',
            })
            .catch((error) => {
              notification.error({
                duration: 4,
                message: ErrorInfo.message(
                  actionType.createSelfOutgoingTransferProductList_Failed,
                ),
                description: errorMessage,
              });
              const CWId = localStorage.getItem('warehouseId');
              history.push(
                `/r/${CWId}/stock/transfer/outgoing/create-case-and-pallet-transfer`,
              );
              throw error;
            })
            .then((response) => {
              http
                .uploadToS3(
                  response.data.data.uploadUrls.signedUrl,
                  waybill.imageFile.originFileObj,
                )
                .then(() => resolve(response.data));
            });
        }),
    );

    let formattedWaybills = [];
    Promise.all(waybillPromises).then((values) => {
      formattedWaybills = waybills.map((waybill, i) => ({
        ss: waybill.ss,
        date: waybill.date,
        image: values[i].data.uploadUrls.imageUrl,
      }));
      http
        .createCaseAndPalletTransfer({
          items,
          waybills: formattedWaybills,
          palletBarcodes,
        })
        .then(() => {
          notification.success({
            duration: 4,
            message: ErrorInfo.message(
              actionType.createCaseAndPalletTransfer_Succeeded,
            ),
          });
          const CWId = localStorage.getItem('warehouseId');
          history.push(`/r/${CWId}/stock/outgoing/transfer/list`);
        })
        .catch((error) => {
          notification.error({
            duration: 4,
            message: ErrorInfo.message(
              actionType.createCaseAndPalletTransfer_Failed,
            ),
            description: ErrorInfo.description(error),
          });
          const CWId = localStorage.getItem('warehouseId');
          history.push(`/r/${CWId}/outgoing/transfer/list`);
        });
    });
  };
}
