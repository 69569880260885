import React, { Component } from 'react';
import { Select, Avatar, Spin, Button } from 'antd';
import { uniqBy } from 'lodash';
/*----------------------------------------------------------------------------*/
import './ProductControl.scss';
import { getTranslation } from './../../../../../../shared';
import i18n from './../../../../../../i18n';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class ProductControl extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => {
    return {
      filterInput: null,
      selectedItem: null,
    };
  };

  render() {
    const { selectedItem } = this.state;
    return (
      <div className="ProductControl">
        <this.SelectField />
        <Button onClick={this.add} disabled={!selectedItem}>
          {i18n.t(
            'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.control.add',
          )}
        </Button>
      </div>
    );
  }

  SelectField = () => {
    const { disabled, loading, placeholder, list } = this.props;
    return (
      <Select
        style={{ width: 350 }}
        showSearch
        allowClear
        optionFilterProp="children"
        disabled={disabled || !list}
        value={this.getValue()}
        filterOption={this.filterOption}
        placeholder={placeholder}
        onChange={this.onChange}
        onSearch={this.onSearch}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {this.getOptions()}
      </Select>
    );
  };

  getOptions = () => {
    const { list } = this.props;
    const { filterInput } = this.state;
    if (!list || !list.length) {
      return null;
    }
    let options = uniqBy(list, `fullName.${getTranslation.local()}`).filter(
      (item) => {
        return item;
      },
    );

    if (!filterInput) {
      options = options.slice(0, Math.min(10, options.length));
    } else {
      options = options.filter((item) => {
        return (
          filterInput &&
          filterInput.length >= 3 &&
          (getTranslation
            .obj(item.fullName)
            .toLowerCase()
            .includes(filterInput.toLowerCase()) ||
            item.id.includes(filterInput) ||
            (item.barcodes &&
              item.barcodes.length &&
              item.barcodes.includes(filterInput)))
        );
      });
    }

    return options.map((item) => {
      return (
        <Select.Option
          value={getTranslation.obj(item.fullName)}
          key={item.id}
          rawvalue={item}
        >
          <this.SelectOptionItem item={item} />
        </Select.Option>
      );
    });
  };

  SelectOptionItem = (props) => {
    const { item } = props;
    const settings = {
      label: getTranslation.obj(item.fullName),
      icon: {
        src: (item.picURL && item.picURL[getTranslation.local()]) || null,
        size: 20,
        className: 'product-icon',
      },
    };
    return (
      <span rawvalue={item}>
        <Avatar {...settings.icon} /> {settings.label}
      </span>
    );
  };

  getValue = () => {
    const { selectedItem } = this.state;
    if (!selectedItem) {
      return;
    }
    return getTranslation.obj(selectedItem.fullName);
  };

  filterOption = (input, option) => {
    return (
      option.props.rawvalue.fullName[getTranslation.local()]
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0 ||
      option.props.rawvalue.id.toString().indexOf(input.toString()) >= 0 ||
      (option.props.rawvalue.barcodes &&
        option.props.rawvalue.barcodes.length &&
        option.props.rawvalue.barcodes.includes(input.toString()))
    );
  };

  onChange = (value, option) => {
    let selectedItem = null;
    try {
      selectedItem = option.props.rawvalue;
    } catch (error) {
      selectedItem = null;
    }
    this.setState({ selectedItem });
  };

  onSearch = (value, option) => {
    this.setState({
      filterInput: value,
    });
  };

  add = () => {
    const { selectedItem } = this.state;
    this.props.onAdd(selectedItem);
    this.setState(this.stateFactory());
  };

  clearState = () => {
    this.setState(this.stateFactory());
  };
}

const ProductControlTranslated = ProductControl;

export { ProductControlTranslated as ProductControl };
