import React, { Component } from 'react';
import {
  Form, Button, Row, Col,
} from 'antd';
/*----------------------------------------------------------------------------*/
import './ProductList.scss';
import { withNamespaces } from 'react-i18next';
import { ProductMultiSelect } from '../filterFields';

/*------------------------------------------------------------------------------
------------------------------------------------------------------------------*/

class ProductListFilter extends Component {
  settings = {
    main: {
      className: 'ProductList_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => ({
    ...this.emptyTableFilter(),
  });

  emptyTableFilter = () => ({
    products: [],
  });

  render() {
    return (
      <div {...this.settings.main}>
        <this.TableFilterForm />
      </div>
    );
  }

  TableFilterForm = (props) => (
    <div>
      <Form {...this.formItemLayout}>
        <Row>
          <Col span={12} style={{ padding: '0px 5px' }}>
            <this.ProductField />
          </Col>
          <Col span={2} style={{ padding: '0px 5px' }}>
            <this.FilterButton />
          </Col>
          {/* <Col span={2} style={{ padding: '0px 5px' }}>
              <this.ClearFilterButton />
            </Col> */}
        </Row>
      </Form>
    </div>
  );

  ProductField = () => {
    const { productList } = this.props;

    const list = (productList && productList.data) || null;

    return (
      <Form.Item>
        <ProductMultiSelect
          placeholder={this.props.t('pages.Stock.Product.list.pageForm.products')}
          list={list}
          onChange={(products) => {
            this.setState({ products });
          }}
          value={this.state.products}
          loading={productList.loading}
        />
      </Form.Item>
    );
  };

  ClearFilterButton = () => (
    <Form.Item>
      <Button
        block
        disabled={this.state.products.length === 0}
        onClick={() => {
          this.clearFilter();
        }}
      >
        {this.props.t('pages.Stock.Product.list.button.clean')}
      </Button>
    </Form.Item>
  );


  FilterButton = () => (
    <Form.Item>
      <Button
        type="primary"
        block
        disabled={this.state.products.length === 0}
        onClick={() => {
          const filter = this.getTableFilter();
          this.props.onChange(filter);
        }}
      >
        {this.props.t('pages.Stock.Product.list.button.filter')}
      </Button>
    </Form.Item>
  );

  getTableFilter = () => {
    const { products } = this.state;
    const filter = {};

    if (products && products.length) {
      filter.productIds = products.map((p) => p.id);
    }

    return filter;
  };

  clearFilter = () => {
    this.setState({ ...this.state, ...this.stateFactory() }, () => {
      this.props.onClear(this.getTableFilter());
    });
  };
}

const ProdcutListFilterTranslated = withNamespaces('translation')(ProductListFilter);

export { ProdcutListFilterTranslated as ProductListFilter };
