import { createHttpService } from '../createHttpService';

const WAREHOUSE_ID = localStorage.getItem('warehouseId');

const httpService = createHttpService();

export const picker = {
  getPickerList: (warehouseId) =>
    httpService.get(`/warehouse/${warehouseId || WAREHOUSE_ID}/pickers`),
  getPickerBusyReasons: (p, q) => httpService.get('/picker/busy-reasons'),
  changeStatusOfPickerToBusy: (p, q, b) =>
    httpService.post(`/picker/${p.pickerId}/busy`, b),
  changeStatusOfPickerToFree: (p, q, b) =>
    httpService.post(`/picker/${p.pickerId}/free`, b),
  getPickerDetail: (pickerId) => httpService.get(`/picker/${pickerId}`),
  getPickerFilteredList: (body) => httpService.post(`/picker/filter`, body),
  getPickerJobDetail: (pickerId) =>
    httpService.get(`/picker/${pickerId}/current-job`),
};
