import { createHttpService } from '../createHttpService';
import { QueryBuilder } from '../QueryBuilder';

const httpService = createHttpService();

export const transfer = {
  getTransferDetail: (transferId) => httpService.get(`/transfers/${transferId}/populated`),
  createTransfer: (data) => httpService.post('/transfers', data),
  getTransferList: (query, filter) => httpService.post(`/transfers/filter?${QueryBuilder.getQueryStr(query)}`, filter),
  addOutgoingTransferPallet: (transferId, qr) => httpService.post(`/transfers/${transferId}/pallet-barcode`, qr),
  setOutgoingTransferContinue: (transferId) => httpService.post(`/transfers/${transferId}/continue-prepare`),
  approveTransfer: (transferId) => httpService.post(`/transfers/${transferId}/approve`),
  updateTransferItems: (transferId, items) => httpService.put(`/transfers/${transferId}/items`, { items }),
  cancelTransfer: (transferId) => httpService.post(`/transfers/${transferId}/cancel`),
  deleteTransfer: (transferId) => httpService.post(`/transfers/${transferId}/delete`),
  shipDirect: ({ transferId, ...data }) => httpService.post(`/transfers/${transferId}/ship/direct`, data),
  addOutgoingTransferPalletBarcode: (data) => httpService.post("/transfers/pallet-barcode", { ...data }),
  bulkSendToWmsTransfer: (transferIds) => httpService.post("/transfers/publish/bulk", { transferIds }),
  addNoteToTransfer: (transferId, note) => httpService.post(`/transfers/${transferId}/add-note`, { note }),
  createEquipmentTransfer: (transfer) => httpService.post("/transfers/equipment", { transfer }),
  changeTransferVisibility: (transferId, isVisibilityEnabled) => httpService.post(`/transfers/${transferId}/change-visibility`, { isVisibilityEnabled }),
};
