import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Table, Card, Button, Tag } from 'antd';
import { get } from 'lodash';
import uniqid from 'uniqid';
import { Link } from 'react-router-dom';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './StockMovementLogList.scss';
import { StockMovementLogFilter } from './StockMovementLogFilter';
/*----------------------------------------------------------------------------*/
import {
  Spinner,
  ExportToExcel,
  CopyToClipboard,
} from './../../../../components';
import { stockMovementLogAction } from './../../../../actions';
import {
  tableHelper,
  statuses,
  getTranslation,
  constants,
  smlDescriptions,
  getDateTimeFormatString,
  getDateFormatString,
} from './../../../../shared';
import { pageLoadEvents } from '../../../../services/events';
import { pageView } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------
------------------------------------------------------------------------------*/

class StockMovementLogList extends Component {
  settings = {
    main: {
      className: 'StockMovementLogList_main',
    },
    card: {
      title: this.props.t('pages.Operation.StockMovementLog.list.title'),
      className: 'no-padding-card',
      extra: '',
      size: 'small',
    },
  };

  dateFormat = getDateFormatString();

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_MOVEMENT_LOG_LIST });
    this.props.onResetStockMovementList();
  }

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    return {
      startDate: new Date(today.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      filter: this.getDefaultFilter(),
    };
  };

  getDefaultFilter = () => {
    return null;
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.PageExtra />}>
          <StockMovementLogFilter
            defaultDateRange={this.defaultDateRange}
            onChange={this.setTableFilter}
            onClear={this.clearTableFilter}
          />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  PageExtra = (props) => {
    return (
      <ExportToExcel
        action={this.props.onExportToXlsxStockMovementLogList}
        query={{ limit: this.props.totalCount, offset: 0 }}
        getFilter={this.getFilter}
        fileName="stockMovementLogList"
        dataFormatter={this.prepareForExport}
        loading={this.props.isExporting}
        disabled={false}
      />
    );
  };

  PageTable = (props) => {
    return (
      <div>
        <Table
          locale={{ emptyText: this.calculateEmptyText() }}
          size="small"
          dataSource={this.prepareData(this.props)}
          columns={this.prepareColumns(this.props)}
          expandedRowRender={this.expandedRowRender}
          rowKey={(record) => {
            return record.id;
          }}
          pagination={{
            ...this.state.pagination,
            total: this.props.totalCount,
          }}
          loading={{ indicator: <Spinner />, spinning: this.props.loading }}
          onChange={this.handleTableChange}
        />
      </div>
    );
  };

  calculateEmptyText = () => {
    if (this.state.filter) {
      return this.props.t('pages.Operation.StockMovementLog.list.noData');
    }
    return this.props.t('pages.Operation.StockMovementLog.list.noFilter');
  };

  expandedRowRender = (record, index, indent, expanded) => (
    <Table
      size="small"
      columns={this.prepareExtendedColumns(record)}
      dataSource={this.prepareExtendedData(record)}
      components={this.components}
      rowKey={(record) => record.id + uniqid()}
      pagination={false}
    />
  );

  prepareExtendedData = (record) => {
    return [{ ...record.productStock, id: 0 }];
  };

  prepareExtendedColumns = (record) => {
    const columns = [
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.acceptance',
        ),
        dataIndex: 'acceptance',
        width: 180,
        render: (acceptance) => <Tag color="blue">{acceptance}</Tag>,
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.available',
        ),
        dataIndex: 'available',
        width: 180,
        render: (available) => <Tag color="geekblue">{available}</Tag>,
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.cancel',
        ),
        dataIndex: 'cancel',
        width: 180,
        render: (cancel) => <Tag color="magenta">{cancel}</Tag>,
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.dead',
        ),
        dataIndex: 'dead',
        width: 180,
        render: (dead) => <Tag color="red">{dead}</Tag>,
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.frozen',
        ),
        dataIndex: 'frozen',
        width: 180,
        render: (frozen) => <Tag color="blue">{frozen}</Tag>,
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.lock',
        ),
        dataIndex: 'lock',
        width: 180,
        render: (lock) => <Tag color="purple">{lock}</Tag>,
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.nonagreement',
        ),
        dataIndex: 'nonagreement',
        width: 180,
        render: (nonagreement) => <Tag color="cyan">{nonagreement}</Tag>,
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.block',
        ),
        dataIndex: 'block',
        width: 180,
        render: (block) => <Tag color="geekblue">{block}</Tag>,
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.reserve',
        ),
        dataIndex: 'reserve',
        width: 180,
        render: (reserve) => <Tag color="volcano">{reserve}</Tag>,
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.reservedForTransfer',
        ),
        dataIndex: 'reservedForTransfer',
        width: 180,
        render: (reservedForTransfer) => (
          <Tag color="purple">{reservedForTransfer}</Tag>
        ),
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.total',
        ),
        dataIndex: 'total',
        width: 180,
        render: (total) => <Tag color="geekblue">{total}</Tag>,
      },
    ];

    return columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          min: 0,
          disabled: this.props.disabled,
          handleCellSave: this.onProductCellChange,
        }),
      };
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState((state) => {
      return {
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      };
    }, this.getList);
  };

  prepareColumns = (props) => {
    return [
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.table.warehouse',
        ),
        dataIndex: 'warehouse',
        key: 'warehouse',
        render: (warehouse) => {
          return <CopyToClipboard message={warehouse.name} />;
        },
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.table.picker',
        ),
        dataIndex: 'picker',
        key: 'picker',
        ...tableHelper.fieldSorter.getStringSortProps('picker'),
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.table.fromProductLocation',
        ),
        dataIndex: 'fromProductLocation',
        key: 'fromProductLocation',
        ...tableHelper.fieldSorter.getStringSortProps('fromProductLocation'),
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.table.toProductLocation',
        ),
        dataIndex: 'toProductLocation',
        key: 'toProductLocation',
        ...tableHelper.fieldSorter.getStringSortProps('toProductLocation'),
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.table.product',
        ),
        dataIndex: 'product',
        key: 'product',
        render: (product) => {
          return <CopyToClipboard message={product.fullName} />;
        },
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.table.quantity',
        ),
        dataIndex: 'quantity',
        key: 'quantity',
        ...tableHelper.fieldSorter.getNumberSortProps('quantity'),
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.table.expiry',
        ),
        dataIndex: 'expiry',
        key: 'expiry',
        ...tableHelper.fieldSorter.getDateSortProps('expiry'),
        render: (expiry) => {
          return moment(expiry).isValid()
            ? moment(new Date(expiry))
                .local()
                .format(this.dateFormat)
            : '-';
        },
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.table.logger',
        ),
        dataIndex: 'logger',
        key: 'logger',
        render: (logger) =>
          logger !== '-' ? <CopyToClipboard message={logger} /> : logger,
        ...tableHelper.fieldSorter.getStringSortProps('logger'),
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.table.loggerModel',
        ),
        dataIndex: 'loggerModel',
        key: 'loggerModel',
        render: (loggerModel, record) => {
          return this.LoggerModelLink(loggerModel, record);
        },
        ...tableHelper.fieldSorter.getStringSortProps('loggerModel'),
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.table.description',
        ),
        dataIndex: 'description',
        key: 'description',
        render: (description) => {
          return Object.keys(smlDescriptions).includes(description)
            ? getTranslation.descriptionType(description)
            : description;
        },
        ...tableHelper.fieldSorter.getStringSortProps('description'),
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.list.table.createdAt',
        ),
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 150,
        ...tableHelper.fieldSorter.getDateSortProps('createdAt'),
        render: (createdAt) => {
          return moment(createdAt).isValid()
            ? moment(new Date(createdAt))
                .local()
                .format(getDateTimeFormatString())
            : '-';
        },
      },
      {
        key: 'detail',
        dataIndex: 'id',
        render: (id) => <this.DetailButton id={id} />,
      },
    ];
  };

  LoggerModelLink = (loggerModel, record) => {
    const {
      Transfer,
      BlockedStock,
      WarehousePurchaseOrder,
      InventoryCheck,
    } = constants.LOGGER_MODEL_TYPE;
    let url = null;
    if (loggerModel === Transfer) {
      url = `/stock-operation/transfer/detail/${record.logger}`;
    }
    if (loggerModel === BlockedStock) {
      url = `/stock-operation/blocked-stock/detail/${record.logger}`;
    }
    if (loggerModel === WarehousePurchaseOrder) {
      url = `/stock-operation/warehouse-purchase-order/detail/${record.logger}`;
    }
    if (loggerModel === InventoryCheck) {
      url = `/stock-operation/inventory-check/detail/${record.logger}`;
    }
    if (!url) {
      return getTranslation.loggerModelType(loggerModel);
    }
    return <Link to={url}>{getTranslation.loggerModelType(loggerModel)}</Link>;
  };

  DetailButton = (props) => {
    const url = `/stock-operation/movement-log/detail/${props.id}`;
    return (
      <Link to={url}>
        <Button size="small">
          {this.props.t('pages.Operation.StockMovementLog.list.detail')}
        </Button>
      </Link>
    );
  };

  prepareData = (props) => {
    const { list } = props;
    if (!list) {
      return null;
    }
    return list.map((movementLog) => {
      return {
        id: movementLog.id,
        warehouse: movementLog.warehouse || '-',
        picker: (movementLog.picker && movementLog.picker.name) || '-',
        logger: movementLog.logger || '-',
        loggerModel: movementLog.loggerModel || '-',
        fromProductLocation:
          (movementLog.fromProductLocation &&
            movementLog.fromProductLocation.warehouseLocation &&
            movementLog.fromProductLocation.warehouseLocation.barcode) ||
          '-',
        toProductLocation:
          (movementLog.toProductLocation &&
            movementLog.toProductLocation.warehouseLocation &&
            movementLog.toProductLocation.warehouseLocation.barcode) ||
          '-',
        product: movementLog.product || '-',
        quantity: movementLog.quantity || '-',
        expiry: movementLog.expiry || '-',
        description: movementLog.description || '-',
        createdAt: movementLog.createdAt || '-',
        productStock: movementLog.productStock || {},
      };
    });
  };

  prepareForExport = (rawData) => {
    const data = this.prepareData({ list: rawData });
    if (!data || !data.length) {
      return null;
    }
    return data.map((item) => {
      return {
        [this.props.t(
          'pages.Operation.StockMovementLog.list.table.warehouse',
        )]: get(item, 'warehouse.name', '-'),
        [this.props.t(
          'pages.Operation.StockMovementLog.list.table.picker',
        )]: item.picker,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.table.fromProductLocation',
        )]: item.fromProductLocation,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.table.toProductLocation',
        )]: item.toProductLocation,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.table.product',
        )]: get(item, 'product.fullName', '-'),
        [this.props.t(
          'pages.Operation.StockMovementLog.list.table.quantity',
        )]: item.quantity,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.table.expiry',
        )]: moment(item.expiry).isValid()
          ? moment(new Date(item.expiry))
              .local()
              .format(getDateFormatString())
          : '-',
        [this.props.t(
          'pages.Operation.StockMovementLog.list.table.logger',
        )]: item.logger,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.table.loggerModel',
        )]: Object.keys(statuses.loggerModelType).includes(item.loggerModel)
          ? getTranslation.loggerModelType(item.loggerModel)
          : item.loggerModel,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.table.description',
        )]: Object.keys(smlDescriptions).includes(item.description)
          ? getTranslation.descriptionType(item.description)
          : item.description,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.table.createdAt',
        )]: moment(item.createdAt).isValid()
          ? moment(new Date(item.createdAt))
              .local()
              .format(getDateTimeFormatString())
          : '-',

        [this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.acceptance',
        )]: item.productStock.acceptance || 0,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.available',
        )]: item.productStock.available || 0,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.cancel',
        )]: item.productStock.cancel || 0,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.dead',
        )]: item.productStock.dead || 0,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.frozen',
        )]: item.productStock.frozen || 0,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.lock',
        )]: item.productStock.lock || 0,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.nonagreement',
        )]: item.productStock.nonagreement || 0,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.reserve',
        )]: item.productStock.reserve || 0,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.reservedForTransfer',
        )]: item.productStock.reservedForTransfer || 0,
        [this.props.t(
          'pages.Operation.StockMovementLog.list.productStock.total',
        )]: item.productStock.total || 0,
      };
    });
  };

  getList = () => {
    const filter = this.getFilter();
    const query = this.getQuery();
    this.props.onGetStockMovementLogList(query, filter);
  };

  getFilter = () => {
    const filter = this.state.filter || {};

    filter.startDate = filter.startDate || this.defaultDateRange.startDate;
    filter.endDate = filter.endDate || this.defaultDateRange.endDate;

    return filter;
  };

  getQuery = () => {
    const { query } = this.state;
    return query;
  };

  setTableFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      };
    }, this.getList);
  };

  clearTableFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      };
    }, this.getList);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => {
  return {
    CWId: state.auth.currentWarehouse._id,
    loading: state.stockMovementLog.list.loading,
    list: state.stockMovementLog.list.data,
    totalCount: state.stockMovementLog.list.total,
    isExporting: state.stockMovementLog.isExporting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetStockMovementLogList: (query, filter) =>
    dispatch(stockMovementLogAction.getStockMovementLogList(query, filter)),
  onExportToXlsxStockMovementLogList: (query, filter, fileName, formatter) =>
    dispatch(
      stockMovementLogAction.exportToXlsxStockMovementLogList(
        query,
        filter,
        fileName,
        formatter,
      ),
    ),
  onResetStockMovementList: () => {
    return dispatch(stockMovementLogAction.resetStockMovementLogList());
  },
});

const StockMovementLogListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(StockMovementLogList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { StockMovementLogListConnected as StockMovementLogList };
