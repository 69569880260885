import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Table, Button, Card, Avatar, Icon, Modal } from 'antd';
import moment from 'moment';
import QRCode from 'qrcode-react';
/*----------------------------------------------------------------------------*/
import './CustomerSatisfactionTab.scss';
import { CustomerSatisfactionTabFilter } from './CustomerSatisfactionTabFilter';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard } from './../../../../../../components';
import { blockedStockAction } from './../../../../../../actions';
import { getTranslation, getDateTimeFormatString } from './../../../../../../shared';
import { pageView } from '../../../../../../services/segment';
import { pageLoadEvents } from '../../../../../../services/events';


/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class CustomerSatisfactionTab extends Component {
  settings = {
    main: {
      className: 'CustomerSatisfactionTab_main',
    },
    card: {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.title',
      ),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.STOCK_RFS_CUSTOMER_SATISFACTION });
    this.getList();
  }

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      filter: this.getDefaultFilter(),
      showRequestQrModal: false,
      selectedRequest: null,
    };
  };

  getDefaultFilter = () => null;

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.BlockedStockRequestQrModal />
          <this.PageFilter />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => <div />;

  PageFilter = () => (
    <CustomerSatisfactionTabFilter
      defaultDateRange={this.defaultDateRange}
      onChange={this.setTableFilter}
      onClear={this.clearTableFilter}
    />
  );

  PageTable = (props) => (
    <div>
      <Table
        size="small"
        defaultExpandAllRows
        dataSource={this.prepareData(this.props)}
        expandedRowRender={this.expandedRowRender}
        columns={this.prepareColumns(this.props)}
        rowKey={(record) => record.id}
        pagination={{
          ...this.state.pagination,
          total: this.props.totalCount,
        }}
        loading={{ indicator: <Spinner />, spinning: this.props.loading }}
        onChange={this.handleTableChange}
      />
    </div>
  );

  expandedRowRender = (record, index, indent, expanded) => (
    <Table
      size="small"
      columns={this.prepareExtendedColumns(record)}
      dataSource={this.prepareExtendedData(record)}
      rowKey={(record) => record.productId}
      pagination={false}
    />
  );

  prepareExtendedColumns = (record) => [
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.subTable.image',
      ),
      dataIndex: 'product.picURL',
      key: 'picURL',
      render: (picURL) => (
        <Avatar
          className="grow"
          shape="square"
          src={getTranslation.obj(picURL)}
        />
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.subTable.productId',
      ),
      dataIndex: 'productId',
      key: 'productId',
      render: (productId) => <CopyToClipboard message={productId} />,
    },
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.subTable.fullName',
      ),
      dataIndex: 'product.fullName',
      key: 'fullName',
      render: (fullName) =>
        fullName && <CopyToClipboard message={getTranslation.obj(fullName)} />,
    },
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.subTable.count',
      ),
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.subTable.isUsed',
      ),
      dataIndex: 'isUsed',
      key: 'isUsed',
      render: (isUsed) =>
        isUsed ? <Icon type="check" /> : <Icon type="close" />,
    },
  ];

  prepareExtendedData = (record) => {
    if (!record.products || !record.products.length) {
      return [];
    }
    return record.products;
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      (state) => ({
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      }),
      this.getList,
    );
  };

  prepareData = (props) => {
    const { list } = this.props;
    if (!list || !list.length) {
      return [];
    }
    return list;
  };

  prepareColumns = (props) => [
    {
      dataIndex: 'id',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.table.id',
      ),
      render: (id) => (
        <>
          <CopyToClipboard message={getTranslation.obj(id)} />
        </>
      ),
    },
    {
      dataIndex: 'status',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.table.status',
      ),
      render: (status) => getTranslation.blockedStockRequestStatus(status),
    },
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.table.createdBy',
      ),
      dataIndex: 'createdBy',
      render: (createdBy) => (
        <>
          <CopyToClipboard message={getTranslation.obj(createdBy)} />
        </>
      ),
    },
    {
      dataIndex: 'warehouse',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.table.warehouse',
      ),
      render: (warehouse) => (
        <>
          <CopyToClipboard message={getTranslation.obj(warehouse.name)} />
        </>
      ),
    },
    {
      dataIndex: 'createdAt',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.table.createdAt',
      ),
      render: (date) =>
        date
          ? moment(new Date(date))
              .local()
              .format(getDateTimeFormatString())
          : '-',
    },
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.table.qr',
      ),
      dataIndex: 'id',
      key: 'qr',
      render: (id) => (
        <Button
          onClick={() => {
            this.setState((state) => ({
              ...state,
              showRequestQrModal: true,
              selectedRequest: id,
            }));
          }}
        >
          {this.props.t(
            'pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.button.showQr',
          )}
        </Button>
      ),
    },
  ];

  BlockedStockRequestQrModal = () => (
    <Modal
      title={this.props.t(
        'pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.modal.requestQr',
      )}
      visible={this.state.showRequestQrModal}
      centered
      onOk={() => {
        this.setState({ showRequestQrModal: false, selectedRequest: null });
      }}
      onCancel={() => {
        this.setState({ showRequestQrModal: false, selectedRequest: null });
      }}
      footer={[]}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <QRCode value={this.getQrStr()} />
      </div>
    </Modal>
  );

  getQrStr = () => {
    const { selectedRequest } = this.state;
    const qrStr = JSON.stringify({ blockedStockUniqueCode: selectedRequest });
    return qrStr;
  };

  getList = () => {
    const query = this.getQuery();
    const filter = this.getFilter();

    if (
      filter &&
      filter.createdAt &&
      filter.createdAt.startDate &&
      filter.createdAt.endDate
    ) {
      this.props.onFilterBlockedStockRequest(query, filter);
    }
  };

  getQuery = () => this.state.query;

  getFilter = () => {
    const filter = this.state.filter || {};

    if (!filter.createdAt) {
      filter.createdAt = this.defaultDateRange;
    }

    filter.warehouses = [this.props.CWId];

    return filter;
  };

  setTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      }),
      this.getList,
    );
  };

  clearTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      }),
      this.getList,
    );
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.blockedStock.blockedStockRequestList.loading,
  list: state.blockedStock.blockedStockRequestList.data,
  totalCount: state.blockedStock.blockedStockRequestList.total,
});

const mapDispatchToProps = (dispatch) => ({
  onFilterBlockedStockRequest: (query, filter) => {
    dispatch(blockedStockAction.filterBlockedStockRequest(query, filter));
  },
});

const CustomerSatisfactionTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(CustomerSatisfactionTab));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { CustomerSatisfactionTabConnected as CustomerSatisfactionTab };
