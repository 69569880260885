/* eslint-disable consistent-return */
import React, { Component } from 'react';
import { Select, Spin, Input } from 'antd';

import { withNamespaces } from 'react-i18next';
import { constants, getTranslation } from './../../shared';
import './ReasonSelect.scss';

const { TextArea } = Input;

class ReasonSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.reason !== this.state.reason) {
      this.handleReasonChange();
    }

    if (prevState.description !== this.state.description) {
      this.handleDescriptionChange();
    }
  }

  stateFactory = () => {
    return {
      reason: null,
      description: '',
    };
  };

  getOptions = () => {
    const reasons = constants.INVENTORY_CHECK_REASONS;
    const reasonsList = Object.values(reasons).map((value) => ({
      type: value,
      value: getTranslation.inventoryCheckReason(value),
    }));
    if (!reasonsList || !reasonsList.length) {
      return null;
    }
    const options = reasonsList.map((item) => {
      return (
        <Select.Option
          className="reason-select"
          value={item.type}
          key={item.type}
          rawvalue={item}
        >
          {item.value}
        </Select.Option>
      );
    });
    return options;
  };

  getValue = () => {
    const { reason } = this.state;
    if (!reason) {
      return;
    }

    return reason.value;
  };

  handleReasonChange = () => {
    this.setState(
      {
        description: '',
      },
      () => {
        this.props.onChange({
          type: this.state.reason.key,
          description: this.state.description || undefined,
        });
      },
    );
  };

  handleDescriptionChange = () => {
    this.props.onChange({
      type: this.state.reason.key,
      description: this.state.description || undefined,
    });
  };

  filterOption = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  onChange = (value, option) => {
    this.setState({
      reason: { value, key: Number(option.key) },
    });
  };

  render() {
    const { disabled, loading } = this.props;
    return (
      <div>
        <Select
          placeholder={this.props.t('common.reason')}
          showSearch
          optionFilterProp="children"
          disabled={disabled}
          value={this.getValue()}
          filterOption={this.filterOption}
          onChange={this.onChange}
          notFoundContent={loading && <Spin size="small" />}
        >
          {this.getOptions()}
        </Select>

        {this.state.reason &&
          this.state.reason.key === constants.INVENTORY_CHECK_REASONS.OTHER && (
          <TextArea
            placeholder={this.props.t(
              'components.reasonSelect.otherPlaceholder',
            )}
            onChange={(event) => {
              this.setState({
                description: event.target.value,
              });
            }}
          />
        )}
      </div>
    );
  }
}
const ReasonSelectTranslated = withNamespaces('translation')(ReasonSelect);

export { ReasonSelectTranslated as ReasonSelect };
