import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: true,
    data: null,
    error: null,
  },
  detail: {
    loading: true,
    data: null,
    error: null,
  },
};

const switcher = {
  [actionType.getSupplierList_InProgress]: getSupplierList_InProgress,
  [actionType.getSupplierList_Succeeded]: getSupplierList_Succeeded,
  [actionType.getSupplierList_Failed]: getSupplierList_Failed,

  [actionType.getSupplierDetail_InProgress]: getSupplierDetail_InProgress,
  [actionType.getSupplierDetail_Succeeded]: getSupplierDetail_Succeeded,
  [actionType.getSupplierDetail_Failed]: getSupplierDetail_Failed,
};

export const supplierReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getSupplierList_InProgress(state) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getSupplierList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload.suppliers,
      error: null,
    },
  });
}

function getSupplierList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getSupplierDetail_InProgress(state) {
  return Object.assign({}, state, {
    detail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getSupplierDetail_Succeeded(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getSupplierDetail_Failed(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/
