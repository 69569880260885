import React from 'react';
// --------------------------------------------------
import { withNamespaces } from 'react-i18next';
import { PickerCard } from './PickerCard';
// --------------------------------------------------
// --------------------------------------------------

const PickerList = ({ pickers, selectedFilters }) => {
  if (!pickers) {
    return null;
  }
  const list = pickers
    .sort((c1, c2) => c2.status - c1.status)
    .map((item) => {
      if (!selectedFilters || !selectedFilters.length) {
        return <PickerCard picker={item} key={item._id} />;
      }
      if (selectedFilters.includes(item.status.toString())) {
        return <PickerCard picker={item} key={item._id} />;
      }
      return null;
    });

  return <div className="PickerList_inner">{list}</div>;
};

const PickerListTranslated = withNamespaces('translation')(PickerList);

export { PickerListTranslated as PickerList };
