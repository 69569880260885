import { Component } from 'react';

class CheckIfStartOfHour extends Component {
  constructor(props) {
    super(props);
    this.checkIfStartOfHour = setInterval(this.checkIfStartOfHour, 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.checkIfStartOfHour);
  }

  checkIfStartOfHour = () => {
    const action = this.props.onHourStart || (() => {});
    const date = new Date();

    if (date.getMinutes() === 0) {
      action();
    }
  }

  render() {
    return null;
  }
}

export default CheckIfStartOfHour;
