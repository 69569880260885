import React, { Component } from 'react';
import { Nav } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { AppSidebarToggler } from '@coreui/react';
import { DefaultHeaderDropdown } from './DefaultHeaderDropdown';
import { HeaderToggle } from './HeaderToggle';

class defaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <h4
          style={{
            marginBottom: 0,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {this.props.warehouseName}
        </h4>
        <Nav className="ml-auto pr-3" navbar>
          <HeaderToggle />
          <DefaultHeaderDropdown account />
        </Nav>
      </div>
    );
  }
}

const defaultHeaderWithRouter = withRouter(defaultHeader);

export { defaultHeaderWithRouter as DefaultHeader };
