import React, { Component } from 'react';
// --------------------------------------------------
import '../BalloonContent.scss';
import { withNamespaces } from 'react-i18next';
// --------------------------------------------------
import {
  statuses,
  fromNow,
  getOrderLastStatusChangeDate,
} from './../../../shared';
// --------------------------------------------------

class OrderBalloonContent extends Component {
  getLastStatusDuration = () => {
    const { order } = this.props;
    let lastStatusChangeDate;
    if (order.lastStatusChangeDate) {
      lastStatusChangeDate = order.lastStatusChangeDate;
    } else {
      lastStatusChangeDate = getOrderLastStatusChangeDate({ order });
    }

    return fromNow({ time: lastStatusChangeDate, shouldAddSecond: true });
  };

  render() {
    const { order } = this.props;
    const { orderStatus } = statuses;
    return (
      <>
        <div>
          <div>
            <strong>
              {this.props.t('components.BalloonContent.clientName')}
            </strong>
            {': '}
            {order.clientName}
          </div>
          <div>
            <strong>{this.props.t('components.BalloonContent.status')}</strong>
            {': '}
            {this.props.t(orderStatus[order.status])}
            {` (${this.getLastStatusDuration()})`}
          </div>
          <div>
            <strong>
              {this.props.t('components.BalloonContent.totalTime')}
            </strong>
            {': '}
            {fromNow({ time: order.checkoutDate, shouldAddSecond: true })}
          </div>
        </div>
      </>
    );
  }
}

const OrderBalloonContentTranslated = withNamespaces('translation')(
  OrderBalloonContent,
);

export { OrderBalloonContentTranslated as OrderBalloonContent };
