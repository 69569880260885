import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Row, Col } from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import {
  WarehouseMultiSelect,
  DateRangeSelect,
  PoStatusMultiSelect,
  SupplierMultiSelect,
  TagMultiSelect,
  CityMultiSelect,
} from './filterFields';
/*----------------------------------------------------------------------------*/
import {
  warehouseAction,
  supplierAction,
  cityAction,
} from './../../../../actions';
import { track } from '../../../../services/segment';
import { WarehousePurchaseOrderListEvents } from '../../../../services/events';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class WarehousePurchaseOrderFilter extends Component {
  settings = {
    main: {
      className: 'WarehousePurchaseOrderListFilter_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    const { onGetWarehouseList, onGetSupplierList, onGetCityList } = this.props;
    onGetWarehouseList();
    onGetSupplierList();
    onGetCityList();
  }

  stateFactory = () => ({
    ...this.emptyTableFilter(),
  });

  emptyTableFilter = () => {
    return {
      startDate: this.props.defaultDateRange.startDate,
      endDate: this.props.defaultDateRange.endDate,
      suppliers: [],
      warehouses: [],
      warehousePurchaseOrdersNo: [],
      statuses: [],
      waybillsNo: [],
      cities: [],
    };
  };

  render() {
    return (
      <div {...this.settings.main}>
        <this.TableFilterForm />
      </div>
    );
  }

  TableFilterForm = (props) => (
    <div>
      <Form>
        <Row>
          <Col span={4} style={{ padding: '0px 5px' }}>
            <this.DateRangeField />
          </Col>
          <Col span={4} style={{ padding: '0px 5px' }}>
            <this.SupplierField />
          </Col>
          <Col span={4} style={{ padding: '0px 5px' }}>
            <this.WarehouseField />
          </Col>
          <Col span={4} style={{ padding: '0px 5px' }}>
            <this.PoStatusSelect />
          </Col>
          <Col span={4} style={{ padding: '0px 5px' }}>
            <this.WpoNumberField />
          </Col>
          <Col span={4} style={{ padding: '0px 5px' }}>
            <this.WaybillNumberField />
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ padding: '0px 5px' }}>
            <this.CityField />
          </Col>
        </Row>
        <Row>
          <Col span={2} style={{ padding: '0px 5px' }}>
            <this.ClearFilterButton />
          </Col>
          <Col offset={20} span={2} style={{ padding: '0px 5px' }}>
            <this.FilterButton />
          </Col>
        </Row>
      </Form>
    </div>
  );

  DateRangeField = () => (
    <Form.Item>
      <DateRangeSelect
        defaultDateRange={this.props.defaultDateRange}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        onChange={(dateRange) => {
          this.setState({
            ...this.state,
            ...dateRange,
          });
          track(WarehousePurchaseOrderListEvents.WPO_LIST_DATE_SELECTED);
        }}
      />
    </Form.Item>
  );

  WarehouseField = () => {
    const { warehouseList } = this.props;
    const { warehouses } = this.state;
    const list = (warehouseList && warehouseList.data) || null;
    return (
      <Form.Item>
        <WarehouseMultiSelect
          list={list}
          onChange={(warehouses) => {
            this.setState({
              warehouses,
            });
          }}
          value={warehouses}
          loading={warehouseList.loading}
          placeholder={this.props.t(
            'pages.Operation.PurchaseOrder.wpoList.filter.warehouse',
          )}
        />
      </Form.Item>
    );
  };

  PoStatusSelect = () => {
    const { statuses } = this.state;
    return (
      <Form.Item>
        <PoStatusMultiSelect
          onChange={(statuses) => {
            this.setState({
              statuses,
            });
          }}
          placeholder={this.props.t(
            'pages.Operation.PurchaseOrder.wpoList.filter.status',
          )}
          value={statuses}
        />
      </Form.Item>
    );
  };

  SupplierField = (props) => {
    const { supplierList } = this.props;
    const { suppliers } = this.state;
    return (
      <Form.Item>
        <SupplierMultiSelect
          value={suppliers}
          onChange={(suppliers) => {
            this.setState({
              suppliers,
            });
          }}
          placeholder={this.props.t(
            'pages.Operation.PurchaseOrder.wpoList.filter.supplier',
          )}
          list={supplierList}
        />
      </Form.Item>
    );
  };

  CityField = (props) => {
    const { cityList } = this.props;
    const { cities } = this.state;
    return (
      <Form.Item>
        <CityMultiSelect
          value={cities}
          onChange={(cities) => {
            this.setState({
              cities,
            });
          }}
          placeholder={this.props.t(
            'pages.Operation.PurchaseOrder.wpoList.filter.city',
          )}
          list={cityList}
        />
      </Form.Item>
    );
  };

  WpoNumberField = (props) => {
    const { warehousePurchaseOrdersNo } = this.state;
    return (
      <Form.Item>
        <TagMultiSelect
          placeholder={this.props.t(
            'pages.Operation.PurchaseOrder.wpoList.filter.warehousePurchaseOrdersNo',
          )}
          disabled={false}
          onChange={(warehousePurchaseOrdersNo) => {
            if (warehousePurchaseOrdersNo[0] === '') {
              this.setState({
                warehousePurchaseOrdersNo: [],
              });
              return;
            }

            this.setState({
              warehousePurchaseOrdersNo,
            });
          }}
          value={warehousePurchaseOrdersNo}
        />
      </Form.Item>
    );
  };

  WaybillNumberField = (props) => {
    const { waybillsNo } = this.state;
    return (
      <Form.Item>
        <TagMultiSelect
          placeholder={this.props.t(
            'pages.Operation.PurchaseOrder.wpoList.filter.waybillsNo',
          )}
          disabled={false}
          onChange={(waybillsNo) => {
            if (waybillsNo[0] === '') {
              this.setState({
                waybillsNo: [],
              });
              return;
            }

            this.setState({
              waybillsNo,
            });
          }}
          value={waybillsNo}
        />
      </Form.Item>
    );
  };

  FilterButton = () => {
    return (
      <Form.Item>
        <Button
          type="primary"
          block
          onClick={() => {
            const filter = this.getTableFilter();
            this.filterChanged(filter);
            track(WarehousePurchaseOrderListEvents.WPO_LIST_FILTER_APPLY_CLICKED);
          }}
        >
          {this.props.t('pages.Operation.PurchaseOrder.wpoList.filter.filter')}
        </Button>
      </Form.Item>
    );
  };

  ClearFilterButton = () => {
    return (
      <Form.Item>
        <Button
          block
          onClick={() => {
            this.clearFilter();
            track(WarehousePurchaseOrderListEvents.WPO_LIST_CLEAR_FILTER_CLICKED);
          }}
        >
          {this.props.t('pages.Operation.PurchaseOrder.wpoList.filter.clean')}
        </Button>
      </Form.Item>
    );
  };

  getTableFilter = () => {
    const {
      suppliers,
      warehouses,
      statuses,
      warehousePurchaseOrdersNo,
      waybillsNo,
      startDate,
      endDate,
      cities,
    } = this.state;

    const filter = {};

    filter.createdAt = {
      startDate: startDate || this.props.defaultDateRange.startDate,
      endDate: endDate || this.props.defaultDateRange.endDate,
    };

    if (warehouses && warehouses.length) {
      filter.warehouses = warehouses.map((warehouse) => warehouse.id);
    }
    if (statuses && statuses.length) {
      filter.statuses = statuses.map((statuse) => statuse.key);
    }
    if (suppliers && suppliers.length) {
      filter.suppliers = suppliers.map((supplier) => supplier._id);
    }
    if (cities && cities.length) {
      filter.cities = cities.map((city) => city._id);
    }
    if (warehousePurchaseOrdersNo && warehousePurchaseOrdersNo.length) {
      filter.warehousePurchaseOrdersNo = warehousePurchaseOrdersNo;
    }
    if (waybillsNo && waybillsNo.length) {
      filter.waybillsNo = waybillsNo;
    }

    return filter;
  };

  filterChanged = (filter) => {
    this.props.onChange(filter);
  };

  clearFilter = () => {
    this.setState({ ...this.state, ...this.stateFactory() }, () => {
      this.props.onClear(this.getTableFilter());
    });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse.id,
  warehouseList: state.warehouse.warehouseList,
  productList: state.product.productList,
  reasonList: state.blockedStock.reasonList,
  supplierList: state.supplier.list.data,
  cityList: state.city.list.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetWarehouseList: () => {
    return dispatch(warehouseAction.getWarehouseList());
  },
  onGetSupplierList: () => {
    return dispatch(supplierAction.getSupplierList());
  },
  onGetCityList: () => {
    return dispatch(cityAction.filterCityList());
  },
});

const WarehousePurchaseOrderFilterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(WarehousePurchaseOrderFilter));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { WarehousePurchaseOrderFilterConnected as WarehousePurchaseOrderFilter };
