import React, { Component } from 'react';
import { Select, Spin } from 'antd';
/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

export class LocationMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => {
    return {};
  };

  render() {
    return <this.SelectField />;
  }

  SelectField = () => {
    const { disabled, loading, placeholder } = this.props;
    return (
      <Select
        mode="multiple"
        placeholder={placeholder}
        showSearch
        allowClear
        optionFilterProp="children"
        disabled={disabled}
        value={this.getValue()}
        filterOption={this.filterOption}
        onChange={this.onChange}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {this.getOptions()}
      </Select>
    );
  };

  getOptions = () => {
    const { list } = this.props;
    if (!list || !list.length) {
      return null;
    }
    const options = list.filter(i => i.operationType).map((item) => {
      return (
        <Select.Option value={item.barcode} key={item.id} rawvalue={item}>
          {item.barcode}
        </Select.Option>
      );
    });
    return options;
  };

  getValue = () => {
    const { value } = this.props;
    if (!value || !value.length) {
      return;
    }
    return value
      .map((item) => {
        return item.barcode;
      });
  };

  filterOption = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  onChange = (value, options) => {
    let selectedItems = [];
    try {
      selectedItems = options.map((o) => o.props.rawvalue);
    } catch (error) {
      selectedItems = [];
    }
    this.props.onChange(selectedItems);
  };
}
