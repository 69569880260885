import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  inventoryCheckCreate: {
    created: false,
    data: null,
  },
  inventoryCheckList: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
  inventoryCheckDetail: {
    loading: true,
    data: null,
    error: null,
  },
  inventoryCheckTaskDetail: {
    loading: true,
    data: null,
    error: null,
  },
  isExporting: false,
  operation: {
    loading: true,
    data: null,
    error: null,
  },
  warehouseStatusControl: {
    loading: false,
    data: null,
    error: null,
  },
};

const switcher = {
  [actionType.getInventoryCheckList_InProgress]: getInventoryCheckList_InProgress,
  [actionType.getInventoryCheckList_Succeeded]: getInventoryCheckList_Succeeded,
  [actionType.getInventoryCheckList_Failed]: getInventoryCheckList_Failed,
  //
  [actionType.getInventoryCheckDetail_InProgress]: getInventoryCheckDetail_InProgress,
  [actionType.getInventoryCheckDetail_Succeeded]: getInventoryCheckDetail_Succeeded,
  [actionType.getInventoryCheckDetail_Failed]: getInventoryCheckDetail_Failed,
  //
  [actionType.createInventoryCheck_InProgress]: createInventoryCheck_InProgress,
  [actionType.createInventoryCheck_Succeeded]: createInventoryCheck_Succeeded,
  [actionType.createInventoryCheck_Failed]: createInventoryCheck_Failed,
  //
  [actionType.getInventoryCheckTaskDetail_InProgress]: getInventoryCheckTaskDetail_InProgress,
  [actionType.getInventoryCheckTaskDetail_Succeeded]: getInventoryCheckTaskDetail_Succeeded,
  [actionType.getInventoryCheckTaskDetail_Failed]: getInventoryCheckTaskDetail_Failed,
  //
  [actionType.bulkApproveInventoryCheck_InProgress]: bulkApproveInventoryCheck_InProgress,
  [actionType.bulkApproveInventoryCheck_Succeeded]: bulkApproveInventoryCheck_Succeeded,
  [actionType.bulkApproveInventoryCheck_Failed]: bulkApproveInventoryCheck_Failed,
  //
  [actionType.bulkTerminateInventoryCheck_InProgress]: bulkTerminateInventoryCheck_InProgress,
  [actionType.bulkTerminateInventoryCheck_Succeeded]: bulkTerminateInventoryCheck_Succeeded,
  [actionType.bulkTerminateInventoryCheck_Failed]: bulkTerminateInventoryCheck_Failed,
  //
  [actionType.bulkIncludeInventoryChecksToSTS_InProgress]: bulkIncludeInventoryChecksToSTS_InProgress,
  [actionType.bulkIncludeInventoryChecksToSTS_Succeeded]: bulkIncludeInventoryChecksToSTS_Succeeded,
  [actionType.bulkIncludeInventoryChecksToSTS_Failed]: bulkIncludeInventoryChecksToSTS_Failed,
  //
  [actionType.bulkRemoveInventoryChecksFromSTS_InProgress]: bulkRemoveInventoryChecksFromSTS_InProgress,
  [actionType.bulkRemoveInventoryChecksFromSTS_Succeeded]: bulkRemoveInventoryChecksFromSTS_Succeeded,
  [actionType.bulkRemoveInventoryChecksFromSTS_Failed]: bulkRemoveInventoryChecksFromSTS_Failed,
  //
  [actionType.exportToXlsx_InProgress]: exportToXlsx_InProgress,
  [actionType.exportToXlsx_Succeeded]: exportToXlsx_Succeeded,
  [actionType.exportToXlsx_Failed]: exportToXlsx_Failed,

  [actionType.getWarehouseStatusControl_InProgress]: getWarehouseStatusControl_InProgress,
  [actionType.getWarehouseStatusControl_Succeeded]: getWarehouseStatusControl_Succeeded,
  [actionType.getWarehouseStatusControl_Failed]: getWarehouseStatusControl_Failed,
};

export const inventoryReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/
function getInventoryCheckList_InProgress(state, payload) {
  return Object.assign({}, state, {
    inventoryCheckList: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function getInventoryCheckList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    inventoryCheckList: {
      loading: false,
      data: payload.data,
      error: null,
      total: payload.total,
    },
  });
}

function getInventoryCheckList_Failed(state, payload) {
  return Object.assign({}, state, {
    inventoryCheckList: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getInventoryCheckDetail_InProgress(state, payload) {
  return Object.assign({}, state, {
    inventoryCheckDetail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getInventoryCheckDetail_Succeeded(state, payload) {
  return Object.assign({}, state, {
    inventoryCheckDetail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getInventoryCheckDetail_Failed(state, payload) {
  return Object.assign({}, state, {
    inventoryCheckDetail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/
function createInventoryCheck_InProgress(state, payload) {
  return Object.assign({}, state, {
    inventoryCheckCreate: {
      created: false,
      data: null,
    },
  });
}

function createInventoryCheck_Succeeded(state, payload) {
  return Object.assign({}, state, {
    inventoryCheckCreate: {
      created: false,
      data: null,
    },
  });
}

function createInventoryCheck_Failed(state, payload) {
  return Object.assign({}, state, {
    inventoryCheckCreate: {
      created: false,
      data: null,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getInventoryCheckTaskDetail_InProgress(state, payload) {
  return Object.assign({}, state, {
    inventoryCheckTaskDetail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getInventoryCheckTaskDetail_Succeeded(state, payload) {
  return Object.assign({}, state, {
    inventoryCheckTaskDetail: {
      loading: false,
      data: payload.task,
      error: null,
    },
  });
}

function getInventoryCheckTaskDetail_Failed(state, payload) {
  return Object.assign({}, state, {
    inventoryCheckTaskDetail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function exportToXlsx_InProgress(state, payload) {
  return Object.assign({}, state, {
    isExporting: true,
  });
}

function exportToXlsx_Succeeded(state, payload) {
  return Object.assign({}, state, {
    isExporting: false,
  });
}

function exportToXlsx_Failed(state, payload) {
  return Object.assign({}, state, {
    isExporting: false,
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function bulkApproveInventoryCheck_InProgress(state, payload) {
  return Object.assign({}, state, {
    operation: {
      created: false,
      data: null,
      loading: true,
    },
  });
}

function bulkApproveInventoryCheck_Succeeded(state, payload) {
  return Object.assign({}, state, {
    operation: {
      created: true,
      data: payload.data.data,
      loading: false,
    },
  });
}

function bulkApproveInventoryCheck_Failed(state, payload) {
  return Object.assign({}, state, {
    operation: {
      created: false,
      data: null,
      loading: false,
    },
  });
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function bulkTerminateInventoryCheck_InProgress(state, payload) {
  return Object.assign({}, state, {
    operation: {
      created: false,
      data: null,
      loading: true,
    },
  });
}

function bulkTerminateInventoryCheck_Succeeded(state, payload) {
  return Object.assign({}, state, {
    operation: {
      created: true,
      data: payload.data.data,
      loading: false,
    },
  });
}

function bulkTerminateInventoryCheck_Failed(state, payload) {
  return Object.assign({}, state, {
    operation: {
      created: false,
      data: null,
      loading: false,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function bulkIncludeInventoryChecksToSTS_InProgress(state, payload) {
  return Object.assign({}, state, {
    operation: {
      created: false,
      data: null,
      loading: true,
    },
  });
}

function bulkIncludeInventoryChecksToSTS_Succeeded(state, payload) {
  return Object.assign({}, state, {
    operation: {
      created: true,
      data: payload.data.data,
      loading: false,
    },
  });
}

function bulkIncludeInventoryChecksToSTS_Failed(state, payload) {
  return Object.assign({}, state, {
    operation: {
      created: false,
      data: null,
      loading: false,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function bulkRemoveInventoryChecksFromSTS_InProgress(state, payload) {
  return Object.assign({}, state, {
    operation: {
      created: false,
      data: null,
      loading: true,
    },
  });
}

function bulkRemoveInventoryChecksFromSTS_Succeeded(state, payload) {
  return Object.assign({}, state, {
    operation: {
      created: true,
      data: payload.data.data,
      loading: false,
    },
  });
}

function bulkRemoveInventoryChecksFromSTS_Failed(state, payload) {
  return Object.assign({}, state, {
    operation: {
      created: false,
      data: null,
      loading: false,
    },
  });
}

function getWarehouseStatusControl_InProgress(state, payload) {
  return Object.assign({}, state, {
    warehouseStatusControl: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getWarehouseStatusControl_Succeeded(state, payload) {
  return Object.assign({}, state, {
    warehouseStatusControl: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getWarehouseStatusControl_Failed(state, payload) {
  return Object.assign({}, state, {
    warehouseStatusControl: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}
