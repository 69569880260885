import React, { Component } from 'react';
import { Tabs } from 'antd';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
/*----------------------------------------------------------------------------*/
import './Stock.scss';
/*----------------------------------------------------------------------------*/
import { history } from './../../../history';
import { IncomingTransferList } from './../IncomingTransfer';
import { OutgoingTransferList } from './../OutgoingTransfer';
import { PurchaseOrderList } from './../PurchaseOrder';
import { permission, canAccess } from './../../../guard';
/*----------------------------------------------------------------------------*/

const { TabPane } = Tabs;

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class Stock extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => ({});

  onTabChange = (key, props) => {
    if (key === 'purchaseOrder') {
      history.push(`/r/${props.currentWarehouseId}/stock/purchase-order/list`);
    }
    if (key === 'incomingTransfer') {
      history.push(`/r/${props.currentWarehouseId}/stock/transfer/list`);
    }
    if (key === 'outgoingTransfer') {
      history.push(
        `/r/${props.currentWarehouseId}/stock/transfer/outgoing/list`,
      );
    }
  };

  render() {
    return <this.PageTabs />;
  }

  PageTabs = () => {
    const outgoingTransferFlag = canAccess(
      permission.outgoingTransferList,
      this.props.permissions,
    );
    const tabs = [];
    tabs.push(
      <TabPane
        key="incomingTransfer"
        tab={this.props.t('pages.Stock.Stock.tab.incomingTransferList')}
      >
        <this.IncomingTransferTab />
      </TabPane>,
    );
    if (outgoingTransferFlag) {
      tabs.push(
        <TabPane
          key="outgoingTransfer"
          tab={this.props.t('pages.Stock.Stock.tab.outgoingTransferList')}
        >
          <this.OutgoingTransferTab />
        </TabPane>,
      );
    }
    tabs.push(
      <TabPane key="purchaseOrder" tab={this.props.t('pages.Stock.Stock.tab.purchaseOrder')}>
        <this.PurchaseOrderTab />
      </TabPane>,
    );
    return (
      <Tabs
        defaultActiveKey={this.props.activeTab}
        onChange={(key) => this.onTabChange(key, this.props)}
      >
        {tabs}
      </Tabs>
    );
  };

  IncomingTransferTab = () => <IncomingTransferList />;

  OutgoingTransferTab = () => <OutgoingTransferList />;

  PurchaseOrderTab = () => <PurchaseOrderList />;
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  currentWarehouseId: state.auth.currentWarehouse._id,
  permissions: state.auth.user.permissions,
});

const mapDispatchToProps = (dispatch) => ({});

const StockConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(Stock));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { StockConnected as Stock };
