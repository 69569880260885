import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import ReactJson from 'react-json-view';
/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/
export class JsonButton extends Component {
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div>
        <Button onClick={this.showModal} block={this.props.block}>
          JSON
        </Button>
        <Modal
          title="JSON"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={900}
          footer={[]}
        >
          <ReactJson
            src={this.props.data}
            collapsed={1}
            enableClipboard={1}
            displayDataTypes={0}
            displayObjectSize={0}
            name={null}
          />
        </Modal>
      </div>
    );
  }
}
