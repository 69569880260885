import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';

export const countryAction = {
  filterCountryList,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function filterCountryList() {
  return (dispatch) => {
    dispatch({ type: actionType.filterCountryList_InProgress });
    http
      .filterCountryList()
      .then((response) => {
        const { data } = response.data;
        const newCountries = data.countries.filter(
          (country) => country.code.alpha2 !== 'XJ',
        );
        dispatch({
          type: actionType.filterCountryList_Succeeded,
          payload: { ...data, countries: newCountries },
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.filterCountryList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.filterCountryList_Failed,
          payload: error,
        });
      });
  };
}
