import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Row, Col } from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import {
  WarehouseMultiSelect,
  ProductMultiSelect,
  TransferTypeMultiSelect,
  DateRangeSelect,
} from '../list/filterFields';
/*----------------------------------------------------------------------------*/
import { warehouseAction, productAction } from '../../../../actions';
/*----------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class TransferBulkCancelListFilter extends Component {
  settings = {
    main: {
      className: 'TransferBulkCancelFilter_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    const { onGetWarehouseList, onGetProductList } = this.props;
    onGetWarehouseList();
    onGetProductList();
  }

  stateFactory = () => ({
    ...this.emptyTableFilter(),
  });

  emptyTableFilter = () => {
    return {
      startDate: this.props.defaultDateRange.startDate,
      endDate: this.props.defaultDateRange.endDate,
      fromWarehouses: [],
      toWarehouses: [],
      products: [],
      integrationTypes: [],
    };
  };

  render() {
    return (
      <div {...this.settings.main}>
        <this.TableFilterForm />
      </div>
    );
  }

  TableFilterForm = (props) => (
    <Form>
      <Row>
        <Col span={5} style={{ padding: '0px 5px' }}>
          <this.DateRangeField />
        </Col>
        <Col span={7} style={{ padding: '0px 5px' }}>
          <this.FromWarehouseField />
        </Col>
        <Col span={7} style={{ padding: '0px 5px' }}>
          <this.ToWarehouseField />
        </Col>
        <Col span={5} style={{ padding: '0px 5px' }}>
          <this.TransferTypeSelect />
        </Col>
      </Row>

      <Row>
        <Col span={2} style={{ padding: '0px 5px' }}>
          <this.ClearFilterButton />
        </Col>
        <Col offset={20} span={2} style={{ padding: '0px 5px' }}>
          <this.FilterButton />
        </Col>
      </Row>
    </Form>
  );

  DateRangeField = () => (
    <Form.Item>
      <DateRangeSelect
        defaultDateRange={this.props.defaultDateRange}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        onChange={(dateRange) => {
          this.setState({
            ...this.state,
            ...dateRange,
          });
        }}
      />
    </Form.Item>
  );

  FromWarehouseField = () => {
    const { warehouseList, isUserAdmin, currentWarehouse } = this.props;
    const { fromWarehouses } = this.state;
    const warehouseItemList = (warehouseList && warehouseList.data) || null;

    let list = [];
    if (warehouseItemList) {
      list = [...list, ...warehouseItemList];
    }

    return (
      <Form.Item>
        <WarehouseMultiSelect
          list={isUserAdmin ? list : [currentWarehouse]}
          onChange={(fromWarehouses) => {
            this.setState({
              fromWarehouses,
            });
          }}
          value={fromWarehouses}
          loading={warehouseList.loading}
          placeholder={this.props.t(
            'pages.Operation.Transfer.bulkCancel.filter.fromWarehouse',
          )}
        />
      </Form.Item>
    );
  };

  ToWarehouseField = () => {
    const { warehouseList, isUserAdmin, currentWarehouse } = this.props;
    const { toWarehouses } = this.state;
    const warehouseItemList = (warehouseList && warehouseList.data) || null;

    let list = [];
    if (warehouseItemList) {
      list = [...list, ...warehouseItemList];
    }

    return (
      <Form.Item>
        <WarehouseMultiSelect
          list={isUserAdmin ? list : [currentWarehouse]}
          onChange={(toWarehouses) => {
            this.setState({
              toWarehouses,
            });
          }}
          value={toWarehouses}
          loading={warehouseList.loading}
          placeholder={this.props.t(
            'pages.Operation.Transfer.bulkCancel.filter.toWarehouse',
          )}
        />
      </Form.Item>
    );
  };

  ProductField = () => {
    const { productList } = this.props;
    const { products } = this.state;
    const list = (productList && productList.data) || null;
    return (
      <Form.Item>
        <ProductMultiSelect
          list={list}
          onChange={(products) => {
            this.setState({
              products,
            });
          }}
          value={products}
          loading={productList.loading}
          placeholder={this.props.t(
            'pages.Operation.Transfer.bulkCancel.filter.product',
          )}
        />
      </Form.Item>
    );
  };

  TransferTypeSelect = () => {
    const { integrationTypes } = this.state;
    return (
      <Form.Item>
        <TransferTypeMultiSelect
          onChange={(integrationTypes) => {
            this.setState({
              integrationTypes,
            });
          }}
          placeholder={this.props.t(
            'pages.Operation.Transfer.bulkCancel.filter.transferType',
          )}
          value={integrationTypes}
        />
      </Form.Item>
    );
  };

  onTransferStatusChange = (statuses) => {
    this.setState({
      statuses,
    });
  };

  FilterButton = () => {
    return (
      <Form.Item>
        <Button
          type="primary"
          block
          onClick={() => {
            const filter = this.getTableFilter();
            this.filterChanged(filter);
          }}
        >
          {this.props.t('pages.Operation.Transfer.bulkCancel.filter.filter')}
        </Button>
      </Form.Item>
    );
  };

  ClearFilterButton = () => {
    return (
      <Form.Item>
        <Button
          block
          onClick={() => {
            this.clearFilter();
          }}
        >
          {this.props.t('pages.Operation.Transfer.bulkCancel.filter.clean')}
        </Button>
      </Form.Item>
    );
  };

  getTableFilter = () => {
    const {
      fromWarehouses,
      toWarehouses,
      products,
      integrationTypes,
      startDate,
      endDate,
    } = this.state;

    const filter = {};

    filter.createdAt = {
      startDate: startDate || this.props.defaultDateRange.startDate,
      endDate: endDate || this.props.defaultDateRange.endDate,
    };

    if (fromWarehouses && fromWarehouses.length) {
      filter.fromWarehouses = fromWarehouses.map(
        (fromWarehouse) => fromWarehouse.id,
      );
    }
    if (toWarehouses && toWarehouses.length) {
      filter.toWarehouses = toWarehouses.map((toWarehouse) => toWarehouse.id);
    }
    if (products && products.length) {
      filter.items = products.map((p) => p.id);
    }

    if (integrationTypes && integrationTypes.length) {
      filter.integrationTypes = integrationTypes.map(
        (transferType) => transferType.key,
      );
    }

    return filter;
  };

  filterChanged = (filter) => {
    this.props.onChange(filter);
  };

  clearFilter = () => {
    this.setState({ ...this.state, ...this.stateFactory() }, () => {
      this.props.onClear(this.getTableFilter());
    });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse.id,
  currentWarehouse: state.auth.currentWarehouse,
  isUserAdmin: state.auth.user.isAdmin,
  warehouseList: state.warehouse.warehouseList,
  productList: state.product.productList,
  locationList: state.product.locationList,
});

const mapDispatchToProps = (dispatch) => ({
  onGetWarehouseList: () => {
    dispatch(warehouseAction.getWarehouseList());
  },
  onGetProductList: () => {
    dispatch(
      productAction.filterProductList({
        fields: 'barcodes picURL fullName packagingInfo',
        includeDefaultFields: false,
      }),
    );
  },
});

const TransferBulkCancelListFilterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(TransferBulkCancelListFilter));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { TransferBulkCancelListFilterConnected as TransferBulkCancelListFilter };
