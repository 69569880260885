import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';
import { LocalDatabase, constants } from './../shared';
import { filterWarehousesByType } from './../shared/warehouse';

export const warehouseAction = {
  getWarehouseList,
  getAvailableStockOfLocationsByWarehouseId,
  getExpiringProducts,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getWarehouseList() {
  return async (dispatch) => {
    dispatch({
      type: actionType.getWarehouseList_InProgress,
    });

    const warehouses = await LocalDatabase.getWithExpiry('warehouses');

    if (warehouses) {
      dispatch({
        type: actionType.getWarehouseList_Succeeded,
        payload: warehouses,
      });
      return Promise.resolve(warehouses);
    } else {
      return http
        .getWarehouseList()
        .then(async (response) => {
          const { warehouses: allWarehouses } = response.data.data;
          const warehouses = filterWarehousesByType(allWarehouses);
          await LocalDatabase.setWithExpiry(
            'warehouses',
            warehouses,
            constants.TTL_FOR_CACHE.WAREHOUSES,
          );
          dispatch({
            type: actionType.getWarehouseList_Succeeded,
            payload: warehouses,
          });
        })
        .catch((error) => {
          notification.error({
            duration: 4,
            message: ErrorInfo.message(actionType.getWarehouseList_Failed),
            description: ErrorInfo.description(error),
          });
          dispatch({
            type: actionType.getWarehouseList_Failed,
            payload: error,
          });
        });
    }
  };
}

function getAvailableStockOfLocationsByWarehouseId() {
  return (dispatch) => {
    dispatch({
      type: actionType.getAvailableStockOfLocationsByWarehouseId_InProgress,
    });
    http
      .getAvailableStockOfLocationsByWarehouseId()
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getAvailableStockOfLocationsByWarehouseId_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.getAvailableStockOfLocationsByWarehouseId_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getAvailableStockOfLocationsByWarehouseId_Failed,
          payload: error,
        });
      });
  };
}

function getExpiringProducts(warehouseId, filter) {
  return (dispatch) => {
    dispatch({
      type: actionType.getExpiringProducts_InProgress,
    });
    http
      .getExpiringProducts(warehouseId, filter)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getExpiringProducts_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getExpiringProducts_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getExpiringProducts_Failed,
          payload: error,
        });
      });
  };
}
