import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
  detail: {
    loading: true,
    data: null,
    error: null,
  },
  calculatedItems: {
    loading: true,
    data: null,
    error: null,
  },
};

const switcher = {
  [actionType.getPurchaseOrderList_InProgress]: getPurchaseOrderList_InProgress,
  [actionType.getPurchaseOrderList_Succeeded]: getPurchaseOrderList_Succeeded,
  [actionType.getPurchaseOrderList_Failed]: getPurchaseOrderList_Failed,

  [actionType.getPurchaseOrderDetail_InProgress]: getPurchaseOrderDetail_InProgress,
  [actionType.getPurchaseOrderDetail_Succeeded]: getPurchaseOrderDetail_Succeeded,
  [actionType.getPurchaseOrderDetail_Failed]: getPurchaseOrderDetail_Failed,

  [actionType.filterPurchaseOrderList_InProgress]: filterPurchaseOrderList_InProgress,
  [actionType.filterPurchaseOrderList_Succeeded]: filterPurchaseOrderList_Succeeded,
  [actionType.filterPurchaseOrderList_Failed]: filterPurchaseOrderList_Failed,

  [actionType.getOperationPurchaseOrderDetail_InProgress]: getPurchaseOrderDetail_InProgress,
  [actionType.getOperationPurchaseOrderDetail_Succeeded]: getPurchaseOrderDetail_Succeeded,
  [actionType.getOperationPurchaseOrderDetail_Failed]: getPurchaseOrderDetail_Failed,

  [actionType.sendToSupplier_InProgress]: sendToSupplier_InProgress,
  [actionType.sendToSupplier_Succeeded]: sendToSupplier_Succeeded,
  [actionType.sendToSupplier_Failed]: sendToSupplier_Failed,

  [actionType.getCalculatedItemProgress_InProgress]: getCalculatedItemProgress_InProgress,
  [actionType.getCalculatedItemProgress_Succeeded]: getCalculatedItemProgress_Succeeded,
  [actionType.getCalculatedItemProgress_Failed]: getCalculatedItemProgress_Failed,
};

export const purchaseOrderReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getPurchaseOrderList_InProgress(state) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function getPurchaseOrderList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload.data,
      error: null,
      total: payload.total,
    },
  });
}

function getPurchaseOrderList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getPurchaseOrderDetail_InProgress(state) {
  return Object.assign({}, state, {
    detail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getPurchaseOrderDetail_Succeeded(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getPurchaseOrderDetail_Failed(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function filterPurchaseOrderList_InProgress(state) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function filterPurchaseOrderList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload.data,
      error: null,
      total: payload.total,
    },
  });
}

function filterPurchaseOrderList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/


function sendToSupplier_InProgress(state) {
  return Object.assign({}, state, {
    sendToSupplier: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function sendToSupplier_Succeeded(state, payload) {
  return Object.assign({}, state, {
    sendToSupplier: {
      loading: false,
      data: payload.data,
      error: null,
      total: payload.total,
    },
  });
}

function sendToSupplier_Failed(state, payload) {
  return Object.assign({}, state, {
    sendToSupplier: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getCalculatedItemProgress_InProgress(state) {
  return Object.assign({}, state, {
    calculatedItems: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getCalculatedItemProgress_Succeeded(state, payload) {
  return Object.assign({}, state, {
    calculatedItems: {
      loading: false,
      data: payload.data,
      error: null,
    },
  });
}

function getCalculatedItemProgress_Failed(state, payload) {
  return Object.assign({}, state, {
    calculatedItems: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}
