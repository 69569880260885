import { createHttpService } from '../createHttpService';

const WAREHOUSE_ID = localStorage.getItem('warehouseId');

const httpService = createHttpService();

export const courier = {
  getCourierBusyReasons: () => httpService.get('/courier/busy-reasons'),
  makeCourierFree: (courierId) => httpService.post(`/courier/${courierId}/free`),
  makeCourierBusy: (courierId, data) => httpService.post(`/courier/${courierId}/busy`, data),
  getCourierList: (warehouseId) => httpService.get(`/warehouse/${warehouseId || WAREHOUSE_ID}/couriers`),
};
