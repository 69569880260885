import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Button, Card } from 'antd';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './BlockedStockTransferList.scss';
import { BlockedStockTransferFilter } from './BlockedStockTransferFilter';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard } from './../../../../../components';
import { getTranslation, constants, getDateTimeFormatString } from './../../../../../shared';
import { blockedStockAction } from './../../../../../actions';
import { pageLoadEvents } from '../../../../../services/events';
import { pageView } from '../../../../../services/segment';
/*----------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------
------------------------------------------------------------------------------*/

class BlockedStockTransferList extends Component {
  settings = {
    main: {
      className: 'BlockedStockTransferList_main',
    },
    card: {
      title: this.props.t('pages.Operation.BlockedStock.transfer.list.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  dateFormat = getDateTimeFormatString();

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_REMOVE_PRODUCT_TRANSFER_LIST });
    this.getList();
  }

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      filter: this.getDefaultFilter(),
    };
  };

  getDefaultFilter = () => {
    return null;
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageFilter />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    return <></>;
  };

  PageFilter = () => {
    return (
      <BlockedStockTransferFilter
        onChange={this.setTableFilter}
        onClear={this.clearTableFilter}
      />
    );
  };

  PageTable = (props) => {
    return (
      <div>
        <Table
          size="small"
          expandedRowRender={this.expandedRowRender}
          dataSource={this.prepareData()}
          columns={this.prepareColumns()}
          rowKey={(record) => {
            return record.id;
          }}
          pagination={{
            ...this.state.pagination,
            total: this.props.totalCount,
          }}
          loading={{ indicator: <Spinner />, spinning: this.props.loading }}
          onChange={this.handleTableChange}
        />
      </div>
    );
  };

  expandedRowRender = (record, index, indent, expanded) => {
    return (
      <Table
        size="small"
        columns={this.prepareExtendedColumns(record)}
        dataSource={this.prepareExtendedData(record)}
        rowKey={(record) => {
          return record.product.id;
        }}
        pagination={false}
      />
    );
  };

  prepareExtendedColumns = (record) => {
    return [
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.list.subTable.productId',
        ),
        dataIndex: 'product.id',
        key: 'productId',
        render: (productId) => <CopyToClipboard message={productId} />,
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.list.subTable.fullName',
        ),
        dataIndex: 'product.fullName',
        key: 'fullName',
        render: (fullName) =>
          fullName && (
            <CopyToClipboard message={getTranslation.obj(fullName)} />
          ),
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.list.subTable.count',
        ),
        dataIndex: 'count',
        key: 'count',
      },
    ];
  };

  prepareExtendedData = (record) => {
    if (!record.products || !record.products.length) {
      return [];
    }
    return record.products;
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState((state) => {
      return {
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      };
    }, this.getList);
  };

  prepareData = (props) => {
    let { list } = this.props;
    if (props) {
      list = props;
    }

    if (!list) {
      return [];
    }

    const tableData = list.map((item) => {
      return {
        ...item,
        table: {
          id: item.id,
          no: item.no,
          sourceWarehouse: item.sourceWarehouse,
          destinationWarehouse: item.destinationWarehouse,
          products: item.products,
          waybill: item.waybill,
          createdAt: moment(new Date(item.createdAt))
            .local()
            .format(this.dateFormat),
        },
      };
    });
    return tableData;
  };

  prepareColumns = () => {
    return [
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.list.table.id',
        ),
        dataIndex: 'table.id',
        key: 'table.id',
        render: (id) => <CopyToClipboard message={id} />,
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.list.table.no',
        ),
        dataIndex: 'table.no',
        key: 'table.no',
        render: (no) => <CopyToClipboard message={no} />,
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.list.table.waybill',
        ),
        dataIndex: 'table.waybill',
        key: 'table.waybill',
        render: (waybill) => <CopyToClipboard message={waybill} />,
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.list.table.sourceWarehouse',
        ),
        dataIndex: 'table.sourceWarehouse',
        key: 'table.sourceWarehouse',
        render: (warehouse) => {
          if (!warehouse) {
            return;
          }
          return (
            <CopyToClipboard message={warehouse.name} inner={warehouse.name} />
          );
        },
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.list.table.destinationWarehouse',
        ),
        dataIndex: 'table.destinationWarehouse',
        key: 'table.destinationWarehouse',
        render: (warehouse) => {
          if (!warehouse) {
            return;
          }
          return (
            <CopyToClipboard message={warehouse.name} inner={warehouse.name} />
          );
        },
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.list.table.createdAt',
        ),
        dataIndex: 'table.createdAt',
        key: 'table.createdAt',
      },
      {
        dataIndex: 'table.id',
        key: 'table.detail',
        render: (id) => <this.DetailButton id={id} />,
      },
    ];
  };

  DetailButton = (props) => {
    const { id } = props;
    return (
      <Link to={`/stock-operation/blocked-stock/transfer/${id}`}>
        <Button size="small">
          {this.props.t(
            'pages.Operation.BlockedStock.transfer.list.table.detail',
          )}
        </Button>
      </Link>
    );
  };

  getList = () => {
    const query = this.getQuery();
    const filter = this.getFilter();
    this.props.onFilterBlockedStockTransfers(query, filter);
  };

  getQuery = () => {
    return this.state.query;
  };

  getFilter = () => {
    const filter = this.state.filter || {};
    filter.statuses = [constants.TRANSFER_STATUS.SHIPPED];
    return filter;
  };

  setTableFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      };
    }, this.getList);
  };

  clearTableFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      };
    }, this.getList);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.blockedStock.blockedStockTransferList.loading,
  list: state.blockedStock.blockedStockTransferList.data,
  totalCount: state.blockedStock.blockedStockTransferList.total,
});

const mapDispatchToProps = (dispatch) => ({
  onFilterBlockedStockTransfers: (query, filter) => {
    dispatch(blockedStockAction.filterBlockedStockTransfers(query, filter));
  },
});

const BlockedStockTransferListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(BlockedStockTransferList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { BlockedStockTransferListConnected as BlockedStockTransferList };
