import React, { Component } from 'react';
import { Descriptions, Tag, Card, Button, Switch } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import './TransferDetail.scss';
/*----------------------------------------------------------------------------*/
import { CopyToClipboard } from '../../../../components';
import { getTranslation, getDateTimeFormatString } from '../../../../shared';
import { NextStep, NextStepDomainTypes } from '../../shared/NextStep/NextStep';
import { TransferDetailLogic } from './TransferDetailLogic';
/*------------------------------------------------------------------------------
------------------------------------------------------------------------------*/

class TransferDescription extends Component {
  settings = {
    main: {
      className: 'TransferDetail_main',
    },
    transferInfoCard: {
      title: `ID: ${this.props.transfer.id}`,
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  render() {
    return (
      <Card
        bodyStyle={{
          paddingLeft: '10px',
          paddingTop: '10px',
        }}
        {...this.settings.transferInfoCard}
      >
        <this.Description />
      </Card>
    );
  }

  stateFactory = () => ({});


  Description = (props) => {
    const { transfer } = this.props;
    return (
      <Descriptions column={1}>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.Transfer.detail.transfer.description.toggleVisibility',
          )}
        >
            <Switch
              onChange={this.props.onVisibilityChange}
              defaultChecked={transfer.isVisibilityEnabled}
            />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.Transfer.detail.transfer.description.id',
          )}
        >
          <CopyToClipboard message={transfer.id} />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.Transfer.detail.transfer.description.no',
          )}
        >
          <CopyToClipboard message={transfer.no} />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.Transfer.detail.transfer.description.type',
          )}
        >
          {getTranslation.transferIntegrationType(transfer.integrationType)}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.Transfer.detail.transfer.description.fromWarehouse',
          )}
        >
          {transfer.fromWarehouse.name}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.Transfer.detail.transfer.description.toWarehouse',
          )}
        >
          {transfer.toWarehouse.name}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.Transfer.detail.transfer.description.isDeparted',
          )}
        >
          {transfer.isDeparted ? (
            <Tag color="#87d068">
              {this.props.t(
                'pages.Operation.Transfer.detail.transfer.description.isDepartedDone',
              )}
            </Tag>
          ) : (
            <Tag color="#f50">
              {this.props.t(
                'pages.Operation.Transfer.detail.transfer.description.isDepartedNotDone',
              )}
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.Transfer.detail.transfer.description.status',
          )}
        >
          {transfer.status === 600 ? (
            <Tag
              color="#87d068"
              style={{ padding: '0 10px', textAlign: 'center' }}
            >
              {getTranslation.transferStatus(transfer.status)}
            </Tag>
          ) : null}
          {transfer.status === 700 ? (
            <Tag
              color="#f50"
              style={{ padding: '0 10px', textAlign: 'center' }}
            >
              {getTranslation.transferStatus(transfer.status)}
            </Tag>
          ) : null}
          {transfer.status !== 600 && transfer.status !== 700 ? (
            <Tag
              color="#2db7f5"
              style={{ padding: '0 10px', textAlign: 'center' }}
            >
              {getTranslation.transferStatus(transfer.status)}
            </Tag>
          ) : null}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.Transfer.detail.transfer.description.nextStep',
          )}
        >
          <NextStep
            directionType={transfer.directionType}
            transferStatus={transfer.status}
            domainType={NextStepDomainTypes.TRANSFER}
          />
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.Transfer.detail.transfer.description.createdAt',
          )}
        >
          {moment(new Date(transfer.createdAt))
            .local()
            .format(getDateTimeFormatString())}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.Transfer.detail.transfer.description.updatedAt',
          )}
        >
          {moment(new Date(transfer.updatedAt))
            .local()
            .format(getDateTimeFormatString())}
        </Descriptions.Item>
        {transfer.sourceTransfer ? (
          <Descriptions.Item
            label={this.props.t(
              'pages.Operation.Transfer.detail.transfer.description.sourceTransfer',
            )}
          >
            <Link
              to={`/stock-operation/transfer/detail/${transfer.sourceTransfer.toString()}`}
            >
              <Button size="small" style={{ fontSize: 9.9, padding: 0 }}>
                {transfer.sourceTransfer}
              </Button>
            </Link>
          </Descriptions.Item>
        ) : null}
        {!TransferDetailLogic.isTransferVirtualDepart(
          transfer.integrationType,
        ) && (
          <Descriptions.Item
            label={this.props.t(
              'pages.Operation.Transfer.detail.transfer.description.nonAggrementTransferId',
            )}
          >
            {transfer.nonAgreementTransfers &&
            transfer.nonAgreementTransfers.length
              ? transfer.nonAgreementTransfers.map((id) => (
                  <Link
                    to={`/stock-operation/transfer/detail/${id}`}
                    target="_blank"
                  >
                    <Button size="small" style={{ marginBottom: '4px' }}>
                      {id}
                    </Button>
                  </Link>
                ))
              : '-'}
          </Descriptions.Item>
        )}

        {transfer.note && (
          <Descriptions.Item
            label={this.props.t(
              'pages.Operation.Transfer.detail.transfer.description.note',
            )}
          >
            <span>{transfer.note}</span>
          </Descriptions.Item>
        )}
      </Descriptions>
    );
  };
}

const TransferDescriptionTranslated = withNamespaces('translation')(
  TransferDescription,
);

export { TransferDescriptionTranslated as TransferDescription };
