import React, { Component } from 'react';
import { Card, Form, Switch, DatePicker, Radio } from 'antd';
import moment from 'moment';
import i18n from '../../../../../i18n';
/*----------------------------------------------------------------------------*/
import './SettingsInfoPanel.scss';
import { getDateFormatString } from '../../../../../shared';
import { track } from '../../../../../services/segment';
import { PurchaseOrderCreateEvents } from '../../../../../services/events';

class SettingsInfoPanel extends Component {
  dateFormat = getDateFormatString();

  settings = {
    main: {
      className: 'SettingsInfoPanel_main',
    },
    card: {
      className: 'no-padding-card',
      title: i18n.t(
        'pages.Operation.PurchaseOrder.create.SettingsInfoPanel.title',
      ),
      size: 'small',
    },
  };

  formItemLayout = {
    style: {
      marginTop: 15,
      marginLeft: 15,
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => {
    const dateRange = this.getDefaultDateRange();
    return {
      expectedDeliveryDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      isVisibilityManual: true,
      isVisible: true,
      visibilityDateOpen: dateRange.startDate,
      visibilityDateClose: dateRange.endDate,
    };
  };

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card}>
          <this.SettingsForm />
        </Card>
      </div>
    );
  }

  SettingsForm = (props) => {
    return (
      <Form {...this.formItemLayout}>
        <this.EstimatedDeliveryDateField />
        <this.VisibilitySettingsField />
        <this.AutoOpenDateRangeField />
        <this.ManualVisibilityControlField />
      </Form>
    );
  };

  EstimatedDeliveryDateField = (props) => {
    const { expectedDeliveryDate } = this.state;
    return (
      <Form.Item
        label={i18n.t(
          'pages.Operation.PurchaseOrder.create.SettingsInfoPanel.form.estimatedDeliveryDate',
        )}
      >
        <DatePicker
          value={moment(new Date(expectedDeliveryDate))}
          onChange={this.onEstimatedDeliveryDateChange}
          format={this.dateFormat}
        />
      </Form.Item>
    );
  };

  VisibilitySettingsField = (props) => {
    const { isVisibilityManual } = this.state;
    return (
      <Form.Item
        label={i18n.t(
          'pages.Operation.PurchaseOrder.create.SettingsInfoPanel.form.visibilitySettings',
        )}
      >
        <Radio.Group
          value={isVisibilityManual ? 'manualControl' : 'selectDate'}
          onChange={this.onVisibilitySettingsChange}
        >
          <Radio.Button value="manualControl">
            {i18n.t(
              'pages.Operation.PurchaseOrder.create.SettingsInfoPanel.form.manualControl',
            )}
          </Radio.Button>
          <Radio.Button value="selectDate">
            {i18n.t(
              'pages.Operation.PurchaseOrder.create.SettingsInfoPanel.form.selectDate',
            )}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
    );
  };

  AutoOpenDateRangeField = (props) => {
    const {
      visibilityDateOpen,
      visibilityDateClose,
      isVisibilityManual,
    } = this.state;
    if (isVisibilityManual) {
      return null;
    }
    return (
      <Form.Item
        label={i18n.t(
          'pages.Operation.PurchaseOrder.create.SettingsInfoPanel.form.dateRange',
        )}
      >
        <DatePicker.RangePicker
          value={[
            moment(new Date(visibilityDateOpen)),
            moment(new Date(visibilityDateClose)),
          ]}
          onChange={(dates) => {
            console.log('--', dates);
            this.onAutoOpeningDateChange(dates[0]);
            this.onAutoClosingDateChange(dates[1]);
          }}
          format={this.dateFormat}
        />
      </Form.Item>
    );
  };

  AutoOpeningDateField = (props) => {
    const { visibilityDateOpen, isVisibilityManual } = this.state;
    if (isVisibilityManual) {
      return null;
    }
    return (
      <Form.Item
        label={i18n.t(
          'pages.Operation.PurchaseOrder.create.SettingsInfoPanel.form.openDate',
        )}
      >
        <DatePicker
          value={moment(new Date(visibilityDateOpen))}
          onChange={this.onAutoOpeningDateChange}
          format={this.dateFormat}
        />
      </Form.Item>
    );
  };

  AutoClosingDateField = (props) => {
    const { visibilityDateClose, isVisibilityManual } = this.state;
    if (isVisibilityManual) {
      return null;
    }
    return (
      <Form.Item
        label={i18n.t(
          'pages.Operation.PurchaseOrder.create.SettingsInfoPanel.form.closeDate',
        )}
      >
        <DatePicker
          value={moment(new Date(visibilityDateClose))}
          onChange={this.onAutoClosingDateChange}
          format={this.dateFormat}
        />
      </Form.Item>
    );
  };

  ManualVisibilityControlField = (props) => {
    const { isVisible, isVisibilityManual } = this.state;
    if (!isVisibilityManual) {
      return null;
    }
    return (
      <Form.Item
        label={i18n.t(
          'pages.Operation.PurchaseOrder.create.SettingsInfoPanel.form.visibility',
        )}
      >
        <Switch
          checked={isVisible}
          checkedChildren={i18n.t(
            'pages.Operation.PurchaseOrder.create.SettingsInfoPanel.form.open',
          )}
          unCheckedChildren={i18n.t(
            'pages.Operation.PurchaseOrder.create.SettingsInfoPanel.form.closed',
          )}
          onChange={this.onManualVisibilityControlChange}
        />
      </Form.Item>
    );
  };

  onEstimatedDeliveryDateChange = (expectedDeliveryDate) => {
    track(PurchaseOrderCreateEvents.PO_CREATE_EXPECTED_DELIVERY_DATE_SELECTED);
    this.setState({ expectedDeliveryDate: expectedDeliveryDate._d });
    this.onChange();
  };

  onVisibilitySettingsChange = (event) => {
    const isVisibilityManual = event.target.value === 'manualControl';
    track(PurchaseOrderCreateEvents.PO_CREATE_VISIBILITY_SETTINGS_SELECTED, { isVisibilityManual });
    this.setState({ isVisibilityManual });
    this.onChange();
  };

  onAutoOpeningDateChange = (visibilityDateOpen) => {
    this.setState({ visibilityDateOpen: visibilityDateOpen._d });
    this.onChange();
  };

  onAutoClosingDateChange = (visibilityDateClose) => {
    this.setState({ visibilityDateClose: visibilityDateClose._d });
    this.onChange();
  };

  onManualVisibilityControlChange = (isVisible) => {
    track(PurchaseOrderCreateEvents.PO_CREATE_VISIBILITY_TOGGLE_CHANGED, { isVisible });
    this.setState({ isVisible });
    this.onChange();
  };

  onChange = () => {
    setTimeout(() => {
      this.props.onChange(this.state);
    });
  };

  clearState = () => {
    this.setState(this.stateFactory());
  };

  getState = () => {
    return this.state;
  };
}

const SettingsInfoPanelTranslated = SettingsInfoPanel;

export { SettingsInfoPanelTranslated as SettingsInfoPanel };
