import React, { Component } from 'react';
import { Select } from 'antd';
// --------------------------------------------------
// --------------------------------------------------
import { withNamespaces } from 'react-i18next';
import { constants, statuses } from '../../../shared';
// --------------------------------------------------

const { Option } = Select;

const courierStatus = constants.COURIER_STATUS;
const courierStatusVal = statuses.courierStatus;

class CourierFilter extends Component {
  constructor() {
    super();
    this.state = {
      selectedItems: [],
    };
    this.filterOptions = [];
    Object.keys(courierStatus).forEach((key) => {
      const optionKey = courierStatus[key];
      this.filterOptions.push(optionKey);
    });
  }

  render() {
    const { onChangeFilter } = this.props;
    const { selectedItems } = this.state;
    const filteredOptions = this.filterOptions.filter(
      (option) => !selectedItems.includes(option.toString()),
    );
    return (
      <div className="CourierFilter_inner">
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder={this.props.t('pages.Home.courierFilter.pleaseSelect')}
          defaultValue={selectedItems}
          onChange={(value) => {
            this.setState({
              selectedItems: value,
            });
            onChangeFilter(value);
          }}
        >
          {filteredOptions.map((item) => (
            <Option key={item}>{this.props.t(courierStatusVal[item])}</Option>
          ))}
        </Select>
      </div>
    );
  }
}

const CourierFilterTranslated = withNamespaces('translation')(CourierFilter);

export { CourierFilterTranslated as CourierFilter };
