import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Form, Button, Row, Col,
} from 'antd';
import { withNamespaces } from 'react-i18next';
import { ProductMultiSelect, DateRangeSelect } from './filterFields';
import { productAction } from './../../../../actions';

class ExpirationDateListFilter extends PureComponent {
  settings = {
    main: {
      className: 'ExpirationDateListFilter_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    const { onGetProductList } = this.props;
    onGetProductList();
  }

  stateFactory = () => ({
    ...this.emptyTableFilter(),
  });

  emptyTableFilter = () => ({
    products: [],
    startDate: this.props.defaultDateRange.startDate,
    endDate: this.props.defaultDateRange.endDate,
  });

  render() {
    return (
      <div {...this.settings.main}>
        <this.TableFilterForm />
      </div>
    );
  }

  TableFilterForm = (props) => (
    <div>
      <Form {...this.formItemLayout}>
        <Row>
          <Col span={5} style={{ padding: '0px 5px' }}>
            <this.DateRangeField />
          </Col>
          <Col span={19} style={{ padding: '0px 5px' }}>
            <this.ProductField />
          </Col>
        </Row>
        <Row>
          <Col span={2} style={{ padding: '0px 5px' }}>
            <this.ClearFilterButton />
          </Col>
          <Col offset={20} span={2} style={{ padding: '0px 5px' }}>
            <this.FilterButton />
          </Col>
        </Row>
      </Form>
    </div>
  );

  DateRangeField = () => (
    <Form.Item>
      <DateRangeSelect
        defaultDateRange={this.props.defaultDateRange}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        onChange={(dateRange) => {
          this.setState({
            ...this.state,
            ...dateRange,
          });
        }}
      />
    </Form.Item>
  );

  ProductField = () => {
    const { productList } = this.props;
    const list = (productList && productList.data) || null;
    return (
      <Form.Item>
        <ProductMultiSelect
          placeholder={this.props.t('pages.Stock.ExpirationDate.list.filter.products')}
          list={list}
          onChange={(products) => {
            this.setState({ products });
          }}
          value={this.state.products}
          loading={productList.loading}
        />
      </Form.Item>
    );
  };

  ClearFilterButton = () => (
    <Form.Item>
      <Button
        block
        onClick={() => {
          this.clearFilter();
        }}
      >
        {this.props.t('pages.Stock.ExpirationDate.list.filter.clean')}
      </Button>
    </Form.Item>
  );

  clearFilter = () => {
    this.setState(
      {
        ...this.emptyTableFilter(),
      },
      () => {
        this.props.onClear(this.getTableFilter());
      },
    );
  };

  FilterButton = () => (
    <Form.Item>
      <Button
        type="primary"
        block
        onClick={() => {
          try {
            const filter = this.getTableFilter();
            this.props.onChange(filter);
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {this.props.t('pages.Stock.ExpirationDate.list.filter.filter')}
      </Button>
    </Form.Item>
  );

  getTableFilter = () => {
    const { products, startDate, endDate } = this.state;

    const filter = {
      removeFromSaleDateRange: {
        startDate: startDate || this.props.defaultDateRange.startDate,
        endDate: endDate || this.props.defaultDateRange.endDate,
      },
    };

    if (products && products.length) {
      filter.productIds = products.map((p) => p.id);
    }
    return filter;
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse.id,
  currentWarehouse: state.auth.currentWarehouse,
  productList: state.product.productList,
});

const mapDispatchToProps = (dispatch) => ({
  onGetProductList: () => {
    dispatch(
      productAction.filterProductList({
        fields: 'barcodes fullName picURL packagingInfo',
        includeDefaultFields: false,
      }),
    );
  },
});

const ExpirationDateListFilterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(ExpirationDateListFilter));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { ExpirationDateListFilterConnected as ExpirationDateListFilter };
