import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';

export const transactionsAction = {
  getTransactionsList,
  publishTransactions,
};

function getTransactionsList({ query, filter }) {
  return (dispatch) => {
    dispatch({ type: actionType.getTransactionsList_InProgress });
    http
      .getTransactionsList({ query, filter })
      .then((response) => {
        dispatch({
          type: actionType.getTransactionsList_Succeeded,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getTransactionsList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getTransactionsList_Failed,
          payload: error,
        });
      });
  };
}

function publishTransactions(data) {
  return (dispatch) => {
    dispatch({ type: actionType.publishTransactions_InProgress });
    return http
      .publishTransactions(data)
      .then((response) => {
        dispatch({
          type: actionType.publishTransactions_Succeeded,
          payload: response.data.data,
        });
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.publishTransactions_Succeeded),
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.publishTransactions_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.publishTransactions_Failed,
          payload: error,
        });
      });
  };
}
