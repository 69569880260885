import { createHttpService } from '../createHttpService';
import { QueryBuilder } from '../QueryBuilder';

const httpService = createHttpService();

export const transactions = {
  getTransactionsList: ({ query, filter }) =>
    httpService.post(
      `/events/filter?${QueryBuilder.getQueryStr(query)}`,
      filter,
    ),
  publishTransactions: (body) => httpService.post('/events', body),
};
