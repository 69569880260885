import React, { Component } from 'react';
import { Select, Spin, Button } from 'antd';
import { uniqBy } from 'lodash';
import './WarehouseControl.scss';
import i18n from './../../../../../../i18n';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class WarehouseControl extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => {
    return {
      selectedItem: null,
    };
  };

  render() {
    const { disabled } = this.props;
    const { selectedItem } = this.state;
    return (
      <div className="WarehouseControl">
        <this.SelectField />
        <Button onClick={this.add} disabled={!selectedItem}>
          {i18n.t(
            'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.control.add',
          )}
        </Button>
        <Button onClick={this.addAll} disabled={disabled}>
          {i18n.t(
            'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.control.addAll',
          )}
        </Button>
      </div>
    );
  }

  SelectField = () => {
    const { loading, disabled, placeholder } = this.props;
    return (
      <Select
        style={{ width: 230 }}
        listHeight={100}
        showSearch
        allowClear
        optionFilterProp="children"
        disabled={disabled}
        value={this.getValue()}
        filterOption={this.filterOption}
        placeholder={placeholder}
        onChange={this.onChange}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {this.getOptions()}
      </Select>
    );
  };

  getOptions = () => {
    const { list } = this.props;
    if (!list || !list.length) {
      return null;
    }
    const options = uniqBy(
      list.filter((item) => item.name),
      'name',
    ).map((item) => {
      return (
        <Select.Option value={item.name} key={item.id} rawvalue={item}>
          {item.name}
        </Select.Option>
      );
    });
    return options;
  };

  getValue = () => {
    const { selectedItem } = this.state;
    if (!selectedItem) {
      return;
    }
    return selectedItem.name;
  };

  filterOption = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
      option.props.rawvalue.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  onChange = (value, option) => {
    let selectedItem = null;
    try {
      selectedItem = option.props.rawvalue;
    } catch (error) {
      selectedItem = null;
    }
    this.setState({ selectedItem });
  };

  add = () => {
    const { selectedItem } = this.state;
    this.props.onAdd(selectedItem);
    this.setState(this.stateFactory());
  };

  addAll = () => {
    const { list } = this.props;
    this.props.onAddAll(uniqBy(list, 'name'));
    this.setState(this.stateFactory());
  };

  clearState = () => {
    this.setState(this.stateFactory());
  };
}

const WarehouseControlTranslated = WarehouseControl;

export { WarehouseControlTranslated as WarehouseControl };
