import React, { Component } from 'react';
import { Card, Button, Avatar } from 'antd';
import classNames from 'classnames';
// --------------------------------------------------
import './CourierCard.scss';
// --------------------------------------------------
import { withNamespaces } from 'react-i18next';
import courierMarker from './../../../assets/markers/courier.png';
import { getLangKey } from './../../../i18n';
import { constants, msToTime, getTranslation } from './../../../shared';
// --------------------------------------------------
const { COURIER_STATUS } = constants;

class CourierCard extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory(props);
  }

  stateFactory = (props) => {
    const newState = {};
    return newState;
  };

  changeCourierStatus = (event) => {
    event.stopPropagation();
    const { courier, openModal } = this.props;
    openModal(courier);
  };

  getTime = (timer, statusLastChangedAt) => {
    let timerString = null;
    try {
      timerString = msToTime(timer - statusLastChangedAt + 1000, getLangKey());
    } catch (error) {
      timerString = null;
    }
    return timerString;
  };

  openStockModal = () => {
    const { courier } = this.props;
    this.props.makeVisibleStockModal(courier.availableStock, courier);
  };

  BusyDescription = () => {
    const { courier, courierBusyReasons } = this.props;
    if (courier.status === COURIER_STATUS.BUSY && courier.lastBusyOption) {
      if (courier.lastBusyOption.length === 24 && courierBusyReasons.data) {
        const reason = courierBusyReasons.data.find(
          (item) => item._id === courier.lastBusyOption,
        );
        return (
          <div style={{ fontSize: 12 }}>
            <div>{getTranslation.obj(reason.messages)}</div>
          </div>
        );
      }
      return (
        <div style={{ fontSize: 12 }}>
          <div>{getTranslation.obj(courier.lastBusyOption)}</div>
          {courier.lastBusyOption.comment ? (
            <div>{courier.lastBusyOption.comment}</div>
          ) : (
            ''
          )}
        </div>
      );
    }
    return null;
  };

  LastStatusChangeTimer = () => {
    const { courier, timer } = this.props;
    if (!courier.statusLastChangedAt) {
      return null;
    }
    const statusLastChangedAt = new Date(courier.statusLastChangedAt).getTime();
    return (
      <span style={{ marginLeft: 5, fontSize: 12 }}>
        <i className="fa fa-clock-o" />
        <span style={{ margin: '0px 5px' }}>
          {this.getTime(timer, statusLastChangedAt)}
        </span>
      </span>
    );
  };

  render() {
    const { courier, t } = this.props;
    if (!courier) {
      return null;
    }
    const courier_cssClass = classNames({
      'courier-card': true,
      free: courier.status === COURIER_STATUS.FREE,
      busy: courier.status === COURIER_STATUS.BUSY,
      canceled: courier.status === COURIER_STATUS.CANCELED,
      returning: courier.status === COURIER_STATUS.RETURNING,
      reserved: courier.status === COURIER_STATUS.RESERVED,
      'on-order':
        courier.status >= COURIER_STATUS.VERIFYING &&
        courier.status <= COURIER_STATUS.REACHED,
    });
    const isChangeStatusBtnEnabled =
      courier.status === COURIER_STATUS.FREE ||
      courier.status === COURIER_STATUS.BUSY;
    return (
      <div className="CourierCard_inner">
        <Card className={courier_cssClass}>
          <div className="courier-card_main">
            <div className="courier-info">
              <div className="courier-name">
                <img
                  className="courier-marker_img"
                  src={courierMarker}
                  alt="courier marker"
                />
                <div className="courier-name_text">
                  <div>{courier.name}</div>
                </div>
              </div>
              <this.LastStatusChangeTimer />
              <div className="courier-detail">
                <div className="courier-status">
                  <Button
                    onClick={this.changeCourierStatus}
                    disabled={!isChangeStatusBtnEnabled}
                  >
                    {getTranslation.courierStatus(courier.status)}
                  </Button>
                  <this.BusyDescription />
                </div>
              </div>
            </div>
            <div
              className="courier-photo"
              style={{
                cursor: this.props.isWaterWarehouse ? 'pointer' : 'default',
              }}
              onClick={() => {
                this.openStockModal();
              }}
            >
              <Avatar
                src={courier.picURL}
                size={50}
                shape="circle"
                icon="user"
                className="courier-photo_img"
              />
            </div>
          </div>
          {courier.carboyCount || courier.availableStock ? (
            <div className="courier-carboy">
              {t('pages.Home.carboyStockModal.carboy')}:{' '}
              {courier.carboyCount || 0}
            </div>
          ) : null}
        </Card>
      </div>
    );
  }
}

const CourierCardConnected = withNamespaces('translation')(CourierCard);

export { CourierCardConnected as CourierCard };
