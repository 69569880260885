import { actionType } from './../actions';
import { createReducer } from './createReducer';
import { getErrorMessage } from './../shared';

const initialState = {
  username: '',
  password: '',
  loginError: '',
  isLoginPending: false,
  roles: [],
  currentWarehouse: {},
  redirectPath: '/',
  loading: false,
  warehouses: [],
  switchError: false,
  switchLoading: false,
  updateWarehouseStatusError: '',
  updateWarehouseStatusLoading: false,
  changePreparationTimeLoading: false,
  changePreparationTimeError: false,
  user: {},
  tokenCode: null,
  otp: null,
  ttl: null,
  maskedGSM: null,
  isOtpVerifyPending: false,
  otpVerifyError: null,
  isOtpResendPending: false,
};

const switcher = {
  [actionType.login_InProgress]: login_InProgress,
  [actionType.login_Succeeded]: login_Succeeded,
  [actionType.login_Otp_Required]: login_Otp_Required,
  [actionType.login_Otp_Reset]: login_Otp_Reset,
  [actionType.login_Failed]: login_Failed,

  [actionType.logout_InProgress]: logout_InProgress,
  [actionType.logout_Succeeded]: logout_Succeeded,
  [actionType.logout_Failed]: logout_Failed,

  [actionType.otpVerify_InProgress]: otpVerify_InProgress,
  [actionType.otpVerify_Succeeded]: otpVerify_Succeeded,
  [actionType.otpVerify_Failed]: otpVerify_Failed,

  [actionType.updateWarehouseStatus_InProgress]: updateWarehouseStatus_InProgress,
  [actionType.updateWarehouseStatus_Succeeded]: updateWarehouseStatus_Succeeded,
  [actionType.updateWarehouseStatus_Failed]: updateWarehouseStatus_Failed,

  [actionType.otpResend_InProgress]: otpResend_InProgress,
  [actionType.otpResend_Succeeded]: otpResend_Succeeded,
  [actionType.otpResend_Failed]: otpResend_Failed,
};

export const authReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function login_InProgress(state) {
  return Object.assign({}, state, {
    loginError: '',
    isLoginPending: true,
  });
}

function login_Succeeded(state, payload) {
  const {
    user,
    tokenCode,
    warehouseId,
    currentWarehouse,
    redirectPath,
  } = payload;
  if (window.location.pathname === '/login') {
    window.location.pathname = redirectPath;
  }
  return Object.assign({}, state, {
    user,
    username: user.name,
    tokenCode,
    warehouseId,
    currentWarehouse,
    redirectPath,
    isLoginPending: false,
  });
}

function login_Otp_Required(state, payload) {
  const { username, otp, ttl, maskedGSM } = payload;

  return Object.assign({}, state, {
    username,
    otp,
    ttl,
    maskedGSM,
    isLoginPending: false,
  });
}

function login_Otp_Reset(state) {
  return Object.assign({}, state, {
    username: '',
    otp: null,
    ttl: null,
    maskedGSM: null,
    isLoginPending: false,
  });
}

function login_Failed(state, payload) {
  const message = getErrorMessage(payload);
  return Object.assign({}, state, {
    loginError: message,
    isLoginPending: false,
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function logout_InProgress(state) {
  return Object.assign({}, state, {});
}

function logout_Succeeded(state) {
  return Object.assign({}, state, initialState);
}

function logout_Failed(state) {
  return Object.assign({}, state, {});
}

function updateWarehouseStatus_InProgress(state) {
  return Object.assign({}, state, {
    updateWarehouseStatusError: '',
    updateWarehouseStatusLoading: true,
  });
}

function updateWarehouseStatus_Succeeded(state, payload) {
  return Object.assign({}, state, {
    currentWarehouse: payload,
    updateWarehouseStatusLoading: false,
  });
}

function updateWarehouseStatus_Failed(state, { message }) {
  return Object.assign({}, state, {
    updateWarehouseStatusError: message,
    updateWarehouseStatusLoading: false,
  });
}

function otpVerify_InProgress(state) {
  return Object.assign({}, state, {
    otpVerifyError: '',
    isOtpVerifyPending: true,
  });
}

function otpVerify_Succeeded(state, payload) {
  const {
    user,
    tokenCode,
    warehouseId,
    currentWarehouse,
    redirectPath,
  } = payload;
  if (window.location.pathname === '/login') {
    window.location.pathname = redirectPath;
  }
  return Object.assign({}, state, {
    user,
    username: user.name,
    tokenCode,
    warehouseId,
    currentWarehouse,
    redirectPath,
    isOtpVerifyPending: false,
    otp: false,
  });
}

function otpVerify_Failed(state, payload) {
  const message = getErrorMessage(payload);
  return Object.assign({}, state, {
    otpVerifyError: message,
    isOtpVerifyPending: false,
  });
}

function otpResend_InProgress(state) {
  return Object.assign({}, state, { isOtpResendPending: true });
}

function otpResend_Succeeded(state) {
  return Object.assign({}, state, { isOtpResendPending: false });
}

function otpResend_Failed(state) {
  return Object.assign({}, state, { isOtpResendPending: false });
}
