export const getStatusTranslation = (status, code) => {
  return statuses[status][code];
};

const t = (s) => s;

export const statuses = {
  blockedStockConclusionType: {
    10: t('shared.statuses.blockedStockConclusionType.10'),
    20: t('shared.statuses.blockedStockConclusionType.20'),
    30: t('shared.statuses.blockedStockConclusionType.30'),
  },
  blockedStockReasonOperationType: {
    71: t('shared.statuses.blockedStockReasonOperationType.71'),
    72: t('shared.statuses.blockedStockReasonOperationType.72'),
    73: t('shared.statuses.blockedStockReasonOperationType.73'),
    74: t('shared.statuses.blockedStockReasonOperationType.74'),
    75: t('shared.statuses.blockedStockReasonOperationType.75'),
    76: t('shared.statuses.blockedStockReasonOperationType.76'),
    77: t('shared.statuses.blockedStockReasonOperationType.77'),
    78: t('shared.statuses.blockedStockReasonOperationType.78'),
  },
  blockedStockRequestStatus: {
    100: t('shared.statuses.blockedStockRequestStatus.100'),
    200: t('shared.statuses.blockedStockRequestStatus.200'),
    300: t('shared.statuses.blockedStockRequestStatus.300'),
    400: t('shared.statuses.blockedStockRequestStatus.400'),
  },
  blockedStockStatus: {
    100: t('shared.statuses.blockedStockStatus.100'),
    300: t('shared.statuses.blockedStockStatus.300'),
    320: t('shared.statuses.blockedStockStatus.320'),
    350: t('shared.statuses.blockedStockStatus.350'),
    500: t('shared.statuses.blockedStockStatus.500'),
    600: t('shared.statuses.blockedStockStatus.600'),
    700: t('shared.statuses.blockedStockStatus.700'),
  },
  blockedStockTransferStatus: {
    320: t('shared.statuses.blockedStockTransferStatus.320'),
    350: t('shared.statuses.blockedStockTransferStatus.350'),
  },
  courierStatus: {
    100: t('shared.statuses.courierStatus.100'),
    200: t('shared.statuses.courierStatus.200'),
    300: t('shared.statuses.courierStatus.300'),
    350: t('shared.statuses.courierStatus.350'),
    375: t('shared.statuses.courierStatus.375'),
    400: t('shared.statuses.courierStatus.400'),
    450: t('shared.statuses.courierStatus.450'),
    500: t('shared.statuses.courierStatus.500'),
    600: t('shared.statuses.courierStatus.600'),
    700: t('shared.statuses.courierStatus.700'),
    800: t('shared.statuses.courierStatus.800'),
    900: t('shared.statuses.courierStatus.900'),
    1000: t('shared.statuses.courierStatus.1000'),
  },
  fleetVehicleType: {
    100: t('shared.statuses.fleetVehicleType.100'),
    200: t('shared.statuses.fleetVehicleType.200'),
    300: t('shared.statuses.fleetVehicleType.300'),
    400: t('shared.statuses.fleetVehicleType.400'),
    500: t('shared.statuses.fleetVehicleType.500'),
    600: t('shared.statuses.fleetVehicleType.600'),
  },
  inventoryCheckScope: {
    100: t('shared.statuses.inventoryCheckScope.100'),
    200: t('shared.statuses.inventoryCheckScope.200'),
  },
  inventoryCheckStatus: {
    100: t('shared.statuses.inventoryCheckStatus.100'),
    400: t('shared.statuses.inventoryCheckStatus.400'),
    500: t('shared.statuses.inventoryCheckStatus.500'),
    600: t('shared.statuses.inventoryCheckStatus.600'),
    700: t('shared.statuses.inventoryCheckStatus.700'),
    800: t('shared.statuses.inventoryCheckStatus.800'),
  },
  inventoryCheckType: {
    100: t('shared.statuses.inventoryCheckType.100'),
    200: t('shared.statuses.inventoryCheckType.200'),
  },
  jobTaskStatus: {
    400: t('shared.statuses.jobTaskStatus.400'),
    500: t('shared.statuses.jobTaskStatus.500'),
    600: t('shared.statuses.jobTaskStatus.600'),
    700: t('shared.statuses.jobTaskStatus.700'),
  },
  loggerModelType: {
    BlockedStock: t('shared.statuses.loggerModelType.BlockedStock'),
    BlockedStockTransfer: t(
      'shared.statuses.loggerModelType.BlockedStockTransfer',
    ),
    Disposal: t('shared.statuses.loggerModelType.Disposal'),
    InventoryCheck: t('shared.statuses.loggerModelType.InventoryCheck'),
    Order: t('shared.statuses.loggerModelType.Order'),
    Refund: t('shared.statuses.loggerModelType.Refund'),
    Transfer: t('shared.statuses.loggerModelType.Transfer'),
    WarehousePurchaseOrder: t(
      'shared.statuses.loggerModelType.WarehousePurchaseOrder',
    ),
    Production: t('shared.statuses.loggerModelType.Production'),
    RetailOrder: t('shared.statuses.loggerModelType.RetailOrder'),
  },
  jobType: {
    StockInventoryCheck: t('shared.statuses.jobType.StockInventoryCheck'),
    StockBlockedStock: t('shared.statuses.jobType.StockBlockedStock'),
    PurchaseOrder: t('shared.statuses.jobType.PurchaseOrder'),
    StockTransfer: t('shared.statuses.jobType.StockTransfer'),
  },
  operationPurchaseOrderStatus: {
    100: t('shared.statuses.operationPurchaseOrderStatus.100'),
    200: t('shared.statuses.operationPurchaseOrderStatus.200'),
    300: t('shared.statuses.operationPurchaseOrderStatus.300'),
    400: t('shared.statuses.operationPurchaseOrderStatus.400'),
    500: t('shared.statuses.operationPurchaseOrderStatus.500'),
    600: t('shared.statuses.operationPurchaseOrderStatus.600'),
    700: t('shared.statuses.operationPurchaseOrderStatus.700'),
    710: t('shared.statuses.operationPurchaseOrderStatus.710'),
  },
  orderStatus: {
    100: t('shared.statuses.orderStatus.100'),
    200: t('shared.statuses.orderStatus.200'),
    300: t('shared.statuses.orderStatus.300'),
    350: t('shared.statuses.orderStatus.350'),
    375: t('shared.statuses.orderStatus.375'),
    400: t('shared.statuses.orderStatus.400'),
    500: t('shared.statuses.orderStatus.500'),
    550: t('shared.statuses.orderStatus.550'),
    600: t('shared.statuses.orderStatus.600'),
    700: t('shared.statuses.orderStatus.700'),
    800: t('shared.statuses.orderStatus.800'),
    900: t('shared.statuses.orderStatus.900'),
    1000: t('shared.statuses.orderStatus.1000'),
    1100: t('shared.statuses.orderStatus.1100'),
    1200: t('shared.statuses.orderStatus.1200'),
    1300: t('shared.statuses.orderStatus.1300'),
    1400: t('shared.statuses.orderStatus.1400'),
    1500: t('shared.statuses.orderStatus.1500'),
  },
  walkInOrderStatus: {
    100: t('shared.statuses.walkInOrderStatus.100'),
    200: t('shared.statuses.walkInOrderStatus.200'),
    300: t('shared.statuses.walkInOrderStatus.300'),
    1000: t('shared.statuses.walkInOrderStatus.1000'),
  },
  walkInOrderCancelReason: {
    10: t('shared.statuses.walkInOrderCancelReason.10'),
    20: t('shared.statuses.walkInOrderCancelReason.20'),
    30: t('shared.statuses.walkInOrderCancelReason.30'),
    40: t('shared.statuses.walkInOrderCancelReason.40'),
    50: t('shared.statuses.walkInOrderCancelReason.50'),
    1000: t('shared.statuses.walkInOrderCancelReason.1000'),
  },
  pickerStatus: {
    100: t('shared.statuses.pickerStatus.100'),
    200: t('shared.statuses.pickerStatus.200'),
    300: t('shared.statuses.pickerStatus.300'),
    1000: t('shared.statuses.pickerStatus.1000'),
  },
  pickerJobStatus: {
    100: t('shared.statuses.pickerJobStatus.100'),
    200: t('shared.statuses.pickerJobStatus.200'),
    300: t('shared.statuses.pickerJobStatus.300'),
    400: t('shared.statuses.pickerJobStatus.400'),
  },
  purchaseOrderStatus: {
    100: t('shared.statuses.purchaseOrderStatus.100'),
    200: t('shared.statuses.purchaseOrderStatus.200'),
    300: t('shared.statuses.purchaseOrderStatus.300'),
    600: t('shared.statuses.purchaseOrderStatus.600'),
    700: t('shared.statuses.purchaseOrderStatus.700'),
  },
  transferIntegrationType: {
    100: t('shared.statuses.transferIntegrationType.100'),
    101: t('shared.statuses.transferIntegrationType.101'),
    102: t('shared.statuses.transferIntegrationType.102'),
    103: t('shared.statuses.transferIntegrationType.103'),
    104: t('shared.statuses.transferIntegrationType.104'),
    105: t('shared.statuses.transferIntegrationType.105'),
    106: t('shared.statuses.transferIntegrationType.106'),
    107: t('shared.statuses.transferIntegrationType.107'),
    108: t('shared.statuses.transferIntegrationType.108'),
    200: t('shared.statuses.transferIntegrationType.200'),
    300: t('shared.statuses.transferIntegrationType.300'),
    400: t('shared.statuses.transferIntegrationType.400'),
    500: t('shared.statuses.transferIntegrationType.500'),
    600: t('shared.statuses.transferIntegrationType.600'),
    700: t('shared.statuses.transferIntegrationType.700'),
    800: t('shared.statuses.transferIntegrationType.800'),
  },
  transferStatus: {
    100: t('shared.statuses.transferStatus.100'),
    200: t('shared.statuses.transferStatus.200'),
    300: t('shared.statuses.transferStatus.300'),
    310: t('shared.statuses.transferStatus.310'),
    320: t('shared.statuses.transferStatus.320'),
    400: t('shared.statuses.transferStatus.400'),
    500: t('shared.statuses.transferStatus.500'),
    600: t('shared.statuses.transferStatus.600'),
    700: t('shared.statuses.transferStatus.700'),
    710: t('shared.statuses.transferStatus.710'),
  },
  warehouseLocationOperationType: {
    10: t('shared.statuses.warehouseLocationOperationType.10'),
    20: t('shared.statuses.warehouseLocationOperationType.20'),
    30: t('shared.statuses.warehouseLocationOperationType.30'),
    40: t('shared.statuses.warehouseLocationOperationType.40'),
    41: t('shared.statuses.warehouseLocationOperationType.41'),
    50: t('shared.statuses.warehouseLocationOperationType.50'),
    60: t('shared.statuses.warehouseLocationOperationType.60'),
    70: t('shared.statuses.warehouseLocationOperationType.70'),
    71: t('shared.statuses.warehouseLocationOperationType.71'),
    72: t('shared.statuses.warehouseLocationOperationType.72'),
    73: t('shared.statuses.warehouseLocationOperationType.73'),
    74: t('shared.statuses.warehouseLocationOperationType.74'),
    75: t('shared.statuses.warehouseLocationOperationType.75'),
    76: t('shared.statuses.warehouseLocationOperationType.76'),
    77: t('shared.statuses.warehouseLocationOperationType.77'),
    78: t('shared.statuses.warehouseLocationOperationType.78'),
    80: t('shared.statuses.warehouseLocationOperationType.80'),
    90: t('shared.statuses.warehouseLocationOperationType.90'),
    100: t('shared.statuses.warehouseLocationOperationType.100'),
    110: t('shared.statuses.warehouseLocationOperationType.110'),
    120: t('shared.statuses.warehouseLocationOperationType.120'),
    130: t('shared.statuses.warehouseLocationOperationType.130'),
    140: t('shared.statuses.warehouseLocationOperationType.140'),
    900: t('shared.statuses.warehouseLocationOperationType.900'),
  },
  warehousePurchaseOrderRejectionType: {
    1: t('shared.statuses.warehousePurchaseOrderRejectionType.1'),
    2: t('shared.statuses.warehousePurchaseOrderRejectionType.2'),
    3: t('shared.statuses.warehousePurchaseOrderRejectionType.3'),
    4: t('shared.statuses.warehousePurchaseOrderRejectionType.4'),
    5: t('shared.statuses.warehousePurchaseOrderRejectionType.5'),
    6: t('shared.statuses.warehousePurchaseOrderRejectionType.6'),
  },
  inventoryCheckReasons: {
    10: t('shared.statuses.inventoryCheckReasons.10'),
    20: t('shared.statuses.inventoryCheckReasons.20'),
    30: t('shared.statuses.inventoryCheckReasons.30'),
    40: t('shared.statuses.inventoryCheckReasons.40'),
    50: t('common.other'),
  },
  removedFromSalesInOrders: {
    100: t('shared.statuses.removedFromSalesInOrders.100'),
    600: t('shared.statuses.removedFromSalesInOrders.600'),
    700: t('shared.statuses.removedFromSalesInOrders.700'),
  },
  supplierApiStatus: {
    0: t('shared.statuses.supplierApiStatus.0'),
    1: t('shared.statuses.supplierApiStatus.1'),
    2: t('shared.statuses.supplierApiStatus.2'),
    3: t('shared.statuses.supplierApiStatus.3'),
  },
  transactionType: {
    1: t('shared.statuses.transactionType.1'),
    2: t('shared.statuses.transactionType.2'),
  },
  transactionServiceType: {
    1: t('shared.statuses.transactionServiceType.1'),
    2: t('shared.statuses.transactionServiceType.2'),
    3: t('shared.statuses.transactionServiceType.3'),
  },
  transactionOperationType: {
    1: t('shared.statuses.transactionOperationType.1'),
    2: t('shared.statuses.transactionOperationType.2'),
  },
  transactionStatus: {
    100: t('shared.statuses.transactionStatus.100'),
    200: t('shared.statuses.transactionStatus.200'),
    300: t('shared.statuses.transactionStatus.300'),
    400: t('shared.statuses.transactionStatus.400'),
    500: t('shared.statuses.transactionStatus.500'),
    600: t('shared.statuses.transactionStatus.600'),
  },
  changeLogErrorCode: {
    100: t('shared.statuses.changeLogErrorCode.100'), // TRANSFER_DEPARTED_WITH_MISSING_PRODUCTS: 100,
    200: t('shared.statuses.changeLogErrorCode.200'), // TRANSFER_DEPARTED_WITH_OVER_SENT_PRODUCTS: 200,
    300: t('shared.statuses.changeLogErrorCode.300'), // NON_AGREEMENT_TRANSFERS_CREATED: 300,
    400: t('shared.statuses.changeLogErrorCode.400'), // TRANSFER_UNCANCELLABLE: 400,
    500: t('shared.statuses.changeLogErrorCode.500'), // TRANSFER_DEPARTED_WITH_MISSING_SENT_AND_OVER_SENT_PRODUCTS: 500,
  },
};
