import { createHttpService } from '../createHttpService';
import { QueryBuilder } from '../QueryBuilder';

const WAREHOUSE_ID = localStorage.getItem('warehouseId');
const httpService = createHttpService();

export const outgoingTransfer = {
  getOutgoingTransferList: (query) => httpService.get(`/warehouse/${WAREHOUSE_ID}/transfers/outgoing?${QueryBuilder.getQueryStr(query)}`),
  getOutgoingTransferDetail: (transferId) => httpService.get(`/warehouse/${WAREHOUSE_ID}/transfers/${transferId}`),
  setOutgoingTransferShip: (transferId, waybills) => httpService.post(`/warehouse/${WAREHOUSE_ID}/transfers/${transferId}/ship`, { waybills }),
  createEmptyCarboyTransfer: (data) => httpService.post('/transfers/self-outgoing/empty-carboy', data),
  createCaseAndPalletTransfer: (data) => httpService.post('/transfers/self-outgoing/case-pallet', data),
  createSelfOutgoingTransferProductList: (filter) => httpService.post(`/warehouse/${WAREHOUSE_ID}/product-stock-and-locations`, filter),
};
