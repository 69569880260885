import { uniq } from 'lodash';
import i18n from './../../../../i18n';

export class DataTransform {
  static importData(importedData, productList, warehouseList) {
    const dataObj = DataTransform.transformDataToObject(importedData);
    const data = DataTransform.transformDataObjToArr(
      dataObj,
      productList,
      warehouseList,
    );
    return data;
  }

  static transformDataObjToArr(dataObj, productList, warehouseList) {
    const arr = [];
    for (const key in dataObj) {
      if (dataObj.hasOwnProperty(key)) {
        const warehouseObj = warehouseList.find((w) => {
          return w.id === key;
        });
        if (!warehouseObj) {
          throw new Error(
            i18n.t('pages.Operation.InventoryCheck.error.warehouseNotFound'),
          );
        }
        const element = {
          warehouse: warehouseObj,
          products: uniq(
            dataObj[key].products.map((pid) => {
              const productObj = productList.find((p) => p.id === pid);
              if (!productObj) {
                throw new Error(
                  i18n.t(
                    'pages.Operation.InventoryCheck.error.productNotFound',
                  ),
                );
              }
              return productObj;
            }),
          ),
        };
        arr.push(element);
      }
    }
    return arr;
  }

  static transformDataToObject(importedData) {
    const data = DataTransform.trim(importedData);
    console.log(data);
    const transformedData = {};
    if (data && data.length) {
      data.forEach((item) => {
        if (item[0] && transformedData[item[0]]) {
          transformedData[item[0]].products.push(item[1]);
        } else {
          transformedData[item[0]] = {
            products: [item[1]],
          };
        }
      });
      return transformedData;
    }
    return null;
  }

  static trim(importedData) {
    return DataTransform.removeCsvDataHeader(importedData).filter(
      (item) => item.length > 1,
    );
  }

  static removeCsvDataHeader(data) {
    data.shift();
    return data;
  }
}
