import React, { Component } from 'react';
import { Button, Table, Popconfirm, Icon } from 'antd';
/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/
import {
  ResizeableTitle,
  EditableCell,
  EditableFormRow,
} from './../../../../../components';

import i18n from './../../../../../i18n';

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class PurchaseOrderTable extends Component {
  components = {
    header: {
      cell: ResizeableTitle,
    },
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  };

  handleResize = (index) => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => {
    return {};
  };

  render() {
    return <this.TableComponent />;
  }

  TableComponent = (props) => {
    const columns = this.prepareColumns();
    return (
      <Table
        size="small"
        pagination={false}
        bordered
        rowClassName={() => 'editable-row'}
        components={this.components}
        dataSource={this.prepareData()}
        columns={columns}
        scroll={{ x: true }}
        rowKey={(record) => {
          return record.id;
        }}
      />
    );
  };

  prepareColumns = () => {
    const columns = [...this.tableOperationColumns(), ...this.tableColumns()];
    return columns;
  };

  tableOperationColumns = () => {
    const { dataSource, columns } = this.props;
    if (!columns || !columns.length || !dataSource || !dataSource.length) {
      return [];
    }
    return [
      {
        dataIndex: 'operation',
        width: 50,
        fixed: true,
        render: (text, record) => {
          if (!record.operation) {
            return null;
          }
          return <this.RowOperation record={record} />;
        },
      },
    ];
  };

  RowOperation = (props) => {
    const { record } = props;
    return (
      <Popconfirm
        title={i18n.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.deleteMessage',
        )}
        onConfirm={() => this.handleRowDelete(record.key)}
        placement="right"
      >
        <Icon
          type="delete"
          theme="filled"
          style={{ fontSize: '16px', color: '#f05050' }}
        />
      </Popconfirm>
    );
  };

  tableColumns = () => {
    const { columns } = this.props;
    if (!columns || !columns.length) {
      return [];
    }
    const mainColumns = columns.map((col, index) => {
      let transformCol = { ...col };
      if (col.editable) {
        transformCol = {
          ...col,
          onCell: (record) => {
            return {
              record,
              editable: col.editable,
              dataIndex: col.dataIndex,
              title: col.title,
              min: 0,
              handleCellSave: this.handleCellSave,
            };
          },
        };
        transformCol.filterIcon = (filtered) => (
          <Icon
            type="delete"
            theme="filled"
            style={{ fontSize: '10px', color: '#f05050' }}
          />
        );
        transformCol.filterDropdown = ({ clearFilters }) => {
          return (
            <div style={{ padding: 8 }}>
              <Button
                type="danger"
                onClick={() => {
                  this.columnDelete(transformCol);
                  clearFilters();
                }}
                size="small"
                style={{ width: 90, marginRight: 8 }}
              >
                {i18n.t(
                  'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.button.delete',
                )}
              </Button>
              <Button
                onClick={() => {
                  clearFilters();
                }}
                size="small"
                style={{ width: 90 }}
              >
                {i18n.t(
                  'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.button.cancel',
                )}
              </Button>
            </div>
          );
        };
      }
      return {
        ...transformCol,
        onHeaderCell: (column) => ({
          width: column.width,
          onResize: this.handleResize(index),
        }),
      };
    });
    return mainColumns;
  };

  prepareData = () => {
    const { dataSource } = this.props;
    if (!dataSource || !dataSource.length) {
      return [];
    }
    return dataSource;
  };

  handleRowDelete = (key) => {
    const dataSource = [...this.props.dataSource];
    this.props.onRowDelete(
      dataSource.filter((item) => item.key !== key),
      key,
    );
  };

  handleCellSave = (row) => {
    const previewData = [...this.props.dataSource];
    const preparedData = previewData.map((prepareItem) => {
      if (row.id === prepareItem.id) {
        const newRow = { ...prepareItem };
        // eslint-disable-next-line prefer-const
        for (let [key] of Object.entries(prepareItem.warehouses)) {
          if (row.warehouses[key] || row.warehouses[key] === 0) {
            newRow.warehouses[key] = row.warehouses[key];
          }
        }
        return newRow;
      }
      return prepareItem;
    });
    this.props.onCellSave(preparedData);
  };

  columnDelete = (column) => {
    const restCol = this.props.columns.filter((col) => {
      return col.key !== column.key;
    });

    const warehouseList = restCol
      .filter((item) => /^warehouse./.test(item.key))
      .map((item) => {
        const warehouseId = item.key.split('.')[1];
        return warehouseId;
      });

    this.props.dataSource.map((row) => {
      // eslint-disable-next-line prefer-const
      for (let [key] of Object.entries(row.warehouses)) {
        if (warehouseList.indexOf(key) === -1) {
          delete row.warehouses[key];
        }
      }
      return row;
    });

    this.props.onColumnDelete(restCol);
  };

  clearState = () => {
    this.setState(this.stateFactory());
  };

  getTransformedData = () => {
    const { dataSource } = this.props;
    return dataSource;
  };
}

const PurchaseOrderTableTranslated = PurchaseOrderTable;

export { PurchaseOrderTableTranslated as PurchaseOrderTable };
