import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
  publish: {
    loading: null,
    data: null,
    error: null,
  },
};

const switcher = {
  [actionType.getTransactionsList_InProgress]: getTransactionsList_InProgress,
  [actionType.getTransactionsList_Succeeded]: getTransactionsList_Succeeded,
  [actionType.getTransactionsList_Failed]: getTransactionsList_Failed,

  [actionType.publishTransactions_InProgress]: publishTransactions_InProgress,
  [actionType.publishTransactions_Succeeded]: publishTransactions_Succeeded,
  [actionType.publishTransactions_Failed]: publishTransactions_Failed,
};

export const transactionsReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getTransactionsList_InProgress(state) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function getTransactionsList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload,
      error: null,
      total: payload.total,
    },
  });
}

function getTransactionsList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function publishTransactions_InProgress(state) {
  return Object.assign({}, state, {
    publish: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function publishTransactions_Succeeded(state, payload) {
  return Object.assign({}, state, {
    publish: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function publishTransactions_Failed(state, payload) {
  return Object.assign({}, state, {
    publish: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}
