import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form, Button, Row, Col,
} from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import {
  WarehouseMultiSelect,
  DateRangeSelect,
  PoStatusMultiSelect,
  SupplierMultiSelect,
  TagMultiSelect,
} from './filterFields';
/*----------------------------------------------------------------------------*/
import { warehouseAction, supplierAction } from './../../../../actions';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class PurchaseOrderFilter extends Component {
  settings = {
    main: {
      className: 'WarehousePurchaseOrderListFilter_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    const { onGetWarehouseList, onGetSupplierList } = this.props;
    onGetWarehouseList();
    onGetSupplierList();
  }

  stateFactory = () => ({
    ...this.emptyTableFilter(),
  });

  emptyTableFilter = () => ({
    startDate: this.props.defaultDateRange.startDate,
    endDate: this.props.defaultDateRange.endDate,
    suppliers: [],
    warehouses: [this.props.currentWarehouse],
    warehousePurchaseOrdersNo: [],
    statuses: [],
  });

  render() {
    return (
      <div {...this.settings.main}>
        <this.TableFilterForm />
      </div>
    );
  }

  TableFilterForm = (props) => (
    <div>
      <Form>
        <Row>
          <Col span={5} style={{ padding: '0px 5px' }}>
            <this.DateRangeField />
          </Col>
          <Col span={6} style={{ padding: '0px 5px' }}>
            <this.SupplierField />
          </Col>
          <Col span={4} style={{ padding: '0px 5px' }}>
            <this.WarehouseField />
          </Col>
          <Col span={4} style={{ padding: '0px 5px' }}>
            <this.PoStatusSelect />
          </Col>
          <Col span={5} style={{ padding: '0px 5px' }}>
            <this.WpoNumberField />
          </Col>
        </Row>
        <Row>
          <Col span={2} style={{ padding: '0px 5px' }}>
            <this.ClearFilterButton />
          </Col>
          <Col offset={20} span={2} style={{ padding: '0px 5px' }}>
            <this.FilterButton />
          </Col>
        </Row>
      </Form>
    </div>
  );

  DateRangeField = () => (
    <Form.Item>
      <DateRangeSelect
        defaultDateRange={this.props.defaultDateRange}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        onChange={(dateRange) => {
          this.setState({
            ...this.state,
            ...dateRange,
          });
        }}
      />
    </Form.Item>
  );

  WarehouseField = () => {
    const { warehouseList } = this.props;
    const { warehouses } = this.state;
    const list = (warehouseList && warehouseList.data) || null;
    return (
      <Form.Item>
        <WarehouseMultiSelect
          list={list}
          disabled
          onChange={(warehouses) => {
            this.setState({
              warehouses,
            });
          }}
          value={warehouses}
          loading={warehouseList.loading}
          placeholder={this.props.t('pages.Stock.PurchaseOrder.list.filter.warehouse')}
        />
      </Form.Item>
    );
  };

  PoStatusSelect = () => {
    const { statuses } = this.state;
    return (
      <Form.Item>
        <PoStatusMultiSelect
          onChange={(statuses) => {
            this.setState({
              statuses,
            });
          }}
          placeholder={this.props.t('pages.Stock.PurchaseOrder.list.filter.status')}
          value={statuses}
        />
      </Form.Item>
    );
  };

  SupplierField = (props) => {
    const { supplierList } = this.props;
    const { suppliers } = this.state;
    return (
      <Form.Item>
        <SupplierMultiSelect
          value={suppliers}
          onChange={(suppliers) => {
            this.setState({
              suppliers,
            });
          }}
          placeholder={this.props.t('pages.Stock.PurchaseOrder.list.filter.supplier')}
          list={supplierList}
        />
      </Form.Item>
    );
  };

  WpoNumberField = (props) => {
    const { warehousePurchaseOrdersNo } = this.state;
    return (
      <Form.Item>
        <TagMultiSelect
          placeholder={this.props.t('pages.Stock.PurchaseOrder.list.filter.warehousePurchaseOrdersNo')}
          disabled={false}
          onChange={(warehousePurchaseOrdersNo) => {
            if (warehousePurchaseOrdersNo[0] === '') {
              this.setState({
                warehousePurchaseOrdersNo: [],
              });
              return;
            }

            this.setState({
              warehousePurchaseOrdersNo,
            });
          }}
          value={warehousePurchaseOrdersNo}
        />
      </Form.Item>
    );
  };

  FilterButton = () => (
    <Form.Item>
      <Button
        type="primary"
        block
        onClick={() => {
          const filter = this.getTableFilter();
          this.filterChanged(filter);
        }}
      >
        {this.props.t('pages.Stock.PurchaseOrder.list.filter.filter')}
      </Button>
    </Form.Item>
  );

  ClearFilterButton = () => (
    <Form.Item>
      <Button
        block
        onClick={() => {
          this.clearFilter();
        }}
      >
        {this.props.t('pages.Stock.PurchaseOrder.list.filter.clean')}
      </Button>
    </Form.Item>
  );

  getTableFilter = () => {
    const {
      suppliers,
      warehouses,
      statuses,
      warehousePurchaseOrdersNo,
      startDate,
      endDate,
    } = this.state;

    const filter = {};

    filter.createdAt = {
      startDate: startDate || this.props.defaultDateRange.startDate,
      endDate: endDate || this.props.defaultDateRange.endDate,
    };

    if (warehouses && warehouses.length) {
      filter.warehouses = warehouses.map((warehouse) => warehouse.id);
    }
    if (statuses && statuses.length) {
      filter.statuses = statuses.map((statuse) => statuse.key);
    }
    if (suppliers && suppliers.length) {
      filter.suppliers = suppliers.map((supplier) => supplier._id);
    }
    if (warehousePurchaseOrdersNo && warehousePurchaseOrdersNo.length) {
      filter.warehousePurchaseOrdersNo = warehousePurchaseOrdersNo;
    }

    return filter;
  };

  filterChanged = (filter) => {
    this.props.onChange(filter);
  };

  clearFilter = () => {
    this.setState({ ...this.state, ...this.stateFactory() }, () => {
      this.props.onClear(this.getTableFilter());
    });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse.id,
  currentWarehouse: state.auth.currentWarehouse,
  warehouseList: state.warehouse.warehouseList,
  productList: state.product.productList,
  reasonList: state.blockedStock.reasonList,
  supplierList: state.supplier.list.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetWarehouseList: () => {
    dispatch(warehouseAction.getWarehouseList());
  },
  onGetSupplierList: () => {
    dispatch(supplierAction.getSupplierList());
  },
});

const PurchaseOrderFilterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(PurchaseOrderFilter));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { PurchaseOrderFilterConnected as PurchaseOrderFilter };
