
import { uniqBy } from 'lodash';

import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  warehouseList: {
    loading: true,
    data: null,
    error: null,
  },
  availableStockOfLocations: {
    loading: true,
    data: null,
    error: null,
  },
  expiringProducts: {
    loading: true,
    data: null,
    error: null,
  },
};

const switcher = {
  [actionType.getWarehouseList_InProgress]: getWarehouseList_InProgress,
  [actionType.getWarehouseList_Succeeded]: getWarehouseList_Succeeded,
  [actionType.getWarehouseList_Failed]: getWarehouseList_Failed,

  [actionType.getExpiringProducts_InProgress]: getExpiringProducts_InProgress,
  [actionType.getExpiringProducts_Succeeded]: getExpiringProducts_Succeeded,
  [actionType.getExpiringProducts_Failed]: getExpiringProducts_Failed,

  [actionType.getAvailableStockOfLocationsByWarehouseId_InProgress]: getAvailableStockOfLocationsByWarehouseId_InProgress,
  [actionType.getAvailableStockOfLocationsByWarehouseId_Succeeded]: getAvailableStockOfLocationsByWarehouseId_Succeeded,
  [actionType.getAvailableStockOfLocationsByWarehouseId_Failed]: getAvailableStockOfLocationsByWarehouseId_Failed,
};

export const warehouseReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getWarehouseList_InProgress(state, payload) {
  return Object.assign({}, state, {
    warehouseList: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getWarehouseList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    warehouseList: {
      loading: false,
      data: uniqBy(
        payload.filter((item) => item.name),
        'name',
      ),
      error: null,
    },
  });
}

function getWarehouseList_Failed(state, payload) {
  return Object.assign({}, state, {
    warehouseList: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getExpiringProducts_InProgress(state, payload) {
  return Object.assign({}, state, {
    expiringProducts: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getExpiringProducts_Succeeded(state, payload) {
  return Object.assign({}, state, {
    expiringProducts: {
      loading: false,
      data: payload,
      error: null,
      count: payload.length || 0,
    },
  });
}

function getExpiringProducts_Failed(state, payload) {
  return Object.assign({}, state, {
    expiringProducts: {
      loading: false,
      data: null,
      error: payload,
      count: 0,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/
function getAvailableStockOfLocationsByWarehouseId_InProgress(state, payload) {
  return Object.assign({}, state, {
    availableStockOfLocations: {
      loading: true,
      data: null,
      error: null,
    },
  });
}
function getAvailableStockOfLocationsByWarehouseId_Succeeded(state, payload) {
  return Object.assign({}, state, {
    availableStockOfLocations: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}
function getAvailableStockOfLocationsByWarehouseId_Failed(state, payload) {
  return Object.assign({}, state, {
    availableStockOfLocations: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}
