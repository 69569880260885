import moment from 'moment/moment';
import i18n from '../i18n';

export const msToString = ({
  duration,
  shouldAddSecond = false,
  isAbbreviationText = true,
}) => {
  const momentDuration = moment.duration(duration);
  let durationText = '';

  if (momentDuration.years() > 0) {
    durationText += isAbbreviationText
      ? `${momentDuration.years()} ${i18n.t('shared.DATE.ABBR.YEAR')} `
      : `${momentDuration.years()} ${i18n.t('shared.DATE.FULL.YEAR')} `;
  }
  if (momentDuration.months() > 0) {
    durationText += isAbbreviationText
      ? `${momentDuration.months()} ${i18n.t('shared.DATE.ABBR.MONTH')} `
      : `${momentDuration.months()} ${i18n.t('shared.DATE.FULL.MONTH')} `;
  }
  if (momentDuration.days() > 0) {
    durationText += isAbbreviationText
      ? `${momentDuration.days()} ${i18n.t('shared.DATE.ABBR.DAY')} `
      : `${momentDuration.days()} ${i18n.t('shared.DATE.FULL.DAY')} `;
  }
  if (momentDuration.hours() > 0) {
    durationText += isAbbreviationText
      ? `${momentDuration.hours()} ${i18n.t('shared.DATE.ABBR.HOUR')} `
      : `${momentDuration.hours()} ${i18n.t('shared.DATE.FULL.HOUR')} `;
  }
  if (parseInt(momentDuration.minutes().toFixed(0), 10)) {
    durationText += isAbbreviationText
      ? `${momentDuration.minutes().toFixed(0)} ${i18n.t(
        'shared.DATE.ABBR.MINUTE',
      )} `
      : `${momentDuration.minutes().toFixed(0)} ${i18n.t(
        'shared.DATE.FULL.MINUTE',
      )} `;
  }
  if (shouldAddSecond && momentDuration.seconds().toFixed(0)) {
    durationText += isAbbreviationText
      ? `${momentDuration.seconds().toFixed(0)} ${i18n.t(
        'shared.DATE.ABBR.SECOND',
      )} `
      : `${momentDuration.seconds().toFixed(0)} ${i18n.t(
        'shared.DATE.FULL.SECOND',
      )} `;
  }
  return durationText.trimEnd();
};

export const fromNow = ({
  time,
  shouldAddSecond = false,
  isAbbreviationText = true,
}) => {
  const duration = moment.duration(moment().diff(time));
  return msToString({ duration, shouldAddSecond, isAbbreviationText });
};
