import { createHttpService } from '../createHttpService';

const httpService = createHttpService();

export const dincerConsumer = {
  centralWarehouseOutgoingTransfer: (transferId, domainType) =>
    httpService.post('/dincer-consumers/central-warehouse-outgoing-transfer', {
      transferId,
      domainType,
    }),
  centralWarehouseIncomingTransfer: (transferId, domainType) =>
    httpService.post('/dincer-consumers/central-warehouse-incoming-transfer', {
      transferId,
      domainType,
    }),
  sendWarehousePurchaseOrders: (warehousePurchaseOrders) =>
    httpService.post('/dincer-consumers/send-warehouse-purchase-orders', {
      warehousePurchaseOrders,
    }),
  centralWarehouseIncomingBlockedStockTransfer: (transferId) =>
    httpService.post(
      '/dincer-consumers/central-warehouse-incoming-blocked-stock-transfer',
      { transferId },
    ),
};
