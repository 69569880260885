import React from 'react';
import i18n from '../../i18n';

export class ErrorInfo {
  static message(title) {
    return (
      <div style={{ fontWeight: 'bold' }}>{ErrorInfo.convertTitle(title)}</div>
    );
  }

  static description(error) {
    let message = '';
    let description = '';
    const errorCode =
      error && error.response && error.response.data
        ? error.response.data.code
        : null;
    if (error) {
      message = error.message || 'Common error';
      try {
        description = error.response.data.message;
      } catch (e) {
        description = 'Check internet connection';
      }
    }
    return (
      <div>
        <div style={{ color: 'red' }}>{message}</div>
        {errorCode && (
          <div>
            {i18n.t('errorCode')} : {errorCode}
          </div>
        )}
        <div>{description}</div>
      </div>
    );
  }

  static convertTitle(text) {
    let title = text;
    title = title.replace('_', ' ');
    const regExp = /[A-Z][a-z]+/gm;
    const resMatch = title.match(regExp);
    const resExec = regExp.exec(title);
    return [text.slice(0, resExec.index), ...resMatch]
      .join(' ')
      .toLowerCase()
      .split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }
}
