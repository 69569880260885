/* eslint-disable react/prefer-stateless-function */
import React from 'react';
// --------------------------------------------------
import './OrderListHeader.scss';
// --------------------------------------------------
// --------------------------------------------------

export class OrderListHeader extends React.Component {
  render() {
    const { title, count } = this.props;
    return (
      <div className="OrderListHeader_inner">
        <h3 className="header-count">{count}</h3>
        <h3 className="header-title">{title}</h3>
      </div>
    );
  }
}
