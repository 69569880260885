import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Icon, Tooltip } from 'antd';
import { constants } from '../../../../shared/constants';
import './NextStep.scss';

const { DIRECTION_TYPES, TRANSFER_STATUS } = constants;
export const NextStepDomainTypes = {
  TRANSFER: 1,
  WPO: 2,
  PO: 3,
};

const NextStep = ({ directionType, transferStatus, domainType, t }) => {
  console.log(directionType, transferStatus);
  // this logic is based on https://getirdev.atlassian.net/browse/SR-978
  let nextStepTooltip = '--';
  const nextStepMessage = t('shared.nextStep.dincerAction');

  const isOrderedPONextStepELigible = (domainType === NextStepDomainTypes.WPO || domainType === NextStepDomainTypes.PO)
    && transferStatus === TRANSFER_STATUS.APPROVED;

  const isShippedTransferNextStepEligible = (directionType === DIRECTION_TYPES.WAREHOUSE_TO_CENTRAL || directionType === DIRECTION_TYPES.CENTRAL_TO_CENTRAL)
    && transferStatus === TRANSFER_STATUS.SHIPPED;

  if (isShippedTransferNextStepEligible) {
    nextStepTooltip = t('shared.nextStep.ShippedTransferNextStep');
  } else if (isOrderedPONextStepELigible) {
    nextStepTooltip = domainType === NextStepDomainTypes.PO ? t('shared.nextStep.OrderPONextStep') : t('shared.nextStep.OrderWPONextStep');
  }
  return (
    (isOrderedPONextStepELigible || isShippedTransferNextStepEligible) ?
    <Tooltip title={nextStepTooltip} className="nextStep">
      <Icon type="info-circle" className="icon nextStepIcon" />
      <span> {nextStepMessage}</span>
    </Tooltip> : '-'
  );
};

NextStep.defaultProps = {
  directionType: null,
};

NextStep.propTypes = {
  // eslint-disable react/require-default-props
  directionType: PropTypes.instanceOf(DIRECTION_TYPES),
  transferStatus: PropTypes.instanceOf(TRANSFER_STATUS).isRequired,
  domainType: PropTypes.instanceOf(NextStepDomainTypes).isRequired,
};

const NextStepTranslated = withNamespaces('translation')(
  NextStep,
);

export { NextStepTranslated as NextStep };

// export default NextStep;
