/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Descriptions,
  Button,
  Table,
  Tag,
  Modal,
  Dropdown,
  Icon,
  Menu,
  Progress,
  Row,
  Col,
  Divider,
  Avatar,
} from 'antd';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import { uniqBy } from 'lodash';
import uniqid from 'uniqid';
/*----------------------------------------------------------------------------*/
import './InventoryCheckDetail.scss';
import { InventoryCheckDetailFilter } from './InventoryCheckDetailFilter';
/*----------------------------------------------------------------------------*/
import {
  Spinner,
  CopyToClipboard,
  ExportToExcel,
  ActionTimeline,
} from './../../../../components';
import { inventoryAction } from './../../../../actions';
import { tableHelper, constants, getTranslation, getDateTimeFormatString } from './../../../../shared';
import { pageLoadEvents } from '../../../../services/events';
import { pageView } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/
const {
  INVENTORY_CHECK_STATUS,
  JOB_TASK_STATUSE,
  INVENTORY_CHECK_TYPE,
} = constants;
const { confirm } = Modal;

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class InventoryCheckDetail extends Component {
  settings = {
    main: {
      className: 'InventoryCheckDetail_main',
    },
    card: {
      title: this.props.t('pages.Operation.InventoryCheck.detail.title'),
      className: 'no-padding-card',
      size: 'small',
    },
    text: {
      className: 'blink-me',
      color: '#D9534F',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_STOCK_CONTROL_DETAIL });
    this.getDetail();
  }

  stateFactory = () => {
    return {
      isClickedPrepareInventoryCheckButton: false,
      isClickedDeleteInventoryCheckButton: false,
      isClickedTerminateInventoryCheckButton: false,
      isClickedIncludeToInventoryCheckButton: false,
      isClickedRemoveFromInventoryCheckButton: false,
      filter: null,
    };
  };

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {loading ? <Spinner /> : <this.PageDetail />}
        </Card>
      </div>
    );
  }

  CardExtra = () => {
    return (
      <Dropdown overlay={this.MenuAction}>
        <Button style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.t('pages.Operation.InventoryCheck.detail.extra.actions')}{' '}
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  MenuAction = () => {
    return (
      <Menu>
        <Menu.Item key="DeleteButton">
          <this.DeleteButton />
        </Menu.Item>
        <Menu.Item key="TerminateButton">
          <this.TerminateButton />
        </Menu.Item>
        <Menu.Item key="SetReadyButton">
          <this.SetReadyButton />
        </Menu.Item>
        <Menu.Item key="IncludeToSTSButton">
          <this.IncludeToSTSButton />
        </Menu.Item>
        <Menu.Item key="RemoveFromSTSButton">
          <this.RemoveFromSTSButton />
        </Menu.Item>
        <Menu.Item key="ExportButton">
          <this.ExportButton />
        </Menu.Item>
      </Menu>
    );
  };

  ExportButton = () => {
    const data = this.prepareDataForExport();
    return (
      <ExportToExcel
        local
        action={this.props.onExportToXlsxInventoryCheck}
        fileName={this.getFileName()}
        data={data}
        loading={this.props.isExporting}
        disabled={this.props.isExporting || !data || !data.length}
      />
    );
  };

  getFileName = () => {
    const { inventoryCheckDetail } = this.props;
    if (
      !inventoryCheckDetail ||
      !inventoryCheckDetail.data ||
      !inventoryCheckDetail.data.id
    ) {
      return `${this.props.t(
        'pages.Operation.InventoryCheck.detail.exportFileName',
      )}`;
    }
    return `${this.props.t(
      'pages.Operation.InventoryCheck.detail.exportFileName',
    )} ${inventoryCheckDetail.data.id}`;
  };

  prepareDataForExport = () => {
    const { inventoryCheckDetail } = this.props;
    if (
      !inventoryCheckDetail ||
      !inventoryCheckDetail.data ||
      !inventoryCheckDetail.data.job ||
      !inventoryCheckDetail.data.job.tasks ||
      !inventoryCheckDetail.data.job.tasks.length
    ) {
      return null;
    }
    const data = inventoryCheckDetail.data.job.tasks;

    const transformed = [];
    let records = [];
    data.forEach((task) => {
      try {
        records = [];
        if (task.data.products) {
          task.data.products.map((p) => {
            const preparedCsvData = {
              key: p.productId + uniqid(),
              [this.props.t(
                'pages.Operation.InventoryCheck.detail.exportTable.inventoryCheckId',
              )]: inventoryCheckDetail.data.id,
              [this.props.t(
                'pages.Operation.InventoryCheck.detail.exportTable.createdAt',
              )]: moment(inventoryCheckDetail.data.createdAt).isValid()
                ? moment(new Date(inventoryCheckDetail.data.createdAt))
                    .local()
                    .format(getDateTimeFormatString())
                : '-',
              [this.props.t(
                'pages.Operation.InventoryCheck.detail.exportTable.warehouse',
              )]: inventoryCheckDetail.data.warehouse.name,
              [this.props.t(
                'pages.Operation.InventoryCheck.detail.exportTable.barcode',
              )]: p.barcodes.join(' - '),
              [this.props.t(
                'pages.Operation.InventoryCheck.detail.exportTable.productId',
              )]: p.productId,
              [this.props.t(
                'pages.Operation.InventoryCheck.detail.exportTable.product',
              )]: getTranslation.obj(p.fullName),
              [this.props.t(
                'pages.Operation.InventoryCheck.detail.exportTable.assignee',
              )]: (task.assignee && task.assignee.name) || task.assignee || '-',
            };

            if (inventoryCheckDetail.data.type === 100) {
              const locationBarcode =
                task.data.locations && task.data.locations.length
                  ? task.data.locations[0].barcode
                  : undefined;
              preparedCsvData[
                this.props.t(
                  'pages.Operation.InventoryCheck.detail.exportTable.location',
                )
              ] = locationBarcode || '-';
            }

            if (
              inventoryCheckDetail.data.isChildInventoryCheck ||
              inventoryCheckDetail.data.scope ===
                constants.INVENTORY_CHECK_SCOPE.GENERAL
            ) {
              preparedCsvData[
                this.props.t(
                  'pages.Operation.InventoryCheck.detail.exportTable.countBeforeInventoryCheck',
                )
              ] = p.beforeInconsistencyFix
                ? (p.beforeInconsistencyFix.frozen || 0) +
                  (p.beforeInconsistencyFix.available || 0)
                : '-';
            }

            preparedCsvData[
              this.props.t(
                'pages.Operation.InventoryCheck.detail.exportTable.initialCount',
              )
            ] = p.initialCount || 0;
            preparedCsvData[
              this.props.t(
                'pages.Operation.InventoryCheck.detail.exportTable.formerFinalCount',
              )
            ] = p.formerFinalCount || '-';
            preparedCsvData[
              this.props.t(
                'pages.Operation.InventoryCheck.detail.exportTable.finalCount',
              )
            ] = p.finalCount || 0;
            preparedCsvData[
              this.props.t(
                'pages.Operation.InventoryCheck.detail.exportTable.assignedAt',
              )
            ] = task.assignedAt || '-';
            preparedCsvData[
              this.props.t(
                'pages.Operation.InventoryCheck.detail.exportTable.completedAt',
              )
            ] = task.completedAt || '-';

            records.push(preparedCsvData);
          });
        }
      } catch (error) {
        console.log('error', error);
        records = [];
      }
      transformed.push(...records);
    });

    return uniqBy(transformed, 'key')
      .filter((i) => i)
      .map((i) => {
        return { ...i, ...delete i.key };
      });
  };

  DeleteButton = (props) => {
    const { isClickedDeleteInventoryCheckButton } = this.state;

    return (
      <Button
        type="danger"
        loading={isClickedDeleteInventoryCheckButton}
        disabled={
          !this.isDeleteButtonShow() || isClickedDeleteInventoryCheckButton
        }
        block
        onClick={() => {
          this.setState({ isClickedDeleteInventoryCheckButton: true });
          this.deleteInventoryCheck();
        }}
      >
        {this.props.t(
          'pages.Operation.InventoryCheck.detail.extra.deleteButton',
        )}
      </Button>
    );
  };

  isDeleteButtonShow = () => {
    const { inventoryCheckDetail } = this.props;
    if (
      !inventoryCheckDetail ||
      !inventoryCheckDetail.data ||
      !inventoryCheckDetail.data.status
    ) {
      return false;
    }
    const { status } = inventoryCheckDetail.data;
    if (
      status === INVENTORY_CHECK_STATUS.NEW ||
      status === INVENTORY_CHECK_STATUS.READY
    ) {
      return true;
    } else {
      return false;
    }
  };

  SetReadyButton = (props) => {
    const { isClickedPrepareInventoryCheckButton } = this.state;

    return (
      <Button
        type="danger"
        disabled={
          !this.isInventoryCheckStatusNew() ||
          isClickedPrepareInventoryCheckButton
        }
        loading={isClickedPrepareInventoryCheckButton}
        onClick={() => {
          this.setState({ isClickedPrepareInventoryCheckButton: true });
          this.setItemReady();
        }}
        block
      >
        {this.props.t(
          'pages.Operation.InventoryCheck.detail.extra.setReadyButton',
        )}
      </Button>
    );
  };

  TerminateButton = () => {
    const { isClickedTerminateInventoryCheckButton } = this.state;
    return (
      <Button
        block
        disabled={
          !this.couldInventoryCheckTerminate() ||
          isClickedTerminateInventoryCheckButton
        }
        loading={isClickedTerminateInventoryCheckButton}
        onClick={() => {
          console.log('terminate');
          this.setState({ isClickedTerminateInventoryCheckButton: true });
          this.terminateInventoryCheck();
        }}
      >
        {this.props.t(
          'pages.Operation.InventoryCheck.detail.extra.terminateButton',
        )}
      </Button>
    );
  };

  IncludeToSTSButton = (props) => {
    const { isClickedIncludeToInventoryCheckButton } = this.state;
    return (
      <Button
        type="danger"
        disabled={
          this.isIncludeToSTSButtonDisabled() ||
          isClickedIncludeToInventoryCheckButton
        }
        loading={isClickedIncludeToInventoryCheckButton}
        onClick={() => {
          this.setState({ isClickedIncludeToInventoryCheckButton: true });
          this.includeToSTS();
        }}
        block
      >
        {this.props.t(
          'pages.Operation.InventoryCheck.detail.extra.includeToSTSButton',
        )}
      </Button>
    );
  };

  RemoveFromSTSButton = (props) => {
    const { isClickedRemoveFromInventoryCheckButton } = this.state;

    return (
      <Button
        type="danger"
        disabled={
          this.isRemoveFromSTSButtonDisabled() ||
          isClickedRemoveFromInventoryCheckButton
        }
        loading={isClickedRemoveFromInventoryCheckButton}
        onClick={() => {
          this.setState({ isClickedRemoveFromInventoryCheckButton: true });
          this.removeFromSTS();
        }}
        block
      >
        {this.props.t(
          'pages.Operation.InventoryCheck.detail.extra.removeFromSTSButton',
        )}
      </Button>
    );
  };

  isIncludeToSTSButtonDisabled = () => {
    const { inventoryCheckDetail } = this.props;
    console.log(inventoryCheckDetail);
    if (!inventoryCheckDetail || !inventoryCheckDetail.data) {
      return true;
    }
    if (!inventoryCheckDetail.data.isIncludedToSTS) {
      return false;
    }
    return true;
  };

  isRemoveFromSTSButtonDisabled = () => {
    const { inventoryCheckDetail } = this.props;
    if (!inventoryCheckDetail || !inventoryCheckDetail.data) {
      return true;
    }
    if (inventoryCheckDetail.data.isIncludedToSTS) {
      return false;
    }
    return true;
  };

  couldInventoryCheckTerminate = () => {
    const { inventoryCheckDetail } = this.props;
    if (
      !inventoryCheckDetail ||
      !inventoryCheckDetail.data ||
      !inventoryCheckDetail.data.status
    ) {
      return false;
    }
    const { status, job } = inventoryCheckDetail.data;
    if (
      status === INVENTORY_CHECK_STATUS.ONGOING &&
      job &&
      job.status === INVENTORY_CHECK_STATUS.ONGOING &&
      job.tasks &&
      job.tasks.length
    ) {
      const flag = job.tasks.reduce((acc, item) => {
        return acc && item.status !== INVENTORY_CHECK_STATUS.ONGOING;
      }, true);
      return flag;
    } else {
      return false;
    }
  };

  isInventoryCheckStatusNew = () => {
    const { inventoryCheckDetail } = this.props;
    if (
      !inventoryCheckDetail ||
      !inventoryCheckDetail.data ||
      !inventoryCheckDetail.data.status
    ) {
      return false;
    }
    const { status } = inventoryCheckDetail.data;
    if (status === INVENTORY_CHECK_STATUS.NEW) {
      return true;
    } else {
      return false;
    }
  };

  PageDetail = (props) => {
    return (
      <div>
        <Row>
          <Col span={14}>
            <this.InventoryCheckDescription />
          </Col>
          <Col span={10}>
            <this.InventoryCheckCompletionRate />
          </Col>
        </Row>
        {this.isInventoryCheckStatusNew() ? null : (
          <>
            <InventoryCheckDetailFilter
              onChange={(filter) => {
                console.log(filter);
                this.setState({ filter });
              }}
              onClear={(filter) => {
                console.log(filter);
                this.setState({ filter: null });
              }}
            />
            <this.InventoryCheckTaskTable />
          </>
        )}
        <ActionTimeline
          actions={this.props.inventoryCheckDetail.data}
          translate={getTranslation.inventoryCheckStatus}
        />
      </div>
    );
  };

  InventoryCheckDescription = (props) => {
    const { inventoryCheckDetail } = this.props;
    if (
      !inventoryCheckDetail ||
      !inventoryCheckDetail.data ||
      !inventoryCheckDetail.data.job
    ) {
      return null;
    }
    return (
      <Descriptions column={2}>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption._id',
          )}
        >
          <CopyToClipboard message={inventoryCheckDetail.data.id} />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.warehouse',
          )}
        >
          <CopyToClipboard
            message={inventoryCheckDetail.data.warehouse.id}
            inner={inventoryCheckDetail.data.warehouse.name}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.owner',
          )}
        >
          <CopyToClipboard message={inventoryCheckDetail.data.job.id} />
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.type',
          )}
        >
          {getTranslation.inventoryCheckType(inventoryCheckDetail.data.type)}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.scope',
          )}
        >
          {getTranslation.inventoryCheckScope(inventoryCheckDetail.data.scope)}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.handover',
          )}
        >
          {inventoryCheckDetail.data.handover ? (
            <Tag color="#5cb85c">
              {this.props.t(
                'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.enable',
              )}
            </Tag>
          ) : (
            <Tag color="#d9534f">
              {this.props.t(
                'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.disable',
              )}
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.type',
          )}
        >
          {getTranslation.inventoryCheckJobType(
            inventoryCheckDetail.data.job.type.key,
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.status',
          )}
        >
          {getTranslation.inventoryCheckStatus(
            inventoryCheckDetail.data.status,
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.expiryDate.isExpiryDateRequired',
          )}
        >
          {inventoryCheckDetail.data.expiryDateRequired ? (
            <Tag color="#5cb85c">
              {this.props.t(
                'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.enable',
              )}
            </Tag>
          ) : (
            <Tag color="#d9534f">
              {this.props.t(
                'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.disable',
              )}
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.createdBy',
          )}
        >
          <CopyToClipboard
            message={
              inventoryCheckDetail.data.createdBy
                ? inventoryCheckDetail.data.createdBy.id
                : ''
            }
            inner={
              inventoryCheckDetail.data.createdBy
                ? inventoryCheckDetail.data.createdBy.name
                : ''
            }
            color="blue"
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.createdAt',
          )}
        >
          {moment(new Date(inventoryCheckDetail.data.createdAt))
            .local()
            .format(getDateTimeFormatString())}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.autoInventoryCheck',
          )}
        >
          {inventoryCheckDetail.data.isAutoInventoryCheck ? (
            <Tag color="#5cb85c">
              {this.props.t(
                'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.enable',
              )}
            </Tag>
          ) : (
            <Tag color="#d9534f">
              {this.props.t(
                'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.disable',
              )}
            </Tag>
          )}
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.isIncludedToSTS',
          )}
        >
          {inventoryCheckDetail.data.isIncludedToSTS ? (
            <Tag color="#5cb85c">
              {this.props.t(
                'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.enable',
              )}
            </Tag>
          ) : (
            <Tag color="#d9534f">
              {this.props.t(
                'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.disable',
              )}
            </Tag>
          )}
        </Descriptions.Item>
        {inventoryCheckDetail.data.isChildInventoryCheck ? (
          <Descriptions.Item
            label={this.props.t(
              'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.parent',
            )}
          >
            <Link
              target="_blank"
              to={`/stock-operation/inventory-check/detail/${inventoryCheckDetail.data.parentInventoryCheck}`}
            >
              {this.props.t('pages.Operation.InventoryCheck.detail.link')}
            </Link>
          </Descriptions.Item>
        ) : null}
        {inventoryCheckDetail.data.childInventoryCheck ? (
          <Descriptions.Item
            label={this.props.t(
              'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.child',
            )}
          >
            <Link
              target="_blank"
              to={`/stock-operation/inventory-check/detail/${inventoryCheckDetail.data.childInventoryCheck}`}
            >
              {this.props.t('pages.Operation.InventoryCheck.detail.link')}
            </Link>
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item label={this.props.t('common.reason')}>
          {this.inventoryCheckReasonDescription(
            inventoryCheckDetail.data.reason,
          )}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  inventoryCheckReasonDescription = (reason) => {
    if (!reason) {
      return '-';
    }
    return reason.type === constants.INVENTORY_CHECK_REASONS.OTHER
      ? reason.description
      : getTranslation.inventoryCheckReason(reason.type);
  };

  InventoryCheckCompletionRate = (props) => {
    const { inventoryCheckDetail } = this.props;
    if (
      !inventoryCheckDetail ||
      !inventoryCheckDetail.data ||
      !inventoryCheckDetail.data.job
    ) {
      return null;
    }
    return (
      <Descriptions column={1}>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.isChild',
          )}
        >
          {inventoryCheckDetail.data.isChildInventoryCheck ? (
            <Tag color="#5cb85c">
              {this.props.t(
                'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.enable',
              )}
            </Tag>
          ) : (
            <Tag color="#d9534f">
              {this.props.t(
                'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.disable',
              )}
            </Tag>
          )}
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckDesctiption.completionRate',
          )}
        >
          <div>
            <Progress
              type="circle"
              width={145}
              percent={inventoryCheckDetail.data.completionPercentage}
              format={(percent) =>
                `${inventoryCheckDetail.data.completedTasksCount
                }/${inventoryCheckDetail.data.taskCount}`
              }
            />
          </div>
        </Descriptions.Item>
      </Descriptions>
    );
  };

  InventoryCheckTaskTable = (props) => {
    const { inventoryCheckDetail } = this.props;
    if (
      !inventoryCheckDetail ||
      !inventoryCheckDetail.data ||
      !inventoryCheckDetail.data.job ||
      !inventoryCheckDetail.data.job.tasks ||
      !inventoryCheckDetail.data.job.tasks.length
    ) {
      return null;
    }
    return (
      <Table
        size="small"
        columns={this.prepareColumns()}
        dataSource={this.prepareData()}
        rowKey={(record) => {
          return record.id;
        }}
        expandedRowRender={
          this.isLocationBased()
            ? (record, index, indent, expanded) => {
                const data = this.prepareProductData(record);
                if (!data || !data.length) {
                  return null;
                }
                return (
                  <Table
                    size="small"
                    columns={this.prepareProductColumns(record)}
                    dataSource={data}
                    rowKey={(record) => {
                      return record.productId;
                    }}
                  />
                );
              }
            : null
        }
      />
    );
  };

  isLocationBased = () => {
    const { inventoryCheckDetail } = this.props;
    if (
      !inventoryCheckDetail ||
      !inventoryCheckDetail.data ||
      !inventoryCheckDetail.data.type
    ) {
      return null;
    }
    return (
      inventoryCheckDetail.data.type === INVENTORY_CHECK_TYPE.LOCATION_BASED
    );
  };

  prepareColumns = (props) => {
    if (this.isLocationBased()) {
      return [
        {
          title: this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable._id',
          ),
          dataIndex: 'id',
          key: 'id',
          render: (id) => (
            <CopyToClipboard message={id} inner={<Icon type="copy" />} />
          ),
          ...tableHelper.fieldSorter.getStringSortProps('id'),
        },
        {
          title: this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable.location',
          ),
          dataIndex: 'data.locations',
          key: 'locations',
          render: (locations) => {
            return locations && locations.length
              ? locations.map((location) => {
                  return (
                    <CopyToClipboard
                      key={location.locationId}
                      message={location.barcode.toUpperCase()}
                      color="red"
                      style={{ margin: 2 }}
                    />
                  );
                })
              : '-';
          },
        },
        {
          title: this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable.status',
          ),
          dataIndex: 'status',
          key: 'status',
          ...tableHelper.fieldSorter.getNumberSortProps('status'),
          render: (code) => {
            return getTranslation.jobTaskStatus(code);
          },
        },
        {
          title: this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable.createdAt',
          ),
          dataIndex: 'createdAt',
          key: 'createdAt',
          ...tableHelper.fieldSorter.getDateSortProps('createdAt'),
          render: (createdAt) => {
            return moment(new Date(createdAt))
              .local()
              .format(getDateTimeFormatString());
          },
        },
        {
          key: 'detail',
          dataIndex: 'id',
          render: (id) => <this.DetailTaskButton id={id} />,
        },
      ];
    }
    return [
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable._id',
        ),
        dataIndex: 'id',
        key: 'id',
        render: (id) => (
          <CopyToClipboard message={id} inner={<Icon type="copy" />} />
        ),
        ...tableHelper.fieldSorter.getStringSortProps('id'),
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable.productImage',
        ),
        dataIndex: 'data.products',
        key: 'products.image',
        render: (products) => {
          return products && products.length
            ? products.map((p, index) => (
              <div key={index}>
                {index !== 0 ? (
                  <Divider dashed="true" style={{ margin: 2 }} />
                  ) : null}
                <Avatar
                  className="product-image"
                  shape="square"
                  src={
                      getTranslation.obj(p.picURL)
                        ? getTranslation.obj(p.picURL)
                        : null
                    }
                />
              </div>
              ))
            : '-';
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable.productId',
        ),
        dataIndex: 'data.products',
        key: 'products.id',
        width: 70,
        render: (products) => {
          return products && products.length
            ? products.map((p, index) => (
              <div key={p.productId}>
                {index !== 0 ? (
                  <Divider dashed="true" style={{ margin: 2 }} />
                  ) : null}
                <CopyToClipboard
                  key={getTranslation.obj(p.productId)}
                  message={getTranslation.obj(p.productId)}
                  style={{ margin: 2 }}
                  inner={<Icon type="copy" />}
                />
              </div>
              ))
            : '-';
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable.productName',
        ),
        dataIndex: 'data.products',
        key: 'products.name',
        render: (products) => {
          return products && products.length
            ? products.map((p, index) => (
              <div key={index}>
                {index !== 0 ? (
                  <Divider dashed="true" style={{ margin: 2 }} />
                  ) : null}
                <CopyToClipboard
                  key={getTranslation.obj(p.fullName)}
                  message={getTranslation.obj(p.fullName)}
                  style={{ margin: 2 }}
                />
              </div>
              ))
            : '-';
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable.productBarcode',
        ),
        dataIndex: 'data.products',
        key: 'products.barcode',
        render: (products) => {
          return products && products.length
            ? products.map((p, index) => {
                if (!p.barcodes || !p.barcodes.length) {
                  return (
                    <Tag color="blue" style={{ margin: 2 }}>
                      {'-'}
                    </Tag>
                  );
                }
                return (
                  <div key={index}>
                    {index !== 0 ? (
                      <Divider dashed="true" style={{ margin: 2 }} />
                    ) : null}
                    {p.barcodes.map((barcode) => {
                      return (
                        <CopyToClipboard
                          key={barcode}
                          color="blue"
                          message={barcode}
                          style={{ margin: 2 }}
                        />
                      );
                    })}
                  </div>
                );
              })
            : '-';
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable.status',
        ),
        dataIndex: 'status',
        key: 'status',
        ...tableHelper.fieldSorter.getNumberSortProps('status'),
        render: (code) => {
          return getTranslation.jobTaskStatus(code);
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable.createdAt',
        ),
        dataIndex: 'createdAt',
        key: 'createdAt',
        ...tableHelper.fieldSorter.getDateSortProps('createdAt'),
        render: (createdAt) => {
          return moment(new Date(createdAt))
            .local()
            .format(getDateTimeFormatString());
        },
      },
      {
        key: 'detail',
        dataIndex: 'id',
        render: (id) => <this.DetailTaskButton id={id} />,
      },
    ];
  };

  DetailTaskButton = (props) => {
    const { data: inventoryCheckDetail } = this.props.inventoryCheckDetail;
    const settings = {
      link: {
        target: '_blank',
        to: `/stock-operation/inventory-check/${inventoryCheckDetail.id}/job/${inventoryCheckDetail.job.id}/task/${props.id}`,
      },
    };
    return (
      <Link {...settings.link}>
        <Button size="small">
          {this.props.t(
            'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable.detail',
          )}
        </Button>
      </Link>
    );
  };

  prepareData = (props) => {
    const { inventoryCheckDetail } = this.props;
    const { filter } = this.state;
    let filteredRecords = inventoryCheckDetail.data.job.tasks;
    if (!filter) {
      return filteredRecords;
    }
    if (filter.statuses && filter.statuses.length) {
      filteredRecords = filteredRecords.filter((item) =>
        filter.statuses.includes(item.status.toString()),
      );
    }
    if (filter.productIds && filter.productIds.length) {
      filteredRecords = filteredRecords.filter((item) => {
        if (item.data && item.data.products && item.data.products.length) {
          if (item.data.products.length === 1) {
            return filter.productIds.includes(item.data.products[0].productId);
          } else {
            return item.data.products
              .map((item) => item.productId)
              .filter((id) => filter.productIds.includes(id)).length;
          }
        }
        return false;
      });
    }
    return filteredRecords;
  };

  prepareProductColumns = (props) => {
    return [
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable.productImage',
        ),
        dataIndex: 'picURL',
        key: 'picURL',
        render: (picURL) => {
          return (
            <Avatar
              className="product-image"
              shape="square"
              src={
                getTranslation.obj(picURL) ? getTranslation.obj(picURL) : null
              }
            />
          );
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable.productId',
        ),
        dataIndex: 'productId',
        key: 'productId',
        render: (productId) => {
          return (
            <CopyToClipboard
              key={getTranslation.obj(productId)}
              message={getTranslation.obj(productId)}
              style={{ margin: 2 }}
              inner={<Icon type="copy" />}
            />
          );
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable.productName',
        ),
        dataIndex: 'fullName',
        key: 'fullName',
        render: (fullName) => {
          return (
            <CopyToClipboard
              key={getTranslation.obj(fullName)}
              message={getTranslation.obj(fullName)}
              style={{ margin: 2 }}
            />
          );
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.detail.inventoryCheckTaskTable.productBarcode',
        ),
        dataIndex: 'barcodes',
        key: 'barcodes',
        render: (barcodes) => {
          if (barcodes && barcodes.length) {
            return barcodes.map((barcode) => {
              return (
                <CopyToClipboard
                  key={barcode}
                  color="blue"
                  message={barcode}
                  style={{ margin: 2 }}
                />
              );
            });
          } else {
            return '-';
          }
        },
      },
    ];
  };

  prepareProductData = (record) => {
    return record.data.products;
  };

  getDetail = () => {
    const { id, onGetInventoryCheckDetail } = this.props;
    onGetInventoryCheckDetail(id);
  };

  setItemReady = () => {
    const { id, onSetInventoryCheckReady } = this.props;
    onSetInventoryCheckReady(id)
      .then(() => {
        this.setState({ isClickedPrepareInventoryCheckButton: false });
      })
      .catch(() => {
        this.setState({ isClickedPrepareInventoryCheckButton: false });
      });
  };

  deleteInventoryCheck = () => {
    const { id, onDeleteInventoryCheck } = this.props;
    const that = this;
    confirm({
      title: this.props.t(
        'pages.Operation.InventoryCheck.detail.deleteConfirmModal',
      ),
      onOk() {
        onDeleteInventoryCheck(id)
          .then(() => {
            that.setState({ isClickedDeleteInventoryCheckButton: false });
          })
          .catch(() => {
            that.setState({ isClickedDeleteInventoryCheckButton: false });
          });
      },
      onCancel() {
        that.setState({ isClickedDeleteInventoryCheckButton: false });
      },
    });
  };

  terminateInventoryCheck = () => {
    const { id, onTerminateInventoryCheck } = this.props;
    const that = this;
    confirm({
      title: this.props.t(
        'pages.Operation.InventoryCheck.detail.terminateConfirmModal',
      ),
      onOk() {
        onTerminateInventoryCheck(id)
          .then(() => {
            that.setState({ isClickedTerminateInventoryCheckButton: false });
          })
          .catch(() => {
            that.setState({ isClickedTerminateInventoryCheckButton: false });
          });
      },
      onCancel() {
        that.setState({ isClickedTerminateInventoryCheckButton: false });
      },
    });
  };

  includeToSTS = () => {
    const { id, onIncludeToSTS } = this.props;
    onIncludeToSTS({
      inventoryCheckIds: [id],
      field: 'isIncludedToSTS',
      newValue: true,
    })
      .then(() => {
        this.setState({ isClickedIncludeToInventoryCheckButton: false });
      })
      .catch(() => {
        this.setState({ isClickedIncludeToInventoryCheckButton: false });
      });
  };

  removeFromSTS = () => {
    const { id, onRemoveFromSTS } = this.props;
    onRemoveFromSTS({
      inventoryCheckIds: [id],
      field: 'isIncludedToSTS',
      newValue: false,
    })
      .then(() => {
        this.setState({ isClickedRemoveFromInventoryCheckButton: false });
      })
      .catch(() => {
        this.setState({ isClickedRemoveFromInventoryCheckButton: false });
      });
  };

  getCountOfCompletedTask = (job) => {
    return job.tasks.filter(
      (task) => task.status === JOB_TASK_STATUSE.COMPLETED,
    ).length;
  };

  getCountOfTask = (job) => {
    return job.tasks.length;
  };

  getCompletionRate = (job) => {
    return Math.floor(
      (this.getCountOfCompletedTask(job) / this.getCountOfTask(job)) * 100,
    );
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.inventory.inventoryCheckDetail.loading,
  inventoryCheckDetail: state.inventory.inventoryCheckDetail,
  isExporting: state.inventory.isExporting,
});

const mapDispatchToProps = (dispatch) => ({
  onGetInventoryCheckDetail: (id) => {
    dispatch(inventoryAction.getInventoryCheckDetail(id));
  },
  onSetInventoryCheckReady: (id) => {
    return dispatch(inventoryAction.setInventoryCheckReady(id));
  },
  onDeleteInventoryCheck: (id) => {
    return dispatch(inventoryAction.deleteInventoryCheck(id));
  },
  onTerminateInventoryCheck: (id) => {
    return dispatch(inventoryAction.terminateInventoryCheck(id));
  },
  onExportToXlsxInventoryCheck: ({ fileName, data, formatter }) => {
    return dispatch(
      inventoryAction.exportToXlsxInventoryCheck({ fileName, data }),
    );
  },
  onIncludeToSTS: (data) => {
    return dispatch(inventoryAction.includeToSTS(data));
  },
  onRemoveFromSTS: (data) => {
    return dispatch(inventoryAction.removeFromSTS(data));
  },
});

const InventoryCheckDetailConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(InventoryCheckDetail));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { InventoryCheckDetailConnected as InventoryCheckDetail };
