import React, { Component } from 'react';
import { Row, Col, Button, Modal, Icon } from 'antd';
// --------------------------------------------------
import './OrderBoard.scss';
import { withNamespaces } from 'react-i18next';
import { OrderListPanel } from './OrderListPanel';
import { OrderDetailModal } from './OrderDetailModal';
// --------------------------------------------------
import { CopyToClipboard } from './../../../components';
import { constants, getTranslation } from './../../../shared';
import { pageView } from '../../../services/segment';
import { pageLoadEvents } from '../../../services/events';
// --------------------------------------------------

const { ORDER_STATUS } = constants;

class OrderBoard extends Component {
  state = { visible: false, order: null };

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.HOME_ORDERS });
  }

  openModal = (order) => {
    const { getOrderDetail } = this.props;
    getOrderDetail(order.id, order.domainType);
    this.setState({
      order,
      visible: true,
    });
  };

  handleOkay = (e) => {
    this.setState({
      order: null,
      visible: false,
    });
  };

  render() {
    const { orders, selectedOrder } = this.props;
    if (!orders || !orders.data) {
      return null;
    }

    const ordersWaiting = orders.data.filter(
      (item) => item.status === ORDER_STATUS.WAITING_FOR_PICKER,
    );
    const ordersVerifying = orders.data.filter(
      (item) => item.status === ORDER_STATUS.VERIFYING,
    );
    const ordersPreparing = orders.data.filter(
      (item) => item.status === ORDER_STATUS.PREPARING,
    );
    const ordersPrepared = orders.data.filter(
      (item) => item.status === ORDER_STATUS.PREPARED,
    );
    const ordersHandover = orders.data.filter(
      (item) => item.status === ORDER_STATUS.HANDOVER,
    );
    const ordersOnway = orders.data.filter(
      (item) => item.status === ORDER_STATUS.ONWAY,
    );
    const ordersReached = orders.data.filter(
      (item) => item.status === ORDER_STATUS.REACHED,
    );

    return (
      <div className="OrderBoard_inner">
        <Modal
          width="70%"
          style={{ top: 20 }}
          title={
            <span>
              {this.props.t('pages.Home.orderBoard.orderDetail')}{' '}
              <CopyToClipboard
                message={this.state.order && this.state.order.id}
                inner={<Icon type="copy" />}
              />
            </span>
          }
          zIndex={1000}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleOkay}
          className="modal-order-detail"
          footer={[
            <Button key="okay" type="primary" onClick={this.handleOkay}>
              {this.props.t('pages.Home.orderBoard.okay')}
            </Button>,
          ]}
        >
          <OrderDetailModal order={selectedOrder.data} />
        </Modal>
        <Row>
          <Col md={4}>
            <OrderListPanel
              timer={this.props.timer}
              title={getTranslation.getOrderStatus(
                ORDER_STATUS.WAITING_FOR_PICKER,
              )}
              count={ordersWaiting.length}
              list={ordersWaiting}
              openOrderDetail={this.openModal}
            />
          </Col>
          <Col md={4}>
            <OrderListPanel
              timer={this.props.timer}
              title={getTranslation.getOrderStatus(ORDER_STATUS.VERIFYING)}
              count={ordersVerifying.length}
              list={ordersVerifying}
              openOrderDetail={this.openModal}
            />
          </Col>
          <Col md={4}>
            <OrderListPanel
              timer={this.props.timer}
              title={getTranslation.getOrderStatus(ORDER_STATUS.PREPARING)}
              count={ordersPreparing.length}
              list={ordersPreparing}
              openOrderDetail={this.openModal}
            />
          </Col>
          <Col md={4}>
            <OrderListPanel
              timer={this.props.timer}
              title={getTranslation.getOrderStatus(ORDER_STATUS.PREPARED)}
              count={ordersPrepared.length}
              list={ordersPrepared}
              openOrderDetail={this.openModal}
            />
          </Col>
          <Col md={4}>
            <OrderListPanel
              timer={this.props.timer}
              title={getTranslation.getOrderStatus(ORDER_STATUS.HANDOVER)}
              count={ordersHandover.length}
              list={ordersHandover}
              openOrderDetail={this.openModal}
            />
          </Col>
          <Col md={4}>
            <OrderListPanel
              timer={this.props.timer}
              title={`${getTranslation.getOrderStatus(
                ORDER_STATUS.ONWAY,
              )}/${getTranslation.getOrderStatus(ORDER_STATUS.REACHED)}`}
              count={`${ordersOnway.length}/${ordersReached.length}`}
              list={[...ordersOnway, ...ordersReached]}
              openOrderDetail={this.openModal}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const OrderBoardTranslated = withNamespaces('translation')(OrderBoard);

export { OrderBoardTranslated as OrderBoard };
