import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Table, Avatar } from 'antd';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import uuid from 'uuid';

import { warehouseAction } from './../../../../actions';
import { Spinner, CopyToClipboard } from './../../../../components';
import { getTranslation, getDateFormatString } from './../../../../shared';
import { getDefaultDateRange } from './../../../../shared/utility';
import { ExpirationDateListFilter } from './ExpirationDateListFilter';
import { pageLoadEvents } from '../../../../services/events';
import { pageView } from '../../../../services/segment';

import './ExpirationDateList.scss';

class ExpirationDateList extends Component {
  settings = {
    main: {
      className: 'ExpirationDateList_main',
    },
    card: {
      title: this.props.t('pages.Stock.ExpirationDate.list.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  dateFormat = getDateFormatString();

  constructor(props) {
    super(props);
    this.defaultDateRange = getDefaultDateRange();
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    return {
      pagination,
      filter: this.getDefaultFilter(),
    };
  };

  getDefaultFilter = () => null;

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.STOCK_PRODUCT_EXPIRATION_DATE });
    this.getList();
  }

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card}>
          <ExpirationDateListFilter
            defaultDateRange={this.defaultDateRange}
            onChange={this.setTableFilter}
            onClear={this.clearTableFilter}
          />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  PageTable = (props) => (
    <Table
      size="small"
      dataSource={this.prepareData(this.props)}
      columns={this.prepareColumns()}
      rowKey={(record) => `${record.id}-${uuid()}`}
      pagination={{
        ...this.state.pagination,
      }}
      loading={{ indicator: <Spinner />, spinning: this.props.loading }}
      onChange={this.handleTableChange}
      rowClassName={(record, index) => {
        const today = new Date(Date.now()).setHours(0, 0, 0, 0);
        const expDay = new Date(record.expiryDate);
        if (expDay - today < 0) {
          return 'expired-product';
        }
      }}
    />
  );

  handleTableChange = (pagination, filters, sorter) => {
    this.setState((state) => ({
      ...state,
      pagination: {
        ...this.state.pagination,
        ...pagination,
      },
    }));
  };

  prepareData = (props) => {
    if (!props.expiringProducts) {
      return null;
    }
    return props.expiringProducts.map((product) => product);
  };

  prepareColumns = () => [
    {
      title: this.props.t('pages.Stock.ExpirationDate.list.productTable.image'),
      dataIndex: 'picURL',
      key: 'picURL',
      render: (picURL) => (
        <Avatar
          className="grow"
          shape="square"
          src={getTranslation.obj(picURL)}
        />
      ),
    },
    {
      title: this.props.t('pages.Stock.ExpirationDate.list.productTable.id'),
      dataIndex: 'id',
      key: 'id',
      render: (id) => <CopyToClipboard message={id} />,
    },
    {
      title: this.props.t(
        'pages.Stock.ExpirationDate.list.productTable.fullName',
      ),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (fullName) =>
        fullName && <CopyToClipboard message={getTranslation.obj(fullName)} />,
    },
    {
      title: this.props.t(
        'pages.Stock.ExpirationDate.list.productTable.storageType',
      ),
      dataIndex: 'storageType',
      key: 'storageType',
      render: (storageType) => {
        if (storageType === 'ambient') {
          return this.props.t(
            'pages.Stock.ExpirationDate.list.storageType.ambient',
          );
        }
        return storageType;
      },
    },
    {
      title: this.props.t(
        'pages.Stock.ExpirationDate.list.productTable.locations',
      ),
      dataIndex: 'location',
      key: 'location',
      render: (location) => (
        <CopyToClipboard
          color="blue"
          style={{ margin: 2 }}
          message={location.barcode.toUpperCase()}
        />
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.ExpirationDate.list.productTable.expiryDate',
      ),
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (expiryDate) =>
        moment(expiryDate).isValid()
          ? moment(new Date(expiryDate))
            .local()
            .format(this.dateFormat)
          : '-',
    },
    {
      title: this.props.t(
        'pages.Stock.ExpirationDate.list.productTable.removeFromSaleDate',
      ),
      dataIndex: 'removeFromSaleDate',
      key: 'removeFromSaleDate',
      render: (removeFromSaleDate) =>
        moment(removeFromSaleDate).isValid()
          ? moment(new Date(removeFromSaleDate))
            .local()
            .format(this.dateFormat)
          : '-',
    },
    {
      title: this.props.t(
        'pages.Stock.ExpirationDate.list.productTable.itemCount',
      ),
      dataIndex: 'count',
      key: 'count',
    },
  ];

  getList = () => {
    const filter = this.getFilter();
    this.props.onGetExpirationDateList(this.props.CWId, filter);
  };

  getFilter = () => {
    const filter = this.state.filter || {};

    if (!filter.removeFromSaleDateRange) {
      filter.removeFromSaleDateRange = {};
    }

    filter.removeFromSaleDateRange.startDate =
      filter.removeFromSaleDateRange.startDate ||
      this.defaultDateRange.startDate;
    filter.removeFromSaleDateRange.endDate =
      filter.removeFromSaleDateRange.endDate || this.defaultDateRange.endDate;

    return filter;
  };

  setTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        filter,
      }),
      this.getList,
    );
  };

  clearTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        filter,
      }),
      this.getList,
    );
  };
}


const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.warehouse.expiringProducts.loading,
  expiringProducts: state.warehouse.expiringProducts.data,
  products: state.product.productList,
});

const mapDispatchToProps = (dispatch) => ({
  onGetExpirationDateList: (warehouseId, filter) =>
    dispatch(warehouseAction.getExpiringProducts(warehouseId, filter)),
});

const ExpirationDateListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(ExpirationDateList));

export { ExpirationDateListConnected as ExpirationDateList };
