import React from 'react';
// --------------------------------------------------
import { OrderListHeader } from './OrderListHeader';
import { OrderList } from './OrderList';
// --------------------------------------------------
// --------------------------------------------------

export const OrderListPanel = ({ title, count, list, openOrderDetail, timer }) => {
    return (
      <div className="OrderListPanel_inner">
        <OrderListHeader title={title} count={count} />
        <OrderList
          list={list}
          openOrderDetail={openOrderDetail}
          timer={timer}
        />
      </div>
    );
};
