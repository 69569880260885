import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Table, Avatar } from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import './HeatingEstimation.scss';
/*----------------------------------------------------------------------------*/
import { history } from './../../../history';
import { heatingEstimationAction } from '../../../actions';
import { Spinner, CopyToClipboard } from '../../../components';
import { tableHelper } from './../../../shared';
import { track } from '../../../services/segment';

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class HeatingEstimation extends Component {
  settings = {
    main: {
      className: 'HeatingEstimation_main',
    },
    card: {
      title: this.props.t('pages.Stock.HeatingEstimation.title'),
      extra: this.props.t('pages.Stock.HeatingEstimation.subTitle'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => ({
    hideColumn: true,
    currentHour: new Date(),
  });

  componentDidMount() {
    this.props.onGetHeatingEstimationList();
  }

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card}>
          <div>
            {loading ? (
              <Spinner />
            ) : (
              <div>
                <this.PageTable />
                <Button onClick={this.changeColumn}>
                  {this.props.t('pages.Stock.HeatingEstimation.changeTable')}
                </Button>
                <Button
                  style={{ float: 'right' }}
                  onClick={this.redirectToSMLPage}
                >
                  {this.props.t(
                    'pages.Stock.HeatingEstimation.stockMovementLogs',
                  )}
                </Button>
                <Button
                  style={{ float: 'right' }}
                  onClick={this.redirectToPurchaseOrderPage}
                >
                  {this.props.t(
                    'pages.Stock.HeatingEstimation.purchaseOrderStatus',
                  )}
                </Button>
              </div>
            )}
          </div>
        </Card>
      </div>
    );
  }

  changeColumn = () => {
    this.setState({ currentHour: new Date().getHours() });

    if (this.state.hideColumn === true) {
      track('HOME_HEATING_ESTIMATION_TABLE_EXPAND');
      return this.setState({ hideColumn: false });
    }
    track('HOME_HEATING_ESTIMATION_TABLE_SHRINK');
    return this.setState({ hideColumn: true });
  };

  redirectToSMLPage = (e) => {
    const warehouseId = localStorage.getItem('warehouseId');
    history.push(`/r/${warehouseId}/stock/movement-log/list`);
  };

  redirectToPurchaseOrderPage = (e) => {
    const warehouseId = localStorage.getItem('warehouseId');
    history.push(`/r/${warehouseId}/stock/purchase-order/list`);
  };

  PageTable = (props) => (
    <Table
      size="small"
      dataSource={this.prepareData(this.props)}
      columns={this.getResponsiveColumns()}
      rowKey={(record) => record.id}
    />
  );

  prepareData = (props) => {
    if (!props.products) {
      return null;
    }
    return props.products.map((p) => ({
      id: p.product.id,
      name: p.product.name,
      fullName: p.product.fullName,
      picURL: p.product.picURL,
      currentPrediction: p.currentPrediction,
      nextPrediction: p.nextPrediction,
      minimumAmount: p.minimumAmount,
      stocks: p.stocks,
      available: p.stocks.available,
      frozen: p.stocks.frozen,
      suggestion: p.suggestion,
      suggestionText: p.suggestionText,
    }));
  };

  getResponsiveColumns = () =>
    this.prepareColumns().filter(({ hide = false }) => !hide);

  prepareColumns = () => [
    {
      title: this.props.t('pages.Stock.HeatingEstimation.productTable.picURL'),
      dataIndex: 'picURL',
      key: 'picURL',
      width: 72,
      render: (picURL) => (
        <Avatar className="grow" shape="square" src={picURL} />
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.HeatingEstimation.productTable.fullName',
      ),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (fullName) => fullName && <CopyToClipboard message={fullName} />,
    },
    {
      title: this.props.t('pages.Stock.HeatingEstimation.productTable.cookBuy'),
      dataIndex: 'suggestion',
      key: 'suggestion',
      ...tableHelper.fieldSorter.getNumberSortProps('suggestion'),
      render: (suggestion, record) => {
        if (record.suggestionText) {
          return `${suggestion} / ${record.suggestionText}`;
        }
        return `${suggestion}`;
      },
    },
    {
      title: this.props.t(
        'pages.Stock.HeatingEstimation.productTable.available',
      ),
      dataIndex: 'available',
      key: 'available',
    },
    {
      title: `${this.state.currentHour} - ${this.state.currentHour +
        1} ${this.props.t(
        'pages.Stock.HeatingEstimation.productTable.prediction',
      )}`,
      dataIndex: 'currentPrediction',
      key: 'currentPrediction',
      hide: this.state.hideColumn,
    },
    {
      title: `${this.state.currentHour + 1} - ${this.state.currentHour +
        2} ${this.props.t(
        'pages.Stock.HeatingEstimation.productTable.prediction',
      )}`,
      dataIndex: 'nextPrediction',
      key: 'nextPrediction',
      hide: this.state.hideColumn,
    },
    {
      title: this.props.t('pages.Stock.HeatingEstimation.productTable.frozen'),
      dataIndex: 'frozen',
      key: 'frozen',
      ...tableHelper.fieldSorter.getNumberSortProps('frozen'),
      hide: this.state.hideColumn,
    },
  ];
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  loading: state.heatingEstimation.heatingEstimationList.loading,
  products: state.heatingEstimation.heatingEstimationList.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetHeatingEstimationList: () =>
    dispatch(heatingEstimationAction.getHeatingEstimationList()),
});

const HeatingEstimationConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(HeatingEstimation));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { HeatingEstimationConnected as HeatingEstimation };
