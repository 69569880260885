import React, { Component } from 'react';
import { Table, Collapse, Avatar } from 'antd';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

import './TransferDetail.scss';
import { TransferDetailLogic } from './TransferDetailLogic';
import { getTranslation, getDateTimeFormatString } from './../../../../shared';

class TransferPallet extends Component {
  render() {
    return <this.TaskPanel />;
  }

  TaskPanel = (props) => {
    const { transfer } = this.props;
    if (!transfer || !transfer.vehicles || !transfer.vehicles.length) {
      return null;
    }
    const pannelInner = [];

    transfer.vehicles.forEach((vehicle) => {
      if (!vehicle.pallets || !vehicle.pallets.length) {
        return null;
      }
      const plate = vehicle.plate;
      vehicle.pallets.forEach((pallet) => {
        pallet.items =
          this.getPalletItemsByPalletProducts(transfer, pallet.palletBarcode) ||
          pallet.items;
        pallet.isRead =
          (transfer.receivedPalletBarcodes &&
            transfer.receivedPalletBarcodes.indexOf(pallet.palletBarcode) !==
              -1) ||
          (TransferDetailLogic.isFromCentralToCentral(transfer.directionType) &&
            TransferDetailLogic.isTransferStatusCompleted(transfer.status));

        let header = `${pallet.palletBarcode} - (${plate})`;
        let palletColor;
        if (pallet.isArrived && !pallet.isRead) {
          palletColor = '#69c0ff';
          header = `${pallet.palletBarcode} - (${plate}) - (${this.props.t(
            'pages.Operation.Transfer.detail.transfer.table.arrivedDateToWarehouse',
          )} - ${moment(pallet.arrivedAt).format(getDateTimeFormatString())})`;
        }
        if (pallet.isRead) {
          palletColor = '#87d068';
          header = `${pallet.palletBarcode} - (${plate})`;
          if (pallet.isArrived) {
            header += ` - (${this.props.t(
              'pages.Operation.Transfer.detail.transfer.table.arrivedDateToWarehouse',
            )} - ${moment(pallet.arrivedAt).format(getDateTimeFormatString())})`;
          }
        }

        const comp = (
          <Collapse.Panel
            style={{ backgroundColor: palletColor }}
            size="small"
            header={header}
            key={pallet.palletBarcode}
          >
            <this.TaskTable
              size="small"
              task={pallet}
              key={pallet.palletBarcode}
              directionType={transfer.directionType}
              status={transfer.status}
            />
          </Collapse.Panel>
        );
        pannelInner.push(comp);
      });
    });
    return (
      <div style={{ padding: 5 }}>
        <Collapse size="small">{pannelInner}</Collapse>
      </div>
    );
  };

  TaskTable = (props) => {
    const { task, directionType, status } = props;
    return (
      <Table
        className="TaskTable"
        size="small"
        columns={this.prepareTaskColumns(task)}
        dataSource={this.prepareTaskData(task, directionType, status)}
        rowKey={(record) => {
          return record.id;
        }}
        rowClassName={(record) => record.sentCount === 0 ? 'danger' : ''}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  prepareTaskColumns = (props) => [
    {
      title: this.props.t(
        'pages.Operation.Transfer.detail.transfer.table.image',
      ),
      dataIndex: 'picURL',
      key: 'picURL',
      width: 72,
      render: (picURL) => (
        <Avatar
          className="grow"
          shape="circle"
          src={getTranslation.obj(picURL)}
        />
      ),
    },
    {
      title: this.props.t(
        'pages.Operation.Transfer.detail.transfer.table.fullName',
      ),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (fullName) => getTranslation.obj(fullName),
    },
    {
      title: this.props.t(
        'pages.Operation.Transfer.detail.transfer.table.sentCount',
      ),
      dataIndex: 'sentCount',
      key: 'sentCount',
    },
    { ...(
      !TransferDetailLogic.isToCentral(this.props.transfer.directionType)
      && !TransferDetailLogic.isFromCentralToCentral(this.props.transfer.directionType)
    ) ?
    {
      title: this.props.t(
        'pages.Operation.Transfer.detail.transfer.table.receivedCount',
      ),
      dataIndex: 'receivedCount',
      key: 'receivedCount',
    } : null,
    },
    { ...(
      !TransferDetailLogic.isToCentral(this.props.transfer.directionType)
      && !TransferDetailLogic.isFromCentralToCentral(this.props.transfer.directionType)
    ) ?
    {
      title: this.props.t(
        'pages.Operation.Transfer.detail.transfer.table.damagedCount',
      ),
      dataIndex: 'damagedCount',
      key: 'damagedCount',
    } : null,
    },
  ];

  prepareTaskData = (pallet, directionType, status) => {
    if (!pallet || !pallet.items || !pallet.items.length) {
      return null;
    }
    return pallet.items.map((record) => {
      const product = this.getProductById(record.item);
      return {
        picURL: product.picURL,
        fullName: product.fullName,
        sentCount: record.sentCount,
        receivedCount:
          record.receivedCount ||
          (TransferDetailLogic.isFromCentralToCentral(directionType) &&
          TransferDetailLogic.isTransferStatusCompleted(status)
            ? record.sentCount
            : 0),
        damagedCount: record.damagedCount || 0,
        id: product.id,
      };
    });
  };

  getProductById = (id) => {
    const { transfer } = this.props;
    if (!transfer || !transfer.items || !transfer.items.length) {
      return null;
    }
    return transfer.items.filter((record) => record.item.id === id)[0].item;
  };

  getPalletItemsByPalletProducts = (transfer, palletBarcode) => {
    let items;
    transfer.jobs.forEach((job) => {
      job.tasks.forEach((task) => {
        if (task.data.palletBarcode === palletBarcode) {
          items = task.data.items;
        }
      });
    });
    return items;
  };
}

const TransferPalletTranslated = withNamespaces('translation')(TransferPallet);

export { TransferPalletTranslated as TransferPallet };
