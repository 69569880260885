/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';

export const canAccess = (route, permissions) => {
  let accessible = false;
  const { rootUrl } = route;
  let neededPermission = null;
  if (!permissions) {
    return true;
  }
  if (rootUrl) {
    neededPermission = permissions.filter((perm) => perm.url.includes(rootUrl));
    accessible = neededPermission && neededPermission.length;
  }
  const routeUrl = route.url || route.path;
  if (routeUrl && route.permission) {
    const allowedAction = route.permission.action;
    neededPermission = permissions.filter((perm) => perm.url === routeUrl)[0];
    if (neededPermission && neededPermission.actions) {
      accessible = neededPermission.actions.includes(allowedAction);
    }
  }
  return accessible;
};

const canAccessInside = (params) => {
  const { allow, permissions } = params;
  return canAccess(allow, permissions);
};

const canAccessComponent = (props) => {
  const isAllowed = canAccessInside(props);
  return <>{isAllowed ? props.children : null}</>;
};

const mapStateToProps = (state) => ({
  permissions: state.auth.user.permissions,
});

const CanAccessComponentConnected = connect(mapStateToProps)(
  canAccessComponent,
);

export { CanAccessComponentConnected as Can };
