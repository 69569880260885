/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import { Button } from 'antd';
import ReactDOMServer from 'react-dom/server';
import { chunk } from 'lodash';
import { PrintTemplate } from './PrintTemplate';
import { constants } from './../../shared';


export class PrintButton extends Component {
  render() {
    const { items, settings } = this.props;

    const productCountPerWaybill = constants.PRODUCT_COUNT_PER_WAYBILL;

    const waybillCount = Math.ceil(items.length / constants.PRODUCT_COUNT_PER_WAYBILL);

    let chunkedItemsArray = [];
    if (items.length > productCountPerWaybill) {
      chunkedItemsArray = chunk(items, productCountPerWaybill);
    } else {
      chunkedItemsArray = items;
    }

    return (
      <Button
        {...settings.button}
        onClick={() => {
          let windowContent = '<!DOCTYPE html>';

          for (let index = 0; index < waybillCount; index++) {
            let waybillItems = items;
            if (items.length > productCountPerWaybill) {
              waybillItems = chunkedItemsArray[index];
            }
            const html = ReactDOMServer.renderToString(
              <PrintTemplate items={waybillItems} />,
            );
            windowContent += '<html>';
            windowContent += '<head><title>Waybill</title></head>';
            windowContent += '<body>';
            windowContent += html;
            windowContent += '</body>';
            windowContent += '</html>';
          }
          const printWin = window.open('/', '_blank');
          const printEvent = new Event('print');
          printWin.document.open();
          printWin.document.write(windowContent);
          printWin.document.addEventListener(
            'print',
            () => {
              printWin.focus();
              printWin.print();
              printWin.document.close();
              printWin.close();
              this.props.changeParentState();
            },
            true,
          );
          printWin.document.dispatchEvent(printEvent);
        }
        }
      >
        {settings.label}
      </Button>
    );
  }
}
