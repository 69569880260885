/* eslint-disable no-param-reassign */
import { constants } from './../../../../shared';
import { config } from './../../../../config';

export class TransferDetailLogic {
  static isFromWarehouse = (directionType) => directionType === constants.DIRECTION_TYPES.WAREHOUSE_TO_CENTRAL ||
    directionType === constants.DIRECTION_TYPES.WAREHOUSE_TO_WAREHOUSE;

  static isToWarehouse = (directionType) => directionType === constants.DIRECTION_TYPES.CENTRAL_TO_WAREHOUSE ||
    directionType === constants.DIRECTION_TYPES.WAREHOUSE_TO_WAREHOUSE;

  static isFromCentral = (directionType) => directionType === constants.DIRECTION_TYPES.CENTRAL_TO_WAREHOUSE ||
    directionType === constants.DIRECTION_TYPES.CENTRAL_TO_CENTRAL;

  static isToCentral = (directionType) => directionType === constants.DIRECTION_TYPES.WAREHOUSE_TO_CENTRAL;

  static isFromCentralToCentral = (directionType) => directionType === constants.DIRECTION_TYPES.CENTRAL_TO_CENTRAL;

  static isFromWarehouseToWarehouse = (directionType) => directionType === constants.DIRECTION_TYPES.WAREHOUSE_TO_WAREHOUSE;

  static isFromWarehouseToCentral = (directionType) => directionType === constants.DIRECTION_TYPES.WAREHOUSE_TO_CENTRAL;

  static isTransferDeparted = (transfer) => transfer.isDeparted;

  static isTransferStatusCreated = (status) => status === constants.TRANSFER_STATUS.CREATED;

  static isTransferStatusApproved = (status) => status === constants.TRANSFER_STATUS.APPROVED;

  static isTransferStatusCompleted = (status) => status === constants.TRANSFER_STATUS.COMPLETED;

  static isTransferStatusShipped = (status) => status === constants.TRANSFER_STATUS.SHIPPED;

  static isTransferVirtualDepart = (integrationType) => integrationType === constants.INTEGRATION_TYPE.VIRTUAL_DEPART;

  static isSendableToWMS = (directionType, transferStatus) => {
      const { DIRECTION_TYPES, TRANSFER_STATUS } = constants;
      if (
        (transferStatus === TRANSFER_STATUS.APPROVED &&
          [DIRECTION_TYPES.CENTRAL_TO_WAREHOUSE, DIRECTION_TYPES.CENTRAL_TO_CENTRAL].includes(
            directionType,
          )) ||
        (transferStatus === TRANSFER_STATUS.SHIPPED &&
          [
            DIRECTION_TYPES.CENTRAL_TO_WAREHOUSE,
            DIRECTION_TYPES.WAREHOUSE_TO_CENTRAL,
            DIRECTION_TYPES.CENTRAL_TO_CENTRAL,
          ].includes(directionType))
      ) {
        return true;
      }
      return false;
  };

  static isDevOrLocalEnvironment = () => config.ENV !== 'production';

  static getProductIdsFromWarehouseStocks(productsStock) {
    return productsStock
      .filter((item) => item.count + item.acceptanceCount)
      .map((item) => item.item);
  }

  static getExistingProductIds(productIds, warehouseProductList) {
    return productIds.filter((product) => warehouseProductList.find((warehouseProduct) => product.toString() === warehouseProduct.id));
  }

  static getExistingStockItems(warehouseStock, existProductIds) {
    return warehouseStock.filter((item) => existProductIds.find((existProduct) => existProduct.toString() === item.item.toString()));
  }

  static findItemInArrayByGivenField(
    array = [], arrayField, object, objectField,
  ) {
    return array.find((item) => item[arrayField].toString() === object[objectField].toString());
  }

  static getPopulatedItems(warehouseProductList, warehouseStockItems) {
    const warehouseItemsArray = [];
    if (warehouseProductList && warehouseProductList.length) {
      warehouseStockItems.forEach((item) => {
        const product = item;
        if (product.count + product.acceptanceCount) {
          product.item = this.findItemInArrayByGivenField(warehouseProductList, 'id', product, 'item');

          product.item = {
            id: product.item.id,
            barcodes: product.item.barcodes,
            fullName: product.item.fullName,
            picURL: product.item.picURL,
          };

          warehouseItemsArray.push(product);
        }
      });
    }
    return warehouseItemsArray;
  }

  static getPreparedFromTransferItems(transfer, productList) {
    let warehouseStock = transfer.fromWarehouse.items;
    let warehouseProductIds = this.getProductIdsFromWarehouseStocks(warehouseStock);
    warehouseProductIds = this.getExistingProductIds(warehouseProductIds, productList);
    warehouseStock = this.getExistingStockItems(warehouseStock, warehouseProductIds);
    const populatedItems = this.getPopulatedItems(productList, warehouseStock);
    return populatedItems;
  }
}
