import { getDataByKey } from './utility';

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const fieldSorter = {
  getStringSortProps: (dataIndex) => {
    return {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => {
        return getDataByKey(a, dataIndex).localeCompare(
          getDataByKey(b, dataIndex),
        );
      },
    };
  },
  getDateSortProps: (dataIndex) => {
    return {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => {
        return (
          new Date(getDataByKey(a, dataIndex)) -
          new Date(getDataByKey(b, dataIndex))
        );
      },
    };
  },
  getNumberSortProps: (dataIndex) => {
    return {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => {
        return getDataByKey(a, dataIndex) - getDataByKey(b, dataIndex);
      },
    };
  },
};

const tableHelper = {
  fieldSorter,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { tableHelper };
