import React, { Component } from 'react';
import { Select, Spin } from 'antd';
import { uniqBy } from 'lodash';
import classNames from 'classnames';
/*----------------------------------------------------------------------------*/
import { TransferLogic } from './TransferLogic';
/*----------------------------------------------------------------------------*/
import { constants } from './../../../../../shared';
/*----------------------------------------------------------------------------*/
const { WAREHOUSE_TYPE } = constants;
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export class FromWarehouseMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => ({
    selectedItems: null,
  });

  render() {
    return <this.SelectField />;
  }

  SelectField = () => {
    const { loading, disabled, placeholder } = this.props;
    return (
      <Select
        mode="multiple"
        showSearch
        allowClear
        optionFilterProp="children"
        disabled={disabled}
        placeholder={placeholder}
        value={this.getValue()}
        filterOption={this.filterOption}
        onChange={this.onChange}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {this.getOptions()}
      </Select>
    );
  };

  getOptions = () => {
    const { list } = this.props;
    if (!list || !list.length) {
      return null;
    }

    const options = uniqBy(list, 'name')
      .filter((item) => {
        return (
          item.warehouseType !== WAREHOUSE_TYPE.VIRTUAL_WAREHOUSE
        );
      })
      .map((item) => {
        const selectClass = classNames({
          isDincerIntegrated:
            item.dincerIntegrationId ||
            item.warehouseType === WAREHOUSE_TYPE.MAIN_WAREHOUSE,
        });
        return (
          <Select.Option
            value={item.name}
            key={item.id}
            rawvalue={item}
            className={selectClass}
          >
            {item.name}
          </Select.Option>
        );
      });
    return options;
  };

  getValue = () => {
    const { selectedItems } = this.state;
    const { list } = this.props;
    if (!selectedItems || !list || !list.length) {
      return [];
    }
    return selectedItems.map((item) => item.name);
  };

  filterOption = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
      option.props.rawvalue.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  }

  onChange = (value, options) => {
    try {
      const selectedItems = options.map((o) => o.props.rawvalue);

      if (TransferLogic.hasFromWarehouseHasDincerIntegrationId(selectedItems)) {
        this.setState(this.stateFactory());
        this.setState({
          selectedItems: [selectedItems[selectedItems.length - 1]],
        });
      } else {
        this.setState({
          selectedItems,
        });
      }
    } catch (error) {
      this.setState({
        selectedItems: null,
      });
    }
    setTimeout(() => {
      this.props.onChange(this.state.selectedItems);
    });
  };

  clearState = () => {
    this.setState(this.stateFactory());
  };
}
