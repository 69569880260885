import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';
import i18n from '../i18n';

export const orderAction = {
  getOrderList,
  getOrderDetail,
  newMarketOrder,
  marketOrderChanged,
  reportFraud,
  getWalkInOrders,
  getWalkInOrderDetails,
  walkInOrderDecreaseStock,
  walkInOrderCancel,
  walkInOrderDeliver,
  filterWalkInOrders,
  walkInOrderRefund,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getOrderList() {
  return (dispatch) => {
    dispatch({
      type: actionType.getOrderList_InProgress,
    });
    http
      .getOrderList()
      .then((response) => {
        const { orders } = response.data.data;
        dispatch({
          type: actionType.getOrderList_Succeeded,
          payload: orders,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getOrderList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getOrderList_Failed,
          payload: error,
        });
      });
  };
}

function getOrderDetail(id, domainType) {
  return (dispatch) => {
    dispatch({
      type: actionType.getOrderDetail_InProgress,
    });
    dispatch({
      type: actionType.reportFraud_Reset,
    });
    http
      .getOrderDetail(id, domainType)
      .then((response) => {
        const { order } = response.data.data;
        dispatch({
          type: actionType.getOrderDetail_Succeeded,
          payload: order,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getOrderDetail_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getOrderDetail_Failed,
          payload: error,
        });
      });
  };
}

function reportFraud(orderId, domainType) {
  return (dispatch) => {
    dispatch({
      type: actionType.reportFraud_InProgress,
    });
    http
      .reportFraud(orderId, domainType)
      .then((response) => {
        const { order } = response.data.data;
        dispatch({
          type: actionType.reportFraud_Succeeded,
          payload: order,
        });
        notification.success({
          duration: 4,
          message: i18n.t('shared.messages.reportedAsFraudSuccessfully'),
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.reportFraud_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.reportFraud_Failed,
          payload: error,
        });
      });
  };
}

function newMarketOrder(order) {
  return (dispatch) => {
    dispatch({
      type: actionType.newMarketOrder,
      payload: { order },
    });
  };
}

function marketOrderChanged(order) {
  return (dispatch) => {
    dispatch({
      type: actionType.marketOrderChanged,
      payload: { order },
    });
  };
}

function getWalkInOrders() {
  return (dispatch) => {
    dispatch({
      type: actionType.getWalkInOrders_InProgress,
    });
    http
      .getWalkInOrders()
      .then((response) => {
        dispatch({
          type: actionType.getWalkInOrders_Succeeded,
          payload: response.data.data.orders,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getWalkInOrders_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getWalkInOrders_Failed,
          payload: error,
        });
      });
  };
}

function getWalkInOrderDetails(orderId) {
  return (dispatch) => {
    dispatch({
      type: actionType.getWalkInOrderDetails_InProgress,
    });
    http
      .getWalkInOrderDetails(orderId)
      .then((response) => {
        dispatch({
          type: actionType.getWalkInOrderDetails_Succeeded,
          payload: response.data.data.order,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getWalkInOrderDetails_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getWalkInOrderDetails_Failed,
          payload: error,
        });
      });
  };
}

function walkInOrderDecreaseStock(orderId) {
  return (dispatch) => {
    dispatch({
      type: actionType.walkInOrderDecreaseStock_InProgress,
    });
    return http
      .walkInOrderDecreaseStock(orderId)
      .then((response) => {
        dispatch({
          type: actionType.walkInOrderDecreaseStock_Succeeded,
          payload: response,
        });
        notification.success({
          duration: 4,
          message: i18n.t('shared.messages.stockSuccessfullyDecreased'),
        });
        dispatch(getWalkInOrderDetails(orderId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.walkInOrderDecreaseStock_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.walkInOrderDecreaseStock_Failed,
          payload: error.response.data,
        });
        throw error;
      });
  };
}

function walkInOrderCancel(orderId, cancelReason) {
  return (dispatch) => {
    dispatch({
      type: actionType.walkInOrderCancel_InProgress,
    });
    return http
      .walkInOrderCancel(orderId, cancelReason)
      .then((response) => {
        dispatch({
          type: actionType.walkInOrderCancel_Succeeded,
          payload: response,
        });
        notification.success({
          duration: 4,
          message: i18n.t('shared.messages.orderHasBeenSuccessfullyCanceled'),
        });
        dispatch(getWalkInOrderDetails(orderId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.walkInOrderCancel_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.walkInOrderCancel_Failed,
          payload: error,
        });
      });
  };
}

function walkInOrderDeliver(orderId, slipNo) {
  return (dispatch) => {
    dispatch({
      type: actionType.walkInOrderDeliver_InProgress,
    });
    return http
      .walkInOrderDeliver(orderId, slipNo)
      .then((response) => {
        dispatch({
          type: actionType.walkInOrderDeliver_Succeeded,
          payload: response,
        });
        dispatch(getWalkInOrderDetails(orderId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.walkInOrderDeliver_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.walkInOrderDeliver_Failed,
          payload: error,
        });
      });
  };
}

function filterWalkInOrders(slipNo) {
  return (dispatch) => {
    dispatch({
      type: actionType.filterWalkInOrders_InProgress,
    });
    return http
      .filterWalkInOrders(slipNo)
      .then((response) => {
        dispatch({
          type: actionType.filterWalkInOrders_Succeeded,
          payload: response.data.data.orders,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.filterWalkInOrders_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.filterWalkInOrders_Failed,
          payload: error,
        });
      });
  };
}

function walkInOrderRefund(orderId, refundSlipNo) {
  return (dispatch) => {
    dispatch({
      type: actionType.walkInOrderRefund_InProgress,
    });
    return http
      .walkInOrderRefund(orderId, refundSlipNo)
      .then((response) => {
        dispatch({
          type: actionType.walkInOrderRefund_Succeeded,
          payload: response,
        });
        notification.success({
          duration: 4,
          message: i18n.t('shared.messages.orderHasBeenSuccessfullyRefunded'),
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.walkInOrderRefund_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.walkInOrderRefund_Failed,
          payload: error,
        });
      });
  };
}
