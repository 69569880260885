import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: true,
    data: null,
    error: null,
    count: 0,
  },
};

const switcher = {
  [actionType.getMissingProductsOrders_InProgress]: getMissingProductsOrders_InProgress,
  [actionType.getMissingProductsOrders_Succeeded]: getMissingProductsOrders_Succeeded,
  [actionType.getMissingProductsOrders_Failed]: getMissingProductsOrders_Failed,

  [actionType.getMissingProductsOrdersCount_InProgress]: getMissingProductsOrdersCount_InProgress,
  [actionType.getMissingProductsOrdersCount_Succeeded]: getMissingProductsOrdersCount_Succeeded,
  [actionType.getMissingProductsOrdersCount_Failed]: getMissingProductsOrdersCount_Failed,

  [actionType.removeFromSalesInMissingProductsOrders_InProgress]: removeFromSalesInMissingProductsOrders_InProgress,
  [actionType.removeFromSalesInMissingProductsOrders_Succeeded]: removeFromSalesInMissingProductsOrders_Succeeded,
  [actionType.removeFromSalesInMissingProductsOrders_Failed]: removeFromSalesInMissingProductsOrders_Failed,

  [actionType.cancelMissingProducts_InProgress]: cancelMissingProducts_InProgress,
  [actionType.cancelMissingProducts_Succeeded]: cancelMissingProducts_Succeeded,
  [actionType.cancelMissingProducts_Failed]: cancelMissingProducts_Failed,

  [actionType.approveCancelMissingProducts_InProgress]: approveCancelMissingProducts_InProgress,
  [actionType.approveCancelMissingProducts_Succeeded]: approveCancelMissingProducts_Succeeded,
  [actionType.approveCancelMissingProducts_Failed]: approveCancelMissingProducts_Failed,
};

export const missingProductsOrdersReducer = createReducer(
  switcher,
  initialState,
);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getMissingProductsOrders_InProgress(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getMissingProductsOrders_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload,
      count: state.list.count || 0,
      error: null,
    },
  });
}

function getMissingProductsOrders_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      count: 0,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/
function getMissingProductsOrdersCount_InProgress(state, payload) {
  return state;
}

function getMissingProductsOrdersCount_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      ...state.list,
      count: payload,
    },
  });
}

function getMissingProductsOrdersCount_Failed(state, payload) {
  return state;
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function removeFromSalesInMissingProductsOrders_InProgress(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: state.list.data,
      count: state.list.count,
      error: null,
    },
  });
}

function removeFromSalesInMissingProductsOrders_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload,
      count: state.list.count || 0,
      error: null,
    },
  });
}

function removeFromSalesInMissingProductsOrders_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: state.list.data,
      count: state.list.count,
      error: payload,
    },
  });
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function cancelMissingProducts_InProgress(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: state.list.data,
      count: state.list.count,
      error: null,
    },
  });
}

function cancelMissingProducts_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload,
      count: state.list.count || 0,
      error: null,
    },
  });
}

function cancelMissingProducts_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: state.list.data,
      count: state.list.count,
      error: payload,
    },
  });
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function approveCancelMissingProducts_InProgress(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: state.list.data,
      count: state.list.count,
      error: null,
    },
  });
}

function approveCancelMissingProducts_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: state.list.data,
      count: state.list.count,
      error: null,
    },
  });
}

function approveCancelMissingProducts_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {

      loading: false,
      data: state.list.data,
      count: state.list.count,
      error: payload,
    },
  });
}
