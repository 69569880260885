import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form, Button, Row, Col,
} from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import {
  WarehouseMultiSelect,
  ProductMultiSelect,
  DateRangeSelect,
  ReasonSelect,
  BsConclusionTypeMultiSelect,
  BsStatusMultiSelect,
  SupplierMultiSelect,
} from './filterFields';
/*----------------------------------------------------------------------------*/
import {
  warehouseAction,
  productAction,
  blockedStockAction,
  supplierAction,
} from './../../../../../../actions';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class ListTabFilter extends Component {
  settings = {
    main: {
      className: 'ListTabFilter_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    const {
      onGetWarehouseList,
      onGetProductList,
      onGetReasonList,
      onGetSupplierList,
    } = this.props;
    onGetWarehouseList();
    onGetProductList();
    onGetReasonList();
    onGetSupplierList();
  }

  stateFactory = () => ({
    ...this.emptyTableFilter(),
  });

  emptyTableFilter = () => ({
    startDate: this.props.defaultDateRange.startDate,
    endDate: this.props.defaultDateRange.endDate,
    reason: null,
    subReason: null,
    products: [],
    warehouses: [this.props.currentWarehouse],
    conclusionTypes: [],
    statuses: [],
    suppliers: [],
  });

  render() {
    return (
      <div {...this.settings.main}>
        <this.TableFilterForm />
      </div>
    );
  }

  TableFilterForm = (props) => (
    <div>
      <Form>
        <Row>
          <Col span={5} style={{ padding: '0px 5px' }}>
            <this.DateRangeField />
          </Col>
          <Col span={8} style={{ padding: '0px 5px' }}>
            <this.WarehouseField />
          </Col>
          <Col span={11} style={{ padding: '0px 5px' }}>
            <this.ProductField />
          </Col>
        </Row>

        <Row>
          <Col span={6} style={{ padding: '0px 5px' }}>
            <this.ReasonField />
          </Col>
          <Col span={7} style={{ padding: '0px 5px' }}>
            <this.SubReasonField />
          </Col>

          <Col span={5} style={{ padding: '0px 5px' }}>
            <this.ConclusionTypeField />
          </Col>
          <Col span={6} style={{ padding: '0px 5px' }}>
            <this.StatusField />
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ padding: '0px 5px' }}>
            <this.SupplierField />
          </Col>
        </Row>

        <Row>
          <Col span={2} style={{ padding: '0px 5px' }}>
            <this.ClearFilterButton />
          </Col>
          <Col offset={20} span={2} style={{ padding: '0px 5px' }}>
            <this.FilterButton />
          </Col>
        </Row>
      </Form>
    </div>
  );

  DateRangeField = () => (
    <Form.Item>
      <DateRangeSelect
        defaultDateRange={this.props.defaultDateRange}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        onChange={(dateRange) => {
          this.setState({
            ...this.state,
            ...dateRange,
          });
        }}
      />
    </Form.Item>
  );

  WarehouseField = () => {
    const { warehouseList } = this.props;
    const { warehouses } = this.state;
    const list = (warehouseList && warehouseList.data) || null;
    return (
      <Form.Item>
        <WarehouseMultiSelect
          list={list}
          disabled
          onChange={(warehouses) => {
            this.setState({
              warehouses,
            });
          }}
          value={warehouses}
          loading={warehouseList.loading}
          placeholder={this.props.t('pages.Stock.BlockedStock.list.tabs.ListTab.filter.warehouse')}
        />
      </Form.Item>
    );
  };

  ProductField = () => {
    const { productList } = this.props;
    const { products } = this.state;
    const list = (productList && productList.data) || null;
    return (
      <Form.Item>
        <ProductMultiSelect
          list={list}
          onChange={(products) => {
            this.setState({
              products,
            });
          }}
          value={products}
          loading={productList.loading}
          placeholder={this.props.t('pages.Stock.BlockedStock.list.tabs.ListTab.filter.product')}
        />
      </Form.Item>
    );
  };

  ReasonField = () => {
    const { reasonList } = this.props;
    const { reason } = this.state;
    const list = (reasonList && reasonList.data) || null;
    return (
      <Form.Item>
        <ReasonSelect
          list={list}
          onChange={(reason) => {
            this.setState({
              ...this.state,
              reason,
              subReason: null,
            });
          }}
          value={reason}
          loading={reasonList.loading}
          placeholder={this.props.t('pages.Stock.BlockedStock.list.tabs.ListTab.filter.reason')}
        />
      </Form.Item>
    );
  };

  SubReasonField = () => {
    const { reason, subReason } = this.state;
    const list = (reason && reason.subReasons) || null;
    return (
      <Form.Item>
        <ReasonSelect
          list={list}
          onChange={(subReason) => {
            this.setState({
              subReason,
            });
          }}
          value={subReason}
          disabled={!list || !list.length}
          placeholder={this.props.t('pages.Stock.BlockedStock.list.tabs.ListTab.filter.subReason')}
        />
      </Form.Item>
    );
  };

  ConclusionTypeField = () => {
    const { conclusionTypes } = this.state;
    return (
      <Form.Item>
        <BsConclusionTypeMultiSelect
          onChange={(conclusionTypes) => {
            this.setState({ conclusionTypes });
          }}
          placeholder={this.props.t('pages.Stock.BlockedStock.list.tabs.ListTab.filter.conclusionType')}
          value={conclusionTypes}
        />
      </Form.Item>
    );
  };

  StatusField = () => {
    const { statuses } = this.state;
    return (
      <Form.Item>
        <BsStatusMultiSelect
          onChange={(statuses) => {
            this.setState({ statuses });
          }}
          placeholder={this.props.t('pages.Stock.BlockedStock.list.tabs.ListTab.filter.status')}
          value={statuses}
        />
      </Form.Item>
    );
  };

  SupplierField = (props) => {
    const { supplierList } = this.props;
    const { suppliers } = this.state;
    return (
      <Form.Item>
        <SupplierMultiSelect
          onChange={(suppliers) => {
            this.setState({ suppliers });
          }}
          value={suppliers}
          placeholder={this.props.t('pages.Stock.BlockedStock.list.tabs.ListTab.filter.supplier')}
          list={supplierList}
        />
      </Form.Item>
    );
  };

  FilterButton = () => (
    <Form.Item>
      <Button
        type="primary"
        block
        onClick={() => {
          const filter = this.getTableFilter();
          this.props.onChange(filter);
        }}
      >
        {this.props.t('pages.Stock.BlockedStock.list.tabs.ListTab.filter.filter')}
      </Button>
    </Form.Item>
  );

  ClearFilterButton = () => (
    <Form.Item>
      <Button
        block
        onClick={() => {
          this.clearFilter();
        }}
      >
        {this.props.t('pages.Stock.BlockedStock.list.tabs.ListTab.filter.clean')}
      </Button>
    </Form.Item>
  );

  getTableFilter = () => {
    const {
      reason,
      subReason,
      products,
      warehouses,
      startDate,
      endDate,
      conclusionTypes,
      statuses,
      suppliers,
    } = this.state;

    const filter = {};

    filter.createdAt = {
      startDate: startDate || this.props.defaultDateRange.startDate,
      endDate: endDate || this.props.defaultDateRange.endDate,
    };

    if (reason) {
      filter.reasons = [reason.id];
    }
    if (subReason) {
      filter.subReasons = [subReason.id];
    }
    if (products && products.length) {
      filter.products = products.map((p) => p.id);
    }

    if (conclusionTypes && conclusionTypes.length) {
      filter.conclusionTypes = conclusionTypes.map((p) => p.key);
    }

    if (statuses && statuses.length) {
      filter.statuses = statuses.map((p) => p.key);
    }

    if (warehouses && warehouses.length) {
      filter.warehouses = warehouses.map((warehouse) => warehouse.id);
    }

    if (suppliers && suppliers.length) {
      filter.suppliers = suppliers.map((s) => s._id);
    }

    return filter;
  };

  clearFilter = () => {
    this.setState({ ...this.state, ...this.stateFactory() }, () => {
      this.props.onClear(this.getTableFilter());
    });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse.id,
  currentWarehouse: state.auth.currentWarehouse,
  warehouseList: state.warehouse.warehouseList,
  productList: state.product.productList,
  reasonList: state.blockedStock.reasonList,
  supplierList: state.supplier.list.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetWarehouseList: () => {
    dispatch(warehouseAction.getWarehouseList());
  },
  onGetProductList: () => {
    dispatch(
      productAction.filterProductList({
        fields: 'barcodes fullName picURL packagingInfo',
        includeDefaultFields: false,
      }),
    );
  },
  onGetReasonList: () => {
    dispatch(blockedStockAction.filterBlockedStockReasons({
      isVisibleByWarehouse: true,
    }));
  },
  onGetSupplierList: () => {
    dispatch(supplierAction.getSupplierList());
  },
});

const ListTabFilterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(ListTabFilter));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { ListTabFilterConnected as ListTabFilter };
