/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Row, Icon, Modal, notification } from 'antd';
import { withNamespaces } from 'react-i18next';
import QRCode from 'qrcode';
/*----------------------------------------------------------------------------*/
import './OutgoingTransferCreate.scss';
import { WaybillUpload } from './UploadModal';
import { OutgoingTransferProductList } from './OutgoingTransferProductList';
import { TransferDataFormatter } from './TransferDataFormatter';
import { constants } from '../../../../shared';
/*----------------------------------------------------------------------------*/
import { Spinner, PrintButton } from '../../../../components';
import { outgoingTransferAction } from '../../../../actions';
import i18n from '../../../../i18n';
import { pageView } from '../../../../services/segment';
import { pageLoadEvents } from '../../../../services/events';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class OutgoingCaseAndPalletTransferCreate extends Component {
  settings = {
    main: {
      className: 'OutgoingTransferCreate_main',
    },
    card: {
      className: 'no-padding-card',
      title: this.props.t('pages.Stock.OutgoingTransfer.create.title'),
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
    this.productListRef = React.createRef();
  }

  stateFactory = () => ({
    info: null,
    itemList: [],
    previewData: null,
    previewColumns: null,
    imported: false,
    selectedProductWithLocation: null,
    modalVisible: false,
    waybills: [],
    isEmptyState: false,
    preparedTransferData: [],
    preparedTransferItems: [],
    disableProductList: false,
    enterWaybillEnabled: false,
    printed: false,
    clickedTransferButton: false,
    modalGenerateQrVisible: false,
    palletBarcode: null,
    isQrPrinted: false,
  });

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.STOCK_CREATE_CASE_AND_PALLET });
    const { onGetProductList } = this.props;
    onGetProductList();
  }

  render() {
    const { ProducListLoading } = this.props;
    if (ProducListLoading) {
      return <Spinner />;
    }

    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageLayout />
          <this.ModalGenerateQr />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    const settings = {
      div: {
        style: { display: 'flex' },
      },
    };
    return (
      <div {...settings.div}>
        <this.WaybillButton />
      </div>
    );
  };

  WaybillButton = (props) => {
    const { selectedProductWithLocation } = this.state;
    const items = TransferDataFormatter.preparePrintData(
      this.props.productList.data,
      selectedProductWithLocation,
      this.props.currentWarehouse.name,
    );
    return (
      <div>
        <Button
          block
          disabled={!selectedProductWithLocation}
          onClick={() => {
            this.setState({ modalVisible: true });
          }}
        >
          {this.props.t(
            'pages.Stock.OutgoingTransfer.create.button.enterWaybill',
          )}
        </Button>
        <Modal
          width={734}
          okButtonProps={{}}
          onCancel={() => {
            this.setState({ modalVisible: false });
          }}
          title={this.props.t(
            'pages.Stock.OutgoingTransfer.create.button.enterWaybill',
          )}
          visible={this.state.modalVisible}
          footer={[
            <Button
              key="printQr"
              onClick={() => {
                this.setState({
                  modalGenerateQrVisible: true,
                  isQrPrinted: true,
                });
                setTimeout(() => {
                  this.generateQr();
                });
              }}
            >
              {this.props.t(
                'pages.Stock.OutgoingTransfer.create.button.generatePalletQr',
              )}
            </Button>,
            <PrintButton
              key="print"
              items={items}
              changeParentState={() => {
                console.log('onPrint', this.state);
                this.setState({ printed: true });
              }}
              settings={{
                label: (
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Icon type="printer" style={{ marginRight: 5 }} />
                    {this.props.t(
                      'pages.Stock.OutgoingTransfer.create.button.printWaybill',
                    )}
                  </span>
                ),
                button: {
                  disabled: !this.state.isQrPrinted,
                },
              }}
            />,
            <Button
              key="cancel"
              onClick={async () => {
                this.clearState();
              }}
            >
              {this.props.t(
                'pages.Stock.OutgoingTransfer.create.button.cancelWaybill',
              )}
            </Button>,
            <Button
              key="save"
              disabled={
                !this.state.waybills.length ||
                !this.state.printed ||
                this.state.clickedTransferButton
              }
              type="danger"
              onClick={() => {
                this.setState({ clickedTransferButton: true });
                this.createTransfer();
              }}
            >
              {this.props.t('pages.Stock.OutgoingTransfer.create.button.save')}
            </Button>,
          ]}
        >
          <WaybillUpload
            waybills={this.state.waybills}
            disabled={!this.state.printed}
            onChange={(waybills) => {
              this.setState({ waybills });
            }}
          />
        </Modal>
      </div>
    );
  };

  generateQr = () => {
    const qr = { type: 130, data: { code: this.getPalletQrStr() } };
    QRCode.toCanvas(
      document.getElementById('canvas'),
      JSON.stringify(qr),
      { errorCorrectionLevel: 'H' },
      (error) => {
        if (error) {
          console.error(error);
        }
      },
    );
  };

  getPalletQrStr = () => {
    const { currentWarehouse } = this.props;
    if (!currentWarehouse) {
      return null;
    }
    const from = this.transformStr(currentWarehouse.name);
    const to = this.transformStr('MAIN');
    const timestamp = Date.now().toString();
    const palletBarcode = `${from}.${to}.${timestamp}`;
    this.setState({ palletBarcode });
    return palletBarcode;
  };

  transformStr = (str) => {
    return this.replaceTurkish(str)
      .toUpperCase()
      .split(' ')
      .join('')
      .split("'")
      .join('')
      .slice(0, 3)
      .normalize();
  };

  replaceTurkish = (str) => {
    return str
      .replace('ü', 'u')
      .replace('ı', 'i')
      .replace('ö', 'o')
      .replace('ş', 's')
      .replace('ğ', 'g')
      .replace('ç', 'c')
      .replace('İ', 'I')
      .replace('Ö', 'O')
      .replace('Ü', 'U')
      .replace('Ş', 'S')
      .replace('Ğ', 'G')
      .replace('Ç', 'C');
  };

  printCanvas = () => {
    const dataUrl = document.getElementById('canvas').toDataURL();
    let windowContent = '<!DOCTYPE html>';
    windowContent += '<html>';
    windowContent += '<head><title>Pallet QR</title></head>';
    windowContent += '<body>';
    windowContent += `<img src="${dataUrl}">`;
    windowContent += '</body>';
    windowContent += '</html>';
    const printWin = window.open('', '', 'width=400,height=400');
    const printEvent = new Event('print');
    printWin.document.open();
    printWin.document.write(windowContent);
    printWin.document.addEventListener(
      'print',
      () => {
        printWin.focus();
        printWin.print();
        printWin.document.close();
        printWin.close();
      },
      true,
    );
    printWin.document.addEventListener(
      'load',
      () => {
        printWin.document.dispatchEvent(printEvent);
      },
      true,
    );
  };

  ModalGenerateQr = (props) => {
    return (
      <Modal
        title={this.props.t(
          'pages.Stock.OutgoingTransfer.create.modalQr.title',
        )}
        visible={this.state.modalGenerateQrVisible}
        onOk={() => {
          this.setState({ modalGenerateQrVisible: false });
        }}
        onCancel={() => {
          this.setState({ modalGenerateQrVisible: false });
        }}
        footer={<this.ModalFooter />}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <canvas id="canvas" width="300" height="300" />
        </div>
      </Modal>
    );
  };

  ModalFooter = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span>
          <Button
            key="close"
            type="danger"
            onClick={() => {
              this.setState({ modalGenerateQrVisible: false });
            }}
          >
            {this.props.t(
              'pages.Stock.OutgoingTransfer.create.modalQr.button.close',
            )}
          </Button>
        </span>
        <span>
          <Button
            key="print"
            type="primary"
            onClick={() => {
              this.printCanvas();
              this.setState({ modalGenerateQrVisible: false });
            }}
          >
            {this.props.t(
              'pages.Stock.OutgoingTransfer.create.modalQr.button.print',
            )}
          </Button>
        </span>
      </div>
    );
  };

  PageLayout = (props) => (
    <div>
      <Row>
        <this.TransferProductList />
      </Row>
    </div>
  );

  TransferProductList = (props) => {
    const { productList, productLoading } = this.props;
    if (productLoading) {
      return null;
    }
    const preparedItems = TransferDataFormatter.getPreparedItems(
      productList.data,
    );

    return (
      <OutgoingTransferProductList
        ref={this.productListRef}
        productList={preparedItems}
        loading={productLoading}
        onChange={this.onTransferProductListChange}
        disabled={this.state.disableProductList}
      />
    );
  };

  toggleModal = () => {
    const { waybills, preparedTransferItems } = this.state;

    const waybillCount = Math.ceil(
      preparedTransferItems.length / constants.PRODUCT_COUNT_PER_WAYBILL,
    );

    if (waybills.length !== waybillCount) {
      notification.error({
        duration: 3,
        message: `${waybillCount} ${this.props.t(
          'pages.Stock.OutgoingTransfer.create.notification.invalidWaybillMessage',
        )}`,
        description: this.props.t(
          'pages.Stock.OutgoingTransfer.create.notification.invalidWaybillDescription',
        ),
      });
    } else {
      this.setState((prevState) => ({
        modalVisible: !prevState.modalVisible,
      }));
    }
  };

  onTransferProductListChange = (itemList, selectedProductWithLocation) => {
    this.setState({ itemList, selectedProductWithLocation });
  };

  createTransfer = async () => {
    const { selectedProductWithLocation, waybills, palletBarcode } = this.state;

    const preparedTransferData = TransferDataFormatter.prepareTransferData(
      selectedProductWithLocation,
    );

    this.props.onSelfOutgoingCaseAndPalletTransferCreate({
      items: preparedTransferData,
      waybills,
      palletBarcodes: [palletBarcode],
    });
  };

  isCreateButtonDisabled = () => {
    const { info, previewData } = this.state;
    return !info || !info.transferType || !previewData;
  };

  isPreviewDisabled = () => {
    const { info, itemList } = this.state;
    return (
      !info ||
      !info.transferType ||
      !info.fromWarehouse ||
      !info.toWarehouses ||
      !info.toWarehouses.length ||
      !itemList ||
      !itemList.length
    );
  };

  isProductCountDisabled = () => {
    const { info, imported } = this.state;
    return (
      !info ||
      !info.transferType ||
      !info.fromWarehouse ||
      !info.toWarehouses ||
      !info.toWarehouses.length ||
      imported
    );
  };

  clearState = () => {
    this.productListRef.current.clearState();
    this.setState(this.stateFactory());
  };
}

const mapStateToProps = (state) => ({
  currentWarehouseId: state.auth.currentWarehouse._id,
  productList: state.outgoingTransfer.productList,
  productLoading: state.outgoingTransfer.productList.loading,
  CWId: state.auth.currentWarehouse._id,
  currentWarehouse: state.auth.currentWarehouse,
});

const mapDispatchToProps = (dispatch) => ({
  onGetProductList: () => {
    const { CONSUMPTION } = constants.WAREHOUSE_LOCATION_OPERATION_TYPE;
    dispatch(
      outgoingTransferAction.createSelfOutgoingTransferProductList({
        isAllowedForSelfTransfer: true,
        locationOperationTypes: [CONSUMPTION],
      }),
    );
  },
  onSelfOutgoingCaseAndPalletTransferCreate: ({
    items,
    waybills,
    palletBarcodes,
  }) => {
    const errorMessage = i18n.t(
      'pages.Stock.OutgoingTransfer.create.notification.anErrorOccurred',
    );
    dispatch(
      outgoingTransferAction.createSelfOutgoingCaseAndPalletTransferWithWaybills(
        {
          items,
          waybills,
          palletBarcodes,
          errorMessage,
        },
      ),
    );
  },
});

const OutgoingCaseAndPalletTransferCreateConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(OutgoingCaseAndPalletTransferCreate));

export { OutgoingCaseAndPalletTransferCreateConnected as OutgoingCaseAndPalletTransferCreate };
