import { createHttpService } from '../createHttpService';
import { QueryBuilder } from '../QueryBuilder';

const httpService = createHttpService();

export const refund = {
  createRefund: (data) => httpService.post(`/blocked-stocks/refunds`, data),
  filterRefundList: (query, filter) => httpService.post(`/blocked-stocks/filter?${QueryBuilder.getQueryStr(query)}`, filter),
  generateEwaybill: (refundId, data) => httpService.post(`/blocked-stocks/refunds/${refundId}/e-waybills/generate`, data),
  createWaybill: (refundId, data) => httpService.post(`/blocked-stocks/refunds/${refundId}/waybills/create`, data),
};
