import React, { Component } from 'react';
import {
  Card, Table, Avatar, Tag, Button,
} from 'antd';
/*----------------------------------------------------------------------------*/
import './ProductList.scss';
import moment from 'moment';
import uniqid from 'uniqid';
/*----------------------------------------------------------------------------*/
import { withNamespaces } from 'react-i18next';
import {
  Spinner,
  EditableCell,
  EditableFormRow,
  CopyToClipboard,
} from '../../../../components';
import { tableHelper, getDateTimeFormatString } from '../../../../shared';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class ProductList extends Component {
  settings = {
    main: {
      className: 'OutgoingTransferProductList_main',
    },
    card: {
      title: this.props.t('pages.Stock.WarehouseStockList.ProductList.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.productList) {
      return {
        items: props.productList,
      };
    }
    return null;
  }

  stateFactory = () => ({
    filter: '',
    items: [],
    isExpandAll: false,
    selectedExpandRowKeys: [],
  });

  CardExtra = (props) => (
    <Button onClick={this.expandAllRow}>
      {this.state.isExpandAll ?
        this.props.t('pages.Stock.WarehouseStockList.ProductList.extra.collapseAll')
        : this.props.t('pages.Stock.WarehouseStockList.ProductList.extra.expandAll')}
    </Button>
  );

  expandAllRow = () => {
    const records = this.prepareData(this.props);
    this.setState((state) => ({
      ...state,
      isExpandAll: !state.isExpandAll,
      selectedExpandRowKeys: (!state.isExpandAll && records) ? records.map((r) => r.id) : [],
    }));
  };

  PageTable = (props) => {
    const records = this.prepareData(this.props);
    return (
      <Table
        size="small"
        columns={this.prepareColumns(this.props)}
        dataSource={records}
        rowKey={(record) => record.id}
        expandedRowKeys={this.state.selectedExpandRowKeys}
        expandedRowRender={this.expandedRowRender}
        onExpand={(expanded, record) => {
          if (expanded) {
            this.setState((state) => ({ selectedExpandRowKeys: [...state.selectedExpandRowKeys, record.id] }));
          } else {
            this.setState((state) => ({
              selectedExpandRowKeys: state.selectedExpandRowKeys.filter((r) => r !== record.id),
            }));
          }
        }}
        components={this.components}
        loading={{ indicator: <Spinner />, spinning: this.props.loading }}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          pageSizeOptions: ['50', '100', '200', '300'],
        }}
      />
    );
  };

  expandedRowRender = (record, index, indent, expanded) => (
    <Table
      size="small"
      columns={this.prepareExtendedColumns(record)}
      dataSource={this.prepareExtendedData(record)}
      components={this.components}
      rowKey={(record) => record.id + uniqid()}
      pagination={false}
    />
  );

  prepareColumns = () => {
    const columns = [
      {
        title: this.props.t('pages.Stock.WarehouseStockList.ProductList.productTable.barcode'),
        dataIndex: 'barcodes',
        key: `barcodes${uniqid()}`,
        width: 120,
        render: (barcodes) => (
          <CopyToClipboard
            color="blue"
            style={{ margin: 2 }}
            key={`id${uniqid()}`}
            message={barcodes[0]}
          />
        ),
      },
      {
        title: this.props.t('pages.Stock.WarehouseStockList.ProductList.productTable.image'),
        dataIndex: 'picURL',
        key: `picURL${uniqid()}`,
        width: 70,
        render: (picURL) => (
          <Avatar className="grow" shape="square" src={picURL} />
        ),
      },
      {
        title: this.props.t('pages.Stock.WarehouseStockList.ProductList.productTable.productId'),
        dataIndex: 'id',
        key: `id${uniqid()}`,
        width: 70,
        render: (id) => <CopyToClipboard key={`id${uniqid()}`} message={id} />,
      },
      {
        title: this.props.t('pages.Stock.WarehouseStockList.ProductList.productTable.fullName'),
        dataIndex: 'name',
        key: `name${uniqid()}`,
        render: (name) => (
          <CopyToClipboard key={`name${uniqid()}`} message={name} />
        ),
      },
    ];

    return columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          min: 0,
        }),
      };
    });
  };

  prepareData = (props) => {
    const { items } = this.state;
    if (!items || !items.length) {
      return null;
    }
    return items.filter((product) => (product.name ? product.name.toUpperCase() : true));
  };

  prepareExtendedData = (record) => record.locations;

  prepareExtendedColumns = (record) => {
    const columns = [
      {
        title: this.props.t('pages.Stock.WarehouseStockList.ProductList.productTable.barcode'),
        dataIndex: 'barcode',
        key: `barcode${uniqid()}`,
        render: (barcode) => (
          <Tag color="red" style={{ margin: 2 }}>
            {barcode.toUpperCase()}
          </Tag>
        ),
      },
      {
        title: this.props.t('pages.Stock.WarehouseStockList.ProductList.productTable.expiryDate'),
        dataIndex: 'expiry',
        key: `expiry${uniqid()}`,
        ...tableHelper.fieldSorter.getDateSortProps('expiry'),
        render: (expiry) => (expiry
          ? moment(new Date(expiry))
            .local()
            .format(getDateTimeFormatString())
          : '-'),
      },
      {
        title: this.props.t('pages.Stock.WarehouseStockList.ProductList.productTable.locationQuantity'),
        dataIndex: 'quantity',
        key: `quantity${uniqid()}`,
        render: (quantity) => quantity,
      },
    ];

    return columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        // eslint-disable-next-line no-shadow
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          min: 0,
        }),
      };
    });
  };


  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageTable />
        </Card>
      </div>
    );
  }
}

const ProductListTranslated = withNamespaces('translation')(ProductList);

export { ProductListTranslated as ProductList };
