const t = (s) => s;
export const navigation = {
  items: [
    {
      name: t('main.SIDEBAR.DASHBOARD'),
      url: '/dashboard/orders',
      permission: { action: 'read' },
    },
    {
      name: t('main.SIDEBAR.MAP'),
      url: '/dashboard/map',
      permission: { action: 'read' },
    },
    {
      name: t('main.SIDEBAR.STOCK'),
      rootUrl: '/stock',
      children: [
        {
          name: t('main.SIDEBAR.INCOMING_TRANSFER_LIST'),
          url: '/stock/transfer/list',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.OUTGOING_TRANSFER_LIST'),
          url: '/stock/transfer/outgoing/list',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.PURCHASE_ORDER_LIST'),
          url: '/stock/purchase-order/list',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.BLOCKED_STOCK_LIST'),
          url: '/stock/blocked-stock/list',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.STOCK_REFUND_LIST'),
          url: '/stock/blocked-stock/refund/list',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.PRODUCT_LIST'),
          url: '/stock/product/list',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.EXPIRATION_DATE_LIST'),
          url: '/stock/expiration-date/list',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.INVENTORY_CHECK'),
          url: '/stock/inventory-check/list',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.MOVEMENT_LOG_LIST'),
          url: '/stock/movement-log/list',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.WAREHOUSE_STOCK_LIST'),
          url: '/stock/warehouse-stock/list',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.HEATING_ESTIMATION'),
          url: '/stock/heating-estimation/list',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.CANCELLED_ORDERS'),
          url: '/stock/cancelled-orders',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.RETURNED_ORDERS'),
          url: '/stock/returned-orders',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.MISSING_PRODUCTS_ORDERS'),
          url: '/stock/order-missing-products',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.EQUIPMENT_LIST'),
          url: '/stock/equipment-request/list',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.RETAIL_WALK_IN'),
          url: '/stock/retail-walk-in-orders/list',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.RETAIL_WALK_IN_REFUND'),
          url: '/stock/retail-walk-in-orders-refund/list',
          permission: { action: 'read' },
        },
      ],
    },
    {
      name: t('main.SIDEBAR.OPERATION_STOCK'),
      rootUrl: '/stock-operation',
      children: [
        {
          name: t('main.SIDEBAR.TRANSFER'),
          rootUrl: '/stock-operation/transfer',
          children: [
            {
              name: t('main.SIDEBAR.TRANSFER_LIST'),
              url: '/stock-operation/transfer/list',
              permission: { action: 'read' },
            },
            {
              name: t('main.SIDEBAR.TRANSFER_BULK_APPROVE'),
              url: '/stock-operation/transfer/bulk-approve',
              permission: { action: 'read' },
            },
            {
              name: t('main.SIDEBAR.TRANSFER_BULK_CANCEL'),
              url: '/stock-operation/transfer/bulk-cancel',
              permission: { action: 'read' },
            },
            {
              name: t('main.SIDEBAR.TRANSFER_CREATE'),
              url: '/stock-operation/transfer/create',
              permission: { action: 'read' },
            },
          ],
        },

        {
          name: t('main.SIDEBAR.PURCHASE_ORDER'),
          rootUrl: '/stock-operation/purchase-order',
          children: [
            {
              name: t('main.SIDEBAR.PURCHASE_ORDER_LIST'),
              url: '/stock-operation/purchase-order/list',
              permission: { action: 'read' },
            },
            {
              name: t('main.SIDEBAR.PURCHASE_ORDER_CREATE'),
              url: '/stock-operation/purchase-order/create',
              permission: { action: 'read' },
            },
            {
              name: t('main.SIDEBAR.WAREHOUSE_PURCHASE_ORDER_LIST'),
              url: '/stock-operation/warehouse-purchase-order/list',
              permission: { action: 'read' },
            },
          ],
        },

        {
          name: t('main.SIDEBAR.BLOCKED_STOCK'),
          rootUrl: '/stock-operation/blocked-stock',
          children: [
            {
              name: t('main.SIDEBAR.BLOCKED_STOCK_LIST'),
              url: '/stock-operation/blocked-stock/list',
              permission: { action: 'read' },
            },
            {
              name: t('main.SIDEBAR.BLOCKED_STOCK_BULK_DECISION'),
              url: '/stock-operation/blocked-stock/bulk-decision',
              permission: { action: 'read' },
            },
            {
              name: t('main.SIDEBAR.BLOCKED_STOCK_RULE_LIST'),
              url: '/stock-operation/blocked-stock/decision-rule/list',
              permission: { action: 'read' },
            },
            {
              name: t('main.SIDEBAR.BLOCKED_STOCK_TRANSFER_LIST'),
              url: '/stock-operation/blocked-stock/transfer/list',
              permission: { action: 'read' },
            },
            {
              name: t('main.SIDEBAR.BLOCKED_STOCK_DISPOSAL_LIST'),
              url: '/stock-operation/blocked-stock/disposal/list',
              permission: { action: 'read' },
            },
            {
              name: t('main.SIDEBAR.BLOCKED_STOCK_REFUND_LIST'),
              url: '/stock-operation/blocked-stock/refund/list',
              permission: { action: 'read' },
            },
          ],
        },
        {
          name: t('main.SIDEBAR.INVENTORY_CHECK'),
          rootUrl: '/stock-operation/inventory-check',
          children: [
            {
              name: t('main.SIDEBAR.INVENTORY_CHECK_LIST'),
              url: '/stock-operation/inventory-check/list',
              permission: { action: 'read' },
            },
            {
              name: t('main.SIDEBAR.INVENTORY_CHECK_BULK_OPERATION'),
              url: '/stock-operation/inventory-check/bulk-approve',
              permission: { action: 'read' },
            },
            {
              name: t('main.SIDEBAR.INVENTORY_CHECK_CREATE'),
              url: '/stock-operation/inventory-check/create',
              permission: { action: 'read' },
            },
          ],
        },
        {
          name: t('main.SIDEBAR.WAREHOUSE_STATUS_CONTROL'),
          url: '/stock-operation/warehouse-status/control',
          permission: { action: 'read' },
        },
        {
          name: t('main.SIDEBAR.MOVEMENT_LOG_LIST'),
          url: '/stock-operation/movement-log/list',
          permission: { action: 'read' },
        },
        {
          name: t('pages.title.PRODUCTION_MODULE'),
          url: '/stock-operation/production-module',
          permission: { action: 'read' },
        },
        {
          name: t('pages.title.TRANSACTIONS'),
          url: '/stock-operation/transactions/list',
          permission: { action: 'read' },
        },
      ],
    },
  ],
};
