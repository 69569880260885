import { createHttpService } from '../createHttpService';

const WAREHOUSE_ID = localStorage.getItem('warehouseId');

const httpService = createHttpService();

export const productionModule = {
  executeProductionModule: (data) =>
    httpService.post(`/warehouse/${WAREHOUSE_ID}/craft-products`, data),
};
