import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';
import { getCountry } from './../shared';

export const cityAction = {
  filterCityList,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function filterCityList() {
  return (dispatch) => {
    dispatch({ type: actionType.filterCityList_InProgress });
    const country = getCountry();
    if (!country) {
      throw new Error('No country');
    }
    http
      .filterCityList({ countryId: country._id })
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.filterCityList_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.filterCityList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.filterCityList_Failed,
          payload: error,
        });
      });
  };
}
