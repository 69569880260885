import React from 'react';
import { Avatar } from 'antd';
import { uniq } from 'lodash';


import i18n from './../../../../../i18n';
import { getTranslation } from './../../../../../shared';


export class DataTable {
  static getManualWpoColunms(warehouseList) {
    if (!warehouseList || !warehouseList.length) {
      return [];
    }
    return warehouseList.map((warehouse) => {
      return {
        warehouseId: warehouse.id,
        editable: true,
        dataIndex: `warehouses.${warehouse.id}`,
        key: `warehouses.${warehouse.id}`,
        title: warehouse.name,
        width: 120,
        min: 0,
      };
    });
  }

  static getManualProductColunms(productList) {
    if (!productList || !productList.length) {
      return [];
    }
    return [
      {
        title: i18n.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.image',
        ),
        fixed: true,
        dataIndex: 'product.picURL',
        key: 'picURL',
        width: 75,
        render: (picURL) => {
          if (!picURL) {
            return null;
          }
          return (
            <Avatar
              className="grow"
              shape="square"
              src={picURL ? getTranslation.obj(picURL) : null}
            />
          );
        },
      },
      {
        title: i18n.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.product',
        ),
        fixed: true,
        width: 300,
        dataIndex: `product.fullName.${getTranslation.local()}`,
        ellipsis: true,
        key: 'product.fullName',
      },
      {
        title: i18n.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.unitPrice',
        ),
        key: 'product.price',
        fixed: true,
        width: 75,
        dataIndex: 'product.price',
        ellipsis: true,
      },
      {
        title: i18n.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.wholesaleVat',
        ),
        key: 'product.wholesaleVat',
        fixed: true,
        width: 75,
        dataIndex: 'product.wholesaleVat',
        ellipsis: true,
      },
      {
        title: i18n.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.inboxQuantity',
        ),
        key: 'product.inboxQuantity',
        fixed: true,
        width: 75,
        dataIndex: 'product.inboxQuantity',
        ellipsis: true,
      },
      {
        title: i18n.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.total',
        ),
        key: 'product.total',
        fixed: true,
        width: 75,
        dataIndex: 'product.total',
        ellipsis: true,
      },
    ];
  }

  static getManualColunms(selectedProductList, selectedWarehouseList) {
    const productColumns = DataTable.getManualProductColunms(
      selectedProductList,
    );
    const warehouseColumns = DataTable.getManualWpoColunms(
      selectedWarehouseList,
    );
    return [...productColumns, ...warehouseColumns];
  }

  static getImportedColunms(productList, warehouseList, importedData) {
    const productColumns = DataTable.getImportedProductColunms(
      productList,
      importedData,
    );
    const warehouseColumns = DataTable.getImportedWpoColunms(
      warehouseList,
      importedData,
    );
    return [...productColumns, ...warehouseColumns];
  }

  static getImportedProductColunms(productList, importedData) {
    const selectedProductList = [];
    for (let j = 1; j < importedData.length; j++) {
      const productId = importedData[j][0];
      selectedProductList.push(
        productList.find((product) => product.id === productId),
      );
    }
    return DataTable.getManualProductColunms(selectedProductList);
  }

  static getImportedWpoColunms = (warehouseList, importedData) => {
    const selectedWarehouseList = importedData[0]
      .filter((item) => /^warehouse./.test(item))
      .map((item) => {
        const warehouseId = item.split('.')[1];
        return warehouseList.find((warehouse) => warehouse.id === warehouseId);
      });
    return DataTable.getManualWpoColunms(selectedWarehouseList);
  };

  static transfromManualData(warehouseList, productList) {
    const data = [];
    productList.forEach((p) => {
      const product = { ...p };
      product.price = product.price || 0;
      product.inboxQuantity = product.packagingInfo[3].inboxQuantity || 0;
      product.total = 0;
      const record = {
        id: product.id,
        product,
        warehouses: {},
        operation: true,
      };
      warehouseList.forEach((warehouse) => {
        record.warehouses[warehouse.id] = 0;
      });
      record.key = record.id;
      record.operation = true;
      data.push(record);
    });
    data.push(DataTable.getManualTotalRow(warehouseList));
    return data;
  }

  static getManualTotalRow(warehouseList) {
    const record = {
      id: 'total',
      key: 'total',
      product: {
        id: 'total',
        total: 0,
        fullName: i18n.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.total',
        ),
      },
      warehouses: {},
      operation: false,
    };
    warehouseList.forEach((warehouse) => {
      record.warehouses[warehouse.id] = 0;
    });
    return record;
  }

  static getImportedFileColunms(importedData) {
    return importedData[0].map((item) => {
      if (/^warehouse./.test(item)) {
        return item.split('.')[1];
      }
      return item;
    });
  }

  static trim(importedData) {
    return importedData.filter((item) => item.length > 1);
  }

  static transformImportedData(importedData, warehouseList, productList) {
    importedData = DataTable.trim(importedData);
    const transformedData = [];
    const columns = DataTable.getImportedFileColunms(importedData);
    for (let j = 1; j < importedData.length; j++) {
      let total = 0;
      const row = importedData[j].reduce(
        (acc, item, index) => {
          if (index === 0) {
            acc.id = item;
            acc.product = productList.find((product) => product.id === item);
            if (!acc.product) {
              throw new Error(
                i18n.t(
                  'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.error.wrongSupplier',
                ),
              );
            }
          }
          if (index === 1) {
            const count = parseFloat(item);
            if (count < 0 || !Number.isInteger(count)) {
              throw new Error(
                i18n.t(
                  'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.error.csvFileHasInvalidProductRecord',
                ),
              );
            }
            acc.product.inboxQuantity = item;
          }
          if (index > 1) {
            acc.warehouses[columns[index]] = parseInt(item);
            total += parseInt(item);
          }
          return acc;
        },
        { warehouses: {} },
      );
      row.key = row.id;
      row.product.total = total;
      row.operation = true;
      transformedData.push(row);
    }
    DataTable.checkImportedData(transformedData, columns);
    transformedData.push(DataTable.getImportedTotalRow(transformedData));
    return transformedData;
  }

  static getImportedTotalRow(transformedData) {
    const totalProducts = transformedData.reduce((acc, item, index) => {
      acc += item.product.total;
      return acc;
    }, 0);
    const totalProductsPerWarehouse = transformedData.reduce(
      (acc, item, index) => {
        if (index === 0) {
          return acc;
        }
        const { warehouses } = item;
        for (const warehouseId in warehouses) {
          if (Object.hasOwnProperty.call(warehouses, warehouseId)) {
            acc[warehouseId] += warehouses[warehouseId];
          }
        }
        return acc;
      },
      { ...transformedData[0].warehouses },
    );
    const totalRow = {
      id: 'total',
      key: 'total',
      product: {
        id: 'total',
        total: totalProducts,
        fullName: i18n.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.total',
        ),
      },
      warehouses: totalProductsPerWarehouse,
      operation: false,
    };
    return totalRow;
  }

  static recalculateTotal(transformedDataWithTotalRow) {
    if (transformedDataWithTotalRow.length === 1) {
      return [];
    }
    const totalPerWarehouse = transformedDataWithTotalRow.reduce(
      (acc, item, index) => {
        if (index === 0 || index === transformedDataWithTotalRow.length - 1) {
          return acc;
        }
        const { warehouses } = item;
        for (const warehouseId in warehouses) {
          if (Object.hasOwnProperty.call(warehouses, warehouseId)) {
            acc[warehouseId] += warehouses[warehouseId];
          }
        }
        return acc;
      },
      { ...transformedDataWithTotalRow[0].warehouses },
    );
    let totalProducts = 0;
    for (const warehouseId in totalPerWarehouse) {
      if (Object.hasOwnProperty.call(totalPerWarehouse, warehouseId)) {
        totalProducts += totalPerWarehouse[warehouseId];
      }
    }
    const totalRow = {
      id: 'total',
      key: 'total',
      product: {
        id: 'total',
        total: totalProducts,
        fullName: i18n.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.table.total',
        ),
      },
      warehouses: totalPerWarehouse,
      operation: false,
    };
    return transformedDataWithTotalRow.map((item, index) => {
      if (item.id === 'total') {
        return totalRow;
      }
      let totalPerProduct = 0;
      const { warehouses } = item;
      for (const warehouseId in warehouses) {
        if (Object.hasOwnProperty.call(warehouses, warehouseId)) {
          totalPerProduct += warehouses[warehouseId];
        }
      }
      item.product.total = totalPerProduct;
      return item;
    });
  }

  static makeData(rawData, columns) {
    const warehousePurchaseOrders = columns
      .filter((item) => /^warehouse./.test(item.key))
      .map((item) => {
        const warehouse = item.key.split('.')[1];
        const items = [];
        rawData.forEach((row) => {
          for (const [key] of Object.entries(row.warehouses)) {
            if (
              key === warehouse &&
              row.warehouses[key] &&
              row.warehouses[key] !== '0' &&
              row.id !== 'total'
            ) {
              items.push({
                item: row.id,
                orderedCount: row.warehouses[key],
              });
            }
          }
        });
        return { warehouse, items };
      })
      .filter((item) => item.items.length);

    return warehousePurchaseOrders;
  }

  static isArrayDataUniq = (arr) => {
    if (arr.length === uniq(arr).length) {
      return true;
    }
    return false;
  };

  static isProductDataUniq = (transformedData) => {
    const rowKeys = transformedData.map((row) => row.key);
    return DataTable.isArrayDataUniq(rowKeys);
  };

  static checkImportedData = (transformedData, columns) => {
    if (
      !DataTable.isProductDataUniq(transformedData) &&
      !DataTable.isArrayDataUniq(columns)
    ) {
      throw new Error(
        i18n.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.error.duplicateBoth',
        ),
      );
    }
    if (DataTable.isProductDataUniq(transformedData)) {
      if (DataTable.isArrayDataUniq(columns)) {
        // eslint-disable-next-line no-useless-return
        return;
      } else {
        throw new Error(
          i18n.t(
            'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.error.duplicateWarehouse',
          ),
        );
      }
    } else {
      throw new Error(
        i18n.t(
          'pages.Operation.PurchaseOrder.create.ProductsInfoPanel.error.duplicateProduct',
        ),
      );
    }
  };
}
