import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Table, Avatar, Modal, Divider } from 'antd';
import { withNamespaces } from 'react-i18next';
import Barcode from 'react-barcode';
/*----------------------------------------------------------------------------*/
import './ProductList.scss';
/*----------------------------------------------------------------------------*/
import { productAction } from './../../../../actions';
import { Spinner, CopyToClipboard } from './../../../../components';
import { tableHelper, getTranslation } from './../../../../shared';
import { ProductListFilter } from './ProductListFilter';
import { pageView } from '../../../../services/segment';
import { pageLoadEvents } from '../../../../services/events';

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class ProductList extends Component {
  settings = {
    main: {
      className: 'ProductList_main',
    },
    card: {
      title: this.props.t('pages.Stock.Product.list.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    const tableFilter = {};
    return {
      pagination,
      query,
      filter: '',
      tableFilter,
      showProductBarcodeModal: false,
      selectedProduct: [],
    };
  };

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.STOCK_PRODUCT_LIST });
    this.props.onGetProducts(this.props.CWId);
    this.props.onResetProductList();
  }

  render() {
    const { loading, products } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card}>
          <div>
            <ProductListFilter
              productList={products}
              onChange={this.setTableFilter}
              onClear={this.clearTableFilter}
            />
            {loading ? (
              <Spinner />
            ) : (
              <div>
                <this.PageTable />
                <this.ProductBarcodeModal />
              </div>
            )}
          </div>
        </Card>
      </div>
    );
  }

  calculateEmptyText = () => {
    if (
      this.state.tableFilter &&
      this.state.tableFilter.productIds &&
      this.state.tableFilter.productIds.length
    ) {
      return this.props.t('pages.Stock.Product.list.noData');
    }
    return this.props.t('pages.Stock.Product.list.noProductSelectedWarning');
  };

  PageTable = (props) => (
    <Table
      locale={{ emptyText: this.calculateEmptyText() }}
      size="small"
      dataSource={this.prepareData(this.props)}
      columns={this.prepareColumns()}
      rowKey={(record) => record.id}
      pagination={{ ...this.state.pagination, total: this.props.totalCount }}
      onChange={this.handleTableChange}
    />
  );

  prepareData = (props) => {
    if (!props.productList) {
      return null;
    }
    return props.productList
      .map((p) => {
        const product = p;
        if (!product.expActive && !product.expDays) {
          product.expDays = {
            lifetime: false,
          };
        }
        return product;
      })
      .filter((product) => {
        const { filter } = this.state;
        if (!filter) {
          return true;
        }
        return (
          product.fullName.tr.toUpperCase().includes(filter.toUpperCase()) ||
          product.id.includes(filter) ||
          product.barcodes.includes(filter)
        );
      });
  };

  prepareColumns = () => [
    {
      title: this.props.t('pages.Stock.Product.list.productTable.image'),
      dataIndex: 'picURL',
      key: 'picURL',
      width: 72,
      render: (picURL) => (
        <Avatar
          className="grow"
          shape="square"
          src={getTranslation.obj(picURL)}
        />
      ),
    },
    {
      title: this.props.t('pages.Stock.Product.list.productTable.id'),
      dataIndex: 'id',
      key: 'id',
      render: (id) => <CopyToClipboard message={id} />,
    },
    {
      title: this.props.t('pages.Stock.Product.list.productTable.fullName'),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (fullName) =>
        fullName && <CopyToClipboard message={getTranslation.obj(fullName)} />,
      ...tableHelper.fieldSorter.getStringSortProps(
        `fullName.${getTranslation.local()}`,
      ),
    },
    {
      title: this.props.t('pages.Stock.Product.list.productTable.storageType'),
      dataIndex: 'storageType',
      key: 'storageType',
      render: (storageType) => {
        if (storageType === 'ambient') {
          return this.props.t('pages.Stock.Product.list.storageType.ambient');
        }
        return storageType;
      },
    },
    {
      title: this.props.t('pages.Stock.Product.list.productTable.expDays'),
      dataIndex: 'expDays',
      key: 'expDays',
      render: (expDays) => {
        if (!expDays || !expDays.lifetime) {
          return '-';
        }
        return `${expDays.lifetime} ${this.props.t(
          'pages.Stock.Product.list.day',
        )}`;
      },
    },
    {
      title: this.props.t('pages.Stock.Product.list.productTable.expDaysDead'),
      dataIndex: 'expDays',
      key: 'expDaysDead',
      render: (expDays) => {
        if (!expDays || !expDays.dead) {
          return '-';
        }
        return `${expDays.dead} ${this.props.t(
          'pages.Stock.Product.list.day',
        )}`;
      },
    },
    {
      title: this.props.t(
        'pages.Stock.Product.list.productTable.productBarcode',
      ),
      dataIndex: 'barcodes',
      key: 'barcodes',
      render: (barcodes) => {
        if (!barcodes || !barcodes.length) {
          return null;
        }
        return barcodes.map((barcode) => <CopyToClipboard message={barcode} />);
      },
    },
  ];

  ProductBarcodeModal = () => (
    <Modal
      title={this.props.t(
        'pages.Stock.Product.list.productTable.productBarcode',
      )}
      visible={this.state.showProductBarcodeModal}
      centered
      onOk={() => {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ showProductBarcodeModal: false });
      }}
      onCancel={() => {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ showProductBarcodeModal: false });
      }}
      footer={[]}
    >
      {this.state.selectedProduct.map((productBarcode, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div style={{ textAlign: 'center' }} key={index}>
          {index !== 0 ? <Divider /> : null}
          {<Barcode value={productBarcode} />}
        </div>
      ))}
    </Modal>
  );

  onChangeSearch = (event) => {
    this.setState({
      filter: event.target.value,
    });
  };

  setTableFilter = (filter) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        current: 1,
      },
    });
    this.setState({
      tableFilter: filter,
    });
    setTimeout(() => {
      this.getList();
    });
  };

  clearTableFilter = () => {
    const filter = this.getDefaultFilter();
    const query = this.getQuery();
    this.setState({
      tableFilter: filter,
    });
    this.props.onGetProductList(this.props.CWId, query, filter);
  };

  getDefaultFilter = () => ({
    productIds: [],
  });

  getList = () => {
    const filter = this.getFilter();
    let query = this.getQuery();

    if (filter.productIds && filter.productIds.length) {
      query = this.getQueryWithoutLimit();
    }

    this.props.onGetProductList(this.props.CWId, query, filter);
  };

  getFilter = () => {
    const { tableFilter } = this.state;
    return {
      productIds: tableFilter.productIds,
    };
  };

  getQuery = () => this.state.query;

  getQueryWithoutLimit = () => {
    const { query } = this.state;
    delete query.limit;
    return query;
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      query: {
        limit: pagination.pageSize,
        offset: pagination.pageSize * (pagination.current - 1),
      },
    });
    this.setState({
      pagination: {
        ...this.state.pagination,
        ...pagination,
      },
    });
    setTimeout(() => {
      this.getList();
    });
  };

  setPageFilter = () => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        current: 1,
      },
    });
    this.setState({
      query: {
        ...this.state.query,
        offset: 0,
      },
    });
    setTimeout(() => {
      this.getList();
    });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  loading: state.product.warehouseProductList.loading,
  productList: state.product.warehouseProductList.data,
  products: state.product.productList,
  totalCount: state.product.warehouseProductList.total,
  CWId: state.auth.currentWarehouse._id,
});

const mapDispatchToProps = (dispatch) => ({
  onGetProductList: (warehouseId, query, filter) =>
    dispatch(productAction.getProductList(warehouseId, query, filter)),
  onResetProductList: (warehouseId, query, filter) =>
    dispatch(productAction.resetWarehouseProductList()),
  onGetProducts: (warehouseId) =>
    dispatch(
      productAction.filterProductList(
        {
          fields: 'barcodes picURL fullName packagingInfo',
          includeDefaultFields: false,
          warehouseId,
        },
        false,
      ),
    ),
});

const ProductListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(ProductList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { ProductListConnected as ProductList };
