/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Descriptions,
  Button,
  Table,
  Tag,
  Modal,
  Row,
  Col,
  Progress,
  Icon,
  Divider,
  Avatar,
} from 'antd';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './InventoryCheckDetail.scss';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard } from './../../../../components';
import { inventoryAction } from './../../../../actions';
import { tableHelper, constants, getTranslation, getDateTimeFormatString } from './../../../../shared';
/*----------------------------------------------------------------------------*/

const {
  INVENTORY_CHECK_STATUS,
  INVENTORY_CHECK_TYPE,
  JOB_TASK_STATUSE,
} = constants;
const { confirm } = Modal;

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class InventoryCheckDetail extends Component {
  settings = {
    main: {
      className: 'InventoryCheckDetail_main',
    },
    card: {
      title: this.props.t('pages.Stock.InventoryCheck.detail.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    this.getDetail();
  }

  stateFactory = () => ({
    isClickedPrepareInventoryCheckButton: false,
    isClickedDeleteInventoryCheckButton: false,
  });

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {loading ? <Spinner /> : <this.PageDetail />}
        </Card>
      </div>
    );
  }

  CardExtra = (props) => (
    <div style={{ display: 'flex' }}>
      {/* <this.DeleteButton /> */}
      <this.SetReadyButton />
    </div>
  );

  DeleteButton = (props) => {
    const { isClickedDeleteInventoryCheckButton } = this.state;
    const settings = {
      deleteButton: {
        type: 'danger',
        style: { marginLeft: 10 },
        loading: isClickedDeleteInventoryCheckButton,
        disabled: isClickedDeleteInventoryCheckButton,
        onClick: () => {
          this.setState({ isClickedDeleteInventoryCheckButton: true });
          this.deleteInventoryCheck();
        },
      },
      deleteButtonLabel: this.props.t(
        'pages.Stock.InventoryCheck.detail.extra.deleteButton',
      ),
    };
    if (this.isDeleteButtonShow()) {
      return (
        <Button {...settings.deleteButton}>{settings.deleteButtonLabel}</Button>
      );
    }
    return null;
  };

  isDeleteButtonShow = () => {
    const { inventoryCheckDetail } = this.props;
    if (
      !inventoryCheckDetail ||
      !inventoryCheckDetail.data ||
      !inventoryCheckDetail.data.status
    ) {
      return false;
    }
    const { status } = inventoryCheckDetail.data;
    if (
      status === INVENTORY_CHECK_STATUS.NEW ||
      status === INVENTORY_CHECK_STATUS.READY
    ) {
      return true;
    }
    return false;
  };

  SetReadyButton = (props) => {
    const { isClickedPrepareInventoryCheckButton } = this.state;
    const settings = {
      button: {
        type: 'danger',
        loading: isClickedPrepareInventoryCheckButton,
        disabled: isClickedPrepareInventoryCheckButton,
        style: { marginLeft: 10 },
        onClick: () => {
          this.setState({ isClickedPrepareInventoryCheckButton: true });
          this.setItemReady();
        },
      },
      buttonLabel: this.props.t(
        'pages.Stock.InventoryCheck.detail.extra.setReadyButton',
      ),
    };
    if (this.isInventoryCheckStatusNew()) {
      return <Button {...settings.button}>{settings.buttonLabel}</Button>;
    }
    return null;
  };

  isInventoryCheckStatusNew = () => {
    const { inventoryCheckDetail } = this.props;
    if (
      !inventoryCheckDetail ||
      !inventoryCheckDetail.data ||
      !inventoryCheckDetail.data.status
    ) {
      return false;
    }
    const { status } = inventoryCheckDetail.data;
    if (status === INVENTORY_CHECK_STATUS.NEW) {
      return true;
    }
    return false;
  };

  PageDetail = (props) => (
    <div>
      <Row>
        <Col span={14}>
          <this.InventoryCheckDescription />
        </Col>
        <Col span={10}>
          <this.InventoryCheckCompletionRate />
        </Col>
      </Row>
      {this.isInventoryCheckStatusNew() ? null : (
        <this.InventoryCheckTaskTable />
      )}
    </div>
  );

  InventoryCheckCompletionRate = (props) => {
    const { inventoryCheckDetail } = this.props;
    if (
      !inventoryCheckDetail ||
      !inventoryCheckDetail.data ||
      !inventoryCheckDetail.data.job
    ) {
      return null;
    }
    return (
      <Descriptions column={1}>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.isChild',
          )}
        >
          {inventoryCheckDetail.data.isChildInventoryCheck ? (
            <Tag color="#5cb85c">
              {this.props.t(
                'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.enable',
              )}
            </Tag>
          ) : (
            <Tag color="#d9534f">
              {this.props.t(
                'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.disable',
              )}
            </Tag>
          )}
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.completionRate',
          )}
        >
          <div>
            <Progress
              type="circle"
              width={145}
              percent={inventoryCheckDetail.data.completionPercentage}
              format={(percent) =>
                `${inventoryCheckDetail.data.completedTasksCount}/${inventoryCheckDetail.data.taskCount}`
              }
            />
          </div>
        </Descriptions.Item>
      </Descriptions>
    );
  };

  InventoryCheckDescription = (props) => {
    const { inventoryCheckDetail } = this.props;
    if (
      !inventoryCheckDetail ||
      !inventoryCheckDetail.data ||
      !inventoryCheckDetail.data.job
    ) {
      return null;
    }
    return (
      <Descriptions column={2}>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption._id',
          )}
        >
          <CopyToClipboard message={inventoryCheckDetail.data.id} />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.warehouse',
          )}
        >
          <CopyToClipboard
            message={inventoryCheckDetail.data.warehouse.id}
            inner={inventoryCheckDetail.data.warehouse.name}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.owner',
          )}
        >
          <CopyToClipboard message={inventoryCheckDetail.data.job.id} />
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.type',
          )}
        >
          {getTranslation.inventoryCheckType(inventoryCheckDetail.data.type)}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.scope',
          )}
        >
          {getTranslation.inventoryCheckScope(inventoryCheckDetail.data.scope)}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.handover',
          )}
        >
          {inventoryCheckDetail.data.handover ? (
            <Tag color="#5cb85c">
              {this.props.t(
                'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.enable',
              )}
            </Tag>
          ) : (
            <Tag color="#d9534f">
              {this.props.t(
                'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.disable',
              )}
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.type',
          )}
        >
          {getTranslation.inventoryCheckJobType(
            inventoryCheckDetail.data.job.type.key,
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.status',
          )}
        >
          {getTranslation.inventoryCheckStatus(
            inventoryCheckDetail.data.status,
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.expiryDate.isExpiryDateRequired',
          )}
        >
          {inventoryCheckDetail.data.expiryDateRequired ? (
            <Tag color="#5cb85c">
              {this.props.t(
                'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.enable',
              )}
            </Tag>
          ) : (
            <Tag color="#d9534f">
              {this.props.t(
                'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.disable',
              )}
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.createdBy',
          )}
        >
          <CopyToClipboard
            message={
              inventoryCheckDetail.data.createdBy
                ? inventoryCheckDetail.data.createdBy.id
                : ''
            }
            inner={
              inventoryCheckDetail.data.createdBy
                ? inventoryCheckDetail.data.createdBy.name
                : ''
            }
            color="blue"
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.createdAt',
          )}
        >
          {moment(new Date(inventoryCheckDetail.data.createdAt))
            .local()
            .format(getDateTimeFormatString())}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.autoInventoryCheck',
          )}
        >
          {inventoryCheckDetail.data.isAutoInventoryCheck ? (
            <Tag color="#5cb85c">
              {this.props.t(
                'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.enable',
              )}
            </Tag>
          ) : (
            <Tag color="#d9534f">
              {this.props.t(
                'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.disable',
              )}
            </Tag>
          )}
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.isIncludedToSTS',
          )}
        >
          {inventoryCheckDetail.data.isIncludedToSTS ? (
            <Tag color="#5cb85c">
              {this.props.t(
                'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.enable',
              )}
            </Tag>
          ) : (
            <Tag color="#d9534f">
              {this.props.t(
                'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.disable',
              )}
            </Tag>
          )}
        </Descriptions.Item>

        {inventoryCheckDetail.data.isChildInventoryCheck ? (
          <Descriptions.Item
            label={this.props.t(
              'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.parent',
            )}
          >
            <Link
              target="_blank"
              to={`/r/${this.props.CWId}/stock/inventory-check/detail/${inventoryCheckDetail.data.parentInventoryCheck}`}
            >
              {this.props.t('pages.Stock.InventoryCheck.detail.link')}
            </Link>
          </Descriptions.Item>
        ) : null}
        {inventoryCheckDetail.data.childInventoryCheck ? (
          <Descriptions.Item
            label={this.props.t(
              'pages.Stock.InventoryCheck.detail.inventoryCheckDesctiption.child',
            )}
          >
            <Link
              target="_blank"
              to={`/r/${this.props.CWId}/stock/inventory-check/detail/${inventoryCheckDetail.data.childInventoryCheck}`}
            >
              {this.props.t('pages.Stock.InventoryCheck.detail.link')}
            </Link>
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item label={this.props.t('common.reason')}>
          {this.inventoryCheckReasonDescription(inventoryCheckDetail.data.reason)}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  inventoryCheckReasonDescription = (reason) => {
    if (!reason) {
      return '-';
    }
    return reason.type === constants.INVENTORY_CHECK_REASONS.OTHER
      ? reason.description
      : getTranslation.inventoryCheckReason(reason.type);
  };

  InventoryCheckTaskTable = (props) => {
    const { inventoryCheckDetail } = this.props;
    if (
      !inventoryCheckDetail ||
      !inventoryCheckDetail.data ||
      !inventoryCheckDetail.data.job ||
      !inventoryCheckDetail.data.job.tasks ||
      !inventoryCheckDetail.data.job.tasks.length
    ) {
      return null;
    }
    return (
      <Table
        size="small"
        columns={this.prepareColumns()}
        dataSource={this.prepareData()}
        rowKey={(record) => record.id}
        expandedRowRender={
          this.isLocationBased()
            ? (record, index, indent, expanded) => {
                const data = this.prepareProductData(record);
                if (!data || !data.length) {
                  return null;
                }
                return (
                  <Table
                    size="small"
                    columns={this.prepareProductColumns(record)}
                    dataSource={data}
                    rowKey={(record) => record.productId}
                  />
                );
              }
            : null
        }
      />
    );
  };

  isLocationBased = () => {
    const { inventoryCheckDetail } = this.props;
    if (
      !inventoryCheckDetail ||
      !inventoryCheckDetail.data ||
      !inventoryCheckDetail.data.type
    ) {
      return null;
    }
    return (
      inventoryCheckDetail.data.type === INVENTORY_CHECK_TYPE.LOCATION_BASED
    );
  };

  prepareColumns = (props) => {
    if (this.isLocationBased()) {
      return [
        {
          title: this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable._id',
          ),
          dataIndex: 'id',
          key: 'id',
          render: (id) => (
            <CopyToClipboard message={id} inner={<Icon type="copy" />} />
          ),
          ...tableHelper.fieldSorter.getStringSortProps('id'),
        },
        {
          title: this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable.location',
          ),
          dataIndex: 'data.locations',
          key: 'locations',
          render: (locations) =>
            locations && locations.length
              ? locations.map((location) => (
                  <CopyToClipboard
                    key={location.locationId}
                    message={location.barcode.toUpperCase()}
                    color="red"
                    style={{ margin: 2 }}
                  />
                ))
              : '-',
        },
        {
          title: this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable.status',
          ),
          dataIndex: 'status',
          key: 'status',
          ...tableHelper.fieldSorter.getNumberSortProps('status'),
          render: (code) => getTranslation.jobTaskStatus(code),
        },
        {
          title: this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable.createdAt',
          ),
          dataIndex: 'createdAt',
          key: 'createdAt',
          ...tableHelper.fieldSorter.getDateSortProps('createdAt'),
          render: (createdAt) =>
            moment(new Date(createdAt))
              .local()
              .format(getDateTimeFormatString()),
        },
        {
          key: 'detail',
          dataIndex: 'id',
          render: (id) => <this.DetailTaskButton id={id} />,
        },
      ];
    }
    return [
      {
        title: this.props.t(
          'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable._id',
        ),
        dataIndex: 'id',
        key: 'id',
        render: (id) => (
          <CopyToClipboard message={id} inner={<Icon type="copy" />} />
        ),
        ...tableHelper.fieldSorter.getStringSortProps('id'),
      },
      {
        title: this.props.t(
          'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable.productImage',
        ),
        dataIndex: 'data.products',
        key: 'products.image',
        render: (products) =>
          products && products.length
            ? products.map((p, index) => (
                <div key={index}>
                  {index !== 0 ? (
                    <Divider dashed="true" style={{ margin: 2 }} />
                  ) : null}
                  <Avatar
                    className="product-image"
                    shape="square"
                    src={
                      getTranslation.obj(p.picURL)
                        ? getTranslation.obj(p.picURL)
                        : null
                    }
                  />
                </div>
              ))
            : '-',
      },
      {
        title: this.props.t(
          'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable.productId',
        ),
        dataIndex: 'data.products',
        key: 'products.id',
        width: 70,
        render: (products) =>
          products && products.length
            ? products.map((p, index) => (
                <div key={p.productId}>
                  {index !== 0 ? (
                    <Divider dashed="true" style={{ margin: 2 }} />
                  ) : null}
                  <CopyToClipboard
                    key={getTranslation.obj(p.productId)}
                    message={getTranslation.obj(p.productId)}
                    style={{ margin: 2 }}
                    inner={<Icon type="copy" />}
                  />
                </div>
              ))
            : '-',
      },
      {
        title: this.props.t(
          'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable.productName',
        ),
        dataIndex: 'data.products',
        key: 'products.name',
        render: (products) =>
          products && products.length
            ? products.map((p, index) => (
                <div key={index}>
                  {index !== 0 ? (
                    <Divider dashed="true" style={{ margin: 2 }} />
                  ) : null}
                  <CopyToClipboard
                    key={getTranslation.obj(p.fullName)}
                    message={getTranslation.obj(p.fullName)}
                    style={{ margin: 2 }}
                  />
                </div>
              ))
            : '-',
      },
      {
        title: this.props.t(
          'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable.productBarcode',
        ),
        dataIndex: 'data.products',
        key: 'products.barcode',
        render: (products) =>
          products && products.length
            ? products.map((p, index) => {
                if (!p.barcodes || !p.barcodes.length) {
                  return (
                    <Tag color="blue" style={{ margin: 2 }}>
                      {'-'}
                    </Tag>
                  );
                }
                return (
                  <div key={index}>
                    {index !== 0 ? (
                      <Divider dashed="true" style={{ margin: 2 }} />
                    ) : null}
                    {p.barcodes.map((barcode) => (
                      <CopyToClipboard
                        key={barcode}
                        color="blue"
                        message={barcode}
                        style={{ margin: 2 }}
                      />
                    ))}
                  </div>
                );
              })
            : '-',
      },
      {
        title: this.props.t(
          'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable.status',
        ),
        dataIndex: 'status',
        key: 'status',
        ...tableHelper.fieldSorter.getNumberSortProps('status'),
        render: (code) => getTranslation.jobTaskStatus(code),
      },
      {
        title: this.props.t(
          'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable.createdAt',
        ),
        dataIndex: 'createdAt',
        key: 'createdAt',
        ...tableHelper.fieldSorter.getDateSortProps('createdAt'),
        render: (createdAt) =>
          moment(new Date(createdAt))
            .local()
            .format(getDateTimeFormatString()),
      },
      {
        key: 'detail',
        dataIndex: 'id',
        render: (id) => <this.DetailTaskButton id={id} />,
      },
    ];
  };

  DetailTaskButton = (props) => {
    const { data: inventoryCheckDetail } = this.props.inventoryCheckDetail;
    const settings = {
      link: {
        target: '_blank',
        to: `/r/${this.props.CWId}/stock/inventory-check/${inventoryCheckDetail.id}/job/${inventoryCheckDetail.job.id}/task/${props.id}`,
      },
    };
    return (
      <Link {...settings.link}>
        <Button size="small">
          {this.props.t(
            'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable.detail',
          )}
        </Button>
      </Link>
    );
  };

  prepareData = (props) => {
    const { inventoryCheckDetail } = this.props;
    return inventoryCheckDetail.data.job.tasks;
  };

  getDetail = () => {
    const { id, onGetInventoryCheckDetail } = this.props;
    onGetInventoryCheckDetail(id);
  };

  setItemReady = () => {
    const { id, onSetInventoryCheckReady } = this.props;
    onSetInventoryCheckReady(id)
      .then(() => {
        this.setState({ isClickedPrepareInventoryCheckButton: false });
      })
      .catch(() => {
        this.setState({ isClickedPrepareInventoryCheckButton: false });
      });
  };

  deleteInventoryCheck = () => {
    const { id, onDeleteInventoryCheck } = this.props;
    const that = this;
    confirm({
      title: this.props.t(
        'pages.Stock.InventoryCheck.detail.deleteConfirmModal',
      ),
      onOk() {
        onDeleteInventoryCheck(id)
          .then(() => {
            that.setState({ isClickedDeleteInventoryCheckButton: false });
          })
          .catch(() => {
            that.setState({ isClickedDeleteInventoryCheckButton: false });
          });
      },
      onCancel() {
        that.setState({ isClickedDeleteInventoryCheckButton: false });
      },
    });
  };

  prepareProductColumns = (props) => [
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable.productImage',
      ),
      dataIndex: 'picURL',
      key: 'picURL',
      render: (picURL) => (
        <Avatar
          className="product-image"
          shape="square"
          src={getTranslation.obj(picURL) ? getTranslation.obj(picURL) : null}
        />
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable.productId',
      ),
      dataIndex: 'productId',
      key: 'productId',
      render: (productId) => (
        <CopyToClipboard
          key={getTranslation.obj(productId)}
          message={getTranslation.obj(productId)}
          style={{ margin: 2 }}
          inner={<Icon type="copy" />}
        />
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable.productName',
      ),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (fullName) => (
        <CopyToClipboard
          key={getTranslation.obj(fullName)}
          message={getTranslation.obj(fullName)}
          style={{ margin: 2 }}
        />
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.detail.inventoryCheckTaskTable.productBarcode',
      ),
      dataIndex: 'barcodes',
      key: 'barcodes',
      render: (barcodes) => {
        if (barcodes && barcodes.length) {
          return barcodes.map((barcode) => (
            <CopyToClipboard
              key={barcode}
              color="blue"
              message={barcode}
              style={{ margin: 2 }}
            />
          ));
        }
        return '-';
      },
    },
  ];

  prepareProductData = (record) => record.data.products;

  getCountOfCompletedTask = (job) =>
    job.tasks.filter((task) => task.status === JOB_TASK_STATUSE.COMPLETED)
      .length;

  getCountOfTask = (job) => job.tasks.length;

  getCompletionRate = (job) =>
    Math.floor(
      (this.getCountOfCompletedTask(job) / this.getCountOfTask(job)) * 100,
    );
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.inventory.inventoryCheckDetail.loading,
  inventoryCheckDetail: state.inventory.inventoryCheckDetail,
});

const mapDispatchToProps = (dispatch) => ({
  onGetInventoryCheckDetail: (id) => {
    dispatch(inventoryAction.getInventoryCheckDetail(id));
  },
  onSetInventoryCheckReady: (id) =>
    dispatch(inventoryAction.setInventoryCheckReady(id)),
  onDeleteInventoryCheck: (id) =>
    dispatch(inventoryAction.deleteInventoryCheck(id)),
});

const InventoryCheckDetailConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(InventoryCheckDetail));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { InventoryCheckDetailConnected as InventoryCheckDetail };
