import React, { Component } from 'react';
import { Select } from 'antd';

/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

export class TagIdMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    return {};
  };

  componentDidMount() {}

  render() {
    return <this.InputField />;
  }

  InputField = () => {
    const { placeholder } = this.props;
    return (
      <Select
        mode="tags"
        placeholder={placeholder}
        value={this.getValue()}
        allowClear
        onChange={this.onChange}
      />
    );
  };

  getValue = () => {
    const { value } = this.props;
    if (!value || !value.length) {
      return;
    }
    let ids = value
      .toString()
      .trim()
      .split(',')
      .map((id) => id.trim());

    if (!ids.length) {
      ids = [value.trim()];
    }

    return ids;
  };

  onChange = (value) => {
    let ids = value
      .toString()
      .trim()
      .split(',')
      .map((transfer) => transfer.trim());

    if (!ids.length) {
      ids = [value.trim()];
    }

    const validIds = ids.filter(
      (id) => id.length && id.length === 24,
    );

    this.props.onChange(validIds);
  };
}
