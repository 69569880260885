import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Table, Button, Icon } from 'antd';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './InventoryCheckList.scss';
import { InventoryCheckFilter } from './InventoryCheckFilter';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard } from './../../../../components';
import { inventoryAction } from './../../../../actions';
import { getTranslation, tableHelper, constants, getDateTimeFormatString } from './../../../../shared';
import { pageLoadEvents } from '../../../../services/events';
import { pageView } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class InventoryCheckList extends Component {
  settings = {
    main: {
      className: 'InventoryCheckList_main',
    },
    card: {
      title: this.props.t('pages.Operation.InventoryCheck.list.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_STOCK_CONTROL_LIST });
    this.getList();
  }

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      tableFilter: this.getDefaultTableFilter(),
    };
  };

  getDefaultTableFilter = () => {
    return null;
  };

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <InventoryCheckFilter
            onChange={this.setTableFilter}
            onClear={this.clearTableFilter}
            defaultDateRange={this.defaultDateRange}
          />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    const url = '/stock-operation/inventory-check/create';
    return (
      <div style={{ display: 'flex' }}>
        <Link to={url}>
          <Button type="danger" style={{ marginLeft: 10 }}>
            {this.props.t(
              'pages.Operation.InventoryCheck.list.extra.createNewButton',
            )}
          </Button>
        </Link>
      </div>
    );
  };

  PageTable = (props) => {
    return (
      <div>
        <Table
          size="small"
          dataSource={this.prepareData(this.props)}
          columns={this.prepareColumns(this.props)}
          rowKey={(record) => {
            return record.id;
          }}
          pagination={{
            ...this.state.pagination,
            total: this.props.totalCount,
          }}
          loading={{ indicator: <Spinner />, spinning: this.props.loading }}
          onChange={this.handleTableChange}
        />
      </div>
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState((state) => {
      return {
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      };
    }, this.getList);
  };

  prepareColumns = (props) => {
    return [
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.list.inventoryCheckTable._id',
        ),
        dataIndex: 'id',
        key: 'id',
        render: (id) => (
          <CopyToClipboard message={id} inner={<Icon type="copy" />} />
        ),
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.list.inventoryCheckTable.warehouse',
        ),
        dataIndex: 'warehouse',
        key: 'warehouse',
        render: (warehouse) => {
          return warehouse ? (
            <CopyToClipboard
              inner={warehouse.name || warehouse}
              message={warehouse.name || warehouse}
            />
          ) : (
            '-'
          );
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.list.inventoryCheckTable.status',
        ),
        dataIndex: 'status',
        key: 'status',
        render: (code) => {
          return getTranslation.inventoryCheckStatus(code);
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.list.inventoryCheckTable.completionPercentage',
        ),
        dataIndex: 'completionPercentage',
        key: 'completionPercentage',
        ...tableHelper.fieldSorter.getNumberSortProps('completionPercentage'),
        render: (completionPercentage, record) => {
          const total =
            record.type === constants.INVENTORY_CHECK_TYPE.PRODUCT_BASED
              ? record.products.length
              : record.locations.length;
          const counted = Math.floor((total * completionPercentage) / 100);
          return `(${counted}/${total}) - %${completionPercentage}`;
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.list.inventoryCheckTable.type',
        ),
        dataIndex: 'type',
        key: 'type',
        render: (code) => {
          return getTranslation.inventoryCheckType(code);
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.list.inventoryCheckTable.inventoryCheckType',
        ),
        dataIndex: 'isAutoInventoryCheck',
        key: 'isAutoInventoryCheck',
        render: (isAutoInventoryCheck) => {
          if (!isAutoInventoryCheck) {
            return this.props.t('pages.Operation.InventoryCheck.list.other');
          }
          return this.props.t(
            'pages.Operation.InventoryCheck.list.inventoryCheckTable.autoInventoryCheck',
          );
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.list.inventoryCheckTable.owner',
        ),
        dataIndex: 'job',
        key: 'job',
        render: (job) => {
          return job ? (
            <CopyToClipboard message={job} inner={<Icon type="copy" />} />
          ) : (
            '-'
          );
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.list.inventoryCheckTable.createdAt',
        ),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) => {
          return moment(new Date(createdAt))
            .local()
            .format(getDateTimeFormatString());
        },
        ...tableHelper.fieldSorter.getDateSortProps('createdAt'),
      },
      {
        key: 'detail',
        dataIndex: 'id',
        render: (id) => <this.DetailInventoryCheckButton id={id} />,
      },
    ];
  };

  DetailInventoryCheckButton = (props) => {
    const url = `/stock-operation/inventory-check/detail/${props.id}`;
    return (
      <Link to={url} target="_blank">
        <Button size="small">
          {this.props.t('pages.Operation.InventoryCheck.list.detailButton')}
        </Button>
      </Link>
    );
  };

  prepareData = (props) => {
    const { inventoryCheckList } = props;
    if (!inventoryCheckList || !inventoryCheckList.length) {
      return null;
    }
    return inventoryCheckList;
  };

  getList = () => {
    const { query } = this.state;
    const filter = this.getFilter();
    this.props.onGetInventoryCheckList(filter, query);
  };

  getFilter = () => {
    const { tableFilter } = this.state;
    let filter = {};
    if (tableFilter) {
      filter = { ...filter, ...tableFilter };
    }
    if (
      !filter.createdAt ||
      !filter.createdAt.startDate ||
      !filter.createdAt.endDate
    ) {
      filter.createdAt = {
        ...this.defaultDateRange,
      };
    }
    return filter;
  };

  setTableFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        tableFilter: filter,
      };
    }, this.getList);
  };

  clearTableFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        tableFilter: filter,
      };
    }, this.getList);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.inventory.inventoryCheckList.loading,
  inventoryCheckList: state.inventory.inventoryCheckList.data,
  totalCount: state.inventory.inventoryCheckList.total,
});

const mapDispatchToProps = (dispatch) => ({
  onGetInventoryCheckList: (config, query) => {
    return dispatch(
      inventoryAction.getInventoryCheckList(
        { ...config, populate: ['warehouses', 'products', 'locations'] },
        query,
      ),
    );
  },
});

const InventoryCheckListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(InventoryCheckList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { InventoryCheckListConnected as InventoryCheckList };
