import React, { Component } from 'react';
import { Input } from 'antd';
/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export class LoggerIdInput extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    return {};
  };

  componentDidMount() {}

  render() {
    return <this.InputField />;
  }

  InputField = () => {
    const { disabled, placeholder, value } = this.props;
    return (
      <Input
        allowClear
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={this.onChange}
      />
    );
  };

  onChange = (event) => {
    this.props.onChange(event.target.value);
  };
}
