import { notification } from 'antd';
import { actionType } from './actionType';
import { productAction } from './product';
import { http } from './../http';
import { ErrorInfo, ExportToExcel } from './../components';
import { history } from './../history';
import { LocalDatabase, constants, transfromReasonResponse } from './../shared';

import { chunkPromises } from './../shared/promise';

export const blockedStockAction = {
  filterBlockedStockReasons,
  filterBlockedStock,
  getBlockedStockDetail,
  updateBlockedStockReason,
  createBlockedStockRule,
  filterBlockedStockRule,
  getBlockedStockRuleDetail,
  updateBlockedStockRule,
  concludeBlockedStock,
  bulkConcludeBlockedStock,
  exportToXlsxBlockedStockList,
  cancelBlockedStock,
  changeConclusionBlockedStock,
  completeTransferBulk,
  filterBlockedStockRequest,
  filterBlockedStockTransfers,
  filterBlockedStockRefunds,
  filterBlockedStockDisposals,
  getBlockedStockTransfer,
  getBlockedStockRefund,
  getBlockedStockDisposal,
  centralWarehouseIncomingBlockedStockTransfer,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function filterBlockedStockReasons(filter) {
  return async (dispatch) => {
    dispatch({ type: actionType.filterBlockedStockReasons_InProgress });

    const blockedStockReasons = await LocalDatabase.getWithExpiry(
      'blockedStockReasons',
    );
    if (blockedStockReasons) {
      dispatch({
        type: actionType.filterBlockedStockReasons_Succeeded,
        payload: blockedStockReasons,
      });
      return Promise.resolve(blockedStockReasons);
    } else {
      return http
        .filterBlockedStockReasons(filter)
        .then(async (response) => {
          const data = transfromReasonResponse(response.data);

          await LocalDatabase.setWithExpiry(
            'blockedStockReasons',
            data,
            constants.TTL_FOR_CACHE.BLOCKEDSTOCKREASONS,
          );
          dispatch({
            type: actionType.filterBlockedStockReasons_Succeeded,
            payload: data,
          });
        })
        .catch((error) => {
          notification.error({
            duration: 4,
            message: ErrorInfo.message(
              actionType.filterBlockedStockReasons_Failed,
            ),
            description: ErrorInfo.description(error),
          });
          dispatch({
            type: actionType.filterBlockedStockReasons_Failed,
            payload: error,
          });
        });
    }
  };
}

function filterBlockedStock(query, filter) {
  return (dispatch) => {
    dispatch({ type: actionType.filterBlockedStock_InProgress });
    http
      .filterBlockedStock(query, filter)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.filterBlockedStock_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.filterBlockedStock_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.filterBlockedStock_Failed,
          payload: error,
        });
      });
  };
}

function getBlockedStockDetail(blockedStockId) {
  return (dispatch) => {
    dispatch({ type: actionType.getBlockedStockDetail_InProgress });
    return http
      .getBlockedStockDetail(blockedStockId)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getBlockedStockDetail_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getBlockedStockDetail_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getBlockedStockDetail_Failed,
          payload: error,
        });
      });
  };
}

function updateBlockedStockReason(blockedStockId, data) {
  return (dispatch) => {
    dispatch({ type: actionType.updateBlockedStockReason_InProgress });
    return http
      .updateBlockedStockReason(blockedStockId, data)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.updateBlockedStockReason_Succeeded,
          payload: data,
        });
        dispatch(getBlockedStockDetail(blockedStockId));
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.updateBlockedStockReason_Succeeded,
          ),
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.updateBlockedStockReason_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.updateBlockedStockReason_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function createBlockedStockRule(data) {
  return (dispatch) => {
    dispatch({ type: actionType.createBlockedStockRule_InProgress });
    http
      .createBlockedStockRule(data)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.createBlockedStockRule_Succeeded,
          payload: data,
        });
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.createBlockedStockRule_Succeeded,
          ),
        });
        history.push('/stock-operation/blocked-stock/decision-rule/list');
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.createBlockedStockRule_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.createBlockedStockRule_Failed,
          payload: error,
        });
      });
  };
}

function filterBlockedStockRule(query, filter) {
  return (dispatch) => {
    dispatch({ type: actionType.filterBlockedStockRule_InProgress });
    http
      .filterBlockedStockRule(query, filter)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.filterBlockedStockRule_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.filterBlockedStockRule_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.filterBlockedStockRule_Failed,
          payload: error,
        });
      });
  };
}

function getBlockedStockRuleDetail(blockedStockRuleId) {
  return (dispatch) => {
    dispatch({ type: actionType.getBlockedStockRuleDetail_InProgress });
    http
      .getBlockedStockRuleDetail(blockedStockRuleId)
      .then((response) => {
        const { data } = response.data;
        const supplierId = data.supplier.id;
        dispatch(
          productAction.filterProductList({
            supplierIds: [supplierId],
            fields: 'barcodes picURL fullName packagingInfo',
            includeDefaultFields: false,
          }),
        );
        dispatch({
          type: actionType.getBlockedStockRuleDetail_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.getBlockedStockRuleDetail_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getBlockedStockRuleDetail_Failed,
          payload: error,
        });
      });
  };
}

function updateBlockedStockRule(blockedStockRuleId, data) {
  return (dispatch) => {
    dispatch({ type: actionType.updateBlockedStockRule_InProgress });
    http
      .updateBlockedStockRule(blockedStockRuleId, data)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.updateBlockedStockRule_Succeeded,
          payload: data,
        });
        dispatch(getBlockedStockRuleDetail(blockedStockRuleId));
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.updateBlockedStockRule_Succeeded,
          ),
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.updateBlockedStockRule_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.updateBlockedStockRule_Failed,
          payload: error,
        });
      });
  };
}

function concludeBlockedStock(blockedStockId, data, refresh) {
  return (dispatch) => {
    dispatch({ type: actionType.concludeBlockedStock_InProgress });
    return http
      .concludeBlockedStock(blockedStockId, data)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.concludeBlockedStock_Succeeded,
          payload: data,
        });
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.concludeBlockedStock_Succeeded),
        });
        if (refresh) {
          dispatch(getBlockedStockDetail(blockedStockId));
        }
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.concludeBlockedStock_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.concludeBlockedStock_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function bulkConcludeBlockedStock(blockedStockBulk, decision, errorMessage) {
  return (dispatch) => {
    dispatch({ type: actionType.bulkConcludeBlockedStock_InProgress });

    return chunkPromises({
      data: blockedStockBulk,
      payload: decision,
      fn: http.concludeBlockedStock,
    })
      .then((response) => {
        if (response.some((r) => r.status === 'rejected')) {
          throw response;
        }
        if (
          response.every(
            (r) => r.status === 'fulfilled' && r.value.status === 200,
          )
        ) {
          notification.success({
            duration: 4,
            message: ErrorInfo.message(
              actionType.bulkConcludeBlockedStock_Succeeded,
            ),
          });
        }
        dispatch({
          type: actionType.bulkConcludeBlockedStock_Succeeded,
          payload: { data: { data: {} } },
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.bulkConcludeBlockedStock_Failed,
          ),
          description: errorMessage,
        });
        dispatch({
          type: actionType.bulkConcludeBlockedStock_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function exportToXlsxBlockedStockList(query, filter, fileName, formatter) {
  return (dispatch) => {
    dispatch({ type: actionType.exportToXlsxBlockedStockList_InProgress });
    getDataForExport(query, filter)
      .then((responses) => {
        ExportToExcel.fileSave({
          fileName,
          data: formatter(responses),
        });
        dispatch({
          type: actionType.exportToXlsxBlockedStockList_Succeeded,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.exportToXlsxBlockedStockList_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.exportToXlsxBlockedStockList_Failed,
          payload: error,
        });
      });
  };
}

function getDataForExport(query, filter) {
  const limit = 50;
  const offset = 0;
  const total = query.limit;
  return getCallOneByOne(total, filter, limit, offset, []);
}

function getCallOneByOne(total, filter, limit, offset, acc = []) {
  if (offset >= total) {
    return http
      .filterBlockedStock({ limit, offset }, filter)
      .then((res) => {
        acc.push(...res.data.data.blockedStocks);
        return acc;
      })
      .catch((err) => acc);
  }
  return http
    .filterBlockedStock({ limit, offset }, filter)
    .then((res) => {
      acc.push(...res.data.data.blockedStocks);
      return getCallOneByOne(total, filter, limit, offset + limit, acc);
    })
    .catch((err) => getCallOneByOne(total, filter, limit, offset + limit, acc));
}

function cancelBlockedStock(blockedStockId) {
  return (dispatch) => {
    dispatch({ type: actionType.cancelBlockedStock_InProgress });
    return http
      .cancelBlockedStock(blockedStockId)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.cancelBlockedStock_Succeeded,
          payload: data,
        });
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.cancelBlockedStock_Succeeded),
        });
        history.push('/stock-operation/blocked-stock/list');
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.cancelBlockedStock_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.cancelBlockedStock_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function changeConclusionBlockedStock(blockedStockId, data, refresh) {
  return (dispatch) => {
    dispatch({ type: actionType.changeConclusionBlockedStock_InProgress });
    return http
      .changeConclusionBlockedStock(blockedStockId, data)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.changeConclusionBlockedStock_Succeeded,
          payload: data,
        });
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.changeConclusionBlockedStock_Succeeded,
          ),
        });
        if (refresh) {
          dispatch(getBlockedStockDetail(blockedStockId));
        }
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.changeConclusionBlockedStock_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.changeConclusionBlockedStock_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function completeTransferBulk(
  transferBulk,
  waybills,
  query,
  filter,
  errorMessage,
) {
  console.log('completeTransferBulk reducer');
  console.log(transferBulk, waybills);

  return (dispatch) => {
    dispatch({ type: actionType.completeTransferBulk_InProgress });

    return uploadFiles(waybills)
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.completeDisposal_Failed),
          description: errorMessage,
        });
        dispatch(filterBlockedStock(query, filter));
        throw error;
      })
      .then((uploadedFiles) => {
        const payload = {
          blockedStockIds: transferBulk.map((refund) => refund.id),
          waybills: uploadedFiles,
        };
        return http
          .completeTransferBulk(payload)
          .then(() => {
            notification.success({
              duration: 4,
              message: ErrorInfo.message(actionType.completeRefund_Succeeded),
            });
          })
          .catch((error) => {
            notification.error({
              duration: 4,
              message: ErrorInfo.message(actionType.completeRefund_Failed),
              description: ErrorInfo.description(error),
            });
          });
      })
      .then(() => {
        dispatch({ type: actionType.completeTransferBulk_Succeeded });
        dispatch(filterBlockedStock(query, filter));
      });
  };
}

function uploadFiles(files) {
  const filesPromises = files.map((file) => {
    return http
      .uploadWaybill({
        file: {
          name: file.imageFile.name,
          type: file.imageFile.type,
          size: file.imageFile.size,
        },
        folderName: 'waybill/',
      })
      .then((response) => {
        return http
          .uploadToS3(
            response.data.data.uploadUrls.signedUrl,
            file.imageFile.originFileObj,
          )
          .then(() => {
            return response.data;
          });
      });
  });
  return Promise.all(filesPromises).then((values) => {
    return files.map((file, i) => ({
      ss: file.ss,
      date: file.date,
      imageUrl: values[i].data.uploadUrls.imageUrl,
    }));
  });
}

function filterBlockedStockRequest(query, filter) {
  return (dispatch) => {
    dispatch({ type: actionType.filterBlockedStockRequest_InProgress });
    http
      .filterBlockedStockRequest(query, filter)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.filterBlockedStockRequest_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.filterBlockedStockRequest_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.filterBlockedStockRequest_Failed,
          payload: error,
        });
      });
  };
}

// =====================================================================================================================

function filterBlockedStockTransfers(query, filter) {
  return (dispatch) => {
    dispatch({ type: actionType.filterBlockedStockTransfers_InProgress });
    http
      .filterBlockedStockTransfers(query, filter)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.filterBlockedStockTransfers_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.filterBlockedStockTransfers_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.filterBlockedStockTransfers_Failed,
          payload: error,
        });
      });
  };
}

function filterBlockedStockRefunds(query, filter) {
  return (dispatch) => {
    dispatch({ type: actionType.filterBlockedStockRefunds_InProgress });
    http
      .filterBlockedStockRefunds(query, filter)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.filterBlockedStockRefunds_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.filterBlockedStockRefunds_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.filterBlockedStockRefunds_Failed,
          payload: error,
        });
      });
  };
}

function filterBlockedStockDisposals(query, filter) {
  return (dispatch) => {
    dispatch({ type: actionType.filterBlockedStockDisposals_InProgress });
    http
      .filterBlockedStockDisposals(query, filter)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.filterBlockedStockDisposals_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.filterBlockedStockDisposals_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.filterBlockedStockDisposals_Failed,
          payload: error,
        });
      });
  };
}

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function getBlockedStockTransfer(blockedStockId) {
  return (dispatch) => {
    dispatch({ type: actionType.getBlockedStockTransfer_InProgress });
    http
      .getBlockedStockTransfer(blockedStockId)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getBlockedStockTransfer_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getBlockedStockTransfer_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getBlockedStockTransfer_Failed,
          payload: error,
        });
      });
  };
}

function getBlockedStockRefund(blockedStockId) {
  return (dispatch) => {
    dispatch({ type: actionType.getBlockedStockRefund_InProgress });
    return http
      .getBlockedStockRefund(blockedStockId)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getBlockedStockRefund_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getBlockedStockRefund_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getBlockedStockRefund_Failed,
          payload: error,
        });
      });
  };
}

function getBlockedStockDisposal(blockedStockId) {
  return (dispatch) => {
    dispatch({ type: actionType.getBlockedStockDisposal_InProgress });
    http
      .getBlockedStockDisposal(blockedStockId)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getBlockedStockDisposal_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getBlockedStockDisposal_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getBlockedStockDisposal_Failed,
          payload: error,
        });
      });
  };
}

function centralWarehouseIncomingBlockedStockTransfer(transferId) {
  return (dispatch) => {
    dispatch({
      type: actionType.centralWarehouseIncomingBlockedStockTransfer_InProgress,
    });
    http
      .centralWarehouseIncomingBlockedStockTransfer(transferId)
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.centralWarehouseIncomingBlockedStockTransfer_Succeeded,
          ),
        });
        dispatch({
          type:
            actionType.centralWarehouseIncomingBlockedStockTransfer_Succeeded,
          payload: response,
        });
        dispatch(getBlockedStockTransfer(transferId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.centralWarehouseIncomingBlockedStockTransfer_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.centralWarehouseIncomingBlockedStockTransfer_Failed,
          payload: error,
        });
      });
  };
}
