export const config = {
  BACKEND_URI: process.env.REACT_APP_BACKEND_URI,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  YANDEX_PLACES_KEY: process.env.REACT_APP_YANDEX_PLACES_KEY,
  YANDEX_JS_API_KEY: process.env.REACT_APP_YANDEX_JS_API_KEY,
  ENV: process.env.REACT_APP_ENV || 'local',
  BASE_URI: '/',
  VERSION: '1.0.0',
  RECAPTCHA_SITEKEY: process.env.REACT_APP_RECAPTCHA_SITEKEY,
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  REACT_APP_GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
};
