import React, { Component } from 'react';
import { Switch, Checkbox } from 'antd';

export class BooleanSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    return {};
  };

  render() {
    return (
      <Checkbox
        checked={this.props.checked}
        onChange={(event) => {
          const checked = event.target.checked;
          this.props.onCheckChange(checked);
        }}
      >
        <span>{this.props.label}: </span>
        <Switch
          disabled={!this.props.checked}
          checked={this.props.value}
          checkedChildren={this.props.checkedChildren || 'on'}
          unCheckedChildren={this.props.unCheckedChildren || 'off'}
          onChange={(value) => {
            this.props.onChange(value);
          }}
        />
      </Checkbox>
    );
  }
}
