import React from 'react';
import CSVReader from 'react-csv-reader';
import { withNamespaces } from 'react-i18next';
import { Button, Icon } from 'antd';
import './ImportCsv.scss';
import { track } from '../../services/segment';
import { CreateTransferEvents } from '../../pages/Operation/Transfer/create/track';
/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/

const ImportCsv = (props) => {
  const { transformData, block, disabled } = props;
  return (
    <div className="ImportFile">
      <Button
        disabled={disabled}
        block={block}
        onClick={() => {
          track(CreateTransferEvents.OPS_TRANSFER_IMPORT_CSV_CLICKED);
          const input = document.querySelector('.csv-input');
          input.value = '';
          input.click();
        }}
      >
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Icon type="import" />
          <span style={{ marginLeft: 5 }}>
            {props.t('components.ImportCsv.button.importCsv')}
          </span>
        </span>
      </Button>
      <CSVReader
        cssClass="react-csv-input"
        cssInputClass="csv-input"
        onFileLoaded={(data) => {
          transformData(data);
        }}
      />
    </div>
  );
};

const ImportCsvTranslated = withNamespaces('translation')(ImportCsv);

export { ImportCsvTranslated as ImportCsv };
