import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: false,
    data: null,
    error: null,
    total: 0,
  },
  detail: {
    loading: true,
    data: null,
    error: null,
  },
  isExporting: false,
};

const switcher = {
  [actionType.getStockMovementLogList_InProgress]: getStockMovementLogList_InProgress,
  [actionType.getStockMovementLogList_Succeeded]: getStockMovementLogList_Succeeded,
  [actionType.getStockMovementLogList_Failed]: getStockMovementLogList_Failed,

  [actionType.resetStockMovementLogList]: resetStockMovementLogList,
  [actionType.getStockMovementLogDetail_InProgress]: getStockMovementLogDetail_InProgress,
  [actionType.getStockMovementLogDetail_Succeeded]: getStockMovementLogDetail_Succeeded,
  [actionType.getStockMovementLogDetail_Failed]: getStockMovementLogDetail_Failed,

  [actionType.exportToXlsx_InProgress]: exportToXlsx_InProgress,
  [actionType.exportToXlsx_Succeeded]: exportToXlsx_Succeeded,
  [actionType.exportToXlsx_Failed]: exportToXlsx_Failed,
};

export const stockMovementLogReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getStockMovementLogList_InProgress(state) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function getStockMovementLogList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload.data,
      error: null,
      total: payload.total,
    },
  });
}

function getStockMovementLogList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}
function resetStockMovementLogList(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function exportToXlsx_InProgress(state, payload) {
  return Object.assign({}, state, {
    isExporting: true,
  });
}

function exportToXlsx_Succeeded(state, payload) {
  return Object.assign({}, state, {
    isExporting: false,
  });
}

function exportToXlsx_Failed(state, payload) {
  return Object.assign({}, state, {
    isExporting: false,
  });
}

function getStockMovementLogDetail_InProgress(state) {
  return Object.assign({}, state, {
    detail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getStockMovementLogDetail_Succeeded(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getStockMovementLogDetail_Failed(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}
