/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Descriptions,
  Table,
  Avatar,
  Input,
  Tag,
  Row,
  Col,
  Icon,
} from 'antd';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './InventoryCheckTaskDetail.scss';
/*----------------------------------------------------------------------------*/
import { getLangKey } from './../../../../i18n';
import { Spinner, CopyToClipboard, JsonButton } from './../../../../components';
import { inventoryAction } from './../../../../actions';
import { tableHelper, getTranslation, constants, getDateTimeFormatString, getDateFormatString } from './../../../../shared';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class InventoryCheckTaskDetail extends Component {
  settings = {
    main: {
      className: 'InventoryCheckTaskDetail_main',
    },
    card: {
      title: this.props.t('pages.Stock.InventoryCheck.taskDetail.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    this.getDetailData();
  }

  stateFactory = () => ({
    productFilter: null,
  });

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {loading ? <Spinner /> : <this.PageTaskDetail />}
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    const settings = {
      inputSearch: {
        className: 'product-filter-input',
        onChange: this.onChangeProductSearch,
        placeholder: this.props.t(
          'pages.Stock.InventoryCheck.taskDetail.extra.filterByProduct',
        ),
      },
    };
    return (
      <div style={{ display: 'flex' }}>
        <Input.Search {...settings.inputSearch} style={{ marginRight: 5 }} />
        <JsonButton data={this.props.inventoryCheckTaskDetail} />
      </div>
    );
  };

  onChangeProductSearch = (event) => {
    this.setState({
      productFilter: event.target.value.trim().toUpperCase(),
    });
  };

  PageTaskDetail = (props) => (
    <div>
      <Row>
        <Col span={14}>
          <this.TaskDescription />
        </Col>
      </Row>
      <this.ProductTable />
    </div>
  );

  TaskDescription = (props) => {
    const { inventoryCheckTaskDetail } = this.props;
    if (!inventoryCheckTaskDetail) {
      return null;
    }

    const descriptionItems = [];

    if (inventoryCheckTaskDetail.id) {
      descriptionItems.push(
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.taskDetail.description.id',
          )}
          key="description.id"
        >
          <CopyToClipboard message={inventoryCheckTaskDetail.id} />
        </Descriptions.Item>,
      );
    }

    if (inventoryCheckTaskDetail.status) {
      descriptionItems.push(
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.taskDetail.description.status',
          )}
          key="description.status"
        >
          {getTranslation.jobTaskStatus(inventoryCheckTaskDetail.status)}
        </Descriptions.Item>,
      );
    }

    if (inventoryCheckTaskDetail.data.locations) {
      descriptionItems.push(
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.taskDetail.description.locationBarcode',
          )}
          key="description.locationBarcode"
        >
          {inventoryCheckTaskDetail.data.locations.map((l) => (
            <Tag key={l.barcode}>{l.barcode}</Tag>
          ))}
        </Descriptions.Item>,
      );
    }

    if (inventoryCheckTaskDetail.assignee) {
      descriptionItems.push(
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.taskDetail.description.assignee',
          )}
          key="description.assignee"
        >
          <CopyToClipboard
            color="blue"
            message={inventoryCheckTaskDetail.assignee._id}
            inner={inventoryCheckTaskDetail.assignee.name}
          />
        </Descriptions.Item>,
      );
    }

    if (inventoryCheckTaskDetail.assignedAt) {
      descriptionItems.push(
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.taskDetail.description.assignedAt',
          )}
          key="description.assignedAt"
        >
          {moment(new Date(inventoryCheckTaskDetail.assignedAt))
            .local()
            .format(getDateTimeFormatString())}
        </Descriptions.Item>,
      );
    }

    if (inventoryCheckTaskDetail.startedAt) {
      descriptionItems.push(
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.taskDetail.description.startedAt',
          )}
          key="description.startedAt"
        >
          {moment(new Date(inventoryCheckTaskDetail.startedAt))
            .local()
            .format(getDateTimeFormatString())}
        </Descriptions.Item>,
      );
    }

    if (inventoryCheckTaskDetail.completedAt) {
      descriptionItems.push(
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.InventoryCheck.taskDetail.description.completedAt',
          )}
          key="description.completedAt"
        >
          {moment(new Date(inventoryCheckTaskDetail.completedAt))
            .local()
            .format(getDateTimeFormatString())}
        </Descriptions.Item>,
      );
    }

    return <Descriptions column={2}>{descriptionItems}</Descriptions>;
  };

  ProductTable = (props) => {
    const { inventoryCheckTaskDetail } = this.props;
    if (
      !inventoryCheckTaskDetail ||
      !inventoryCheckTaskDetail.data ||
      !inventoryCheckTaskDetail.data.products
    ) {
      return null;
    }
    return (
      <Table
        size="small"
        columns={this.prepareColumns()}
        dataSource={this.prepareData()}
        rowKey={(record) => record.productId}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  isTaskCompleted = () => {
    const { inventoryCheckTaskDetail } = this.props;
    if (
      !inventoryCheckTaskDetail ||
      !inventoryCheckTaskDetail.status ||
      // eslint-disable-next-line
      inventoryCheckTaskDetail.status != constants.JOB_TASK_STATUSE.COMPLETED
    ) {
      return false;
    }
    return true;
  };

  initialCountAsFinalCount = (data, record) => {
    if (!this.isTaskCompleted()) {
      return false;
    }
    if (record.initialCount === record.finalCount && !data.finalCount) {
      return true;
    }
  };

  prepareColumns = (props) => [
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.taskDetail.productTable.productImage',
      ),
      dataIndex: 'picURL',
      key: 'picURL',
      render: (picURL) => (
        <Avatar
          className="product-image"
          shape="square"
          src={getTranslation.obj(picURL)}
        />
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.taskDetail.productTable.productId',
      ),
      dataIndex: 'productId',
      key: 'productId',
      render: (productId) => (
        <CopyToClipboard message={productId} inner={<Icon type="copy" />} />
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.taskDetail.productTable.productName',
      ),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (name) => <CopyToClipboard message={getTranslation.obj(name)} />,
      ...tableHelper.fieldSorter.getStringSortProps(`name.${getLangKey()}`),
    },
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.taskDetail.productTable.initialCount',
      ),
      dataIndex: 'expireDates',
      key: 'initialCount',
      render: (expireDates) => {
        if (!expireDates || !expireDates.length) {
          return '-';
        }

        const datas = expireDates.map((data, index) => {
          if (!data.initialCount) {
            return (
              <tr key={`initialCount-${index}`}>
                <td> - </td>
              </tr>
            );
          }
          return (
            <tr key={`initialCount-${index}`}>
              <td>{data.initialCount}</td>
            </tr>
          );
        });
        return (
          <table>
            <tbody>{datas}</tbody>
          </table>
        );
      },
    },
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.taskDetail.productTable.finalCount',
      ),
      dataIndex: 'expireDates',
      key: 'finalCount',
      render: (expireDates, record) => {
        if (!expireDates || !expireDates.length) {
          return '-';
        }

        const datas = expireDates.map((data, index) => {
          if (!data.finalCount) {
            return (
              <tr key={`finalCount-${index}`}>
                <td> - </td>
              </tr>
            );
          }
          if (this.initialCountAsFinalCount(data, record)) {
            return (
              <tr key={`finalCount-${index}`}>
                <td>{data.initialCount}</td>
              </tr>
            );
          }
          return (
            <tr key={`finalCount-${index}`}>
              <td>{data.finalCount}</td>
            </tr>
          );
        });
        return (
          <table>
            <tbody>{datas}</tbody>
          </table>
        );
      },
    },
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.taskDetail.productTable.expireDate',
      ),
      dataIndex: 'expireDates',
      key: 'expireDate',
      render: (expireDates) => {
        if (!expireDates || !expireDates.length) {
          return '-';
        }

        const datas = expireDates.map((data, index) => {
          if (!data.expireDate) {
            return (
              <tr key={`expireDate-${index}`}>
                <td> - </td>
              </tr>
            );
          }
          return (
            <tr key={`expireDate-${index}`}>
              <td>
                {
                  <CopyToClipboard
                    message={moment(new Date(data.expireDate))
                      .local()
                      .format(getDateFormatString())}
                  />
                }
              </td>
            </tr>
          );
        });
        return (
          <table>
            <tbody>{datas}</tbody>
          </table>
        );
      },
    },
  ];

  prepareData = (props) => {
    const { inventoryCheckTaskDetail } = this.props;
    if (
      !inventoryCheckTaskDetail ||
      !inventoryCheckTaskDetail.data ||
      !inventoryCheckTaskDetail.data.products ||
      !inventoryCheckTaskDetail.data.products.length
    ) {
      return null;
    }
    return inventoryCheckTaskDetail.data.products.filter((product) => {
      const { productFilter } = this.state;
      if (!productFilter) {
        return true;
      }
      return getTranslation
        .obj(product.fullName)
        .toUpperCase()
        .includes(productFilter);
    });
  };

  getDetailData = () => {
    const options = this.getDetailOptions();
    if (!options) {
      return null;
    }
    this.props.onGetInventoryCheckTaskDetail(options);
  };

  getDetailOptions = () => {
    const { inventoryCheckId, jobId, jobTaskId } = this.props;
    if (!inventoryCheckId || !jobId || !jobTaskId) {
      return null;
    }
    return { inventoryCheckId, jobId, jobTaskId };
  };

  getCountOfCompletedProduct = (task) =>
    task.data.products.filter((product) => product.finalCount).length;

  getCountOfTaskProducts = (task) => task.data.products.length;

  getCompletionRate = (task) =>
    Math.floor(
      (this.getCountOfCompletedProduct(task) /
        this.getCountOfTaskProducts(task)) *
        100,
    );
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  inventoryCheckTaskDetail: state.inventory.inventoryCheckTaskDetail.data,
  loading: state.inventory.inventoryCheckTaskDetail.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onGetInventoryCheckTaskDetail: (options) => {
    dispatch(inventoryAction.getInventoryCheckTaskDetail(options));
  },
});

const InventoryCheckTaskDetailConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(InventoryCheckTaskDetail));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { InventoryCheckTaskDetailConnected as InventoryCheckTaskDetail };
