import React, { Component } from 'react';
import { Table, Avatar, Button, Modal } from 'antd';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { getDateTimeFormatString } from './../../../../../shared';

/*------------------------------------------------------------------------------
------------------------------------------------------------------------------*/

class WaybillTable extends Component {
  dateFormat = getDateTimeFormatString();

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    return {
      wpoRejectionType: null,
      waybill: null,
      isOpenModalConclude: false,
      isWaybillModalOpen: false,
    };
  };

  render() {
    return (
      <div>
        <this.WaybillModal />
        <this.WaybillTable />
      </div>
    );
  }

  WaybillTable = () => {
    return (
      <Table
        size="small"
        columns={this.prepareWaybillColumns()}
        dataSource={this.prepareWaybillData()}
        rowKey={(record) => {
          return record.ss;
        }}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  prepareWaybillColumns = (props) => {
    return [
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.detail.table.no',
        ),
        dataIndex: 'ss',
        key: 'ss',
        width: 100,
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.detail.table.image',
        ),
        dataIndex: 'imageUrl',
        key: 'imageUrl',
        width: 72,
        render: (image) => {
          return (
            <Avatar
              className="waybill-table-image grow"
              shape="square"
              src={image}
            />
          );
        },
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.detail.table.createdAt',
        ),
        dataIndex: 'date',
        key: 'date',
        render: (date) => {
          return moment(new Date(date))
            .local()
            .format(this.dateFormat);
        },
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'detail',
        render: (id, waybill) => {
          return <this.DetailWaybillButton waybill={waybill} />;
        },
      },
    ];
  };

  DetailWaybillButton = (props) => {
    const { waybill } = props;
    return (
      <Button
        size="small"
        onClick={() => {
          this.setState({ waybill });
          this.setState({ isWaybillModalOpen: true });
        }}
      >
        {this.props.t(
          'pages.Operation.BlockedStock.transfer.detail.table.detail',
        )}
      </Button>
    );
  };

  WaybillModal = () => {
    const { waybill } = this.state;
    if (!waybill) {
      return null;
    }
    return (
      <Modal
        title={waybill.ss}
        visible={this.state.isWaybillModalOpen}
        onOk={() => {
          this.setState({ isWaybillModalOpen: false });
          this.setState({ waybill: null });
        }}
        onCancel={() => {
          this.setState({ isWaybillModalOpen: false });
          this.setState({ waybill: null });
        }}
      >
        <img
          alt={`waybill-${waybill.ss}`}
          className="waybill-modal-image"
          src={waybill.imageUrl}
          style={{ maxWidth: '100%', maxHeight: '100%', margin: 'auto' }}
        />
      </Modal>
    );
  };

  prepareWaybillData = (props) => {
    const { waybill } = this.props;
    if (!waybill || !waybill.waybills || !waybill.waybills.length) {
      return [];
    }
    const { waybills } = waybill;
    return waybills;
  };
}
const WaybillTableTranslated = withNamespaces('translation')(WaybillTable);

export { WaybillTableTranslated as WaybillTable };
