import React, { Component } from 'react';
import { Card, Form } from 'antd';
/*----------------------------------------------------------------------------*/
import './SupplierInfoPanel.scss';
import { SupplierSelect } from './SupplierSelect';
import { SupplierAccountSelect } from './SupplierAccountSelect';
import { SendToSupplierSelect } from './SendToSupplierSelect';
import i18n from '../../../../../i18n';
import { track } from '../../../../../services/segment';
import { PurchaseOrderCreateEvents } from '../../../../../services/events';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class SupplierInfoPanel extends Component {
  settings = {
    main: {
      className: 'SupplierInfoPanel_main',
    },
    card: {
      className: 'no-padding-card',
      title: i18n.t(
        'pages.Operation.PurchaseOrder.create.SupplierInfoPanel.title',
      ),
      size: 'small',
    },
  };

  formItemLayout = {
    style: {
      marginTop: 15,
    },
    labelCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 4, offset: 0 },
    },
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 0 },
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => {
    return {
      supplier: null,
      account: null,
      isEdiEnabled: null,
    };
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card}>
          <this.SupplierForm />
        </Card>
      </div>
    );
  }

  SupplierForm = (props) => {
    return (
      <Form {...this.formItemLayout}>
        <this.SupplierField />
        <this.AccountField />
        <this.SendToSupplierField />
      </Form>
    );
  };

  SupplierField = (props) => {
    const { supplierList, value } = this.props;
    const supplier = this.getSupplier();
    return (
      <Form.Item
        label={i18n.t(
          'pages.Operation.PurchaseOrder.create.SupplierInfoPanel.form.supplier',
        )}
      >
        <SupplierSelect
          disabled={!!value}
          onChange={this.onSupplierChange}
          value={supplier}
          placeholder={i18n.t(
            'pages.Operation.PurchaseOrder.create.SupplierInfoPanel.form.supplier',
          )}
          list={supplierList}
        />
      </Form.Item>
    );
  };

  AccountField = (props) => {
    const { value } = this.props;
    const supplier = this.getSupplier();
    const account = this.getAccount();
    return (
      <Form.Item
        label={i18n.t(
          'pages.Operation.PurchaseOrder.create.SupplierInfoPanel.form.account',
        )}
      >
        <SupplierAccountSelect
          onChange={this.onAccountChange}
          value={account}
          placeholder={i18n.t(
            'pages.Operation.PurchaseOrder.create.SupplierInfoPanel.form.account',
          )}
          list={supplier && supplier.accounts}
          disabled={
            !supplier ||
            !supplier.accounts ||
            !supplier.accounts.length ||
            value
          }
        />
      </Form.Item>
    );
  };

  SendToSupplierField = (props) => {
    const supplier = this.getSupplier();
    const isEdiEnabled = this.getIsEdiEnabled();
    if (!supplier || !supplier.isApiEnabled) {
      return <></>;
    }
    return (
      <Form.Item
        label={i18n.t(
          'pages.Operation.PurchaseOrder.create.SupplierInfoPanel.form.isEdiEnabled',
        )}
      >
        <SendToSupplierSelect
          onChange={this.onSendToSupplierChange}
          supplier={supplier}
          value={isEdiEnabled}
          placeholder={i18n.t(
            'pages.Operation.PurchaseOrder.create.SupplierInfoPanel.form.isEdiEnabled',
          )}
        />
      </Form.Item>
    );
  };

  onSupplierChange = (supplier) => {
    track(PurchaseOrderCreateEvents.PO_CREATE_SUPPLIER_SELECTED, { supplierName: supplier.name });
    this.setState({ supplier });
    this.setState({ account: null });
    if (!supplier) {
      this.setState({ isEdiEnabled: null });
    } else {
      this.setState({ isEdiEnabled: !supplier.isApiEnabled ? false : null });
    }

    this.onChange();
    this.props.onChangeSupplier();
  };

  onAccountChange = (account) => {
    track(PurchaseOrderCreateEvents.PO_CREATE_ACCOUNT_SELECTED);
    this.setState({ account });
    this.onChange();
  };

  onSendToSupplierChange = (isEdiEnabled) => {
    track(PurchaseOrderCreateEvents.PO_CREATE_SEND_TO_SUPPLIER_SELECTED, { isEdiEnabled });
    this.setState({ isEdiEnabled });
    this.onChange();
  };

  onChange = () => {
    setTimeout(() => {
      this.props.onChange(this.state);
    });
  };

  clearState = () => {
    this.setState(this.stateFactory());
  };

  getSupplier = () => {
    const { value } = this.props;
    if (value) {
      return value.supplier;
    }
    return this.state.supplier;
  };

  getAccount = () => {
    const { value } = this.props;
    if (value) {
      return value.account;
    }
    return this.state.account;
  };

  getIsEdiEnabled = () => {
    return this.state.isEdiEnabled;
  };

  getStateOrValue = () => {
    const { value } = this.props;
    if (value) {
      return {
        ...value,
        isEdiEnabled: value.supplier.isApiEnabled
          ? this.state.isEdiEnabled
          : false,
      };
    }
    return this.state;
  };
}

const SupplierInfoPanelTranslated = SupplierInfoPanel;

export { SupplierInfoPanelTranslated as SupplierInfoPanel };
