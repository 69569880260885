import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Descriptions,
  Table,
  Dropdown,
  Button,
  Icon,
  Menu,
  Row,
  Col,
} from 'antd';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './StockMovementLogDetail.scss';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard, JsonButton } from './../../../../components';
import { stockMovementLogAction } from './../../../../actions';
import { getTranslation, getDateTimeFormatString, getDateFormatString } from './../../../../shared';
import { pageLoadEvents } from '../../../../services/events';
import { pageView } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/

class StockMovementLogDetail extends Component {
  settings = {
    main: {
      className: 'StockMovementLogDetail_main',
    },
    card: {
      title: this.props.t('pages.Operation.StockMovementLog.detail.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_MOVEMENT_LOG_DETAIL });
    this.getDetail();
  }

  stateFactory = () => {
    return {};
  };

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {loading ? <Spinner /> : <this.PageDetail />}
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    return (
      <Dropdown overlay={this.MenuAction}>
        <Button style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.t(
            'pages.Operation.StockMovementLog.detail.button.actions',
          )}{' '}
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  MenuAction = () => {
    return (
      <Menu>
        <Menu.Item key="JsonButton">
          <JsonButton data={this.props.detail} block />
        </Menu.Item>
      </Menu>
    );
  };

  PageDetail = (props) => {
    return (
      <div>
        <Row>
          <Col span={6}>
            <this.PageDescription />
          </Col>
          <Col span={18}>
            <this.PageTable />
          </Col>
        </Row>
      </div>
    );
  };

  PageDescription = (props) => {
    const { detail } = this.props;
    if (!detail) {
      return null;
    }
    return (
      <Descriptions column={1}>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.StockMovementLog.detail.description.id',
          )}
        >
          <CopyToClipboard key={detail.id} message={detail.id} />
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.StockMovementLog.detail.description.warehouse',
          )}
        >
          {detail.warehouse.name}
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.StockMovementLog.detail.description.picker',
          )}
        >
          {(detail.picker && detail.picker.name) || '-'}
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.StockMovementLog.detail.description.fromProductLocation',
          )}
        >
          {(detail.fromProductLocation &&
            detail.fromProductLocation.warehouseLocation &&
            detail.fromProductLocation.warehouseLocation.barcode) ||
            '-'}
          <div>
            <b>
              {this.props.t(
                'pages.Operation.StockMovementLog.detail.description.quantity',
              )}
            </b>
            :{' '}
            {(detail.fromProductLocation &&
              detail.fromProductLocation.productLocations &&
              detail.fromProductLocation.productLocations[0] &&
              detail.fromProductLocation.productLocations[0].quantity) ||
              '-'}
          </div>
          <div>
            <b>
              {this.props.t(
                'pages.Operation.StockMovementLog.detail.description.expiry',
              )}
            </b>
            :{' '}
            {(detail.fromProductLocation &&
              detail.fromProductLocation.productLocations &&
              detail.fromProductLocation.productLocations[0] &&
              detail.fromProductLocation.productLocations[0].expiry &&
              moment(
                new Date(detail.fromProductLocation.productLocations[0].expiry),
              )
                .local()
                .format(getDateFormatString())) ||
              '-'}
          </div>
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.StockMovementLog.detail.description.toProductLocation',
          )}
        >
          {(detail.toProductLocation &&
            detail.toProductLocation.warehouseLocation &&
            detail.toProductLocation.warehouseLocation.barcode) ||
            '-'}
          <div>
            <b>
              {this.props.t(
                'pages.Operation.StockMovementLog.detail.description.quantity',
              )}
            </b>
            :{' '}
            {(detail.toProductLocation &&
              detail.toProductLocation.productLocations &&
              detail.toProductLocation.productLocations[0] &&
              detail.toProductLocation.productLocations[0].quantity) ||
              '-'}
          </div>
          <div>
            <b>
              {this.props.t(
                'pages.Operation.StockMovementLog.detail.description.expiry',
              )}
            </b>
            :{' '}
            {(detail.toProductLocation &&
              detail.toProductLocation.productLocations &&
              detail.toProductLocation.productLocations[0] &&
              detail.toProductLocation.productLocations[0].expiry &&
              moment(
                new Date(detail.toProductLocation.productLocations[0].expiry),
              )
                .local()
                .format(getDateFormatString())) ||
              '-'}
          </div>
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.StockMovementLog.detail.description.product',
          )}
        >
          {getTranslation.obj(detail.product.fullName)}
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.StockMovementLog.detail.description.quantity',
          )}
        >
          {detail.quantity}
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.StockMovementLog.detail.description.logger',
          )}
        >
          {(detail.logger && (
            <CopyToClipboard key={detail.logger} message={detail.logger} />
          )) ||
            '-'}
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.StockMovementLog.detail.description.loggerModel',
          )}
        >
          {this.getLink()}
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.StockMovementLog.detail.description.description',
          )}
        >
          {getTranslation.descriptionType(detail.description)}
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.StockMovementLog.detail.description.createdAt',
          )}
        >
          {moment(new Date(detail.createdAt))
            .local()
            .format(getDateTimeFormatString())}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  PageTable = (props) => {
    const { detail } = this.props;
    if (!detail || !detail.productStock) {
      return null;
    }
    return (
      <Table
        size="small"
        columns={this.prepareColumns()}
        dataSource={this.prepareData()}
        rowKey={(record) => {
          return record.id;
        }}
      />
    );
  };

  prepareColumns = (props) => {
    return [
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.detail.productStock.acceptance',
        ),
        dataIndex: 'acceptance',
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.detail.productStock.available',
        ),
        dataIndex: 'available',
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.detail.productStock.cancel',
        ),
        dataIndex: 'cancel',
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.detail.productStock.dead',
        ),
        dataIndex: 'dead',
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.detail.productStock.frozen',
        ),
        dataIndex: 'frozen',
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.detail.productStock.lock',
        ),
        dataIndex: 'lock',
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.detail.productStock.nonagreement',
        ),
        dataIndex: 'nonagreement',
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.detail.productStock.reserve',
        ),
        dataIndex: 'reserve',
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.detail.productStock.reservedForTransfer',
        ),
        dataIndex: 'reservedForTransfer',
      },
      {
        title: this.props.t(
          'pages.Operation.StockMovementLog.detail.productStock.total',
        ),
        dataIndex: 'total',
      },
    ];
  };

  getLink = () => {
    const { detail } = this.props;
    if (detail.loggerModel === 'Transfer') {
      return (
        <Link to={`/stock-operation/transfer/detail/${detail.logger}`}>
          {getTranslation.loggerModelType(detail.loggerModel)}
        </Link>
      );
    }
    if (detail.loggerModel === 'WarehousePurchaseOrder') {
      return (
        <Link
          to={`/stock-operation/warehouse-purchase-order/detail/${detail.logger}`}
        >
          {getTranslation.loggerModelType(detail.loggerModel)}
        </Link>
      );
    }
    if (detail.loggerModel === 'Order') {
      return getTranslation.loggerModelType(detail.loggerModel);
    }
    return getTranslation.loggerModelType(detail.loggerModel);
  };

  prepareData = (props) => {
    const { detail } = this.props;
    return [{ ...detail.productStock, id: 0 }];
  };

  getDetail = () => {
    const { id, onGetStockMovementLogDetail } = this.props;
    onGetStockMovementLogDetail(id);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.stockMovementLog.detail.loading,
  detail: state.stockMovementLog.detail.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetStockMovementLogDetail: (id) => {
    dispatch(stockMovementLogAction.getStockMovementLogDetail(id));
  },
});

const StockMovementLogDetailConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(StockMovementLogDetail));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { StockMovementLogDetailConnected as StockMovementLogDetail };
