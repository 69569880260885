import React from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import ReactJson from 'react-json-view';
import { Descriptions, Tag } from 'antd';
import { pickerAction } from './../../../actions';
import { Spinner } from '../../../components';
import { getTranslation } from '../../../shared';

class PickerJobDetail extends React.Component {
  componentDidMount() {
    this.props.onGetPickerJobDetail(this.props.pickerId);
  }

  render() {
    if (this.props.isPickerJobDetailLoading) {
      return <Spinner />;
    }
    if (!this.props.pickerJobDetail) {
      return null;
    }

    return (
      <div>
        <Descriptions column={1}>
          <Descriptions.Item
            label={this.props.t('pages.Home.pickerList.pickerName')}
          >
            {this.props.pickerName || '-'}
          </Descriptions.Item>
          <Descriptions.Item
            label={this.props.t('pages.Home.pickerList.assignedJob')}
          >
            <Tag color="purple">
              {getTranslation.pickerJobStatus(
                this.props.pickerJobDetail.workingType,
              ) || '-'}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
        <ReactJson
          src={this.props.pickerJobDetail.workingJob}
          enableClipboard={1}
          displayDataTypes={0}
          displayObjectSize={0}
          name={null}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pickerJobDetail: state.picker.pickerJobDetail.data,
  isPickerJobDetailLoading: state.picker.pickerJobDetail.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onGetPickerJobDetail: (pickerId) => {
    dispatch(pickerAction.getPickerJobDetail(pickerId));
  },
});

const PickerJobDetailConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(PickerJobDetail));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { PickerJobDetailConnected as PickerJobDetail };
