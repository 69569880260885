import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Button, Card, Table, Avatar, InputNumber } from 'antd';
import moment from 'moment';
// --------------------------------------------------
import './ReturnedOrders.scss';
// --------------------------------------------------
import { returnedOrdersAction } from '../../../actions';
import { Spinner, CopyToClipboard } from '../../../components';
import { pageView, track } from '../../../services/segment';
import { pageLoadEvents } from '../../../services/events';
// --------------------------------------------------
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

class ReturnedOrders extends Component {
  settings = {
    main: {
      className: 'ReturnedOrders_main',
    },
    card: {
      title: this.props.t('pages.Stock.ReturnedOrders.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.HOME_RETURN_ORDER });
    this.props.onGetReturnedOrders();
  }

  componentDidUpdate(prevProps) {
    const { returnedOrders } = this.props;
    if (prevProps.returnedOrders !== returnedOrders) {
      this.updateReturnOrders();
    }
  }

  stateFactory = () => {
    const state = {
      returnedOrders: {},
    };
    return state;
  };

  updateReturnOrders = () => {
    const returnedOrdersObj = {};
    if (!this.props.returnedOrders) {
      return;
    }
    this.props.returnedOrders.forEach((returnedOrder) => {
      const newProducts = {};
      returnedOrder.products.forEach((product) => {
        const newProduct = { ...product };
        newProduct.finalRefundCount = newProduct.initialRefundCount;
        newProducts[product.id] = newProduct;
      });
      const newReturnedOrder = {
        ...returnedOrder,
        products: newProducts,
      };
      returnedOrdersObj[returnedOrder.id] = newReturnedOrder;
    });
    this.setState({
      returnedOrders: returnedOrdersObj,
    });
  };

  prepareColumns = () => {
    const columns = [
      {
        title: this.props.t('pages.Stock.ReturnedOrders.table.clientName'),
        dataIndex: 'clientName',
      },
      {
        title: this.props.t('pages.Stock.ReturnedOrders.table.courierName'),
        dataIndex: 'courierName',
      },
      {
        title: this.props.t('pages.Stock.ReturnedOrders.table.pickerName'),
        dataIndex: 'pickerName',
      },
      {
        title: this.props.t('pages.Stock.ReturnedOrders.table.checkoutDate'),
        dataIndex: 'checkoutDate',
        render: (checkoutDate) =>
          moment(checkoutDate).isValid()
            ? moment(new Date(checkoutDate))
              .local()
              .format(dateFormat)
            : '-',
      },
      {
        title: this.props.t('pages.Stock.ReturnedOrders.table.itemCount'),
        dataIndex: 'itemCount',
      },
      {
        dataIndex: 'completeReturnedOrder',
        render: (_, record) => (
          <Button
            disabled={
              !this.validateAllProducts(this.state.returnedOrders[record.id])
            }
            onClick={() => this.completeReturnOrder(record.id)}
          >
            {this.props.t('pages.Stock.ReturnedOrders.acceptRefund')}
          </Button>
        ),
      },
    ];

    return columns;
  };

  validateAllProducts = (order) => {
    if (!order || !order.products) {
      return;
    }
    const { products } = order;
    // eslint-disable-next-line consistent-return
    return !Object.values(products).some((p) => {
      const { finalRefundCount, initialRefundCount } = p;
      return !this.validateProduct(finalRefundCount, initialRefundCount);
    });
  };

  validateProduct = (finalRefundCount, initialRefundCount) => {
    if (finalRefundCount === 0) {
      return true;
    }
    return !(
      finalRefundCount > initialRefundCount ||
      finalRefundCount < 0 ||
      (finalRefundCount && typeof finalRefundCount !== 'number')
    );
  };

  completeReturnOrder = (id) => {
    const order = this.state.returnedOrders[id];
    if (!order || !order.products) {
      return;
    }
    const refundProducts = Object.values(order.products).map((product) => {
      return {
        id: product.id,
        finalRefundCount:
          product.finalRefundCount || product.finalRefundCount === 0
            ? product.finalRefundCount
            : undefined,
      };
    });
    const data = {
      refundProducts,
    };
    this.props.onCompleteReturnedOrders(id, data);
    track('HOME_RETURN_ORDER_ACCEPT_CLICKED', data);
  };

  prepareData = () => {
    const { returnedOrders } = this.props;
    if (!returnedOrders || !returnedOrders.length) {
      return [];
    }
    return returnedOrders.map((order) => ({
      clientName: order.client.clientName || '-',
      courierName: order.courier.name || '-',
      pickerName: order.picker.name || '-',
      checkoutDate: new Date(order.dateOfOrder),
      itemCount: order.products ? order.products.length : 0,
      id: order.id,
      products: order.products,
      isDeparted: order.isDeparted,
    }));
  };

  expandedRowRender = (record) => (
    <Table
      size="small"
      columns={this.prepareExtendedColumns(record)}
      dataSource={this.prepareExtendedData(record)}
      rowKey={(data) => data.id}
      pagination={false}
    />
  );

  prepareExtendedData = (record) => {
    if (!record || !record.products || !record.products.length) {
      return null;
    }
    return record.products.map((product) => ({
      productName: product.fullName,
      productImage: product.picURL,
      refundReason: product.refundReason,
      targetLocation: product.warehouseLocationBarcode,
      orderCount: product.orderCount,
      pickedCount: product.pickedCount,
      initialRefundCount: product.initialRefundCount,
      productId: product.product,
      id: product.id,
      isAllowedToChangeRefundCount: product.isAllowedToChangeRefundCount,
    }));
  };

  prepareExtendedColumns = (record) => {
    const columns = [
      {
        title: this.props.t(
          'pages.Stock.ReturnedOrders.expandedTable.productImage',
        ),
        dataIndex: 'productImage',
        width: 72,
        render: (picURL) => (
          <Avatar className="grow" shape="square" src={picURL} />
        ),
      },
      {
        title: this.props.t(
          'pages.Stock.ReturnedOrders.expandedTable.productName',
        ),
        dataIndex: 'productName',
        render: (productName, data) => (
          <CopyToClipboard message={data.productId} inner={productName} />
        ),
      },
      {
        title: this.props.t('pages.Stock.ReturnedOrders.table.refundReason'),
        dataIndex: 'refundReason',
      },
      {
        title: this.props.t('pages.Stock.ReturnedOrders.table.targetLocation'),
        dataIndex: 'targetLocation',
      },
      {
        title: this.props.t(
          'pages.Stock.ReturnedOrders.expandedTable.refundCount',
        ),
        dataIndex: 'initialRefundCount',
        render: (_, productData) => {
          const finalRefundCount =
            this.state.returnedOrders[record.id] &&
            this.state.returnedOrders[record.id].products[productData.id] &&
            this.state.returnedOrders[record.id].products[productData.id]
              .finalRefundCount;
          return (
            <InputNumber
              className={
                !this.validateProduct(
                  finalRefundCount,
                  productData.initialRefundCount,
                ) && 'red'
              }
              disabled={!productData.isAllowedToChangeRefundCount}
              value={finalRefundCount}
              onChange={(e) => this.updateRefundCount(record, productData, e)}
            />
          );
        },
        width: 80,
      },
      {
        title: this.props.t('pages.Stock.ReturnedOrders.expandedTable.count'),
        dataIndex: 'orderCount',
        width: 80,
      },
    ];

    return columns;
  };

  updateRefundCount = (rowData, productData, value) => {
    const { returnedOrders } = this.state;
    const rowId = rowData.id;
    const { id } = productData;
    const newReturnedOrders = {
      ...returnedOrders,
      [rowId]: {
        ...returnedOrders[rowId],
        products: {
          ...returnedOrders[rowId].products,
          [productData.id]: {
            ...returnedOrders[rowId].products[id],
            finalRefundCount: value === '' ? undefined : value,
          },
        },
      },
    };
    this.setState({
      returnedOrders: newReturnedOrders,
    });
    track('HOME_RETURN_ORDER_REFUND_COUNT_CHANGED');
  };

  PageTable = () => (
    <div>
      <Table
        size="small"
        columns={this.prepareColumns()}
        dataSource={this.prepareData()}
        expandedRowRender={this.expandedRowRender}
        rowKey={(record) => record.id}
        pagination={false}
        loading={{ indicator: <Spinner />, spinning: this.props.loading }}
        onExpand={track('HOME_RETURN_ORDER_TABLE_ROW_EXPAND')}
      />
    </div>
  );

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card}>
          <this.PageTable />
        </Card>
      </div>
    );
  }
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  returnedOrders: state.returnedOrders.list.data,
  loading: state.returnedOrders.list.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onGetReturnedOrders: () => dispatch(returnedOrdersAction.getReturnedOrders()),
  onCompleteReturnedOrders: (id, data) =>
    dispatch(returnedOrdersAction.completeReturnedOrders(id, data)),
});

const ReturnedOrdersConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(ReturnedOrders));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { ReturnedOrdersConnected as ReturnedOrders };
