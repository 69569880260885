import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Row, Col } from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import './BlockedStockRefundList.scss';
import {
  ProductMultiSelect,
  DateRangeSelect,
  TagMultiSelect,
  SupplierMultiSelect,
} from './filterFields';
import { productAction, supplierAction, warehouseAction } from '../../../../actions';
/*----------------------------------------------------------------------------*/


class BlockedStockRefundFilter extends Component {
  settings = {
    main: {
      className: 'BlockedStockRefundFilter_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    const {
      onGetWarehouseList,
      onGetProductList,
      onGetSupplierList,
    } = this.props;
    onGetWarehouseList();
    onGetProductList();
    onGetSupplierList();
  }

  stateFactory = () => ({
    startDate: this.props.defaultDateRange.startDate,
    endDate: this.props.defaultDateRange.endDate,
    products: [],
    warehouses: [this.props.currentWarehouse],
    suppliers: [],
    refundIds: [],
    accountCodes: [],
    pickerIds: [],
  })

  render() {
    return (
      <div {...this.settings.main}>
        <this.TableFilterForm />
      </div>
    );
  }

  TableFilterForm = (props) => {
    return (
      <div>
        <Form>
          <Row>
            <Col span={5} style={{ padding: '0px 5px' }}>
              <this.DateRangeField />
            </Col>
            <Col span={11} style={{ padding: '0px 5px' }}>
              <this.ProductField />
            </Col>
          </Row>

          <Row>
            <Col span={12} style={{ padding: '0px 5px' }}>
              <this.SupplierField />
            </Col>
            <Col span={12} style={{ padding: '0px 5px' }}>
              <this.AccountCodeField />
            </Col>
          </Row>

          <Row>
            <Col span={12} style={{ padding: '0px 5px' }}>
              <this.RefundIdField />
            </Col>
            <Col span={12} style={{ padding: '0px 5px' }}>
              <this.PickerIdField />
            </Col>
          </Row>

          <Row>
            <Col span={2} style={{ padding: '0px 5px' }}>
              <this.ClearFilterButton />
            </Col>
            <Col offset={20} span={2} style={{ padding: '0px 5px' }}>
              <this.FilterButton />
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  DateRangeField = () => {
    return (
      <Form.Item>
        <DateRangeSelect
          defaultDateRange={this.props.defaultDateRange}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChange={(dateRange) => {
            this.setState({
              ...this.state,
              ...dateRange,
            });
          }}
        />
      </Form.Item>
    );
  };


  ProductField = () => {
    const { productList } = this.props;
    const { products } = this.state;
    const list = (productList && productList.data) || null;
    return (
      <Form.Item>
        <ProductMultiSelect
          list={list}
          onChange={(products) => {
            this.setState({
              products,
            });
          }}
          value={products}
          loading={productList.loading}
          placeholder={this.props.t(
            'pages.Stock.BlockedStock.refund.list.filter.product',
          )}
        />
      </Form.Item>
    );
  };

  SupplierField = (props) => {
    const { supplierList } = this.props;
    const { suppliers } = this.state;
    return (
      <Form.Item>
        <SupplierMultiSelect
          onChange={(suppliers) => {
            this.setState({ suppliers });
          }}
          value={suppliers}
          placeholder={this.props.t(
            'pages.Stock.BlockedStock.refund.list.filter.supplier',
          )}
          list={supplierList}
        />
      </Form.Item>
    );
  };

  RefundIdField = () => {
    const { refundIds } = this.state;
    return (
      <Form.Item>
        <TagMultiSelect
          placeholder={this.props.t(
            'pages.Stock.BlockedStock.refund.list.filter.refundId',
          )}
          disabled={false}
          onChange={(refundIds) => {
            if (refundIds[0] === '') {
              this.setState({
                refundIds: [],
              });
              return;
            }

            this.setState({
              refundIds,
            });
          }}
          value={refundIds}
        />
      </Form.Item>
    );
  };

  AccountCodeField = () => {
    const { accountCodes } = this.state;
    return (
      <Form.Item>
        <TagMultiSelect
          placeholder={this.props.t(
            'pages.Stock.BlockedStock.refund.list.filter.accountCode',
          )}
          disabled={false}
          onChange={(accountCodes) => {
            if (accountCodes[0] === '') {
              this.setState({
                accountCodes: [],
              });
              return;
            }

            this.setState({
              accountCodes,
            });
          }}
          value={accountCodes}
        />
      </Form.Item>
    );
  };

  PickerIdField = () => {
    const { pickerIds } = this.state;
    return (
      <Form.Item>
        <TagMultiSelect
          placeholder={this.props.t(
            'pages.Stock.BlockedStock.refund.list.filter.pickerId',
          )}
          disabled={false}
          onChange={(pickerIds) => {
            if (pickerIds[0] === '') {
              this.setState({
                pickerIds: [],
              });
              return;
            }

            this.setState({
              pickerIds,
            });
          }}
          value={pickerIds}
        />
      </Form.Item>
    );
  };

  FilterButton = () => {
    return (
      <Form.Item>
        <Button
          type="primary"
          block
          onClick={() => {
            const filter = this.getTableFilter();
            this.props.onChange(filter);
          }}
        >
          {this.props.t(
            'pages.Stock.BlockedStock.refund.list.filter.filter',
          )}
        </Button>
      </Form.Item>
    );
  };

  ClearFilterButton = () => {
    return (
      <Form.Item>
        <Button
          block
          onClick={() => {
            this.clearFilter();
          }}
        >
          {this.props.t(
            'pages.Stock.BlockedStock.refund.list.filter.clean',
          )}
        </Button>
      </Form.Item>
    );
  };

  getTableFilter = () => {
    const {
      products,
      warehouses,
      startDate,
      endDate,
      suppliers,
      refundIds,
      accountCodes,
      pickerIds,
    } = this.state;

    const filter = {};

    filter.createdAt = {
      startDate: startDate || this.props.defaultDateRange.startDate,
      endDate: endDate || this.props.defaultDateRange.endDate,
    };

    if (products && products.length) {
      filter.productIds = products.map((p) => p.id);
    }

    if (warehouses && warehouses.length) {
      filter.warehouseIds = warehouses.map((warehouse) => warehouse.id);
    }

    if (suppliers && suppliers.length) {
      filter.supplierIds = suppliers.map((s) => s._id);
    }

    if (refundIds && refundIds.length) {
      filter.refundIds = refundIds;
    }

    if (pickerIds && pickerIds.length) {
      filter.pickerIds = pickerIds;
    }

    if (accountCodes && accountCodes.length) {
      filter.accountCodes = accountCodes;
    }

    return filter;
  };

  clearFilter = () => {
    this.setState({ ...this.state, ...this.stateFactory() }, () => {
      this.props.onClear(this.getTableFilter());
    });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  currentWarehouse: state.auth.currentWarehouse,
  warehouseList: state.warehouse.warehouseList,
  productList: state.product.productList,
  supplierList: state.supplier.list.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetWarehouseList: () => {
    dispatch(warehouseAction.getWarehouseList());
  },
  onGetProductList: () => {
    dispatch(
      productAction.filterProductList({
        fields: 'barcodes picURL fullName packagingInfo',
        includeDefaultFields: false,
      }),
    );
  },
  onGetSupplierList: () => {
    dispatch(supplierAction.getSupplierList());
  },
});

const BlockedStockRefundFilterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(BlockedStockRefundFilter));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { BlockedStockRefundFilterConnected as BlockedStockRefundFilter };
