/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Descriptions, Avatar, Table, Collapse } from 'antd';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './IncomingTransferDetail.scss';
import './../../Stock/Stock.scss';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard } from '../../../../components';
import { getTranslation, getDateTimeFormatString } from '../../../../shared';
import { transferAction } from '../../../../actions';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class IncomingTransferDetail extends Component {
  settings = {
    main: {
      className: 'IncomingTransferDetail_main',
    },
    card: {
      title: this.props.t('pages.Stock.IncomingTransfer.detail.pageTitle'),
      className: 'no-padding-card',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    this.getDetail();
  }

  stateFactory = () => ({});

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {loading ? <Spinner /> : <this.PageDetail />}
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    const settings = {
      div: {
        style: { display: 'flex' },
      },
    };
    return <div {...settings.div} />;
  };

  PageDetail = (props) => (
    <div>
      <this.Description />
      <this.Table />
      <this.TaskPanel />
    </div>
  );

  Description = (props) => {
    const { transfer } = this.props;
    if (!transfer) {
      return null;
    }
    return (
      <Descriptions column={1}>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.IncomingTransfer.detail.transfer.description.id',
          )}
        >
          <CopyToClipboard message={transfer.id} />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.IncomingTransfer.detail.transfer.description.type',
          )}
        >
          {getTranslation.transferIntegrationType(transfer.integrationType)}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.IncomingTransfer.detail.transfer.description.status',
          )}
        >
          {getTranslation.transferStatus(transfer.status)}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.IncomingTransfer.detail.transfer.description.fromWarehouse',
          )}
        >
          {transfer.fromWarehouse.name}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.IncomingTransfer.detail.transfer.description.toWarehouse',
          )}
        >
          {transfer.toWarehouse.name}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.IncomingTransfer.detail.transfer.description.updatedAt',
          )}
        >
          {moment(new Date(transfer.updatedAt))
            .local()
            .format(getDateTimeFormatString())}
        </Descriptions.Item>
        {transfer.note && (
          <Descriptions.Item
            label={this.props.t(
              'pages.Stock.IncomingTransfer.detail.transfer.description.note',
            )}
          >
            <span>{transfer.note}</span>
          </Descriptions.Item>
        )}
      </Descriptions>
    );
  };

  Table = (props) => {
    const { transfer } = this.props;
    if (!transfer || !transfer.items) {
      return null;
    }
    const { items: products = [] } = transfer;
    return (
      <Table
        size="small"
        columns={this.prepareColumns(products)}
        dataSource={this.prepareData(products)}
        rowKey={(record) => record.item.id}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  prepareColumns = (props) => [
    {
      title: this.props.t(
        'pages.Stock.IncomingTransfer.detail.transfer.table.image',
      ),
      dataIndex: 'item.picURL',
      key: 'picURL',
      width: 72,
      render: (picURL) => (
        <Avatar
          className="product-image"
          shape="square"
          src={getTranslation.obj(picURL)}
        />
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.IncomingTransfer.detail.transfer.table.fullName',
      ),
      dataIndex: 'item.fullName',
      key: 'item.fullName',
      render: (fullName) => getTranslation.obj(fullName),
    },
    {
      title: this.props.t(
        'pages.Stock.IncomingTransfer.detail.transfer.table.number',
      ),
      dataIndex: 'count',
      key: 'count',
    },
  ];

  prepareData = (props) => props;

  TaskPanel = (props) => {
    const { transfer, CWId } = this.props;
    if (!transfer || !transfer.jobs || !transfer.jobs.length) {
      return null;
    }
    const pannelInner = [];
    const job = transfer.jobs.find(
      (job) => job.data.warehouse.toString() === CWId,
    );
    if (!job || !job.tasks || !job.tasks.length) {
      return null;
    }
    job.tasks.forEach((task) => {
      const comp = (
        <Collapse.Panel
          header={`${task.data.palletBarcode}`}
          key={task.data.palletBarcode}
        >
          <this.TaskTable task={task.data} key={task.id} />
        </Collapse.Panel>
      );
      pannelInner.push(comp);
    });
    return <Collapse>{pannelInner}</Collapse>;
  };

  TaskTable = (props) => {
    const { task } = props;
    return (
      <Table
        size="small"
        columns={this.prepareTaskColumns(task)}
        dataSource={this.prepareTaskData(task)}
        rowKey={(record) => record.id}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  prepareTaskColumns = (props) => [
    {
      title: this.props.t(
        'pages.Stock.IncomingTransfer.detail.transfer.table.image',
      ),
      dataIndex: 'picURL',
      key: 'picURL',
      width: 72,
      render: (picURL) => (
        <Avatar
          className="product-image"
          shape="square"
          src={getTranslation.obj(picURL)}
        />
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.IncomingTransfer.detail.transfer.table.fullName',
      ),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (fullName) => getTranslation.obj(fullName),
    },
    {
      title: this.props.t(
        'pages.Stock.IncomingTransfer.detail.transfer.table.sentCount',
      ),
      dataIndex: 'sentCount',
      key: 'sentCount',
    },
    {
      title: this.props.t(
        'pages.Stock.IncomingTransfer.detail.transfer.table.receivedCount',
      ),
      dataIndex: 'expireDates',
      key: 'receivedCount',
      render: (exp, record) =>
        exp.map((expItem, index) => (
          <div key={`${record.id}-${index}`}>{expItem.receivedCount}</div>
        )),
    },
    {
      title: this.props.t(
        'pages.Stock.IncomingTransfer.detail.transfer.table.damagedCount',
      ),
      dataIndex: 'expireDates',
      key: 'damagedCount',
      render: (exp, record) =>
        exp.map((expItem, index) => (
          <div key={`${record.id}-${index}`}>{expItem.damagedCount}</div>
        )),
    },
    {
      title: this.props.t(
        'pages.Stock.IncomingTransfer.detail.transfer.table.expireDate',
      ),
      dataIndex: 'expireDates',
      key: 'expireDate',
      render: (exp) =>
        exp.map((expItem, index) => {
          if (!expItem.expireDate) {
            return <div key={index}>-</div>;
          }
          return (
            <div key={expItem.expireDate.toString()}>
              {moment(new Date(expItem.expireDate))
                .local()
                .format(getDateTimeFormatString())}
            </div>
          );
        }),
    },
  ];

  prepareTaskData = (task) => {
    if (!task || !task.items || !task.items.length) {
      return null;
    }
    return task.items.map((record) => {
      const product = this.getProductById(record.item);
      return {
        picURL: product.picURL,
        fullName: product.fullName,
        sentCount: record.sentCount,
        id: product.id,
        expireDates: record.expireDates || [],
      };
    });
  };

  getProductById = (id) => {
    const { transfer } = this.props;
    if (!transfer || !transfer.items || !transfer.items.length) {
      return null;
    }
    return transfer.items.filter((record) => record.item.id === id)[0].item;
  };

  getDetail = () => {
    const { onGetIncomingTransferDetail, id } = this.props;
    onGetIncomingTransferDetail(id);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  transfer: state.transfer.detail.data,
  loading: state.transfer.detail.loading,
  CWId: state.auth.currentWarehouse._id,
});

const mapDispatchToProps = (dispatch) => ({
  onGetIncomingTransferDetail: (id) =>
    dispatch(transferAction.getIncomingTransferDetail(id)),
});

const IncomingTransferDetailConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(IncomingTransferDetail));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { IncomingTransferDetailConnected as IncomingTransferDetail };
