import React, { Component } from 'react';
import { Select } from 'antd';

export class PalletBarcodeSelect extends Component {
  InputField = () => {
    const { placeholder } = this.props;
    return (
      <Select
        mode="tags"
        placeholder={placeholder}
        value={this.getValue()}
        allowClear
        onChange={this.onChange}
      />
    );
  };

  getValue = () => {
    const { value } = this.props;
    if (!value || !value.length) {
      return;
    }
    let palletBarcodes = value
      .toString()
      .trim()
      .split(',')
      .map((palletBarcode) => palletBarcode.trim());

    if (!palletBarcodes.length) {
      palletBarcodes = [value.trim()];
    }

    return palletBarcodes;
  };

  onChange = (value) => {
    let palletBarcodes = value
      .toString()
      .trim()
      .split(',')
      .map((transfer) => transfer.trim());

    if (!palletBarcodes.length) {
      palletBarcodes = [value.trim()];
    }
    this.props.onChange(palletBarcodes);
  };

  render() {
    return <this.InputField />;
  }
}
