import React, { Component } from 'react';
import { differenceBy } from 'lodash';
import { Card, Form } from 'antd';
/*----------------------------------------------------------------------------*/
import { FromWarehouseMultiSelect } from './FromWarehouseMultiSelect';
import { ToWarehouseMultiSelect } from './ToWarehouseMultiSelect';
import { TransferTypeSelect } from './TransferTypeSelect';
import { TransferLogic } from './TransferLogic';
import i18n from '../../../../../i18n';
import { track } from '../../../../../services/segment';
import { CreateTransferEvents } from '../track';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------
------------------------------------------------------------------------------*/

class TransferInfoPanel extends Component {
  settings = {
    main: {
      className: 'TransferInfoPanel_main',
    },
    card: {
      title: i18n.t('pages.Operation.Transfer.create.TransferInfo.title'),
      size: 'small',
    },
  };

  formItemLayout = {
    style: {
      marginTop: 15,
    },
    labelCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 8, offset: 0 },
    },
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 15, offset: 0 },
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
    this.transferTypeRef = React.createRef();
    this.fromWarehouseRef = React.createRef();
    this.toWarehouseRef = React.createRef();
  }

  stateFactory = () => {
    return {
      transferType: null,
      fromWarehouses: [],
      toWarehouses: [],
      isNonAgreement: true,
    };
  };

  componentDidMount() {}

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card}>
          <this.TransferInfoForm />
        </Card>
      </div>
    );
  }

  TransferInfoForm = (props) => {
    return (
      <Form {...this.formItemLayout}>
        <this.TransferTypeField />
        <this.FromWarehouseField />
        <this.ToWarehouseField />
      </Form>
    );
  };

  TransferTypeField = () => {
    return (
      <Form.Item
        label={i18n.t(
          'pages.Operation.Transfer.create.TransferInfo.infoForm.transferType',
        )}
      >
        <TransferTypeSelect
          ref={this.transferTypeRef}
          onChange={this.onTransferTypeChange}
          onChangeForNonAggrement={this.onChangeForNonAggrement}
          placeholder={i18n.t(
            'pages.Operation.Transfer.create.TransferInfo.infoForm.transferType',
          )}
          isNonAgreement={this.state.isNonAgreement}
        />
      </Form.Item>
    );
  };

  FromWarehouseField = () => {
    const { warehouseList } = this.props;
    const warehouseItemList = (warehouseList && warehouseList.data) || null;
    let list = [];
    if (warehouseItemList) {
      list = [...list, ...warehouseItemList];
    }

    const { fromWarehouses } = this.state;

    if (TransferLogic.isFromWarehouseNonAgreement(fromWarehouses)) {
      if (this.state.isNonAgreement) {
        this.transferTypeRef.current.onChangeForNonAggrement();
      }
    }

    return (
      <Form.Item
        label={i18n.t(
          'pages.Operation.Transfer.create.TransferInfo.infoForm.fromWarehouse',
        )}
      >
        <FromWarehouseMultiSelect
          ref={this.fromWarehouseRef}
          disabled={this.isFromWarehouseDisabled()}
          list={list}
          onChange={this.onFromWarehouseChange}
          placeholder={i18n.t(
            'pages.Operation.Transfer.create.TransferInfo.infoForm.fromWarehouse',
          )}
        />
      </Form.Item>
    );
  };

  ToWarehouseField = () => {
    const { warehouseList } = this.props;
    const warehouseItemList = (warehouseList && warehouseList.data) || null;
    let list = [];
    if (warehouseItemList) {
      list = [...list, ...warehouseItemList];
    }

    const { fromWarehouses, toWarehouses } = this.state;
    let toWarehouseList = [];
    if (list && fromWarehouses && fromWarehouses.length) {
      toWarehouseList = differenceBy(list, fromWarehouses, 'id');
    }

    if (TransferLogic.isFromWarehouseNonAgreement(fromWarehouses)) {
      toWarehouseList = toWarehouseList.filter(
        (warehouse) => !warehouse.isNonAgreementStore,
      );
      if (this.state.isNonAgreement) {
        this.transferTypeRef.current.onChangeForNonAggrement();
      }
    }

    if (TransferLogic.isToWarehouseNonAgreement(toWarehouses)) {
      if (this.state.isNonAgreement) {
        this.transferTypeRef.current.onChangeForNonAggrement();
      }
    }

    return (
      <Form.Item
        label={i18n.t(
          'pages.Operation.Transfer.create.TransferInfo.infoForm.toWarehouse',
        )}
      >
        <ToWarehouseMultiSelect
          ref={this.toWarehouseRef}
          disabled={this.isToWarehouseDisabled()}
          list={toWarehouseList}
          fromWarehouses={this.state.fromWarehouses}
          onChange={this.onToWarehouseChange}
          placeholder={i18n.t(
            'pages.Operation.Transfer.create.TransferInfo.infoForm.toWarehouse',
          )}
        />
      </Form.Item>
    );
  };

  onTransferTypeChange = (transferType) => {
    if (!transferType) {
      this.setState(this.stateFactory());
      this.fromWarehouseRef.current.clearState();
      this.toWarehouseRef.current.clearState();
    }
    this.setState({
      transferType,
    });
    this.onChange();
    track(CreateTransferEvents.OPS_TRANSFER_TYPE_SELECTED, transferType);
  };

  onChangeForNonAggrement = (transferType) => {
    if (!transferType) {
      this.setState(this.stateFactory());
    }
    this.setState({
      transferType,
      isNonAgreement: false,
    });
    this.onChange();
  };

  onFromWarehouseChange = (fromWarehouses) => {
    this.setState({
      fromWarehouses,
    });
    if (TransferLogic.isFromWarehouseNonAgreement(fromWarehouses)) {
      this.setState({
        isNonAgreement: true,
      });
    }
    this.setState({
      fromWarehouses,
    });

    this.setState({
      toWarehouses: [],
    });
    this.toWarehouseRef.current.clearState();
    this.onChange();
    console.log(fromWarehouses);
    track(CreateTransferEvents.OPS_TRANSFER_FROM_WAREHOUSE_SELECTED, fromWarehouses.length ? {
      fromWarehouse: fromWarehouses[0]._id,
    } : '');
  };

  onToWarehouseChange = (toWarehouses) => {
    this.setState({
      toWarehouses,
    });

    if (TransferLogic.isToWarehouseNonAgreement(toWarehouses)) {
      this.setState({
        isNonAgreement: true,
      });
    }
    this.onChange();
    track(CreateTransferEvents.OPS_TRANSFER_TARGET_WAREHOUSE_SELECTED, toWarehouses.length ? {
      toWarehouse: toWarehouses[0]._id,
    } : '');
  };

  onChange = () => {
    setTimeout(() => {
      this.props.onChange(this.state);
    }, 200);
  };

  clearState = () => {
    this.setState(this.stateFactory());
    this.transferTypeRef.current.clearState();
    this.fromWarehouseRef.current.clearState();
    this.toWarehouseRef.current.clearState();
  };

  clearDirectionsState = () => {
    this.setState({ ...this.state, fromWarehouses: [], toWarehouses: [] });
    this.fromWarehouseRef.current.clearState();
    this.toWarehouseRef.current.clearState();
  };

  isFromWarehouseDisabled = () => {
    const { transferType } = this.state;
    const { imported } = this.props;
    return !transferType || imported;
  };

  isToWarehouseDisabled = () => {
    const { transferType, fromWarehouses } = this.state;
    const { imported } = this.props;
    return !transferType || !fromWarehouses.length || imported;
  };
}

const TransferInfoPanelTranslated = TransferInfoPanel;

export { TransferInfoPanelTranslated as TransferInfoPanel };
