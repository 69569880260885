export const pageLoadEvents = {
  HOME_ORDERS: "HOME_ORDERS",
  LOGIN: "LOGIN",
  HOME_MAP: "HOME_MAP",
  HOME_HEATING_ESTIMATION: "HOME_HEATING_ESTIMATION",
  HOME_CANCEL_ORDER: "HOME_CANCEL_ORDER",
  HOME_RETURN_ORDER: "HOME_RETURN_ORDER",
  HOME_ORDER_MISSING_PRODUCTS: "HOME_ORDER_MISSING_PRODUCTS",
  STOCK_INCOMING_TRANSFER_LIST: "STOCK_INCOMING_TRANSFER_LIST",
  STOCK_OUTGOING_TRANSFER_LIST: "STOCK_OUTGOING_TRANSFER_LIST",
  STOCK_PURCHASE_ORDER_LIST: "STOCK_PURCHASE_ORDER_LIST",
  STOCK_CREATE_CASE_AND_PALLET: "STOCK_CREATE_CASE_AND_PALLET",
  STOCK_EMPTY_CARBOY_TRANSFER: "STOCK_EMPTY_CARBOY_TRANSFER",
  STOCK_PURCHASE_ORDER_CREATE: "STOCK_PURCHASE_ORDER_CREATE",
  STOCK_REMOVE_FROM_SALES_LIST: "STOCK_REMOVE_FROM_SALES_LIST",
  STOCK_RFS_TRANSFER_TO_MW: "STOCK_RFS_TRANSFER_TO_MW",
  STOCK_RFS_RETURN: "STOCK_RFS_RETURN",
  STOCK_RFS_DISPOSAL: "STOCK_RFS_DISPOSAL",
  STOCK_RFS_CUSTOMER_SATISFACTION: "STOCK_RFS_CUSTOMER_SATISFACTION",
  STOCK_PRODUCT_LIST: "STOCK_PRODUCT_LIST",
  STOCK_PRODUCT_EXPIRATION_DATE: "STOCK_PRODUCT_EXPIRATION_DATE",
  STOCK_STOCK_CONTROL: "STOCK_STOCK_CONTROL",
  STOCK_CREATE_STOCK_CONTROL: "STOCK_CREATE_STOCK_CONTROL",
  STOCK_PRODUCT_PLACEMENT: "STOCK_PRODUCT_PLACEMENT",
  STOCK_WAREHOUSE_STOCK_LIST: "STOCK_WAREHOUSE_STOCK_LIST",
  STOCK_EQUIPMENT_LIST: "STOCK_EQUIPMENT_LIST",
  OPS_TRANSFER_LIST: "OPS_TRANSFER_LIST",
  OPS_TRANSFER_DETAIL: "OPS_TRANSFER_DETAIL",
  OPS_TRANSFER_BULK_APPROVE: "OPS_TRANSFER_BULK_APPROVE",
  OPS_TRANSFER_BULK_CANCEL: "OPS_TRANSFER_BULK_CANCEL",
  OPS_TRANSFER_CREATE: "OPS_TRANSFER_CREATE",
  OPS_PO_LIST: "OPS_PO_LIST",
  OPS_PO_CREATE: "OPS_PO_CREATE",
  OPS_PO_DETAIL: "OPS_PO_DETAIL",
  OPS_WPO_LIST: "OPS_WPO_LIST",
  OPS_WPO_DETAIL: "OPS_WPO_DETAIL",
  OPS_REMOVE_FROM_SALES_LIST: "OPS_REMOVE_FROM_SALES_LIST",
  OPS_REMOVE_FROM_SALES_DETAIL: "OPS_REMOVE_FROM_SALES_DETAIL",
  OPS_REMOVE_FROM_SALES_BULK_OPERATION: "OPS_REMOVE_FROM_SALES_BULK_OPERATION",
  OPS_REMOVE_FROM_SALES_RULES: "OPS_REMOVE_FROM_SALES_RULES",
  OPS_REMOVE_PRODUCT_REMOVAL_TRANSFER: "OPS_REMOVE_PRODUCT_REMOVAL_TRANSFER",
  OPS_REMOVE_PRODUCT_TRANSFER_LIST: "OPS_REMOVE_PRODUCT_TRANSFER_LIST",
  OPS_REMOVE_PRODUCT_DISPOSAL_LIST: "OPS_REMOVE_PRODUCT_DISPOSAL_LIST",
  OPS_REMOVE_PRODUCT_REMOVAL_REFUND: "OPS_REMOVE_PRODUCT_REMOVAL_REFUND",
  OPS_STOCK_CONTROL_LIST: "OPS_STOCK_CONTROL_LIST",
  OPS_STOCK_CONTROL_CREATE: "OPS_STOCK_CONTROL_CREATE",
  OPS_STOCK_CONTROL_DETAIL: "OPS_STOCK_CONTROL_DETAIL",
  OPS_STOCK_BULK_LIST: "OPS_STOCK_BULK_LIST",
  OPS_WAREHOUSE_STATUS: "OPS_WAREHOUSE_STATUS",
  OPS_MOVEMENT_LOG_LIST: "OPS_MOVEMENT_LOG_LIST",
  OPS_MOVEMENT_LOG_DETAIL: "OPS_MOVEMENT_LOG_DETAIL",
  OPS_PRODUCTION_MODULE: "OPS_MOVEMENT_LOG_DETAIL",
  OPS_EVENT_TRANSACTIONS: "OPS_EVENT_TRANSACTIONS",
};

export const PurchaseOrderListEvents = {
  PO_LIST_DATE_SELECTED: "PO_LIST_DATE_SELECTED",
  PO_LIST_NEW_TPS_CLICKED: "PO_LIST_NEW_TPS_CLICKED",
  PO_LIST_CLEAR_FILTER_CLICKED: "PO_LIST_CLEAR_FILTER_CLICKED",
  PO_LIST_MAIN_PO_FILTER_CHANGED: "PO_LIST_MAIN_PO_FILTER_CHANGED",
  PO_LIST_FILTER_APPLY_CLICKED: "PO_LIST_FILTER_APPLY_CLICK",
  PO_LIST_DETAIL_CLICKED: "PO_LIST_DETAIL_CLICKED",
};

export const WarehousePurchaseOrderListEvents = {
  WPO_LIST_DATE_SELECTED: "WPO_LIST_DATE_SELECTED",
  WPO_LIST_EXPORT_TO_EXCEL_CLICKED: "WPO_LIST_EXPORT_TO_EXCEL_CLICKED",
  WPO_LIST_CLEAR_FILTER_CLICKED: "WPO_LIST_CLEAR_FILTER_CLICKED",
  WPO_LIST_FILTER_APPLY_CLICKED: "WPO_LIST_FILTER_APPLY_CLICK",
  WPO_LIST_DETAIL_CLICKED: "WPO_LIST_DETAIL_CLICKED",
};

export const PurchaseOrderDetailEvents = {
  PO_DETAIL_JSON_CLICKED: "PO_DETAIL_JSON_CLICKED",
  PO_DETAIL_ORDER_CLICKED: "PO_DETAIL_ORDER_CLICKED",
  PO_DETAIL_CREATE_SPO_CLICKED: "PO_DETAIL_CREATE_SPO_CLICKED",
  PO_DETAIL_CANCEL_PO_CLICKED: "PO_DETAIL_CANCEL_PO_CLICKED",
  PO_DETAIL_SEND_TO_WMS_CLICKED: "PO_DETAIL_SEND_TO_WMS_CLICKED",
  PO_DETAIL_SEND_TO_SUPPLIER_CLICKED: "PO_DETAIL_SEND_TO_SUPPLIER_CLICKED",
  PO_DETAIL_WPO_DETAIL_BUTTON_CLICKED: "PO_DETAIL_WPO_DETAIL_BUTTON_CLICKED",
  PO_DETAIL_CALCULATE_DEFICIENCIES_BUTTON_CLICKED: "PO_DETAIL_CALCULATE_DEFICIENCIES_BUTTON_CLICKED",
};

export const WarehousePurchaseOrderDetailEvents = {
  WPO_DETAIL_APPROVE_CLICKED: "WPO_DETAIL_APPROVE_CLICKED",
  WPO_DETAIL_REJECT_CLICKED: "WPO_DETAIL_REJECT_CLICKED",
  WPO_DETAIL_CANCEL_CLICKED: "WPO_DETAIL_CANCEL_CLICKED",
};

export const TransferDetailEvents = {
  TRANSFER_DETAIL_SHIP_BUTTON_CLICKED: "TRANSFER_DETAIL_SHIP_BUTTON_CLICKED",
  TRANSFER_DETAIL_EDIT_BUTTON_CLICKED: "TRANSFER_DETAIL_EDIT_BUTTON_CLICKED",
  TRANSFER_DETAIL_SAVE_BUTTON_CLICKED: "TRANSFER_DETAIL_SAVE_BUTTON_CLICKED",
  TRANSFER_DETAIL_APPROVE_BUTTON_CLICKED: "TRANSFER_DETAIL_APPROVE_BUTTON_CLICKED",
  TRANSFER_DETAIL_CANCEL_BUTTON_CLICKED: "TRANSFER_DETAIL_CANCEL_BUTTON_CLICKED",
  TRANSFER_DETAIL_DELETE_BUTTON_CLICKED: "TRANSFER_DETAIL_DELETE_BUTTON_CLICKED",
  TRANSFER_DETAIL_WMS_BUTTON_CLICKED: "TRANSFER_DETAIL_WMS_BUTTON_CLICKED",
  TRANSFER_DETAIL_DEPART_TRANSFER_VEHICLES_BUTTON_CLICKED: "TRANSFER_DETAIL_DEPART_TRANSFER_VEHICLES_BUTTON_CLICKED",
  TRANSFER_DETAIL_SEND_DEPARTED_ORDER_BUTTON_CLICKED: "TRANSFER_DETAIL_SEND_DEPARTED_ORDER_BUTTON_CLICKED",
};

export const PurchaseOrderCreateEvents = {
  PO_CREATE_SUPPLIER_SELECTED: "PO_CREATE_SUPPLIER_SELECTED",
  PO_CREATE_ACCOUNT_SELECTED: "PO_CREATE_ACCOUNT_SELECTED",
  PO_CREATE_SEND_TO_SUPPLIER_SELECTED: "PO_CREATE_SEND_TO_SUPPLIER_SELECTED",
  PO_CREATE_EXPECTED_DELIVERY_DATE_SELECTED: "PO_CREATE_EXPECTED_DELIVERY_DATE_SELECTED",
  PO_CREATE_VISIBILITY_SETTINGS_SELECTED: "PO_CREATE_VISIBILITY_SETTINGS_SELECTED",
  PO_CREATE_VISIBILITY_TOGGLE_CHANGED: "PO_CREATE_VISIBILITY_TOGGLE_CHANGED",
  PO_CREATE_WAREHOUSE_ADDED: "PO_CREATE_WAREHOUSE_ADDED",
  PO_CREATE_ALL_WAREHOUSES_ADDED: "PO_CREATE_ALL_WAREHOUSES_ADDED",
  PO_CREATE_PRODUCT_ADDED: "PO_CREATE_PRODUCT_ADDED",
  PO_CREATE_IMPORT_CSV_CLICKED: "PO_CREATE_IMPORT_CSV_CLICKED",
  PO_CREATE_CLEAR_BUTTON_CLICKED: "PO_CREATE_CLEAR_BUTTON_CLICKED",
  PO_CREATE_SAVE_BUTTON_CLICKED: "PO_CREATE_SAVE_BUTTON_CLICKED",
  PO_CREATE_CANCEL_BUTTON_CLICKED: "PO_CREATE_CANCEL_BUTTON_CLICKED",
};
