import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  reasonList: {
    loading: true,
    data: null,
    error: null,
  },
  blockedStockList: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
  blockedStockDetail: {
    loading: true,
    data: null,
    error: null,
  },
  ruleList: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
  blockedStockRuleDetail: {
    loading: true,
    data: null,
    error: null,
  },
  blockedStockRequestList: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
  blockedStockTransferList: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
  blockedStockRefundList: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
  blockedStockDisposalList: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
  blockedStockTransferDetail: {
    loading: true,
    data: null,
    error: null,
  },
  blockedStockRefundDetail: {
    loading: true,
    data: null,
    error: null,
  },
  blockedStockDisposalDetail: {
    loading: true,
    data: null,
    error: null,
  },
};

const switcher = {
  [actionType.filterBlockedStockReasons_InProgress]: filterBlockedStockReasons_InProgress,
  [actionType.filterBlockedStockReasons_Succeeded]: filterBlockedStockReasons_Succeeded,
  [actionType.filterBlockedStockReasons_Failed]: filterBlockedStockReasons_Failed,

  [actionType.filterBlockedStock_InProgress]: filterBlockedStock_InProgress,
  [actionType.filterBlockedStock_Succeeded]: filterBlockedStock_Succeeded,
  [actionType.filterBlockedStock_Failed]: filterBlockedStock_Failed,

  [actionType.getBlockedStockDetail_InProgress]: getBlockedStockDetail_InProgress,
  [actionType.getBlockedStockDetail_Succeeded]: getBlockedStockDetail_Succeeded,
  [actionType.getBlockedStockDetail_Failed]: getBlockedStockDetail_Failed,

  [actionType.filterBlockedStockRule_InProgress]: filterBlockedStockRule_InProgress,
  [actionType.filterBlockedStockRule_Succeeded]: filterBlockedStockRule_Succeeded,
  [actionType.filterBlockedStockRule_Failed]: filterBlockedStockRule_Failed,

  [actionType.getBlockedStockRuleDetail_InProgress]: getBlockedStockRuleDetail_InProgress,
  [actionType.getBlockedStockRuleDetail_Succeeded]: getBlockedStockRuleDetail_Succeeded,
  [actionType.getBlockedStockRuleDetail_Failed]: getBlockedStockRuleDetail_Failed,

  [actionType.exportToXlsxBlockedStockList_InProgress]: exportToXlsxBlockedStockList_InProgress,
  [actionType.exportToXlsxBlockedStockList_Succeeded]: exportToXlsxBlockedStockList_Succeeded,
  [actionType.exportToXlsxBlockedStockList_Failed]: exportToXlsxBlockedStockList_Failed,

  [actionType.cancelBlockedStock_InProgress]: cancelBlockedStock_InProgress,
  [actionType.cancelBlockedStock_Succeeded]: cancelBlockedStock_Succeeded,
  [actionType.cancelBlockedStock_Failed]: cancelBlockedStock_Failed,

  [actionType.filterBlockedStockRequest_InProgress]: filterBlockedStockRequest_InProgress,
  [actionType.filterBlockedStockRequest_Succeeded]: filterBlockedStockRequest_Succeeded,
  [actionType.filterBlockedStockRequest_Failed]: filterBlockedStockRequest_Failed,

  [actionType.filterBlockedStockTransfers_InProgress]: filterBlockedStockTransfers_InProgress,
  [actionType.filterBlockedStockTransfers_Succeeded]: filterBlockedStockTransfers_Succeeded,
  [actionType.filterBlockedStockTransfers_Failed]: filterBlockedStockTransfers_Failed,

  [actionType.filterBlockedStockRefunds_InProgress]: filterBlockedStockRefunds_InProgress,
  [actionType.filterBlockedStockRefunds_Succeeded]: filterBlockedStockRefunds_Succeeded,
  [actionType.filterBlockedStockRefunds_Failed]: filterBlockedStockRefunds_Failed,

  [actionType.filterBlockedStockDisposals_InProgress]: filterBlockedStockDisposals_InProgress,
  [actionType.filterBlockedStockDisposals_Succeeded]: filterBlockedStockDisposals_Succeeded,
  [actionType.filterBlockedStockDisposals_Failed]: filterBlockedStockDisposals_Failed,

  [actionType.getBlockedStockTransfer_InProgress]: getBlockedStockTransfer_InProgress,
  [actionType.getBlockedStockTransfer_Succeeded]: getBlockedStockTransfer_Succeeded,
  [actionType.getBlockedStockTransfer_Failed]: getBlockedStockTransfer_Failed,

  [actionType.getBlockedStockRefund_InProgress]: getBlockedStockRefund_InProgress,
  [actionType.getBlockedStockRefund_Succeeded]: getBlockedStockRefund_Succeeded,
  [actionType.getBlockedStockRefund_Failed]: getBlockedStockRefund_Failed,

  [actionType.getBlockedStockDisposal_InProgress]: getBlockedStockDisposal_InProgress,
  [actionType.getBlockedStockDisposal_Succeeded]: getBlockedStockDisposal_Succeeded,
  [actionType.getBlockedStockDisposal_Failed]: getBlockedStockDisposal_Failed,
};

export const blockedStockReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function filterBlockedStockReasons_InProgress(state, payload) {
  return Object.assign({}, state, {
    reasonList: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function filterBlockedStockReasons_Succeeded(state, payload) {
  return Object.assign({}, state, {
    reasonList: {
      loading: false,
      data: payload.blockedStockReasons,
      error: null,
    },
  });
}

function filterBlockedStockReasons_Failed(state, payload) {
  return Object.assign({}, state, {
    reasonList: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function filterBlockedStock_InProgress(state, payload) {
  return Object.assign({}, state, {
    blockedStockList: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function filterBlockedStock_Succeeded(state, payload) {
  return Object.assign({}, state, {
    blockedStockList: {
      loading: false,
      data: payload.blockedStocks,
      error: null,
      total: payload.total,
    },
  });
}

function filterBlockedStock_Failed(state, payload) {
  return Object.assign({}, state, {
    blockedStockList: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

function getBlockedStockDetail_InProgress(state, payload) {
  return Object.assign({}, state, {
    blockedStockDetail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getBlockedStockDetail_Succeeded(state, payload) {
  return Object.assign({}, state, {
    blockedStockDetail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getBlockedStockDetail_Failed(state, payload) {
  return Object.assign({}, state, {
    blockedStockDetail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function filterBlockedStockRule_InProgress(state, payload) {
  return Object.assign({}, state, {
    ruleList: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function filterBlockedStockRule_Succeeded(state, payload) {
  return Object.assign({}, state, {
    ruleList: {
      loading: false,
      data: payload.data,
      error: null,
      total: payload.total,
    },
  });
}

function filterBlockedStockRule_Failed(state, payload) {
  return Object.assign({}, state, {
    ruleList: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

function getBlockedStockRuleDetail_InProgress(state, payload) {
  return Object.assign({}, state, {
    blockedStockRuleDetail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getBlockedStockRuleDetail_Succeeded(state, payload) {
  return Object.assign({}, state, {
    blockedStockRuleDetail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getBlockedStockRuleDetail_Failed(state, payload) {
  return Object.assign({}, state, {
    blockedStockRuleDetail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function exportToXlsxBlockedStockList_InProgress(state, payload) {
  return Object.assign({}, state, {
    isExporting: true,
  });
}

function exportToXlsxBlockedStockList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    isExporting: false,
  });
}

function exportToXlsxBlockedStockList_Failed(state, payload) {
  return Object.assign({}, state, {
    isExporting: false,
  });
}

function cancelBlockedStock_InProgress(state, payload) {
  return Object.assign({}, state, {
    cancelBlockedStock: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function cancelBlockedStock_Succeeded(state, payload) {
  return Object.assign({}, state, {
    cancelBlockedStock: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function cancelBlockedStock_Failed(state, payload) {
  return Object.assign({}, state, {
    cancelBlockedStock: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function filterBlockedStockRequest_InProgress(state, payload) {
  return Object.assign({}, state, {
    blockedStockRequestList: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function filterBlockedStockRequest_Succeeded(state, payload) {
  return Object.assign({}, state, {
    blockedStockRequestList: {
      loading: false,
      data: payload.blockedStockRequests,
      error: null,
      total: payload.total,
    },
  });
}

function filterBlockedStockRequest_Failed(state, payload) {
  return Object.assign({}, state, {
    blockedStockRequestList: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

function filterBlockedStockTransfers_InProgress(state, payload) {
  return Object.assign({}, state, {
    blockedStockTransferList: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function filterBlockedStockTransfers_Succeeded(state, payload) {
  return Object.assign({}, state, {
    blockedStockTransferList: {
      loading: false,
      data: payload.transfers,
      error: null,
      total: payload.total,
    },
  });
}

function filterBlockedStockTransfers_Failed(state, payload) {
  return Object.assign({}, state, {
    blockedStockTransferList: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

function filterBlockedStockRefunds_InProgress(state, payload) {
  return Object.assign({}, state, {
    blockedStockRefundList: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function filterBlockedStockRefunds_Succeeded(state, payload) {
  return Object.assign({}, state, {
    blockedStockRefundList: {
      loading: false,
      data: payload.refunds,
      error: null,
      total: payload.total,
    },
  });
}

function filterBlockedStockRefunds_Failed(state, payload) {
  return Object.assign({}, state, {
    blockedStockRefundList: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

function filterBlockedStockDisposals_InProgress(state, payload) {
  return Object.assign({}, state, {
    blockedStockDisposalList: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function filterBlockedStockDisposals_Succeeded(state, payload) {
  return Object.assign({}, state, {
    blockedStockDisposalList: {
      loading: false,
      data: payload.disposals,
      error: null,
      total: payload.total,
    },
  });
}

function filterBlockedStockDisposals_Failed(state, payload) {
  return Object.assign({}, state, {
    blockedStockDisposalList: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

function getBlockedStockTransfer_InProgress(state, payload) {
  return Object.assign({}, state, {
    blockedStockTransferDetail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getBlockedStockTransfer_Succeeded(state, payload) {
  return Object.assign({}, state, {
    blockedStockTransferDetail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getBlockedStockTransfer_Failed(state, payload) {
  return Object.assign({}, state, {
    blockedStockTransferDetail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function getBlockedStockRefund_InProgress(state, payload) {
  return Object.assign({}, state, {
    blockedStockRefundDetail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getBlockedStockRefund_Succeeded(state, payload) {
  return Object.assign({}, state, {
    blockedStockRefundDetail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getBlockedStockRefund_Failed(state, payload) {
  return Object.assign({}, state, {
    blockedStockRefundDetail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function getBlockedStockDisposal_InProgress(state, payload) {
  return Object.assign({}, state, {
    blockedStockDisposalDetail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getBlockedStockDisposal_Succeeded(state, payload) {
  return Object.assign({}, state, {
    blockedStockDisposalDetail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getBlockedStockDisposal_Failed(state, payload) {
  return Object.assign({}, state, {
    blockedStockDisposalDetail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}
