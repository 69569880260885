import React, { Component } from 'react';
import { Select, Avatar, Spin } from 'antd';
/*----------------------------------------------------------------------------*/
import { getTranslation } from './../../../../../shared';
/*----------------------------------------------------------------------------*/

export class ProductMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => ({
    filterInput: null,
  });

  render() {
    return <this.SelectField />;
  }

  SelectField = () => {
    const { disabled, loading, placeholder } = this.props;
    return (
      <Select
        mode="multiple"
        placeholder={placeholder}
        showSearch
        allowClear
        disabled={disabled}
        value={this.getValue()}
        filterOption={this.filterOption}
        onChange={this.onChange}
        onSearch={this.onSearch}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {this.getOptions()}
      </Select>
    );
  };

  getOptions = () => {
    const { list } = this.props;
    const { filterInput } = this.state;
    if (!list || !list.length) {
      return null;
    }
    let options = list;

    if (!filterInput) {
      options = options.slice(0, Math.min(10, options.length));
    } else {
      options = options.filter(
        (item) =>
          filterInput &&
          filterInput.length >= 3 &&
          (getTranslation
            .obj(item.fullName)
            .toLowerCase()
            .includes(filterInput.toLowerCase()) ||
            item.id.includes(filterInput) ||
            (item.barcodes &&
              item.barcodes.length &&
              item.barcodes.includes(filterInput))),
      );
    }

    return options.map((item) => (
      <Select.Option
        value={getTranslation.obj(item.fullName) + item.id}
        key={item.id}
        rawvalue={item}
      >
        <this.SelectOptionItem item={item} />
      </Select.Option>
    ));
  };

  SelectOptionItem = (props) => {
    const { item } = props;
    const settings = {
      label: item && item.fullName && getTranslation.obj(item.fullName),
      icon: {
        src: item && item.picURL && getTranslation.obj(item.picURL),
        size: 20,
        className: 'product-icon',
      },
    };
    return (
      <>
        <Avatar {...settings.icon} /> {settings.label}
      </>
    );
  };

  getValue = () => {
    const { value } = this.props;
    if (!value || !value.length) {
      return;
    }
    return value.map((item) => getTranslation.obj(item.fullName) + item.id);
  };

  filterOption = (input, option) =>
    option.props.rawvalue.fullName[getTranslation.local()]
      .toLowerCase()
      .indexOf(input.toLowerCase()) >= 0 ||
    option.props.rawvalue.id.toString().indexOf(input.toString()) >= 0 ||
    (option.props.rawvalue.barcodes &&
      option.props.rawvalue.barcodes.length &&
      option.props.rawvalue.barcodes.includes(input.toString()));

  onChange = (value, options) => {
    let selectedItems = [];
    try {
      selectedItems = options.map((o) => o.props.rawvalue);
    } catch (error) {
      selectedItems = [];
    }
    this.props.onChange(selectedItems);
  };

  onSearch = (value, option) => {
    this.setState({
      filterInput: value,
    });
  };
}
