import { createHttpService } from '../createHttpService';

const WAREHOUSE_ID = localStorage.getItem('warehouseId');
const httpService = createHttpService();

export const order = {
  getOrderList: () => httpService.get(`/warehouse/${WAREHOUSE_ID}/orders`),
  getOrderDetail: (id, domainType) =>
    httpService.get(
      `/warehouse/${WAREHOUSE_ID}/orders/${id}?domainType=${domainType}`,
    ),
  reportFraud: (orderId, domainType) =>
    httpService.post(
      `/warehouse/${WAREHOUSE_ID}/orders/${orderId}/fraud?domainType=${domainType}`,
    ),
  getWalkInOrders: () => httpService.get(`/retail-walk-in/${WAREHOUSE_ID}`),
  getWalkInOrderDetails: (orderId) =>
    httpService.get(`/retail-walk-in/${WAREHOUSE_ID}/orders/${orderId}`),
  walkInOrderDecreaseStock: (orderId) =>
    httpService.post(
      `/retail-walk-in/${WAREHOUSE_ID}/orders/${orderId}/decrease-stocks`,
    ),
  walkInOrderCancel: (orderId, cancelReason) =>
    httpService.post(
      `/retail-walk-in/${WAREHOUSE_ID}/orders/${orderId}/cancel`,
      { cancelReason },
    ),
  walkInOrderDeliver: (orderId, slipNo) =>
    httpService.post(
      `/retail-walk-in/${WAREHOUSE_ID}/orders/${orderId}/deliver`,
      { slipNo },
    ),
  filterWalkInOrders: (slipNo) =>
    httpService.post(
      `/retail-walk-in/${WAREHOUSE_ID}/orders/filter`,
      { slipNo },
    ),
    walkInOrderRefund: (orderId, refundSlipNo) =>
    httpService.post(
      `/retail-walk-in/${WAREHOUSE_ID}/orders/${orderId}/refund`,
      { refundSlipNo },
    ),
};
