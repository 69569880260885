import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Popconfirm, Tag } from 'antd';
// --------------------------------------------------
import { Can, permission } from './../../guard';
import { authAction } from './../../actions';
import { constants } from './../../shared';
import { track } from '../../services/segment';
// --------------------------------------------------

class HeaderToggle extends Component {
  onChangeSwitch = () => {
    if (this.currentWarehouseStatus() === constants.WAREHOUSE_STATUS.FREE) {
      this.props.updateWarehouseStatus({
        status: constants.WAREHOUSE_STATUS.BUSY,
      });
      track('HOME_WAREHOUSE_STATUS_CHANGE', constants.WAREHOUSE_STATUS.BUSY);
    }
    if (this.currentWarehouseStatus() === constants.WAREHOUSE_STATUS.BUSY) {
      this.props.updateWarehouseStatus({
        status: constants.WAREHOUSE_STATUS.FREE,
      });
      track('HOME_WAREHOUSE_STATUS_CHANGE', constants.WAREHOUSE_STATUS.FREE);
    }
  };

  currentWarehouseStatus = () => this.props.currentWarehouse.status;

  getPopconfirmText = () =>
    this.currentWarehouseStatus() === 300
      ? this.props.t('layout.headerToggle.busy')
      : this.props.t('layout.headerToggle.free');

  render() {
    const { t } = this.props;
    return (
      <Can allow={permission.warehouseStatus}>
        <Popconfirm
          placement="bottomRight"
          onConfirm={this.onChangeSwitch}
          title={t('main.setWarehouseStatus', {
            status: this.getPopconfirmText(),
          })}
        >
          <Tag
            color={this.currentWarehouseStatus() === 300 ? 'geekblue' : '#f50'}
            style={{ marginRight: 20 }}
          >
            {this.currentWarehouseStatus() === 300
              ? t('layout.headerToggle.warehouseFree')
              : t('layout.headerToggle.warehouseBusy')}
          </Tag>
        </Popconfirm>
      </Can>
    );
  }
}

const mapStateToProps = (state) => ({
  currentWarehouse: state.auth.currentWarehouse,
  updateWarehouseStatusLoading: state.auth.updateWarehouseStatusLoading,
});

const mapDispatchToProps = (dispatch) => ({
  updateWarehouseStatus: ({ status }) => {
    dispatch(authAction.updateWarehouseStatus({ status }));
  },
});
const HeaderToggleConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(HeaderToggle));

export { HeaderToggleConnected as HeaderToggle };
