import React, { Component } from 'react';
// --------------------------------------------------
import './PickerPanel.scss';
import { PickerFilter } from './PickerFilter';
import { PickerList } from './PickerList';
// --------------------------------------------------
import { Spinner } from '../../../components';
// --------------------------------------------------

export class PickerPanel extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory(props);
  }

  stateFactory = () => {
    const newState = {
      selectedFilters: [],
    };
    return newState;
  };

  onChangeFilter = (selectedFilters) => {
    this.setState({
      selectedFilters,
    });
  };

  render() {
    const { pickers } = this.props;
    const { loading, data } = pickers;
    const { selectedFilters } = this.state;
    if (loading) {
      return <Spinner />;
    }
    return (
      <div className="PickerPanel_inner">
        <PickerFilter onChangeFilter={this.onChangeFilter} />
        <PickerList pickers={data} selectedFilters={selectedFilters} />
      </div>
    );
  }
}
