/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Input, Card, Table, Avatar, Tag } from 'antd';
import { uniqBy } from 'lodash';
/*----------------------------------------------------------------------------*/
import './TransferProductListPanel.scss';
/*----------------------------------------------------------------------------*/
import {
  Spinner,
  EditableCell,
  EditableFormRow,
} from '../../../../../components';
import { tableHelper, getTranslation } from '../../../../../shared';
import i18n from '../../../../../i18n';
import { track } from '../../../../../services/segment';
import { CreateTransferEvents } from '../track';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class TransferProductListPanel extends Component {
  settings = {
    main: {
      className: 'TransferProductListPanel_main',
    },
    card: {
      title: i18n.t(
        'pages.Operation.Transfer.create.TransferProductList.title',
      ),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    return {
      filter: '',
      items: [],
      selectedItems: [],
    };
  };

  static getDerivedStateFromProps(props, state) {
    if (props.productList) {
      return {
        items: props.productList,
      };
    }
    return null;
  }

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    return (
      <Input.Search
        className="product-filter-input"
        allowClear
        onChange={this.onChangeSearch}
        placeholder={i18n.t(
          'pages.Operation.Transfer.create.TransferProductList.extra.filter',
        )}
      />
    );
  };

  PageTable = (props) => {
    return (
      <Table
        size="small"
        scroll={{ y: 500 }}
        columns={this.prepareColumns(this.props)}
        dataSource={this.prepareData(this.props)}
        rowKey={(record) => record.id}
        components={this.components}
        loading={{ indicator: <Spinner />, spinning: this.props.loading }}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  prepareColumns = () => {
    const { disabled } = this.props;
    const columns = [
      {
        title: i18n.t(
          'pages.Operation.Transfer.create.TransferProductList.productTable.barcode',
        ),
        dataIndex: 'barcodes',
        key: 'barcodes',
        width: 200,
        render: (barcodes) => {
          if (!barcodes || !barcodes.length) {
            return (
              <Tag color="blue" style={{ margin: 2 }}>
                {'-'}
              </Tag>
            );
          }
          return barcodes.map((barcode) => {
            return (
              <Tag key={barcode} color="blue" style={{ margin: 2 }}>
                {barcode}
              </Tag>
            );
          });
        },
      },
      {
        title: i18n.t(
          'pages.Operation.Transfer.create.TransferProductList.productTable.image',
        ),
        dataIndex: 'picURL',
        key: 'picURL',
        width: 70,
        render: (picURL) => {
          return (
            <Avatar
              className="grow"
              shape="square"
              src={picURL ? getTranslation.obj(picURL) : null}
            />
          );
        },
      },
      {
        title: i18n.t(
          'pages.Operation.Transfer.create.TransferProductList.productTable.fullName',
        ),
        dataIndex: 'fullName',
        key: 'fullName',
        width: 300,
        render: (fullName) => (fullName ? getTranslation.obj(fullName) : '-'),
        ...tableHelper.fieldSorter.getStringSortProps(
          `fullName.${getTranslation.local()}`,
        ),
      },
      {
        title: i18n.t(
          'pages.Operation.Transfer.create.TransferProductList.productTable.packagingInfo',
        ),
        dataIndex: 'packagingInfo',
        key: 'packagingInfo',
        width: 150,
        render: (packagingInfo) =>
          packagingInfo ? <this.PackageInfo data={packagingInfo} /> : '-',
      },
      {
        title: i18n.t(
          'pages.Operation.Transfer.create.TransferProductList.productTable.quantity',
        ),
        dataIndex: 'quantity',
        key: 'quantity',
        editable: true,
      },
    ];

    return columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          min: 0,
          disabled,
          handleCellSave: this.onProductCellChange,
        }),
      };
    });
  };

  prepareData = (props) => {
    const { items, selectedItems } = this.state;
    if (!items || !items.length) {
      return null;
    }
    return items
      .map((p) => {
        const product = p;
        const productQuantity = selectedItems.find(
          (item) => item.itemId === product.id,
        );
        product.quantity = (productQuantity && productQuantity.count) || 0;
        return product;
      })
      .filter((product) => {
        const { filter } = this.state;
        if (!filter) {
          return true;
        }
        return (
          getTranslation
            .obj(product.fullName)
            .toUpperCase()
            .includes(filter.toUpperCase()) ||
          product.id.includes(filter) ||
          (product.barcodes &&
            product.barcodes.length &&
            product.barcodes.includes(filter))
        );
      });
  };

  PackageInfo = (props) => {
    const { data } = props;
    const innerComponent = [];
    let i = 0;
    for (const info in data) {
      if (data.hasOwnProperty(info)) {
        if (info > 0) {
          innerComponent.push(
            <div key={i}>
              <span>
                {info == 1
                  ? i18n.t(
                      'pages.Operation.Transfer.create.TransferProductList.productTable.productPackagingTypes.1',
                    )
                  : info == 2
                  ? i18n.t(
                      'pages.Operation.Transfer.create.TransferProductList.productTable.productPackagingTypes.2',
                    )
                  : info == 3
                  ? i18n.t(
                      'pages.Operation.Transfer.create.TransferProductList.productTable.productPackagingTypes.3',
                    )
                  : info == 4
                  ? i18n.t(
                      'pages.Operation.Transfer.create.TransferProductList.productTable.productPackagingTypes.4',
                    )
                  : ''}
              </span>
              <span>{data[info].inboxQuantity}</span>
            </div>,
          );
        }
        // eslint-disable-next-line no-plusplus
        i++;
      }
    }
    return <div className="PackageInfo">{innerComponent}</div>;
  };

  onProductCellChange = (row) => {
    const { id, fullName, quantity } = row;
    const { selectedItems } = this.state;

    const uniqItems = uniqBy(selectedItems, 'itemId');

    const findedItem = uniqItems.find((item) => item.itemId === id);

    if (findedItem) {
      findedItem.count = quantity;
      uniqItems.map((item) => {
        if (item.itemId === findedItem.itemId) {
          return findedItem;
        }
        return item;
      });
    } else {
      uniqItems.push({
        itemId: id,
        name: getTranslation.obj(fullName),
        count: quantity,
      });
    }

    this.setState({
      selectedItems: [...uniqItems.filter((item) => item.count)],
    });
    setTimeout(() => {
      this.props.onChange(this.state.selectedItems);
      track(CreateTransferEvents.OPS_TRANSFER_PRODUCT_NUMBER_ENTERED, {
        items: this.state.selectedItems,
      });
    });
  };

  onChangeSearch = (event) => {
    this.setState({
      filter: event.target.value,
    });
  };

  clearState = () => {
    this.setState(this.stateFactory());
  };
}

const TransferProductListPanelTranslated = TransferProductListPanel;

export { TransferProductListPanelTranslated as TransferProductListPanel };
