import 'antd/dist/antd.css';
import './App.scss';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { DefaultLayout } from './layout';
import { Login, Page404, Page500 } from './pages';
import { authAction } from './actions';
import { Spinner } from './components';

class App extends Component {
  componentDidMount() {
    const path = window.location.pathname;
    if (!(path === '/login')) {
      this.props.initSession();
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path="/login" name="Login Page" component={Login} />
        <Route exact path="/404" name="Page 404" component={Page404} />
        <Route exact path="/500" name="Page 500" component={Page500} />
        {this.props.isLoginPending ? (
          <Spinner />
        ) : (
          <Route path="/" name="Home" component={DefaultLayout} />
        )}
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  tokenCode: state.auth.tokenCode,
  isLoginPending: state.auth.isLoginPending,
});

const mapDispatchToProps = (dispatch) => ({
  initSession: () => dispatch(authAction.checkSession()),
});

const AppConnected = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App),
);

export { AppConnected as App };
