import React, { Component } from 'react';
import { Select, Spin } from 'antd';

/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/
import { statuses, getTranslation, constants } from './../../../../../shared';
import i18n from './../../../../../i18n';
/*----------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------
------------------------------------------------------------------------------*/

class TransferTypeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
    this.list = this.getList();
  }

  getList = () => {
    const { transferIntegrationType } = statuses;
    const { INTEGRATION_TYPE } = constants;
    const list = [];
    for (const key in transferIntegrationType) {
      if (
        transferIntegrationType.hasOwnProperty(key) &&
        parseInt(key, 10) !== INTEGRATION_TYPE.REFUND &&
        parseInt(key, 10) !== INTEGRATION_TYPE.DISPOSAL &&
        parseInt(key, 10) !== INTEGRATION_TYPE.PRODUCT_SAMPLING &&
        parseInt(key, 10) !== INTEGRATION_TYPE.CONSUMABLE &&
        parseInt(key, 10) !== INTEGRATION_TYPE.BLOCKED_STOCK
      ) {
        const element = { key, value: i18n.t(transferIntegrationType[key]) };
        list.push(element);
      }
    }
    return list;
  };

  stateFactory = () => {
    return {
      selectedItem: null,
    };
  };

  componentDidMount() {}

  render() {
    return <this.SelectField />;
  }

  SelectField = () => {
    const { loading, disabled, placeholder } = this.props;
    return (
      <Select
        showSearch
        allowClear
        optionFilterProp="children"
        disabled={disabled}
        placeholder={placeholder}
        value={this.getValue()}
        filterOption={this.filterOption}
        onChange={this.onChange}
        onChangeForNonAggrement={this.onChangeForNonAggrement}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {this.getOptions()}
      </Select>
    );
  };

  getOptions = () => {
    const options = this.list.map((item) => {
      return (
        <Select.Option
          value={getTranslation.transferIntegrationType(item.key)}
          key={item.key}
          rawvalue={item}
        >
          {getTranslation.transferIntegrationType(item.key)}
        </Select.Option>
      );
    });
    return options;
  };

  getValue = () => {
    const { selectedItem } = this.state;
    if (!selectedItem) {
      return;
    }
    return this.list
      .filter((item) => item.key === selectedItem.key)
      .map((item) => {
        return getTranslation.obj(item.value);
      })[0];
  };

  filterOption = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  onChange = (value, option) => {
    try {
      const selectedItem = option.props.rawvalue;
      this.setState({
        selectedItem,
      });
    } catch (error) {
      this.setState({
        selectedItem: null,
      });
    }
    setTimeout(() => {
      this.props.onChange(this.state.selectedItem);
    });
  };

  onChangeForNonAggrement = () => {
    try {
      if (this.props.isNonAgreement) {
        const { VIRTUAL_DEPART } = constants.INTEGRATION_TYPE;
        const selectedItemForNonAgreement = {
          key: VIRTUAL_DEPART,
          value: statuses.transferIntegrationType[VIRTUAL_DEPART],
        };
        this.setState({
          selectedItem: selectedItemForNonAgreement,
        });
      } else {
        this.setState({
          selectedItem: null,
        });
      }
    } catch (error) {
      this.setState({
        selectedItem: null,
      });
    }
    setTimeout(() => {
      this.props.onChangeForNonAggrement(this.state.selectedItem);
    });
  };

  clearState = () => {
    this.setState(this.stateFactory());
  };
}

const TransferTypeSelectTranslated = TransferTypeSelect;

export { TransferTypeSelectTranslated as TransferTypeSelect };
