import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Row, Col } from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import {
  ConclusionTypeSelect,
  ProductMultiSelect,
  ReasonMultiSelect,
  SupplierSelect,
  WarehouseMultiSelect,
} from './filterFields';
/*----------------------------------------------------------------------------*/
import {
  supplierAction,
  warehouseAction,
  productAction,
  blockedStockAction,
} from './../../../../../actions';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class BlockedStockRuleFilter extends Component {
  settings = {
    main: {
      className: 'BlockedStockRuleFilter_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    return {
      supplier: null,
      products: [],
      warehouses: [],
      reasons: [],
      subReasons: [],
      conclusionType: null,
    };
  };

  componentDidMount() {
    this.loadFormData();
  }

  render() {
    return (
      <div {...this.settings.main}>
        <this.TableFilterForm />
      </div>
    );
  }

  TableFilterForm = () => {
    return (
      <Form>
        <Row>
          <Col span={12} style={{ padding: '0px 5px' }}>
            <this.SupplierField />
          </Col>
          <Col span={12} style={{ padding: '0px 5px' }}>
            <this.ProductField />
          </Col>
        </Row>
        <Row>
          <Col span={7} style={{ padding: '0px 5px' }}>
            <this.WarehouseField />
          </Col>
          <Col span={5} style={{ padding: '0px 5px' }}>
            <this.ReasonField />
          </Col>
          <Col span={7} style={{ padding: '0px 5px' }}>
            <this.SubReasonField />
          </Col>
          <Col span={5} style={{ padding: '0px 5px' }}>
            <this.ConclusionTypeField />
          </Col>
        </Row>

        <Row>
          <Col span={2} style={{ padding: '0px 5px' }}>
            <this.ClearFilterButton />
          </Col>
          <Col offset={20} span={2} style={{ padding: '0px 5px' }}>
            <this.FilterButton />
          </Col>
        </Row>
      </Form>
    );
  };

  SupplierField = (props) => {
    const { supplier } = this.state;
    const { supplierList } = this.props;
    return (
      <Form.Item>
        <SupplierSelect
          onChange={(supplier) => {
            this.setState({ supplier });
            if (supplier && supplier._id) {
              this.props.onGetProductList(supplier._id);
            } else {
              this.setState({ products: [] });
            }
          }}
          value={supplier}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.list.filter.supplier',
          )}
          list={supplierList}
        />
      </Form.Item>
    );
  };

  ProductField = () => {
    const { productList } = this.props;
    const { products, supplier } = this.state;
    const list = (productList && productList.data) || null;
    return (
      <Form.Item>
        <ProductMultiSelect
          list={list}
          disabled={!supplier}
          onChange={(products) => {
            this.setState({ products });
          }}
          value={products}
          loading={productList.loading}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.list.filter.product',
          )}
        />
      </Form.Item>
    );
  };

  WarehouseField = () => {
    const { warehouseList } = this.props;
    const { warehouses } = this.state;
    const list = (warehouseList && warehouseList.data) || null;
    return (
      <Form.Item>
        <WarehouseMultiSelect
          list={list}
          onChange={(warehouses) => {
            this.setState({ warehouses });
          }}
          value={warehouses}
          loading={warehouseList.loading}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.list.filter.warehouse',
          )}
        />
      </Form.Item>
    );
  };

  ReasonField = () => {
    const { reasonList } = this.props;
    const { reasons } = this.state;
    const list = (reasonList && reasonList.data) || null;
    return (
      <Form.Item>
        <ReasonMultiSelect
          list={list}
          onChange={(reasons) => {
            this.setState({ reasons });
          }}
          value={reasons}
          loading={reasonList.loading}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.list.filter.reason',
          )}
        />
      </Form.Item>
    );
  };

  SubReasonField = () => {
    const { reasons, subReasons } = this.state;
    const list = [];
    reasons.forEach((reason) => {
      if (reason && reason.subReasons) {
        list.push(...reason.subReasons);
      }
    });
    return (
      <Form.Item>
        <ReasonMultiSelect
          list={list}
          onChange={(subReasons) => {
            this.setState({ subReasons });
          }}
          value={subReasons}
          disabled={!list || !list.length}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.list.filter.subReason',
          )}
        />
      </Form.Item>
    );
  };

  ConclusionTypeField = () => {
    const { conclusionType } = this.state;
    return (
      <Form.Item>
        <ConclusionTypeSelect
          onChange={(conclusionType) => {
            this.setState({ conclusionType });
          }}
          value={conclusionType}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.list.filter.conclusionType',
          )}
        />
      </Form.Item>
    );
  };

  FilterButton = () => {
    return (
      <Form.Item>
        <Button
          type="primary"
          block
          onClick={() => {
            const filter = this.getTableFilter();
            this.filterChanged(filter);
          }}
        >
          {this.props.t(
            'pages.Operation.BlockedStock.decisionRule.list.filter.filter',
          )}
        </Button>
      </Form.Item>
    );
  };

  ClearFilterButton = () => {
    return (
      <Form.Item>
        <Button
          block
          onClick={() => {
            this.clearFilter();
          }}
        >
          {this.props.t(
            'pages.Operation.BlockedStock.decisionRule.list.filter.clean',
          )}
        </Button>
      </Form.Item>
    );
  };

  getTableFilter = () => {
    const {
      supplier,
      products,
      warehouses,
      reasons,
      subReasons,
      conclusionType,
    } = this.state;
    const payload = {};
    if (supplier && supplier._id) {
      payload.suppliers = [supplier._id];
    }
    if (products && products.length) {
      payload.products = products.map((p) => p.id);
    }
    if (warehouses && warehouses.length) {
      payload.warehouses = warehouses.map((w) => w._id);
    }
    if (reasons && reasons.length) {
      payload.reasons = reasons.map((r) => r.id);
    }
    if (subReasons && subReasons.length) {
      payload.subReasons = subReasons.map((sr) => sr.id);
    }
    if (conclusionType && conclusionType.key) {
      payload.conclusionTypes = [conclusionType.key];
    }
    return payload;
  };

  loadFormData = () => {
    this.props.onGetSupplierList();
    this.props.onGetWarehouseList();
    this.props.onGetReasonList();
  };

  filterChanged = (filter) => {
    this.props.onChange(filter);
  };

  clearFilter = () => {
    this.setState({ ...this.state, ...this.stateFactory() }, () => {
      this.props.onClear(this.getTableFilter());
    });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse.id,
  supplierList: state.supplier.list.data,
  warehouseList: state.warehouse.warehouseList,
  productList: state.product.productList,
  reasonList: state.blockedStock.reasonList,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSupplierList: () => {
    return dispatch(supplierAction.getSupplierList());
  },
  onGetWarehouseList: () => {
    return dispatch(warehouseAction.getWarehouseList());
  },
  onGetProductList: (id) => {
    return dispatch(
      productAction.filterProductList(
        {
          supplierIds: [id],
          fields: 'barcodes picURL fullName packagingInfo',
          includeDefaultFields: false,
        },
        false,
      ),
    );
  },
  onGetReasonList: () => {
    return dispatch(
      blockedStockAction.filterBlockedStockReasons({
        isVisibleByWarehouse: true,
      }),
    );
  },
  onCreateBlockedStockRule: (data) => {
    return dispatch(blockedStockAction.createBlockedStockRule(data));
  },
});

const BlockedStockRuleFilterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(BlockedStockRuleFilter));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { BlockedStockRuleFilterConnected as BlockedStockRuleFilter };
