import { QueryBuilder } from '../QueryBuilder';
import { createHttpService } from '../createHttpService';

const WAREHOUSE_ID = localStorage.getItem('warehouseId');

const httpService = createHttpService();

export const purchaseOrder = {
  getPurchaseOrderList: (query) => httpService.get(`/warehouse/${WAREHOUSE_ID}/purchase-orders?${QueryBuilder.getQueryStr(query)}`),
  getPurchaseOrderDetail: (purchaseOrderId) => httpService.get(`/warehouse/${WAREHOUSE_ID}/purchase-orders/${purchaseOrderId}`),
  createPurchaseOrderAcceptance: (purchaseOrderId, data) => httpService.post(`/warehouse/${WAREHOUSE_ID}/purchase-orders/${purchaseOrderId}/acceptance`, data),
  getOperationPurchaseOrderDetail: (purchaseOrderId) => httpService.get(`/purchase-orders/${purchaseOrderId}`),
  createPurchaseOrder: (data) => httpService.post('/purchase-orders', data),
  filterPurchaseOrderList: (query, data) => httpService.post(`/purchase-orders/filter?${QueryBuilder.getQueryStr(query)}`, data),
  createSelfPurchaseOrder: (data) => httpService.post('/purchase-orders/self', data),
  orderPurchaseOrder: (purchaseOrderId, data) => httpService.post(`/purchase-orders/${purchaseOrderId}/order`, data),
  getWarehousePurchaseOrderDetail: (warehousePurchaseOrderId) => httpService.get(`/warehouse-purchase-orders/${warehousePurchaseOrderId}`),
  filterWarehousePurchaseOrderList: (query, data) => httpService.post(`/warehouse-purchase-orders/filter?${QueryBuilder.getQueryStr(query)}`, data),
  approveWarehousePurchaseOrder: (warehousePurchaseOrderId) => httpService.post(`/warehouse-purchase-orders/${warehousePurchaseOrderId}/approve`),
  rejectWarehousePurchaseOrder: (warehousePurchaseOrderId, data) => httpService.post(`/warehouse-purchase-orders/${warehousePurchaseOrderId}/reject`, data),
  cancelPurchaseOrder: (purchaseOrderId) => httpService.delete(`/purchase-orders/${purchaseOrderId}/cancel`),
  cancelWarehousePurchaseOrder: (warehousePurchaseOrderId) => httpService.delete(`/warehouse-purchase-orders/${warehousePurchaseOrderId}/cancel`),
  updatePurchaseOrder: (purchaseOrderId, data) => httpService.put(`/purchase-orders/${purchaseOrderId}`, data),
  sendToWmsPurchaseOrder: (id) => httpService.post(`/purchase-orders/${id}/send-to-wms`),
  calculateItemProgress: (purchaseOrderId, data) => httpService.post(`/purchase-orders/${purchaseOrderId}/calculated-items-progress`, data),
  getCalculatedItemProgress: (purchaseOrderId) => httpService.get(`/purchase-orders/${purchaseOrderId}/calculated-items-progress`),
  getWarehousePurchaseOrderEWaybill: (warehousePurchaseOrderId) => httpService.get(`/warehouse-purchase-orders/${warehousePurchaseOrderId}/check-e-waybill`),
};
