import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer } from './../reducers';
import { redirectMiddleware } from './redirectMiddleware';
import { actionType } from './../actions';

const composeEnhancers = compose;

const logger = createLogger({
  predicate: (getState, action) => action.type !== actionType.timerTick,
  collapsed: (getState, action, logEntry) => !logEntry.error,
});

export const store = createStore(
  reducer,
  composeWithDevTools(
    composeEnhancers(applyMiddleware(thunk, logger, redirectMiddleware)),
  ),
);
