import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';

export const pickerAction = {
  getPickerList,
  getPickerBusyReasons,
  changeStatusOfPickerToBusy,
  changeStatusOfPickerToFree,
  getPickerDetail,
  getPickerJobDetail,
  pickerUpdated,
  getPickerFilteredList,
};

function getPickerList(warehouseId) {
  return (dispatch) => {
    dispatch({
      type: actionType.getPickerList_InProgress,
    });
    http
      .getPickerList(warehouseId)
      .then((response) => {
        const { pickers } = response.data.data;
        dispatch({
          type: actionType.getPickerList_Succeeded,
          payload: pickers,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getPickerList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getPickerList_Failed,
          payload: error,
        });
      });
  };
}

function getPickerBusyReasons(p, q) {
  return (dispatch) => {
    dispatch({
      type: actionType.getPickerBusyReasons_InProgress,
    });
    http
      .getPickerBusyReasons(p, q)
      .then((response) => {
        const { reasons } = response.data.data;
        dispatch({
          type: actionType.getPickerBusyReasons_Succeeded,
          payload: reasons,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getPickerBusyReasons_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getPickerBusyReasons_Failed,
          payload: error,
        });
      });
  };
}

function changeStatusOfPickerToBusy(p, q, b) {
  return (dispatch) => {
    dispatch({
      type: actionType.changeStatusOfPickerToBusy_InProgress,
    });
    http
      .changeStatusOfPickerToBusy(p, q, b)
      .then((response) => {
        dispatch({
          type: actionType.changeStatusOfPickerToBusy_Succeeded,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.changeStatusOfPickerToBusy_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.changeStatusOfPickerToBusy_Failed,
          payload: error,
        });
      });
  };
}

function changeStatusOfPickerToFree(p, q, b) {
  return (dispatch) => {
    dispatch({
      type: actionType.changeStatusOfPickerToFree_InProgress,
    });
    http
      .changeStatusOfPickerToFree(p, q, b)
      .then(({ data }) => {
        dispatch({
          type: actionType.changeStatusOfPickerToFree_Succeeded,
          payload: data.data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.changeStatusOfPickerToFree_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.changeStatusOfPickerToFree_Failed,
          payload: error,
        });
      });
  };
}

function getPickerDetail(pickerId) {
  return (dispatch) => {
    dispatch({
      type: actionType.getPickerDetail_InProgress,
    });
    http
      .getPickerDetail(pickerId)
      .then((response) => {
        const res = response.data.data.picker;
        dispatch({
          type: actionType.getPickerDetail_Succeeded,
          payload: res,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getPickerDetail_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getPickerDetail_Failed,
          payload: error,
        });
      });
  };
}

function getPickerJobDetail(pickerId) {
  return (dispatch) => {
    dispatch({
      type: actionType.getPickerJobDetail_InProgress,
    });
    http
      .getPickerJobDetail(pickerId)
      .then((response) => {
        const res = response.data.data;
        dispatch({
          type: actionType.getPickerJobDetail_Succeeded,
          payload: res,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getPickerJobDetail_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getPickerJobDetail_Failed,
          payload: error,
        });
      });
  };
}

function pickerUpdated(data) {
  const { picker } = data;
  return (dispatch) => {
    dispatch({
      type: actionType.pickerUpdated,
      payload: { picker },
    });
  };
}

function getPickerFilteredList(body) {
  return (dispatch) => {
    dispatch({
      type: actionType.getPickerFilteredList_InProgress,
    });
    http
      .getPickerFilteredList(body)
      .then((response) => {
        const { pickers } = response.data.data;
        const pickersObject = {};
        pickers.forEach(picker => pickersObject[picker.id] = picker);
        dispatch({
          type: actionType.getPickerFilteredList_Succeeded,
          payload: pickersObject,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getPickerFilteredList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getPickerFilteredList_Failed,
          payload: error,
        });
      });
  };
}
