/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Form, Button, Card, Dropdown, Icon, Menu, Switch } from 'antd';
/*----------------------------------------------------------------------------*/
import './BlockedStockRuleDetail.scss';
import {
  ConclusionTypeSelect,
  ProductMultiSelect,
  ReasonMultiSelect,
  SupplierSelect,
  WarehouseMultiSelect,
  SupplierAccountSelect,
} from './formFields';
/*----------------------------------------------------------------------------*/
import { Spinner } from './../../../../../components';
import {
  supplierAction,
  warehouseAction,
  productAction,
  blockedStockAction,
} from './../../../../../actions';
import { getTranslation } from './../../../../../shared';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class BlockedStockRuleDetail extends Component {
  settings = {
    main: {
      className: 'BlockedStockRuleDetail_main',
    },
    card: {
      title: this.props.t(
        'pages.Operation.BlockedStock.decisionRule.detail.title',
      ),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  formItemLayout = {
    style: {
      marginTop: 15,
      marginBottom: 15,
    },
    labelCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 3, offset: 0 },
    },
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 0 },
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    this.loadData();
  }

  stateFactory = () => {
    return {
      edit: false,
      supplier: null,
      supplierAccount: null,
      products: [],
      warehouses: [],
      reasons: [],
      subReasons: [],
      conclusionType: null,
      isActive: false,
    };
  };

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {loading ? <Spinner /> : <this.PageDetail />}
        </Card>
      </div>
    );
  }

  startEdit = () => {
    this.setState({ ...this.getDetailValues(), edit: true });
  };

  getDetailValues = () => {
    const {
      detail,
      supplierList,
      warehouseList,
      productList,
      reasonList,
    } = this.props;
    if (!detail || !productList || !productList.length) {
      return this.stateFactory();
    }
    const subReasonList = [];
    if (reasonList && reasonList.length) {
      reasonList.forEach((reason) => {
        if (reason && reason.subReasons) {
          subReasonList.push(...reason.subReasons);
        }
      });
    }

    return {
      supplier: supplierList.find((s) => s._id === detail.supplier.id),
      supplierAccount: detail.supplier.accounts[0],
      products: detail.products.map((product) => {
        return productList.find((p) => p.id === product.id);
      }),
      warehouses: detail.warehouses.map((warehouse) => {
        return warehouseList.find((p) => p.id === warehouse.id);
      }),
      reasons: detail.reasons.map((reason) => {
        return reasonList.find((p) => p.id === reason.id);
      }),
      subReasons: detail.subReasons.map((subReason) => {
        return subReasonList.find((p) => p.id === subReason.id);
      }),
      conclusionType: {
        key: detail.conclusionType,
        value: getTranslation.blockedStockConclusionType(detail.conclusionType),
      },
      isActive: detail.isActive,
    };
  };

  getValues = () => {
    const { edit } = this.state;
    if (edit) {
      return this.state;
    } else {
      return this.getDetailValues();
    }
  };

  CardExtra = (props) => {
    return (
      <Dropdown overlay={this.MenuAction}>
        <Button style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.t(
            'pages.Operation.BlockedStock.decisionRule.detail.button.actions',
          )}{' '}
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  MenuAction = () => {
    return (
      <Menu>
        <Menu.Item key="EditButton">
          <this.EditButton />
        </Menu.Item>
        <Menu.Item key="SaveButton">
          <this.SaveButton />
        </Menu.Item>
        <Menu.Item key="CancelButton">
          <this.CancelButton />
        </Menu.Item>
      </Menu>
    );
  };

  EditButton = (props) => {
    const { edit } = this.state;
    return (
      <Button
        block
        onClick={() => {
          this.startEdit();
        }}
        disabled={edit}
      >
        {this.props.t(
          'pages.Operation.BlockedStock.decisionRule.detail.button.edit',
        )}
      </Button>
    );
  };

  SaveButton = (props) => {
    return (
      <Button
        type="danger"
        block
        onClick={() => {
          this.updateDetail();
        }}
        disabled={this.isSaveButtonDisabled()}
      >
        {this.props.t(
          'pages.Operation.BlockedStock.decisionRule.detail.button.save',
        )}
      </Button>
    );
  };

  isSaveButtonDisabled = () => {
    const { edit } = this.state;
    const { supplier } = this.getValues();
    if (!edit || !supplier) {
      return true;
    }
    return false;
  };

  updateDetail = () => {
    const detail = this.getPayload();
    const { id, onUpdateBlockedStockRule } = this.props;
    onUpdateBlockedStockRule(id, detail);
    this.setState({ edit: false });
  };

  getPayload = () => {
    const detail = this.getValues();
    return {
      blockedStockRule: {
        supplier: detail.supplier.id || detail.supplier._id,
        accountCode: detail.supplierAccount && detail.supplierAccount.code,
        products: detail.products.map((p) => p.id),
        warehouses: detail.warehouses.map((p) => p.id),
        reasons: detail.reasons.map((p) => p.id),
        subReasons: detail.subReasons.map((p) => p.id),
        isActive: detail.isActive,
        conclusionType: detail.conclusionType && detail.conclusionType.key,
      },
    };
  };

  CancelButton = (props) => {
    const { detail, onGetProductList } = this.props;
    const { edit } = this.state;
    return (
      <Button
        block
        onClick={() => {
          onGetProductList(detail.supplier.id);
          this.setState({ ...this.getDetailValues(), edit: false });
        }}
        disabled={!edit}
      >
        {this.props.t(
          'pages.Operation.BlockedStock.decisionRule.detail.button.cancel',
        )}
      </Button>
    );
  };

  PageDetail = (props) => {
    return (
      <div>
        <this.PageForm />
      </div>
    );
  };

  PageForm = () => {
    return (
      <Form>
        <this.SupplierField />
        <this.SupplierAccountField />
        <this.ProductField />
        <this.WarehouseField />
        <this.ReasonField />
        <this.SubReasonField />
        <this.ConclusionTypeField />
        <this.IsActiveControlField />
      </Form>
    );
  };

  SupplierField = (props) => {
    const { supplierList } = this.props;
    const { supplier, edit } = this.getValues();
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.decisionRule.detail.form.supplier',
        )}
      >
        <SupplierSelect
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.detail.form.supplier',
          )}
          list={supplierList}
          value={supplier}
          disabled={!edit}
          onChange={(supplier) => {
            this.setState({ supplier });
            this.setState({ products: [] });
            this.setState({ supplierAccount: null });
            if (supplier && supplier._id) {
              this.props.onGetProductList(supplier._id);
            }
          }}
        />
      </Form.Item>
    );
  };

  SupplierAccountField = (props) => {
    const { supplier, supplierAccount, edit } = this.getValues();
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.decisionRule.detail.form.account',
        )}
      >
        <SupplierAccountSelect
          onChange={(supplierAccount) => {
            this.setState({ supplierAccount });
          }}
          disabled={!edit}
          value={supplierAccount}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.detail.form.account',
          )}
          list={supplier && supplier.accounts}
        />
      </Form.Item>
    );
  };

  ProductField = () => {
    const { productList } = this.props;
    const { products, edit } = this.getValues();
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.decisionRule.detail.form.product',
        )}
      >
        <ProductMultiSelect
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.detail.form.product',
          )}
          list={productList}
          value={products || []}
          loading={!productList}
          disabled={!edit}
          onChange={(products) => {
            this.setState({ products });
          }}
        />
      </Form.Item>
    );
  };

  WarehouseField = () => {
    const { warehouseList } = this.props;
    const { warehouses, edit } = this.getValues();
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.decisionRule.detail.form.warehouse',
        )}
      >
        <WarehouseMultiSelect
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.detail.form.warehouse',
          )}
          list={warehouseList}
          value={warehouses}
          loading={!warehouseList}
          disabled={!edit}
          onChange={(warehouses) => {
            this.setState({ warehouses });
          }}
        />
      </Form.Item>
    );
  };

  ReasonField = () => {
    const { reasonList } = this.props;
    const { reasons, edit } = this.getValues();
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.decisionRule.detail.form.reason',
        )}
      >
        <ReasonMultiSelect
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.detail.form.reason',
          )}
          list={reasonList}
          value={reasons}
          loading={!reasonList}
          disabled={!edit}
          onChange={(reasons) => {
            this.setState({ reasons });
            this.setState({ subReasons: [] });
          }}
        />
      </Form.Item>
    );
  };

  SubReasonField = () => {
    const { subReasons, reasons, edit } = this.getValues();

    const subReasonList = [];
    if (reasons && reasons.length) {
      reasons.forEach((reason) => {
        if (reason && reason.subReasons) {
          subReasonList.push(...reason.subReasons);
        }
      });
    }

    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.decisionRule.detail.form.subReason',
        )}
      >
        <ReasonMultiSelect
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.detail.form.subReason',
          )}
          list={subReasonList}
          value={subReasons}
          disabled={!edit}
          onChange={(subReasons) => {
            this.setState({ subReasons });
          }}
        />
      </Form.Item>
    );
  };

  ConclusionTypeField = () => {
    const { conclusionType, edit } = this.getValues();
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.decisionRule.detail.form.conclusionType',
        )}
      >
        <ConclusionTypeSelect
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.detail.form.conclusionType',
          )}
          value={conclusionType}
          disabled={!edit}
          onChange={(conclusionType) => {
            this.setState({ conclusionType });
          }}
        />
      </Form.Item>
    );
  };

  IsActiveControlField = (props) => {
    const { isActive, edit } = this.getValues();
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.decisionRule.detail.form.active',
        )}
      >
        <Switch
          disabled={!edit}
          checked={isActive}
          checkedChildren={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.detail.form.active',
          )}
          unCheckedChildren={this.props.t(
            'pages.Operation.BlockedStock.decisionRule.detail.form.inactive',
          )}
          onChange={(isActive) => {
            this.setState({ isActive });
          }}
        />
      </Form.Item>
    );
  };

  loadData = () => {
    this.getDetail();
    this.props.onGetSupplierList();
    this.props.onGetWarehouseList();
    this.props.onGetReasonList();
  };

  getDetail = () => {
    const { id, onGetBlockedStockRuleDetail } = this.props;
    onGetBlockedStockRuleDetail(id);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading:
    state.blockedStock.blockedStockRuleDetail.loading ||
    state.supplier.list.loading ||
    state.warehouse.warehouseList.loading ||
    state.blockedStock.reasonList.loading,
  detail: state.blockedStock.blockedStockRuleDetail.data,
  supplierList: state.supplier.list.data,
  warehouseList: state.warehouse.warehouseList.data,
  productList: state.product.productList.data,
  reasonList: state.blockedStock.reasonList.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetBlockedStockRuleDetail: (id) => {
    return dispatch(blockedStockAction.getBlockedStockRuleDetail(id));
  },
  onGetSupplierList: () => {
    return dispatch(supplierAction.getSupplierList());
  },
  onGetWarehouseList: () => {
    return dispatch(warehouseAction.getWarehouseList());
  },
  onGetProductList: (id) => {
    return dispatch(
      productAction.filterProductList(
        {
          supplierIds: [id],
          fields: 'barcodes picURL fullName packagingInfo',
          includeDefaultFields: false,
        },
        false,
      ),
    );
  },
  onGetReasonList: () => {
    return dispatch(
      blockedStockAction.filterBlockedStockReasons({
        isVisibleByWarehouse: true,
      }),
    );
  },
  onUpdateBlockedStockRule: (blockedStockRuleId, data) => {
    return dispatch(
      blockedStockAction.updateBlockedStockRule(blockedStockRuleId, data),
    );
  },
});

const BlockedStockRuleDetailConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(BlockedStockRuleDetail));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { BlockedStockRuleDetailConnected as BlockedStockRuleDetail };
