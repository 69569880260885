import React from 'react';
import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo, ExportToExcel, CopyToClipboard } from './../components';
import { constants } from './../shared';
import i18n from '../i18n';

export const inventoryAction = {
  getInventoryCheckList,
  getInventoryCheckDetail,
  createInventoryCheck,
  bulkCreateInventoryCheck,
  createInventoryCheckInProgress,
  setInventoryCheckReady,
  deleteInventoryCheck,
  terminateInventoryCheck,
  getInventoryCheckTaskDetail,
  exportToXlsxInventoryCheck,
  bulkApproveInventoryCheck,
  bulkTerminateInventoryCheck,
  bulkIncludeInventoryChecksToSTS,
  bulkRemoveInventoryChecksFromSTS,
  includeToSTS,
  removeFromSTS,
  getWarehouseStatusControl,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getInventoryCheckList(config, query) {
  return (dispatch) => {
    dispatch({
      type: actionType.getInventoryCheckList_InProgress,
    });
    http
      .getInventoryCheckList(config, query)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getInventoryCheckList_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getInventoryCheckList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getInventoryCheckList_Failed,
          payload: error,
        });
      });
  };
}

function getInventoryCheckDetail(inventoryCheckId) {
  return (dispatch) => {
    dispatch({
      type: actionType.getInventoryCheckDetail_InProgress,
    });
    http
      .getInventoryCheckDetail(inventoryCheckId)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getInventoryCheckDetail_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getInventoryCheckDetail_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getInventoryCheckDetail_Failed,
          payload: error,
        });
      });
  };
}

function getInventoryCheckTaskDetail({ inventoryCheckId, jobId, jobTaskId }) {
  return (dispatch) => {
    dispatch({
      type: actionType.getInventoryCheckTaskDetail_InProgress,
    });
    http
      .getInventoryCheckTaskDetail({ inventoryCheckId, jobId, jobTaskId })
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getInventoryCheckTaskDetail_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.getInventoryCheckTaskDetail_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getInventoryCheckTaskDetail_Failed,
          payload: error,
        });
      });
  };
}

function createInventoryCheckInProgress() {
  return (dispatch) => {
    dispatch({
      type: actionType.createInventoryCheck_InProgress,
    });
  };
}

function createInventoryCheck(data) {
  return http.createInventoryCheck(data);
}

function bulkCreateInventoryCheck(data) {
  return (dispatch) => {
    dispatch({
      type: actionType.createInventoryCheck_InProgress,
    });

    if (data && data.length) {
      return createInventoryCheckFromArray(data)
        .then((response) => {
          notification.success({
            duration: 4,
            message: ErrorInfo.message(
              actionType.createInventoryCheck_Succeeded,
            ),
          });
          dispatch({
            type: actionType.createInventoryCheck_Succeeded,
            payload: response,
          });
        })
        .catch((error) => {
          notification.error({
            duration: 4,
            message: ErrorInfo.message(actionType.createInventoryCheck_Failed),
            description: ErrorInfo.description(error),
          });
          dispatch({
            type: actionType.createInventoryCheck_Failed,
            payload: error,
          });
          throw error;
        });
    }

    return createInventoryCheckOneByOne(data)
      .then((response) => {
        let description;
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.duplicateProductIds &&
          response.data.data.duplicateProductIds.length
        ) {
          description = (
            <>
              <div>{i18n.t('inventoryCheckDuplicate')}</div>
              {response.data.data.duplicateProductIds.map((id) => (
                <div>
                  <CopyToClipboard message={id} />
                </div>
              ))}
            </>
          );
        }
        notification.success({
          duration: 12,
          message: ErrorInfo.message(actionType.createInventoryCheck_Succeeded),
          description,
        });
        dispatch({
          type: actionType.createInventoryCheck_Succeeded,
          payload: response,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.createInventoryCheck_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.createInventoryCheck_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function createInventoryCheckFromArray(data, index = 0) {
  if (index === data.length - 1) {
    return http
      .createInventoryCheck({ ...data[index] })
      .then((response) => {
        console.log('createInventoryCheckFromArray', response);
      })
      .catch((error) => {
        console.log('createInventoryCheckFromArray error', error);
        throw error;
      });
  }
  if (index < data.length - 1) {
    return http
      .createInventoryCheck({ ...data[index] })
      .then((response) => {
        console.log('next', response);
        return createInventoryCheckFromArray(data, index + 1);
      })
      .catch((error) => {
        console.log('next error', error);
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.createInventoryCheck_Failed),
          description: ErrorInfo.description(error),
        });
        return createInventoryCheckFromArray(data, index + 1);
      });
  }
}

function createInventoryCheckOneByOne(data, index = 0) {
  if (index === data.warehouseId.length - 1) {
    return http
      .createInventoryCheck({ ...data, warehouseId: data.warehouseId[index] })
      .then((response) => {
        console.log('createInventoryCheckOneByOne', response);
        return response;
      })
      .catch((error) => {
        console.log('createInventoryCheckOneByOne error', error);
        throw error;
      });
  }
  if (index < data.warehouseId.length - 1) {
    return http
      .createInventoryCheck({ ...data, warehouseId: data.warehouseId[index] })
      .then((response) => {
        console.log('next', response);
        return createInventoryCheckOneByOne(data, index + 1);
      })
      .catch((error) => {
        console.log('next error', error);
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.createInventoryCheck_Failed),
          description: ErrorInfo.description(error),
        });
        return createInventoryCheckOneByOne(data, index + 1);
      });
  }
}

function exportToXlsxInventoryCheck({ fileName, data }) {
  return (dispatch) => {
    dispatch({ type: actionType.exportToXlsx_InProgress });
    if (!data) {
      return dispatch({
        type: actionType.exportToXlsx_Failed,
      });
    }
    ExportToExcel.fileSave({
      fileName,
      data,
    });
    dispatch({
      type: actionType.exportToXlsx_Succeeded,
    });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function setInventoryCheckReady(inventoryCheckId) {
  return (dispatch) => {
    dispatch({
      type: actionType.setInventoryCheckReady_InProgress,
    });
    return http
      .setInventoryCheckReady(inventoryCheckId)
      .then((response) => {
        const { data } = response.data;
        if (data.scope === constants.INVENTORY_CHECK_SCOPE.GENERAL) {
          notification.warning({
            duration: 4,
            message: i18n.t('shared.messages.inventoryCheckGeneralApprove'),
          });
        } else {
          notification.success({
            duration: 4,
            message: ErrorInfo.message(
              actionType.setInventoryCheckReady_Succeeded,
            ),
          });
        }
        dispatch({
          type: actionType.setInventoryCheckReady_Succeeded,
          payload: data,
        });
        dispatch(getInventoryCheckDetail(inventoryCheckId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.setInventoryCheckReady_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.setInventoryCheckReady_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function deleteInventoryCheck(inventoryCheckId) {
  return (dispatch) => {
    dispatch({
      type: actionType.deleteInventoryCheck_InProgress,
    });
    return http
      .deleteInventoryCheck(inventoryCheckId)
      .then((response) => {
        const { data } = response.data;
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.deleteInventoryCheck_Succeeded),
        });
        dispatch({
          type: actionType.deleteInventoryCheck_Succeeded,
          payload: data,
        });
        dispatch(getInventoryCheckDetail(inventoryCheckId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.deleteInventoryCheck_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.deleteInventoryCheck_Failed,
          payload: error,
        });
        throw error;
      });
  };
}
function includeToSTS(requestData) {
  const { inventoryCheckIds } = requestData;
  return (dispatch) => {
    dispatch({
      type: actionType.includeToSTS_InProgress,
    });
    return http
      .includeToSTS(requestData)
      .then((response) => {
        const { data } = response.data;

        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.includeToSTS_Succeeded),
        });
        dispatch({
          type: actionType.includeToSTS_Succeeded,
          payload: data,
        });
        dispatch(getInventoryCheckDetail(inventoryCheckIds[0]));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.includeToSTS_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.includeToSTS_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function removeFromSTS(requestData) {
  const { inventoryCheckIds } = requestData;
  return (dispatch) => {
    dispatch({
      type: actionType.removeFromSTS_InProgress,
    });
    return http
      .removeFromSTS(requestData)
      .then((response) => {
        const { data } = response.data;
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.removeFromSTS_Succeeded),
        });
        dispatch({
          type: actionType.removeFromSTS_Succeeded,
          payload: data,
        });
        dispatch(getInventoryCheckDetail(inventoryCheckIds[0]));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.removeFromSTS_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.removeFromSTS_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function terminateInventoryCheck(inventoryCheckId) {
  return (dispatch) => {
    dispatch({
      type: actionType.terminateInventoryCheck_InProgress,
    });
    return http
      .terminateInventoryCheck(inventoryCheckId)
      .then((response) => {
        const { data } = response.data;
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.terminateInventoryCheck_Succeeded,
          ),
        });
        dispatch({
          type: actionType.terminateInventoryCheck_Succeeded,
          payload: data,
        });
        dispatch(getInventoryCheckDetail(inventoryCheckId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.terminateInventoryCheck_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.terminateInventoryCheck_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function bulkApproveInventoryCheck({ data, errorMessage }) {
  const { InventoryCheckIds, inventoryCheckList } = data;
  return (dispatch) => {
    dispatch({
      type: actionType.bulkApproveInventoryCheck_InProgress,
    });
    return http
      .bulkApproveInventoryCheck({ inventoryCheckIds: InventoryCheckIds })
      .then(() => {
        const selectedInventoryCheckList = inventoryCheckList.filter(
          (inventoryCheck) => InventoryCheckIds.includes(inventoryCheck.id),
        );
        const generalInventoryChecks = selectedInventoryCheckList.filter(
          (inventoryCheck) =>
            inventoryCheck.scope === constants.INVENTORY_CHECK_SCOPE.GENERAL,
        );
        if (generalInventoryChecks.length === InventoryCheckIds.length) {
          notification.warning({
            duration: 4,
            message: i18n.t('shared.messages.inventoryCheckBulkGeneralApprove'),
          });
        } else if (generalInventoryChecks.length) {
          notification.warning({
            duration: 4,
            message: i18n.t(
              'shared.messages.inventoryCheckBulkGeneralAndPartialApprove',
            ),
          });
        } else {
          notification.success({
            duration: 4,
            message: ErrorInfo.message(
              actionType.bulkApproveInventoryCheck_Succeeded,
            ),
          });
        }
        dispatch({
          type: actionType.bulkApproveInventoryCheck_Succeeded,
          payload: { data: { data: {} } },
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.bulkApproveInventoryCheck_Failed,
          ),
          description: errorMessage,
        });
        dispatch({
          type: actionType.bulkApproveInventoryCheck_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function bulkTerminateInventoryCheck({ data, errorMessage }) {
  const InventoryCheckIds = data;
  return (dispatch) => {
    dispatch({
      type: actionType.bulkTerminateInventoryCheck_InProgress,
    });
    return http
      .bulkTerminateInventoryCheck({ inventoryCheckIds: InventoryCheckIds })
      .then(() => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.bulkTerminateInventoryCheck_Succeeded,
          ),
        });
        dispatch({
          type: actionType.bulkTerminateInventoryCheck_Succeeded,
          payload: { data: { data: {} } },
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.bulkTerminateInventoryCheck_Failed,
          ),
          description: errorMessage,
        });
        dispatch({
          type: actionType.bulkTerminateInventoryCheck_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function bulkIncludeInventoryChecksToSTS({ data }) {
  return (dispatch) => {
    dispatch({
      type: actionType.bulkIncludeInventoryChecksToSTS_InProgress,
    });
    return http
      .bulkIncludeInventoryChecksToSTS(data)
      .then(() => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.bulkIncludeInventoryChecksToSTS_Succeeded,
          ),
        });
        dispatch({
          type: actionType.bulkIncludeInventoryChecksToSTS_Succeeded,
          payload: { data: { data: {} } },
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.bulkIncludeInventoryChecksToSTS_Failed,
          ),
        });
        dispatch({
          type: actionType.bulkIncludeInventoryChecksToSTS_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function bulkRemoveInventoryChecksFromSTS({ data }) {
  return (dispatch) => {
    dispatch({
      type: actionType.bulkRemoveInventoryChecksFromSTS_InProgress,
    });
    return http
      .bulkRemoveInventoryChecksFromSTS(data)
      .then(() => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.bulkRemoveInventoryChecksFromSTS_Succeeded,
          ),
        });
        dispatch({
          type: actionType.bulkRemoveInventoryChecksFromSTS_Succeeded,
          payload: { data: { data: {} } },
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.bulkRemoveInventoryChecksFromSTS_Failed,
          ),
        });
        dispatch({
          type: actionType.bulkRemoveInventoryChecksFromSTS_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function getWarehouseStatusControl(id) {
  return (dispatch) => {
    dispatch({
      type: actionType.getWarehouseStatusControl_InProgress,
    });
    return http
      .getWarehouseStatusControl(id)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getWarehouseStatusControl_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.getWarehouseStatusControl_Failed,
          ),
        });
        dispatch({
          type: actionType.getWarehouseStatusControl_Failed,
          payload: error,
        });
        throw error;
      });
  };
}
