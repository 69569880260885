export const CreateTransferEvents = {
  OPS_TRANSFER_TYPE_SELECTED: "OPS_TRANSFER_TYPE_SELECTED",
  OPS_TRANSFER_FROM_WAREHOUSE_SELECTED: "OPS_TRANSFER_FROM_WAREHOUSE_SELECTED",
  OPS_TRANSFER_TARGET_WAREHOUSE_SELECTED: "OPS_TRANSFER_TARGET_WAREHOUSE_SELECTED",
  OPS_TRANSFER_PRODUCT_NUMBER_ENTERED: "OPS_TRANSFER_PRODUCT_NUMBER_ENTERED",
  OPS_TRANSFER_IMPORT_CSV_CLICKED: "OPS_TRANSFER_IMPORT_CSV_CLICKED",
  OPS_TRANSFER_IMPORT_CSV_SUCCESS: "OPS_TRANSFER_IMPORT_CSV_SUCCESS",
  OPS_TRANSFER_CLEAR: "OPS_TRANSFER_CLEAR",
  OPS_TRANSFER_CREATE_NEW: "OPS_TRANSFER_CREATE_NEW",
};
