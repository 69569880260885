import React, { Component } from 'react';
import { Input, Card, Table, Avatar, Tag, notification } from 'antd';
import { uniqBy, groupBy } from 'lodash';

/*----------------------------------------------------------------------------*/
import './OutgoingTransferProductList.scss';
import moment from 'moment';
import uniqid from 'uniqid';
/*----------------------------------------------------------------------------*/
import {
  Spinner,
  EditableCell,
  EditableFormRow,
} from '../../../../../components';
import { tableHelper, getDateTimeFormatString } from '../../../../../shared';
import i18n from '../../../../../i18n';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class OutgoingTransferProductList extends Component {
  settings = {
    main: {
      className: 'OutgoingTransferProductList_main',
    },
    card: {
      title: i18n.t(
        'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.title',
      ),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => ({
    filter: '',
    items: [],
    selectedItems: [],
    selectedLocationItems: [],
    selectedProducts: [],
    totalProductCount: {},
  });

  static getDerivedStateFromProps(props, state) {
    if (props.productList) {
      return {
        items: props.productList,
      };
    }
    return null;
  }

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => (
    <Input.Search
      className="product-filter-input"
      onChange={this.onChangeSearch}
      placeholder={i18n.t(
        'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.extra.filter',
      )}
    />
  );

  PageTable = (props) => (
    <Table
      size="small"
      scroll={{ y: 500 }}
      columns={this.prepareColumns(this.props)}
      dataSource={this.prepareData(this.props)}
      rowKey={(record) => record.id}
      expandedRowRender={this.expandedRowRender}
      components={this.components}
      loading={{ indicator: <Spinner />, spinning: this.props.loading }}
      pagination={{
        defaultPageSize: 20,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
      }}
    />
  );

  expandedRowRender = (record, index, indent, expanded) => (
    <Table
      size="small"
      columns={this.prepareExtendedColumns(record)}
      dataSource={this.prepareExtendedData(record)}
      components={this.components}
      rowKey={(record) => record.id + uniqid()}
      pagination={false}
    />
  );

  prepareColumns = () => {
    const columns = [
      {
        title: i18n.t(
          'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.barcode',
        ),
        dataIndex: 'barcodes',
        key: `barcodes${uniqid()}`,
        width: 120,
        render: (barcodes) => (
          <Tag color="blue" style={{ margin: 2 }}>
            {barcodes[0]}
          </Tag>
        ),
      },
      {
        title: i18n.t(
          'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.image',
        ),
        dataIndex: 'picURL',
        key: `picURL${uniqid()}`,
        width: 70,
        render: (picURL) => (
          <Avatar className="grow" shape="square" src={picURL} />
        ),
      },
      {
        title: i18n.t(
          'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.fullName',
        ),
        dataIndex: 'name',
        key: `name${uniqid()}`,
        width: 500,
        render: (name) => name,
      },
      {
        title: i18n.t(
          'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.quantity',
        ),
        dataIndex: 'quantity',
        key: `quantity${uniqid()}`,
        width: 50,
        render: (quantity) => (
          <Tag
            color={quantity ? 'geekblue' : null}
            style={{ margin: 2, fontSize: 13, fontWeight: 'bold' }}
          >
            {quantity || 0}
          </Tag>
        ),
        editable: false,
      },
    ];

    return columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          min: 0,
          //          disabled: disabled,
          handleCellSave: this.onProductRowChange,
        }),
      };
    });
  };

  prepareData = (props) => {
    const { items, selectedLocationItems } = this.state;
    if (!items || !items.length) {
      return null;
    }
    return items
      .map((p) => {
        const product = p;
        let totalCount = 0;
        product.locations.map((pl) => {
          const foundItem = selectedLocationItems.find(
            (l) => l.productLocationId === pl.productLocationId,
          );
          if (
            foundItem &&
            pl.productLocationId === foundItem.productLocationId
          ) {
            // eslint-disable-next-line no-param-reassign
            pl.editedCount = foundItem.editedCount;
            totalCount += foundItem.editedCount;
          }

          return pl;
        });

        product.quantity = totalCount;

        return product;
      })
      .filter((product) => {
        const { filter } = this.state;
        if (!filter) {
          return true;
        }
        return product.name.toUpperCase().includes(filter.toUpperCase());
      });
  };

  prepareExtendedData = (record) => record.locations;

  prepareExtendedColumns = (record) => {
    const columns = [
      {
        title: i18n.t(
          'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.isActive.title',
        ),
        dataIndex: 'isActive',
        key: `isActive${uniqid()}`,
        render: (isActive) => (
          <Tag color={isActive ? 'green' : 'red'} style={{ margin: 2 }}>
            {isActive
              ? i18n
                .t(
                  'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.isActive.yes',
                )
                .toUpperCase()
              : i18n
                .t(
                  'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.isActive.no',
                )
                .toUpperCase()}
          </Tag>
        ),
      },
      {
        title: i18n.t(
          'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.isLocked.title',
        ),
        dataIndex: 'isLocked',
        key: `isLocked${uniqid()}`,
        render: (isLocked) => (
          <Tag color={isLocked ? 'green' : 'red'} style={{ margin: 2 }}>
            {isLocked
              ? i18n
                .t(
                  'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.isLocked.yes',
                )
                .toUpperCase()
              : i18n
                .t(
                  'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.isLocked.no',
                )
                .toUpperCase()}
          </Tag>
        ),
      },
      {
        title: i18n.t(
          'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.barcode',
        ),
        dataIndex: 'barcode',
        key: `barcode${uniqid()}`,
        render: (barcode) => (
          <Tag color="red" style={{ margin: 2 }}>
            {barcode.toUpperCase()}
          </Tag>
        ),
      },
      {
        title: i18n.t(
          'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.locationQuantity',
        ),
        dataIndex: 'quantity',
        key: `quantity${uniqid()}`,
        render: (quantity) => quantity,
      },
      {
        title: i18n.t(
          'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.expiryDate',
        ),
        dataIndex: 'expiry',
        key: `expiry${uniqid()}`,
        ...tableHelper.fieldSorter.getDateSortProps('expiry'),
        render: (expiry) =>
          expiry
            ? moment(new Date(expiry))
                .local()
                .format(getDateTimeFormatString())
            : '-',
      },
      {
        title: i18n.t(
          'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.quantityToBeSent',
        ),
        dataIndex: 'editedCount',
        key: 'editedCount',
        width: 100,
        editable: true,
        render: (quantity) => (
          <Tag
            color={quantity ? 'magenta' : null}
            style={{ margin: 2, fontSize: 13, fontWeight: 'bold' }}
          >
            {quantity || 0}
          </Tag>
        ),
      },
    ];

    return columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          min: 0,
          disabled: this.props.disabled,
          handleCellSave: this.onProductCellChange,
        }),
      };
    });
  };

  onProductCellChange = (row) => {
    const {
      productId,
      productBarcode,
      productLocationId,
      barcode,
      expiry,
      editedCount,
      quantity,
    } = row;

    let { selectedLocationItems } = this.state;

    if (editedCount > quantity) {
      notification.error({
        duration: 3,
        message: i18n.t(
          'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.notification.invalidCountEntered',
        ),
        description: i18n.t(
          'pages.Stock.OutgoingTransfer.create.OutgoingTransferProductList.productTable.notification.invalidCount',
        ),
      });
    } else {
      const uniqItems = uniqBy(selectedLocationItems, 'productLocationId');

      const findedItem = uniqItems.find(
        (item) => item.productLocationId === productLocationId,
      );

      if (findedItem) {
        findedItem.editedCount = editedCount;
        uniqItems.map((item) => {
          if (item.productLocationId === findedItem.productLocationId) {
            return findedItem;
          }
          return item;
        });
      } else {
        uniqItems.push({
          productLocationId,
          expiry,
          editedCount,
          locationBarcode: barcode,
          productId,
          productBarcode,
        });
      }

      selectedLocationItems = [...uniqItems.filter((item) => item.editedCount)];
      const selectedProductWithLocation = groupBy(
        selectedLocationItems,
        'productId',
      );

      this.setState({
        selectedLocationItems,
      });

      setTimeout(() => {
        this.props.onChange(
          this.state.selectedLocationItems,
          selectedProductWithLocation,
        );
      });
    }
  };

  onChangeSearch = (event) => {
    this.setState({
      filter: event.target.value,
    });
  };

  clearState = () => {
    this.setState(this.stateFactory());
  };
}

const OutgoingTransferProductListTranslated = OutgoingTransferProductList;

export { OutgoingTransferProductListTranslated as OutgoingTransferProductList };
