/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { Tag, Avatar, Table, Popconfirm } from 'antd';
import { uniqBy } from 'lodash';
import { withNamespaces } from 'react-i18next';
import { TransferDetailLogic } from './TransferDetailLogic';
/*----------------------------------------------------------------------------*/
import './TransferDetail.scss';
/*----------------------------------------------------------------------------*/
import { EditableCell, EditableFormRow, Spinner } from '../../../../components';
import { getTranslation } from '../../../../shared';
/*------------------------------------------------------------------------------
------------------------------------------------------------------------------*/

class TransferTable extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => ({
    transferItems: [],
  });

  render() {
    return <this.Table />;
  }

  components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  };

  // eslint-disable-next-line func-names
  populateCounts = function (transfer) {
    const items = transfer.items.map((item) => {
      const fromItem = transfer.fromWarehouse.items.find((fi) => {
        const itemId =
          fi.item && fi.item.id ? fi.item.id.toString() : fi.item.toString();
        return itemId === item.item.id.toString();
      });
      const toItem = transfer.toWarehouse.items.find((ti) => {
        const itemId =
          ti.item && ti.item.id ? ti.item.id.toString() : ti.item.toString();
        return itemId === item.item.id.toString();
      });
      item.fromCount = fromItem ? fromItem.count : 0;
      item.fromCountAcceptance = fromItem ? fromItem.acceptanceCount : 0;
      item.toCount = toItem ? toItem.count : 0;
      item.toCountAcceptance = toItem ? toItem.acceptanceCount : 0;
      return item;
    });
    return items;
  };

  Table = (props) => {
    const { transfer } = this.props;
    if (
      !transfer ||
      !transfer.items ||
      !transfer.items.length ||
      !transfer.fromWarehouse ||
      !transfer.toWarehouse ||
      !transfer.fromWarehouse.items ||
      !transfer.toWarehouse.items
    ) {
      return null;
    }
    transfer.items = this.populateCounts(transfer);
    const { items: products = [] } = transfer;
    return (
      <Table
        size="small"
        columns={this.prepareColumns(products)}
        dataSource={this.prepareData(products)}
        rowKey={(record) => record.item.id}
        components={this.components}
        loading={{ indicator: <Spinner />, spinning: this.props.loading }}
        rowClassName={(record, index) =>
          (
            TransferDetailLogic.isTransferStatusCreated(transfer.status) &&
            record.count > record.fromCount
          ) || (
            record.sentCount === 0 && record.notOrdered
            ) ? 'danger' : ''
        }
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  prepareColumns = (props) => {
    const { disabled } = this.props;
    const columns = [
      {
        title: this.props.t(
          'pages.Operation.Transfer.detail.transfer.table.barcode',
        ),
        dataIndex: 'item.barcodes',
        key: 'barcodes',
        width: 72,
        render: (barcodes) =>
          barcodes.map((barcode) => (
            <Tag key={barcode} color="blue" style={{ margin: 2 }}>
              {barcode}
            </Tag>
          )),
      },
      {
        title: this.props.t(
          'pages.Operation.Transfer.detail.transfer.table.image',
        ),
        dataIndex: 'item.picURL',
        key: 'picURL',
        width: 72,
        render: (picURL) => (
          <Avatar
            className="grow"
            shape="circle"
            src={getTranslation.obj(picURL)}
          />
        ),
      },
      {
        title: this.props.t(
          'pages.Operation.Transfer.detail.transfer.table.fullName',
        ),
        dataIndex: 'item.fullName',
        key: 'item.fullName',
        render: (fullName) => getTranslation.obj(fullName),
      },
      {
        ...(!TransferDetailLogic.isFromWarehouse(
          this.props.transfer.directionType,
        )
          ? {
            title: this.props.transfer.fromWarehouse.name,
            dataIndex: 'fromCount',
            key: 'item.fromWarehouse',
          }
          : {
            title: this.props.transfer.fromWarehouse.name,
            children: [
              {
                title: this.props.t(
                  'pages.Operation.Transfer.detail.transfer.table.acceptanceArea',
                ),
                dataIndex: 'fromCountAcceptance',
                key: 'acceptanceArea-from',
              },
              {
                title: this.props.t(
                  'pages.Operation.Transfer.detail.transfer.table.availableArea',
                ),
                dataIndex: 'fromCount',
                key: 'availableArea-from',
              },
            ],
          }),
      },
      {
        title: this.props.t(
          'pages.Operation.Transfer.detail.transfer.table.orderCount',
        ),
        dataIndex: 'count',
        key: 'count',
        editable: true,
      },
      {
        ...(!TransferDetailLogic.isToWarehouse(
          this.props.transfer.directionType,
        )
          ? {
            title: this.props.transfer.toWarehouse.name,
            dataIndex: 'toCount',
            key: 'item.tomWarehouse',
          }
          : {
            title: this.props.transfer.toWarehouse.name,
            children: [
              {
                title: this.props.t(
                  'pages.Operation.Transfer.detail.transfer.table.acceptanceArea',
                ),
                dataIndex: 'toCountAcceptance',
                key: 'acceptanceArea-to',
              },
              {
                title: this.props.t(
                  'pages.Operation.Transfer.detail.transfer.table.availableArea',
                ),
                dataIndex: 'toCount',
                key: 'availableArea-to',
              },
            ],
          }),
      },
      { ...(
        !TransferDetailLogic.isToCentral(this.props.transfer.directionType)
        && !TransferDetailLogic.isTransferVirtualDepart(this.props.transfer.integrationType)
      ) ?
      {
        title: this.props.t(
          'pages.Operation.Transfer.detail.transfer.table.receivedCount',
        ),
        dataIndex: 'receivedCount',
        key: 'receivedCount',
      } : null,
      },
      { ...(
        !TransferDetailLogic.isToCentral(this.props.transfer.directionType)
        && !TransferDetailLogic.isTransferVirtualDepart(this.props.transfer.integrationType)
      ) ?
      {
        title: this.props.t(
          'pages.Operation.Transfer.detail.transfer.table.damagedCount',
        ),
        dataIndex: 'damagedCount',
        key: 'damagedCount',
      } : null,
      },
      {
        title: '',
        dataIndex: 'operation',
        render: (text, record) =>
          !this.props.disabled && this.props.transfer.items.length >= 1 ? (
            <Popconfirm
              title={this.props.t(
                'pages.Operation.Transfer.detail.transfer.table.checkDeleteProduct',
              )}
              onConfirm={() => this.onProductDelete(record)}
            >
              <Tag color="#f50">X</Tag>
            </Popconfirm>
          ) : null,
      },
    ];

    return columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          min: 0,
          disabled,
          handleCellSave: this.onProductCellChange,
        }),
      };
    });
  };

  onProductDelete = (row) => {
    setTimeout(() => {
      this.props.onChangeProductDelete(row);
    });
  };

  onProductCellChange = (row) => {
    const { item, count } = row;
    const { id } = item;
    const { selectedItems } = this.state;
    const uniqItems = uniqBy(selectedItems, 'item');
    const findedItem = uniqItems.find((item) => item.item === id);

    if (findedItem) {
      findedItem.count = count;
      uniqItems.map((item) => {
        if (item.item === findedItem.item) {
          return findedItem;
        }
        return item;
      });
    } else {
      uniqItems.push({
        item: id,
        count,
      });
    }

    this.setState({
      selectedItems: [...uniqItems.filter((item) => item.count)],
    });
    setTimeout(() => {
      this.props.onChange(this.state.selectedItems);
    });
  };

  prepareData = (props) => props;
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const TransferTableTranslated = withNamespaces('translation')(TransferTable);

export { TransferTableTranslated as TransferTable };
