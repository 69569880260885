/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row } from 'antd';
import { withNamespaces } from 'react-i18next';

/*----------------------------------------------------------------------------*/
import './WarehouseStockList.scss';
import { ProductList } from './ProductList';

import { WarehouseStockListFormatter } from './WarehouseStockListFormatter';
import { WarehouseStockFilter } from './WarehouseStockFilter';
import { constants } from '../../../shared';
/*----------------------------------------------------------------------------*/
import { Spinner } from '../../../components';
import { outgoingTransferAction } from '../../../actions';
import { pageLoadEvents } from '../../../services/events';
import { pageView } from '../../../services/segment';

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class WarehouseStockList extends Component {
  settings = {
    main: {
      className: 'WarehouseStockList_main',
    },
    card: {
      className: 'no-padding-card',
      title: this.props.t('pages.Stock.WarehouseStockList.title'),
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
    this.productListRef = React.createRef();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.STOCK_WAREHOUSE_STOCK_LIST });
    this.getList();
  }

  stateFactory = () => {
    return {
      tableFilter: {
        locationOperationTypes: this.getLocationOperationTypes(),
        locations: [],
      },
    };
  };

  getLocationOperationTypes = () => {
    const { currentWarehouse } = this.props;
    console.log('currentWarehouse.domainType', currentWarehouse.domainTypes);
    const locationOperationTypes = [];
    if (currentWarehouse.domainTypes) {
      if (currentWarehouse.domainTypes.includes(constants.DOMAIN_TYPE.WATER)) {
        locationOperationTypes.push(20, 110, 140);
      }
      if (
        currentWarehouse.domainTypes.includes(constants.DOMAIN_TYPE.GETIR10) ||
        currentWarehouse.domainTypes.includes(constants.DOMAIN_TYPE.MARKET)
      ) {
        locationOperationTypes.push(80, 100);
      }
    }
    return locationOperationTypes;
  };

  CardExtra = (props) => {
    const settings = {
      div: {
        style: { display: 'flex' },
      },
    };
    return <div {...settings.div} />;
  };

  PageLayout = (props) => (
    <div>
      <Row>
        <WarehouseStockFilter
          locationOperationTypes={this.getLocationOperationTypes()}
          onChange={this.setTableFilter}
          onClear={this.clearTableFilter}
        />
        <this.ProductList />
      </Row>
    </div>
  );

  ProductList = (props) => {
    const { productList, productLoading } = this.props;
    if (productLoading) {
      return <Spinner />;
    }
    const preparedItems = WarehouseStockListFormatter.getPreparedItems(
      productList.data,
      this.state.tableFilter.locations &&
        this.state.tableFilter.locations.length
        ? this.state.tableFilter.locations
        : [],
    );

    return (
      <ProductList
        ref={this.productListRef}
        productList={preparedItems}
        loading={productLoading}
        onChange={this.onTransferProductListChange}
        disabled={this.state.disableProductList}
      />
    );
  };

  setTableFilter = (filter) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        current: 1,
      },
    });
    this.setState({
      tableFilter: filter,
    });
    setTimeout(() => {
      this.getList();
    });
  };

  clearTableFilter = () => {
    const filter = this.getDefaultFilter();
    this.setState({
      tableFilter: filter,
    });
    delete filter.locations;
    this.props.onGetProductList(filter);
  };

  getList = () => {
    const filter = this.getFilter();
    this.props.onGetProductList(filter);
  };

  getFilter = () => {
    const { tableFilter } = this.state;
    return {
      locationOperationTypes:
        tableFilter.locationOperationTypes &&
        tableFilter.locationOperationTypes.length
          ? tableFilter.locationOperationTypes
          : this.getLocationOperationTypes(),
      productIds: tableFilter.products,
    };
  };

  getDefaultFilter = () => {
    return {
      locationOperationTypes: this.getLocationOperationTypes(),
      locations: [],
    };
  };

  render() {
    const { ProducListLoading } = this.props;
    if (ProducListLoading) {
      return <Spinner />;
    }

    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageLayout />
        </Card>
      </div>
    );
  }
}

/*------------------------------------------------------------------------------

  ------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  currentWarehouseId: state.auth.currentWarehouse._id,
  productList: state.outgoingTransfer.productList,
  productLoading: state.outgoingTransfer.productList.loading,
  CWId: state.auth.currentWarehouse._id,
  currentWarehouse: state.auth.currentWarehouse,
});

const mapDispatchToProps = (dispatch) => ({
  onGetProductList: (filter) => {
    dispatch(
      outgoingTransferAction.createSelfOutgoingTransferProductList(filter),
    );
  },
});

const WarehouseStockListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(WarehouseStockList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { WarehouseStockListConnected as WarehouseStockList };
