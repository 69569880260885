import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';

export const returnedOrdersAction = {
  getReturnedOrders,
  getReturnedOrdersCount,
  completeReturnedOrders,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getReturnedOrders() {
  return (dispatch) => {
    dispatch({
      type: actionType.getReturnedOrders_InProgress,
    });
    http
      .getReturnedOrders()
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getReturnedOrders_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getReturnedOrders_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getReturnedOrders_Failed,
          payload: error,
        });
      });
  };
}

function getReturnedOrdersCount() {
  return (dispatch) => {
    dispatch({
      type: actionType.getReturnedOrdersCount_InProgress,
    });
    http
      .getReturnedOrdersCount()
      .then((response) => {
        const { total } = response.data.data;
        dispatch({
          type: actionType.getReturnedOrdersCount_Succeeded,
          payload: total,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionType.getReturnedOrdersCount_Failed,
          payload: error,
        });
      });
  };
}

function completeReturnedOrders(orderId, data) {
  return (dispatch) => {
    dispatch({
      type: actionType.completeReturnedOrders_InProgress,
    });
    return http
      .completeReturnedOrders(orderId, data)
      .then((response) => {
        dispatch({
          type: actionType.completeReturnedOrders_Succeeded,
        });
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.completeReturnedOrders_Succeeded,
          ),
        });
        dispatch(getReturnedOrders());
        dispatch(getReturnedOrdersCount());
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.completeReturnedOrders_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.completeReturnedOrders_Failed,
          payload: error,
        });
      });
  };
}
