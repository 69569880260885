import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  transferCreate: {
    created: false,
    data: null,
  },
  list: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
  detail: {
    loading: true,
    data: {
      products: [],
    },
    error: null,
  },
  approve: {
    loading: true,
    data: null,
    error: null,
  },
  cancelTransfer: {
    loading: true,
    data: null,
    error: null,
  },
  isExporting: false,
  isAddNoteInProgress: false,
  equipmentList: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
  changeTransferVisibilityStatus: {
    loading: false,
    error: null,
  },
  createEquipmentStatus: {
    loading: false,
    error: null,
  },
};

const switcher = {
  [actionType.getIncomingTransferList_InProgress]: getIncomingTransferList_InProgress,
  [actionType.getIncomingTransferList_Succeeded]: getIncomingTransferList_Succeeded,
  [actionType.getIncomingTransferList_Failed]: getIncomingTransferList_Failed,

  [actionType.getIncomingTransferDetail_InProgress]: getIncomingTransferDetail_InProgress,
  [actionType.getIncomingTransferDetail_Succeeded]: getIncomingTransferDetail_Succeeded,
  [actionType.getIncomingTransferDetail_Failed]: getIncomingTransferDetail_Failed,

  [actionType.createTransfer_InProgress]: createTransfer_InProgress,
  [actionType.createTransfer_Succeeded]: createTransfer_Succeeded,
  [actionType.createTransfer_Failed]: createTransfer_Failed,

  [actionType.getTransferList_InProgress]: getTransferList_InProgress,
  [actionType.getTransferList_Succeeded]: getTransferList_Succeeded,
  [actionType.getTransferList_Failed]: getTransferList_Failed,

  [actionType.getTransferDetail_InProgress]: getTransferDetail_InProgress,
  [actionType.getTransferDetail_Succeeded]: getTransferDetail_Succeeded,
  [actionType.getTransferDetail_Failed]: getTransferDetail_Failed,

  [actionType.updateTransferItems_InProgress]: updateTransferItems_InProgress,
  [actionType.updateTransferItems_Succeeded]: updateTransferItems_Succeeded,
  [actionType.updateTransferItems_Failed]: updateTransferItems_Failed,

  [actionType.approveTransfer_InProgress]: approveTransfer_InProgress,
  [actionType.approveTransfer_Succeeded]: approveTransfer_Succeeded,
  [actionType.approveTransfer_Failed]: approveTransfer_Failed,

  [actionType.cancelTransfer_InProgress]: cancelTransfer_InProgress,
  [actionType.cancelTransfer_Succeeded]: cancelTransfer_Succeeded,
  [actionType.cancelTransfer_Failed]: cancelTransfer_Failed,

  [actionType.deleteTransfer_InProgress]: deleteTransfer_InProgress,
  [actionType.deleteTransfer_Succeeded]: deleteTransfer_Succeeded,
  [actionType.deleteTransfer_Failed]: deleteTransfer_Failed,

  [actionType.centralWarehouseOutgoingTransfer_InProgress]: centralWarehouseOutgoingTransfer_InProgress,
  [actionType.centralWarehouseOutgoingTransfer_Succeeded]: centralWarehouseOutgoingTransfer_Succeeded,
  [actionType.centralWarehouseOutgoingTransfer_Failed]: centralWarehouseOutgoingTransfer_Failed,

  [actionType.centralWarehouseIncomingTransfer_InProgress]: centralWarehouseIncomingTransfer_InProgress,
  [actionType.centralWarehouseIncomingTransfer_Succeeded]: centralWarehouseIncomingTransfer_Succeeded,
  [actionType.centralWarehouseIncomingTransfer_Failed]: centralWarehouseIncomingTransfer_Failed,

  [actionType.sendWarehousePurchaseOrders_InProgress]: sendWarehousePurchaseOrders_InProgress,
  [actionType.sendWarehousePurchaseOrders_Succeeded]: sendWarehousePurchaseOrders_Succeeded,
  [actionType.sendWarehousePurchaseOrders_Failed]: sendWarehousePurchaseOrders_Failed,

  [actionType.departTransferVehicle_InProgress]: departTransferVehicle_InProgress,
  [actionType.departTransferVehicle_Succeeded]: departTransferVehicle_Succeeded,
  [actionType.departTransferVehicle_Failed]: departTransferVehicle_Failed,

  [actionType.sendDepartedOrder_InProgress]: sendDepartedOrder_InProgress,
  [actionType.sendDepartedOrder_Succeeded]: sendDepartedOrder_Succeeded,
  [actionType.sendDepartedOrder_Failed]: sendDepartedOrder_Failed,

  [actionType.exportToXlsxTransferList_InProgress]: exportToXlsxTransferList_InProgress,
  [actionType.exportToXlsxTransferList_Succeeded]: exportToXlsxTransferList_Succeeded,
  [actionType.exportToXlsxTransferList_Failed]: exportToXlsxTransferList_Failed,

  [actionType.addNoteToTransfer_InProgress]: addNoteToTransfer_InProgress,
  [actionType.addNoteToTransfer_Succeeded]: addNoteToTransfer_Succeeded,
  [actionType.addNoteToTransfer_Failed]: addNoteToTransfer_Failed,

  [actionType.getEquipmentTransferList_InProgress]: getEquipmentTransferList_InProgress,
  [actionType.getEquipmentTransferList_Succeeded]: getEquipmentTransferList_Succeeded,
  [actionType.getEquipmentTransferList_Failed]: getEquipmentTransferList_Failed,

  [actionType.createEquipmentTransfer_InProgress]: createEquipmentTransfer_InProgress,
  [actionType.createEquipmentTransfer_Succeeded]: createEquipmentTransfer_Succeeded,
  [actionType.createEquipmentTransfer_Failed]: createEquipmentTransfer_Failed,

  [actionType.changeTransferVisibility_InProgress]: changeTransferVisibility_InProgress,
  [actionType.changeTransferVisibility_Succeeded]: changeTransferVisibility_Succeeded,
  [actionType.changeTransferVisibility_Failed]: changeTransferVisibility_Failed,


};

export const transferReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getIncomingTransferList_InProgress(state) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function getIncomingTransferList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload.transfers,
      error: null,
      total: payload.total,
    },
  });
}

function getIncomingTransferList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getIncomingTransferDetail_InProgress(state) {
  return Object.assign({}, state, {
    detail: {
      loading: true,
      data: {
        products: [],
      },
      error: null,
    },
  });
}

function getIncomingTransferDetail_Succeeded(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getIncomingTransferDetail_Failed(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: {
        products: [],
      },
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function createTransfer_InProgress(state, payload) {
  return Object.assign({}, state, {
    transferCreate: {
      created: false,
      data: null,
      loading: true,
    },
  });
}

function createTransfer_Succeeded(state, payload) {
  return Object.assign({}, state, {
    transferCreate: {
      created: true,
      data: payload.data.data,
      loading: false,
    },
  });
}

function createTransfer_Failed(state, payload) {
  return Object.assign({}, state, {
    transferCreate: {
      created: false,
      data: null,
      loading: false,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getTransferList_InProgress(state) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function getTransferList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload.transfers,
      error: null,
      total: payload.total,
    },
  });
}

function getTransferList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getTransferDetail_InProgress(state) {
  return Object.assign({}, state, {
    detail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getTransferDetail_Succeeded(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getTransferDetail_Failed(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function updateTransferItems_InProgress(state, payload) {
  return Object.assign({}, state, {
    updateTransfer: {
      created: false,
      data: null,
    },
  });
}

function updateTransferItems_Succeeded(state, payload) {
  return Object.assign({}, state, {
    updateTransfer: {
      created: true,
      data: payload.data.data,
    },
  });
}

function updateTransferItems_Failed(state, payload) {
  return Object.assign({}, state, {
    updateTransfer: {
      created: false,
      data: null,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function approveTransfer_InProgress(state, payload) {
  return Object.assign({}, state, {
    approve: {
      created: false,
      data: null,
      loading: false,
    },
  });
}

function approveTransfer_Succeeded(state, payload) {
  return Object.assign({}, state, {
    approve: {
      created: true,
      data: payload.data.data,
      loading: true,
    },
  });
}

function approveTransfer_Failed(state, payload) {
  return Object.assign({}, state, {
    approve: {
      created: false,
      data: null,
      loading: true,
    },
  });
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function cancelTransfer_InProgress(state, payload) {
  return Object.assign({}, state, {
    cancelTransfer: {
      created: false,
      data: null,
      loading: false,
    },
  });
}

function cancelTransfer_Succeeded(state, payload) {
  return Object.assign({}, state, {
    cancelTransfer: {
      created: true,
      data: payload.data.data,
      loading: true,
    },
  });
}

function cancelTransfer_Failed(state, payload) {
  return Object.assign({}, state, {
    cancelTransfer: {
      created: false,
      data: null,
      loading: true,
    },
  });
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function deleteTransfer_InProgress(state, payload) {
  return Object.assign({}, state, {
    deleteTransfer: {
      created: false,
      data: null,
    },
  });
}

function deleteTransfer_Succeeded(state, payload) {
  return Object.assign({}, state, {
    deleteTransfer: {
      created: true,
      data: payload.data.data,
    },
  });
}

function deleteTransfer_Failed(state, payload) {
  return Object.assign({}, state, {
    deleteTransfer: {
      created: false,
      data: null,
    },
  });
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function centralWarehouseOutgoingTransfer_InProgress(state, payload) {
  return Object.assign({}, state, {
    centralWarehouseOutgoingTransfer: {
      created: false,
      data: null,
    },
  });
}

function centralWarehouseOutgoingTransfer_Succeeded(state, payload) {
  return Object.assign({}, state, {
    centralWarehouseOutgoingTransfer: {
      created: true,
      data: payload.data.data,
    },
  });
}

function centralWarehouseOutgoingTransfer_Failed(state, payload) {
  return Object.assign({}, state, {
    centralWarehouseOutgoingTransfer: {
      created: false,
      data: null,
    },
  });
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function centralWarehouseIncomingTransfer_InProgress(state, payload) {
  return Object.assign({}, state, {
    centralWarehouseIncomingTransfer: {
      created: false,
      data: null,
    },
  });
}

function centralWarehouseIncomingTransfer_Succeeded(state, payload) {
  return Object.assign({}, state, {
    centralWarehouseIncomingTransfer: {
      created: true,
      data: payload.data.data,
    },
  });
}

function centralWarehouseIncomingTransfer_Failed(state, payload) {
  return Object.assign({}, state, {
    centralWarehouseIncomingTransfer: {
      created: false,
      data: null,
    },
  });
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function sendWarehousePurchaseOrders_InProgress(state, payload) {
  return Object.assign({}, state, {
    sendWarehousePurchaseOrders: {
      created: false,
      data: null,
    },
  });
}

function sendWarehousePurchaseOrders_Succeeded(state, payload) {
  return Object.assign({}, state, {
    sendWarehousePurchaseOrders: {
      created: true,
      data: payload.data.data,
    },
  });
}

function sendWarehousePurchaseOrders_Failed(state, payload) {
  return Object.assign({}, state, {
    sendWarehousePurchaseOrders: {
      created: false,
      data: null,
    },
  });
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function departTransferVehicle_InProgress(state, payload) {
  return Object.assign({}, state, {
    departTransferVehicle: {
      created: false,
      data: null,
    },
  });
}

function departTransferVehicle_Succeeded(state, payload) {
  return Object.assign({}, state, {
    departTransferVehicle: {
      created: true,
      data: payload.data.data,
    },
  });
}

function departTransferVehicle_Failed(state, payload) {
  return Object.assign({}, state, {
    departTransferVehicle: {
      created: false,
      data: null,
    },
  });
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function sendDepartedOrder_InProgress(state, payload) {
  return Object.assign({}, state, {
    sendDepartedOrder: {
      created: false,
      data: null,
    },
  });
}

function sendDepartedOrder_Succeeded(state, payload) {
  return Object.assign({}, state, {
    sendDepartedOrder: {
      created: true,
      data: payload.data.data,
    },
  });
}

function sendDepartedOrder_Failed(state, payload) {
  return Object.assign({}, state, {
    sendDepartedOrder: {
      created: false,
      data: null,
    },
  });
}

function exportToXlsxTransferList_InProgress(state, payload) {
  return Object.assign({}, state, {
    isExporting: true,
  });
}

function exportToXlsxTransferList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    isExporting: false,
  });
}

function exportToXlsxTransferList_Failed(state, payload) {
  return Object.assign({}, state, {
    isExporting: false,
  });
}

function addNoteToTransfer_InProgress(state, payload) {
  return Object.assign({}, state, {
    isAddNoteInProgress: true,
  });
}

function addNoteToTransfer_Succeeded(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: {
        ...state.detail.data,
        note: payload.note,
      },
      error: null,
    },
    isAddNoteInProgress: false,
  });
}

function addNoteToTransfer_Failed(state, payload) {
  return Object.assign({}, state, {
    isAddNoteInProgress: false,
  });
}


function getEquipmentTransferList_InProgress(state) {
  return Object.assign({}, state, {
    equipmentList: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function getEquipmentTransferList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    equipmentList: {
      loading: false,
      data: payload.transfers,
      error: null,
      total: payload.total,
    },
  });
}

function getEquipmentTransferList_Failed(state, payload) {
  return Object.assign({}, state, {
    equipmentList: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

function createEquipmentTransfer_InProgress(state) {
  return Object.assign({}, state, {
    createEquipmentStatus: {
      loading: true,
      error: null,
    },
  });
}

function createEquipmentTransfer_Succeeded(state) {
  return Object.assign({}, state, {
    createEquipmentStatus: {
      loading: false,
      error: null,
    },
  });
}

function createEquipmentTransfer_Failed(state, payload) {
  return Object.assign({}, state, {
    createEquipmentStatus: {
      loading: false,
      error: payload,
    },
  });
}


function changeTransferVisibility_InProgress(state) {
  return Object.assign({}, state, {
    changeTransferVisibilityStatus: {
      loading: true,
      error: null,
    },
  });
}

function changeTransferVisibility_Succeeded(state) {
  return Object.assign({}, state, {
    changeTransferVisibilityStatus: {
      loading: false,
      error: null,
    },
  });
}

function changeTransferVisibility_Failed(state, payload) {
  return Object.assign({}, state, {
    changeTransferVisibilityStatus: {
      loading: false,
      error: payload,
    },
  });
}
