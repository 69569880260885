import { getLangKey } from '../i18n';
import { getCountryCode } from '../shared';

export const pageView = ({ pageName, props = {} }) => {
  if (window.analytics) {
    const search = window.location.search;
    if (pageName) {
      window.analytics.page(pageName, { search, ...props });
    }
  } else {
    console.log('Analytics is not initialized');
  }
};

export const identify = ({ traits: userInfo }) => {
  try {
    if (window.analytics) {
      const {
        id,
        ...rest
      } = getFormattedTraits({ userInfo });
      window.analytics.identify(id, {
        ...rest,
      });
    } else {
      setTimeout(identify({ traits: userInfo }), 1000);
    }
  } catch (error) {
    console.error(error);
  }
};

export const track = (eventName, properties) => {
  if (window.analytics) {
    window.analytics.track(eventName, properties);
  }
};


const getFormattedTraits = ({ userInfo }) => {
  const langKey = getLangKey();
  const selectedCountry = getCountryCode();
  const { _id, email, warehouseId, isAdmin } = userInfo;

  return ({
    langKey,
    selectedCountry,
    id: _id,
    email,
    warehouseId,
    isAdmin,
  });
};
