export class WarehouseStockListFormatter {
  static getPreparedItems(products, locations) {
    if (!locations || !locations.length) {
      return products.map((p) => ({
        ...p,
        locations: p.locations.map((l) => ({
          ...l,
          productId: p.id,
          productBarcode: p.barcodes[0],
        })),
      }));
    }
    return products
      .map((p) => ({
        ...p,
        locations: p.locations
          .map((l) => ({
            ...l,
            productId: p.id,
            productBarcode: p.barcodes[0],
          }))
          .filter((l) => {
            const sPLs = locations.map((spl) => spl.barcode);
            return sPLs.includes(l.barcode);
          }),
      }))
      .filter((p) => p.locations.length);
  }
}
