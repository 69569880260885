import { uniqBy, groupBy, uniq } from 'lodash';

import { notification } from 'antd';
import { constants, getTranslation } from '../../../../shared';
import i18n from './../../../../i18n';

export class TransferDataFormatter {
  static getWarehouseName(warehouseList, warehouseId) {
    try {
      const warehouse = warehouseList.find(
        (warehouse) => warehouse.id.toString() === warehouseId.toString(),
      );
      return warehouse.name;
    } catch (error) {
      notification.error({
        duration: 3,
        message: warehouseId,
        description: i18n.t(
          'pages.Operation.Transfer.create.notification.warehouseNotFound',
        ),
      });
      console.log(
        'CSV file has invalid record, warehouse not found ',
        warehouseId,
      );
      throw error;
    }
  }

  static getProductName(productList, productId) {
    try {
      const product = productList.find(
        (product) => product.id.toString() === productId.toString(),
      );
      return getTranslation.obj(product.fullName);
    } catch (error) {
      notification.error({
        duration: 3,
        message: productId,
        description: i18n.t(
          'pages.Operation.Transfer.create.notification.productNotFound',
        ),
      });
      console.log('CSV file has invalid record, product not found ', productId);
      throw error;
    }
  }

  static preparedPreviewData(
    fromWarehouses,
    toWarehouses,
    selectedProductList,
  ) {
    if (
      !fromWarehouses ||
      !fromWarehouses.length ||
      !toWarehouses ||
      !toWarehouses.length
    ) {
      return null;
    }

    let records;
    if (fromWarehouses.length > 1) {
      records = fromWarehouses.map((fromWarehouse) => ({
        direction: `${fromWarehouse.name} - ${toWarehouses[0].name}`,
        fromWarehouseId: fromWarehouse.id,
        toWarehouseId: toWarehouses[0].id,
        ...selectedProductList.reduce((acc, product) => {
          acc[product.itemId] = product.count;
          return acc;
        }, {}),
      }));
    }

    if (toWarehouses.length > 1) {
      records = toWarehouses.map((toWarehouse) => ({
        direction: `${fromWarehouses[0].name} - ${toWarehouse.name}`,
        fromWarehouseId: fromWarehouses[0].id,
        toWarehouseId: toWarehouse.id,
        ...selectedProductList.reduce((acc, product) => {
          acc[product.itemId] = product.count;
          return acc;
        }, {}),
      }));
    }
    console.log(toWarehouses, fromWarehouses);
    if (toWarehouses.length === 1 && fromWarehouses.length === 1) {
      records = [
        {
          direction: `${fromWarehouses[0].name} - ${toWarehouses[0].name}`,
          fromWarehouseId: fromWarehouses[0].id,
          toWarehouseId: toWarehouses[0].id,
          ...selectedProductList.reduce((acc, product) => {
            acc[product.itemId] = product.count;
            return acc;
          }, {}),
        },
      ];
    }

    return {
      columns: selectedProductList.map((product) => {
        return {
          productId: product.itemId,
          name: product.name,
        };
      }),
      preparedPreviewData: records,
    };
  }

  static removeCsvDataHeader(data) {
    data.shift();
    // eslint-disable-next-line no-param-reassign
    data = TransferDataFormatter.trim(data);
    TransferDataFormatter.checkData(data);
    TransferDataFormatter.checkProductCount(data);
    return data;
  }

  static preparedCsvPreviewData(warehouseList, productList, data) {
    try {
      const pureCsvData = TransferDataFormatter.removeCsvDataHeader(data);
      const {
        preparedPureCsvData,
        uniqProducts,
      } = TransferDataFormatter.preparePureCsvData(
        warehouseList,
        productList,
        pureCsvData,
      );
      const preparedCsvPreviewData = TransferDataFormatter.prepareCsvPreviewData(
        preparedPureCsvData,
      );

      return {
        columns: uniqProducts,
        preparedCsvPreviewData,
      };
    } catch (error) {
      throw error;
    }
  }

  static trim(importedData) {
    return importedData.filter((item) => item.length > 1);
  }

  static checkData(data) {
    const check = data.map((item) => item[0] + item[1] + item[2]);
    if (uniq(check).length === check.length) {
      // eslint-disable-next-line no-useless-return
      return;
    } else {
      throw new Error('notification.csvFileHasInvalidRecords');
    }
  }

  static checkProductCount(data) {
    const invalidProduct = {};
    data.forEach((item) => {
      const count = parseFloat(item[3]);
      if (count < 0 || !Number.isInteger(count)) {
        invalidProduct.productId = item[2];
        invalidProduct.hasInvalidProductCount =
          count < 0 || !Number.isInteger(count);
        return invalidProduct;
      }
    });
    if (invalidProduct.hasInvalidProductCount) {
      notification.error({
        duration: 3,
        message: i18n.t(
          'pages.Operation.Transfer.create.notification.csvFileHasInvalidProductRecord',
        ),
        // description: invalidProduct.productId,
      });
      console.log(
        'CSV file has invalid record, product has invalid record',
        invalidProduct.productId,
      );
      throw new Error('notification.csvFileHasInvalidProductRecord');
    }
  }

  static preparePureCsvData(warehouseList, productList, data) {
    const mapCsvData = data.map((item) => ({
      fromWarehouseId: item[0],
      toWarehouseId: item[1],
      productId: item[2],
      count: item[3],
    }));
    try {
      let groupCsvDataByWarehouseId;
      const groupCsvDataByFromWarehouseId = Object.entries(
        groupBy(mapCsvData, 'fromWarehouseId'),
      );
      const groupCsvDataByToWarehouseId = Object.entries(
        groupBy(mapCsvData, 'toWarehouseId'),
      );

      if (
        groupCsvDataByFromWarehouseId.length > 1 &&
        groupCsvDataByToWarehouseId.length > 1
      ) {
        notification.error({
          duration: 3,
          message: i18n.t(
            'pages.Operation.Transfer.create.notification.csvFileHasMultipleFromAndToWarehouse',
          ),
        });
        console.log(
          'CSV file has invalid record, file has multiple from and to warehouses, fromWarehouses ',
          groupCsvDataByFromWarehouseId,
        );
        console.log(
          'CSV file has invalid record, file has multiple from and to warehouses, toWarehouses ',
          groupCsvDataByToWarehouseId,
        );
        throw new Error();
      } else if (
        groupCsvDataByFromWarehouseId.length > 1 &&
        groupCsvDataByToWarehouseId.length === 1
      ) {
        groupCsvDataByWarehouseId = groupCsvDataByFromWarehouseId;
        let hasDincerIntegrationId = false;
        let foundItem;
        groupCsvDataByWarehouseId.forEach((gItem) => {
          foundItem = warehouseList.find(
            (warehouse) => warehouse.id === gItem[0],
          );
          if (foundItem.dincerIntegrationId) {
            hasDincerIntegrationId = true;
          }
        });
        if (hasDincerIntegrationId) {
          notification.error({
            duration: 3,
            message: i18n.t(
              'pages.Operation.Transfer.create.notification.csvFileHasMultipleFromWarehouseWhichHasDincerIntegrationId',
            ),
          });

          console.log(
            'CSV file has invalid record, csv File Has Multiple FromWarehouse Which Has DincerIntegrationId ',
            foundItem.id,
          );
          throw new Error();
        }
      } else if (
        groupCsvDataByToWarehouseId.length > 1 &&
        groupCsvDataByFromWarehouseId.length === 1
      ) {
        groupCsvDataByWarehouseId = groupCsvDataByToWarehouseId;
      } else if (
        groupCsvDataByToWarehouseId.length === 1 &&
        groupCsvDataByFromWarehouseId.length === 1
      ) {
        groupCsvDataByWarehouseId = groupCsvDataByToWarehouseId;
      }

      const previewDataFormat = [];
      const usedProducts = [];
      const usedWarehouses = [];
      groupCsvDataByWarehouseId.forEach((gItem) => {
        let obj = {};
        let itemObj = {};

        if (Array.isArray(gItem[1])) {
          gItem[1].forEach((item) => {
            obj.fromWarehouseId = item.fromWarehouseId;
            obj.toWarehouseId = item.toWarehouseId;

            obj.direction = `${this.getWarehouseName(
              warehouseList,
              obj.fromWarehouseId,
            )} - ${this.getWarehouseName(warehouseList, obj.toWarehouseId)}`;
            usedWarehouses.push(obj.direction);
            usedProducts.push({
              productId: item.productId,
              name: this.getProductName(productList, item.productId),
            });
            itemObj = {
              ...itemObj,
              [item.productId]: item.count,
            };
          });

          obj = {
            ...obj,
            ...itemObj,
          };

          previewDataFormat.push(obj);
        }
      });
      const uniqProducts = uniqBy(usedProducts, 'productId');
      const uniqWarehouses = uniq(usedWarehouses);

      uniqProducts.forEach((p) => {
        previewDataFormat.map((item) => {
          const i = item;
          if (!Object.keys(item).includes(p.productId.toString())) {
            i[p.productId] = 0;
            return i;
          }
          return i;
        });
      });

      return {
        preparedPureCsvData: previewDataFormat,
        uniqProducts,
        uniqWarehouses,
      };
    } catch (error) {
      throw error;
    }
  }

  static prepareCsvPreviewData(data) {
    const preparedDataRecord = [];
    data.forEach((item) => {
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          if (
            key !== 'fromWarehouseId' &&
            key !== 'toWarehouseId' &&
            key !== 'direction'
          ) {
            preparedDataRecord.push(key);
          }
        }
      }
    });

    preparedDataRecord.flat().forEach((record) => {
      data.map((item) => {
        if (!Object.keys(item).includes(record)) {
          return {
            ...item,
          };
        }
        return item;
      });
    });

    const records = data.map((item) => ({
      ...item,
    }));

    return records;
  }

  static prepareTransferData(transferType, previewData, storeAndWarehouseList) {
    const preparedTransferData = [];
    previewData.forEach((item) => {
      const transfer = {};
      const itemsObject = { ...item };
      delete itemsObject.direction;
      delete itemsObject.fromWarehouseId;
      delete itemsObject.toWarehouseId;

      const items = Object.entries(itemsObject)
        .map((item, index) => ({
          item: item[0],
          count: parseInt(item[1], 10),
        }))
        .filter((item) => item.count);

      transfer.fromWarehouse = item.fromWarehouseId;
      transfer.toWarehouse = item.toWarehouseId;
      transfer.integrationType = parseInt(transferType.key, 10);
      transfer.domainType = 3;
      const populatedFromWarehouse = storeAndWarehouseList.find(
        (item) => item.id === transfer.fromWarehouse,
      );
      const populatedToWarehouse = storeAndWarehouseList.find(
        (item) => item.id === transfer.toWarehouse,
      );
      transfer.directionType = this.getTransferDirectionType(
        populatedFromWarehouse,
        populatedToWarehouse,
      );
      transfer.items = items;

      preparedTransferData.push({
        transfer,
      });
    });

    return preparedTransferData;
  }

  static getTransferDirectionType(from, to) {
    if (from.dincerIntegrationId && !to.dincerIntegrationId) {
      return constants.DIRECTION_TYPES.CENTRAL_TO_WAREHOUSE;
    }

    if (!from.dincerIntegrationId && to.dincerIntegrationId) {
      return constants.DIRECTION_TYPES.WAREHOUSE_TO_CENTRAL;
    }

    if (!from.dincerIntegrationId && !to.dincerIntegrationId) {
      return constants.DIRECTION_TYPES.WAREHOUSE_TO_WAREHOUSE;
    }

    if (from.dincerIntegrationId && to.dincerIntegrationId) {
      return constants.DIRECTION_TYPES.CENTRAL_TO_CENTRAL;
    }
  }
}
