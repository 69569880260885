import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
  confirmLoading: false,
  eWaybill: {
    loading: false,
    data: false,
    errorCount: 0,
  },
  createRefund: {
    loading: false,
    data: null,
    error: null,
  },
  createWaybillBulk: {
    loading: false,
    data: null,
    error: null,
  },
  createWaybill: {
    loading: false,
    data: null,
    error: null,
  },
};

const switcher = {
  [actionType.filterRefundList_InProgress]: filterRefundList_InProgress,
  [actionType.filterRefundList_Succeeded]: filterRefundList_Succeeded,
  [actionType.filterRefundList_Failed]: filterRefundList_Failed,

  [actionType.completeRefundBulk_InProgress]: completeRefundBulk_InProgress,
  [actionType.completeRefundBulk_Succeeded]: completeRefundBulk_Succeeded,
  [actionType.completeRefundBulk_Failed]: completeRefundBulk_Failed,

  [actionType.generateEwaybill_InProgress]: generateEwaybill_InProgress,
  [actionType.generateEwaybill_Succeeded]: generateEwaybill_Succeeded,
  [actionType.generateEwaybill_Failed]: generateEwaybill_Failed,

  [actionType.createRefund_InProgress]: createRefund_InProgress,
  [actionType.createRefund_Succeeded]: createRefund_Succeeded,
  [actionType.createRefund_Failed]: createRefund_Failed,

  [actionType.createWaybill_InProgress]: createWaybill_InProgress,
  [actionType.createWaybill_Succeeded]: createWaybill_Succeeded,
  [actionType.createWaybill_Failed]: createWaybill_Failed,

  [actionType.createWaybillBulk_InProgress]: createWaybillBulk_InProgress,
  [actionType.createWaybillBulk_Succeeded]: createWaybillBulk_Succeeded,
  [actionType.createWaybillBulk_Failed]: createWaybillBulk_Failed,
};

export const refundReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function filterRefundList_InProgress(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function filterRefundList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload.blockedStocks,
      error: null,
      total: payload.total,
    },
  });
}

function filterRefundList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

function completeRefundBulk_InProgress(state, payload) {
  return Object.assign({}, state, {
    confirmLoading: false,
  });
}

function completeRefundBulk_Succeeded(state, payload) {
  return Object.assign({}, state, {
    confirmLoading: true,
  });
}

function completeRefundBulk_Failed(state, payload) {
  return Object.assign({}, state, {
    confirmLoading: true,
  });
}


function generateEwaybill_InProgress(state, payload) {
  return Object.assign({}, state, {
    eWaybill: {
      loading: true,
      data: null,
      errorCount: state.eWaybill.errorCount,
    },
  });
}

function generateEwaybill_Succeeded(state, payload) {
  return Object.assign({}, state, {
    eWaybill: {
      loading: false,
      data: payload,
      errorCount: 0,
    },
  });
}

function generateEwaybill_Failed(state, payload) {
  return Object.assign({}, state, {
    eWaybill: {
      loading: false,
      data: null,
      errorCount: state.eWaybill.errorCount += 1,
    },
  });
}


function createRefund_InProgress(state, payload) {
  return Object.assign({}, state, {
    createRefund: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function createRefund_Succeeded(state, payload) {
  return Object.assign({}, state, {
    createRefund: {
      loading: false,
      data: payload,
      error: false,
    },
  });
}

function createRefund_Failed(state, payload) {
  return Object.assign({}, state, {
    createRefund: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function createWaybill_InProgress(state, payload) {
  return Object.assign({}, state, {
    createWaybill: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function createWaybill_Succeeded(state, payload) {
  return Object.assign({}, state, {
    createWaybill: {
      loading: false,
      data: payload,
      error: false,
    },
  });
}

function createWaybill_Failed(state, payload) {
  return Object.assign({}, state, {
    createWaybill: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function createWaybillBulk_InProgress(state, payload) {
  return Object.assign({}, state, {
    createWaybillBulk: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function createWaybillBulk_Succeeded(state, payload) {
  return Object.assign({}, state, {
    createWaybillBulk: {
      loading: false,
      data: payload,
      error: false,
    },
  });
}

function createWaybillBulk_Failed(state, payload) {
  return Object.assign({}, state, {
    createWaybillBulk: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}
