import { constants } from './constants';

export const filterWarehousesByType = (warehouseList) => {
  const filteredWarehouses = warehouseList.filter(
    (warehouse) => warehouse.warehouseType === constants.WAREHOUSE_TYPE.REGULAR_WAREHOUSE ||
    warehouse.warehouseType === constants.WAREHOUSE_TYPE.NONAGREEMENT_WAREHOUSE ||
    warehouse.warehouseType === constants.WAREHOUSE_TYPE.SC_GROCER_WAREHOUSE ||
    warehouse.warehouseType === constants.WAREHOUSE_TYPE.FACTORY_WAREHOUSE ||
    warehouse.warehouseType === constants.WAREHOUSE_TYPE.STORE_CONVERSION ||
    warehouse.warehouseType === constants.WAREHOUSE_TYPE.MAIN_WAREHOUSE ||
    warehouse.warehouseType === constants.WAREHOUSE_TYPE.SUPPLY_CHAIN_OPERATIONS ||
    warehouse.warehouseType === constants.WAREHOUSE_TYPE.OTHER
  );
  return filteredWarehouses;
};
