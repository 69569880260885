import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Button, Card } from 'antd';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './WarehousePurchaseOrderList.scss';
import { WarehousePurchaseOrderFilter } from './WarehousePurchaseOrderFilter';
/*----------------------------------------------------------------------------*/
import {
  Spinner,
  CopyToClipboard,
  ExportToExcel,
} from './../../../../components';
import { getTranslation, getDateTimeFormatString } from './../../../../shared';
import { warehousePurchaseOrderAction } from './../../../../actions';
import { pageLoadEvents, WarehousePurchaseOrderListEvents } from '../../../../services/events';
import { pageView, track } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class WarehousePurchaseOrderList extends Component {
  settings = {
    main: {
      className: 'WarehousePurchaseOrderList_main',
    },
    card: {
      title: this.props.t('pages.Operation.PurchaseOrder.wpoList.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_WPO_LIST });
    this.getList();
  }

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      filter: this.getDefaultFilter(),
    };
  };

  getDefaultFilter = () => {
    return null;
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageFilter />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => (
    <ExportToExcel
      action={this.props.onExportToXlsxWarehousePurchaseOrder}
      query={{ limit: this.props.totalCount, offset: 0 }}
      getFilter={this.getFilter}
      fileName="warehousePurchaseOrderList"
      dataFormatter={this.prepareForExport}
      loading={this.props.isExporting}
      disabled={false}
    />
  );

  PageFilter = () => {
    return (
      <WarehousePurchaseOrderFilter
        defaultDateRange={this.defaultDateRange}
        onChange={this.setTableFilter}
        onClear={this.clearTableFilter}
      />
    );
  };

  PageTable = () => {
    return (
      <Table
        size="small"
        dataSource={this.prepareData(this.props)}
        columns={this.prepareColumns(this.props)}
        rowKey={(record) => {
          return record.id;
        }}
        pagination={{ ...this.state.pagination, total: this.props.totalCount }}
        loading={{ indicator: <Spinner />, spinning: this.props.loading }}
        onChange={this.handleTableChange}
      />
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState((state) => {
      return {
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      };
    }, this.getList);
  };

  prepareColumns = (props) => [
    {
      title: this.props.t(
        'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.id',
      ),
      dataIndex: 'id',
      key: 'id',
      render: (id) => <CopyToClipboard message={id} />,
    },
    {
      title: this.props.t(
        'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.no',
      ),
      dataIndex: 'no',
      key: 'no',
      render: (no) => <CopyToClipboard message={no} />,
    },
    {
      title: this.props.t(
        'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.waybillsNo',
      ),
      dataIndex: 'waybills',
      key: 'waybillsNo',
      render: (waybillsNo) => {
        if (!waybillsNo || !waybillsNo.length) {
          return '-';
        }
        const wn = waybillsNo.map((waybill) => {
          return waybill.waybillInfos.map((info) => {
            return <CopyToClipboard message={info.ss} key={info.ss} />;
          });
        });
        return wn;
      },
    },
    {
      title: this.props.t(
        'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.source',
      ),
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: this.props.t(
        'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.warehouse',
      ),
      dataIndex: 'warehouse',
      key: 'warehouse',
    },
    {
      title: this.props.t(
        'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.status',
      ),
      dataIndex: 'status',
      key: 'status',
      render: (status) => getTranslation.operationPurchaseOrderStatus(status),
    },
    {
      title: this.props.t(
        'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.date',
      ),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'detail',
      render: (id) => (
        <Link to={`/stock-operation/warehouse-purchase-order/detail/${id}`}>
          <Button size="small" onClick={() => track(WarehousePurchaseOrderListEvents.WPO_LIST_DETAIL_CLICKED)}>
            {this.props.t(
              'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.detail',
            )}
          </Button>
        </Link>
      ),
    },
  ];

  prepareData = (props) => {
    const { list } = props;
    if (!list || !list.length) {
      return [];
    }
    return list.map((item) => {
      return {
        ...item,
        table: {},
        date: moment(new Date(item.createdAt))
          .local()
          .format(getDateTimeFormatString()),
        source: item.supplier ? item.supplier.name : '-',
        warehouse: (item.warehouse && item.warehouse.name) || '-',
        status: item.status,
        no: item.no,
        id: item.id,
        key: item.id,
      };
    });
  };

  getWaybillNumbers = (record) => {
    if (!record || !record.waybills || !record.waybills.length) {
      return ['-'];
    }
    const waybillNumbers = [];
    record.waybills.forEach((waybill) => {
      waybill.waybillInfos.forEach((info) => {
        waybillNumbers.push(info.ss);
      });
    });
    return waybillNumbers;
  };

  prepareForExport = (rawData) => {
    const data = this.prepareData({ list: rawData });
    const exportData = [];
    data.forEach((item) => {
      const waybillNumbers = this.getWaybillNumbers(item);
      waybillNumbers.forEach((waybillNumber) => {
        exportData.push({
          [this.props.t(
            'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.id',
          )]: item.id,
          [this.props.t(
            'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.no',
          )]: item.no,
          [this.props.t(
            'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.waybillsNo',
          )]: waybillNumber,
          [this.props.t(
            'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.source',
          )]: item.source,
          [this.props.t(
            'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.warehouse',
          )]: item.warehouse,
          [this.props.t(
            'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.status',
          )]: getTranslation.operationPurchaseOrderStatus(item.status),
          [this.props.t(
            'pages.Operation.PurchaseOrder.wpoList.purchaseOrderTable.date',
          )]: item.date,
        });
      });
    });
    return exportData;
  };

  getList = () => {
    const filter = this.getFilter();
    const query = this.getQuery();
    if (
      filter &&
      filter.createdAt &&
      filter.createdAt.startDate &&
      filter.createdAt.endDate
    ) {
      this.props.onGetWarehousePurchaseOrderList(query, filter);
    }
  };

  getFilter = () => {
    const filter = this.state.filter || {};
    if (!filter.createdAt) {
      filter.createdAt = this.defaultDateRange;
    }
    return filter;
  };

  getQuery = () => {
    const { query } = this.state;
    return query;
  };

  setTableFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      };
    }, this.getList);
  };

  clearTableFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      };
    }, this.getList);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  list: state.warehousePurchaseOrder.list.data,
  totalCount: state.warehousePurchaseOrder.list.total,
  loading: state.warehousePurchaseOrder.list.loading,
  isExporting: state.warehousePurchaseOrder.isExporting,
});

const mapDispatchToProps = (dispatch) => ({
  onGetWarehousePurchaseOrderList: (query, filter) => {
    dispatch(
      warehousePurchaseOrderAction.filterWarehousePurchaseOrderList(
        query,
        filter,
      ),
    );
  },
  onExportToXlsxWarehousePurchaseOrder: (
    query,
    filter,
    fileName,
    formatter,
  ) => {
    track(WarehousePurchaseOrderListEvents.WPO_LIST_EXPORT_TO_EXCEL_CLICKED);
    return dispatch(
      warehousePurchaseOrderAction.exportToXlsxWarehousePurchaseOrder(
        query,
        filter,
        fileName,
        formatter,
      ),
    );
  },
});

const WarehousePurchaseOrderListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(WarehousePurchaseOrderList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { WarehousePurchaseOrderListConnected as WarehousePurchaseOrderList };
