import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Icon, Input, Button } from 'antd';
import { Countdown } from './Countdown';
import { authAction } from './../../../actions';
import './OtpModalContent.scss';

class OtpModalContent extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    otpCode: '',
  };

  componentDidMount() {
    this.myRef.current.focus();
  }

  handleChange = (e) => this.setState({ otpCode: e.target.value });

  otpVerify = () => {
    const { username } = this.props;
    const { otpCode } = this.state;
    this.props.onOtpVerify({ username, otpCode });
  };

  otpResend = () => {
    const { username } = this.props;
    this.props.onOtpResend({ username });
  };

  render() {
    return (
      <div className="otp-modal-content">
        <Countdown />
        <div className="information">
          <Icon
            type="phone"
            theme="outlined"
            style={{ fontSize: '24px', marginRight: '10px' }}
          />
          <p className="information-text">
            {this.props.t('main.OtpModalContent.information', {
              maskedGSM: `*****${this.props.maskedGSM || ''}`,
            })}
          </p>
        </div>
        <div className="input-wrapper">
          <Input
            ref={this.myRef}
            placeholder={this.props.t('main.OtpModalContent.enterCode')}
            onChange={this.handleChange}
            value={this.state.otpCode}
            maxLength={6}
            onPressEnter={this.otpVerify}
            className="modal-input"
          />
        </div>
        <div className="button-wrapper">
          <Button
            className="confirm-button"
            loading={this.props.isLoading}
            onClick={this.otpVerify}
          >
            {this.props.t('main.OtpModalContent.confirm')}
          </Button>
        </div>
        <div className="resend-wrapper">
          <p className="message">
            {this.props.t('main.OtpModalContent.didntReceiveCode')}
          </p>
          <Button
            type="link"
            loading={this.props.isResendLoading}
            className="resend-button"
            onClick={this.otpResend}
          >
            {this.props.t('main.OtpModalContent.resendCode')}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ttl: state.auth.ttl,
  maskedGSM: state.auth.maskedGSM,
  username: state.auth.username,
  isError: state.auth.otpVerifyError,
  isLoading: state.auth.isOtpVerifyPending,
  isResendLoading: state.auth.isOtpResendPending,
});

const mapDispatchToProps = (dispatch) => ({
  onOtpVerify: ({ username, otpCode }) =>
    dispatch(
      authAction.otpVerify({
        username,
        otpCode,
      }),
    ),
  onOtpResend: ({ username }) =>
    dispatch(
      authAction.otpResend({
        username,
      }),
    ),
});

const OtpModalContentConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(OtpModalContent));

export { OtpModalContentConnected as OtpModalContent };
