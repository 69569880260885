import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: true,
    data: null,
    error: null,
    count: 0,
  },
};

const switcher = {
  [actionType.getReturnedOrders_InProgress]: getReturnedOrders_InProgress,
  [actionType.getReturnedOrders_Succeeded]: getReturnedOrders_Succeeded,
  [actionType.getReturnedOrders_Failed]: getReturnedOrders_Failed,

  [actionType.getReturnedOrdersCount_InProgress]: getReturnedOrdersCount_InProgress,
  [actionType.getReturnedOrdersCount_Succeeded]: getReturnedOrdersCount_Succeeded,
  [actionType.getReturnedOrdersCount_Failed]: getReturnedOrdersCount_Failed,

  [actionType.completeReturnedOrders_InProgress]: completeReturnedOrders_InProgress,
  [actionType.completeReturnedOrders_Succeeded]: completeReturnedOrders_Succeeded,
  [actionType.completeReturnedOrders_Failed]: completeReturnedOrders_Failed,
};

export const returnedOrdersReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getReturnedOrders_InProgress(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
      count: state.count,
    },
  });
}

function getReturnedOrders_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload,
      count: payload && payload.length,
      error: null,
    },
  });
}

function getReturnedOrders_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      count: state.count,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/
function getReturnedOrdersCount_InProgress(state, payload) {
  return state;
}

function getReturnedOrdersCount_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      ...state.list,
      count: payload,
    },
  });
}

function getReturnedOrdersCount_Failed(state, payload) {
  return state;
}

/* ----------------------------------------------------------------------------
------------------------------------------------------------------------------*/
function completeReturnedOrders_InProgress(state, payload) {
  return Object.assign({}, state, {
    list: {
      ...state.list,
      loading: true,
    },
  });
}

function completeReturnedOrders_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload,
      count: state.count,
      error: null,
    },
  });
}

function completeReturnedOrders_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      ...state.list,
      loading: false,
      error: payload,
    },
  });
}
