import { QueryBuilder } from '../QueryBuilder';
import { createHttpService } from '../createHttpService';

const WAREHOUSE_ID = localStorage.getItem('warehouseId');

const httpService = createHttpService();

export const incomingTransfer = {
  getIncomingTransferList: (query) => httpService.get(`/warehouse/${WAREHOUSE_ID}/transfers/incoming?${QueryBuilder.getQueryStr(query)}`),
  getIncomingTransferDetail: (transferId) => httpService.get(`/warehouse/${WAREHOUSE_ID}/transfers/${transferId}`),
};
