import i18n, { getLangKey } from './../../i18n';
import { getDataByKey } from './../utility';
import { statuses } from '../statuses';
import { smlDescriptions } from '../smlDescriptions';

export class getTranslation {
  static obj(record) {
    let translation = null;
    try {
      translation = record ? record[getLangKey()] : null;
      if (!translation && typeof record === 'string') {
        translation = record;
      }
    } catch (error) {
      console.error(
        '[Translate Error]:',
        'record is',
        record,
        'error is',
        error,
      );
      translation = '-';
    }
    return translation;
  }

  static str(keyString, trems) {
    return getDataByKey(trems, keyString)[getLangKey()];
  }

  static local() {
    return getLangKey();
  }

  static const(constName, key) {
    return i18n.t(statuses[constName][key]);
  }

  static transferStatus(code) {
    return i18n.t(statuses.transferStatus[code]);
  }

  static blockedStockTransferStatus(code) {
    return i18n.t(statuses.blockedStockTransferStatus[code]);
  }

  static transferIntegrationType(code) {
    return i18n.t(statuses.transferIntegrationType[code]);
  }

  static operationPurchaseOrderStatus(code) {
    return i18n.t(statuses.operationPurchaseOrderStatus[code]);
  }

  static purchaseOrderStatus(code) {
    return i18n.t(statuses.purchaseOrderStatus[code]);
  }

  static blockedStockConclusionType(code) {
    return i18n.t(statuses.blockedStockConclusionType[code]);
  }

  static blockedStockReasonOperationType(code) {
    return i18n.t(statuses.blockedStockReasonOperationType[code]);
  }

  static blockedStockStatus(code) {
    return i18n.t(statuses.blockedStockStatus[code]);
  }

  static warehousePurchaseOrderRejectionType(code) {
    return i18n.t(statuses.warehousePurchaseOrderRejectionType[code]);
  }

  static getOrderStatus(status) {
    return i18n.t(statuses.orderStatus[status]);
  }

  static loggerModelType(code) {
    return i18n.t(statuses.loggerModelType[code]);
  }

  static descriptionType(code) {
    return i18n.t(smlDescriptions[code] || code);
  }

  static inventoryCheckType(code) {
    return i18n.t(statuses.inventoryCheckType[code]);
  }

  static inventoryCheckScope(code) {
    return i18n.t(statuses.inventoryCheckScope[code]);
  }

  static inventoryCheckStatus(code) {
    return i18n.t(statuses.inventoryCheckStatus[code]);
  }

  static jobTaskStatus(code) {
    return i18n.t(statuses.jobTaskStatus[code]);
  }

  static inventoryCheckJobType(code) {
    return i18n.t(statuses.jobType[code]);
  }

  static warehouseLocationOperationType(code) {
    return i18n.t(statuses.warehouseLocationOperationType[code]);
  }

  static blockedStockRequestStatus(code) {
    return i18n.t(statuses.blockedStockRequestStatus[code]);
  }

  static pickerStatus(code) {
    return i18n.t(statuses.pickerStatus[code]);
  }

  static pickerJobStatus(code) {
    return i18n.t(statuses.pickerJobStatus[code]);
  }

  static courierStatus(code) {
    return i18n.t(statuses.courierStatus[code]);
  }

  static inventoryCheckReason(code) {
    return i18n.t(statuses.inventoryCheckReasons[code]);
  }

  static removedFromSalesInOrders(code) {
    return i18n.t(statuses.removedFromSalesInOrders[code]);
  }

  static supplierApiStatus(code) {
    return i18n.t(statuses.supplierApiStatus[code]);
  }

  static transactionType(code) {
    return i18n.t(statuses.transactionType[code]);
  }

  static transactionServiceType(code) {
    return i18n.t(statuses.transactionServiceType[code]);
  }

  static transactionOperationType(code) {
    return i18n.t(statuses.transactionOperationType[code]);
  }

  static transactionStatus(code) {
    return i18n.t(statuses.transactionStatus[code]);
  }

  static getWalkInOrderStatus(status) {
    return i18n.t(statuses.walkInOrderStatus[status]);
  }

  static getWalkInOrderCancelReason(reason) {
    return i18n.t(statuses.walkInOrderCancelReason[reason]);
  }

  static getChangeLogErrorCode(reason) {
    return i18n.t(statuses.changeLogErrorCode[reason]);
  }
}
