import { Button, Form, Input, Spin } from 'antd';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';

class EwaybillFormComponent extends Component {
  handleEwaybillSubmit = e => {
    e.preventDefault();
    console.log(this.props.form.getFieldsValue());
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.onFormSubmit(values);
      }
    });
  };

  renderForm = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleEwaybillSubmit}>
        <Form.Item>
          {getFieldDecorator('driverName', {
            rules: [
              {
                required: true,
                message: this.props.t(
                  'pages.Stock.BlockedStock.refund.detail.modal.required',
                ),
              },
              {
                pattern: new RegExp(/^[A-ZŞÇĞÜİÖa-zşçüğöı\s]+$/i),
                message: this.props.t(
                  'pages.Stock.BlockedStock.refund.detail.modal.onlyLetters',
                ),
              }],
          })(
            <Input
              placeholder={this.props.t(
                'pages.Stock.BlockedStock.refund.detail.modal.driverName',
              )}
            />,
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('driverSurname', {
            rules: [
              {
                required: true,
                message: this.props.t(
                  'pages.Stock.BlockedStock.refund.detail.modal.required',
                ),
              },
              {
                pattern: new RegExp(/^[A-ZŞÇĞÜİÖa-zşçüğöı\s]+$/i),
                message: this.props.t(
                  'pages.Stock.BlockedStock.refund.detail.modal.onlyLetters',
                ),
              },
            ],
          })(
            <Input
              placeholder={this.props.t(
                'pages.Stock.BlockedStock.refund.detail.modal.driverSurname',
              )}
            />,
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('nationalityId', {
            rules: [
              { required: true },
              {
                pattern: new RegExp(/^\d+$/),
                message: this.props.t(
                  'pages.Stock.BlockedStock.refund.detail.modal.onlyNumbers',
                ),
              }],
          })(
            <Input
              placeholder={this.props.t(
                'pages.Stock.BlockedStock.refund.detail.modal.NID',
              )}
            />,
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('licensePlate', {
            rules: [
              {
                required: true,
                message: this.props.t(
                  'pages.Stock.BlockedStock.refund.detail.modal.required',
                ),
              },
              {
                max: 9,
                message: this.props.t(
                  'pages.Stock.BlockedStock.refund.detail.modal.plateError',
                ),
              },
              {
                pattern: new RegExp(/^[A-Za-z0-9]*[A-Za-z0-9][A-Za-z0-9]*$/),
                message: this.props.t(
                  'pages.Stock.BlockedStock.refund.detail.modal.onlyNumbers',
                ),
              },
            ],
          })(
            <Input
              placeholder={this.props.t(
                'pages.Stock.BlockedStock.refund.detail.modal.licensePlate',
              )}
            />,
          )}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={this.props.isLoading}>
            {this.props.t(
              'pages.Stock.BlockedStock.refund.detail.modal.continue',
            )}
          </Button>
        </Form.Item>
      </Form>
    );
  }

  renderSuccess = () => {
    const { s3EWaybillUrl } = this.props.data;

    return (
      <div>
        <p>
          {this.props.t(
            'pages.Stock.BlockedStock.refund.detail.modal.ewaybillCreated',
          )}
        </p>
        <a href={s3EWaybillUrl} target="_blank" rel="noopener noreferrer">Preview</a>
      </div>
    );
  }

  render() {
    if (this.props.isLoading) {
      return (
        <Spin tip={this.props.t(
          'pages.Stock.BlockedStock.refund.detail.modal.ewaybillLoading',
        )}
        >
          {this.renderForm()}
        </Spin>
      );
    } else if (this.props.data) {
      return this.renderSuccess();
    } else {
      return (
        this.renderForm()
      );
    }
  }
}

const EwaybillForm = Form.create({ name: 'ewaybillCreate' })(EwaybillFormComponent);
export default withNamespaces('translation')(EwaybillForm);
