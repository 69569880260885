import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';
import { constants } from '../shared';

export const missingProductsOrdersAction = {
  getMissingProductsOrders,
  getMissingProductsOrdersCount,
  removeFromSalesInMissingProductsOrders,
  cancelMissingProducts,
  approveCancelMissingProducts,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getMissingProductsOrders() {
  return (dispatch) => {
    dispatch({
      type: actionType.getMissingProductsOrders_InProgress,
    });
    return http
      .getMissingProductsOrders()
      .then((response) => {
        const { orderMissingProducts } = response.data;
        dispatch({
          type: actionType.getMissingProductsOrders_Succeeded,
          payload: orderMissingProducts,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.getMissingProductsOrders_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getMissingProductsOrders_Failed,
          payload: error,
        });
      });
  };
}

function getMissingProductsOrdersCount(data) {
  return (dispatch) => {
    dispatch({
      type: actionType.getMissingProductsOrdersCount_InProgress,
    });
    return http
      .getMissingProductsOrdersCount(data)
      .then((response) => {
        const { total } = response.data;
        dispatch({
          type: actionType.getMissingProductsOrdersCount_Succeeded,
          payload: total,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionType.getMissingProductsOrdersCount_Failed,
          payload: error,
        });
      });
  };
}

function removeFromSalesInMissingProductsOrders(data) {
  return (dispatch) => {
    dispatch({
      type: actionType.removeFromSalesInMissingProductsOrders_InProgress,
    });
    return http
      .removeFromSalesInMissingProductsOrders(data)
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.removeFromSalesInMissingProductsOrders_Succeeded),
        });
        dispatch({
          type: actionType.removeFromSalesInMissingProductsOrders_Succeeded,
        });
        dispatch(getMissingProductsOrders());
        dispatch(getMissingProductsOrdersCount({
          statuses: [constants.REMOVED_FROM_SALES_IN_ORDERS.CREATED],
        }));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.removeFromSalesInMissingProductsOrders_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.removeFromSalesInMissingProductsOrders_Failed,
          payload: error,
        });
        throw Error;
      });
  };
}

function cancelMissingProducts(data) {
  return (dispatch) => {
    dispatch({
      type: actionType.cancelMissingProducts_InProgress,
    });
    return http
      .cancelMissingProducts(data)
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.cancelMissingProducts_Succeeded),
        });
        dispatch({
          type: actionType.cancelMissingProducts_Succeeded,
        });
        dispatch(getMissingProductsOrders());
        dispatch(getMissingProductsOrdersCount({
          statuses: [constants.REMOVED_FROM_SALES_IN_ORDERS.CREATED],
        }));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.cancelMissingProducts_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.cancelMissingProducts_Failed,
          payload: error,
        });
        throw Error;
      });
  };
}

function approveCancelMissingProducts(data) {
  return (dispatch) => {
    dispatch({
      type: actionType.approveCancelMissingProducts_InProgress,
    });
    return http
      .approveCancelMissingProducts(data)
      .then((response) => {
        dispatch({
          type: actionType.approveCancelMissingProducts_Succeeded,
        });
        return response.data;
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.approveCancelMissingProducts_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.approveCancelMissingProducts_Failed,
          payload: error,
        });
        throw Error;
      });
  };
}
