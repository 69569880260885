import 'react-toastify/dist/ReactToastify.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, Link } from 'react-router-dom';
import shortid from 'shortid';
import { withNamespaces } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import {
  AppHeader,
  AppSidebar,
  AppSidebarForm,
  AppSidebarNav,
} from '@coreui/react';
import { navigation } from './../../navigation';
import { routes } from './../../routes';
import { DefaultHeader } from './DefaultHeader';
import { canAccess } from './../../guard';
import {
  Socket,
  VerifyingSound,
  Timer,
  TerminalAppLink,
} from './../../components';
import { config } from '../../config';
import { getCountryCode } from '../../shared';

class DefaultLayout extends Component {
  render() {
    const { t } = this.props;
    const currentCountryCode = getCountryCode();
    const localizeAndPrefixUrl = (navigationItem) => {
      if (navigationItem.title) {
        return {
          ...navigationItem,
          name: t(navigationItem.name),
        };
      }
      if (navigationItem.children) {
        return {
          ...navigationItem,
          name: t(navigationItem.name),
          children: navigationItem.children.map(localizeAndPrefixUrl),
        };
      }
      if (navigationItem.url.includes('/stock-operation/')) {
        return {
          ...navigationItem,
          name: t(navigationItem.name),
          url: `${navigationItem.url}`,
        };
      }
      return {
        ...navigationItem,
        name: t(navigationItem.name),
        url: `/r/${this.props.CWId}${navigationItem.url}`,
      };
    };

    const guardedNavigationItems = (items) => {
      const { permissions } = this.props;
      return items
        .filter((navItem) => canAccess(navItem, permissions))
        .map((navItem) => {
          if (!navItem.children) {
            return navItem;
          }
          return Object.assign({}, navItem, {
            children: guardedNavigationItems(navItem.children),
          });
        });
    };

    const localizedNavigationItems = guardedNavigationItems(
      navigation.items,
    ).map(localizeAndPrefixUrl);

    const localizedNavigation = {
      ...navigation,
      items: localizedNavigationItems,
    };

    const filteredRoutes = routes
      .filter((route) => canAccess(route, this.props.permissions))
      .map((route) => {
        if (route.path.includes('/stock-operation/')) {
          return {
            ...route,
            path: `${route.path}`,
            name: route.name,
          };
        }
        return {
          ...route,
          path: `/r/:currentWarehouse${route.path}`,
          name: route.name,
        };
      });

    const main = () => (
      <main className="main">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          style={{ zIndex: 1999 }}
        />
        <Switch>
          {filteredRoutes.map((route) =>
            route.component ? (
              <Route
                key={shortid.generate()}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={(propsParam) => <route.component {...propsParam} />}
              />
            ) : null,
          )}
          <Redirect from="/" to={this.props.redirectPath} />
        </Switch>
      </main>
    );

    return (
      <div className={`app ${config.ENV}`}>
        <AppHeader fixed>
          <Timer />
          <Socket />
          <VerifyingSound />
          <DefaultHeader warehouseName={this.props.currentWarehouse.name} />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarForm />
            <AppSidebarNav navConfig={localizedNavigation} {...this.props} />
            {currentCountryCode === 'gb' && (
              <Link
                className="getir-support-zendesk"
                to={{ pathname: 'https://getiruk.zendesk.com/hc/en-us ' }}
                target="_blank"
                style={{ display: 'flex' }}
              >
                <img
                  src="/assets/img/zendesk.png"
                  alt="zendesk-button"
                  style={{ margin: '0 auto' }}
                />
              </Link>
            )}
            <TerminalAppLink />
            <Link
              className="getir-support-link"
              to={{ pathname: 'http://support.getir.com ' }}
              target="_blank"
            >
              support.getir.com
            </Link>
          </AppSidebar>
          {main()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  roles: state.auth.roles,
  permissions: state.auth.user.permissions,
  redirectPath: state.auth.redirectPath,
  currentWarehouse: state.auth.currentWarehouse,
});

const DefaultLayoutConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(DefaultLayout));

export { DefaultLayoutConnected as DefaultLayout };
