export class TransferDataFormatter {
  static getPreparedItems(products) {
    return products.map((p) => ({
      ...p,
      locations: p.locations.map((l) => ({
        ...l,
        productId: p.id,
        productBarcode: p.barcodes[0],
      })),
    }));
  }

  static prepareLocations = ({ locations, isForPrintWaybill }) => locations.map((location) => {
    const l = { ...location };
    return {
      count: l.editedCount,
      ...(
        isForPrintWaybill ? {
          barcode: l.productBarcode,
        } : null
      ),
      ...delete l.productLocationId,
      ...delete l.productId,
      ...delete l.editedCount,
      ...delete l.productBarcode,
      ...l,
    };
  })


  static getProductById(products, item) {
    return products.find((p) => p.id === item).name;
  }

  static preparePrintData(products, selectedItems, fromWarehouse) {
    const preparedItems = this.prepareTransferData(selectedItems, true);
    const preparedItemWithTotalCount = preparedItems.map((item) => {
      // eslint-disable-next-line no-param-reassign
      item.count = item.locations.reduce((total, loc) => {
        // eslint-disable-next-line no-param-reassign
        total += loc.count;
        return total;
      }, 0);
      // eslint-disable-next-line no-param-reassign
      delete item.location;
      return item;
    });

    return preparedItemWithTotalCount.map((item) => ({
      name: this.getProductById(products, item.item),
      count: item.count,
      barcode: item.barcode,
      fromWarehouse,
    }));
  }

  static prepareTransferData(selectedProductWithLocation, isForPrintWaybill = false) {
    const items = [];
    for (const key in selectedProductWithLocation) {
      if (selectedProductWithLocation.hasOwnProperty(key)) {
        const locations = this.prepareLocations({ locations: selectedProductWithLocation[key], isForPrintWaybill });
        let item;
        if (isForPrintWaybill) {
          item = {
            item: key,
            barcode: locations[0].barcode,
            fromWarehouse: locations[0].fromWarehouse,
            locations,
          };
        } else {
          item = {
            item: key,
            locations,
          };
        }

        items.push(item);
      }
    }
    return items;
  }
}
