import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Button, Card } from 'antd';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './TransferBulkApprove.scss';
import { TransferBulkApproveListFilter } from './TransferBulkApproveListFilter';
/*----------------------------------------------------------------------------*/
import { transferAction } from './../../../../actions';
import { Spinner, CopyToClipboard } from './../../../../components';
import { tableHelper, getTranslation, constants, getDateTimeFormatString } from './../../../../shared';
import i18n from './../../../../i18n';
import { pageLoadEvents } from '../../../../services/events';
import { pageView } from '../../../../services/segment';

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class TransferBulkApprove extends Component {
  settings = {
    main: {
      className: 'TransferBulkApprove_main',
    },
    card: {
      title: this.props.t('pages.Operation.Transfer.bulkApprove.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  dateFormat = getDateTimeFormatString();

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_TRANSFER_BULK_APPROVE });
    this.getList();
  }

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      filter: this.getDefaultTableFilter(),
      selectedRowKeys: [],
      selectedTransfers: [],
      failedTransfers: [],
      errorApproveTransfers: [],
    };
  };

  getDefaultTableFilter = () => {
    return {};
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageFilter />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  PageFilter = (props) => (
    <TransferBulkApproveListFilter
      defaultDateRange={this.defaultDateRange}
      onChange={this.setPageFilter}
      onClear={this.clearPageFilter}
    />
  );

  CardExtra = (props) => (
    <Button
      block
      onClick={this.onApproveClick}
      disabled={!this.state.selectedTransfers.length}
      loading={!this.props.approveTransferLoading}
    >
      {this.props.t('pages.Operation.Transfer.bulkApprove.button.approve')}
    </Button>
  );

  onApproveClick = async () => {
    console.log('onApproveClick', this.state);
    const { selectedTransfers } = this.state;
    this.approveTransfer(selectedTransfers)
      .then(() => {
        this.setState({
          selectedTransfers: [],
          selectedRowKeys: [],
          failedTransfers: [],
          errorApproveTransfers: [],
        });

        setTimeout(() => {
          this.getList();
        });
      })
      .catch((err) => {
        const successTransferApprove = err
          .filter((e) => e.status === 'fulfilled' && e.value.data.data._id)
          .map((e) => e.value.data.data._id);
        const errorTransferApprove = selectedTransfers.filter(
          (x) => !successTransferApprove.includes(x),
        );
        this.setState({
          selectedTransfers: [],
          selectedRowKeys: [],
          failedTransfers: [],
          errorApproveTransfers: [],
        });
        setTimeout(() => {
          this.getList();
        });
        if (errorTransferApprove.length) {
          this.setState({ errorApproveTransfers: errorTransferApprove });
        }
      });
  };

  PageTable = () => {
    const { selectedRowKeys, errorApproveTransfers } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleTableChecboxChange,
    };
    return (
      <Table
        rowKey={(record) => record.id}
        dataSource={this.prepareData()}
        columns={this.prepareColumns()}
        size="small"
        rowClassName={(record, index) =>
          errorApproveTransfers.length &&
          errorApproveTransfers.includes(record.id)
            ? 'red-color'
            : ''
        }
        pagination={{ ...this.state.pagination, total: this.props.totalCount }}
        loading={{ indicator: <Spinner />, spinning: this.props.loading }}
        rowSelection={rowSelection}
        onChange={this.handleTableChange}
      />
    );
  };

  prepareColumns = (props) => [
    {
      title: this.props.t('pages.Operation.Transfer.bulkApprove.table.date'),
      dataIndex: 'table.date',
      key: 'table.date',
      width: 185,
      ...tableHelper.fieldSorter.getDateSortProps('table.date'),
    },
    {
      title: this.props.t(
        'pages.Operation.Transfer.bulkApprove.table.fromWarehouse',
      ),
      dataIndex: 'table.fromWarehouse',
      key: 'table.fromWarehouse',
      width: 205,
      render: (fromWarehouse) => <CopyToClipboard message={fromWarehouse} />,
      ...tableHelper.fieldSorter.getStringSortProps('table.fromWarehouse'),
    },
    {
      title: this.props.t(
        'pages.Operation.Transfer.bulkApprove.table.toWarehouse',
      ),
      dataIndex: 'table.toWarehouse',
      key: 'table.toWarehouse',
      width: 205,
      render: (toWarehouse) => <CopyToClipboard message={toWarehouse} />,
      ...tableHelper.fieldSorter.getStringSortProps('table.toWarehouse'),
    },
    {
      title: this.props.t('pages.Operation.Transfer.bulkApprove.table.type'),
      dataIndex: 'table.integrationType',
      key: 'table.integrationType',
      width: 150,
      ...tableHelper.fieldSorter.getNumberSortProps('table.integrationType'),
      render: (type) => getTranslation.transferIntegrationType(type),
    },
    {
      title: this.props.t(
        'pages.Operation.Transfer.bulkApprove.table.transferNumber',
      ),
      dataIndex: 'table.no',
      key: 'table.no',
      render: (no) => <CopyToClipboard message={no} />,
      ...tableHelper.fieldSorter.getStringSortProps('table.no'),
    },
    {
      title: '',
      dataIndex: 'table.id',
      key: 'table.id',
      render: (id) => <this.DetailButton id={id} />,
    },
  ];

  DetailButton = (props) => {
    const { id } = props;
    const url = `/stock-operation/transfer/detail/${id}`;
    return (
      <Link to={url}>
        <Button size="small">
          {this.props.t('pages.Operation.Transfer.bulkApprove.table.detail')}
        </Button>
      </Link>
    );
  };

  prepareData = () => {
    const { transferList } = this.props;
    if (!transferList || !transferList.length) {
      return [];
    }
    return transferList
      .filter((transfer) => transfer.status === 100)
      .map((transfer) => ({
        ...transfer,
        table: {
          id: transfer.id,
          no: transfer.no,
          key: transfer.id,
          status: transfer.status,
          integrationType: transfer.integrationType,
          fromWarehouse:
            (transfer.fromWarehouse && transfer.fromWarehouse.name) || '-',
          toWarehouse:
            (transfer.toWarehouse && transfer.toWarehouse.name) || '-',
          date: moment(new Date(transfer.updatedAt))
            .local()
            .format(this.dateFormat),
        },
      }));
  };

  handleTableChecboxChange = (selectedRowKeys) => {
    this.setState({
      selectedTransfers: selectedRowKeys,
      selectedRowKeys,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState((state) => {
      return {
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      };
    }, this.getList);
  };

  getList = () => {
    const query = this.getQuery();
    const filter = this.getFilter();
    const { CREATED } = constants.TRANSFER_STATUS;
    if (
      filter &&
      filter.createdAt &&
      filter.createdAt.startDate &&
      filter.createdAt.endDate
    ) {
      filter.statuses = [CREATED];
      this.props.onGetTransferBulkApproveList(query, filter);
    }
  };

  getQuery = () => {
    return this.state.query;
  };

  getFilter = () => {
    const filter = this.state.filter || {};
    if (!filter.createdAt) {
      filter.createdAt = this.defaultDateRange;
    }
    return filter;
  };

  setPageFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
        selectedRowKeys: [],
        selectedTransfers: [],
        failedTransfers: [],
        errorApproveTransfers: [],
      };
    }, this.getList);
  };

  clearPageFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
        selectedRowKeys: [],
        selectedTransfers: [],
        failedTransfers: [],
        errorApproveTransfers: [],
      };
    }, this.getList);
  };

  clearState = () => {
    this.setState(this.stateFactory());
  };

  approveTransfer = async (data) => this.props.onApproveTransfer(data);
}

/*------------------------------------------------------------------------------

    ------------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

    ------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  transferList: state.transfer.list.data,
  loading: state.transfer.list.loading,
  approveTransferLoading: state.transfer.approve.loading,
  totalCount: state.transfer.list.total,
});

const mapDispatchToProps = (dispatch) => ({
  onGetTransferBulkApproveList: (query, filter = {}) =>
    dispatch(transferAction.getTransferList(query, filter, true)),
  onApproveTransfer: (data) =>
    dispatch(
      transferAction.approveTransfer({
        data,
        query: {
          limit: 100,
          offset: 0,
        },
        filter: {
          statuses: [100],
        },
        isBulkApprove: true,
        errorMessage: i18n.t(
          'pages.Operation.Transfer.bulkApprove.notification.errorMessageForApproveTransfer',
        ),
      }),
    ),
});

const TransferBulkApproveConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(TransferBulkApprove));

/*------------------------------------------------------------------------------

      ------------------------------------------------------------------------------*/

export { TransferBulkApproveConnected as TransferBulkApprove };
