import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: true,
    data: null,
    error: null,
  },
  busyReasons: {
    loading: true,
    data: null,
    error: null,
  },
  pickerDetail: {
    loading: true,
    data: null,
    error: null,
  },
  pickerJobDetail: {
    loading: false,
    data: null,
    error: null,
  },
  busyPickerList: {},
  freePickerList: {},
  pickerFilteredList: {
    loading: true,
    data: null,
    error: null,
  },
};

const switcher = {
  [actionType.getPickerList_InProgress]: getPickerList_InProgress,
  [actionType.getPickerList_Succeeded]: getPickerList_Succeeded,
  [actionType.getPickerList_Failed]: getPickerList_Failed,

  [actionType.getPickerBusyReasons_InProgress]: getPickerBusyReasons_InProgress,
  [actionType.getPickerBusyReasons_Succeeded]: getPickerBusyReasons_Succeeded,
  [actionType.getPickerBusyReasons_Failed]: getPickerBusyReasons_Failed,

  [actionType.getPickerDetail_InProgress]: getPickerDetail_InProgress,
  [actionType.getPickerDetail_Succeeded]: getPickerDetail_Succeeded,
  [actionType.getPickerDetail_Failed]: getPickerDetail_Failed,

  [actionType.getPickerJobDetail_InProgress]: getPickerJobDetail_InProgress,
  [actionType.getPickerJobDetail_Succeeded]: getPickerJobDetail_Succeeded,
  [actionType.getPickerJobDetail_Failed]: getPickerJobDetail_Failed,

  [actionType.pickerUpdated]: pickerUpdated,

  [actionType.getPickerFilteredList_InProgress]: getPickerFilteredList_InProgress,
  [actionType.getPickerFilteredList_Succeeded]: getPickerFilteredList_Succeeded,
  [actionType.getPickerFilteredList_Failed]: getPickerFilteredList_Failed,
};

export const pickerReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getPickerList_InProgress(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getPickerList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getPickerList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getPickerBusyReasons_InProgress(state, payload) {
  return Object.assign({}, state, {
    busyReasons: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getPickerBusyReasons_Succeeded(state, payload) {
  return Object.assign({}, state, {
    busyReasons: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getPickerBusyReasons_Failed(state, payload) {
  return Object.assign({}, state, {
    busyReasons: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getPickerDetail_InProgress(state, payload) {
  return Object.assign({}, state, {
    pickerDetail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getPickerDetail_Succeeded(state, payload) {
  return Object.assign({}, state, {
    pickerDetail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getPickerDetail_Failed(state, payload) {
  return Object.assign({}, state, {
    pickerDetail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getPickerJobDetail_InProgress(state, payload) {
  return Object.assign({}, state, {
    pickerJobDetail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getPickerJobDetail_Succeeded(state, payload) {
  return Object.assign({}, state, {
    pickerJobDetail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getPickerJobDetail_Failed(state, payload) {
  return Object.assign({}, state, {
    pickerJobDetail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function pickerUpdated(state, { picker }) {
  const pickerList = state.list.data;
  if (!pickerList) {
    return state;
  }
  const updatedList = pickerList.map((item) => {
    const updatedItem = { ...item };
    if (item._id === picker.id) {
      updatedItem.status = picker.status;
    }
    return updatedItem;
  });
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: updatedList,
      error: null,
    },
  });
}

function getPickerFilteredList_InProgress(state, payload) {
  return Object.assign({}, state, {
    pickerFilteredList: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getPickerFilteredList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    pickerFilteredList: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getPickerFilteredList_Failed(state, payload) {
  return Object.assign({}, state, {
    pickerFilteredList: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}
