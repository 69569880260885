import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
  detail: {
    loading: true,
    data: null,
    error: null,
  },
  eWaybill: {
    loading: false,
    data: null,
    error: null,
  },
};

const switcher = {
  [actionType.filterWarehousePurchaseOrderList_InProgress]: filterWarehousePurchaseOrderList_InProgress,
  [actionType.filterWarehousePurchaseOrderList_Succeeded]: filterWarehousePurchaseOrderList_Succeeded,
  [actionType.filterWarehousePurchaseOrderList_Failed]: filterWarehousePurchaseOrderList_Failed,

  [actionType.getWarehousePurchaseOrderDetail_InProgress]: getWarehousePurchaseOrderDetail_InProgress,
  [actionType.getWarehousePurchaseOrderDetail_Succeeded]: getWarehousePurchaseOrderDetail_Succeeded,
  [actionType.getWarehousePurchaseOrderDetail_Failed]: getWarehousePurchaseOrderDetail_Failed,

  [actionType.exportToXlsx_InProgress]: exportToXlsx_InProgress,
  [actionType.exportToXlsx_Succeeded]: exportToXlsx_Succeeded,
  [actionType.exportToXlsx_Failed]: exportToXlsx_Failed,

  [actionType.getWarehousePurchaseOrderEWaybill_InProgress]: getWarehousePurchaseOrderEWaybill_InProgress,
  [actionType.getWarehousePurchaseOrderEWaybill_Succeeded]: getWarehousePurchaseOrderEWaybill_Succeeded,
  [actionType.getWarehousePurchaseOrderEWaybill_Failed]: getWarehousePurchaseOrderEWaybill_Failed,

};

export const warehousePurchaseOrderReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getWarehousePurchaseOrderDetail_InProgress(state) {
  return Object.assign({}, state, {
    detail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getWarehousePurchaseOrderDetail_Succeeded(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getWarehousePurchaseOrderDetail_Failed(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function filterWarehousePurchaseOrderList_InProgress(state) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function filterWarehousePurchaseOrderList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload.data,
      error: null,
      total: payload.total,
    },
  });
}

function filterWarehousePurchaseOrderList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function exportToXlsx_InProgress(state, payload) {
  return Object.assign({}, state, {
    isExporting: true,
  });
}

function exportToXlsx_Succeeded(state, payload) {
  return Object.assign({}, state, {
    isExporting: false,
  });
}

function exportToXlsx_Failed(state, payload) {
  return Object.assign({}, state, {
    isExporting: false,
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getWarehousePurchaseOrderEWaybill_InProgress(state) {
  return Object.assign({}, state, {
    eWaybill: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getWarehousePurchaseOrderEWaybill_Succeeded(state, payload) {
  return Object.assign({}, state, {
    eWaybill: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getWarehousePurchaseOrderEWaybill_Failed(state, payload) {
  return Object.assign({}, state, {
    eWaybill: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}
