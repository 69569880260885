import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: true,
    data: null,
    error: null,
  },
  busyReasons: {
    loading: true,
    data: null,
    error: null,
  },
  busyCourierList: {},
  freeCourierList: {},
};

const switcher = {
  [actionType.getCourierList_Succeeded]: getCourierList_Succeeded,
  [actionType.getCourierList_InProgress]: getCourierList_InProgress,
  [actionType.getCourierList_Failed]: getCourierList_Failed,

  [actionType.getBusyReasons_InProgress]: getBusyReasons_InProgress,
  [actionType.getBusyReasons_Succeeded]: getBusyReasons_Succeeded,
  [actionType.getBusyReasons_Failed]: getBusyReasons_Failed,

  [actionType.makeCourierBusy_InProgress]: makeCourierBusy_InProgress,
  [actionType.makeCourierBusy_Succeeded]: makeCourierBusy_Succeeded,
  [actionType.makeCourierBusy_Failed]: makeCourierBusy_Failed,

  [actionType.makeCourierFree_InProgress]: makeCourierFree_InProgress,
  [actionType.makeCourierFree_Succeeded]: makeCourierFree_Succeeded,
  [actionType.makeCourierFree_Failed]: makeCourierFree_Failed,

  [actionType.courierLocationChanged]: courierLocationChanged,
  [actionType.courierUpdated]: courierUpdated,
};

export const courierReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getCourierList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getCourierList_InProgress(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getCourierList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getBusyReasons_InProgress(state, payload) {
  return Object.assign({}, state, {
    busyReasons: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getBusyReasons_Succeeded(state, payload) {
  return Object.assign({}, state, {
    busyReasons: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getBusyReasons_Failed(state, payload) {
  return Object.assign({}, state, {
    busyReasons: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function makeCourierBusy_InProgress(state, payload) {
  return state;
}

function makeCourierBusy_Succeeded(state, payload) {
  return courierUpdated(state, payload);
}

function makeCourierBusy_Failed(state, payload) {
  return state;
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function makeCourierFree_InProgress(state, payload) {
  return state;
}

function makeCourierFree_Succeeded(state, payload) {
  return courierUpdated(state, payload);
}

function makeCourierFree_Failed(state, payload) {
  return state;
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function courierLocationChanged(state, { courierId, courierLocation }) {
  const courierList = state.list.data;
  if (!courierList) {
    return state;
  }
  const updatedList = courierList.map((item) => {
    const updatedItem = { ...item };
    if (item._id === courierId) {
      updatedItem.location.coordinates = [
        courierLocation.lon,
        courierLocation.lat,
      ];
    }
    return updatedItem;
  });
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: updatedList,
      error: null,
    },
  });
}

function courierUpdated(state, { courier }) {
  const courierList = state.list.data;
  if (!courierList) {
    return state;
  }
  const updatedList = courierList.map((item) => {
    let updatedItem = { ...item };
    if (item._id === courier.id) {
      updatedItem = { ...updatedItem, ...courier };
    }
    return updatedItem;
  });
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: updatedList,
      error: null,
    },
  });
}
