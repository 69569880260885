import React, { Component } from 'react';
import { Select } from 'antd';
// --------------------------------------------------
// --------------------------------------------------
import { withNamespaces } from 'react-i18next';
import { constants, statuses } from '../../../shared';
// --------------------------------------------------

const { Option } = Select;

const pickerStatus = constants.PICKER_STATUS;
const pickerStatusVal = statuses.pickerStatus;

class PickerFilter extends Component {
  constructor() {
    super();
    this.state = {
      selectedItems: [],
    };
    this.filterOptions = [];
    Object.keys(pickerStatus).forEach((key) => {
      const optionKey = pickerStatus[key];
      this.filterOptions.push(optionKey);
    });
  }

  render() {
    const { onChangeFilter } = this.props;
    const { selectedItems } = this.state;
    const filteredOptions = this.filterOptions.filter(
      (option) => !selectedItems.includes(option.toString()),
    );
    return (
      <div className="PickerFilter_inner">
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder={this.props.t('pages.Home.pickerFilter.pleaseSelect')}
          defaultValue={selectedItems}
          onSearch={(value) => {
            if (value === "thanks") {
              // eslint-disable-next-line no-alert
              window.alert('❤️✨ Thanks Yasin Vural ✨❤️');
            }
          }}
          onChange={(value) => {
            this.setState({
              selectedItems: value,
            });
            onChangeFilter(value);
          }}
        >
          {filteredOptions.map((item) => (
            <Option key={item}>{this.props.t(pickerStatusVal[item])}</Option>
          ))}
        </Select>
      </div>
    );
  }
}

const PickerFilterTranslated = withNamespaces('translation')(PickerFilter);

export { PickerFilterTranslated as PickerFilter };
