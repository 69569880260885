export const supportedLanguages = {
  en: { flag: '🇺🇸', text: 'English' },
  'en-US': { flag: '🇺🇸', text: 'English - US' },
  tr: { flag: '🇹🇷', text: 'Türkçe' },
  fr: { flag: '🇫🇷', text: 'Français' },
  de: { flag: '🇩🇪', text: 'Deutsche' },
  nl: { flag: '🇳🇱', text: 'Dutch' },
  es: { flag: '🇪🇸', text: 'Española' },
  it: { flag: '🇮🇹', text: 'Italiana' },
  pt: { flag: '🇵🇹', text: 'Português' },
};
