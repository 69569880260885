import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Table, Button, Dropdown, Icon, Menu } from 'antd';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './InventoryCheckBulkApprove.scss';
import { InventoryCheckBulkApproveFilter } from './InventoryCheckBulkApproveFilter';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard } from './../../../../components';
import { inventoryAction } from './../../../../actions';
import { getTranslation, constants, getDateTimeFormatString } from './../../../../shared';
import i18n from './../../../../i18n';
import { pageLoadEvents } from '../../../../services/events';
import { pageView } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/
const { INVENTORY_CHECK_STATUS } = constants;

class InventoryCheckBulkApprove extends Component {
  settings = {
    main: {
      className: 'InventoryCheckBulkApprove_main',
    },
    card: {
      title: this.props.t('pages.Operation.InventoryCheck.bulkApprove.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_STOCK_BULK_LIST });
    this.getList();
  }

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      tableFilter: this.getDefaultTableFilter(),
      selectedRowKeys: [],
      selectedInventoryChecks: [],
      failedInventoryChecks: [],
      errorOperationInventoryChecks: [],
    };
  };

  getDefaultTableFilter = () => {
    return null;
  };

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  CardExtra = () => {
    return (
      <Dropdown overlay={this.MenuAction}>
        <Button style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.t(
            'pages.Operation.InventoryCheck.bulkApprove.extra.actions',
          )}
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  MenuAction = () => {
    return (
      <Menu>
        <Menu.Item key="ApproveButton">
          <this.ApproveButton />
        </Menu.Item>
        <Menu.Item key="TerminateButton">
          <this.TerminateButton />
        </Menu.Item>
        <Menu.Item key="IncludeToSTS">
          <this.IncludeToSTS />
        </Menu.Item>
        <Menu.Item key="RemoveFromSTS">
          <this.RemoveFromSTS />
        </Menu.Item>
      </Menu>
    );
  };

  isApproveButtonDisabled = () => {
    const { selectedInventoryChecks, tableFilter } = this.state;
    if (
      !selectedInventoryChecks.length ||
      !this.props.operationInventoryCheckLoading
    ) {
      return true;
    }
    if (
      (tableFilter &&
        tableFilter.statuses[0] ===
          constants.INVENTORY_CHECK_STATUS.NEW.toString()) ||
      !tableFilter
    ) {
      return false;
    }
    return true;
  };

  isTerminateButtonDisabled = () => {
    const { selectedInventoryChecks } = this.state;

    if (
      !selectedInventoryChecks.length ||
      !this.props.operationInventoryCheckLoading
    ) {
      return true;
    }
    return false;
  };

  ApproveButton = (props) => {
    return (
      <div style={{ display: 'flex' }}>
        <Button
          block
          onClick={this.onApproveClick}
          disabled={this.isApproveButtonDisabled()}
        >
          {this.props.t(
            'pages.Operation.InventoryCheck.bulkApprove.extra.approve',
          )}
        </Button>
      </div>
    );
  };

  TerminateButton = () => {
    return (
      <Button
        block
        disabled={this.isTerminateButtonDisabled()}
        onClick={this.onTerminateClick}
      >
        {this.props.t(
          'pages.Operation.InventoryCheck.bulkApprove.extra.terminateButton',
        )}
      </Button>
    );
  };

  isIncludeToSTSButtonDisabled = () => {
    const { tableFilter = {} } = this.state;
    if (!this.state.selectedInventoryChecks.length) {
      return true;
    }
    if (tableFilter) {
      const { isIncludedToSTS, isIncludedToSTSChecked } = tableFilter;
      return (
        !isIncludedToSTSChecked || (isIncludedToSTSChecked && isIncludedToSTS)
      );
    }
    return true;
  };

  isRemoveFromSTSButtonDisabled = () => {
    const { tableFilter = {} } = this.state;
    if (!this.state.selectedInventoryChecks.length) {
      return true;
    }
    if (tableFilter) {
      const { isIncludedToSTS, isIncludedToSTSChecked } = tableFilter;
      return (
        !isIncludedToSTSChecked || (isIncludedToSTSChecked && !isIncludedToSTS)
      );
    }
    return true;
  };

  IncludeToSTS = () => {
    return (
      <div style={{ display: 'flex' }}>
        <Button
          block
          onClick={this.onIncludeToSTSClick}
          disabled={this.isIncludeToSTSButtonDisabled()}
        >
          {this.props.t(
            'pages.Operation.InventoryCheck.bulkApprove.extra.includeToSTS',
          )}
        </Button>
      </div>
    );
  };

  RemoveFromSTS = () => {
    return (
      <Button
        block
        disabled={this.isRemoveFromSTSButtonDisabled()}
        onClick={this.onRemoveFromSTSClick}
      >
        {this.props.t(
          'pages.Operation.InventoryCheck.bulkApprove.extra.removeFromSTS',
        )}
      </Button>
    );
  };

  onApproveClick = async () => {
    console.log('onApproveClick', this.state);
    const { selectedInventoryChecks } = this.state;
    this.approveInventoryCheck({
      InventoryCheckIds: selectedInventoryChecks,
      inventoryCheckList: this.props.inventoryCheckList,
    })
      .then(() => {
        this.clearState();
        setTimeout(() => {
          this.getList();
        });
      })
      .catch((response) => {
        this.clearState();
        setTimeout(() => {
          this.getList();
        });
        if (
          response &&
          response.data &&
          response.data.cannotBeApproveInventoryCheckIds.length
        ) {
          this.setState({
            errorOperationInventoryChecks:
              response.data.cannotBeApproveInventoryCheckIds,
          });
        }
      });
  };

  onTerminateClick = async () => {
    console.log('onTerminateClick', this.state);
    const { selectedInventoryChecks } = this.state;
    this.terminateInventoryCheck(selectedInventoryChecks)
      .then(() => {
        this.clearState();
        setTimeout(() => {
          this.getList();
        });
      })
      .catch((response) => {
        this.clearState();
        setTimeout(() => {
          this.getList();
        });
        if (
          response &&
          response.data &&
          response.data.inventoryChecksThatCannotBeCanceledIds.length
        ) {
          this.setState({
            errorOperationInventoryChecks:
              response.data.inventoryChecksThatCannotBeCanceledIds,
          });
        }
      });
  };

  onIncludeToSTSClick = async () => {
    console.log('onIncludeToSTSClick', this.state);
    const { selectedInventoryChecks } = this.state;
    this.includeInventoryChecksToSTS({
      inventoryCheckIds: selectedInventoryChecks,
      field: 'isIncludedToSTS',
      newValue: true,
    })
      .then(() => {
        this.clearState();
        setTimeout(() => {
          this.getList();
        });
      })
      .catch((response) => {
        this.clearState();
        setTimeout(() => {
          this.getList();
        });

        this.setState({
          errorOperationInventoryChecks: selectedInventoryChecks,
        });
      });
  };

  onRemoveFromSTSClick = async () => {
    console.log('onRemoveFromSTSClick', this.state);
    const { selectedInventoryChecks } = this.state;
    this.removeInventoryChecksFromSTS({
      inventoryCheckIds: selectedInventoryChecks,
      field: 'isIncludedToSTS',
      newValue: false,
    })
      .then(() => {
        this.clearState();
        setTimeout(() => {
          this.getList();
        });
      })
      .catch((response) => {
        this.clearState();
        setTimeout(() => {
          this.getList();
        });

        this.setState({
          errorOperationInventoryChecks: selectedInventoryChecks,
        });
      });
  };

  PageTable = (props) => {
    const { selectedRowKeys, errorOperationInventoryChecks } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleTableChecboxChange,
    };
    return (
      <div>
        <Table
          size="small"
          dataSource={this.prepareData(this.props)}
          columns={this.prepareColumns(this.props)}
          rowKey={(record) => {
            return record.id;
          }}
          pagination={{
            ...this.state.pagination,
            total: this.props.totalCount,
          }}
          rowClassName={(record, index) => {
            return errorOperationInventoryChecks.length &&
              errorOperationInventoryChecks.includes(record.id)
              ? 'red-color'
              : '';
          }}
          rowSelection={rowSelection}
          loading={{ indicator: <Spinner />, spinning: this.props.loading }}
          onChange={this.handleTableChange}
        />
      </div>
    );
  };

  handleTableChecboxChange = (selectedRowKeys) => {
    this.setState({
      selectedInventoryChecks: selectedRowKeys,
      selectedRowKeys,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState((state) => {
      return {
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      };
    }, this.getList);
  };

  prepareColumns = (props) => {
    return [
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.bulkApprove.inventoryCheckTable._id',
        ),
        dataIndex: 'id',
        key: 'id',
        render: (id) => (
          <CopyToClipboard message={id} inner={<Icon type="copy" />} />
        ),
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.bulkApprove.inventoryCheckTable.warehouse',
        ),
        dataIndex: 'warehouse',
        key: 'warehouse',
        render: (warehouse) => {
          return warehouse ? (
            <CopyToClipboard
              inner={warehouse.name || warehouse}
              message={warehouse.name || warehouse}
            />
          ) : (
            '-'
          );
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.bulkApprove.inventoryCheckTable.status',
        ),
        dataIndex: 'status',
        key: 'status',
        render: (code) => {
          return getTranslation.inventoryCheckStatus(code);
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.bulkApprove.inventoryCheckTable.type',
        ),
        dataIndex: 'type',
        key: 'type',
        render: (code) => {
          return getTranslation.inventoryCheckType(code);
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.bulkApprove.inventoryCheckTable.inventoryCheckType',
        ),
        dataIndex: 'isAutoInventoryCheck',
        key: 'isAutoInventoryCheck',
        render: (isAutoInventoryCheck) => {
          if (!isAutoInventoryCheck) {
            return this.props.t(
              'pages.Operation.InventoryCheck.bulkApprove.other',
            );
          }
          return this.props.t(
            'pages.Operation.InventoryCheck.bulkApprove.inventoryCheckTable.autoInventoryCheck',
          );
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.bulkApprove.inventoryCheckTable.owner',
        ),
        dataIndex: 'job',
        key: 'job',
        render: (job) => {
          return job ? (
            <CopyToClipboard message={job} inner={<Icon type="copy" />} />
          ) : (
            '-'
          );
        },
      },
      {
        title: this.props.t(
          'pages.Operation.InventoryCheck.bulkApprove.inventoryCheckTable.createdAt',
        ),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) => {
          return moment(new Date(createdAt))
            .local()
            .format(getDateTimeFormatString());
        },
      },
      {
        key: 'detail',
        dataIndex: 'id',
        render: (id) => <this.DetailInventoryCheckButton id={id} />,
      },
    ];
  };

  DetailInventoryCheckButton = (props) => {
    const url = `/stock-operation/inventory-check/detail/${props.id}`;
    return (
      <Link to={url} target="_blank">
        <Button size="small">
          {this.props.t(
            'pages.Operation.InventoryCheck.bulkApprove.detailButton',
          )}
        </Button>
      </Link>
    );
  };

  prepareData = (props) => {
    const { inventoryCheckList } = props;
    if (!inventoryCheckList || !inventoryCheckList.length) {
      return null;
    }
    return inventoryCheckList;
  };

  getList = () => {
    const { query } = this.state;
    const allFilter = this.getFilter();
    const filter = { ...allFilter, isIncludedToSTSChecked: undefined };
    this.props.onGetInventoryCheckList(filter, query);
  };

  getFilter = () => {
    const { tableFilter } = this.state;
    const filter = { statuses: [INVENTORY_CHECK_STATUS.NEW], ...tableFilter };

    if (
      !filter.createdAt ||
      !filter.createdAt.startDate ||
      !filter.createdAt.endDate
    ) {
      filter.createdAt = {
        ...this.defaultDateRange,
      };
    }
    return filter;
  };

  setTableFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        tableFilter: filter,
        selectedRowKeys: [],
        selectedInventoryChecks: [],
        failedInventoryChecks: [],
        errorOperationInventoryChecks: [],
      };
    }, this.getList);
  };

  clearTableFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        tableFilter: filter,
        selectedRowKeys: [],
        selectedInventoryChecks: [],
        failedInventoryChecks: [],
        errorOperationInventoryChecks: [],
      };
    }, this.getList);
  };

  clearState = () => {
    this.setState((state) => {
      return { ...this.stateFactory(), tableFilter: state.tableFilter };
    });
  };

  approveInventoryCheck = async (data) =>
    this.props.onApproveInventoryCheck(data);

  terminateInventoryCheck = async (data) =>
    this.props.onTerminateInventoryCheck(data);

  includeInventoryChecksToSTS = async (data) =>
    this.props.onIncludeInventoryChecksToSTS(data);

  removeInventoryChecksFromSTS = async (data) =>
    this.props.onRemoveInventoryChecksFromSTS(data);

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <InventoryCheckBulkApproveFilter
            onChange={this.setTableFilter}
            onClear={this.clearTableFilter}
            defaultDateRange={this.defaultDateRange}
          />
          <this.PageTable />
        </Card>
      </div>
    );
  }
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.inventory.inventoryCheckList.loading,
  inventoryCheckList: state.inventory.inventoryCheckList.data,
  totalCount: state.inventory.inventoryCheckList.total,
  operationInventoryCheckLoading: state.inventory.operation.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onGetInventoryCheckList: (config, query) => {
    return dispatch(
      inventoryAction.getInventoryCheckList(
        { ...config, populate: ['warehouses', 'products', 'locations'] },
        query,
      ),
    );
  },
  onApproveInventoryCheck: (data) => {
    return dispatch(
      inventoryAction.bulkApproveInventoryCheck({
        data,
        errorMessage: i18n.t(
          'pages.Operation.InventoryCheck.bulkApprove.notification.errorMessageForApproveInventoryCheck',
        ),
      }),
    );
  },
  onTerminateInventoryCheck: (data) => {
    return dispatch(
      inventoryAction.bulkTerminateInventoryCheck({
        data,
        errorMessage: i18n.t(
          'pages.Operation.InventoryCheck.bulkApprove.notification.errorMessageForTerminateInventoryCheck',
        ),
      }),
    );
  },
  onIncludeInventoryChecksToSTS: (data) => {
    return dispatch(
      inventoryAction.bulkIncludeInventoryChecksToSTS({
        data,
      }),
    );
  },
  onRemoveInventoryChecksFromSTS: (data) => {
    return dispatch(
      inventoryAction.bulkRemoveInventoryChecksFromSTS({
        data,
      }),
    );
  },
});

const InventoryCheckBulkApproveConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(InventoryCheckBulkApprove));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { InventoryCheckBulkApproveConnected as InventoryCheckBulkApprove };
