import React, { Component } from 'react';
import { Card, Popover, Tag } from 'antd';
import classNames from 'classnames';
import { uniq } from 'lodash';
// --------------------------------------------------
import './OrderCard.scss';
// --------------------------------------------------
import { withNamespaces } from 'react-i18next';
import courierMarker from './../../../assets/markers/courier.png';
import clientMarker from './../../../assets/markers/client.png';
import pickerMarker from './../../../assets/markers/picker.png';
import { getLangKey } from './../../../i18n';
import { constants, msToTime, getCountryCode } from './../../../shared';
import { track } from '../../../services/segment';
// --------------------------------------------------

const { ORDER_STATUS } = constants;

class OrderCard extends Component {
  EST = 0;

  constructor(props) {
    super(props);
    this.EST = this.getEstimateOrderTime();
  }

  render() {
    const { order } = this.props;
    if (!order) {
      return null;
    }
    const order_cssClass = classNames({
      'order-card': true,
      waitingForPicker: order.status === ORDER_STATUS.WAITING_FOR_PICKER,
      verifying: order.status === ORDER_STATUS.VERIFYING,
      preparing: order.status === ORDER_STATUS.PREPARING,
      prepared: order.status === ORDER_STATUS.PREPARED,
      handover: order.status === ORDER_STATUS.HANDOVER,
      onway: order.status === ORDER_STATUS.ONWAY,
      reached: order.status === ORDER_STATUS.REACHED,
    });
    return (
      <div className="OrderCard_inner">
        <Card
          className={order_cssClass}
          onClick={() => {
            track('HOME_ORDERS_DETAIL_BOX_CLICKED');
            this.props.openOrderDetail(order);
          }}
        >
          <div className="line" style={{ justifyContent: 'flex-start' }}>
            <this.OrderTimer />
          </div>

          <div className="line">
            <Popover
              content={this.props.t('pages.Home.orderCard.clientName')}
              trigger="hover"
              placement="right"
            >
              <img
                className="marker_img"
                src={clientMarker}
                alt="client marker"
              />
              <span className="client-name">{order.clientName || '-'}</span>
            </Popover>
          </div>
          <div className="line">
            <Popover
              content={this.props.t('pages.Home.orderCard.courierName')}
              trigger="hover"
              placement="right"
            >
              <img
                className="marker_img"
                src={courierMarker}
                alt="courier marker"
              />
              <span className="courier-name">{order.courier.name || '-'}</span>
              {order.isInQueue && (
                <span>
                  {`(${this.props.t('pages.Home.orderCard.inQueue')})`}
                </span>
              )}
            </Popover>
          </div>
          <div className="line">
            <Popover
              content={this.props.t('pages.Home.orderCard.pickerName')}
              trigger="hover"
              placement="right"
            >
              <img
                className="marker_img"
                src={pickerMarker}
                alt="picker marker"
              />
              <span className="picker-name">{order.picker.name || '-'}</span>
            </Popover>
          </div>
          {order.isBatchedOrder && (
            <div className="line batched">
              <div className="batched_badge">
                {this.props.t('pages.Home.orderCard.batchedOrder')}
              </div>
            </div>
          )}
          {order.productLocations && order.productLocations.length
            ? order.productLocations.map((productLocation) => (
                <div className="line location" key={productLocation.location}>
                  <Tag className="tag">
                    <span className="tag_productCount">
                      {productLocation.productCount}
                    </span>
                    {productLocation.locationBarcode}
                  </Tag>
                </div>
              ))
            : null}
        </Card>
      </div>
    );
  }

  OrderTimer = () => {
    const { timer } = this.props;

    const phaseStartDate = this.getPhaseStartDate();

    if (!phaseStartDate) {
      return null;
    }

    const progress = this.getProgress(timer, phaseStartDate);
    const timerProgressBar_cssClass = this.getTimerBackground(progress);

    return (
      <div style={{ padding: 2, fontSize: 12, flex: 1 }}>
        <div className="progress" style={{ height: 20, width: '100%' }}>
          <div
            className={timerProgressBar_cssClass}
            role="progressbar"
            style={{ width: `${progress}%` }}
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div style={{ color: 'black', marginLeft: '5px' }}>
              {this.getTimeString(timer, phaseStartDate)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  getTimerBackground = (progress) => {
    // if there is one more conditional change requested, refactor
    const { order } = this.props;
    const { VERIFYING, PREPARING, PREPARED, HANDOVER } = ORDER_STATUS;
    const country = getCountryCode();

    if (country === 'gb') {
      if ([VERIFYING, PREPARING].includes(order.status)) {
        return classNames({
          'progress-bar': true,
          'bg-success timer-1step': progress <= 90,
          'bg-info timer-2step': progress > 90 && progress <= 115,
          'bg-warning timer-3step': progress > 115 && progress <= 130,
          'bg-danger timer-4step': progress > 130 && progress <= 150,
          'bg-very-danger timer-5step': progress > 150,
        });
      }
      if ([PREPARED, HANDOVER].includes(order.status)) {
        return classNames({
          'progress-bar': true,
          'bg-success timer-1step': progress <= 33,
          'bg-warning timer-3step': progress > 33 && progress <= 100,
          'bg-danger timer-4step': progress > 100,
        });
      }
    }
    if (country === 'tr') {
      if ([VERIFYING, PREPARING].includes(order.status)) {
        return classNames({
          'progress-bar': true,
          'bg-success timer-1step': progress <= 50,
          'bg-info timer-2step': progress > 50 && progress <= 70,
          'bg-warning timer-3step': progress > 70 && progress <= 130,
          'bg-danger timer-4step': progress > 130 && progress <= 150,
          'bg-very-danger timer-5step': progress > 150,
        });
      }
      if ([PREPARED, HANDOVER].includes(order.status)) {
        return classNames({
          'progress-bar': true,
          'bg-success timer-1step': progress <= 30,
          'bg-warning timer-3step': progress > 30 && progress <= 110,
          'bg-danger timer-4step': progress > 110,
        });
      }
    }

    if ([VERIFYING, PREPARING].includes(order.status)) {
      return classNames({
        'progress-bar': true,
        'bg-success timer-1step': progress <= 50,
        'bg-info timer-2step': progress > 50 && progress <= 70,
        'bg-warning timer-3step': progress > 70 && progress <= 130,
        'bg-danger timer-4step': progress > 130 && progress <= 150,
        'bg-very-danger timer-5step': progress > 150,
      });
    }
    if ([PREPARED, HANDOVER].includes(order.status)) {
      return classNames({
        'progress-bar': true,
        'bg-success timer-1step': progress <= 33,
        'bg-warning timer-3step': progress > 33 && progress <= 100,
        'bg-danger timer-4step': progress > 100,
      });
    }
  };

  getPhaseStartDate = () => {
    const { order } = this.props;
    const { VERIFYING, PREPARING, PREPARED, HANDOVER } = ORDER_STATUS;
    let phaseStartDate = null;

    if ([VERIFYING, PREPARING].includes(order.status)) {
      phaseStartDate = new Date(order.pickerAssignDate).getTime();
    } else if (
      [PREPARED, HANDOVER].includes(order.status) &&
      order.courier.name
    ) {
      const assignDate = new Date(order.assignDate).getTime();
      const prepareDate = new Date(order.prepareDate).getTime();
      phaseStartDate = Math.max(assignDate, prepareDate);
    }
    return phaseStartDate;
  };

  getTimeString = (timer, timeStart) => {
    let timerString = null;
    try {
      timerString = msToTime(timer - timeStart + 1000, getLangKey());
    } catch (error) {
      timerString = null;
    }
    return timerString;
  };

  getProgress = (timer, timeStart) => {
    const { order } = this.props;
    const { VERIFYING, PREPARING, PREPARED, HANDOVER } = ORDER_STATUS;
    let estimate = this.EST;

    if ([VERIFYING, PREPARING].includes(order.status)) {
      estimate = this.EST;
    } else if ([PREPARED, HANDOVER].includes(order.status)) {
      estimate = 90 * 1000;
    }

    const passed = timer - timeStart + 1000;
    const percent = (passed / estimate) * 100;
    return percent;
  };

  getEstimateOrderTime = () => {
    const { order } = this.props;
    const country = getCountryCode();

    let basis = 75;

    let a = 3;

    let b = 4;

    let c = 5;

    let d = 6;
    if (country === 'gb') {
      basis = 20;
      a = 2.3;
      b = 3;
      c = 3.5;
      d = 3.8;
    } else {
      if (order.domainType === 1) {
        basis = 50;
        a = 2;
        b = 3;
        c = 4;
        d = 5;
      }
      if (order.domainType === 3) {
        basis = 75;
        a = 3;
        b = 4;
        c = 5;
        d = 6;
      }
    }
    const EST =
      basis +
      a * this.getTotalItemCount() +
      b * this.getUniqueItemCount() +
      c * this.getSubcategoryCount() +
      d * this.getCategoryCount();

    return EST * 1000;
  };

  getTotalItemCount = () => {
    const {
      order: { products },
    } = this.props;

    if (!products || !products.length) {
      return 0;
    }

    return products.reduce((acc, item) => {
      // eslint-disable-next-line no-param-reassign
      acc += item.orderCount || item.count;
      return acc;
    }, 0);
  };

  getUniqueItemCount = () => {
    const {
      order: { products },
    } = this.props;

    if (!products || !products.length) {
      return 0;
    }

    return products.length;
  };

  getSubcategoryCount = () => {
    const {
      order: { products },
    } = this.props;

    if (!products || !products.length) {
      return 0;
    }

    return uniq(
      products.map((item) => item.masterSubCategory).filter((item) => item),
    ).length;
  };

  getCategoryCount = () => {
    const {
      order: { products },
    } = this.props;

    if (!products || !products.length) {
      return 0;
    }
    const categories = products
      .map((item) => item.masterCategory)
      .filter((item) => item);

    return uniq(categories).length;
  };
}

const OrderCardTranslated = withNamespaces('translation')(OrderCard);

export { OrderCardTranslated as OrderCard };
