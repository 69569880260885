import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';

export const heatingEstimationAction = {
  getHeatingEstimationList,
};

function getHeatingEstimationList() {
  return (dispatch) => {
    dispatch({
      type: actionType.getHeatingEstimationList_InProgress,
    });
    http
      .getHeatingEstimationList()
      .then((res) => {
        const response = res.data.data;
        dispatch({
          type: actionType.getHeatingEstimationList_Succeeded,
          payload: response,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getHeatingEstimationList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getHeatingEstimationList_Failed,
          payload: error,
        });
      });
  };
}
