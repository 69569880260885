import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Table, Button, Icon } from 'antd';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './InventoryCheckList.scss';
import { InventoryCheckFilter } from './InventoryCheckFilter';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard } from './../../../../components';
import { inventoryAction } from './../../../../actions';
import { getTranslation, getDateTimeFormatString } from './../../../../shared';
import { pageLoadEvents } from '../../../../services/events';
import { pageView } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class InventoryCheckList extends Component {
  settings = {
    main: {
      className: 'InventoryCheckList_main',
    },
    card: {
      title: this.props.t('pages.Stock.InventoryCheck.list.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.STOCK_STOCK_CONTROL });
    this.getList();
  }

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      tableFilter: this.getDefaultTableFilter(),
    };
  };

  getDefaultTableFilter = () => null;

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <InventoryCheckFilter
            onChange={this.setTableFilter}
            onClear={this.clearTableFilter}
            defaultDateRange={this.defaultDateRange}
          />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    const settings = {
      style: { display: 'flex' },
      link: {
        to: `/r/${this.props.CWId}/stock/inventory-check/create`,
      },
      button: {
        type: 'danger',
        style: { marginLeft: 10 },
      },
    };
    return (
      <div style={settings.style}>
        <Link {...settings.link}>
          <Button {...settings.button}>
            {this.props.t(
              'pages.Stock.InventoryCheck.list.extra.createNewButton',
            )}
          </Button>
        </Link>
      </div>
    );
  };

  PageTable = (props) => (
    <div>
      <Table
        size="small"
        dataSource={this.prepareData(this.props)}
        columns={this.prepareColumns(this.props)}
        rowKey={(record) => record.id}
        // expandedRowRender={this.expandedRowRender}
        pagination={{
          ...this.state.pagination,
          total: this.props.totalCount,
        }}
        loading={{ indicator: <Spinner />, spinning: this.props.loading }}
        onChange={this.handleTableChange}
      />
    </div>
  );

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      query: {
        limit: pagination.pageSize,
        offset: pagination.pageSize * (pagination.current - 1),
      },
    });
    this.setState({
      pagination: {
        ...this.state.pagination,
        ...pagination,
      },
    });
    setTimeout(() => {
      this.getList();
    });
  };

  prepareColumns = (props) => [
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.list.inventoryCheckTable._id',
      ),
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <CopyToClipboard message={id} inner={<Icon type="copy" />} />
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.list.inventoryCheckTable.warehouse',
      ),
      dataIndex: 'warehouse',
      key: 'warehouse',
      render: (warehouse) =>
        warehouse ? (
          <CopyToClipboard
            inner={warehouse.name || warehouse}
            message={warehouse._id || warehouse.id}
          />
        ) : (
          '-'
        ),
    },
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.list.inventoryCheckTable.status',
      ),
      dataIndex: 'status',
      key: 'status',
      render: (code) => getTranslation.inventoryCheckStatus(code),
    },
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.list.inventoryCheckTable.type',
      ),
      dataIndex: 'type',
      key: 'type',
      render: (code) => getTranslation.inventoryCheckType(code),
    },
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.list.inventoryCheckTable.inventoryCheckType',
      ),
      dataIndex: 'isAutoInventoryCheck',
      key: 'isAutoInventoryCheck',
      render: (isAutoInventoryCheck) => {
        if (!isAutoInventoryCheck) {
          return this.props.t('pages.Stock.InventoryCheck.list.other');
        }
        return this.props.t(
          'pages.Stock.InventoryCheck.list.inventoryCheckTable.autoInventoryCheck',
        );
      },
    },
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.list.inventoryCheckTable.owner',
      ),
      dataIndex: 'job',
      key: 'job',
      render: (job) =>
        job ? (
          <CopyToClipboard message={job} inner={<Icon type="copy" />} />
        ) : (
          '-'
        ),
    },
    {
      title: this.props.t(
        'pages.Stock.InventoryCheck.list.inventoryCheckTable.createdAt',
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) =>
        moment(new Date(createdAt))
          .local()
          .format(getDateTimeFormatString()),
    },
    {
      key: 'detail',
      dataIndex: 'id',
      render: (id) => <this.DetailInventoryCheckButton id={id} />,
    },
  ];

  DetailInventoryCheckButton = (props) => {
    const url = `/r/${this.props.CWId}/stock/inventory-check/detail/${props.id}`;
    return (
      <Link to={url} target="_blank">
        <Button size="small">
          {this.props.t('pages.Stock.InventoryCheck.list.detailButton')}
        </Button>
      </Link>
    );
  };

  prepareData = (props) => {
    const { inventoryCheckList } = props;
    if (!inventoryCheckList || !inventoryCheckList.length) {
      return null;
    }
    return inventoryCheckList;
  };

  getList = () => {
    const { query } = this.state;
    const filter = this.getFilter();
    this.props.onGetInventoryCheckList(filter, query);
  };

  getFilter = () => {
    const { tableFilter } = this.state;
    let filter = {};
    if (tableFilter) {
      filter = { ...filter, ...tableFilter };
    }
    if (
      !filter.createdAt ||
      !filter.createdAt.startDate ||
      !filter.createdAt.endDate
    ) {
      filter.createdAt = {
        ...this.defaultDateRange,
      };
    }
    if (!filter.warehouseIds) {
      filter.warehouseIds = [this.props.CWId];
    }
    console.log('filter', filter);
    return filter;
  };

  setTableFilter = (filter) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        current: 1,
      },
    });
    this.setState({
      query: {
        ...this.state.query,
        offset: 0,
      },
    });
    this.setState({
      tableFilter: filter,
    });
    setTimeout(() => {
      this.getList();
    });
  };

  clearTableFilter = () => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        current: 1,
      },
    });
    this.setState({
      query: {
        ...this.state.query,
        offset: 0,
      },
    });
    this.setState({
      tableFilter: this.getDefaultTableFilter(),
    });
    setTimeout(() => {
      this.getList();
    });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  currentWarehouse: state.auth.currentWarehouse,
  loading: state.inventory.inventoryCheckList.loading,
  inventoryCheckList: state.inventory.inventoryCheckList.data,
  totalCount: state.inventory.inventoryCheckList.total,
});

const mapDispatchToProps = (dispatch) => ({
  onGetInventoryCheckList: (config, query) => {
    dispatch(
      inventoryAction.getInventoryCheckList(
        { ...config, populate: ['warehouses', 'products', 'locations'] },
        query,
      ),
    );
  },
});

const InventoryCheckListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(InventoryCheckList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { InventoryCheckListConnected as InventoryCheckList };
