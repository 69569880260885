import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import { LocalDatabase } from './shared';
import translationEN from './locales/en/translation.json';
import translationTR from './locales/tr/translation.json';
import translationNL from './locales/nl/translation.json';
import translationFR from './locales/fr/translation.json';
import translationDE from './locales/de/translation.json';
import translationES from './locales/es/translation.json';
import translationIT from './locales/it/translation.json';
import translationPT from './locales/pt/translation.json';
import translationENUS from './locales/en_US/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  tr: {
    translation: translationTR,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  nl: {
    translation: translationNL,
  },
  es: {
    translation: translationES,
  },
  it: {
    translation: translationIT,
  },
  pt: {
    translation: translationPT,
  },
  'en-US': {
    translation: translationENUS,
  },
};

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // // pass the i18n instance to the react-i18next components.
  // // Alternative use the I18nextProvider: https://react.i18next.com/components/i18nextprovider
  .use(reactI18nextModule)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    debug: true, // change this to true for debug console logs
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ['translation'],
    returnEmptyString: false,
    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
    react: {
      wait: true,
    },
  });

export const changeLanguage = (language) => {
  LocalDatabase.clear().finally(() => i18n.changeLanguage(language));
};

export const getLangKey = () => {
  if (!i18n.language) {
    return;
  }
  const langKey = i18n.language;
  return langKey;
};

export default i18n;
