import React from 'react';
import Loadable from 'react-loadable';
import i18n from './i18n';

function Loading() {
  return <div>{i18n.t('WAITING')}</div>;
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

// Dashboard

const DashboardOrders = Loadable({
  loader: () => import('./pages/Home'),
  loading: Loading,
  render(loaded) {
    const Component = loaded.Home;
    return <Component activeTab="orders" />;
  },
});

const DashboardMap = Loadable({
  loader: () => import('./pages/Home'),
  loading: Loading,
  render(loaded) {
    const Component = loaded.Home;
    return <Component activeTab="map" />;
  },
});

const DashboardHeatingEstimation = Loadable({
  loader: () => import('./pages/Home'),
  loading: Loading,
  render(loaded) {
    const Component = loaded.Home;
    return <Component activeTab="heatingEstimation" />;
  },
});

const DashboardCancelledOrders = Loadable({
  loader: () => import('./pages/Home'),
  loading: Loading,
  render(loaded) {
    const Component = loaded.Home;
    return <Component activeTab="cancelledOrders" />;
  },
});

const DashboardReturnedOrders = Loadable({
  loader: () => import('./pages/Home'),
  loading: Loading,
  render(loaded) {
    const Component = loaded.Home;
    return <Component activeTab="returnedOrders" />;
  },
});
const DashboardMissingProductsOrders = Loadable({
  loader: () => import('./pages/Home'),
  loading: Loading,
  render(loaded) {
    const Component = loaded.Home;
    return <Component activeTab="missingProductsOrders" />;
  },
});
// retailWalkInOrders

const RetailWalkInOrdersList = Loadable({
  loader: () => import('./pages/Stock/RetailWalkInOrders'),
  render(loaded) {
    const Component = loaded.RetailWalkInOrders;
    return <Component />;
  },
  loading: Loading,
});

const RetailWalkInOrdersRefundList = Loadable({
  loader: () => import('./pages/Stock/RetailWalkInOrdersRefund'),
  render(loaded) {
    const Component = loaded.RetailWalkInOrdersRefund;
    return <Component />;
  },
  loading: Loading,
});

const RetailWalkInOrdersDetail = Loadable({
  loader: () =>
    import('./pages/Stock/RetailWalkInOrders/detail/RetailWalkInOrdersDetail'),
  render(loaded, props) {
    const Component = loaded.RetailWalkInOrdersDetail;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});

// IncomingTransfer

const IncomingTransferList = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded) {
    const Component = loaded.Stock;
    return <Component activeTab="incomingTransferList" />;
  },
  loading: Loading,
});

const IncomingTransferDetail = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.IncomingTransferDetail;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});

// OutgoingTransfer

const OutgoingTransferList = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded) {
    const Component = loaded.Stock;
    return <Component activeTab="outgoingTransfer" />;
  },
  loading: Loading,
});

const OutgoingTransferDetail = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.OutgoingTransferDetail;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});

const OutgoingTransferCreate = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.OutgoingTransferCreate;
    return <Component />;
  },
  loading: Loading,
});

const OutgoingCaseAndPalletTransferCreate = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.OutgoingCaseAndPalletTransferCreate;
    return <Component />;
  },
  loading: Loading,
});

// PurchaseOrder

const PurchaseOrderList = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded) {
    const Component = loaded.Stock;
    return <Component activeTab="purchaseOrder" />;
  },
  loading: Loading,
});

const PurchaseOrderDetail = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.PurchaseOrderDetail;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});

const PurchaseOrderCreate = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.PurchaseOrderCreate;
    return <Component />;
  },
  loading: Loading,
});

// BlockedStock

const BlockedStockList = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.BlockedStockList;
    return <Component />;
  },
  loading: Loading,
});


const BlockedStockRefundList = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.BlockedStockRefundList;
    return <Component />;
  },
  loading: Loading,
});

const BlockedStockRefundDetail = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const { id } = props.match.params;
    const Component = loaded.BlockedStockRefundDetail;
    return <Component id={id}/>;
  },
  loading: Loading,
});

// Product

const ProductList = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.ProductList;
    return <Component />;
  },
  loading: Loading,
});

// ExpirationDate

const ExpirationDateList = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.ExpirationDateList;
    return <Component />;
  },
  loading: Loading,
});
const ExpirationDateListDashboard = Loadable({
  loader: () => import('./pages/Home'),
  render(loaded, props) {
    const Component = loaded.Home;
    return <Component activeTab="expirationDate" />;
  },
  loading: Loading,
});
// Heating Estimation
const HeatingEstimation = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.HeatingEstimation;
    return <Component />;
  },
  loading: Loading,
});

// InventoryCheck

const InventoryCheckList = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.InventoryCheckList;
    return <Component />;
  },
  loading: Loading,
});

const InventoryCheckCreate = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.InventoryCheckCreate;
    return <Component />;
  },
  loading: Loading,
});

const InventoryCheckDetail = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.InventoryCheckDetail;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});

const InventoryCheckTaskDetail = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.InventoryCheckTaskDetail;
    const { inventoryCheckId, jobId, jobTaskId } = props.match.params;
    return <Component {...{ inventoryCheckId, jobId, jobTaskId }} />;
  },
  loading: Loading,
});

// StockMovementLog

const StockMovementLogList = Loadable({
  loader: () => import('./pages/Stock'),
  render(loaded, props) {
    const Component = loaded.StockMovementLogList;
    return <Component />;
  },
  loading: Loading,
});

// StockMovementLog
const WarehouseStockList = Loadable({
  loader: () => import('./pages/Stock/WarehouseStockList'),
  render(loaded, props) {
    const Component = loaded.WarehouseStockList;
    return <Component />;
  },
  loading: Loading,
});

const EquipmentRequestList = Loadable({
  loader: () => import('./pages/Stock/EquipmentRequestList'),
  render(loaded, props) {
    const Component = loaded.EquipmentRequestList;
    return <Component />;
  },
  loading: Loading,
});

const EquipmentRequestCreate = Loadable({
  loader: () => import('./pages/Stock/EquipmentRequestCreate'),
  render(loaded, props) {
    const Component = loaded.EquipmentRequestCreate;
    return <Component />;
  },
  loading: Loading,
});

// OperationTransfer

const OperationTransferCreate = Loadable({
  loader: () => import('./pages/Operation/Transfer'),
  render(loaded, props) {
    const Component = loaded.TransferCreate;
    return <Component />;
  },
  loading: Loading,
});

const OperationTransferList = Loadable({
  loader: () => import('./pages/Operation/Transfer'),
  render(loaded) {
    const Component = loaded.TransferList;
    return <Component />;
  },
  loading: Loading,
});

const OperationTransferDetail = Loadable({
  loader: () => import('./pages/Operation/Transfer'),
  render(loaded, props) {
    const Component = loaded.TransferDetail;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});

const OperationTransferBulkApprove = Loadable({
  loader: () => import('./pages/Operation/Transfer'),
  render(loaded) {
    const Component = loaded.TransferBulkApprove;
    return <Component />;
  },
  loading: Loading,
});

const OperationTransferBulkCancel = Loadable({
  loader: () => import('./pages/Operation/Transfer'),
  render(loaded) {
    const Component = loaded.TransferBulkCancel;
    return <Component />;
  },
  loading: Loading,
});

// OperationPurchaseOrder

const OperationWarehousePurchaseOrderList = Loadable({
  loader: () => import('./pages/Operation/PurchaseOrder'),
  render(loaded, props) {
    const Component = loaded.WarehousePurchaseOrderList;
    return <Component />;
  },
  loading: Loading,
});

const OperationPurchaseOrderList = Loadable({
  loader: () => import('./pages/Operation/PurchaseOrder'),
  render(loaded, props) {
    const Component = loaded.PurchaseOrderList;
    return <Component />;
  },
  loading: Loading,
});

const OperationPurchaseOrderCreate = Loadable({
  loader: () => import('./pages/Operation/PurchaseOrder'),
  render(loaded, props) {
    const Component = loaded.PurchaseOrderCreate;
    return <Component />;
  },
  loading: Loading,
});

const OperationSubPurchaseOrderCreate = Loadable({
  loader: () => import('./pages/Operation/PurchaseOrder'),
  render(loaded, props) {
    const Component = loaded.PurchaseOrderCreate;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});

const OperationPurchaseOrderDetail = Loadable({
  loader: () => import('./pages/Operation/PurchaseOrder'),
  render(loaded, props) {
    const Component = loaded.PurchaseOrderDetail;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});

const OperationWarehousePurchaseOrderDetail = Loadable({
  loader: () => import('./pages/Operation/PurchaseOrder'),
  render(loaded, props) {
    const Component = loaded.WarehousePurchaseOrderDetail;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});

// OperationBlockedStock

const OperationBlockedStockList = Loadable({
  loader: () => import('./pages/Operation/BlockedStock'),
  render(loaded, props) {
    const Component = loaded.BlockedStockList;
    return <Component />;
  },
  loading: Loading,
});

const OperationBlockedStockDetail = Loadable({
  loader: () => import('./pages/Operation/BlockedStock'),
  render(loaded, props) {
    const Component = loaded.BlockedStockDetail;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});

const OperationBlockedStockBulkDecision = Loadable({
  loader: () => import('./pages/Operation/BlockedStock'),
  render(loaded, props) {
    const Component = loaded.BlockedStockBulkDecision;
    return <Component />;
  },
  loading: Loading,
});

const OperationBlockedStockRuleCreate = Loadable({
  loader: () => import('./pages/Operation/BlockedStock'),
  render(loaded, props) {
    const Component = loaded.BlockedStockRuleCreate;
    return <Component />;
  },
  loading: Loading,
});

const OperationBlockedStockRuleDetail = Loadable({
  loader: () => import('./pages/Operation/BlockedStock'),
  render(loaded, props) {
    const Component = loaded.BlockedStockRuleDetail;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});

const OperationBlockedStockRuleList = Loadable({
  loader: () => import('./pages/Operation/BlockedStock'),
  render(loaded, props) {
    const Component = loaded.BlockedStockRuleList;
    return <Component />;
  },
  loading: Loading,
});

const OperationBlockedStockTransferList = Loadable({
  loader: () => import('./pages/Operation/BlockedStock'),
  render(loaded, props) {
    const Component = loaded.BlockedStockTransferList;
    return <Component />;
  },
  loading: Loading,
});

const OperationBlockedStockTransferDetail = Loadable({
  loader: () => import('./pages/Operation/BlockedStock'),
  render(loaded, props) {
    const Component = loaded.BlockedStockTransferDetail;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});

const OperationBlockedStockDisposalList = Loadable({
  loader: () => import('./pages/Operation/BlockedStock'),
  render(loaded, props) {
    const Component = loaded.BlockedStockDisposalList;
    return <Component />;
  },
  loading: Loading,
});

const OperationBlockedStockDisposalDetail = Loadable({
  loader: () => import('./pages/Operation/BlockedStock'),
  render(loaded, props) {
    const Component = loaded.BlockedStockDisposalDetail;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});

const OperationBlockedStockRefundList = Loadable({
  loader: () => import('./pages/Operation/BlockedStock'),
  render(loaded, props) {
    const Component = loaded.BlockedStockRefundList;
    return <Component />;
  },
  loading: Loading,
});

const OperationBlockedStockRefundDetail = Loadable({
  loader: () => import('./pages/Operation/BlockedStock'),
  render(loaded, props) {
    const Component = loaded.BlockedStockRefundDetail;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});

// OperationInventoryCheck

const OperationInventoryCheckList = Loadable({
  loader: () => import('./pages/Operation/InventoryCheck'),
  render(loaded, props) {
    const Component = loaded.InventoryCheckList;
    return <Component />;
  },
  loading: Loading,
});

const OperationInventoryCheckBulkApprove = Loadable({
  loader: () => import('./pages/Operation/InventoryCheck'),
  render(loaded, props) {
    const Component = loaded.InventoryCheckBulkApprove;
    return <Component />;
  },
  loading: Loading,
});

const OperationInventoryCheckCreate = Loadable({
  loader: () => import('./pages/Operation/InventoryCheck'),
  render(loaded, props) {
    const Component = loaded.InventoryCheckCreate;
    return <Component />;
  },
  loading: Loading,
});

const OperationInventoryCheckDetail = Loadable({
  loader: () => import('./pages/Operation/InventoryCheck'),
  render(loaded, props) {
    const Component = loaded.InventoryCheckDetail;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});

const OperationInventoryCheckTaskDetail = Loadable({
  loader: () => import('./pages/Operation/InventoryCheck'),
  render(loaded, props) {
    const Component = loaded.InventoryCheckTaskDetail;
    const { inventoryCheckId, jobId, jobTaskId } = props.match.params;
    return <Component {...{ inventoryCheckId, jobId, jobTaskId }} />;
  },
  loading: Loading,
});

const OperationWarehouseStatusControl = Loadable({
  loader: () => import('./pages/Operation/InventoryCheck'),
  render(loaded, props) {
    const Component = loaded.WarehouseStatusControl;
    return <Component />;
  },
  loading: Loading,
});

// OperationStockMovementLog

const OperationStockMovementLogList = Loadable({
  loader: () => import('./pages/Operation/StockMovementLog'),
  render(loaded, props) {
    const Component = loaded.StockMovementLogList;
    return <Component />;
  },
  loading: Loading,
});

const OperationStockMovementLogDetail = Loadable({
  loader: () => import('./pages/Operation/StockMovementLog'),
  render(loaded, props) {
    const Component = loaded.StockMovementLogDetail;
    const { id } = props.match.params;
    return <Component id={id} />;
  },
  loading: Loading,
});
// production module

const OperationProductionModuleList = Loadable({
  loader: () =>
    import('./pages/Operation/ProductionModule/list/ProductionModuleList'),
  render(loaded, props) {
    const Component = loaded.ProductionModuleList;
    return <Component />;
  },
  loading: Loading,
});

const OperationTransactionsList = Loadable({
  loader: () => import('./pages/Operation/Transactions/list/TransactionsList'),
  render(loaded, props) {
    const Component = loaded.TransactionsList;
    return <Component />;
  },
  loading: Loading,
});

/*------------------------------------------------------------------------------
Routes
https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
------------------------------------------------------------------------------*/

export const routes = [
  // Dashboard
  {
    path: '/dashboard/orders',
    name: 'main.SIDEBAR.HOME_PAGE',
    component: DashboardOrders,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/dashboard/map',
    name: 'main.SIDEBAR.HOME_PAGE',
    component: DashboardMap,
    exact: true,
    permission: { action: 'read' },
  },
  // Home Expiration Date
  {
    path: '/dashboard/expiration-date',
    name: 'main.SIDEBAR.EXPIRATION_DATE',
    component: ExpirationDateListDashboard,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/heating-estimation/list',
    name: 'main.SIDEBAR.HOME_PAGE',
    component: DashboardHeatingEstimation,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/cancelled-orders',
    name: 'CANCELLED_ORDER.LIST',
    component: DashboardCancelledOrders,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/returned-orders',
    name: 'RETURNED_ORDER.LIST',
    component: DashboardReturnedOrders,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/order-missing-products',
    name: 'MISSING_PRODUCTS_ORDER.LIST',
    component: DashboardMissingProductsOrders,
    exact: true,
    permission: { action: 'read' },
  },
  // retailWalkInOrders
  {
    path: '/stock/retail-walk-in-orders/list',
    name: 'RETAIL_WALK_IN_ORDERS.LIST',
    component: RetailWalkInOrdersList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/retail-walk-in-orders-refund/list',
    name: 'RETAIL_WALK_IN_ORDERS_REFUND.LIST',
    component: RetailWalkInOrdersRefundList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/retail-walk-in-orders/detail/:id',
    name: 'RETAIL_WALK_IN_ORDERS.DETAIL',
    component: RetailWalkInOrdersDetail,
    exact: true,
    permission: { action: 'read' },
  },
  // IncomingTransfer
  {
    path: '/stock/transfer/list',
    name: 'TRANSFER.INCOMING.LIST',
    component: IncomingTransferList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/transfer/:id',
    name: 'TRANSFER.INCOMING.DETAIL',
    component: IncomingTransferDetail,
    exact: true,
    permission: { action: 'read' },
  },
  // OutgoingTransfer
  {
    path: '/stock/transfer/outgoing/list',
    name: 'TRANSFER.OUTGOING.LIST',
    component: OutgoingTransferList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/transfer/outgoing/detail/:id',
    name: 'TRANSFER.OUTGOING.DETAIL',
    component: OutgoingTransferDetail,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/transfer/outgoing/create',
    name: 'TRANSFER.OUTGOING.CREATE',
    component: OutgoingTransferCreate,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/transfer/outgoing/create-case-and-pallet-transfer',
    name: 'TRANSFER.OUTGOING.CREATECASEANDPALLETTRANSFER',
    component: OutgoingCaseAndPalletTransferCreate,
    exact: true,
    permission: { action: 'read' },
  },
  // PurchaseOrder
  {
    path: '/stock/purchase-order/list',
    name: 'PURCHASE_ORDER.LIST',
    component: PurchaseOrderList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/purchase-order/create',
    name: 'PURCHASE_ORDER.CREATE',
    component: PurchaseOrderCreate,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/purchase-order/detail/:id',
    name: 'PURCHASE_ORDER.DETAIL',
    component: PurchaseOrderDetail,
    exact: true,
    permission: { action: 'read' },
  },
  // BlockedStockList
  {
    path: '/stock/blocked-stock/list',
    name: 'BLOCK_STOCK.LIST',
    component: BlockedStockList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/blocked-stock/refund/detail/:id',
    name: 'BLOCK_STOCK.REFUND_DETAIL',
    component: BlockedStockRefundDetail,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/blocked-stock/refund/list',
    name: 'BLOCK_STOCK.REFUND_LIST',
    component: BlockedStockRefundList,
    exact: true,
    permission: { action: 'read' },
  },


  // Product
  {
    path: '/stock/product/list',
    name: 'PRODUCT.LIST',
    component: ProductList,
    exact: true,
    permission: { action: 'read' },
  },
  // Expiration Date
  {
    path: '/stock/expiration-date/list',
    name: 'EXPIRATION_DATE.LIST',
    component: ExpirationDateList,
    exact: true,
    permission: { action: 'read' },
  },
  // Heating Estimation
  {
    path: '/stock/heating-estimation/list',
    name: 'HEATING_ESTIMATION.LIST',
    component: HeatingEstimation,
    exact: true,
    permission: { action: 'read' },
  },
  // InventoryCheck
  {
    path: '/stock/inventory-check/list',
    name: 'INVENTORY_CHECK.LIST',
    component: InventoryCheckList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/inventory-check/create',
    name: 'INVENTORY_CHECK.CREATE',
    component: InventoryCheckCreate,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/inventory-check/detail/:id',
    name: 'INVENTORY_CHECK.DETAIL',
    component: InventoryCheckDetail,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/inventory-check/:inventoryCheckId/job/:jobId/task/:jobTaskId',
    name: 'INVENTORY_CHECK.DETAIL',
    component: InventoryCheckTaskDetail,
    exact: true,
    permission: { action: 'read' },
  },
  // StockMovementLog
  {
    path: '/stock/movement-log/list',
    name: 'STOCK.MOVEMENT_LOG.LIST',
    component: StockMovementLogList,
    exact: true,
    permission: { action: 'read' },
  },
  // WarehouseStock
  {
    path: '/stock/warehouse-stock/list',
    name: 'WAREHOUSE_STOCK_LIST',
    component: WarehouseStockList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/equipment-request/list',
    name: 'EQUIPMENT_REQUEST.LIST',
    component: EquipmentRequestList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock/equipment-request/create',
    name: 'EQUIPMENT_REQUEST.CREATE',
    component: EquipmentRequestCreate,
    exact: true,
    permission: { action: 'read' },
  },
  // OperationInventoryCheck
  {
    path: '/stock-operation/inventory-check/list',
    name: 'INVENTORY_CHECK.LIST',
    component: OperationInventoryCheckList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/inventory-check/bulk-approve',
    name: 'INVENTORY_CHECK.BULK_APPROVE',
    component: OperationInventoryCheckBulkApprove,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/inventory-check/create',
    name: 'INVENTORY_CHECK.CREATE',
    component: OperationInventoryCheckCreate,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/inventory-check/detail/:id',
    name: 'INVENTORY_CHECK.DETAIL',
    component: OperationInventoryCheckDetail,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path:
      '/stock-operation/inventory-check/:inventoryCheckId/job/:jobId/task/:jobTaskId',
    name: 'INVENTORY_CHECK.DETAIL',
    component: OperationInventoryCheckTaskDetail,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/warehouse-status/control',
    name: 'OPERATION.WAREHOUSE.CONTROL',
    component: OperationWarehouseStatusControl,
    exact: true,
    permission: { action: 'read' },
  },
  // OperationStockMovementLog
  {
    path: '/stock-operation/movement-log/list',
    name: 'STOCK.MOVEMENT_LOG.LIST',
    component: OperationStockMovementLogList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/movement-log/detail/:id',
    name: 'STOCK.MOVEMENT_LOG.DETAIL',
    component: OperationStockMovementLogDetail,
    exact: true,
    permission: { action: 'read' },
  },
  // production module
  {
    path: '/stock-operation/production-module',
    name: 'STOCK.PRODUCTION_MODULE',
    component: OperationProductionModuleList,
    exact: true,
    permission: { action: 'read' },
  },
  // transactions
  {
    path: '/stock-operation/transactions/list',
    name: 'STOCK.TRANSACTIONS',
    component: OperationTransactionsList,
    exact: true,
    permission: { action: 'read' },
  },
  // OperationTransfer
  {
    path: '/stock-operation/transfer/create',
    name: 'TRANSFER.CREATE',
    component: OperationTransferCreate,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/transfer/list',
    name: 'TRANSFER.TRANSFER_LIST.',
    component: OperationTransferList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/transfer/detail/:id',
    name: 'TRANSFER.TRANSFER_DETAIL.',
    component: OperationTransferDetail,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/transfer/bulk-approve',
    name: 'TRANSFER.TRANSFER_BULK_APPROVE.',
    component: OperationTransferBulkApprove,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/transfer/bulk-cancel',
    name: 'TRANSFER.TRANSFER_BULK_CANCEL.',
    component: OperationTransferBulkCancel,
    exact: true,
    permission: { action: 'read' },
  },
  // OperationPurchaseOrder
  {
    path: '/stock-operation/purchase-order/list',
    name: 'PURCHASE_ORDER.LIST',
    component: OperationPurchaseOrderList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/warehouse-purchase-order/list',
    name: 'PURCHASE_ORDER.LIST',
    component: OperationWarehousePurchaseOrderList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/purchase-order/create',
    name: 'PURCHASE_ORDER.CREATE',
    component: OperationPurchaseOrderCreate,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/sub-purchase-order/create/:id',
    name: 'PURCHASE_ORDER.CREATE',
    component: OperationSubPurchaseOrderCreate,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/purchase-order/detail/:id',
    name: 'PURCHASE_ORDER.DETAIL',
    component: OperationPurchaseOrderDetail,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/warehouse-purchase-order/detail/:id',
    name: 'PURCHASE_ORDER.WPO_DETAIL',
    component: OperationWarehousePurchaseOrderDetail,
    exact: true,
    permission: { action: 'read' },
  },
  // OperationBlockedStock
  {
    path: '/stock-operation/blocked-stock/list',
    name: 'BLOCK_STOCK.LIST',
    component: OperationBlockedStockList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/blocked-stock/detail/:id',
    name: 'BLOCK_STOCK.DETAIL',
    component: OperationBlockedStockDetail,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/blocked-stock/bulk-decision',
    name: 'BLOCK_STOCK.BULK_DECISION',
    component: OperationBlockedStockBulkDecision,
    exact: true,
    permission: { action: 'read' },
  },

  {
    path: '/stock-operation/blocked-stock/decision-rule/create',
    name: 'BLOCK_STOCK.DECISION_RULE_CREATE',
    component: OperationBlockedStockRuleCreate,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/blocked-stock/decision-rule/detail/:id',
    name: 'BLOCK_STOCK.DECISION_RULE_DETAIL',
    component: OperationBlockedStockRuleDetail,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/blocked-stock/decision-rule/list',
    name: 'BLOCK_STOCK.DECISION_RULE_LIST',
    component: OperationBlockedStockRuleList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/blocked-stock/transfer/list',
    name: 'BLOCK_STOCK.TRANSFER.LIST',
    component: OperationBlockedStockTransferList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/blocked-stock/transfer/:id',
    name: 'BLOCK_STOCK.TRANSFER.DETAIL',
    component: OperationBlockedStockTransferDetail,
    exact: true,
    permission: { action: 'read' },
  },

  {
    path: '/stock-operation/blocked-stock/disposal/list',
    name: 'BLOCK_STOCK.DISPOSAL.LIST',
    component: OperationBlockedStockDisposalList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/blocked-stock/disposal/:id',
    name: 'BLOCK_STOCK.DISPOSAL.DETAIL',
    component: OperationBlockedStockDisposalDetail,
    exact: true,
    permission: { action: 'read' },
  },

  {
    path: '/stock-operation/blocked-stock/refund/list',
    name: 'BLOCK_STOCK.REFUND.LIST',
    component: OperationBlockedStockRefundList,
    exact: true,
    permission: { action: 'read' },
  },
  {
    path: '/stock-operation/blocked-stock/refund/:id',
    name: 'BLOCK_STOCK.REFUND.DETAIL',
    component: OperationBlockedStockRefundDetail,
    exact: true,
    permission: { action: 'read' },
  },
];
