import React, { Component } from 'react';
// --------------------------------------------------
import './CourierPanel.scss';
import { CourierFilter } from './CourierFilter';
import { CourierList } from './CourierList';
// --------------------------------------------------
import { Spinner } from '../../../components';
// --------------------------------------------------

export class CourierPanel extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory(props);
  }

  stateFactory = () => {
    const newState = {
      selectedFilters: [],
    };
    return newState;
  };

  onChangeFilter = (selectedFilters) => {
    this.setState({
      selectedFilters,
    });
  };

  render() {
    const { couriers } = this.props;
    const { loading, data } = couriers;
    const { selectedFilters } = this.state;
    if (loading) {
      return <Spinner />;
    }
    return (
      <div className="CourierPanel_inner">
        <CourierFilter onChangeFilter={this.onChangeFilter} />
        <CourierList
          timer={this.props.timer}
          couriers={data}
          selectedFilters={selectedFilters}
          makeCourierBusy={this.props.makeCourierBusy}
          makeCourierFree={this.props.makeCourierFree}
          courierBusyReasons={this.props.courierBusyReasons}
          availableStockOfLocations={this.props.availableStockOfLocations}
          isWaterWarehouse={this.props.isWaterWarehouse}
        />
      </div>
    );
  }
}
