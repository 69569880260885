function all(items, fn, payload, isDelayRequired) {
  let promises;

  if (isDelayRequired) {
    let delay = 0;

    const delayIncrement = 250;

    promises = items.map((item) => {
      delay += delayIncrement;

      return new Promise((resolve) => setTimeout(resolve, delay)).then(() => fn(item, payload));
    });
  } else {
    promises = items.map((item) => fn(item, payload));
  }

  return Promise.allSettled(promises);
}

function series(items, fn) {
  const result = [];
  return items
    .reduce((acc, item) => {
      // eslint-disable-next-line no-param-reassign
      acc = acc.then(() => fn(item).then((res) => result.push(res)));
      return acc;
    }, Promise.resolve())
    .then(() => result);
}

function splitToChunks(items, chunkSize) {
  const result = [];
  for (let i = 0; i < items.length; i += chunkSize) {
    result.push(items.slice(i, i + chunkSize));
  }
  return result;
}

function chunkPromises({
  data, payload = {}, fn, chunkSize = 20, isDelayRequired,
}) {
  let result = [];
  const chunks = splitToChunks(data, chunkSize);
  // eslint-disable-next-line no-return-assign
  return series(chunks, (chunk) => all(chunk, fn, payload, isDelayRequired).then((res) => (result = result.concat(res)))).then(() => result);
}

export { chunkPromises };
