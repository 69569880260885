import React, { Component } from 'react';
import {
  Card,
  Form,
  Switch,
  DatePicker,
  Radio,
  Button,
  Tooltip,
  Icon,
} from 'antd';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

/*----------------------------------------------------------------------------*/
import './VisibilitySettings.scss';
import {
  getDateTimeFormatString,
  getDateFormatString,
} from '../../../../../shared';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class VisibilitySettings extends Component {
  settings = {
    main: {
      className: 'VisibilitySettings_main',
    },
    card: {
      className: 'no-padding-card',
      title: this.props.t(
        'pages.Operation.PurchaseOrder.detail.VisibilitySettings.title',
      ),
      size: 'small',
    },
  };

  dateFormat = getDateFormatString();

  formItemLayout = {
    style: {
      marginTop: 15,
    },
    labelCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 5, offset: 0 },
    },
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 19, offset: 0 },
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => {
    return {
      isEdit: false,
      isSaved: false,
      expectedDeliveryDate: null,
      isVisibilityManual: null,
      isVisible: false,
      visibilityDateOpen: null,
      visibilityDateClose: null,
    };
  };

  getInitialSettings = () => {
    return {
      expectedDeliveryDate: this.props.expectedDeliveryDate,
      isVisibilityManual: this.props.visibilityType === 1,
      isVisible: this.props.isVisible || false,
      visibilityDateOpen:
        (this.props.visibilityRange && this.props.visibilityRange.start) ||
        this.getDefaultDateRange().startDate,
      visibilityDateClose:
        (this.props.visibilityRange && this.props.visibilityRange.end) ||
        this.getDefaultDateRange().endDate,
    };
  };

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  getPayload = () => {
    const payload = {
      expectedDeliveryDate: this.state.expectedDeliveryDate,
      visibilityType: this.state.isVisibilityManual ? 1 : 2,
      isVisible: this.state.isVisible,
      visibilityRange: {
        start: new Date(this.state.visibilityDateOpen).setHours(0, 0, 0, 0),
        end: new Date(this.state.visibilityDateClose).setHours(23, 59, 59, 999),
      },
    };
    return payload;
  };

  getSettings = () => {
    const { isEdit, isSaved } = this.state;
    if (isEdit || isSaved) {
      return this.state;
    } else {
      return this.getInitialSettings();
    }
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.SettingsForm />
        </Card>
      </div>
    );
  }

  CardExtra = () => {
    const { isEdit } = this.state;
    const { isEditable } = this.props;
    return (
      <div>
        {isEdit ? null : (
          <Button
            size="small"
            disabled={!isEditable}
            style={{ marginRight: 5 }}
            onClick={() => {
              this.setState({
                isEdit: true,
                isSaved: false,
                ...this.getInitialSettings(),
              });
            }}
          >
            {this.props.t(
              'pages.Operation.PurchaseOrder.detail.VisibilitySettings.button.edit',
            )}
          </Button>
        )}
        {!isEdit ? null : (
          <>
            <Button
              size="small"
              style={{ marginRight: 5 }}
              onClick={() => {
                this.setState({ ...this.state, isEdit: false, isSaved: false });
              }}
            >
              {this.props.t(
                'pages.Operation.PurchaseOrder.detail.VisibilitySettings.button.cancel',
              )}
            </Button>
            <Button
              size="small"
              type="danger"
              style={{ marginRight: 5 }}
              onClick={() => {
                const payload = this.getPayload();
                this.props.onSave(payload);
                this.setState({ ...this.state, isEdit: false, isSaved: true });
              }}
            >
              {this.props.t(
                'pages.Operation.PurchaseOrder.detail.VisibilitySettings.button.save',
              )}
            </Button>
          </>
        )}
      </div>
    );
  };

  SettingsForm = (props) => {
    return (
      <Form {...this.formItemLayout}>
        <this.EstimatedDeliveryDateField />
        <this.VisibilitySettingsField />
        <this.AutoOpenDateRangeField />
        <this.ManualVisibilityControlField />
      </Form>
    );
  };

  EstimatedDeliveryDateField = (props) => {
    const { isEdit } = this.state;
    const { expectedDeliveryDate } = this.getSettings();

    return (
      <Form.Item
        label={this.props.t(
          'pages.Operation.PurchaseOrder.detail.VisibilitySettings.form.estimatedDeliveryDate',
        )}
      >
        <DatePicker
          disabled={!isEdit}
          value={expectedDeliveryDate && moment(new Date(expectedDeliveryDate))}
          onChange={(expectedDeliveryDate) => {
            this.setState({ expectedDeliveryDate: expectedDeliveryDate._d });
          }}
          format={this.dateFormat}
        />
      </Form.Item>
    );
  };

  VisibilitySettingsField = (props) => {
    const { isEdit } = this.state;
    const { isVisibilityManual } = this.getSettings();
    return (
      <Form.Item
        label={this.props.t(
          'pages.Operation.PurchaseOrder.detail.VisibilitySettings.form.visibilitySettings',
        )}
      >
        <Radio.Group
          disabled={!isEdit}
          value={isVisibilityManual ? 'manualControl' : 'selectDate'}
          onChange={(event) => {
            const isVisibilityManual = event.target.value === 'manualControl';
            this.setState({ isVisibilityManual });
          }}
        >
          <Radio.Button value="manualControl">
            {this.props.t(
              'pages.Operation.PurchaseOrder.detail.VisibilitySettings.form.manualControl',
            )}
          </Radio.Button>
          <Radio.Button value="selectDate">
            {this.props.t(
              'pages.Operation.PurchaseOrder.detail.VisibilitySettings.form.selectDate',
            )}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
    );
  };

  AutoOpenDateRangeField = (props) => {
    const { isEdit } = this.state;
    const {
      visibilityDateOpen,
      visibilityDateClose,
      isVisibilityManual,
    } = this.getSettings();
    if (isVisibilityManual) {
      return null;
    }
    return (
      <Form.Item
        label={this.props.t(
          'pages.Operation.PurchaseOrder.detail.VisibilitySettings.form.dateRange',
        )}
      >
        <DatePicker.RangePicker
          disabled={!isEdit}
          value={[
            moment(new Date(visibilityDateOpen)),
            moment(new Date(visibilityDateClose)),
          ]}
          style={{ width: '450px' }}
          onChange={(dates) => {
            console.log('--', dates);
            this.setState({
              ...this.state,
              visibilityDateOpen: dates[0]._d,
              visibilityDateClose: dates[1]._d,
            });
          }}
          format={isEdit ? getDateFormatString() : getDateTimeFormatString()}
        />
      </Form.Item>
    );
  };

  AutoOpeningDateField = (props) => {
    const { isEdit } = this.state;
    const { visibilityDateOpen, isVisibilityManual } = this.getSettings();
    if (isVisibilityManual) {
      return null;
    }
    return (
      <Form.Item
        label={this.props.t(
          'pages.Operation.PurchaseOrder.detail.VisibilitySettings.form.openDate',
        )}
      >
        <DatePicker
          disabled={!isEdit}
          value={moment(new Date(visibilityDateOpen))}
          onChange={(visibilityDateOpen) => {
            this.setState({ visibilityDateOpen: visibilityDateOpen._d });
          }}
          format={isEdit ? getDateFormatString() : getDateTimeFormatString()}
        />
      </Form.Item>
    );
  };

  AutoClosingDateField = (props) => {
    const { isEdit } = this.state;
    const { visibilityDateClose, isVisibilityManual } = this.getSettings();
    if (isVisibilityManual) {
      return null;
    }
    return (
      <Form.Item
        label={this.props.t(
          'pages.Operation.PurchaseOrder.detail.VisibilitySettings.form.closeDate',
        )}
      >
        <DatePicker
          disabled={!isEdit}
          value={moment(new Date(visibilityDateClose))}
          onChange={(visibilityDateClose) => {
            this.setState({ visibilityDateClose: visibilityDateClose._d });
          }}
          format={isEdit ? getDateFormatString() : getDateTimeFormatString()}
        />
      </Form.Item>
    );
  };

  ManualVisibilityControlField = (props) => {
    const { isEdit } = this.state;
    const { isVisible, isVisibilityManual } = this.getSettings();
    const { isEditable } = this.props;
    if (!isVisibilityManual) {
      return null;
    }

    return (
      <Form.Item
        label={
          <div className="display-inherit">
            <div className="flex-center">
              {isEditable && (
                <Tooltip
                  placement="left"
                  title={this.props.t(
                    'pages.Operation.PurchaseOrder.detail.VisibilitySettings.form.tooltip',
                  )}
                >
                  <Icon type="info-circle" className="icon" />
                </Tooltip>
              )}

              <div>
                {this.props.t(
                  'pages.Operation.PurchaseOrder.detail.VisibilitySettings.form.visibility',
                )}
              </div>
            </div>
          </div>
        }
      >
        <Switch
          disabled={!isEdit}
          checked={isVisible}
          checkedChildren={this.props.t(
            'pages.Operation.PurchaseOrder.detail.VisibilitySettings.form.open',
          )}
          unCheckedChildren={this.props.t(
            'pages.Operation.PurchaseOrder.detail.VisibilitySettings.form.closed',
          )}
          onChange={(isVisible) => {
            this.setState({ isVisible });
          }}
        />
      </Form.Item>
    );
  };
}

const VisibilitySettingsTranslated = withNamespaces('translation')(
  VisibilitySettings,
);

export { VisibilitySettingsTranslated as VisibilitySettings };
