import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: true,
    data: null,
    error: null,
  },
  detail: {
    loading: true,
    data: null,
    error: null,
  },
  reportFraud: {
    loading: false,
    data: null,
    error: null,
  },
  walkInOrders: {
    loading: true,
    data: null,
    error: null,
  },
  walkInOrderDetails: {
    loading: true,
    data: null,
    error: null,
  },
  walkInOrderDecreaseStock: {
    loading: false,
    data: null,
    error: null,
  },
  walkInOrderCancel: {
    loading: false,
    data: null,
    error: null,
  },
  walkInOrderDeliver: {
    loading: false,
    data: null,
    error: null,
  },
  filterWalkInOrders: {
    loading: false,
    data: null,
    error: null,
  },
  walkInOrderRefund: {
    loading: false,
    data: null,
    error: null,
  },
};

const switcher = {
  [actionType.getOrderList_InProgress]: getOrderList_InProgress,
  [actionType.getOrderList_Succeeded]: getOrderList_Succeeded,
  [actionType.getOrderList_Failed]: getOrderList_Failed,
  //
  [actionType.getOrderDetail_InProgress]: getOrderDetail_InProgress,
  [actionType.getOrderDetail_Succeeded]: getOrderDetail_Succeeded,
  [actionType.getOrderDetail_Failed]: getOrderDetail_Failed,
  //
  [actionType.reportFraud_InProgress]: reportFraud_InProgress,
  [actionType.reportFraud_Succeeded]: reportFraud_Succeeded,
  [actionType.reportFraud_Failed]: reportFraud_Failed,
  [actionType.reportFraud_Reset]: reportFraud_Reset,
  //
  [actionType.newMarketOrder]: newMarketOrder,
  [actionType.marketOrderChanged]: marketOrderChanged,

  [actionType.getWalkInOrders_InProgress]: getWalkInOrders_InProgress,
  [actionType.getWalkInOrders_Succeeded]: getWalkInOrders_Succeeded,
  [actionType.getWalkInOrders_Failed]: getWalkInOrders_Failed,

  [actionType.getWalkInOrderDetails_InProgress]: getWalkInOrderDetails_InProgress,
  [actionType.getWalkInOrderDetails_Succeeded]: getWalkInOrderDetails_Succeeded,
  [actionType.getWalkInOrderDetails_Failed]: getWalkInOrderDetails_Failed,

  [actionType.walkInOrderDecreaseStock_InProgress]: walkInOrderDecreaseStock_InProgress,
  [actionType.walkInOrderDecreaseStock_Succeeded]: walkInOrderDecreaseStock_Succeeded,
  [actionType.walkInOrderDecreaseStock_Failed]: walkInOrderDecreaseStock_Failed,

  [actionType.walkInOrderCancel_InProgress]: walkInOrderCancel_InProgress,
  [actionType.walkInOrderCancel_Succeeded]: walkInOrderCancel_Succeeded,
  [actionType.walkInOrderCancel_Failed]: walkInOrderCancel_Failed,

  [actionType.walkInOrderDeliver_InProgress]: walkInOrderDeliver_InProgress,
  [actionType.walkInOrderDeliver_Succeeded]: walkInOrderDeliver_Succeeded,
  [actionType.walkInOrderDeliver_Failed]: walkInOrderDeliver_Failed,

  [actionType.filterWalkInOrders_InProgress]: filterWalkInOrders_InProgress,
  [actionType.filterWalkInOrders_Succeeded]: filterWalkInOrders_Succeeded,
  [actionType.filterWalkInOrders_Failed]: filterWalkInOrders_Failed,

  [actionType.walkInOrderRefund_InProgress]: walkInOrderRefund_InProgress,
  [actionType.walkInOrderRefund_Succeeded]: walkInOrderRefund_Succeeded,
  [actionType.walkInOrderRefund_Failed]: walkInOrderRefund_Failed,
};

export const orderReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getOrderList_InProgress(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: true,
    },
  });
}

function getOrderList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getOrderList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getOrderDetail_InProgress(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getOrderDetail_Succeeded(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getOrderDetail_Failed(state, payload) {
  return Object.assign({}, state, {
    detail: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function reportFraud_InProgress(state, payload) {
  return Object.assign({}, state, {
    reportFraud: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function reportFraud_Succeeded(state, payload) {
  return Object.assign({}, state, {
    reportFraud: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function reportFraud_Failed(state, payload) {
  return Object.assign({}, state, {
    reportFraud: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function reportFraud_Reset(state, payload) {
  return Object.assign({}, state, {
    reportFraud: {
      loading: false,
      data: null,
      error: null,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function newMarketOrder(state, { order }) {
  const orderList = state.list.data;
  if (!orderList) {
    return state;
  }
  const updatedList = [...orderList, order];
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: updatedList,
      error: null,
    },
  });
}

function marketOrderChanged(state, { order }) {
  const orderList = state.list.data;
  if (!orderList) {
    return state;
  }
  const updatedList = orderList.map((item) => {
    let updatedItem = { ...item };
    if (updatedItem.id === order.id) {
      updatedItem = { ...order };
    }
    return updatedItem;
  });
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: updatedList,
      error: null,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/
function getWalkInOrders_InProgress(state, payload) {
  return Object.assign({}, state, {
    walkInOrders: {
      loading: true,
    },
  });
}

function getWalkInOrders_Succeeded(state, payload) {
  return Object.assign({}, state, {
    walkInOrders: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getWalkInOrders_Failed(state, payload) {
  return Object.assign({}, state, {
    walkInOrders: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function getWalkInOrderDetails_InProgress(state, payload) {
  return Object.assign({}, state, {
    walkInOrderDetails: {
      loading: true,
    },
  });
}

function getWalkInOrderDetails_Succeeded(state, payload) {
  return Object.assign({}, state, {
    walkInOrderDetails: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function getWalkInOrderDetails_Failed(state, payload) {
  return Object.assign({}, state, {
    walkInOrderDetails: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function walkInOrderDecreaseStock_InProgress(state, payload) {
  return Object.assign({}, state, {
    walkInOrderDecreaseStock: {
      loading: true,
    },
  });
}

function walkInOrderDecreaseStock_Succeeded(state, payload) {
  return Object.assign({}, state, {
    walkInOrderDecreaseStock: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function walkInOrderDecreaseStock_Failed(state, payload) {
  return Object.assign({}, state, {
    walkInOrderDecreaseStock: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function walkInOrderCancel_InProgress(state, payload) {
  return Object.assign({}, state, {
    walkInOrderCancel: {
      loading: true,
    },
  });
}

function walkInOrderCancel_Succeeded(state, payload) {
  return Object.assign({}, state, {
    walkInOrderCancel: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function walkInOrderCancel_Failed(state, payload) {
  return Object.assign({}, state, {
    walkInOrderCancel: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function walkInOrderDeliver_InProgress(state, payload) {
  return Object.assign({}, state, {
    walkInOrderDeliver: {
      loading: true,
    },
  });
}

function walkInOrderDeliver_Succeeded(state, payload) {
  return Object.assign({}, state, {
    walkInOrderDeliver: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function walkInOrderDeliver_Failed(state, payload) {
  return Object.assign({}, state, {
    walkInOrderDeliver: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function filterWalkInOrders_InProgress(state, payload) {
  return Object.assign({}, state, {
    filterWalkInOrders: {
      loading: true,
    },
  });
}

function filterWalkInOrders_Succeeded(state, payload) {
  return Object.assign({}, state, {
    filterWalkInOrders: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function filterWalkInOrders_Failed(state, payload) {
  return Object.assign({}, state, {
    filterWalkInOrders: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

function walkInOrderRefund_InProgress(state, payload) {
  return Object.assign({}, state, {
    walkInOrderRefund: {
      loading: true,
    },
  });
}

function walkInOrderRefund_Succeeded(state, payload) {
  return Object.assign({}, state, {
    walkInOrderRefund: {
      loading: false,
      data: payload,
      error: null,
    },
  });
}

function walkInOrderRefund_Failed(state, payload) {
  return Object.assign({}, state, {
    walkInOrderRefund: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}
