import React, { Component } from 'react';
import { Form, Modal } from 'antd';
import { withNamespaces } from 'react-i18next';
import { CountrySelect } from './CountrySelect';

class CountrySelectModal extends Component {
  formItemLayout = {
    style: {
      marginTop: 15,
      marginBottom: 15,
    },
    labelCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 8, offset: 0 },
    },
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 16, offset: 0 },
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    return {
      country: JSON.parse(localStorage.getItem('country')),
    };
  };

  handleOk = () => {
    this.props.handleOk(this.state.country);
  };

  handleCancel = () => {
    this.setState(this.stateFactory(), () =>
      this.props.handleCancel(this.state.country),
    );
  };

  render() {
    return (
      <div>
        <Modal
          title={this.props.t('components.CountrySelectModal.selectCountry')}
          visible={this.props.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okButtonProps={{ disabled: !this.state.country }}
        >
          <Form>
            <Form.Item
              {...this.formItemLayout}
              label={this.props.t('components.CountrySelectModal.country')}
            >
              <CountrySelect
                placeholder={this.props.t(
                  'components.CountrySelectModal.country',
                )}
                value={this.state.country}
                list={this.props.list.data}
                loading={this.props.list.loading}
                onChange={(country) => {
                  this.setState({ country });
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const CountrySelectModalTranslated = withNamespaces('translation')(
  CountrySelectModal,
);

export { CountrySelectModalTranslated as CountrySelectModal };
