import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form, Button, Row, Col, notification,
} from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import './StockMovementLogList.scss';
import { differenceBy } from 'lodash';
import {
  WarehouseMultiSelect,
  ProductMultiSelect,
  LocationMultiSelect,
  PickerMultiSelect,
  LoggerIdInput,
  LoggerModelMultiSelect,
  DescriptionMultiSelect,
  DateRangeSelect,
} from './filterFields';
/*----------------------------------------------------------------------------*/
import {
  warehouseAction,
  productAction,
  pickerAction,
  warehouseLocationAction,
} from './../../../../actions';

import { constants } from './../../../../shared';

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class StockMovementLogFilter extends Component {
  settings = {
    main: {
      className: 'StockMovementLogFilter_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    const {
      currentWarehouse,
      onGetProductList,
      onGetPickerList,
      onGetLocationList,
      CWId,
    } = this.props;
    onGetProductList();
    this.setState({ warehouses: [currentWarehouse] });
    onGetLocationList(CWId);
    onGetPickerList(CWId);
  }

  stateFactory = () => ({
    ...this.emptyTableFilter(),
  });

  emptyTableFilter = () => ({
    warehouses: [],
    fromProductLocation: [],
    toProductLocation: [],
    products: [],
    descriptions: [],
    startDate: this.props.defaultDateRange.startDate,
    endDate: this.props.defaultDateRange.endDate,
    pickers: [],
    loggerModels: [],
    loggerId: null,
  });

  TableFilterForm = (props) => (
    <div>
      <Form {...this.formItemLayout}>
        <Row>
          <Col span={5} style={{ padding: '0px 5px' }}>
            <this.DateRangeField />
          </Col>
          <Col span={19} style={{ padding: '0px 5px' }}>
            <this.ProductField />
          </Col>
        </Row>
        <Row>
          <Col span={8} style={{ padding: '0px 5px' }}>
            <this.LocationFromField />
          </Col>
          <Col span={8} style={{ padding: '0px 5px' }}>
            <this.LocationToField />
          </Col>
          <Col span={8} style={{ padding: '0px 5px' }}>
            <this.PickerField />
          </Col>
        </Row>
        <Row>
          <Col span={8} style={{ padding: '0px 5px' }}>
            <this.LoggerIdField />
          </Col>
          <Col span={8} style={{ padding: '0px 5px' }}>
            <this.DescriptionField />
          </Col>
          <Col span={8} style={{ padding: '0px 5px' }}>
            <this.LoggerModelField />
          </Col>
        </Row>
        <Row>
          <Col span={2} style={{ padding: '0px 5px' }}>
            <this.ClearFilterButton />
          </Col>
          <Col offset={20} span={2} style={{ padding: '0px 5px' }}>
            <this.FilterButton />
          </Col>
        </Row>
      </Form>
    </div>
  );

  DateRangeField = () => (
    <Form.Item>
      <DateRangeSelect
        defaultDateRange={this.props.defaultDateRange}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        onChange={(dateRange) => {
          this.setState({
            ...this.state,
            ...dateRange,
          });
        }}
      />
    </Form.Item>
  );

  WarehouseField = () => {
    const { warehouseList, isUserAdmin } = this.props;
    const list = (warehouseList && warehouseList.data) || [];
    return (
      <Form.Item>
        <WarehouseMultiSelect
          placeholder={this.props.t('pages.Stock.StockMovementLog.list.table.warehouse')}
          list={list}
          disabled={!isUserAdmin}
          onChange={(warehouses) => {
            this.setState({ warehouses }, this.getDependentFields);
          }}
          value={this.state.warehouses}
          loading={warehouseList.loading}
        />
      </Form.Item>
    );
  };

  isSelectableField = () => {
    const { warehouses } = this.state;
    if (warehouses.length === 1) {
      return true;
    }
    return false;
  };

  getDependentFields = () => {
    const { warehouses } = this.state;
    if (warehouses.length === 1) {
      const wid = warehouses[0].id;
      this.props.onGetLocationList(wid);
      this.props.onGetPickerList(wid);
      return;
    }
    this.setState({
      ...this.state,
      fromProductLocation: [],
      toProductLocation: [],
      pickers: [],
    });
  };

  ProductField = () => {
    const { productList } = this.props;
    const list = (productList && productList.data) || null;
    return (
      <Form.Item>
        <ProductMultiSelect
          placeholder={this.props.t('pages.Stock.StockMovementLog.list.table.product')}
          list={list}
          onChange={(products) => {
            this.setState({ products });
          }}
          value={this.state.products}
          loading={productList.loading}
        />
      </Form.Item>
    );
  };

  LocationFromField = () => {
    const { locationList } = this.props;
    const { fromProductLocation, toProductLocation } = this.state;
    let list = (locationList && locationList.data) || null;

    if (toProductLocation && toProductLocation.length) {
      list = differenceBy(list, toProductLocation, 'id');
    }

    return (
      <Form.Item>
        <LocationMultiSelect
          placeholder={this.props.t('pages.Stock.StockMovementLog.list.table.fromProductLocation')}
          list={list}
          disabled={!this.isSelectableField()}
          onChange={(fromProductLocation) => {
            this.setState({ fromProductLocation });
          }}
          value={fromProductLocation}
          loading={locationList.loading}
        />
      </Form.Item>
    );
  };

  LocationToField = () => {
    const { locationList } = this.props;
    const { fromProductLocation, toProductLocation } = this.state;
    let list = (locationList && locationList.data) || null;

    if (fromProductLocation && fromProductLocation.length) {
      list = differenceBy(list, fromProductLocation, 'id');
    }

    return (
      <Form.Item>
        <LocationMultiSelect
          placeholder={this.props.t('pages.Stock.StockMovementLog.list.table.toProductLocation')}
          list={list}
          disabled={!this.isSelectableField()}
          onChange={(toProductLocation) => {
            this.setState({ toProductLocation });
          }}
          value={toProductLocation}
          loading={locationList.loading}
        />
      </Form.Item>
    );
  };

  PickerField = () => {
    const { pickerList } = this.props;
    const list = (pickerList && pickerList.data) || null;
    return (
      <Form.Item>
        <PickerMultiSelect
          placeholder={this.props.t('pages.Stock.StockMovementLog.list.table.picker')}
          list={list}
          disabled={!this.isSelectableField()}
          onChange={(pickers) => {
            this.setState({ pickers });
          }}
          value={this.state.pickers}
          loading={pickerList.loading}
        />
      </Form.Item>
    );
  };

  LoggerModelField = () => {
    const { loggerModels } = this.state;
    return (
      <Form.Item>
        <LoggerModelMultiSelect
          placeholder={this.props.t('pages.Stock.StockMovementLog.list.table.loggerModel')}
          onChange={(loggerModels) => {
            this.setState({ loggerModels });
          }}
          value={loggerModels}
        />
      </Form.Item>
    );
  };

  LoggerIdField = () => {
    const { loggerId } = this.state;
    return (
      <Form.Item>
        <LoggerIdInput
          placeholder={this.props.t('pages.Stock.StockMovementLog.list.table.logger')}
          disabled={false}
          onChange={(loggerId) => {
            this.setState({ loggerId: loggerId.trim() });
          }}
          value={loggerId}
        />
      </Form.Item>
    );
  };

  DescriptionField = () => {
    const { descriptions } = this.state;
    return (
      <Form.Item>
        <DescriptionMultiSelect
          placeholder={this.props.t('pages.Stock.StockMovementLog.list.table.description')}
          onChange={(descriptions) => {
            this.setState({ descriptions });
          }}
          value={descriptions}
        />
      </Form.Item>
    );
  };

  ClearFilterButton = () => (
    <Form.Item>
      <Button
        block
        onClick={() => {
          this.clearFilter();
        }}
      >
        {this.props.t('pages.Stock.StockMovementLog.list.filter.clean')}
      </Button>
    </Form.Item>
  );

  clearFilter = () => {
    const { currentWarehouse } = this.props;
    this.setState(
      {
        ...this.emptyTableFilter(),
        warehouses: [currentWarehouse],
      },
      () => {
        this.props.onClear(this.getTableFilter());
      },
    );
  };

  FilterButton = () => (
    <Form.Item>
      <Button
        type="primary"
        block
        onClick={() => {
          try {
            const filter = this.getTableFilter();
            this.props.onChange(filter);
            // eslint-disable-next-line no-empty
          } catch (e) { }
        }}
      >
        {this.props.t('pages.Stock.StockMovementLog.list.filter.filter')}
      </Button>
    </Form.Item>
  );

  getTableFilter = () => {
    const {
      warehouses,
      products,
      fromProductLocation,
      toProductLocation,
      descriptions,
      startDate,
      endDate,
      pickers,
      loggerModels,
      loggerId,
    } = this.state;
    console.log('getTableFilter state', this.state);
    const filter = {};

    filter.startDate = startDate || this.props.defaultDateRange.startDate;
    filter.endDate = endDate || this.props.defaultDateRange.endDate;

    const msForStartDate = new Date(startDate).getTime();
    const msForEndDate = new Date(endDate).getTime();
    const msTimeDiff = msForEndDate - msForStartDate;

    if (msTimeDiff > constants.ALLOWED_MAX_TIME_INTERVAL_FOR_SML_FILTER) {
      notification.error({
        duration: 3,
        message: this.props.t('pages.Stock.StockMovementLog.list.notification.timeIntervalError'),
      });
      throw new Error();
    }

    if (warehouses && warehouses.length) {
      filter.warehouses = warehouses.map((w) => w.id);
    }
    if (products && products.length) {
      filter.products = products.map((p) => p.id);
    }

    if (fromProductLocation && fromProductLocation.length) {
      filter.fromProductLocation = {
        warehouseLocations: fromProductLocation.map((p) => p.id),
      };
    }
    if (toProductLocation && toProductLocation.length) {
      filter.toProductLocation = {
        warehouseLocations: toProductLocation.map((p) => p.id),
      };
    }
    if (pickers && pickers.length) {
      filter.pickers = pickers.map((p) => p._id);
    }

    if (loggerModels && loggerModels.length) {
      filter.loggerModels = loggerModels.map((loggerModel) => loggerModel.key);
    }
    if (loggerId) {
      filter.loggerId = loggerId;
    }
    if (descriptions && descriptions.length) {
      filter.descriptions = descriptions.map((loggerModel) => loggerModel.key);
    }

    console.log('getTableFilter filter', filter);
    return filter;
  };

  render() {
    return (
      <div {...this.settings.main}>
        <this.TableFilterForm />
      </div>
    );
  }
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  isUserAdmin: state.auth.user.isAdmin,
  CWId: state.auth.currentWarehouse.id,
  currentWarehouse: state.auth.currentWarehouse,
  locationList: state.warehouseLocation.warehouseLocationList,
  warehouseList: state.warehouse.warehouseList,
  productList: state.product.productList,
  pickerList: state.picker.list,
});

const mapDispatchToProps = (dispatch) => ({
  onGetWarehouseList: () => {
    dispatch(warehouseAction.getWarehouseList());
  },
  onGetProductList: () => {
    dispatch(
      productAction.filterProductList({
        fields: 'barcodes fullName picURL packagingInfo',
        includeDefaultFields: false,
      }),
    );
  },
  onGetPickerList: (warehouseId) => {
    dispatch(pickerAction.getPickerList(warehouseId));
  },
  onGetLocationList: (warehouseId) => {
    dispatch(warehouseLocationAction.filterWarehouseLocations({ warehouseId, hasOperationType: true }));
  },
});

const StockMovementLogFilterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(StockMovementLogFilter));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { StockMovementLogFilterConnected as StockMovementLogFilter };
