import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import {
  Card,
  Row,
  Col,
  Button,
  Dropdown,
  Icon,
  Menu,
  notification,
  Modal,
  Input,
} from 'antd';
import { remove } from 'lodash';
import QRCode from 'qrcode';
/*----------------------------------------------------------------------------*/
import './TransferDetail.scss';
import { Can, permission } from './../../../../guard';
import { TransferDescription } from './TransferDescription';
import { TransferTable } from './TransferTable';
import { TransferPallet } from './TransferPallet';
import { WaybillTable } from './WaybillTable';
import { ProductSelect } from './ProductSelect';
import { TransferDataFormatter } from './TransferDataFormatter';
import { TransferDetailLogic } from './TransferDetailLogic';
/*----------------------------------------------------------------------------*/
import { Spinner, JsonButton, ActionTimeline } from './../../../../components';
import { transferAction, productAction, warehouseAction } from './../../../../actions';
import { getTranslation, constants } from './../../../../shared';
import { pageLoadEvents, TransferDetailEvents } from '../../../../services/events';
import { pageView, track } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/
const { TRANSFER_STATUS } = constants;
/*----------------------------------------------------------------------------*/
class TransferDetail extends Component {
  settings = {
    main: {
      className: 'TransferDetail_main',
    },
    card: {
      title: this.props.t('pages.Operation.Transfer.detail.pageTitle'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_TRANSFER_DETAIL });
    const {
      onGetWarehouseList,
    } = this.props;
    onGetWarehouseList();

    this.getDetail();
  }

  stateFactory = () => ({
    transfer: this.props.transfer,
    loading: null,
    selectTransferItems: [],
    selectedItems: null,
    selectedItem: null,
    fromWarehouseItems: null,
    isEditButtonEnabled: false,
    isEditButtonClicked: false,
    isSaveButtonEnabled: false,
    tableDisabled: true,
    note: '',
    isClickedApproveTransferButton: false,
    isClickedCancelTransferButton: false,
    isDeleteButtonEnabled: false,
    modalGenerateQrVisible: false,
    modalAddNoteVisible: false,
    isPalletBarcodeSaved: false,
    isShipButtonClicked: false,
  });

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {loading ? <Spinner /> : <this.PageDetail />}
        </Card>
        <this.ModalGenerateQrVisible />
        <this.ModalAddNote />
      </div>
    );
  }

  getWarehouseType = (warehouseId) => {
    const { warehouseList } = this.props;
    if (warehouseList.length) {
      const warehouse = warehouseList.find(item => item.id === warehouseId);
      return warehouse.type;
    }
  }

  CardExtra = (props) => {
    const { loading } = this.props;
    if (loading) {
      return null;
    }
    return (
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {this.props.transfer.status === 100 &&
        this.state.isEditButtonEnabled &&
        !this.props.isEditButtonClicked ? (
          <this.ProductField />
        ) : null}
        <Dropdown overlay={this.MenuAction}>
          <Button style={{ display: 'flex', alignItems: 'center' }}>
            {this.props.t(
              'pages.Operation.Transfer.detail.transfer.button.actions',
            )}{' '}
            <Icon type="down" />
          </Button>
        </Dropdown>
      </Row>
    );
  };

  onVisibilityChange = (isVisibilityEnabled) => {
    this.props.changeTransferVisibility(this.props.transfer.id, isVisibilityEnabled);
  }

  PageDetail = (props) => {
    return (
      <div>
        <Row>
          <Col span={6}>
            <TransferDescription
              transfer={this.props.transfer}
              CWId={this.props.CWId}
              onVisibilityChange={this.onVisibilityChange}
            />
            <ActionTimeline
              actions={this.props.transfer}
              translate={getTranslation.transferStatus}
            />
          </Col>
          <Col span={18}>
            <TransferTable
              transfer={this.props.transfer}
              onChange={this.onProductTableChange}
              disabled={this.state.tableDisabled}
              loading={
                this.state.isEditButtonClicked && this.props.productLoading
              }
              onChangeProductDelete={this.onChangeProductDelete}
            />
            {this.props.transfer.status > 100 ? (
              <TransferPallet transfer={this.props.transfer} />
            ) : null}
            {this.props.transfer.waybill ? (
              <WaybillTable waybill={this.props.transfer.waybill} />
            ) : null}
          </Col>
        </Row>
      </div>
    );
  };

  generateQr = () => {
    QRCode.toCanvas(
      document.getElementById('canvas'),
      this.getPalletQrStr(),
      { errorCorrectionLevel: 'H' },
      (error) => {
        if (error) {
          console.error(error);
        }
      },
    );
  };

  ModalGenerateQrVisible = (props) => {
    const { transfer } = this.props;
    if (!transfer || !transfer.status) {
      return null;
    }

    return (
      <Modal
        title={this.props.t('pages.Operation.Transfer.detail.modalQr.title')}
        visible={this.state.modalGenerateQrVisible}
        onOk={() => {
          this.setState({ modalGenerateQrVisible: false });
        }}
        onCancel={() => {
          this.setState({ modalGenerateQrVisible: false });
        }}
        footer={[
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>
              <Button
                key="close"
                type="danger"
                onClick={() => {
                  this.setState({ modalGenerateQrVisible: false });
                }}
              >
                {this.props.t(
                  'pages.Operation.Transfer.detail.modalQr.button.close',
                )}
              </Button>
            </span>
            <span>
              <Button
                key="print"
                type="primary"
                onClick={() => {
                  this.printCanvas();
                  this.setState({
                    isQrCodePrinted: true,
                    isPalletBarcodeSaved: false,
                  });
                }}
              >
                {this.props.t(
                  'pages.Operation.Transfer.detail.modalQr.button.print',
                )}
              </Button>
              <Button
                key="ship"
                disabled={
                  this.state.isShipButtonClicked ||
                  !this.state.isQrCodePrinted ||
                  !this.state.isPalletBarcodeSaved
                }
                loading={
                  this.state.isShipButtonClicked && this.state.isQrCodePrinted
                }
                type="primary"
                onClick={() => {
                  this.setState({ isShipButtonClicked: true });
                  this.setShip();
                }}
              >
                {this.props.t(
                  'pages.Operation.Transfer.detail.modalQr.button.ship',
                )}
              </Button>
            </span>
          </div>,
        ]}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <canvas id="canvas" width="300" height="300" />
        </div>
      </Modal>
    );
  };

  ModalAddNote = (props) => {
    const { transfer } = this.props;
    if (!transfer || !transfer.status) {
      return null;
    }

    return (
      <Modal
        title={this.props.t('pages.Operation.Transfer.detail.addNote')}
        visible={this.state.modalAddNoteVisible}
        onOk={() => {
          this.setState({ modalAddNoteVisible: false });
        }}
        onCancel={() => {
          this.setState({ modalAddNoteVisible: false });
        }}
        footer={[
          <div
            key="footer"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>
              <Button
                key="cancel"
                onClick={() => {
                  this.setState({ modalAddNoteVisible: false });
                  this.setState({ note: '' });
                }}
              >
                {this.props.t(
                  'pages.Operation.Transfer.detail.transfer.button.cancel',
                )}
              </Button>
            </span>
            <span>
              <Button
                key="ok"
                onClick={async () => {
                  await this.addNoteToTransfer(transfer.id, this.state.note);
                  this.setState({ modalAddNoteVisible: false });
                  this.setState({ note: '' });
                }}
              >
                {this.props.t(
                  'pages.Operation.Transfer.detail.transfer.button.save',
                )}
              </Button>
            </span>
          </div>,
        ]}
      >
        <Input.TextArea
          value={this.state.note}
          onChange={(event) => {
            this.setState({ note: event.target.value });
          }}
          maxLength={50}
          placeholder={this.props.t(
            'pages.Operation.Transfer.detail.addNotePlaceholder',
          )}
        />
      </Modal>
    );
  };

  setShip = async () => {
    const { id } = this.props;
    const { palletBarcode } = this.state;

    return this.shipDirect({ transferId: id, palletBarcode })
      .then(() => {
        this.setState(this.stateFactory());
      })
      .catch(() => {
        this.setState(this.stateFactory());
      });
  };

  printCanvas = () => {
    const { palletBarcode } = this.state;
    this.addTransferPalletBarcode(palletBarcode);
    const dataUrl = document.getElementById('canvas').toDataURL();
    let windowContent = '<!DOCTYPE html>';
    windowContent += '<html>';
    windowContent += '<head><title>Pallet QR</title></head>';
    windowContent += '<body>';
    windowContent += `<img src="${dataUrl}">`;
    windowContent += '</body>';
    windowContent += '</html>';
    const printWin = window.open('', '', 'width=400,height=400');
    const printEvent = new Event('print');
    printWin.document.open();
    printWin.document.write(windowContent);
    printWin.document.addEventListener(
      'print',
      () => {
        printWin.focus();
        printWin.print();
        printWin.document.close();
        printWin.close();
      },
      true,
    );
    printWin.document.addEventListener(
      'load',
      () => {
        printWin.document.dispatchEvent(printEvent);
      },
      true,
    );
  };

  addTransferPalletBarcode = (palletBarcode) => {
    const { fromWarehouse } = this.props.transfer;
    return this.addOutgoingTransferPalletBarcode({
      palletBarcode,
      warehouseId: fromWarehouse.id,
    });
  };

  getPalletQrStr = () => {
    const { fromWarehouse, toWarehouse, id, countryCode } = this.props.transfer;
    const from = this.transformStr(fromWarehouse.name);
    const to = this.transformStr(toWarehouse.name);
    const timestamp = Date.now().toString();
    const palletBarcode =
      countryCode === 'GB'
        ? `${from}.${to}.${id}`
        : `${from}.${to}.${timestamp}`;
    this.setState({ palletBarcode });
    return palletBarcode;
  };

  transformStr = (str) => {
    return this.replaceTurkish(str)
      .toUpperCase()
      .split(' ')
      .join('')
      .split("'")
      .join('')
      .slice(0, 3)
      .normalize();
  };

  replaceTurkish = (str) => {
    return str
      .replace('ü', 'u')
      .replace('ı', 'i')
      .replace('ö', 'o')
      .replace('ş', 's')
      .replace('ğ', 'g')
      .replace('ç', 'c')
      .replace('İ', 'I')
      .replace('Ö', 'O')
      .replace('Ü', 'U')
      .replace('Ş', 'S')
      .replace('Ğ', 'G')
      .replace('Ç', 'C');
  };

  MenuAction = (props) => (
    <Menu>
      <Menu.Item key="JsonButton">
        <JsonButton data={this.props.transfer} block />
      </Menu.Item>
      {this.props.transfer.status === TRANSFER_STATUS.SHIPPED &&
        this.props.transfer.integrationType ===
          constants.INTEGRATION_TYPE.EQUIPMENT && (
          <Menu.Item key="AddNote">
            <this.AddNoteButton />
          </Menu.Item>
        )}
      <Can allow={permission.shipTransfer}>
        <Menu.Item key="ShipButton">
          <this.ShipButton />
        </Menu.Item>
      </Can>
      {this.props.transfer.status !== 700 &&
      !this.state.isEditButtonEnabled &&
      this.props.transfer.status === 100 ? (
        <Menu.Item key="EditButton">
          <this.EditButton />
        </Menu.Item>
      ) : null}
      {this.props.transfer.status !== 700 && this.state.isSaveButtonEnabled ? (
        <Menu.Item key="SaveButton">
          <this.SaveButton />
        </Menu.Item>
      ) : null}
      {this.props.transfer.status !== 700 &&
      !this.state.isEditButtonEnabled &&
      this.props.transfer.status < 200 ? (
        <Menu.Item key="ApproveButton">
          <this.ApproveButton />
        </Menu.Item>
      ) : null}
      {this.props.transfer.status !== TRANSFER_STATUS.DELETED &&
      !this.state.isEditButtonEnabled &&
      this.props.transfer.status === TRANSFER_STATUS.APPROVED &&
      this.props.transfer.status !== TRANSFER_STATUS.CANCEL_REQUEST_SENT ? (
        <Menu.Item key="CancelButton">
          <this.CancelButton />
        </Menu.Item>
      ) : null}
      {this.props.transfer.status !== 700 &&
      !this.state.isEditButtonEnabled &&
      this.props.transfer.status === 100 ? (
        <Menu.Item key="DeleteButton">
          <this.DeleteButton />
        </Menu.Item>
      ) : null}
      {TransferDetailLogic.isSendableToWMS(
        this.props.transfer.directionType,
        this.props.transfer.status,
      ) ? (
        <Menu.Item key="WMSButton">
          <this.WMSButton />
        </Menu.Item>
      ) : null}
      {this.props.transfer.status === 200 &&
      !TransferDetailLogic.isFromWarehouseToWarehouse(
        this.props.transfer.directionType,
      ) &&
      TransferDetailLogic.isDevOrLocalEnvironment() ? (
        <Menu.Item key="DepartTransferVehicle">
          <this.DepartTransferVehicleButton />
        </Menu.Item>
      ) : null}
      {(this.props.transfer.status === 320 ||
        this.props.transfer.status === 500) &&
      !TransferDetailLogic.isTransferDeparted(this.props.transfer) &&
      !TransferDetailLogic.isFromWarehouseToCentral(
        this.props.transfer.directionType,
      ) &&
      !TransferDetailLogic.isFromWarehouseToWarehouse(
        this.props.transfer.directionType,
      ) &&
      TransferDetailLogic.isDevOrLocalEnvironment() ? (
        <Menu.Item key="SendDepartedOrder">
          <this.SendDepartedOrderButton />
        </Menu.Item>
      ) : null}
    </Menu>
  );

  AddNoteButton = () =>
  (
    <Button
      icon="edit"
      block
      onClick={this.onAddNoteClick}
      disabled={this.props.transfer.directionType === constants.DIRECTION_TYPES.WAREHOUSE_TO_SUPPLY_CHAIN_OPS}
    >
      {this.props.t('pages.Operation.Transfer.detail.addNote')}
    </Button>
  );


  EditButton = () => (
    <Button
      icon="edit"
      type="danger"
      block
      loading={this.state.isEditButtonClicked && this.props.productLoading}
      onClick={this.onEditClick}
    >
      {this.props.t('pages.Operation.Transfer.detail.transfer.button.edit')}
    </Button>
  );

  SaveButton = () => (
    <Button icon="save" block onClick={this.onSaveClick}>
      {this.props.t('pages.Operation.Transfer.detail.transfer.button.save')}
    </Button>
  );

  ApproveButton = () => (
    <Button
      icon="check"
      block
      onClick={this.onApproveClick}
      loading={this.state.isClickedApproveTransferButton}
      disabled={this.state.isClickedApproveTransferButton}
    >
      {this.props.t('pages.Operation.Transfer.detail.transfer.button.approve')}
    </Button>
  );

  CancelButton = () => (
    <Button
      block
      onClick={this.onCancelClick}
      loading={this.state.isClickedCancelTransferButton}
      disabled={this.state.isClickedCancelTransferButton}
    >
      {this.props.t('pages.Operation.Transfer.detail.transfer.button.cancel')}
    </Button>
  );

  DeleteButton = () => (
    <Button
      icon="delete"
      block
      onClick={this.onDeleteClick}
      loading={this.state.isClickedDeleteTransferButton}
      disabled={this.state.isClickedDeleteTransferButton}
    >
      {this.props.t('pages.Operation.Transfer.detail.transfer.button.delete')}
    </Button>
  );

  WMSButton = () => (
    <Button icon="transaction" block onClick={this.onSendToWMSClick}>
      {this.props.t(
        'pages.Operation.Transfer.detail.transfer.button.sendToWMS',
      )}
    </Button>
  );

  DepartTransferVehicleButton = () => (
    <Button icon="transaction" block onClick={this.DepartTransferVehicleClick}>
      {this.props.t(
        'pages.Operation.Transfer.detail.transfer.button.departTransferVehicle',
      )}
    </Button>
  );

  SendDepartedOrderButton = () => (
    <Button icon="transaction" block onClick={this.SendDepartedOrderClick}>
      {this.props.t(
        'pages.Operation.Transfer.detail.transfer.button.sendDepartedOrder',
      )}
    </Button>
  );

  ShipButton = (props) => {
    const { transfer } = this.props;
    if (!transfer || !transfer.status) {
      return null;
    }

    const warehouseType = this.getWarehouseType(transfer.id);
    return (
      <div>
        <Button
          block
          onClick={() => {
            this.setState({
              modalGenerateQrVisible: true,
              isQrCodePrinted: false,
              isPalletBarcodeSaved: false,
              isShipButtonClicked: false,
            });
            setTimeout(() => {
              this.generateQr();
            });
            track(TransferDetailEvents.TRANSFER_DETAIL_SHIP_BUTTON_CLICKED);
          }}
          disabled={
            (transfer.status !== TRANSFER_STATUS.APPROVED &&
            (warehouseType !== constants.WAREHOUSE_TYPE.MAIN_WAREHOUSE
              || warehouseType !== constants.WAREHOUSE_TYPE.REGULAR_WAREHOUSE
              || warehouseType !== constants.WAREHOUSE_TYPE.SC_GROCER_WAREHOUSE
              || warehouseType !== constants.WAREHOUSE_TYPE.FACTORY_WAREHOUSE))
              || TransferDetailLogic.isTransferStatusShipped(transfer.status)
            }

        >
          {this.props.t('pages.Operation.Transfer.detail.transfer.button.ship')}
        </Button>
      </div>
    );
  };

  SendDepartedOrderClick = async () => {
    const { transfer } = this.props;

    const preparedSendDepartedOrderData = TransferDataFormatter.prepareSendDepartedOrderData(
      transfer,
    );

    this.sendDepartedOrder(transfer.id, preparedSendDepartedOrderData);

    this.setState({
      tableDisabled: true,
      transfer,
    });
    setTimeout(track(TransferDetailEvents.TRANSFER_DETAIL_SEND_DEPARTED_ORDER_BUTTON_CLICKED));
  };

  DepartTransferVehicleClick = async () => {
    const { transfer } = this.props;

    const preparedDepartTransferVehicleData = TransferDataFormatter.prepareDepartTransferVehicleData(
      transfer,
    );

    this.departTransferVehicle(transfer.id, preparedDepartTransferVehicleData);

    this.setState({
      tableDisabled: true,
      transfer,
    });
    setTimeout(track(TransferDetailEvents.TRANSFER_DETAIL_DEPART_TRANSFER_VEHICLES_BUTTON_CLICKED));
  };

  onSendToWMSClick = async () => {
    const { transfer } = this.props;

    this.sendToWMS(transfer)
      .then(() => {
        this.setState({
          tableDisabled: true,
          transfer,
        });
        setTimeout(this.getDetail);
      })
      .catch(() => {
        this.setState({
          tableDisabled: true,
          transfer,
        });
      });

    setTimeout(track(TransferDetailEvents.TRANSFER_DETAIL_WMS_BUTTON_CLICKED));
  };

  onEditClick = async () => {
    await this.setPropsToLocalState({ transfer: this.props.transfer });

    const { transfer } = this.state;
    this.setState({
      isEditButtonClicked: true,
    });

    const productIds = TransferDetailLogic.getProductIdsFromWarehouseStocks(
      transfer.fromWarehouse.items,
    );

    const { products } = await this.props
      .onGetProductList(productIds)
      .catch((err) => {
        throw err;
      });

    transfer.fromWarehouse.items = TransferDetailLogic.getPreparedFromTransferItems(
      transfer,
      products,
    );

    const fromWarehouseItems = [...transfer.fromWarehouse.items];

    transfer.items.forEach((i) => {
      remove(fromWarehouseItems, (item) => item.item.id === i.item.id);
    });

    await this.setPropsToLocalState({ fromWarehouseItems });

    this.setState({
      tableDisabled: false,
      isEditButtonEnabled: true,
      isSaveButtonEnabled: true,
    });
    setTimeout(track(TransferDetailEvents.TRANSFER_DETAIL_EDIT_BUTTON_CLICKED));
  };

  onApproveClick = async () => {
    const { transfer } = this.props;

    this.setState({
      tableDisabled: true,
      transfer,
      isClickedApproveTransferButton: true,
      isClickedCancelTransferButton: false,
    });

    this.approveTransfer(transfer.id).catch(() => {
      this.setState({
        isClickedApproveTransferButton: false,
      });
    });

    setTimeout(track(TransferDetailEvents.TRANSFER_DETAIL_APPROVE_BUTTON_CLICKED));
  };

  setPropsToLocalState = (props) =>
    new Promise((resolve) => {
      setTimeout(() => {
        this.setState(
          {
            ...props,
          },
          () => {
            resolve();
          },
        );
      });
    });

  onSaveClick = async () => {
    await this.setPropsToLocalState({ transfer: this.props.transfer });
    const { transfer } = this.state;
    let { selectTransferItems } = this.state;

    if (!selectTransferItems.length) {
      selectTransferItems = transfer.items;
    }

    this.setState({
      selectTransferItems,
    });
    const transferId = transfer.id;
    const items = TransferDataFormatter.prepareSaveTransferData(
      selectTransferItems,
    );
    const foundItems = selectTransferItems.filter((i) => !i.count);

    if (foundItems.length) {
      foundItems.forEach((item) => {
        notification.error({
          duration: 1,
          message: this.props.t(
            'pages.Operation.Transfer.detail.transfer.errorNoticationDescription.orderQuantity',
          ),
          description: item.item.fullName[getTranslation.local()],
        });
      });
    } else {
      await this.props.onUpdateTransfer(transferId, items);
      this.setState({
        tableDisabled: true,
        isEditButtonEnabled: false,
        isSaveButtonEnabled: false,
      });
    }

    setTimeout(track(TransferDetailEvents.TRANSFER_DETAIL_SAVE_BUTTON_CLICKED));
  };

  onCancelClick = async () => {
    const { transfer } = this.props;

    this.setState({
      tableDisabled: true,
      transfer,
      isClickedApproveTransferButton: false,
      isClickedCancelTransferButton: true,
    });

    this.cancelTransfer(transfer.id).catch(() => {
      this.setState({
        isClickedCancelTransferButton: false,
      });
    });

    setTimeout(track(TransferDetailEvents.TRANSFER_DETAIL_CANCEL_BUTTON_CLICKED));
  };

  onAddNoteClick = async () => {
    this.setState({ modalAddNoteVisible: true });
  };

  onDeleteClick = async () => {
    const { transfer } = this.props;

    this.setState({
      tableDisabled: true,
      transfer,
      isClickedDeleteTransferButton: true,
    });

    this.deleteTransfer().catch(() => {
      this.setState({
        isClickedDeleteTransferButton: false,
      });
    });

    setTimeout(track(TransferDetailEvents.TRANSFER_DETAIL_DELETE_BUTTON_CLICKED));
  };

  onProductTableChange = (selectedItems) => {
    const {
      transfer,
      transfer: { items },
    } = this.props;
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      selectedItems,
    });

    selectedItems.forEach((selectedItem) => {
      const findItem = items.find((item) => item.item.id === selectedItem.item);
      if (findItem) {
        findItem.count = selectedItem.count;
        items.map((item) => {
          if (item.item.id === findItem.item.id) {
            return findItem;
          }
          return item;
        });
      }
    });

    this.setState({
      transfer: { ...transfer, items },
      selectTransferItems: items,
    });
  };

  ProductField = () => {
    const { transfer, fromWarehouseItems, tableDisabled } = this.state;
    const list = (transfer && transfer.fromWarehouse.items) || null;
    return (
      <div style={{ marginRight: 5 }}>
        <ProductSelect
          list={list}
          disabled={tableDisabled}
          fromWarehouseItems={fromWarehouseItems}
          onChange={this.onProductChange}
          placeholder={this.props.t(
            'pages.Operation.Transfer.detail.transfer.productField.add',
          )}
        />
      </div>
    );
  };

  onChangeProductDelete = async (selectedItem) => {
    const { fromWarehouseItems } = this.state;
    await this.setPropsToLocalState({ transfer: this.props.transfer });
    const { transfer } = this.state;
    remove(transfer.items, (item) => item.item.id === selectedItem.item.id);

    if (selectedItem.fromCount) {
      await this.setPropsToLocalState({
        fromWarehouseItems: [selectedItem, ...fromWarehouseItems],
      });
    }
    notification.success({
      duration: 0.7,
      message: this.props.t(
        'pages.Operation.Transfer.detail.transfer.successNoticationDescription.productDeleted',
      ),
      description: selectedItem.item.fullName[getTranslation.local()],
    });
    this.setState({
      transfer: {
        ...transfer,
        items: transfer.items,
      },
      selectTransferItems: transfer.items,
    });
  };

  onProductChange = (selectedItem) => {
    notification.success({
      duration: 0.7,
      message: this.props.t(
        'pages.Operation.Transfer.detail.transfer.successNoticationDescription.productAdded',
      ),
      description: selectedItem.item.fullName[getTranslation.local()],
    });
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      selectedItem,
    });
    const {
      transfer,
      transfer: { items },
    } = this.props;
    const findItem = items.find(
      (item) => item.item.id === selectedItem && selectedItem.item.id,
    );

    if (findItem) {
      findItem.count = selectedItem.count;
      items.map((item) => {
        if (item.item.id === findItem.item.id) {
          return findItem;
        }
        return item;
      });
    }

    if (!findItem && selectedItem) {
      items.push({
        item: {
          ...selectedItem.item,
        },
        count: 0,
      });
    }

    this.setState({
      fromWarehouseItems: [...transfer.fromWarehouse.items],
    });
    const { fromWarehouseItems } = this.state;

    items.forEach((i) => {
      remove(fromWarehouseItems, (item) => item.item.id === i.item.id);
    });

    this.setState({
      transfer: {
        ...transfer,
        items,
      },
      fromWarehouseItems,
      selectTransferItems: items,
    });
  };

  getDetail = async () => this.props.onGetTransferDetail(this.props.id);

  approveTransfer = async (transferId) =>
    this.props.onApproveTransfer(transferId);

  addNoteToTransfer = async (transferId, note) => {
    this.props.onAddNoteToTransfer(transferId, note);
  };

  cancelTransfer = async (transferId) =>
    this.props.onCancelTransfer(transferId);

  deleteTransfer = async () => this.props.onDeleteTransfer(this.props.id);

  sendToWMS = async (transfer) => this.props.onSendToWMS(transfer);

  departTransferVehicle = async (transferId, data) =>
    this.props.departTransferVehicle(transferId, data);

  sendDepartedOrder = async (transferId, data) =>
    this.props.sendDepartedOrder(transferId, data);

  shipDirect = async (data) => this.props.onShipDirect(data);

  addOutgoingTransferPalletBarcode = (palletBarcode) => {
    this.props
      .onAddOutgoingTransferPalletBarcode(palletBarcode)
      .then(() => {
        this.setState({
          isPalletBarcodeSaved: true,
        });
      })
      .catch(() => {
        this.setState({
          modalGenerateQrVisible: false,
          isPalletBarcodeSaved: false,
        });
      });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  transfer: state.transfer.detail.data,
  productList: state.product.productList,
  productLoading: state.product.productList.loading,
  loading: state.transfer.detail.loading,
  transferVisibilityLoading: state.transfer.changeTransferVisibilityStatus.loading,
  warehouseList: state.warehouse.warehouseList,
});

const mapDispatchToProps = (dispatch) => ({
  onGetProductList: (productIds) => {
    return dispatch(
      productAction.filterProductList({
        fields: 'barcodes picURL fullName packagingInfo',
        includeDefaultFields: false,
        productIds,
      }),
    );
  },
  onGetTransferDetail: (id) => {
    return dispatch(transferAction.getTransferDetail(id));
  },
  onUpdateTransfer: (transferId, items) => {
    return dispatch(transferAction.updateTransferItems(transferId, items));
  },
  onCancelTransfer: (transferId) => {
    return dispatch(transferAction.cancelTransfer({ transferId }));
  },
  onApproveTransfer: (transferId) => {
    return dispatch(transferAction.approveTransfer({ transferId }));
  },
  onDeleteTransfer: (id) => {
    return dispatch(transferAction.deleteTransfer(id));
  },
  onAddOutgoingTransferPalletBarcode: (palletBarcode) => {
    return dispatch(
      transferAction.addOutgoingTransferPalletBarcode(palletBarcode),
    );
  },
  onShipDirect: (data) => {
    return dispatch(transferAction.shipDirect(data));
  },
  onSendToWMS: (transfer) => {
    return dispatch(transferAction.bulkSendToWmsTransfer([transfer.id]));
  },
  departTransferVehicle: (transferId, data) => {
    return dispatch(transferAction.departTransferVehicle(transferId, data));
  },
  sendDepartedOrder: (transferId, data) => {
    return dispatch(transferAction.sendDepartedOrder(transferId, data));
  },
  onAddNoteToTransfer: (transferId, note) => {
    return dispatch(transferAction.addNoteToTransfer(transferId, note));
  },
  changeTransferVisibility: (transferId, isVisibilityEnabled) => {
    return dispatch(transferAction.changeTransferVisibility(transferId, isVisibilityEnabled));
  },
  onGetWarehouseList: () => {
    dispatch(warehouseAction.getWarehouseList());
  },
});

const TransferDetailConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(TransferDetail));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { TransferDetailConnected as TransferDetail };
