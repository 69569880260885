import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Button, Card } from 'antd';
/*----------------------------------------------------------------------------*/
import './BlockedStockRuleList.scss';
import moment from 'moment';
import { BlockedStockRuleFilter } from './BlockedStockRuleFilter';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard } from './../../../../../components';
import { getTranslation, getDateFormatString } from './../../../../../shared';
import { blockedStockAction } from './../../../../../actions';
import { pageLoadEvents } from '../../../../../services/events';
import { pageView } from '../../../../../services/segment';
/*----------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------
------------------------------------------------------------------------------*/

class BlockedStockRuleList extends Component {
  settings = {
    main: {
      className: 'BlockedStockRuleList_main',
    },
    card: {
      title: this.props.t(
        'pages.Operation.BlockedStock.decisionRule.list.title',
      ),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  dateFormat = getDateFormatString();

  constructor(props) {
    pageView({ pageName: pageLoadEvents.OPS_REMOVE_FROM_SALES_RULES });
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    this.getList();
  }

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 5,
      pageSize: 5,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      filter: this.getDefaultFilter(),
    };
  };

  getDefaultFilter = () => {
    return null;
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageFilter />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    return <this.CreateButton />;
  };

  PageFilter = () => {
    return (
      <BlockedStockRuleFilter
        onChange={this.setTableFilter}
        onClear={this.clearTableFilter}
      />
    );
  };

  PageTable = (props) => {
    return (
      <div>
        <Table
          size="small"
          dataSource={this.prepareData(this.props)}
          columns={this.prepareColumns(this.props)}
          rowKey={(record) => {
            return record.id;
          }}
          expandedRowRender={this.expandedRowRender}
          pagination={{
            ...this.state.pagination,
            total: this.props.totalCount,
          }}
          loading={{ indicator: <Spinner />, spinning: this.props.loading }}
          onChange={this.handleTableChange}
        />
      </div>
    );
  };

  expandedRowRender = (record) => (
    <Table
      size="small"
      columns={this.prepareExpandedColumns(this.props)}
      dataSource={this.prepareExpandedData(record)}
      components={this.components}
      rowKey={(record) => record.id}
      pagination={false}
    />
  );

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      query: {
        limit: pagination.pageSize,
        offset: pagination.pageSize * (pagination.current - 1),
      },
    });
    this.setState({
      pagination: {
        ...this.state.pagination,
        ...pagination,
      },
    });
    setTimeout(() => {
      this.getList();
    });
  };

  prepareData = (props) => {
    return this.props.list;
  };

  prepareExpandedData = (record) => {
    return [record];
  };

  prepareColumns = (props) => {
    return [
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.decisionRule.list.table.createdAt',
        ),
        dataIndex: 'createdAt',
        render: (date) =>
          moment(new Date(date))
            .local()
            .format(this.dateFormat),
      },

      {
        title: this.props.t(
          'pages.Operation.BlockedStock.decisionRule.list.table.supplier',
        ),
        dataIndex: 'supplier',
        render: (supplier) => {
          return <>{supplier.name}</>;
        },
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.decisionRule.list.table.reason',
        ),
        dataIndex: 'reasons',
        render: (reasons) => {
          const renderedReasons = reasons.map((reason) => {
            return (
              <CopyToClipboard
                key={reason.id}
                message={reason.value}
                style={{ margin: 2 }}
              />
            );
          });
          return <>{renderedReasons}</>;
        },
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.decisionRule.list.table.subReason',
        ),
        dataIndex: 'subReasons',
        render: (subReasons) => {
          const renderedSubReasons = subReasons.map((reason) => {
            return (
              <CopyToClipboard
                key={reason.id}
                message={reason.value}
                style={{ margin: 2 }}
              />
            );
          });
          return <>{renderedSubReasons}</>;
        },
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.decisionRule.list.table.conclusionType',
        ),
        dataIndex: 'conclusionType',
        render: (conclusionType) =>
          getTranslation.blockedStockConclusionType(conclusionType),
      },

      { dataIndex: 'id', render: (id) => <this.DetailButton id={id} /> },
    ];
  };

  prepareExpandedColumns = (props) => [
    {
      title: this.props.t(
        'pages.Operation.BlockedStock.decisionRule.list.table.product',
      ),
      dataIndex: 'products',
      key: 'products',
      render: (products) => {
        const renderedProducts = products.map((product) => {
          return (
            <CopyToClipboard
              key={product.id}
              message={product.fullName}
              style={{ margin: 2 }}
            />
          );
        });
        return <>{renderedProducts}</>;
      },
    },
    {
      title: this.props.t(
        'pages.Operation.BlockedStock.decisionRule.list.table.warehouse',
      ),
      dataIndex: 'warehouses',
      key: 'warehouses',
      render: (warehouses) => {
        const renderedWarehouses = warehouses.map((warehouse) => {
          return (
            <CopyToClipboard
              key={warehouse.id}
              message={warehouse.name}
              style={{ margin: 2 }}
            />
          );
        });
        return <>{renderedWarehouses}</>;
      },
    },
  ];

  DetailButton = (props) => {
    const { id } = props;
    const url = `/stock-operation/blocked-stock/decision-rule/detail/${id}`;
    return (
      <Link to={url}>
        <Button size="small">
          {this.props.t(
            'pages.Operation.BlockedStock.decisionRule.list.table.detail',
          )}
        </Button>
      </Link>
    );
  };

  CreateButton = (props) => {
    const url = '/stock-operation/blocked-stock/decision-rule/create';
    return (
      <Link to={url}>
        <Button type="danger">
          {this.props.t(
            'pages.Operation.BlockedStock.decisionRule.list.button.create',
          )}
        </Button>
      </Link>
    );
  };

  getList = () => {
    const query = this.getQuery();
    const filter = this.getFilter();
    this.props.onGetBlockedStockRuleList(query, filter);
  };

  getQuery = () => {
    return this.state.query;
  };

  getFilter = () => {
    return this.state.filter;
  };

  setTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      }),
      this.getList,
    );
  };

  clearTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      }),
      this.getList,
    );
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.blockedStock.ruleList.loading,
  list: state.blockedStock.ruleList.data,
  totalCount: state.blockedStock.ruleList.total,
});

const mapDispatchToProps = (dispatch) => ({
  onGetBlockedStockRuleList: (query, filter) => {
    return dispatch(blockedStockAction.filterBlockedStockRule(query, filter));
  },
});

const BlockedStockRuleListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(BlockedStockRuleList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { BlockedStockRuleListConnected as BlockedStockRuleList };
