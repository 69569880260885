import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';

export const disposalAction = {
  filterDisposalList,
  completeDisposalBulk,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function filterDisposalList(query, filter) {
  return (dispatch) => {
    dispatch({ type: actionType.filterDisposalList_InProgress });
    http
      .filterDisposalList(query, filter)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.filterDisposalList_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.filterDisposalList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.filterDisposalList_Failed,
          payload: error,
        });
      });
  };
}

function completeDisposalBulk(reportBulk, files, query, filter, errorMessage) {
  console.log('completeDisposalBulk reducer');
  console.log(reportBulk, files);
  return (dispatch) => {
    dispatch({ type: actionType.completeDisposalBulk_InProgress });
    uploadFiles(files)
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.completeDisposal_Failed),
          description: errorMessage,
        });
        dispatch(filterDisposalList(query, filter));
        throw error;
      })
      .then((uploadedFiles) => {
        const payload = {
          blockedStockIds: reportBulk.map((disposal) => disposal.id),
          imageUrl: uploadedFiles[0].image,
        };
        return http
          .createDisposal(payload)
          .then(() => {
            notification.success({
              duration: 4,
              message: ErrorInfo.message(actionType.completeDisposal_Succeeded),
            });
          })
          .catch((error) => {
            notification.error({
              duration: 4,
              message: ErrorInfo.message(actionType.completeDisposal_Failed),
              description: ErrorInfo.description(error),
            });
          });
      })
      .then(() => {
        dispatch({ type: actionType.completeDisposalBulk_Succeeded });
        dispatch(filterDisposalList(query, filter));
      });
  };
}

function uploadFiles(files) {
  const filesPromises = files.map((file) => {
    return http
      .uploadWaybill({
        file: {
          name: file.name,
          type: file.type,
          size: file.size,
        },
        folderName: 'disposal/',
      })
      .then((response) => {
        return http
          .uploadToS3(
            response.data.data.uploadUrls.signedUrl,
            file.originFileObj,
          )
          .then(() => {
            return response.data;
          });
      });
  });
  return Promise.all(filesPromises).then((values) => {
    return files.map((file, i) => ({
      ss: file.ss,
      date: file.date,
      image: values[i].data.uploadUrls.imageUrl,
    }));
  });
}
