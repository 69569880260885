import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';
import { LocalDatabase, constants } from './../shared';

export const supplierAction = {
  getSupplierList,
  getSupplierDetail,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getSupplierList(filter = null, shouldStorage = true) {
  return async (dispatch) => {
    dispatch({ type: actionType.getSupplierList_InProgress });

    const suppliers = await LocalDatabase.getWithExpiry('suppliers');

    if (shouldStorage && suppliers) {
      dispatch({
        type: actionType.getSupplierList_Succeeded,
        payload: suppliers,
      });
      return Promise.resolve(suppliers);
    } else {
      return http
        .getSupplierList(filter)
        .then(async (response) => {
          const { data } = response.data;

          if (shouldStorage) {
            await LocalDatabase.setWithExpiry(
              'suppliers',
              data,
              constants.TTL_FOR_CACHE.SUPPLIERS,
            );
          }

          dispatch({
            type: actionType.getSupplierList_Succeeded,
            payload: data,
          });

          return data;
        })
        .catch((error) => {
          notification.error({
            duration: 4,
            message: ErrorInfo.message(actionType.getSupplierList_Failed),
            description: ErrorInfo.description(error),
          });
          dispatch({
            type: actionType.getSupplierList_Failed,
            payload: error,
          });
          return null;
        });
    }
  };
}

function getSupplierDetail(id) {
  return (dispatch) => {
    dispatch({ type: actionType.getSupplierDetail_InProgress });
    http
      .getSupplierDetail(id)
      .then((response) => {
        dispatch({
          type: actionType.getSupplierDetail_Succeeded,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getSupplierDetail_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getSupplierDetail_Failed,
          payload: error,
        });
      });
  };
}
