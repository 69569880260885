import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Button, Card, Form, Modal } from 'antd';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './BlockedStockBulkDecision.scss';
import { BlockedStockFilter } from './BlockedStockFilter';
import { ConclusionTypeSelect } from './ConclusionTypeSelect';
import { SupplierSelect } from './SupplierSelect';
import { SupplierAccountSelect } from './SupplierAccountSelect';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard } from './../../../../components';
import { getTranslation, tableHelper, constants, statuses, getDateFormatString } from './../../../../shared';
import { blockedStockAction } from './../../../../actions';
import i18n from '../../../../i18n';
import { pageLoadEvents } from '../../../../services/events';
import { pageView } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/
const { BLOCKED_STOCK_CONCLUSION_TYPES } = constants;

class BlockedStockBulkDecision extends Component {
  settings = {
    main: {
      className: 'BlockedStockBulkDecision_main',
    },
    card: {
      title: this.props.t('pages.Operation.BlockedStock.bulkDecision.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  dateFormat = getDateFormatString();

  formItemLayout = {
    style: {
      marginTop: 15,
      marginBottom: 15,
    },
    labelCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 8, offset: 0 },
    },
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 16, offset: 0 },
    },
  };

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.defaultStatuses = this.getDefaultStatuses();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_REMOVE_FROM_SALES_BULK_OPERATION });
    this.getList();
  }

  getDefaultStatuses = () => {
    const { CREATED } = constants.BLOCKED_STOCK_STATUSES;
    const { blockedStockStatus } = statuses;
    const defaultStatuses = [
      {
        key: CREATED,
        value: this.props.t(blockedStockStatus[CREATED]),
      },
    ];
    return defaultStatuses;
  };

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      isOpenModalConclude: false,
      isConcludeClicked: false,
      conclusionType: null,
      supplier: null,
      account: null,
      blockedStockBulk: [],
      pagination,
      query,
      filter: this.getDefaultFilter(),
      errorBulkConclude: [],
    };
  };

  getDefaultFilter = () => {
    return null;
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageFilter />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    return <this.ConcludeButton />;
  };

  ConcludeButton = (props) => {
    const { blockedStockBulk } = this.state;
    return (
      <div>
        <Button
          block
          disabled={!blockedStockBulk.length}
          onClick={() => {
            this.setState({ isOpenModalConclude: true });
          }}
        >
          {this.props.t(
            'pages.Operation.BlockedStock.bulkDecision.button.conclude',
          )}
        </Button>
        <Modal
          okButtonProps={{
            loading: this.state.isConcludeClicked,
            disabled: !this.canBeDecided() || this.state.isConcludeClicked,
          }}
          cancelButtonProps={{
            disabled: this.state.isConcludeClicked,
          }}
          title={this.props.t(
            'pages.Operation.BlockedStock.bulkDecision.modal.conclude',
          )}
          visible={this.state.isOpenModalConclude}
          onOk={() => {
            console.log('onOk', this.state);
            this.setState({
              isConcludeClicked: true,
            });
            this.concludeBlockedStockBulk()
              .then(() => {
                this.setState(
                  {
                    ...this.state,
                    isOpenModalConclude: false,
                    isConcludeClicked: false,
                    conclusionType: null,
                    supplier: null,
                    account: null,
                    blockedStockBulk: [],
                  },
                  this.afterBlockedStockBulkDecision,
                );
              })
              .catch((err) => {
                const successBulkConculude = err
                  .filter(
                    (e) => e.status === 'fulfilled' && e.value.data.data._id,
                  )
                  .map((e) => e.value.data.data._id);
                const errorBulkConclude = blockedStockBulk.filter(
                  (x) => !successBulkConculude.includes(x),
                );
                this.clearState();
                setTimeout(() => {
                  this.getList();
                });
                if (errorBulkConclude.length) {
                  this.setState({
                    errorBulkConclude: errorBulkConclude.map((bs) => bs.id),
                  });
                }
              });
          }}
          onCancel={() => {
            console.log('onCancel', this.state);
            this.setState({
              ...this.state,
              isOpenModalConclude: false,
              isConcludeClicked: false,
              conclusionType: null,
              supplier: null,
              account: null,
              blockedStockBulk: [],
            });
          }}
        >
          <this.ConcludeForm />
        </Modal>
      </div>
    );
  };

  ConcludeForm = (props) => {
    return (
      <Form>
        <this.ConclusionTypeField />
        <this.SupplierAndAccountFields />
      </Form>
    );
  };

  SupplierAndAccountFields = () => {
    if (this.isConcludeTypeRefund()) {
      return (
        <>
          <this.SupplierField />
          <this.SupplierAccountField />
        </>
      );
    }
    return null;
  };

  SupplierField = (props) => {
    const { supplierList } = this.props;
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.bulkDecision.filter.supplier',
        )}
      >
        <SupplierSelect
          onChange={(supplier) => {
            console.log('supplier', supplier);
            this.setState((state) => {
              return { ...state, account: null, supplier };
            });
          }}
          disabled
          value={this.state.supplier}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.bulkDecision.filter.supplier',
          )}
          list={supplierList}
        />
      </Form.Item>
    );
  };

  SupplierAccountField = (props) => {
    const { supplier } = this.state;
    if (!supplier) {
      return null;
    }
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.bulkDecision.filter.account',
        )}
      >
        <SupplierAccountSelect
          onChange={(account) => {
            console.log('account', account);
            this.setState({ account });
          }}
          value={this.state.account}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.bulkDecision.filter.account',
          )}
          list={supplier.accounts}
        />
      </Form.Item>
    );
  };

  canBeDecided = () => {
    const { supplier, account, conclusionType } = this.state;
    if (
      (this.isConcludeTypeRefund() && supplier && account) ||
      (conclusionType && !this.isConcludeTypeRefund())
    ) {
      return true;
    }
    return false;
  };

  isConcludeTypeRefund = () => {
    const { conclusionType } = this.state;
    if (
      conclusionType &&
      conclusionType.key === BLOCKED_STOCK_CONCLUSION_TYPES.REFUND.toString()
    ) {
      return true;
    }
    return false;
  };

  ConclusionTypeField = () => {
    const { conclusionType } = this.state;
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t(
          'pages.Operation.BlockedStock.bulkDecision.form.conclusionType',
        )}
      >
        <ConclusionTypeSelect
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.bulkDecision.form.conclusionType',
          )}
          value={conclusionType}
          onChange={(conclusionType) => {
            this.setState({ conclusionType });
          }}
        />
      </Form.Item>
    );
  };

  PageFilter = () => {
    return (
      <BlockedStockFilter
        defaultDateRange={this.defaultDateRange}
        defaultStatuses={this.defaultStatuses}
        onChange={this.setTableFilter}
        onClear={this.clearTableFilter}
      />
    );
  };

  PageTable = (props) => {
    const { errorBulkConclude } = this.state;
    return (
      <div>
        <Table
          size="small"
          rowSelection={this.getRowSelection()}
          dataSource={this.prepareData()}
          columns={this.prepareColumns()}
          rowKey={(record) => {
            return record.id;
          }}
          rowClassName={(record, index) =>
            errorBulkConclude.length && errorBulkConclude.includes(record.id)
              ? 'red-color'
              : ''
          }
          pagination={{
            ...this.state.pagination,
            total: this.props.totalCount,
          }}
          loading={{ indicator: <Spinner />, spinning: this.props.loading }}
          onChange={this.handleTableChange}
        />
      </div>
    );
  };

  getRowSelection = () => {
    const { blockedStockBulk } = this.state;
    return {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          'selectedRows: ',
          selectedRows,
        );
        this.setState({ blockedStockBulk: [...selectedRows] });
      },
      selectedRowKeys: blockedStockBulk.map((bs) => bs.id),
    };
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState((state) => {
      return {
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      };
    }, this.getList);
  };

  prepareData = () => {
    const { list } = this.props;
    if (!list) {
      return [];
    }

    const tableData = list.map((item) => {
      return {
        ...item,
        table: {
          id: item.id,
          status: getTranslation.blockedStockStatus(item.status),
          quantity: item.count,
          reason: item.reason && item.reason.values,
          subReason: item.subReason && item.subReason.values,
          product: item.product,
          warehouse: item.warehouse.name,
          createdAt: moment(new Date(item.createdAt))
            .local()
            .format(this.dateFormat),
        },
      };
    });
    return tableData;
  };

  prepareColumns = () => {
    return [
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.bulkDecision.table.warehouse',
        ),
        dataIndex: 'table.warehouse',
        key: 'table.warehouse',
        render: (warehouse) => <CopyToClipboard message={warehouse} />,
        ...tableHelper.fieldSorter.getStringSortProps('table.warehouse'),
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.bulkDecision.table.product',
        ),
        dataIndex: 'table.product',
        key: 'table.product',
        render: (product) => {
          return (
            <CopyToClipboard message={getTranslation.obj(product.fullName)} />
          );
        },
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.bulkDecision.table.supplier',
        ),
        dataIndex: 'table.product',
        key: 'table.supplier',
        width: 200,
        render: (product) => {
          if (!product.suppliers || !product.suppliers.length) {
            return '-';
          }
          return product.suppliers.map((supplier) => {
            return (
              <CopyToClipboard
                message={getTranslation.obj(supplier.name)}
                key={supplier.id}
                style={{ margin: 2 }}
              />
            );
          });
        },
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.bulkDecision.table.quantity',
        ),
        dataIndex: 'table.quantity',
        key: 'table.quantity',
        ...tableHelper.fieldSorter.getNumberSortProps('table.quantity'),
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.bulkDecision.table.reason',
        ),
        dataIndex: 'table.reason',
        key: 'table.reason',
        render: (reason) => <CopyToClipboard message={reason} />,
        ...tableHelper.fieldSorter.getStringSortProps('table.reason'),
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.bulkDecision.table.subReason',
        ),
        dataIndex: 'table.subReason',
        key: 'table.subReason',
        render: (subReason) => {
          return subReason ? <CopyToClipboard message={subReason} /> : '-';
        },
        ...tableHelper.fieldSorter.getStringSortProps('table.subReason'),
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.bulkDecision.table.status',
        ),
        dataIndex: 'table.status',
        key: 'table.status',
        ...tableHelper.fieldSorter.getStringSortProps('table.status'),
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.bulkDecision.table.createdAt',
        ),
        dataIndex: 'table.createdAt',
        key: 'table.createdAt',
        ...tableHelper.fieldSorter.getDateSortProps('table.createdAt'),
      },
      {
        dataIndex: 'table.id',
        key: 'table.id',
        render: (id) => <this.DetailButton id={id} />,
      },
    ];
  };

  DetailButton = (props) => {
    const { id } = props;
    return (
      <Link to={`/stock-operation/blocked-stock/detail/${id}`}>
        <Button size="small">
          {this.props.t(
            'pages.Operation.BlockedStock.bulkDecision.table.detail',
          )}
        </Button>
      </Link>
    );
  };

  getList = () => {
    const query = this.getQuery();
    const filter = this.getFilter();
    if (
      filter &&
      filter.createdAt &&
      filter.createdAt.startDate &&
      filter.createdAt.endDate
    ) {
      this.props.onGetBlockedStockList(query, filter);
    }
  };

  getQuery = () => {
    return this.state.query;
  };

  getFilter = () => {
    const filter = this.state.filter || {};
    if (!filter.createdAt) {
      filter.createdAt = this.defaultDateRange;
    }
    if (!filter.statuses) {
      filter.statuses = this.defaultStatuses.map((s) => s.key);
    }
    return filter;
  };

  getSupplierFromFilter = (filter) => {
    const { supplierList } = this.props;
    let filterSupplierId = null;
    try {
      filterSupplierId = filter.suppliers[0];
    } catch (error) {
      filterSupplierId = null;
    }
    if (!filterSupplierId) {
      return null;
    }
    const supplier = supplierList.find((s) => s._id === filterSupplierId);
    return supplier;
  };

  setTableFilter = (filter) => {
    const supplier = this.getSupplierFromFilter(filter);
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
        supplier,
        isOpenModalConclude: false,
        isConcludeClicked: false,
        conclusionType: null,
        blockedStockBulk: [],
      };
    }, this.getList);
  };

  clearTableFilter = (filter) => {
    const supplier = this.getSupplierFromFilter(filter);
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
        supplier,
        isOpenModalConclude: false,
        isConcludeClicked: false,
        conclusionType: null,
        blockedStockBulk: [],
      };
    }, this.getList);
  };

  concludeBlockedStockBulk = () => {
    const { conclusionType, blockedStockBulk, supplier, account } = this.state;
    const { onBlockedStockBulkDecision } = this.props;
    if (!blockedStockBulk.length) {
      return;
    }
    const payload = { conclusionType: conclusionType.key };
    if (this.isConcludeTypeRefund()) {
      payload.supplier = supplier._id;
      payload.accountCode = account.code;
    }
    return onBlockedStockBulkDecision(
      blockedStockBulk.map((bs) => bs.id),
      payload,
    );
  };

  afterBlockedStockBulkDecision = () => {
    const filter = this.getFilter();
    this.clearTableFilter(filter);
  };

  clearState = () => {
    this.setState(this.stateFactory());
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.blockedStock.blockedStockList.loading,
  list: state.blockedStock.blockedStockList.data,
  totalCount: state.blockedStock.blockedStockList.total,
  supplierList: state.supplier.list.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetBlockedStockList: (query, filter) => {
    dispatch(blockedStockAction.filterBlockedStock(query, filter));
  },
  onBlockedStockBulkDecision: (blockedStockBulk, decision) => {
    console.log(blockedStockBulk, decision);
    return dispatch(
      blockedStockAction.bulkConcludeBlockedStock(
        blockedStockBulk,
        decision,
        i18n.t(
          'pages.Operation.BlockedStock.bulkDecision.notification.errorMessageForBulkBlockedStocked',
        ),
      ),
    );
  },
});

const BlockedStockBulkDecisionConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(BlockedStockBulkDecision));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { BlockedStockBulkDecisionConnected as BlockedStockBulkDecision };
