import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Progress } from 'antd';

class Countdown extends Component {
  state = {
    ttl: this.props.ttl,
    intervalId: '',
  };

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  componentDidMount() {
    const timer = setInterval(() => {
      if (this.state.ttl > 0 && this.state.ttl !== null) {
        this.setState((prevState) => {
          return {
            ttl: prevState.ttl - 1,
            intervalId: timer,
          };
        });
      }
    }, 1000);
  }

  render() {
    if (this.state.ttl === 0) {
      return (
        <p
          style={{ textAlign: 'center', marginBottom: '30px', color: 'red' }}
        >
          {this.props.t('main.OtpModalContent.pleaseLoginAgain')}
        </p>
      );
    }
    if (this.state.ttl !== null) {
      return (
        <Progress
          percent={(this.state.ttl * 100) / this.props.ttl}
          format={() =>
            `${this.state.ttl} ${this.props.t('main.OtpModalContent.sec')}`
          }
          width={48}
          strokeColor={{
            '0%': '#6254B2',
            '100%': '#77C092',
          }}
          style={{ marginBottom: '30px' }}
        />
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  ttl: state.auth.ttl,
});

const mapDispatchToProps = (dispatch) => ({});

const CountdownConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(Countdown));

export { CountdownConnected as Countdown };
