import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
};

const switcher = {
  [actionType.filterCityList_InProgress]: filterCityList_InProgress,
  [actionType.filterCityList_Succeeded]: filterCityList_Succeeded,
  [actionType.filterCityList_Failed]: filterCityList_Failed,
};

export const cityReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function filterCityList_InProgress(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function filterCityList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload.cities,
      error: null,
      total: payload.total || payload.cities.length,
    },
  });
}

function filterCityList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}
