import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Descriptions, Row, Typography } from 'antd';
import { withNamespaces } from 'react-i18next';

import { Link } from 'react-router-dom';

import './WarehouseStatusControl.scss';
import { CopyToClipboard, Spinner, YesNoTag } from '../../../components';
import { warehouseAction, inventoryAction } from '../../../actions';
import { WarehouseSelect } from '../../Stock/InventoryCheck/create/formFields';
import { pageLoadEvents } from '../../../services/events';
import { pageView } from '../../../services/segment';

class WarehouseStatusControl extends Component {
  settings = {
    main: {
      className: 'WarehouseStatusControl',
    },
    card: {
      title: this.props.t('pages.Operation.WarehouseStatusControl.title'),
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_WAREHOUSE_STATUS });
    this.props.onGetWarehouseList();
  }

  stateFactory = () => {
    return {
      warehouse: undefined,
    };
  };

  renderData = () => {
    const { data, loading, error } = this.props.warehouseStatusControl;
    if (loading) {
      return <Spinner />;
    }
    if (error) {
      return (
        <Typography>
          {this.props.t('pages.operation.warehouseStatusControl.error')}
        </Typography>
      );
    }

    return (
      this.state.warehouse &&
      this.state.warehouse.length &&
      data && (
        <>
          <Descriptions
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
            layout="vertical"
          >
            <Descriptions.Item
              label={this.props.t(
                'pages.operation.warehouseStatusControl.inventoryCheckEligilibity',
              )}
            >
              {data.inventoryCheckEligibilityStatus}
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t(
                'pages.operation.warehouseStatusControl.areThereAnyActiveRefundOrders',
              )}
            >
              <YesNoTag flag={data.areThereAnyActiveRefundOrders} />
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t(
                'pages.operation.warehouseStatusControl.areThereAnyActiveCancelledOrders',
              )}
            >
              <YesNoTag flag={data.areThereAnyActiveCancelledOrders} />
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t(
                'pages.operation.warehouseStatusControl.areThereAnyActiveMissingProductRecords',
              )}
            >
              <YesNoTag
                flag={data.areThereAnyActiveOrderMissingProductRecords}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={this.props.t(
                'pages.operation.warehouseStatusControl.isWarehouseAvailable',
              )}
            >
              <YesNoTag flag={data.isWarehouseAvailable} />
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            className="scrollable"
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
            layout="vertical"
          >
            {data.activeTransferIds && (
              <Descriptions.Item
                label={this.props.t(
                  'pages.operation.warehouseStatusControl.activeTransfers',
                )}
              >
                {data.activeTransferIds.length
                  ? data.activeTransferIds.map((transfer) => (
                      <div>
                        <Link
                          to={`/stock-operation/transfer/detail/${transfer}`}
                        >
                          {transfer}
                        </Link>
                      </div>
                    ))
                  : '-'}
              </Descriptions.Item>
            )}

            {data.activeOrderIds && (
              <Descriptions.Item
                label={this.props.t(
                  'pages.operation.warehouseStatusControl.activeOrders',
                )}
              >
                {data.activeOrderIds.length
                  ? data.activeOrderIds.map((orderId) => (
                      <CopyToClipboard message={orderId} />
                    ))
                  : '-'}
              </Descriptions.Item>
            )}
            {data.activeWarehousePurchaseOrderIds && (
              <Descriptions.Item
                label={this.props.t(
                  'pages.operation.warehouseStatusControl.activePurchaseOrders',
                )}
              >
                {data.activeWarehousePurchaseOrderIds.length
                  ? data.activeWarehousePurchaseOrderIds.map(
                      (purchaseOrder) => (
                        <div>
                          <Link
                            to={`/stock-operation/purchase-order/detail/${purchaseOrder}`}
                          >
                            {purchaseOrder}
                          </Link>
                        </div>
                      ),
                    )
                  : '-'}
              </Descriptions.Item>
            )}
            {data.activeInventoryCheckIds && (
              <Descriptions.Item
                label={this.props.t(
                  'pages.operation.warehouseStatusControl.activeInventoryChecks',
                )}
              >
                {data.activeInventoryCheckIds.length
                  ? data.activeInventoryCheckIds.map((inventoryCheck) => (
                      <div>
                        <Link
                          to={`/stock-operation/inventory-check/detail/${inventoryCheck}`}
                        >
                          {inventoryCheck}
                        </Link>
                      </div>
                    ))
                  : '-'}
              </Descriptions.Item>
            )}
          </Descriptions>
        </>
      )
    );
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card}>
          <Row gutter={20}>
            <Col span={12}>
              <WarehouseSelect
                list={this.props.warehouseList.data}
                placeholder={this.props.t(
                  'pages.Operation.InventoryCheck.bulkApprove.inventoryCheckTable.warehouse',
                )}
                onChange={(warehouses) => {
                  if (warehouses && warehouses[0]) {
                    this.props.onGetWarehouseStatusControl(warehouses[0].id);
                  }
                  this.setState({
                    warehouse:
                      warehouses && warehouses.length ? warehouses : undefined,
                  });
                }}
                value={this.state.warehouse}
                loading={this.props.loading}
              />
            </Col>
            <Col span={24} className="mt-20">
              {this.renderData()}
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  warehouseStatusControl: state.inventory.warehouseStatusControl,
  warehouseList: state.warehouse.warehouseList,
});

const mapDispatchToProps = (dispatch) => ({
  onGetWarehouseStatusControl: (id) => {
    dispatch(inventoryAction.getWarehouseStatusControl(id));
  },
  onGetWarehouseList: () => {
    dispatch(warehouseAction.getWarehouseList());
  },
});
const WarehouseStatusControlConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(WarehouseStatusControl));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { WarehouseStatusControlConnected as WarehouseStatusControl };
