import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form, Button, Row, Col,
} from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import {
  WarehouseMultiSelect,
  ProductMultiSelect,
  DateRangeSelect,
  BsRequestStatusMultiSelect,
  TagMultiSelect,
} from './filterFields';
/*----------------------------------------------------------------------------*/
import { warehouseAction, productAction } from './../../../../../../actions';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class CustomerSatisfactionTabFilter extends Component {
  settings = {
    main: {
      className: 'CustomerSatisfactionTabFilter_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    const { onGetWarehouseList, onGetProductList } = this.props;
    onGetWarehouseList();
    onGetProductList();
  }

  stateFactory = () => ({
    ...this.emptyTableFilter(),
  });

  emptyTableFilter = () => ({
    startDate: this.props.defaultDateRange.startDate,
    endDate: this.props.defaultDateRange.endDate,
    products: [],
    warehouses: [this.props.currentWarehouse],
    statuses: [],
    blockedStockRequestIds: [],
  });

  render() {
    return (
      <div {...this.settings.main}>
        <this.TableFilterForm />
      </div>
    );
  }

  TableFilterForm = (props) => (
    <div>
      <Form>
        <Row>
          <Col span={5} style={{ padding: '0px 5px' }}>
            <this.DateRangeField />
          </Col>
          <Col span={8} style={{ padding: '0px 5px' }}>
            <this.WarehouseField />
          </Col>
          <Col span={11} style={{ padding: '0px 5px' }}>
            <this.ProductField />
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ padding: '0px 5px' }}>
            <this.RequestIdField />
          </Col>
          <Col span={12} style={{ padding: '0px 5px' }}>
            <this.RequestStatusField />
          </Col>
        </Row>

        <Row>
          <Col span={2} style={{ padding: '0px 5px' }}>
            <this.ClearFilterButton />
          </Col>
          <Col offset={20} span={2} style={{ padding: '0px 5px' }}>
            <this.FilterButton />
          </Col>
        </Row>
      </Form>
    </div>
  );

  DateRangeField = () => (
    <Form.Item>
      <DateRangeSelect
        defaultDateRange={this.props.defaultDateRange}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        onChange={(dateRange) => {
          this.setState({
            ...this.state,
            ...dateRange,
          });
        }}
      />
    </Form.Item>
  );

  WarehouseField = () => {
    const { warehouseList } = this.props;
    const { warehouses } = this.state;
    const list = (warehouseList && warehouseList.data) || null;
    return (
      <Form.Item>
        <WarehouseMultiSelect
          list={list}
          disabled
          onChange={(warehouses) => {
            this.setState({
              warehouses,
            });
          }}
          value={warehouses}
          loading={warehouseList.loading}
          placeholder={this.props.t('pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.filter.warehouse')}
        />
      </Form.Item>
    );
  };

  ProductField = () => {
    const { productList } = this.props;
    const { products } = this.state;
    const list = (productList && productList.data) || null;
    return (
      <Form.Item>
        <ProductMultiSelect
          list={list}
          onChange={(products) => {
            this.setState({
              products,
            });
          }}
          value={products}
          loading={productList.loading}
          placeholder={this.props.t('pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.filter.product')}
        />
      </Form.Item>
    );
  };

  RequestStatusField = () => {
    const { statuses } = this.state;
    return (
      <Form.Item>
        <BsRequestStatusMultiSelect
          onChange={(statuses) => {
            this.setState({ statuses });
          }}
          placeholder={this.props.t('pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.filter.status')}
          value={statuses}
        />
      </Form.Item>
    );
  };

  RequestIdField = () => {
    const { blockedStockRequestIds } = this.state;
    return (
      <Form.Item>
        <TagMultiSelect
          placeholder={this.props.t('pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.filter.id')}
          disabled={false}
          onChange={(blockedStockRequestIds) => {
            if (blockedStockRequestIds[0] === '') {
              this.setState({
                blockedStockRequestIds: [],
              });
              return;
            }

            this.setState({
              blockedStockRequestIds,
            });
          }}
          value={blockedStockRequestIds}
        />
      </Form.Item>
    );
  };

  FilterButton = () => (
    <Form.Item>
      <Button
        type="primary"
        block
        onClick={() => {
          const filter = this.getTableFilter();
          this.props.onChange(filter);
        }}
      >
        {this.props.t('pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.filter.filter')}
      </Button>
    </Form.Item>
  );

  ClearFilterButton = () => (
    <Form.Item>
      <Button
        block
        onClick={() => {
          this.clearFilter();
        }}
      >
        {this.props.t('pages.Stock.BlockedStock.list.tabs.CustomerSatisfactionTab.filter.clean')}
      </Button>
    </Form.Item>
  );

  getTableFilter = () => {
    const {
      products,
      warehouses,
      startDate,
      endDate,
      statuses,
      blockedStockRequestIds,
    } = this.state;

    const filter = {};

    filter.createdAt = {
      startDate: startDate || this.props.defaultDateRange.startDate,
      endDate: endDate || this.props.defaultDateRange.endDate,
    };
    if (products && products.length) {
      filter.products = products.map((p) => p.id);
    }

    if (statuses && statuses.length) {
      filter.statuses = statuses.map((p) => p.key);
    }

    if (warehouses && warehouses.length) {
      filter.warehouses = warehouses.map((warehouse) => warehouse.id);
    }
    if (blockedStockRequestIds && blockedStockRequestIds.length) {
      filter.blockedStockRequestIds = blockedStockRequestIds;
    }

    return filter;
  };

  clearFilter = () => {
    this.setState({ ...this.state, ...this.stateFactory() }, () => {
      this.props.onClear(this.getTableFilter());
    });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse.id,
  currentWarehouse: state.auth.currentWarehouse,
  warehouseList: state.warehouse.warehouseList,
  productList: state.product.productList,
});

const mapDispatchToProps = (dispatch) => ({
  onGetWarehouseList: () => {
    dispatch(warehouseAction.getWarehouseList());
  },
  onGetProductList: () => {
    dispatch(
      productAction.filterProductList({
        fields: 'barcodes fullName picURL packagingInfo',
        includeDefaultFields: false,
      }),
    );
  },
});

const CustomerSatisfactionTabFilterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(CustomerSatisfactionTabFilter));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { CustomerSatisfactionTabFilterConnected as CustomerSatisfactionTabFilter };
