import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';

export const productionModuleAction = {
  executeProductionModule,
};

function executeProductionModule(requestData) {
  return (dispatch) => {
    dispatch({
      type: actionType.executeProductionModule_InProgress,
    });
    return http
      .executeProductionModule(requestData)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.executeProductionModule_Succeeded,
          payload: data,
        });
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.executeProductionModule_Succeeded),
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.executeProductionModule_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.executeProductionModule_Failed,
          payload: error,
        });
        throw Error;
      });
  };
}
