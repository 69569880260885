import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Table, Descriptions, Row, Col, Modal, Button, Alert, Icon } from 'antd';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';

import { WaybillTable } from './WaybillTable';
import { Spinner, CopyToClipboard, PrintButton } from './../../../../components';
import { blockedStockAction, refundAction } from './../../../../actions';
import { getTranslation, getDateTimeFormatString } from './../../../../shared';
import { getCountryCode } from './../../../../shared/utility';

import './BlockedStockRefundDetail.scss';
import EwaybillForm from './EwaybillForm';
import { WaybillUpload } from '../list/UploadModal';

class BlockedStockRefundDetail extends Component {
  settings = {
    main: {
      className: 'BlockedStockRefundDetail_main',
    },
    card: {
      title: this.props.t('pages.Stock.BlockedStock.refund.detail.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
    this.currentCountryCode = getCountryCode();
  }

  componentDidMount() {
    this.getDetail();
  }

  stateFactory = () => ({
    isManualWaybillModalOpen: false,
    isEWaybillModalOpen: false,
    driverInfo: null,
    waybills: [],
    printed: false,
  });


  getDetail = () => {
    const { id, onGetDetail } = this.props;
    onGetDetail(id).then((e) => {
      console.log(this.props.detail, e);
      if (
        this.props.detail
        && !this.props.detail.eWaybill
        && !this.props.detail.waybill
        && this.currentCountryCode === 'tr'
      ) {
        this.setState({
          isEWaybillModalOpen: true,
        });
      } else if (
        this.currentCountryCode !== 'tr'
        && this.props.detail
        && !this.props.detail.waybill
      ) {
        this.setState({
          isManualWaybillModalOpen: true,
        });
      }
    });
  };


  onEwaybillSubmit = ({ driverName, driverSurname, nationalityId, licensePlate }) => {
    if (!this.state.driverInfo) {
      this.setState({
        driverInfo: { driverName, driverSurname, nationalityId, licensePlate },
      }, () => {
        this.generateEwaybill();
      });
    } else {
      this.generateEwaybill();
    }
  }

  generateEwaybill = () => {
    const payload = {
      driver: {
        name: this.state.driverInfo.driverName,
        surname: this.state.driverInfo.driverSurname,
        nationalNo: this.state.driverInfo.nationalityId,
      },
      plate: this.state.driverInfo.licensePlate,
    };
    this.props.generateEwaybill(this.props.id, payload);
  }

  createManualWaybill = () => {
    console.log("manual");
    this.setState({ isEWaybillModalOpen: false, isManualWaybillModalOpen: true });
  }

  onEwaybillModalClosed = () => {
    if (this.props.eWaybillData) {
      this.setState({ isEWaybillModalOpen: false });
    }
  }

  EWaybillModal = () => (
    <Modal
      title={this.props.t(
        'pages.Stock.BlockedStock.refund.detail.modal.title',
      )}
      visible={this.state.isEWaybillModalOpen}
      footer={null}
      onCancel={this.onEwaybillModalClosed}
      closable={!!this.props.eWaybillData}
    >
      {this.props.eWaybillErrorCount ?
        <Alert
          message={
            <div>
              <p>{this.props.eWaybillErrorCount > 1 ?
                this.props.t(
                  'pages.Stock.BlockedStock.refund.detail.modal.createManuallyDescription',
                ) :
                this.props.t(
                  'pages.Stock.BlockedStock.refund.detail.modal.retryDescription',
                )}
              </p>
              {
                this.props.eWaybillErrorCount > 1 ?
                  <Button type="primary" key="retry" onClick={this.createManualWaybill}>
                    {this.props.t(
                      'pages.Stock.BlockedStock.refund.detail.modal.createManually',
                    )}
                  </Button> :
                  <Button type="primary" key="retry" onClick={this.onEwaybillSubmit}>
                    {this.props.t(
                      'pages.Stock.BlockedStock.refund.detail.modal.retryButton',
                    )}
                  </Button>
              }

            </div>
          }
          type="info"
          showIcon
        /> :
        <EwaybillForm
          onFormSubmit={this.onEwaybillSubmit}
          isLoading={this.props.eWaybillLoading}
          data={this.props.eWaybillData}
        />
      }
    </Modal>
  );


  getPrintData = () => {
    const { products, warehouse } = this.props.detail;
    const printData = products.map((product) => ({
      fromWarehouse: warehouse.name,
      barcode: "??refund.product.barcodes[0]",
      name: getTranslation.obj(product.product.fullName),
      count: "??refund.count",
    }));
    return printData;
  };


  renderManuelWayBillFooter = () => (
    [
      <PrintButton
        key="print"
        changeParentState={() => {
          this.setState({ printed: true });
        }}
        items={this.getPrintData()}
        settings={{
          label: (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Icon type="printer" style={{ marginRight: 5 }} />
              {this.props.t(
                'pages.Stock.BlockedStock.RefundDetail.manualModal.printWaybill',
              )}
            </span>
          ),
        }}
      />,
      <Button
        key="cancel"
        onClick={this.onManualFormCanceled}
      >
        {this.props.t(
          'pages.Stock.BlockedStock.RefundDetail.manualModal.cancel',
        )}
      </Button>,
      <Button
        key="save"
        disabled={
          !this.state.waybills.length ||
          !this.state.printed ||
          this.state.isModalRefundBulkSaveClicked
        }
        type="primary"
        onClick={this.onManualFormSaved}
        loading={this.props.createWaybillLoading}
      >
        {this.props.t(
          'pages.Stock.BlockedStock.RefundDetail.manualModal.save',
        )}
      </Button>,
    ]
  )

  ManualWaybillModal = (props) => {
    return (
      <Modal
        width={734}
        okButtonProps={{}}
        title={this.props.t(
          'pages.Stock.BlockedStock.RefundDetail.manualModal.enterWaybill',
        )}
        visible={this.state.isManualWaybillModalOpen}
        footer={this.renderManuelWayBillFooter()}
        onCancel={this.onManualFormCanceled}
      >
        <WaybillUpload
          waybills={this.state.waybills}
          onChange={(waybills) => {
            this.setState({ waybills });
          }}
        />
      </Modal>
    );
  };

  onManualFormCanceled = () => {
    console.log('onCancel', this.state);
    this.setState((state) => ({
      ...state,
      isModalRefundBulkSaveClicked: false,
      waybills: [],
      printed: false,
      isManualWaybillModalOpen: false,
    }));
  }

  onManualFormSaved = () => {
    console.log('onOk', this.state);
    this.setState({
       isModalRefundBulkSaveClicked: true,
    });

    this.props.createWaybillBulk(this.props.detail.id, this.state.waybills).then(() => {
      this.setState({
       isManualWaybillModalOpen: false,
      });
    });
  }

  openWaybillModal = () => {
    if (this.currentCountryCode === 'tr') {
      if (this.props.detail && !this.props.detail.eWaybill && !this.props.detail.waybill) {
        this.setState({
          isEWaybillModalOpen: true,
        });
      } else {
        this.setState({
          isManualWaybillModalOpen: true,
        });
      }
    } else if (this.props.detail && !this.props.detail.waybill) {
      this.setState({
        isManualWaybillModalOpen: true,
      });
    }
  }


  PageDescription = (props) => {
    const { detail } = this.props;
    if (!detail) {
      return null;
    }

    return (
      <Descriptions column={2}>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.BlockedStock.refund.detail.description.id',
          )}
        >
          <CopyToClipboard message={detail.id} />
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.BlockedStock.refund.detail.description.no',
          )}
        >
          <CopyToClipboard message={detail.no} />
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.BlockedStock.refund.detail.description.supplier',
          )}
        >
          <CopyToClipboard message={detail.supplier.name} />
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.BlockedStock.refund.detail.description.account',
          )}
        >
          <CopyToClipboard
            message={detail.supplier.account.code}
            inner={detail.supplier.account.name}
          />
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.BlockedStock.refund.detail.description.warehouse',
          )}
        >
          <CopyToClipboard
            message={detail.warehouse.id}
            inner={detail.warehouse.name}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.BlockedStock.refund.detail.description.createdBy',
          )}
        >
          <CopyToClipboard
            message={detail.createdBy.id}
            inner={detail.createdBy.name}
          />
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Stock.BlockedStock.refund.detail.description.createdAt',
          )}
        >
          {moment(new Date(detail.createdAt))
            .local()
            .format(getDateTimeFormatString())}
        </Descriptions.Item>
      </Descriptions>
    );
  };


  prepareColumns = (props) => {
    return [
      {
        title: this.props.t(
          'pages.Stock.BlockedStock.refund.detail.subTable.productId',
        ),
        dataIndex: 'product.id',
        key: 'productId',
        render: (productId) => <CopyToClipboard message={productId} />,
      },
      {
        title: this.props.t(
          'pages.Stock.BlockedStock.refund.detail.subTable.fullName',
        ),
        dataIndex: 'product.fullName',
        key: 'fullName',
        render: (fullName) =>
          fullName && (
            <CopyToClipboard message={getTranslation.obj(fullName)} />
          ),
      },
      {
        title: this.props.t(
          'pages.Stock.BlockedStock.refund.detail.subTable.count',
        ),
        dataIndex: 'count',
        key: 'count',
      },
    ];
  };

  prepareData = (props) => {
    const { detail } = this.props;
    return detail.products;
  };

  ProductTable = (props) => {
    return (
      <Table
        size="small"
        columns={this.prepareColumns()}
        dataSource={this.prepareData()}
        rowKey={(record) => {
          return record.id;
        }}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  PageDetail = (props) => {
    const { detail } = this.props;
    if (!detail) {
      return null;
    }
    return (
      <div>
        <this.PageDescription />
        <Row>
          <Col span={24} style={{ padding: '5px' }}>
            <this.ProductTable />
            <WaybillTable waybill={detail.waybill} eWaybill={detail.eWaybill} />
          </Col>
        </Row>
        <this.EWaybillModal />
        <this.ManualWaybillModal />
      </div>
    );
  };

  render() {
    const { loading } = this.props;
    const isButtonDisabled = this.currentCountryCode === 'tr'
      ? this.props.detail && (this.props.detail.eWaybill)
      : this.props.detail && (this.props.detail.waybill);
    return (
      <div {...this.settings.main}>
        <Card
          {...this.settings.card}
          extra={
            <Button
              onClick={this.openWaybillModal}
              disabled={isButtonDisabled}
            >
              {this.props.t(
                'pages.Stock.BlockedStock.refund.detail.description.generateWaybill',
              )}
            </Button>
          }
        >
          {loading ? <Spinner /> : <this.PageDetail />}
        </Card>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.blockedStock.blockedStockRefundDetail.loading,
  detail: state.blockedStock.blockedStockRefundDetail.data,
  eWaybillLoading: state.refund.eWaybill.loading,
  eWaybillErrorCount: state.refund.eWaybill.errorCount,
  eWaybillData: state.refund.eWaybill.data,
  createWaybillLoading: state.refund.createWaybillBulk.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onGetDetail: (id) => dispatch(blockedStockAction.getBlockedStockRefund(id)),
  generateEwaybill: (refundId, payload) => dispatch(refundAction.generateEwaybill(refundId, payload)),
  createWaybillBulk: (refundId, payload) => dispatch(refundAction.createWaybillBulk(refundId, payload)),
});

const BlockedStockRefundDetailConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(BlockedStockRefundDetail));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { BlockedStockRefundDetailConnected as BlockedStockRefundDetail };
