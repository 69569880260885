import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Form } from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import './PurchaseOrderCreate.scss';
import {
  ProductMultiSelect,
  WarehouseLocationSelect,
  ProductCountInput,
} from './formFields';
/*----------------------------------------------------------------------------*/
import { history } from './../../../../history';
import { Spinner } from './../../../../components';
import {
  supplierAction,
  productAction,
  purchaseOrderAction,
  warehouseLocationAction,
} from './../../../../actions';
import { constants } from './../../../../shared';
import { pageView } from '../../../../services/segment';
import { pageLoadEvents } from '../../../../services/events';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class PurchaseOrderCreate extends Component {
  settings = {
    main: {
      className: 'PurchaseOrderCreate_main',
    },
    card: {
      className: 'no-padding-card',
      title: this.props.t('pages.Stock.PurchaseOrder.create.title'),
      size: 'small',
    },
  };

  formItemLayout = {
    style: {
      marginTop: 15,
      marginBottom: 15,
    },
    labelCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 3, offset: 0 },
    },
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 0 },
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => ({
    location: null,
    products: [],
    supplierId: '',
    accountId: '',
    isClickedSavePurchaseOrder: false,
  });

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.STOCK_PURCHASE_ORDER_CREATE });
    this.getWarehouseLocationList();
    this.getProductList();
  }

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card}>
          {loading ? <Spinner /> : <this.Page />}
        </Card>
      </div>
    );
  }

  Page = (props) => (
    <>
      <this.PageForm />
      <this.Toolbar />
    </>
  );

  PageForm = () => (
    <Form>
      <this.WarehouseLocationField />
      <this.ProductField />
      <this.ProductCountField />
    </Form>
  );

  WarehouseLocationField = () => {
    const { loading, warehouseLocationList } = this.props;
    const { location } = this.state;
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t('pages.Stock.PurchaseOrder.create.form.locations')}
      >
        <WarehouseLocationSelect
          list={warehouseLocationList}
          onChange={(location) => {
            this.setState({ location });
          }}
          value={location}
          loading={loading}
        />
      </Form.Item>
    );
  };

  ProductField = () => {
    const { loading, productList } = this.props;
    const { products } = this.state;
    return (
      <Form.Item
        {...this.formItemLayout}
        label={this.props.t('pages.Stock.PurchaseOrder.create.form.products')}
      >
        <ProductMultiSelect
          list={productList}
          onChange={(products) => {
            this.setState({
              products: products.map((item) => ({
                ...item,
                count: 0,
                operation: true,
              })),
            });
          }}
          value={products}
          loading={loading}
        />
      </Form.Item>
    );
  };

  ProductCountField = () => {
    const { products } = this.state;
    return (
      <ProductCountInput
        list={products}
        onDeleteRow={(data) => {
          this.setState({ products: data });
        }}
        onCountChange={(data) => {
          this.setState({ products: data });
        }}
      />
    );
  };

  Toolbar = (props) => (
    <div className="Toolbar">
      <this.CancelButton />
      <span>
        <this.SaveButton />
      </span>
    </div>
  );

  SaveButton = (props) => (
    <Button
      className="SaveButton"
      onClick={this.clickSave}
      loading={this.state.isClickedSavePurchaseOrder}
      disabled={
        this.isCreateButtonDisabled() || this.state.isClickedSavePurchaseOrder
      }
    >
      {this.props.t('pages.Stock.PurchaseOrder.create.button.save')}
    </Button>
  );

  CancelButton = (props) => (
    <Button className="CancelButton" type="danger" onClick={this.clickCancel}>
      {this.props.t('pages.Stock.PurchaseOrder.create.button.cancel')}
    </Button>
  );

  getWarehouseLocationList = () => {
    this.props.onGetWarehouseLocationList(this.props.CWId, [
      constants.WAREHOUSE_LOCATION_OPERATION_TYPE.PICKING,
    ]);
  };

  getProductList = async () => {
    const { suppliers } = await this.props.onGetSupplierList();
    const supplierId = (suppliers && suppliers[0] && suppliers[0]._id) || '';
    const accountId =
      (suppliers &&
        suppliers[0] &&
        suppliers[0].accounts &&
        suppliers[0].accounts[0] &&
        suppliers[0].accounts[0]._id) ||
      '';
    this.setState({ supplierId });
    this.setState({ accountId });
    this.props.onGetProductList({ supplierIds: [supplierId] });
  };

  isCreateButtonDisabled = () => {
    const { location, products } = this.state;
    if (!location || !products || !products.length) {
      return true;
    }
    if (products.length === products.filter((item) => item.count > 0).length) {
      return false;
    }
    return true;
  };

  clickSave = () => {
    const { location, products, supplierId, accountId } = this.state;
    const payload = {
      locationBarcode: location.barcode,
      items: products.map((item) => ({
        item: item.id,
        count: item.count,
      })),
      supplierId,
      accountId,
    };
    this.setState({ isClickedSavePurchaseOrder: true });
    this.createSelfPurchaseOrder(payload).catch(() => {
      this.setState({ isClickedSavePurchaseOrder: false });
    });
  };

  clickCancel = () => {
    this.setState(this.stateFactory());
    const { CWId } = this.props;
    history.push(`/r/${CWId}/stock/purchase-order/list`);
  };

  createSelfPurchaseOrder = (payload) =>
    this.props.onCreateSelfPurchaseOrder(payload);
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => {
  return {
    CWId: state.auth.currentWarehouse.id,
    loading: state.product.productList.loading,
    productList: state.product.productList.data,
    warehouseLocationList: state.warehouseLocation.warehouseLocationList.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetSupplierList: () => {
    return dispatch(
      supplierAction.getSupplierList(
        { isSelfPurchaseOrderAllowed: true },
        false,
      ),
    );
  },
  onGetProductList: ({ supplierIds }) => {
    dispatch(
      productAction.filterProductList(
        {
          fields: 'barcodes picURL fullName packagingInfo wholesaleVat',
          includeDefaultFields: false,
          isAllowedForSelfPurchaseOrder: true,
          supplierIds,
        },
        false,
      ),
    );
  },
  onCreateSelfPurchaseOrder: (data) =>
    dispatch(purchaseOrderAction.createSelfPurchaseOrder(data)),
  onGetWarehouseLocationList: (CWId, operationTypes) => {
    dispatch(
      warehouseLocationAction.filterWarehouseLocations({
        warehouseId: CWId,
        operationTypes,
        isAllowedForTransfer: true,
      }),
    );
  },
});

const PurchaseOrderCreateConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(PurchaseOrderCreate));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { PurchaseOrderCreateConnected as PurchaseOrderCreate };
