import { QueryBuilder } from '../QueryBuilder';
import { createHttpService } from '../createHttpService';

const httpService = createHttpService();

export const blockedStock = {
  filterBlockedStockReasons: (filter) => httpService.post('/blocked-stocks/reasons/filter', filter),
  getBlockedStockDetail: (blockedStockId) => httpService.get(`/blocked-stocks/${blockedStockId}`),
  getBlockedStockRuleDetail: (blockedStockRuleId) => httpService.get(`/blocked-stocks/rules/${blockedStockRuleId}`),
  updateBlockedStockRule: (blockedStockRuleId, data) => httpService.put(`/blocked-stocks/rules/${blockedStockRuleId}`, data),
  filterBlockedStock: (query, filter) => httpService.post(`/blocked-stocks/filter?${QueryBuilder.getQueryStr(query)}`, filter),
  createBlockedStockRule: (data) => httpService.post('/blocked-stocks/rules', data),
  filterBlockedStockRule: (query, filter) => httpService.post(`blocked-stocks/rules/filter?${QueryBuilder.getQueryStr(query)}`, filter),
  concludeBlockedStock: (blockedStockId, data) => httpService.post(`/blocked-stocks/${blockedStockId}/conclude`, data),
  updateBlockedStockReason: (blockedStockId, data) => httpService.put(`/blocked-stocks/${blockedStockId}/reason`, data),
  cancelBlockedStock: (blockedStockId) => httpService.post(`/blocked-stocks/${blockedStockId}/cancel`),
  changeConclusionBlockedStock: (blockedStockId, data) => httpService.put(`/blocked-stocks/${blockedStockId}/conclusion-type`, data),
  completeTransferBulk: (data) => httpService.post(`/blocked-stocks/transfers`, data),
  filterBlockedStockRequest: (query, filter) => httpService.post(`/blocked-stocks/requests/filter?${QueryBuilder.getQueryStr(query)}`, filter),

  filterBlockedStockTransfers: (query, filter) => httpService.post(`/blocked-stocks/transfers/filter?${QueryBuilder.getQueryStr(query)}`, filter),
  filterBlockedStockRefunds: (query, filter) => httpService.post(`/blocked-stocks/refunds/filter?${QueryBuilder.getQueryStr(query)}`, filter),
  filterBlockedStockDisposals: (query, filter) => httpService.post(`/blocked-stocks/disposals/filter?${QueryBuilder.getQueryStr(query)}`, filter),
  getBlockedStockTransfer: (transferId) => httpService.get(`/blocked-stocks/transfers/${transferId}`),
  getBlockedStockRefund: (refundId) => httpService.get(`/blocked-stocks/refunds/${refundId}`),
  getBlockedStockDisposal: (disposalId) => httpService.get(`/blocked-stocks/disposals/${disposalId}`),
};
