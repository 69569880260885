import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: true,
    data: null,
    error: null,
    total: 0,
  },
};

const switcher = {
  [actionType.filterCountryList_InProgress]: filterCountryList_InProgress,
  [actionType.filterCountryList_Succeeded]: filterCountryList_Succeeded,
  [actionType.filterCountryList_Failed]: filterCountryList_Failed,
};

export const countryReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function filterCountryList_InProgress(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
      total: 0,
    },
  });
}

function filterCountryList_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload.countries,
      error: null,
      total: payload.total || payload.countries.length,
    },
  });
}

function filterCountryList_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      error: payload,
      total: 0,
    },
  });
}
