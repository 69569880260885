import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  list: {
    loading: true,
    data: null,
    error: null,
    count: 0,
  },
};

const switcher = {
  [actionType.getCancelledOrders_InProgress]: getCancelledOrders_InProgress,
  [actionType.getCancelledOrders_Succeeded]: getCancelledOrders_Succeeded,
  [actionType.getCancelledOrders_Failed]: getCancelledOrders_Failed,

  [actionType.getCancelledOrdersCount_InProgress]: getCancelledOrdersCount_InProgress,
  [actionType.getCancelledOrdersCount_Succeeded]: getCancelledOrdersCount_Succeeded,
  [actionType.getCancelledOrdersCount_Failed]: getCancelledOrdersCount_Failed,
};

export const cancelledOrdersReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getCancelledOrders_InProgress(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function getCancelledOrders_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: payload,
      count: (payload && payload.length) || 0,
      error: null,
    },
  });
}

function getCancelledOrders_Failed(state, payload) {
  return Object.assign({}, state, {
    list: {
      loading: false,
      data: null,
      count: 0,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/
function getCancelledOrdersCount_InProgress(state, payload) {
  return state;
}

function getCancelledOrdersCount_Succeeded(state, payload) {
  return Object.assign({}, state, {
    list: {
      ...state.list,
      count: payload,
    },
  });
}

function getCancelledOrdersCount_Failed(state, payload) {
  return state;
}
