import { get } from 'lodash';
import { constants } from './';

const { ORDER_STATUS } = constants;

export const getOrderLastStatusChangeDate = ({ order }) => {
  switch (order.status) {
    case ORDER_STATUS.WAITING_FOR_PICKER:
      return get(order, 'checkoutDate');
    case ORDER_STATUS.VERIFYING:
      return get(order, 'pickerAssignDate');
    case ORDER_STATUS.PREPARING:
      return get(order, 'verifyDates[0]');
    case ORDER_STATUS.PREPARED:
      return get(order, 'prepareDate');
    case ORDER_STATUS.HANDOVER:
      return get(order, 'handoverDate');
    case ORDER_STATUS.ON_WAY:
      return get(order, 'onwayDate');
    case ORDER_STATUS.REACHED:
      return get(order, 'reachDate');
    case ORDER_STATUS.DELIVERED:
      return get(order, 'deliverDate');
    case ORDER_STATUS.RATED:
      return get(order, 'rateDate');
    default:
      return undefined;
  }
};

export default { getOrderLastStatusChangeDate };
