/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import moment from 'moment';

export class PrintTemplate extends Component {
  render() {
    const pageStyle = {
      width: '21cm',
      height: '30.589cm',
      display: 'block',
      margin: 0,
      position: 'relative',
    };

    const body = {
      fontSize: '14px',
      fontFamily: 'Arial',
      margin: 0,
    };

    const { items } = this.props;

    return (
      <div style={{ ...pageStyle, ...body }}>
        <div style={{
          position: 'absolute', top: '5.5cm', height: '2cm', float: 'left', width: '38%',
        }}
        >
          <p>{items[0].toWarehouse}</p>
          <p>{items[0].fromWarehouse}</p>
          <p style={{ marginTop: '20px' }}>{Date.now()}</p>
        </div>
        <div style={{
          position: 'absolute', top: '6.5cm', float: 'right', marginLeft: 'auto', right: '0', width: '20%',
        }}
        >
          <p>{moment().format('DD/MM/YYYY')}</p>
          <p>{moment().format('DD/MM/YYYY')}</p>
          <p>{moment().format('HH:mm')}</p>
        </div>
        <table style={{
          position: 'absolute', top: '11cm', float: 'left', width: '100%', tableLayout: 'fixed',
        }}
        >
          {items.map((item, index) => (
            <tr key={index}>
              <td style={{ width: '20px' }}>{`${item.barcode} `}</td>
              <td style={{ width: '70px', wordWrap: 'break-word' }}>{item.name}</td>
              <td style={{ width: '10px', textAlign: 'center' }}>{item.count}</td>
              <td style={{ width: '30px' }} />
              <td style={{ width: '40px' }} />
            </tr>
          ))}
        </table>
      </div>
    );
  }
}
