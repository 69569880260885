import React, { Component } from 'react';
import {
  Table, Popconfirm, Icon, Avatar,
} from 'antd';
/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/
import { withNamespaces } from 'react-i18next';
import { EditableCell, EditableFormRow } from './../../../../../components';
import { getTranslation } from './../../../../../shared';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class ProductCountInput extends Component {
  components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => ({
    columns: [],
    dataSource: this.emptyTableData(),
  });

  operationTableColumn = () => {
    const { list } = this.props;
    return {
      title: '',
      width: 50,
      key: 'operation',
      fixed: true,
      render: (text, record) => {
        if (list.length) {
          return (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleRowDelete(record.id)}
            >
              <Icon
                type="delete"
                theme="filled"
                style={{ fontSize: '16px', color: '#f05050' }}
              />
            </Popconfirm>
          );
        }
        return null;
      },
    };
  };

  emptyTableData = () => [];

  render() {
    return <this.TableComponent />;
  }

  TableComponent = (props) => (
    <Table
      size="small"
      bordered
      rowClassName={() => 'editable-row'}
      components={this.components}
      dataSource={this.prepareData()}
      columns={this.prepareColumns()}
      rowKey={(record) => record.id}
    />
  );

  prepareColumns = () => {
    const columns = [
      {
        ...this.operationTableColumn(),
      },
      {
        title: this.props.t(
          'pages.Stock.PurchaseOrder.create.formFields.productTable.image',
        ),
        dataIndex: 'picURL',
        key: 'picURL',
        width: 72,
        render: (picURL) => (
          <Avatar
            className="grow"
            shape="square"
            src={getTranslation.obj(picURL)}
          />
        ),
      },
      {
        title: this.props.t(
          'pages.Stock.PurchaseOrder.create.formFields.productTable.fullName',
        ),
        dataIndex: 'fullName',
        key: 'fullName',
        render: (fullName) => getTranslation.obj(fullName),
      },
      {
        title: this.props.t(
          'pages.Stock.PurchaseOrder.create.formFields.productTable.quantity',
        ),
        dataIndex: 'count',
        key: 'count',
        editable: true,
      },
    ];
    return columns.map((col, index) => {
      let transformCol = { ...col };
      if (col.editable) {
        transformCol = {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleCellSave: this.handleCellSave,
          }),
        };
      }
      return {
        ...transformCol,
      };
    });
  };

  prepareData = () => {
    const { list } = this.props;
    console.log(list);
    if (!list || !list.length) {
      return [];
    }
    return list;
  };

  handleRowDelete = (recordId) => {
    const { list } = this.props;
    const dataSource = [...list];
    const newList = dataSource.filter((item) => item.id !== recordId);
    this.props.onDeleteRow(newList);
  };

  handleCellSave = (row) => {
    const { list } = this.props;
    const newData = [...list];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.props.onCountChange(newData);
  };
}

const ProductCountInputTranslated = withNamespaces('translation')(
  ProductCountInput,
);

export { ProductCountInputTranslated as ProductCountInput };
