import React, { Component } from 'react';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import { courierAction, orderAction, pickerAction } from './../../actions';
import { config } from './../../config';

class Socket extends Component {
  constructor(props) {
    super(props);
    this.socket = io(`${config.BACKEND_URI}?token=${props.token}`, { transports: ['websocket'] });
  }

  componentDidMount() {
    this.socket.on('connect', (data) => {
      console.log('socket connected', data);
    });

    this.socket.on('reconnect', (data) => {
      this.props.onSocketReconnected();
    });

    this.socket.on('courier:location', (data) => {
      const { courier = {} } = data;
      const { location = {} } = courier;
      const { coordinates = [29, 41] } = location;
      const courierId = courier._id;
      const courierLocation = {
        lat: coordinates[1],
        lon: coordinates[0],
      };
      this.props.onCourierLocationChanged(courierId, courierLocation);
    });

    this.socket.on('courier:updated', (data) => {
      this.props.onCourierUpdated(data);
    });

    this.socket.on('new-market-order', (data) => {
      this.props.onNewMarketOrder(data);
    });

    this.socket.on('market-order-changed', (data) => {
      this.props.onMarketOrderChanged(data);
    });

    this.socket.on('statusChanged', (data) => {
      this.props.onPickerUpdated(data);
    });
  }

  componentWillUnmount() {
    this.socket.disconnect();
  }

  render() {
    return <div className="Socket_inner" />;
  }
}

const mapStateToProps = (state) => ({
  currentWarehouseId: state.auth.currentWarehouse._id,
  token: localStorage.getItem('tokenCode'),
});

const mapDispatchToProps = (dispatch) => ({
  onCourierLocationChanged: (courierId, courierLocation) =>
    dispatch(courierAction.courierLocationChanged(courierId, courierLocation)),
  onCourierUpdated: (courier) =>
    dispatch(courierAction.courierUpdated(courier)),
  onNewMarketOrder: (order) => dispatch(orderAction.newMarketOrder(order)),
  onMarketOrderChanged: (order) =>
    dispatch(orderAction.marketOrderChanged(order)),
  onSocketReconnected: () => {
    dispatch(orderAction.getOrderList());
  },
  onPickerUpdated: (picker) =>
    dispatch(pickerAction.pickerUpdated(picker)),
});

const SocketConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Socket);

export { SocketConnected as Socket };
