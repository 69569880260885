import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Form,
  Button,
  Dropdown,
  Icon,
  Menu,
  Switch,
  Collapse,
  Avatar,
  notification,
} from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import './InventoryCheckCreate.scss';
import {
  ScopeSelect,
  TypeSelect,
  WarehouseSelect,
  ProductMultiSelect,
  LocationMultiSelect,
} from './formFields';
import { DataTransform } from './DataTransform';
/*----------------------------------------------------------------------------*/
import { Spinner, ImportCsv, ReasonSelect } from './../../../../components';
import { history } from './../../../../history';
import { getTranslation, constants } from './../../../../shared';
import {
  inventoryAction,
  warehouseAction,
  productAction,
  warehouseLocationAction,
} from './../../../../actions';
import { pageLoadEvents } from '../../../../services/events';
import { pageView } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/

const { INVENTORY_CHECK_TYPE, INVENTORY_CHECK_SCOPE } = constants;

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class InventoryCheckCreate extends Component {
  settings = {
    main: {
      className: 'InventoryCheckCreate_main',
    },
    card: {
      className: 'no-padding-card',
      title: this.props.t('pages.Operation.InventoryCheck.create.title'),
      extra: '',
      size: 'small',
    },
  };

  formItemLayout = {
    style: {
      marginTop: 15,
    },
    labelCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 4, offset: 0 },
    },
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 0 },
    },
  };

  tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 13,
        offset: 3,
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_STOCK_CONTROL_CREATE });
    this.prepareToCreate();
  }

  prepareToCreate = () => {
    this.props.onGetWarehouseList();
    this.props.onGetProductList();
  };

  stateFactory = () => {
    return {
      type: null,
      scope: null,
      handover: false,
      expiryDateRequired: false,
      warehouses: [],
      locations: [],
      products: [],
      isClickedSave: false,
      importedData: null,
    };
  };

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {loading ? <Spinner /> : <this.PageCreate />}
        </Card>
      </div>
    );
  }

  CardExtra = () => {
    return (
      <Dropdown overlay={this.MenuAction}>
        <Button style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.t('pages.Operation.InventoryCheck.create.button.actions')}{' '}
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  MenuAction = () => {
    return (
      <Menu>
        <Menu.Item key="CreateButton">
          <this.CreateButton />
        </Menu.Item>
        <Menu.Item key="ClearButton">
          <this.ClearButton />
        </Menu.Item>
        <Menu.Item key="ImportFromCsvButton">
          <this.ImportFromCsvButton />
        </Menu.Item>
        <Menu.Item key="CancelButton">
          <this.CancelButton />
        </Menu.Item>
      </Menu>
    );
  };

  CreateButton = () => {
    return (
      <Button
        type="danger"
        disabled={!this.getPayload() || this.state.isClickedSave}
        loading={this.state.isClickedSave}
        block
        onClick={() => {
          this.setState({ isClickedSave: true });
          const payload = this.getPayload();
          console.log(payload);
          this.createInventoryCheck(payload)
            .then(() => {
              history.push('/stock-operation/inventory-check/list');
            })
            .catch(() => {
              history.push('/stock-operation/inventory-check/list');
            });
        }}
      >
        {this.props.t('pages.Operation.InventoryCheck.create.button.save')}
      </Button>
    );
  };

  ClearButton = () => {
    return (
      <Button
        block
        onClick={() => {
          this.setState(this.stateFactory());
        }}
      >
        {this.props.t('pages.Operation.InventoryCheck.create.button.clean')}
      </Button>
    );
  };

  ImportFromCsvButton = () => {
    return (
      <ImportCsv
        block
        disabled={this.importCsvDisabled() || !this.props.productList.data}
        transformData={(data) => {
          const { productList, warehouseList } = this.props;
          try {
            const importedData = DataTransform.importData(
              data,
              productList.data,
              warehouseList.data,
            );
            this.setState((state) => {
              return {
                ...state,
                warehouses: [],
                products: [],
                locations: [],
                importedData,
              };
            });
          } catch (error) {
            notification.error({
              duration: 4,
              message: (
                <div style={{ fontWeight: 'bold' }}>
                  {this.props.t(
                    'pages.Operation.InventoryCheck.create.error.title',
                  )}
                </div>
              ),
              description: (
                <div>
                  <div style={{ color: 'red' }}>{error.message}</div>
                </div>
              ),
            });
            this.setState({ importedData: null });
          }
        }}
      />
    );
  };

  ImportDataPreview = () => {
    const { importedData } = this.state;
    if (!importedData || !importedData.length) {
      return null;
    }
    return (
      <Collapse accordion style={{ margin: 5 }}>
        {importedData.map((item) => {
          return (
            <Collapse.Panel
              header={item.warehouse.name}
              key={item.warehouse.id}
            >
              {item.products.map((product) => {
                return (
                  <div key={product.id} style={{ margin: 5 }}>
                    <Avatar
                      src={
                        product &&
                        product.picURL &&
                        getTranslation.obj(product.picURL)
                      }
                      size={40}
                      className="product-icon grow"
                    />
                    {getTranslation.obj(product.fullName)}
                  </div>
                );
              })}
            </Collapse.Panel>
          );
        })}
      </Collapse>
    );
  };

  CancelButton = () => {
    return (
      <Button
        block
        onClick={() => {
          history.goBack();
        }}
      >
        {this.props.t('pages.Operation.InventoryCheck.create.button.cancel')}
      </Button>
    );
  };

  PageCreate = () => {
    return (
      <>
        <this.PageForm />
        <this.ImportDataPreview />
      </>
    );
  };

  PageForm = (props) => {
    return (
      <Form {...this.formItemLayout}>
        <this.TypeSelect />
        <this.ScopeSelect />
        <this.HandoverSwitch />
        <this.ExpiryDateRequiredSwitch />
        <this.WarehouseSelect />
        <this.LocationSelect />
        <this.ProductSelect />
        <this.ReasonSelect />
      </Form>
    );
  };

  TypeSelect = () => {
    return (
      <Form.Item
        label={this.props.t('pages.Operation.InventoryCheck.create.form.type')}
      >
        <TypeSelect
          placeholder={this.props.t(
            'pages.Operation.InventoryCheck.create.form.type',
          )}
          onChange={(type) => {
            this.setState(
              (state) => {
                return {
                  ...state,
                  type,
                  warehouses: [],
                  products: [],
                  locations: [],
                  importedData: null,
                };
              },
              () => {
                this.getLocationsByWarehouse();
                this.getProducts();
              },
            );
          }}
          value={this.state.type}
        />
      </Form.Item>
    );
  };

  ScopeSelect = () => {
    return (
      <Form.Item
        label={this.props.t('pages.Operation.InventoryCheck.create.form.scope')}
      >
        <ScopeSelect
          placeholder={this.props.t(
            'pages.Operation.InventoryCheck.create.form.scope',
          )}
          onChange={(scope) => {
            this.setState(
              (state) => {
                return {
                  ...state,
                  scope,
                  warehouses: [],
                  products: [],
                  locations: [],
                  importedData: null,
                };
              },
              () => {
                this.getLocationsByWarehouse();
                this.getProducts();
              },
            );
          }}
          value={this.state.scope}
        />
      </Form.Item>
    );
  };

  HandoverSwitch = () => {
    const { handover } = this.state;
    return (
      <Form.Item
        label={this.props.t(
          'pages.Operation.InventoryCheck.create.form.handover',
        )}
      >
        <Switch
          defaultChecked={handover}
          checkedChildren={this.props.t(
            'pages.Operation.InventoryCheck.create.switch.enable',
          )}
          unCheckedChildren={this.props.t(
            'pages.Operation.InventoryCheck.create.switch.disable',
          )}
          onChange={() => {
            this.setState({ handover: !handover });
          }}
        />
      </Form.Item>
    );
  };

  ExpiryDateRequiredSwitch = () => {
    const { expiryDateRequired } = this.state;
    return (
      <Form.Item
        label={this.props.t(
          'pages.Operation.InventoryCheck.create.form.expiryDateRequired',
        )}
      >
        <Switch
          defaultChecked={expiryDateRequired}
          checkedChildren={this.props.t(
            'pages.Operation.InventoryCheck.create.switch.enable',
          )}
          unCheckedChildren={this.props.t(
            'pages.Operation.InventoryCheck.create.switch.disable',
          )}
          onChange={() => {
            this.setState({ expiryDateRequired: !expiryDateRequired });
          }}
        />
      </Form.Item>
    );
  };

  WarehouseSelect = () => {
    const { warehouseList } = this.props;
    const { type, importedData } = this.state;
    const list = (warehouseList && warehouseList.data) || [];

    if (!type || importedData) {
      return null;
    }
    // eslint-disable-next-line
    const isProductBased = type.key == INVENTORY_CHECK_TYPE.PRODUCT_BASED;
    const mode = isProductBased ? 'multiple' : 'default';

    return (
      <Form.Item
        label={this.props.t(
          'pages.Operation.InventoryCheck.create.form.warehouses',
        )}
      >
        <span>
          <WarehouseSelect
            mode={mode}
            placeholder={this.props.t(
              'pages.Operation.InventoryCheck.create.form.warehouses',
            )}
            list={list}
            onChange={(warehouses) => {
              this.setState(
                (state) => {
                  return { ...state, warehouses, locations: [] };
                },
                () => {
                  this.getLocationsByWarehouse();
                },
              );
            }}
            value={this.state.warehouses}
            loading={warehouseList.loading}
          />
          {isProductBased ? (
            <Button
              onClick={() => {
                this.setState({ warehouses: list }, this.getProducts);
              }}
            >
              {this.props.t(
                'pages.Operation.InventoryCheck.create.button.addAll',
              )}
            </Button>
          ) : null}
        </span>
      </Form.Item>
    );
  };

  ProductSelect = () => {
    const { productList } = this.props;
    const list = (productList && productList.data) || null;

    const { type, scope, importedData } = this.state;

    if (!scope || !type || importedData) {
      return null;
    }

    if (
      // eslint-disable-next-line
      scope.key == INVENTORY_CHECK_SCOPE.PARTIAL &&
      // eslint-disable-next-line
      type.key == INVENTORY_CHECK_TYPE.PRODUCT_BASED
    ) {
      return (
        <Form.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.create.form.products',
          )}
        >
          <ProductMultiSelect
            placeholder={this.props.t(
              'pages.Operation.InventoryCheck.create.form.products',
            )}
            list={list}
            onChange={(products) => {
              this.setState({ products });
            }}
            value={this.state.products}
            loading={productList.loading}
          />
        </Form.Item>
      );
    }
    return null;
  };

  ReasonSelect = () => {
    return (
      <Form.Item label={this.props.t('common.reason')}>
        <ReasonSelect
          onChange={(reason) => {
            this.setState(() => ({
              reason,
            }));
          }}
        />
      </Form.Item>
    );
  };

  LocationSelect = () => {
    const { locationList } = this.props;
    const list = (locationList && locationList.data) || null;

    const { type, scope, locations, warehouses } = this.state;

    if (!scope || !type) {
      return null;
    }

    if (
      // eslint-disable-next-line
      scope.key == INVENTORY_CHECK_SCOPE.PARTIAL &&
      // eslint-disable-next-line
      type.key == INVENTORY_CHECK_TYPE.LOCATION_BASED &&
      warehouses.length === 1
    ) {
      return (
        <Form.Item
          label={this.props.t(
            'pages.Operation.InventoryCheck.create.form.locations',
          )}
        >
          <LocationMultiSelect
            placeholder={this.props.t(
              'pages.Operation.InventoryCheck.create.form.locations',
            )}
            list={list}
            onChange={(locations) => {
              this.setState({ locations });
            }}
            value={locations}
            loading={locationList.loading}
          />
        </Form.Item>
      );
    }

    return null;
  };

  getLocationsByWarehouse = () => {
    const { type, scope, warehouses } = this.state;

    if (!scope || !type) {
      return null;
    }

    if (
      // eslint-disable-next-line
      scope.key == INVENTORY_CHECK_SCOPE.PARTIAL &&
      // eslint-disable-next-line
      type.key == INVENTORY_CHECK_TYPE.LOCATION_BASED &&
      warehouses.length === 1
    ) {
      this.props.onGetLocationList(warehouses[0].id);
    }
  };

  importCsvDisabled = () => {
    const { type, scope } = this.state;

    if (!scope || !type) {
      return true;
    }

    if (
      // eslint-disable-next-line
      scope.key == INVENTORY_CHECK_SCOPE.PARTIAL &&
      // eslint-disable-next-line
      type.key == INVENTORY_CHECK_TYPE.PRODUCT_BASED
    ) {
      return false;
    }
    return true;
  };

  getProducts = () => {
    const { productList } = this.props;
    const { type, scope } = this.state;

    if (!scope || !type) {
      return null;
    }

    if (
      // eslint-disable-next-line
      scope.key == INVENTORY_CHECK_SCOPE.PARTIAL &&
      // eslint-disable-next-line
      type.key == INVENTORY_CHECK_TYPE.PRODUCT_BASED &&
      !productList.data
    ) {
      this.props.onGetProductList();
    }
  };

  getPayload = () => {
    const {
      type,
      scope,
      handover,
      expiryDateRequired,
      warehouses,
      locations,
      products,
      importedData,
      reason,
    } = this.state;

    const payload = {
      handover,
      expiryDateRequired,
    };

    if (
      scope &&
      type &&
      importedData &&
      importedData.length &&
      reason &&
      (reason.type !== constants.INVENTORY_CHECK_REASONS.OTHER ||
        (reason.type === constants.INVENTORY_CHECK_REASONS.OTHER &&
          reason.description))
    ) {
      return importedData.map((item) => {
        return {
          ...payload,
          type: type.key,
          scope: scope.key,
          warehouseId: item.warehouse.id,
          products: item.products.map((p) => p.id),
          reason,
        };
      });
    }

    if (
      !scope ||
      !type ||
      !warehouses.length ||
      !reason ||
      (reason.type === constants.INVENTORY_CHECK_REASONS.OTHER &&
        !reason.description)
    ) {
      return null;
    } else {
      payload.type = type.key;
      payload.scope = scope.key;
      payload.warehouseId = warehouses.map((w) => w.id);
      payload.reason = reason;
    }

    if (
      // eslint-disable-next-line
      scope.key == INVENTORY_CHECK_SCOPE.PARTIAL &&
      // eslint-disable-next-line
      type.key == INVENTORY_CHECK_TYPE.PRODUCT_BASED
    ) {
      if (!products.length) {
        return null;
      } else {
        payload.products = products.map((p) => p.id);
      }
    }

    if (
      // eslint-disable-next-line
      scope.key == INVENTORY_CHECK_SCOPE.PARTIAL &&
      // eslint-disable-next-line
      type.key == INVENTORY_CHECK_TYPE.LOCATION_BASED
    ) {
      if (!locations.length) {
        return null;
      } else {
        payload.locations = locations.map((l) => l.id);
      }
    }

    return payload;
  };

  createInventoryCheck = (payload) => {
    return this.props.onCreateInventoryCheck(payload);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading:
    state.warehouse.warehouseList.loading || state.product.productList.loading,
  warehouseList: state.warehouse.warehouseList,
  locationList: state.warehouseLocation.warehouseLocationList,
  productList: state.product.productList,
  pickerList: state.picker.list,
});

const mapDispatchToProps = (dispatch) => ({
  onGetWarehouseList: () => {
    dispatch(warehouseAction.getWarehouseList());
  },

  onGetProductList: () => {
    dispatch(
      productAction.filterProductList({
        fields: 'barcodes picURL fullName packagingInfo',
        includeDefaultFields: false,
      }),
    );
  },

  onGetLocationList: (warehouseId) => {
    const { WAREHOUSE_LOCATION_OPERATION_TYPE } = constants;
    const {
      CANCEL,
      PICKING,
      EMPTY_CARBOY,
      FROZEN,
      INCOMING,
    } = WAREHOUSE_LOCATION_OPERATION_TYPE;
    dispatch(
      warehouseLocationAction.filterWarehouseLocations({
        warehouseId,
        operationTypes: [CANCEL, PICKING, EMPTY_CARBOY, FROZEN, INCOMING],
      }),
    );
  },

  onCreateInventoryCheck: (data) => {
    return dispatch(inventoryAction.bulkCreateInventoryCheck(data));
  },
});

const InventoryCheckCreateConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(InventoryCheckCreate));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { InventoryCheckCreateConnected as InventoryCheckCreate };
