import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Menu, Dropdown, Icon } from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import './PurchaseOrderCreate.scss';
import { ProductsInfoPanel } from './ProductsInfoPanel';
import { SupplierInfoPanel } from './SupplierInfoPanel';
import { SettingsInfoPanel } from './SettingsInfoPanel';
/*----------------------------------------------------------------------------*/
import { history } from './../../../../history';
import { ImportCsv } from './../../../../components';
import {
  supplierAction,
  warehouseAction,
  productAction,
  purchaseOrderAction,
} from './../../../../actions';
import { pageLoadEvents, PurchaseOrderCreateEvents } from '../../../../services/events';
import { pageView, track } from '../../../../services/segment';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class PurchaseOrderCreate extends Component {
  settings = {
    main: {
      className: 'PurchaseOrderCreate_main',
    },
    card: {
      className: 'no-padding-card',
      title: this.props.t('pages.Operation.PurchaseOrder.create.title'),
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
    this.supplierInfoRef = React.createRef();
    this.settingsInfoRef = React.createRef();
    this.productsInfoRef = React.createRef();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_PO_CREATE });
    this.props.onGetSupplierList();
    this.props.onGetWarehouseList();
    const { id } = this.props;
    if (id) {
      this.props.onGetPurchaseOrderDetail(id);
    }
  }

  stateFactory = () => {
    return {
      isClickedCreatePurchaseOrderButton: false,
    };
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.SupplierInfoPanel />
          <this.SettingsInfoPanel />
          <this.ProductsInfoPanel />
        </Card>
      </div>
    );
  }

  CardExtra = () => {
    return (
      <Dropdown overlay={this.MenuAction}>
        <Button style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.t('pages.Operation.PurchaseOrder.create.button.actions')}{' '}
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  MenuAction = () => {
    return (
      <Menu>
        <Menu.Item key="CreateButton">
          <this.CreateButton />
        </Menu.Item>
        <Menu.Item key="ClearButton">
          <this.ClearButton />
        </Menu.Item>
        <Menu.Item key="ImportFromCsvButton">
          <this.ImportFromCsvButton />
        </Menu.Item>
        <Menu.Item key="CancelButton">
          <this.CancelButton />
        </Menu.Item>
      </Menu>
    );
  };

  CreateButton = () => {
    const { isClickedCreatePurchaseOrderButton } = this.state;
    return (
      <Button
        type="danger"
        block
        onClick={this.onCreateClick}
        loading={isClickedCreatePurchaseOrderButton}
        disabled={
          !this.isSaveButtonEnabled() || isClickedCreatePurchaseOrderButton
        }
      >
        {this.props.t('pages.Operation.PurchaseOrder.create.button.save')}
      </Button>
    );
  };

  ClearButton = () => {
    return (
      <Button block onClick={this.onClearClick}>
        {this.props.t('pages.Operation.PurchaseOrder.create.button.clean')}
      </Button>
    );
  };

  ImportFromCsvButton = () => {
    const { warehouseList } = this.props;
    const supplierInfo = this.getSupplierInfo();
    return (
      <ImportCsv
        block
        transformData={this.onImportCsvClick}
        disabled={
          !warehouseList ||
          !supplierInfo ||
          !supplierInfo.supplier ||
          (!supplierInfo.supplier._id && !supplierInfo.supplier.id)
        }
      />
    );
  };

  CancelButton = () => {
    return (
      <Button block onClick={this.onCancelClick}>
        {this.props.t('pages.Operation.PurchaseOrder.create.button.cancel')}
      </Button>
    );
  };

  SupplierInfoPanel = (props) => {
    const { supplierList } = this.props;
    return (
      <SupplierInfoPanel
        ref={this.supplierInfoRef}
        onChange={this.onSupplierInfoChange}
        onChangeSupplier={this.clearProductsInfo}
        supplierList={supplierList}
        value={this.getSupplierParentInfo()}
      />
    );
  };

  SettingsInfoPanel = () => {
    return (
      <SettingsInfoPanel
        ref={this.settingsInfoRef}
        onChange={this.onSettingsInfoChange}
      />
    );
  };

  ProductsInfoPanel = (props) => {
    const { warehouseList, productList } = this.props;
    const supplierInfo = this.getSupplierInfo();
    return (
      <ProductsInfoPanel
        ref={this.productsInfoRef}
        supplierInfo={supplierInfo}
        warehouseList={warehouseList}
        productList={productList}
      />
    );
  };

  onSupplierInfoChange = (supplierInfo) => {
    if (supplierInfo && supplierInfo.supplier && supplierInfo.supplier._id) {
      this.props.onGetProductList(supplierInfo.supplier._id);
    }
  };

  onSettingsInfoChange = (settingsInfo) => {
    console.log('onSettingsChange', settingsInfo);
  };

  onCreateClick = (data) => {
    const payload = this.getPayload();
    this.setState({ isClickedCreatePurchaseOrderButton: true });
    this.props
      .onCreatePurchaseOrder(payload)
      .then(() => {
        this.setState({ isClickedCreatePurchaseOrderButton: false });
      })
      .catch(() => {
        this.setState({ isClickedCreatePurchaseOrderButton: false });
      });
    track(PurchaseOrderCreateEvents.PO_CREATE_SAVE_BUTTON_CLICKED);
  };

  onClearClick = (data) => {
    track(PurchaseOrderCreateEvents.PO_CREATE_CLEAR_BUTTON_CLICKED);
    this.clearState();
  };

  onImportCsvClick = (importedData) => {
    console.log('onImportCsvClick', importedData);
    track(PurchaseOrderCreateEvents.PO_CREATE_IMPORT_CSV_CLICKED);
    this.clearProductsInfo();
    this.setImportedData(importedData);
  };

  setImportedData = (importedData) => {
    this.productsInfoRef.current.setImportedData(importedData);
  };

  onCancelClick = (data) => {
    track(PurchaseOrderCreateEvents.PO_CREATE_CANCEL_BUTTON_CLICKED);
    history.push('/stock-operation/purchase-order/list');
  };

  isSaveButtonEnabled = () => {
    const supplierInfo = this.getSupplierInfo();
    const orders = this.getProductsInfo();
    let flag = false;
    try {
      flag =
        (supplierInfo.supplier._id || supplierInfo.supplier.id) &&
        (supplierInfo.account._id || supplierInfo.account.id) &&
        !(supplierInfo.isEdiEnabled === null) &&
        orders &&
        orders.length;
    } catch (error) {
      flag = false;
    }
    return flag;
  };

  getPayload = () => {
    const { id } = this.props;
    const supplierInfo = this.getSupplierInfo();
    const settingsInfo = this.getSettingsInfo();
    const orders = this.getProductsInfo();
    const payload = {
      purchaseOrder: {
        supplier: supplierInfo.supplier._id || supplierInfo.supplier.id,
        accountId: supplierInfo.account._id || supplierInfo.account.id,
        isEdiEnabled: supplierInfo.isEdiEnabled,
        warehousePurchaseOrders: orders,
        expectedDeliveryDate: settingsInfo.expectedDeliveryDate,
        visibilityType: settingsInfo.isVisibilityManual ? 1 : 2,
      },
    };
    if (settingsInfo.isVisibilityManual) {
      payload.purchaseOrder.isVisible =
        settingsInfo.isVisibilityManual && settingsInfo.isVisible;
    }
    if (!settingsInfo.isVisibilityManual) {
      payload.purchaseOrder.visibilityRange = {
        start: settingsInfo.visibilityDateOpen,
        end: settingsInfo.visibilityDateClose,
      };
    }
    if (id) {
      payload.purchaseOrder.parent = id;
      payload.purchaseOrder.isMainPurchaseOrder = false;
    } else {
      payload.purchaseOrder.isMainPurchaseOrder = true;
    }
    return payload;
  };

  clearState = () => {
    this.setState(this.stateFactory());
    this.clearSupplierInfo();
    this.clearSettingsInfo();
    this.clearProductsInfo();
  };

  clearSupplierInfo = () => {
    this.supplierInfoRef.current.clearState();
  };

  clearSettingsInfo = () => {
    this.settingsInfoRef.current.clearState();
  };

  clearProductsInfo = () => {
    this.productsInfoRef.current.clearState();
  };

  getSupplierParentInfo = () => {
    if (this.props.id) {
      return this.props.detail;
    }
    return null;
  };

  getSupplierInfo = () => {
    try {
      return this.supplierInfoRef.current.getStateOrValue();
    } catch (error) {
      return {};
    }
  };

  getSettingsInfo = () => {
    return this.settingsInfoRef.current.getState();
  };

  getProductsInfo = () => {
    return this.productsInfoRef.current.getTransformedData();
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.supplier.list.loading,
  supplierList: state.supplier.list.data,
  warehouseList: state.warehouse.warehouseList.data,
  productList: state.product.productList.data,
  loadingDetail: state.purchaseOrder.detail.loading,
  detail:
    state.purchaseOrder.detail.data &&
    state.purchaseOrder.detail.data.purchaseOrder,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSupplierList: () => {
    return dispatch(supplierAction.getSupplierList());
  },
  onGetWarehouseList: () => {
    return dispatch(warehouseAction.getWarehouseList());
  },
  onGetProductList: (id) => {
    return dispatch(
      productAction.filterProductList(
        {
          fields: 'barcodes picURL fullName packagingInfo wholesaleVat price',
          includeDefaultFields: false,
          supplierIds: [id],
        },
        false,
      ),
    );
  },
  onCreatePurchaseOrder: (payload) => {
    return dispatch(purchaseOrderAction.createPurchaseOrder(payload));
  },
  onGetPurchaseOrderDetail: (id) => {
    return dispatch(
      purchaseOrderAction.getOperationPurchaseOrderParentDetail(id),
    );
  },
});

const PurchaseOrderCreateConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(PurchaseOrderCreate));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { PurchaseOrderCreateConnected as PurchaseOrderCreate };
