import React, { Component } from 'react';
import { Select, Avatar, Spin } from 'antd';
import { withNamespaces } from 'react-i18next';
import { constants } from '../../../../../shared/constants';
import { getTranslation, getCountryCode } from './../../../../../shared';
import './ProductMultiSelect.scss';
/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class ProductMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => {
    return {
      filterInput: null,
    };
  };

  render() {
    return <this.SelectField />;
  }

  SelectField = () => {
    const { disabled, loading, placeholder } = this.props;
    const country = getCountryCode();
    const isShipmentDirectForAtLeastOne =
      country !== 'tr'
        ? false
        : this.props.list &&
          this.props.list.some(
            (product) =>
              product.mainStoreSpecs &&
              product.mainStoreSpecs.shipmentType ===
                constants.PRODUCT_SHIPMENT_TYPE.DIRECT_SHIPMENT,
          );

    return (
      <>
        <Select
          mode="multiple"
          placeholder={placeholder}
          showSearch
          allowClear
          disabled={disabled}
          value={this.getValue()}
          filterOption={this.filterOption}
          onChange={this.onChange}
          onSearch={this.onSearch}
          notFoundContent={loading ? <Spin size="small" /> : null}
        >
          {this.getOptions()}
        </Select>
        {isShipmentDirectForAtLeastOne && (
          <div className="inventoryCheckProductsWarningMessage">
            {this.props.t('inventoryCheckProductsWarningMessage')}
          </div>
        )}
      </>
    );
  };

  getOptions = () => {
    const { list } = this.props;
    const { filterInput } = this.state;
    if (!list || !list.length) {
      return null;
    }
    let options = list;

    if (!filterInput) {
      options = options.slice(0, Math.min(10, options.length));
    } else {
      options = options.filter((item) => {
        return (
          filterInput &&
          filterInput.length >= 3 &&
          (getTranslation
            .obj(item.fullName)
            .toLowerCase()
            .includes(filterInput.toLowerCase()) ||
            item.id.includes(filterInput) ||
            (item.barcodes &&
              item.barcodes.length &&
              item.barcodes.includes(filterInput)))
        );
      });
    }
    return options.map((item) => {
      const country = getCountryCode();
      const isItemShipmentDirect =
        item.mainStoreSpecs &&
        item.mainStoreSpecs.shipmentType ===
          constants.PRODUCT_SHIPMENT_TYPE.DIRECT_SHIPMENT &&
        country === 'tr';
      return (
        <Select.Option
          value={getTranslation.obj(item.fullName) + item.id}
          key={item.id}
          rawvalue={item}
          disabled={isItemShipmentDirect}
        >
          <this.SelectOptionItem item={item} />
        </Select.Option>
      );
    });
  };

  SelectOptionItem = (props) => {
    const { item } = props;
    const settings = {
      label: item && item.fullName && getTranslation.obj(item.fullName),
      icon: {
        src: item && item.picURL && getTranslation.obj(item.picURL),
        size: 20,
        className: 'product-icon',
      },
    };
    return (
      <>
        <Avatar {...settings.icon} /> {settings.label}
      </>
    );
  };

  getValue = () => {
    const { value } = this.props;
    if (!value || !value.length) {
      return;
    }
    return value.map((item) => {
      return getTranslation.obj(item.fullName) + item.id;
    });
  };

  filterOption = (input, option) => {
    return (
      option.props.rawvalue.fullName[getTranslation.local()]
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0 ||
      option.props.rawvalue.id.toString().indexOf(input.toString()) >= 0 ||
      (option.props.rawvalue.barcodes &&
        option.props.rawvalue.barcodes.length &&
        option.props.rawvalue.barcodes.includes(input.toString()))
    );
  };

  onChange = (value, options) => {
    let selectedItems = [];
    try {
      selectedItems = options.map((o) => o.props.rawvalue);
    } catch (error) {
      selectedItems = [];
    }
    this.props.onChange(selectedItems);
  };

  onSearch = (value, option) => {
    this.setState({
      filterInput: value,
    });
  };
}

const ProductMultiSelectTranslated = withNamespaces('translation')(
  ProductMultiSelect,
);

export { ProductMultiSelectTranslated as ProductMultiSelect };
