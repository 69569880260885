import React, { Component } from 'react';
import { Select, Spin } from 'antd';
/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/
import { statuses, getTranslation } from './../../../../../../../shared';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export class BsConclusionTypeMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
    this.list = this.getList();
  }

  getList = () => {
    const { blockedStockConclusionType } = statuses;
    const list = [];
    for (const key in blockedStockConclusionType) {
      if (blockedStockConclusionType.hasOwnProperty(key)) {
        const element = { key, value: blockedStockConclusionType[key] };
        list.push(element);
      }
    }
    return list;
  };

  stateFactory = () => ({});

  componentDidMount() {}

  render() {
    return <this.SelectField />;
  }

  SelectField = () => {
    const { loading, disabled, placeholder } = this.props;
    return (
      <Select
        mode="multiple"
        showSearch
        allowClear
        optionFilterProp="children"
        disabled={disabled}
        placeholder={placeholder}
        value={this.getValue()}
        filterOption={this.filterOption}
        onChange={this.onChange}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {this.getOptions()}
      </Select>
    );
  };

  getOptions = () => {
    const options = this.list.map((item) => (
      <Select.Option
        value={getTranslation.blockedStockConclusionType(item.key)}
        key={item.key}
        rawvalue={item}
      >
        {getTranslation.blockedStockConclusionType(item.key)}
      </Select.Option>
    ));
    return options;
  };

  getValue = () => {
    const { value } = this.props;
    if (!value || !value.length) {
      return [];
    }
    return value.map((item) =>
      getTranslation.blockedStockConclusionType(item.key),
    );
  };

  filterOption = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  onChange = (value, options) => {
    let selectedItems = [];
    try {
      selectedItems = options.map((o) => o.props.rawvalue);
    } catch (error) {
      selectedItems = [];
    }
    this.props.onChange(selectedItems);
  };
}
