/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
// --------------------------------------------------
import './OrderList.scss';
import { OrderCard } from './OrderCard';
// --------------------------------------------------
// --------------------------------------------------

export class OrderList extends Component {
  render() {
    const { list } = this.props;
    if (!list) {
      return null;
    }
    const orderCards = list.map((order) => {
      return (
        <OrderCard
          timer={this.props.timer}
          order={order}
          key={order.id}
          openOrderDetail={this.props.openOrderDetail}
        />
      );
    });
    return <div className="OrderList_inner">{orderCards}</div>;
  }
}
