import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Button, Card, Avatar, Modal, Icon } from 'antd';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './TransferTab.scss';
import { WaybillUpload } from './../../UploadModal';
import { TransferTabFilter } from './TransferTabFilter';
/*----------------------------------------------------------------------------*/
import {
  Spinner,
  PrintButton,
  CopyToClipboard,
} from './../../../../../../components';
import { blockedStockAction } from './../../../../../../actions';
import { constants, getTranslation, getDateTimeFormatString } from './../../../../../../shared';
import i18n from '../../../../../../i18n';

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class TransferTab extends Component {
  settings = {
    main: {
      className: 'TransferTab_main',
    },
    card: {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.TransferTab.title',
      ),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    this.getList();
  }

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      isOpenModalTransferBulk: false,
      isModalTransferBulkSaveClicked: false,
      transferBulk: [],
      waybills: [],
      printed: false,
      pagination,
      query,
      filter: this.getDefaultFilter(),
    };
  };

  getDefaultFilter = () => null;

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageFilter />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => <this.TransferButton />;

  TransferButton = (props) => {
    const { transferBulk } = this.state;
    return (
      <div>
        <Button
          block
          disabled={!transferBulk.length}
          onClick={() => {
            this.setState({ isOpenModalTransferBulk: true });
          }}
        >
          {this.props.t(
            'pages.Stock.BlockedStock.list.tabs.TransferTab.button.enterWaybill',
          )}
        </Button>
        <Modal
          width={734}
          okButtonProps={{}}
          title={this.props.t(
            'pages.Stock.BlockedStock.list.tabs.TransferTab.modal.enterWaybill',
          )}
          visible={this.state.isOpenModalTransferBulk}
          footer={[
            <PrintButton
              key="print"
              changeParentState={() => {
                console.log('onPrint', this.state);
                this.setState({ printed: true });
              }}
              items={this.getPrintData()}
              settings={{
                label: (
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Icon type="printer" style={{ marginRight: 5 }} />
                    {this.props.t(
                      'pages.Stock.BlockedStock.list.tabs.TransferTab.button.printWaybill',
                    )}
                  </span>
                ),
              }}
            />,
            <Button
              key="cancel"
              onClick={() => {
                console.log('onCancel', this.state);
                this.setState((state) => ({
                  ...this.stateFactory(),
                  filter: state.filter,
                }));
              }}
            >
              {this.props.t(
                'pages.Stock.BlockedStock.list.tabs.TransferTab.button.cancel',
              )}
            </Button>,
            <Button
              key="save"
              disabled={
                !this.state.waybills.length ||
                !this.state.printed ||
                this.state.isModalTransferBulkSaveClicked
              }
              type="primary"
              onClick={() => {
                console.log('onOk', this.state);
                this.setState({ isModalTransferBulkSaveClicked: true });
                this.completeTransferBulk()
                  .then(() => {
                    this.setState((state) => ({
                      ...this.stateFactory(),
                      filter: state.filter,
                    }));
                  })
                  .catch(() => {
                    this.setState((state) => ({
                      ...this.stateFactory(),
                      filter: state.filter,
                    }));
                  });
              }}
            >
              {this.props.t(
                'pages.Stock.BlockedStock.list.tabs.TransferTab.button.save',
              )}
            </Button>,
          ]}
          onCancel={() => {
            console.log('onCancel', this.state);
            this.setState(this.stateFactory());
          }}
        >
          <WaybillUpload
            waybills={this.state.waybills}
            onChange={(waybills) => {
              this.setState({ waybills });
            }}
          />
        </Modal>
      </div>
    );
  };

  getPrintData = () => {
    const { transferBulk } = this.state;
    const printData = transferBulk.map((transfer) => ({
      fromWarehouse: transfer.warehouse.name,
      barcode: transfer.product.barcodes[0],
      name: getTranslation.obj(transfer.product.fullName),
      count: transfer.count,
    }));
    return printData;
  };

  completeTransferBulk = () => {
    const { waybills, transferBulk } = this.state;
    const query = this.getQuery();
    const filter = this.getFilter();

    return this.props.onCompleteTransferBulk(
      transferBulk,
      waybills,
      query,
      filter,
    );
  };

  PageFilter = () => (
    <TransferTabFilter
      defaultDateRange={this.defaultDateRange}
      onChange={this.setTableFilter}
      onClear={this.clearTableFilter}
    />
  );

  PageTable = (props) => (
    <div>
      <Table
        size="small"
        dataSource={this.prepareData(this.props)}
        rowSelection={this.getRowSelection()}
        columns={this.prepareColumns(this.props)}
        rowKey={(record) => record.id}
        pagination={{
          ...this.state.pagination,
          total: this.props.totalCount,
        }}
        loading={{ indicator: <Spinner />, spinning: this.props.loading }}
        onChange={this.handleTableChange}
      />
    </div>
  );

  getRowSelection = () => {
    const { transferBulk } = this.state;
    return {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          'selectedRows: ',
          selectedRows,
        );
        this.setState({ transferBulk: [...selectedRows] });
      },
      selectedRowKeys: transferBulk.map((bs) => bs.id),
    };
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      (state) => ({
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      }),
      this.getList,
    );
  };

  prepareData = (props) => {
    const { list } = this.props;
    if (!list || !list.length) {
      return [];
    }
    return list;
  };

  prepareColumns = (props) => [
    {
      dataIndex: 'product',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.TransferTab.table.product',
      ),
      render: (product) => (
        <>
          <Avatar
            className="grow"
            shape="square"
            src={getTranslation.obj(product.picURL)}
            style={{ marginRight: 5 }}
          />
          <CopyToClipboard message={getTranslation.obj(product.fullName)} />
        </>
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.TransferTab.table.supplier',
      ),
      dataIndex: 'product',
      key: 'table.supplier',
      width: 200,
      render: (product) => {
        if (!product.suppliers || !product.suppliers.length) {
          return '-';
        }
        return product.suppliers.map((supplier) => (
          <CopyToClipboard
            message={getTranslation.obj(supplier.name)}
            key={supplier.id}
            style={{ margin: 2 }}
          />
        ));
      },
    },
    {
      dataIndex: 'expiryDate',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.TransferTab.table.expireDate',
      ),
      render: (date) =>
        date
          ? moment(new Date(date))
              .local()
              .format(getDateTimeFormatString())
          : '-',
    },
    {
      dataIndex: 'status',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.TransferTab.table.status',
      ),
      render: (status) => getTranslation.blockedStockStatus(status),
    },
    {
      dataIndex: 'count',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.TransferTab.table.quantity',
      ),
    },
    {
      dataIndex: 'reason.values',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.TransferTab.table.reason',
      ),
    },
    {
      dataIndex: 'conclusionType',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.TransferTab.table.conclusionType',
      ),
      render: (code) => getTranslation.blockedStockConclusionType(code),
    },
    {
      dataIndex: 'createdAt',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.TransferTab.table.createdAt',
      ),
      render: (date) =>
        date
          ? moment(new Date(date))
              .local()
              .format(getDateTimeFormatString())
          : '-',
    },
  ];

  DetailButton = (props) => {
    const { CWId } = this.props;
    const { id } = props;
    return (
      <Link to={`/r/${CWId}/block-stock/detail/${id}`}>
        <Button size="small">
          {this.props.t(
            'pages.Stock.BlockedStock.list.tabs.TransferTab.table.detail',
          )}
        </Button>
      </Link>
    );
  };

  getList = () => {
    const query = this.getQuery();
    const filter = this.getFilter();

    if (
      filter &&
      filter.createdAt &&
      filter.createdAt.startDate &&
      filter.createdAt.endDate
    ) {
      this.props.onGetTransferTab(query, filter);
    }
  };

  getQuery = () => this.state.query;

  getFilter = () => {
    const filter = this.state.filter || {};

    if (!filter.createdAt) {
      filter.createdAt = this.defaultDateRange;
    }

    filter.warehouses = [this.props.CWId];

    const {
      BLOCKED_STOCK_STATUSES,
      BLOCKED_STOCK_CONCLUSION_TYPES,
    } = constants;
    filter.conclusionTypes = [BLOCKED_STOCK_CONCLUSION_TYPES.TRANSFER];
    filter.statuses = [BLOCKED_STOCK_STATUSES.TO_BE_TRANSFERRED];

    return filter;
  };

  setTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      }),
      this.getList,
    );
  };

  clearTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      }),
      this.getList,
    );
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.blockedStock.blockedStockList.loading,
  list: state.blockedStock.blockedStockList.data,
  totalCount: state.blockedStock.blockedStockList.total,
});

const mapDispatchToProps = (dispatch) => ({
  onGetTransferTab: (query, filter) => {
    dispatch(blockedStockAction.filterBlockedStock(query, filter));
  },
  onCompleteTransferBulk: (transferBulk, waybills, query, filter) => {
    const errorMessage = i18n.t(
      'pages.Stock.BlockedStock.list.tabs.TransferTab.notification.anErrorOccurred',
    );
    return dispatch(
      blockedStockAction.completeTransferBulk(
        transferBulk,
        waybills,
        query,
        filter,
        errorMessage,
      ),
    );
  },
});

const TransferTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(TransferTab));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { TransferTabConnected as TransferTab };
