export class TransferLogic {
  static isFromWarehouseNonAgreement(fromWarehouses) {
    return fromWarehouses && fromWarehouses.some((fromWarehouse) => fromWarehouse && fromWarehouse.isNonAgreementStore);
  }

  static hasFromWarehouseHasDincerIntegrationId(fromWarehouses) {
    return fromWarehouses && fromWarehouses.some((fromWarehouse) => fromWarehouse && fromWarehouse.dincerIntegrationId);
  }

  static isToWarehouseNonAgreement(toWarehouses) {
    return toWarehouses && toWarehouses.some((toWarehouse) => toWarehouse && toWarehouse.isNonAgreementStore);
  }

  static hasLastWarehouseHasDincerIntegrationId(selectedItems, lastElement) {
    return selectedItems.length > 1 && lastElement && lastElement.dincerIntegrationId;
  }

  static hasPenultimateWarehouseHasNotGotDincerIntegrationId(selectedItems) {
    return selectedItems.length >= 2 && !selectedItems[selectedItems.length - 2].dincerIntegrationId;
  }

  static hasPenultimateWarehouseHasDincerIntegrationId(selectedItems) {
    return selectedItems.length >= 2 && selectedItems[selectedItems.length - 2].dincerIntegrationId;
  }
}
