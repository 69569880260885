import { createHttpService } from '../createHttpService';
import { QueryBuilder } from '../QueryBuilder';

const WAREHOUSE_ID = localStorage.getItem('warehouseId');

const httpService = createHttpService();

export const warehouse = {
/* eslint-disable max-len */
  getProductList: (warehouseId, query, filter = {}) => httpService.post(`/warehouse/${warehouseId || WAREHOUSE_ID}/products?${QueryBuilder.getQueryStr(query)}`, filter),
  getExpiringProducts: (warehouseId, filter = {}) => httpService.post(`/warehouse/${warehouseId || WAREHOUSE_ID}/get-expiring-products`, filter),
  getWarehouseDetail: (id, config) => httpService.get(`/warehouse/${id || WAREHOUSE_ID}`, { ...config, ignoreHeaders: true }),
  getWarehouseList: () => httpService.get('/warehouses'),
  updateWarehouseStatus: (data) => httpService.put(`/warehouses/${WAREHOUSE_ID}/status`, data),
  getCancelledOrders: (warehouseId) => httpService.get(`/warehouse/${warehouseId || WAREHOUSE_ID}/cancelled-orders`),
  getCancelledOrdersCount: (warehouseId) => httpService.get(`/warehouse/${warehouseId || WAREHOUSE_ID}/cancelled-orders/count`),
  completeCancelledOrder: (cancelledOrderId, warehouseId) => httpService.post(`/warehouse/${warehouseId || WAREHOUSE_ID}/cancelled-orders/${cancelledOrderId}/complete`),
  getAvailableStockOfLocationsByWarehouseId: (warehouseId) => httpService.get(`/warehouse/${warehouseId || WAREHOUSE_ID}/location-stocks`),
  getReturnedOrders: (warehouseId) => httpService.get(`/warehouse/${warehouseId || WAREHOUSE_ID}/refund-orders`),
  getReturnedOrdersCount: (warehouseId) => httpService.get(`/warehouse/${warehouseId || WAREHOUSE_ID}/refund-orders/count`),
  completeReturnedOrders: (returnedOrderId, data) => httpService.post(`/warehouse/${WAREHOUSE_ID}/refund-orders/${returnedOrderId}/complete`, data),
  getMissingProductsOrders: (warehouseId) => httpService.get(`/warehouse/${warehouseId || WAREHOUSE_ID}/order-missing-products`),
  getMissingProductsOrdersCount: (data) => httpService.post('/warehouse/order-missing-products/filter/count', { warehouseIds: [WAREHOUSE_ID], ...data }),
  cancelMissingProducts: (data) => httpService.post('/warehouse/order-missing-products/cancel', data),
  removeFromSalesInMissingProductsOrders: (data) => httpService.post('/warehouse/order-missing-products/remove-from-sale', data),
  approveCancelMissingProducts: (data) => httpService.post(`/warehouse/${WAREHOUSE_ID}/compare/available-and-reserve`, data),
/* eslint-enable max-len */
};
