import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';
import { history } from '../../src/history';

export const refundAction = {
  filterRefundList,
  completeRefundBulk,
  generateEwaybill,
  createRefund,
  createWaybill,
  createWaybillBulk,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function filterRefundList(query, filter) {
  return (dispatch) => {
    dispatch({ type: actionType.filterRefundList_InProgress });
    http
      .filterRefundList(query, filter)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.filterRefundList_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.filterRefundList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.filterRefundList_Failed,
          payload: error,
        });
      });
  };
}

function completeRefundBulk(
  refundBulk,
  waybills,
  supplier,
  supplierAccount,
  query,
  filter,
  errorMessage,
) {
  console.log('completeRefundBulk reducer');
  console.log(refundBulk, waybills, supplier, supplierAccount);

  return (dispatch) => {
    dispatch({ type: actionType.completeRefundBulk_InProgress });

    return uploadFiles(waybills)
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.completeDisposal_Failed),
          description: errorMessage,
        });
        dispatch(filterRefundList(query, filter));
        throw error;
      })
      .then((uploadedFiles) => {
        const payload = {
          blockedStockIds: refundBulk.map((refund) => refund.id),
          waybills: uploadedFiles,
          supplier: supplier._id,
          accountCode: supplierAccount.code,
        };

        return http
          .createRefund(payload)
          .then(() => {
            notification.success({
              duration: 4,
              message: ErrorInfo.message(actionType.completeRefund_Succeeded),
            });
          })
          .catch((error) => {
            notification.error({
              duration: 4,
              message: ErrorInfo.message(actionType.completeRefund_Failed),
              description: ErrorInfo.description(error),
            });
          });
      })
      .then(() => {
        dispatch({ type: actionType.completeRefundBulk_Succeeded });
        dispatch(filterRefundList(query, filter));
      });
  };
}

function uploadFiles(files) {
  const filesPromises = files.map((file) => {
    return http
      .uploadWaybill({
        file: {
          name: file.imageFile.name,
          type: file.imageFile.type,
          size: file.imageFile.size,
        },
        folderName: 'waybill/',
      })
      .then((response) => {
        return http
          .uploadToS3(
            response.data.data.uploadUrls.signedUrl,
            file.imageFile.originFileObj,
          )
          .then(() => {
            return response.data;
          });
      });
  });
  return Promise.all(filesPromises).then((values) => {
    return files.map((file, i) => ({
      ss: file.ss,
      date: file.date,
      imageUrl: values[i].data.uploadUrls.imageUrl,
    }));
  });
}

function createRefund(payload, redirectUrl) {
  return (dispatch) => {
    dispatch({ type: actionType.createRefund_InProgress });
    return http
      .createRefund(payload, redirectUrl)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.createRefund_Succeeded,
          payload: data,
        });
        if (redirectUrl) {
          history.push(`${redirectUrl}/${data._id}`);
        }
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.createRefund_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.createRefund_Failed,
          payload: error,
        });
      });
  };
}

function generateEwaybill(refundId, payload) {
  return (dispatch) => {
    dispatch({ type: actionType.generateEwaybill_InProgress });
    return http
      .generateEwaybill(refundId, payload)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.generateEwaybill_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.generateEwaybill_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.generateEwaybill_Failed,
          payload: error,
        });
      });
  };
}

function createWaybill(refundId, payload) {
  return (dispatch) => {
    dispatch({ type: actionType.createWaybill_InProgress });
    return http
      .createWaybill(refundId, payload)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.createWaybill_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.createWaybill_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.createWaybill_Failed,
          payload: error,
        });
      });
  };
}


function createWaybillBulk(
  refundId,
  waybills,
) {
  return (dispatch) => {
    dispatch({ type: actionType.createWaybillBulk_InProgress });

    return uploadFiles(waybills)
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(`TODO`),
          description: "TODO",
        });
        throw error;
      })
      .then((uploadedFiles) => {
        const payload = {
          waybills: uploadedFiles,
        };
        return http
          .createWaybill(refundId, payload)
          .then(() => {
            notification.success({
              duration: 4,
              message: ErrorInfo.message(actionType.createWaybillBulk_Succeeded),
            });
          })
          .catch((error) => {
            notification.error({
              duration: 4,
              message: ErrorInfo.message(actionType.createWaybillBulk_Failed),
              description: ErrorInfo.description(error),
            });
            dispatch({ type: actionType.createWaybillBulk_Failed });
          });
      })
      .then(() => {
        dispatch({ type: actionType.createWaybillBulk_Succeeded });
      });
  };
}
