import localforage from 'localforage';

export class LocalDatabase {
  static async setWithExpiry(key, value, ttl = 1000 * 60 * 10) {
    await localforage.config({
      driver: localforage.INDEXEDDB, // same as using setDriver()
      name: 'marketPanel',
      version: 1.0,
      size: 4980736, // Size of database, in bytes. WebSQL-only for now.
      storeName: 'dataToBeCached', // Should be alphanumeric, with underscores.
      // description: 'We are using this cache for use data to be cached',
    });
    const now = new Date();
    const item = {
      value,
      expiry: now.getTime() + ttl,
    };
    await localforage.setItem(key, item);
  }

  static async getWithExpiry(key) {
    await localforage.config({
      driver: localforage.INDEXEDDB, // same as using setDriver()
      name: 'marketPanel',
      version: 1.0,
      size: 4980736, // Size of database, in bytes. WebSQL-only for now.
      storeName: 'dataToBeCached', // Should be alphanumeric, with underscores.
      // description: 'We are using this cache for use data to be cached',
    });

    const item = await localforage.getItem(key);

    if (!item) {
      return null;
    }
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      await localforage.removeItem(key);
      return null;
    }
    return item.value;
  }

  static clear() {
    return localforage.clear();
  }
}
