import React, { Component } from 'react';
import { Select, Spin } from 'antd';
import { uniqBy } from 'lodash';
/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export class ProductLocationSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => {
    return {
      selectedItem: null,
    };
  };

  render() {
    return <this.SelectField />;
  }

  SelectField = () => {
    const { disabled, loading } = this.props;
    return (
      <Select
        showSearch
        allowClear
        optionFilterProp="children"
        disabled={disabled}
        value={this.getValue()}
        filterOption={this.filterOption}
        onChange={this.onChange}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {this.getOptions()}
      </Select>
    );
  };

  getOptions = () => {
    const { list } = this.props;
    if (!list || !list.length) {
      return null;
    }
    const options = uniqBy(list, 'barcode').map((item) => {
      return (
        <Select.Option value={item.barcode} key={item.id} rawvalue={item}>
          {item.barcode}
        </Select.Option>
      );
    });
    return options;
  };

  getValue = () => {
    const { selectedItem } = this.state;
    const { list, value } = this.props;
    if (!selectedItem || !list || !list.length || !value) {
      return '';
    }
    return list
      .filter((item) => item.id === selectedItem.id)
      .map((item) => {
        return item.barcode;
      })[0];
  };

  filterOption = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  onChange = (value, option) => {
    try {
      const selectedItem = option.props.rawvalue;
      this.setState({
        selectedItem,
      });
    } catch (error) {
      this.setState({
        selectedItem: null,
      });
    }
    setTimeout(() => {
      this.props.onChange(this.state.selectedItem);
    });
  };
}
