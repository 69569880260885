import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';

export const cancelledOrdersAction = {
  getCancelledOrders,
  getCancelledOrdersCount,
  completeCancelledOrder,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getCancelledOrders() {
  return (dispatch) => {
    dispatch({
      type: actionType.getCancelledOrders_InProgress,
    });
    http
      .getCancelledOrders()
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getCancelledOrders_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getCancelledOrders_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getCancelledOrders_Failed,
          payload: error,
        });
      });
  };
}

function getCancelledOrdersCount() {
  return (dispatch) => {
    dispatch({
      type: actionType.getCancelledOrdersCount_InProgress,
    });
    http
      .getCancelledOrdersCount()
      .then((response) => {
        const { total } = response.data;
        dispatch({
          type: actionType.getCancelledOrdersCount_Succeeded,
          payload: total,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionType.getCancelledOrdersCount_Failed,
          payload: error,
        });
      });
  };
}

function completeCancelledOrder(orderId) {
  return (dispatch) => {
    dispatch({
      type: actionType.completeCancelledOrder_InProgress,
    });
    return http
      .completeCancelledOrder(orderId)
      .then((response) => {
        dispatch({
          type: actionType.completeCancelledOrder_Succeeded,
        });
        dispatch(getCancelledOrders());
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.completeCancelledOrder_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.completeCancelledOrder_Failed,
          payload: error,
        });
      });
  };
}
