import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Button, Card } from 'antd';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './IncomingTransferList.scss';
import { TransferListFilter } from './TransferListFilter';
/*----------------------------------------------------------------------------*/
import { transferAction } from '../../../../actions';
import { Spinner, CopyToClipboard } from '../../../../components';
import { constants, getTranslation, getDateTimeFormatString } from '../../../../shared';
import { Can, permission } from '../../../../guard';
import { pageView } from '../../../../services/segment';
import { pageLoadEvents } from '../../../../services/events';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class IncomingTransferList extends Component {
  settings = {
    main: {
      className: 'IncomingTransferList_main',
    },
    card: {
      title: this.props.t('pages.Stock.IncomingTransfer.list.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  dateFormat = getDateTimeFormatString();

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.STOCK_INCOMING_TRANSFER_LIST });
    this.getList();
  }

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      filter: this.getDefaultFilter(),
    };
  };

  getDefaultFilter = () => null;

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<div />}>
          <this.PageFilter />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  PageFilter = (props) => (
    <TransferListFilter
      onChange={this.setPageFilter}
      onClear={this.clearPageFilter}
    />
  );

  PageTable = () => (
    <Table
      size="small"
      dataSource={this.prepareData(this.props)}
      columns={this.prepareColumns(this.props)}
      pagination={{ ...this.state.pagination, total: this.props.totalCount }}
      loading={{ indicator: <Spinner />, spinning: this.props.loading }}
      onChange={this.handleTableChange}
    />
  );

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      (state) => ({
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      }),
      this.getList,
    );
  };

  prepareColumns = (props) => [
    {
      title: this.props.t('pages.Stock.IncomingTransfer.list.table.date'),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: this.props.t('pages.Stock.IncomingTransfer.list.table.id'),
      dataIndex: 'id',
      key: 'id',
      render: (id) => <CopyToClipboard message={id} />,
    },
    {
      title: this.props.t('pages.Stock.IncomingTransfer.list.table.no'),
      dataIndex: 'no',
      key: 'no',
      render: (no) => <CopyToClipboard message={no} />,
    },
    {
      title: this.props.t('pages.Stock.IncomingTransfer.list.table.source'),
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: this.props.t('pages.Stock.IncomingTransfer.list.table.type'),
      dataIndex: 'integrationType',
      key: 'integrationType',
      render: (type) => getTranslation.transferIntegrationType(type),
    },
    {
      title: this.props.t('pages.Stock.IncomingTransfer.list.table.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => getTranslation.transferStatus(status),
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'detail',
      render: (id) => (
        <Can allow={permission.transferDetail}>
          <Link to={`/r/${props.currentWarehouseId}/stock/transfer/${id}`}>
            <Button size="small">
              {this.props.t('pages.Stock.IncomingTransfer.list.table.detail')}
            </Button>
          </Link>
        </Can>
      ),
    },
  ];

  prepareData = (props) => {
    const { IncomingTransferList } = props;
    if (!IncomingTransferList || !IncomingTransferList.length) {
      return null;
    }
    return IncomingTransferList.map((transfer) => ({
      date: moment(new Date(transfer.updatedAt))
        .local()
        .format(getDateTimeFormatString()),
      source: transfer.fromWarehouse ? transfer.fromWarehouse.name || '-' : '-',
      integrationType: transfer.integrationType,
      status: transfer.status,
      id: transfer._id,
      key: transfer._id,
      no: transfer.no,
    })).filter(
      (transfer) =>
        transfer.status !== constants.TRANSFER_STATUS.CREATED &&
        transfer.status !== constants.TRANSFER_STATUS.DELETED,
    );
  };

  getList = () => {
    const query = this.getQuery();
    const filter = this.getFilter();
    this.props.onGetIncomingTransferList({ ...query, ...filter });
  };

  getQuery = () => this.state.query;

  getFilter = () => {
    const filter = this.state.filter || {};
    return filter;
  };

  setPageFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      }),
      this.getList,
    );
  };

  clearPageFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      }),
      this.getList,
    );
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  currentWarehouseId: state.auth.currentWarehouse._id,
  IncomingTransferList: state.transfer.list.data,
  loading: state.transfer.list.loading,
  totalCount: state.transfer.list.total,
});

const mapDispatchToProps = (dispatch) => ({
  onGetIncomingTransferList: (query) =>
    dispatch(transferAction.getIncomingTransferList(query)),
});

const IncomingTransferListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(IncomingTransferList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { IncomingTransferListConnected as IncomingTransferList };
