import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Row, Col } from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import './BlockedStockTransferList.scss';
import {
  WarehouseMultiSelect,
  ProductMultiSelect,
  TagMultiSelect,
} from './filterFields';
/*----------------------------------------------------------------------------*/
import { warehouseAction, productAction } from './../../../../../actions';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class BlockedStockTransferFilter extends Component {
  settings = {
    main: {
      className: 'BlockedStockTransferFilter_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    const { onGetWarehouseList, onGetProductList } = this.props;
    onGetWarehouseList();
    onGetProductList();
  }

  stateFactory = () => {
    return {
      ...this.emptyTableFilter(),
    };
  };

  emptyTableFilter = () => {
    return {
      transferIds: [],
      sourceWarehouses: [],
      destinationWarehouses: [],
      products: [],
      waybillIds: [],
      pickerIds: [],
    };
  };

  render() {
    return (
      <div {...this.settings.main}>
        <this.TableFilterForm />
      </div>
    );
  }

  TableFilterForm = (props) => {
    return (
      <div>
        <Form>
          <Row>
            <Col span={12} style={{ padding: '0px 5px' }}>
              <this.SourceWarehouseField />
            </Col>
            <Col span={12} style={{ padding: '0px 5px' }}>
              <this.DestinationWarehouseField />
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ padding: '0px 5px' }}>
              <this.ProductField />
            </Col>
          </Row>

          <Row>
            <Col span={8} style={{ padding: '0px 5px' }}>
              <this.TransferIdField />
            </Col>
            <Col span={8} style={{ padding: '0px 5px' }}>
              <this.WaybillIdField />
            </Col>
            <Col span={8} style={{ padding: '0px 5px' }}>
              <this.PickerIdField />
            </Col>
          </Row>

          <Row>
            <Col span={2} style={{ padding: '0px 5px' }}>
              <this.ClearFilterButton />
            </Col>
            <Col offset={20} span={2} style={{ padding: '0px 5px' }}>
              <this.FilterButton />
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  SourceWarehouseField = () => {
    const { warehouseList } = this.props;
    const { sourceWarehouses } = this.state;
    const list = (warehouseList && warehouseList.data) || null;
    return (
      <Form.Item>
        <WarehouseMultiSelect
          list={list}
          onChange={(sourceWarehouses) => {
            this.setState({
              sourceWarehouses,
            });
          }}
          value={sourceWarehouses}
          loading={warehouseList.loading}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.transfer.list.filter.sourceWarehouse',
          )}
        />
      </Form.Item>
    );
  };

  DestinationWarehouseField = () => {
    const { warehouseList } = this.props;
    const { destinationWarehouses } = this.state;
    const list = (warehouseList && warehouseList.data) || null;
    return (
      <Form.Item>
        <WarehouseMultiSelect
          list={list}
          onChange={(destinationWarehouses) => {
            this.setState({
              destinationWarehouses,
            });
          }}
          value={destinationWarehouses}
          loading={warehouseList.loading}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.transfer.list.filter.destinationWarehouse',
          )}
        />
      </Form.Item>
    );
  };

  ProductField = () => {
    const { productList } = this.props;
    const { products } = this.state;
    const list = (productList && productList.data) || null;
    return (
      <Form.Item>
        <ProductMultiSelect
          list={list}
          onChange={(products) => {
            this.setState({
              products,
            });
          }}
          value={products}
          loading={productList.loading}
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.transfer.list.filter.product',
          )}
        />
      </Form.Item>
    );
  };

  TransferIdField = () => {
    const { transferIds } = this.state;
    return (
      <Form.Item>
        <TagMultiSelect
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.transfer.list.filter.transferId',
          )}
          disabled={false}
          onChange={(transferIds) => {
            if (transferIds[0] === '') {
              this.setState({
                transferIds: [],
              });
              return;
            }

            this.setState({
              transferIds,
            });
          }}
          value={transferIds}
        />
      </Form.Item>
    );
  };

  WaybillIdField = () => {
    const { waybillIds } = this.state;
    return (
      <Form.Item>
        <TagMultiSelect
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.transfer.list.filter.waybillId',
          )}
          disabled={false}
          onChange={(waybillIds) => {
            if (waybillIds[0] === '') {
              this.setState({
                waybillIds: [],
              });
              return;
            }

            this.setState({
              waybillIds,
            });
          }}
          value={waybillIds}
        />
      </Form.Item>
    );
  };

  PickerIdField = () => {
    const { pickerIds } = this.state;
    return (
      <Form.Item>
        <TagMultiSelect
          placeholder={this.props.t(
            'pages.Operation.BlockedStock.transfer.list.filter.pickerId',
          )}
          disabled={false}
          onChange={(pickerIds) => {
            if (pickerIds[0] === '') {
              this.setState({
                pickerIds: [],
              });
              return;
            }

            this.setState({
              pickerIds,
            });
          }}
          value={pickerIds}
        />
      </Form.Item>
    );
  };

  FilterButton = () => {
    return (
      <Form.Item>
        <Button
          type="primary"
          block
          onClick={() => {
            const filter = this.getTableFilter();
            this.props.onChange(filter);
          }}
        >
          {this.props.t(
            'pages.Operation.BlockedStock.transfer.list.filter.filter',
          )}
        </Button>
      </Form.Item>
    );
  };

  ClearFilterButton = () => {
    return (
      <Form.Item>
        <Button
          block
          onClick={() => {
            this.clearFilter();
          }}
        >
          {this.props.t(
            'pages.Operation.BlockedStock.transfer.list.filter.clean',
          )}
        </Button>
      </Form.Item>
    );
  };

  getTableFilter = () => {
    const {
      transferIds,
      sourceWarehouses,
      destinationWarehouses,
      products,
      waybillIds,
      pickerIds,
    } = this.state;

    const filter = {};

    if (products && products.length) {
      filter.productIds = products.map((p) => p.id);
    }

    if (sourceWarehouses && sourceWarehouses.length) {
      filter.sourceWarehouses = sourceWarehouses.map(
        (warehouse) => warehouse.id,
      );
    }

    if (destinationWarehouses && destinationWarehouses.length) {
      filter.destinationWarehouses = destinationWarehouses.map(
        (warehouse) => warehouse.id,
      );
    }

    if (pickerIds && pickerIds.length) {
      filter.pickerIds = pickerIds;
    }

    if (transferIds && transferIds.length) {
      filter.transferIds = transferIds;
    }

    if (waybillIds && waybillIds.length) {
      filter.waybillIds = waybillIds;
    }

    return filter;
  };

  clearFilter = () => {
    this.setState({ ...this.state, ...this.stateFactory() }, () => {
      this.props.onClear(this.getTableFilter());
    });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse.id,
  warehouseList: state.warehouse.warehouseList,
  productList: state.product.productList,
});

const mapDispatchToProps = (dispatch) => ({
  onGetWarehouseList: () => {
    dispatch(warehouseAction.getWarehouseList());
  },
  onGetProductList: () => {
    dispatch(
      productAction.filterProductList({
        fields: 'barcodes picURL fullName packagingInfo',
        includeDefaultFields: false,
      }),
    );
  },
});

const BlockedStockTransferFilterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(BlockedStockTransferFilter));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { BlockedStockTransferFilterConnected as BlockedStockTransferFilter };
