import React, { Component } from 'react';
import { isNumber, isBoolean } from 'lodash';
import { Select } from 'antd';
import { withNamespaces } from 'react-i18next';

class DifferenceStockControlSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    return {};
  };

  render() {
    return <this.SelectField />;
  }

  SelectField = () => {
    const { placeholder } = this.props;
    return (
      <Select
        disabled={this.props.disabled}
        placeholder={placeholder}
        value={this.getValue()}
        onChange={this.onChange}
        allowClear
      >
        <Select.Option value={1}>
          {this.props.t('pages.Operation.InventoryCheck.list.filter.yes')}
        </Select.Option>
        <Select.Option value={0}>
          {this.props.t('pages.Operation.InventoryCheck.list.filter.no')}
        </Select.Option>
      </Select>
    );
  };

  getValue = () => {
    const { value } = this.props;
    if (isBoolean(value)) {
      return value ? 1 : 0;
    }
    return undefined;
  };

  onChange = (value) => {
    this.props.onChange(isNumber(value) ? !!value : undefined);
  };
}

const DifferenceStockControlSelectTranslated = withNamespaces('translation')(
  DifferenceStockControlSelect,
);

export { DifferenceStockControlSelectTranslated as DifferenceStockControlSelect };
