import React, { Component } from 'react';
import { Select, Spin } from 'antd';
import { uniqBy } from 'lodash';
/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export class WarehouseSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => ({});

  render() {
    return <this.SelectField />;
  }

  SelectField = () => {
    const {
      allowClear, loading, disabled, placeholder, mode,
    } = this.props;
    return (
      <Select
        showSearch
        mode={mode}
        allowClear={allowClear}
        optionFilterProp="children"
        disabled={disabled}
        value={this.getValue()}
        filterOption={this.filterOption}
        placeholder={placeholder}
        onChange={this.onChange}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {this.getOptions()}
      </Select>
    );
  };

  getOptions = () => {
    const { list } = this.props;
    if (!list || !list.length) {
      return;
    }
    const options = uniqBy(list, 'name').map((item) => (
      <Select.Option value={item.name} key={item.id} rawvalue={item}>
        {item.name}
      </Select.Option>
    ));
    return options;
  };

  getValue = () => {
    const { value } = this.props;
    if (!value || !value.length) {
      return;
    }
    if (this.props.mode === 'multiple') {
      return value.map((item) => item.name);
    }
    return value[0].name;
  };

  filterOption = (input, option) => (
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
      option.props.rawvalue.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
  );

  onChange = (value, options) => {
    let selectedItems = [];
    try {
      if (this.props.mode === 'multiple') {
        selectedItems = options.map((o) => o.props.rawvalue);
      } else {
        selectedItems.push(options.props.rawvalue);
      }
    } catch (error) {
      selectedItems = [];
    }
    this.props.onChange(selectedItems);
  };
}
