import { uniqBy } from 'lodash';

import { actionType } from './../actions';
import { createReducer } from './createReducer';

const initialState = {
  warehouseLocationList: {
    loading: true,
    data: null,
    error: null,
  },
};

// filterWarehouseLocation_InProgress
const switcher = {
  [actionType.filterWarehouseLocation_InProgress]: filterWarehouseLocation_InProgress,
  [actionType.filterWarehouseLocation_Succeeded]: filterWarehouseLocation_Succeeded,
  [actionType.filterWarehouseLocation_Failed]: filterWarehouseLocation_Failed,
};

export const warehouseLocationReducer = createReducer(switcher, initialState);

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function filterWarehouseLocation_InProgress(state, payload) {
  return Object.assign({}, state, {
    warehouseLocationList: {
      loading: true,
      data: null,
      error: null,
    },
  });
}

function filterWarehouseLocation_Succeeded(state, payload) {
  return Object.assign({}, state, {
    warehouseLocationList: {
      loading: false,
      data: uniqBy(payload, 'id'),
      error: null,
    },
  });
}

function filterWarehouseLocation_Failed(state, payload) {
  return Object.assign({}, state, {
    warehouseLocationList: {
      loading: false,
      data: null,
      error: payload,
    },
  });
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/
