import React, { Component } from 'react';
import { Modal } from 'antd';
import QRCode from 'qrcode-react';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class TerminalAppLink extends Component {
  state = {
    visibleQrModal: false,
  };

  render() {
    return (
      <>
        <div
          style={{ cursor: 'pointer' }}
          className="text-center mb-1"
          onClick={this.showQRLink}
        >
          {this.props.t('components.TerminalAppLink.linkTitle')}
        </div>
        <this.QrModal />
      </>
    );
  }

  showQRLink = (event) => {
    event.preventDefault();
    this.setState({
      visibleQrModal: true,
    });
  };

  generateLink = () => {
    return `https://cdn.getir.com/misc/TERMINAL_PROD.vnd.android.package-archive?v=${Date.now()}`;
  };

  QrModal = () => {
    return (
      <Modal
        title={this.props.t('components.TerminalAppLink.linkTitle')}
        visible={this.state.visibleQrModal}
        centered
        onOk={() => {
          this.setState({ visibleQrModal: false });
        }}
        onCancel={() => {
          this.setState({ visibleQrModal: false });
        }}
        footer={[]}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <QRCode value={this.generateLink()} />
        </div>
      </Modal>
    );
  };
}
const TerminalAppLinkTranslated = withNamespaces('translation')(
  TerminalAppLink,
);

export { TerminalAppLinkTranslated as TerminalAppLink };
