import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import {
  Card,
  Descriptions,
  Table,
  Avatar,
  Dropdown,
  Button,
  Icon,
  Menu,
  Tag,
} from 'antd';
import { Link } from 'react-router-dom';
/*----------------------------------------------------------------------------*/
import './PurchaseOrderDetail.scss';
import { VisibilitySettings } from './VisibilitySettings';
import { ItemsTable } from './ItemsTable';
import { SubPoTable } from './SubPoTable';
/*----------------------------------------------------------------------------*/
import {
  Spinner,
  JsonButton,
  CopyToClipboard,
  ActionTimeline,
} from './../../../../components';
import {
  getTranslation,
  constants,
  getDateTimeFormatString,
} from './../../../../shared';
import { purchaseOrderAction } from './../../../../actions';
import { history } from './../../../../history';
import { NextStep, NextStepDomainTypes } from '../../shared/NextStep/NextStep';
import { pageView, track } from '../../../../services/segment';
import { pageLoadEvents, PurchaseOrderDetailEvents } from '../../../../services/events';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class PurchaseOrderDetail extends Component {
  settings = {
    main: {
      className: 'PurchaseOrderDetail_main',
    },
    card: {
      title: this.props.t('pages.Operation.PurchaseOrder.detail.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    return {
      isClickedOrderPurchaseOrderButton: false,
      isClickedSendToSupplierButton: false,
      isClickedCancelPoButton: false,
    };
  };

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.OPS_PO_DETAIL });
    this.getDetail();
  }

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {loading ? <Spinner /> : <this.PageDetail />}
        </Card>
      </div>
    );
  }

  CardExtra = () => {
    return (
      <Dropdown overlay={this.MenuAction}>
        <Button style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.t('pages.Operation.PurchaseOrder.detail.button.actions')}{' '}
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  MenuAction = () => {
    return (
      <Menu>
        <Menu.Item key="JsonButton" onClick={() => track(PurchaseOrderDetailEvents.PO_DETAIL_JSON_CLICKED)}>
          <JsonButton data={this.props.detail} block />
        </Menu.Item>
        <Menu.Item key="OrderButton">
          <this.OrderButton />
        </Menu.Item>
        <Menu.Item key="CreateSpoButton">
          <this.CreateSpoButton />
        </Menu.Item>
        <Menu.Item key="CancelPoButton">
          <this.CancelPoButton />
        </Menu.Item>
        <Menu.Item key="SendToWMSButton">
          <this.SendToWMSButton />
        </Menu.Item>
        {this.props.detail && this.props.detail.isEdiEnabled && (
          <Menu.Item key="SendToSupplierButton">
            <this.SendToSupplierButton />
          </Menu.Item>
        )}
      </Menu>
    );
  };

  OrderButton = () => {
    const { isClickedOrderPurchaseOrderButton } = this.state;
    return (
      <Button
        block
        loading={isClickedOrderPurchaseOrderButton}
        disabled={
          !this.isOperationOrderAvailable() || isClickedOrderPurchaseOrderButton
        }
        onClick={() => {
          this.setState({ isClickedOrderPurchaseOrderButton: true });
          this.orderPo();
          track(PurchaseOrderDetailEvents.PO_DETAIL_ORDER_CLICKED);
        }}
      >
        {this.props.t('pages.Operation.PurchaseOrder.detail.button.order')}
      </Button>
    );
  };

  CreateSpoButton = () => {
    return (
      <Button
        block
        disabled={!this.isOperationSpoAvailable()}
        onClick={() => {
          this.createSpo();
          track(PurchaseOrderDetailEvents.PO_DETAIL_CREATE_SPO_CLICKED);
        }}
      >
        {this.props.t('pages.Operation.PurchaseOrder.detail.button.createSpo')}
      </Button>
    );
  };

  CancelPoButton = () => {
    const { isClickedCancelPoButton } = this.state;
    return (
      <Button
        block
        loading={isClickedCancelPoButton}
        disabled={!this.isCancelPoAvailable() || isClickedCancelPoButton}
        onClick={() => {
          this.setState({ isClickedCancelPoButton: true });
          this.cancelPo();
          track(PurchaseOrderDetailEvents.PO_DETAIL_CANCEL_PO_CLICKED);
        }}
      >
        {this.props.t('pages.Operation.PurchaseOrder.detail.button.cancel')}
      </Button>
    );
  };

  cancelPo = () => {
    console.log('cancelPo');
    const { onCancelPurchaseOrder, id, detail } = this.props;
    onCancelPurchaseOrder(id, detail.isMainWarehousePurchaseOrder)
      .then(() => {
        this.setState({ isClickedCancelPoButton: false });
      })
      .catch(() => {
        this.setState({ isClickedCancelPoButton: false });
      });
  };

  isCancelPoAvailable = () => {
    const { detail } = this.props;
    try {
      if (
        detail.status === constants.PURCHASE_ORDER_STATUSES.CREATED ||
        detail.status === constants.PURCHASE_ORDER_STATUSES.ORDERED
      ) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  SendToWMSButton = () => {
    return (
      <Button
        block
        disabled={!this.isSendToWmsAvailable()}
        onClick={() => {
          this.sendToWMS();
          track(PurchaseOrderDetailEvents.PO_DETAIL_SEND_TO_WMS_CLICKED);
        }}
      >
        {this.props.t('pages.Operation.PurchaseOrder.detail.button.sendToWMS')}
      </Button>
    );
  };

  isSendToWmsAvailable = () => {
    const { detail: purchaseOrder } = this.props;
    try {
      if (!purchaseOrder) {
        return null;
      }
      let isEnabled = false;
      const isMainWarehouse = purchaseOrder.isMainWarehousePurchaseOrder;
      const isOrderedOrReceivingStarted =
        purchaseOrder.status === constants.PURCHASE_ORDER_STATUSES.ORDERED ||
        purchaseOrder.status ===
          constants.PURCHASE_ORDER_STATUSES.RECEIVING_STARTED;
      if (isMainWarehouse && isOrderedOrReceivingStarted) {
        isEnabled = true;
      }
      return isEnabled;
    } catch (error) {
      return false;
    }
  };

  SendToSupplierButton = () => {
    const { isClickedSendToSupplierButton } = this.state;
    return (
      <Button
        block
        type="danger"
        loading={isClickedSendToSupplierButton}
        disabled={!this.isSendToSupplierAvailable()}
        onClick={() => {
          this.setState({ isClickedSendToSupplierButton: true });
          this.sendToSupplier();
          track(PurchaseOrderDetailEvents.PO_DETAIL_SEND_TO_SUPPLIER_CLICKED);
        }}
      >
        {this.props.t(
          'pages.Operation.PurchaseOrder.detail.button.sendToSupplier',
        )}
      </Button>
    );
  };

  isSendToSupplierAvailable = () => {
    const { isClickedSendToSupplierButton } = this.state;
    const { detail: purchaseOrder } = this.props;
    return (
      !isClickedSendToSupplierButton &&
      purchaseOrder &&
      purchaseOrder.status === constants.PURCHASE_ORDER_STATUSES.ORDERED &&
      purchaseOrder.supplier.isApiEnabled &&
      purchaseOrder.isEdiEnabled &&
      !this.allWpoIsSentToSupplier()
    );
  };

  allWpoIsSentToSupplier = () => {
    const { detail } = this.props;
    if (
      !detail ||
      !detail.warehousePurchaseOrders ||
      !detail.warehousePurchaseOrders.length
    ) {
      return null;
    }
    const counter = this.getSupplierStatusCounter();
    if (
      counter[constants.SUPPLIER_API_STATUSES.SENT] ===
      detail.warehousePurchaseOrders.length
    ) {
      return true;
    }
  };

  PageDetail = (props) => {
    return (
      <div>
        <this.Description />
        <this.VisibilitySettings />
        <this.Table />
        {this.props.detail.isMainPurchaseOrder && <this.SubPoTable />}
        <ActionTimeline
          actions={this.props.detail}
          translate={getTranslation.operationPurchaseOrderStatus}
        />
        {this.props.detail.status !==
          constants.PURCHASE_ORDER_STATUSES.CREATED && (
          <ItemsTable id={this.props.id} />
        )}
      </div>
    );
  };

  isVisibilitySettingsEditable = () => {
    const { detail } = this.props;
    if (!detail) {
      return false;
    }
    const {
      CREATED,
      ORDERED,
      RECEIVING_STARTED,
    } = constants.PURCHASE_ORDER_STATUSES;
    const isEditable = [CREATED, ORDERED, RECEIVING_STARTED].includes(
      detail.status,
    );
    return isEditable;
  };

  VisibilitySettings = () => {
    const { detail } = this.props;
    if (!detail) {
      return null;
    }
    return (
      <VisibilitySettings
        isEditable={this.isVisibilitySettingsEditable()}
        isVisible={detail.isVisible}
        visibilityType={detail.visibilityType}
        expectedDeliveryDate={detail.expectedDeliveryDate}
        visibilityRange={detail.visibilityRange}
        onSave={(settings) => {
          const { id, onUpdatePurchaseOrder } = this.props;
          console.log('save visibility settings', settings);
          onUpdatePurchaseOrder(id, settings);
        }}
      />
    );
  };

  Description = (props) => {
    const { detail } = this.props;
    if (!detail) {
      return null;
    }
    return (
      <Descriptions column={1}>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.PurchaseOrder.detail.description.id',
          )}
        >
          <CopyToClipboard message={detail.id} />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.PurchaseOrder.detail.description.no',
          )}
        >
          <CopyToClipboard message={detail.no} />
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.PurchaseOrder.detail.description.status',
          )}
        >
          {getTranslation.operationPurchaseOrderStatus(detail.status)}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.PurchaseOrder.detail.description.supplier',
          )}
        >
          {detail.supplier.name}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.PurchaseOrder.detail.description.supplierStatus',
          )}
        >
          {this.SupplierStatusesCounterTag()}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.PurchaseOrder.detail.description.createdAt',
          )}
        >
          {moment(detail.createdAt)
            .local()
            .format(getDateTimeFormatString())}
        </Descriptions.Item>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.PurchaseOrder.detail.description.mainPurchaseOrder',
          )}
        >
          {!detail.isMainPurchaseOrder && detail.parent ? (
            <div>
              <CopyToClipboard message={detail.parent} />
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={`/stock-operation/purchase-order/detail/${detail.parent}`}
              >
                {this.props.t('common.link')}
              </Link>
            </div>
          ) : (
            '-'
          )}
        </Descriptions.Item>
        {detail.isMainWarehousePurchaseOrder && (
          <Descriptions.Item
            label={this.props.t(
              'pages.Operation.PurchaseOrder.wpoDetail.description.nextStep',
            )}
          >
            <NextStep
              transferStatus={detail.status}
              domainType={NextStepDomainTypes.PO}
            />
          </Descriptions.Item>
        )}
      </Descriptions>
    );
  };

  supplierApiStatusTagColor = {
    [constants.SUPPLIER_API_STATUSES.ERR]: 'red',
    [constants.SUPPLIER_API_STATUSES.NONE]: 'blue',
    [constants.SUPPLIER_API_STATUSES.SENDING]: 'yellow',
    [constants.SUPPLIER_API_STATUSES.SENT]: 'green',
  };

  SupplierStatusesCounterTag = () => {
    const supplierApiStatusCounter = this.getSupplierStatusCounter();

    const apiStatusArray = [];

    for (const supplierApiStatus in supplierApiStatusCounter) {
      if (
        Object.hasOwnProperty.call(supplierApiStatusCounter, supplierApiStatus)
      ) {
        if (!supplierApiStatusCounter[supplierApiStatus]) {
          continue;
        }
        apiStatusArray.push(
          <Tag color={this.supplierApiStatusTagColor[supplierApiStatus]}>
            {getTranslation.supplierApiStatus(supplierApiStatus)}:{' '}
            {supplierApiStatusCounter[supplierApiStatus]}
          </Tag>,
        );
      }
    }

    return <span>{apiStatusArray}</span>;
  };

  SubPoTable = () => {
    if (!this.props.subPurchaseOrders || !this.props.subPurchaseOrders.length) {
      return null;
    }
    return <SubPoTable subPurchaseOrders={this.props.subPurchaseOrders} />;
  };

  getSupplierStatusCounter = () => {
    const { detail } = this.props;
    if (
      !detail ||
      !detail.warehousePurchaseOrders ||
      !detail.warehousePurchaseOrders.length
    ) {
      return null;
    }
    const supplierApiStatusCounter = {
      [constants.SUPPLIER_API_STATUSES.ERR]: 0,
      [constants.SUPPLIER_API_STATUSES.NONE]: 0,
      [constants.SUPPLIER_API_STATUSES.SENDING]: 0,
      [constants.SUPPLIER_API_STATUSES.SENT]: 0,
    };
    detail.warehousePurchaseOrders.forEach((wpo) => {
      supplierApiStatusCounter[wpo.supplierApiStatus]++;
    });

    return supplierApiStatusCounter;
  };

  Table = () => {
    const { detail } = this.props;
    if (
      !detail ||
      !detail.warehousePurchaseOrders ||
      !detail.warehousePurchaseOrders.length
    ) {
      return null;
    }
    const { warehousePurchaseOrders } = detail;
    return (
      <Table
        size="small"
        columns={this.prepareColumns(warehousePurchaseOrders)}
        dataSource={this.prepareData(warehousePurchaseOrders)}
        expandedRowRender={this.expandedRowRender}
        rowKey={(record) => {
          return record.id;
        }}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  prepareColumns = (props) => {
    return [
      {
        title: this.props.t('pages.Operation.PurchaseOrder.detail.table.id'),
        dataIndex: 'id',
        key: 'id copy',
        render: (id) => <CopyToClipboard message={id} />,
      },
      {
        title: this.props.t('pages.Operation.PurchaseOrder.detail.table.no'),
        dataIndex: 'no',
        key: 'no',
        render: (no) => <CopyToClipboard message={no} />,
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.detail.table.supplierStatus',
        ),
        dataIndex: 'supplierApiStatus',
        key: 'supplierApiStatus',
        render: (supplierApiStatus) => {
          return (
            <Tag color={this.supplierApiStatusTagColor[supplierApiStatus]}>
              {getTranslation.supplierApiStatus(supplierApiStatus)}
            </Tag>
          );
        },
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.detail.table.status',
        ),
        dataIndex: 'status',
        key: 'status',
        render: (status) => getTranslation.operationPurchaseOrderStatus(status),
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.detail.table.warehouse',
        ),
        dataIndex: 'warehouse.name',
        key: 'warehouse',
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.detail.table.supplier',
        ),
        dataIndex: 'supplier.name',
        key: 'supplier',
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.detail.table.createdAt',
        ),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) => {
          return moment(new Date(createdAt))
            .local()
            .format(getDateTimeFormatString());
        },
      },
      {
        dataIndex: 'id',
        key: 'id',
        render: (id) => {
          return <this.DetailWpoButton id={id} />;
        },
      },
    ];
  };

  DetailWpoButton = (props) => {
    const { id } = props;
    const url = `/stock-operation/warehouse-purchase-order/detail/${id}`;
    return (
      <Link to={url} target="_blank" rel="noopener noreferrer">
        <Button
          size="small"
          onClick={() => track(PurchaseOrderDetailEvents.PO_DETAIL_WPO_DETAIL_BUTTON_CLICKED)}
        >
          {this.props.t('pages.Operation.PurchaseOrder.detail.button.detail')}
        </Button>
      </Link>
    );
  };

  prepareData = (props) => {
    return props;
  };

  expandedRowRender = (record, index, indent, expanded) => {
    return (
      <Table
        size="small"
        columns={this.prepareExtendedColumns(record)}
        dataSource={this.prepareExtendedData(record)}
        rowKey={(data) => {
          return data.item.id;
        }}
        pagination={false}
      />
    );
  };

  prepareExtendedColumns = (record) => {
    return [
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.detail.subTable.image',
        ),
        dataIndex: 'item.picURL',
        key: 'picURL',
        render: (picURL) => (
          <Avatar
            className="grow"
            shape="square"
            src={getTranslation.obj(picURL)}
          />
        ),
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.detail.subTable.itemId',
        ),
        dataIndex: 'item.id',
        key: 'item.id',
        render: (id) => <CopyToClipboard message={id} />,
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.detail.subTable.itemName',
        ),
        dataIndex: 'item.fullName',
        key: 'item.fullName',
        render: (fullName) => (
          <CopyToClipboard message={getTranslation.obj(fullName)} />
        ),
      },
      {
        title: this.props.t(
          'pages.Operation.PurchaseOrder.detail.subTable.orderedCount',
        ),
        dataIndex: 'orderedCount',
        key: 'orderedCount',
      },
    ];
  };

  prepareExtendedData = (record) => {
    if (!record.items || !record.items.length) {
      return [];
    }
    console.log(record.items);
    return record.items;
  };

  getDetail = () => {
    const { id, onGetPurchaseOrderDetail } = this.props;
    onGetPurchaseOrderDetail(id);
  };

  orderPo = () => {
    const { id, onOrderPurchaseOrder } = this.props;
    onOrderPurchaseOrder(id)
      .then(() => {
        this.setState({ isClickedOrderPurchaseOrderButton: false });
      })
      .catch(() => {
        this.setState({ isClickedOrderPurchaseOrderButton: false });
      });
  };

  createSpo = () => {
    const { detail } = this.props;
    const url = `/stock-operation/sub-purchase-order/create/${detail.id}`;
    history.push(url);
  };

  isOperationOrderAvailable = () => {
    const { detail } = this.props;
    try {
      if (detail.status === constants.PURCHASE_ORDER_STATUSES.CREATED) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  isOperationSpoAvailable = () => {
    const { detail } = this.props;
    const {
      ORDERED,
      RECEIVING_STARTED,
      COMPLETED,
    } = constants.PURCHASE_ORDER_STATUSES;
    try {
      if (
        detail.isMainPurchaseOrder &&
        (detail.status === ORDERED ||
          detail.status === RECEIVING_STARTED ||
          detail.status === COMPLETED)
      ) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  sendToWMS = () => {
    const { detail, onSendToWmsPurchaseOrder } = this.props;
    onSendToWmsPurchaseOrder(detail.id);
  };

  sendToSupplier = () => {
    const { onSendToSupplier } = this.props;
    onSendToSupplier(this.props.id)
      .then(() => {
        this.setState({ isClickedSendToSupplierButton: false });
      })
      .catch(() => {
        this.setState({ isClickedSendToSupplierButton: false });
      });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.purchaseOrder.detail.loading,
  detail:
    state.purchaseOrder.detail.data &&
    state.purchaseOrder.detail.data.purchaseOrder,
  subPurchaseOrders:
    state.purchaseOrder.detail.data &&
    state.purchaseOrder.detail.data.subPurchaseOrders,
});

const mapDispatchToProps = (dispatch) => ({
  onGetPurchaseOrderDetail: (id) => {
    dispatch(purchaseOrderAction.getOperationPurchaseOrderDetail(id));
  },
  onOrderPurchaseOrder: (id) => {
    return dispatch(purchaseOrderAction.orderPurchaseOrder(id));
  },
  onSendToSupplier: (purchaseOrderId) => {
    return dispatch(purchaseOrderAction.sendToSupplier(purchaseOrderId));
  },
  onCancelPurchaseOrder: (purchaseOrderId, isMainWarehousePurchaseOrder) => {
    return dispatch(
      purchaseOrderAction.cancelPurchaseOrder(
        purchaseOrderId,
        isMainWarehousePurchaseOrder,
      ),
    );
  },
  onUpdatePurchaseOrder: (purchaseOrderId, data) => {
    dispatch(purchaseOrderAction.updatePurchaseOrder(purchaseOrderId, data));
  },
  onSendToWmsPurchaseOrder: (id) => {
    return dispatch(purchaseOrderAction.sendToWmsPurchaseOrder(id));
  },
});

const PurchaseOrderDetailConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(PurchaseOrderDetail));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { PurchaseOrderDetailConnected as PurchaseOrderDetail };
