import React from 'react';
import { withNamespaces } from 'react-i18next';
import { CopyToClipboard as Clipboard } from 'react-copy-to-clipboard';
/*----------------------------------------------------------------------------*/
import { Tag, message } from 'antd';
/*----------------------------------------------------------------------------*/

const CopyToClipboard = (props) => (
  <Clipboard
    onCopy={() => {
      message.success(
        `${props.t('components.CopyToClipboard.copyToClipboard.text')}: ${props.message}`,
        1,
      );
    }}
    text={props.message}
  >
    <Tag
      size="small"
      color={props.color || null}
      style={{ ...props.style } || null}
    >
      {props.inner || props.message}
    </Tag>
  </Clipboard>
);

const CopyToClipboardTranslated = withNamespaces('translation')(
  CopyToClipboard,
);

export { CopyToClipboardTranslated as CopyToClipboard };
