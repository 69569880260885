import React from 'react';
import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo, ExportToExcel } from './../components';
import { history } from '../../src/history';

import { purchaseOrderAction } from './purchaseOrder';

import { chunkPromises } from './../shared/promise';
import i18n from './../i18n';

export const transferAction = {
  getIncomingTransferList,
  getIncomingTransferDetail,
  createTransfer,
  getTransferList,
  getTransferDetail,
  updateTransferItems,
  approveTransfer,
  cancelTransfer,
  deleteTransfer,
  centralWarehouseOutgoingTransfer,
  centralWarehouseIncomingTransfer,
  sendWarehousePurchaseOrders,
  departTransferVehicle,
  sendDepartedOrder,
  exportToXlsxTransferList,
  shipDirect,
  addOutgoingTransferPalletBarcode,
  bulkSendToWmsTransfer,
  addNoteToTransfer,
  getEquipmentTransferList,
  createEquipmentTransfer,
  changeTransferVisibility,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function getIncomingTransferList(query) {
  return (dispatch) => {
    dispatch({ type: actionType.getIncomingTransferList_InProgress });
    http
      .getIncomingTransferList(query)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getIncomingTransferList_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getIncomingTransferList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getIncomingTransferList_Failed,
          payload: error,
        });
      });
  };
}

function addOutgoingTransferPalletBarcode({ palletBarcode, warehouseId }) {
  return (dispatch) => {
    dispatch({ type: actionType.addOutgoingTransferPalletBarcode_InProgress });
    return http
      .addOutgoingTransferPalletBarcode({ barcode: palletBarcode, warehouseId })
      .then((response) => {
        dispatch({
          type: actionType.addOutgoingTransferPalletBarcode_Succeeded,
          payload: response,
        });
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.addOutgoingTransferPalletBarcode_Succeeded,
          ),
          description: i18n.t(
            'shared.messages.addOutgoingTransferPalletBarcodeSuccess',
          ),
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.addOutgoingTransferPalletBarcode_Failed,
          ),
          description: i18n.t(
            'shared.messages.addOutgoingTransferPalletBarcodeError',
          ),
        });
        dispatch({
          type: actionType.addOutgoingTransferPalletBarcode_Failed,
          payload: error,
        });
        throw error;
      });
  };
}
function shipDirect(data) {
  return (dispatch) => {
    dispatch({ type: actionType.shipDirect_InProgress });
    return http
      .shipDirect(data)
      .then((response) => {
        console.log(response, data);
        dispatch({
          type: actionType.shipDirect_Succeeded,
          payload: response,
        });
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.shipDirect_Succeeded),
          description: i18n.t('shared.messages.shipDirectSuccess'),
        });
        dispatch(getTransferDetail(data.transferId));
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message &&
          error.response.data.data &&
          error.response.data.data.notExistsOrNotEnoughProductLocations
        ) {
          notification.error({
            duration: 4,
            message: ErrorInfo.message(actionType.shipDirect_Failed),
            description: (
              <div>
                <div>{error.response.data.message}</div>
                <div>
                  <div>{i18n.t('shared.messages.productIds')}:</div>
                  {error.response.data.data.notExistsOrNotEnoughProductLocations.map(
                    (product) => {
                      return <div>{product.productId}</div>;
                    },
                  )}
                </div>
              </div>
            ),
          });
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          notification.error({
            duration: 4,
            message: ErrorInfo.message(actionType.shipDirect_Failed),
            description: error.response.data.message,
          });
        } else {
          notification.error({
            duration: 4,
            message: ErrorInfo.message(actionType.shipDirect_Failed),
            description: i18n.t('shared.messages.shipDirectError'),
          });
        }
        dispatch({
          type: actionType.shipDirect_Failed,
          payload: error,
        });
      });
  };
}
function getIncomingTransferDetail(id) {
  return (dispatch) => {
    dispatch({ type: actionType.getIncomingTransferDetail_InProgress });
    http
      .getIncomingTransferDetail(id)
      .then((response) => {
        dispatch({
          type: actionType.getIncomingTransferDetail_Succeeded,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.getIncomingTransferDetail_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getIncomingTransferDetail_Failed,
          payload: error,
        });
      });
  };
}

function createTransfer(data, CWId, errorMessage) {
  return (dispatch) => {
    dispatch({
      type: actionType.createTransfer_InProgress,
    });
    return chunkPromises({ data, fn: http.createTransfer })
      .then((response) => {
        if (response.some((r) => r.status === 'rejected')) {
          throw response;
        }
        if (
          response.every(
            (r) => r.status === 'fulfilled' && r.value.status === 200,
          )
        ) {
          notification.success({
            duration: 4,
            message: ErrorInfo.message(actionType.createTransfer_Succeeded),
          });
        }
        dispatch({
          type: actionType.createTransfer_Succeeded,
          payload: { data: { data: {} } },
        });
        const url = '/stock-operation/transfer/list';
        history.push(url);
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.createTransfer_Failed),
          description:
            error.length > 1
              ? errorMessage
              : ErrorInfo.description(error[0].reason),
        });
        dispatch({
          type: actionType.createTransfer_Failed,
          payload: error,
        });
        const url = '/stock-operation/transfer/list';
        history.push(url);
      });
  };
}

function getTransferList(query, filter) {
  return (dispatch) => {
    dispatch({ type: actionType.getTransferList_InProgress });
    http
      .getTransferList(query, filter)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getTransferList_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getTransferList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getTransferList_Failed,
          payload: error,
        });
      });
  };
}

function getTransferDetail(transferId) {
  return (dispatch) => {
    dispatch({ type: actionType.getTransferDetail_InProgress });
    http
      .getTransferDetail(transferId)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getTransferDetail_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getTransferDetail_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getTransferDetail_Failed,
          payload: error,
        });
      });
  };
}

function addNoteToTransfer(transferId, note) {
  return (dispatch) => {
    dispatch({ type: actionType.addNoteToTransfer_InProgress });
    return http
      .addNoteToTransfer(transferId, note)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.addNoteToTransfer_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.addNoteToTransfer_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.addNoteToTransfer_Failed,
          payload: error,
        });
      });
  };
}

function updateTransferItems(transferId, items) {
  return (dispatch) => {
    dispatch({
      type: actionType.updateTransferItems_InProgress,
    });
    http
      .updateTransferItems(transferId, items)
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.updateTransferItems_Succeeded),
        });
        dispatch({
          type: actionType.updateTransferItems_Succeeded,
          payload: response,
        });
        dispatch(getTransferDetail(transferId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.updateTransferItems_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.updateTransferItems_Failed,
          payload: error,
        });
      });
  };
}

function approveTransfer({
  data,
  transferId,
  query,
  filter,
  isBulkApprove = false,
  errorMessage,
}) {
  const transferIds = isBulkApprove ? data : [transferId];
  return (dispatch) => {
    dispatch({
      type: actionType.approveTransfer_InProgress,
    });
    return chunkPromises({
      data: transferIds,
      fn: http.approveTransfer,
      chunkSize: 1,
      isDelayRequired: isBulkApprove,
    })
      .then((response) => {
        if (response.some((r) => r.status === 'rejected')) {
          throw response;
        }
        if (
          response.every(
            (r) => r.status === 'fulfilled' && r.value.status === 200,
          )
        ) {
          notification.success({
            duration: 4,
            message: ErrorInfo.message(actionType.approveTransfer_Succeeded),
          });
        }
        dispatch({
          type: actionType.approveTransfer_Succeeded,
          payload: isBulkApprove ? { data: { data: {} } } : response[0].value,
        });
        if (isBulkApprove) {
          // dispatch(getTransferList(query, filter));
        } else {
          dispatch(getTransferDetail(transferId));
        }
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.approveTransfer_Failed),
          description: isBulkApprove
            ? errorMessage
            : ErrorInfo.description(error[0].reason),
        });
        dispatch({
          type: actionType.approveTransfer_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function cancelTransfer({
  data,
  transferId,
  query,
  filter,
  isBulkCancel = false,
  errorMessage,
}) {
  const transferIds = isBulkCancel ? data : [transferId];
  return (dispatch) => {
    dispatch({
      type: actionType.cancelTransfer_InProgress,
    });
    return chunkPromises({
      data: transferIds,
      fn: http.cancelTransfer,
      chunkSize: 1,
      isDelayRequired: isBulkCancel,
    })
      .then((response) => {
        if (response.some((r) => r.status === 'rejected')) {
          throw response;
        }
        if (
          response.every(
            (r) => r.status === 'fulfilled' && r.value.status === 200,
          )
        ) {
          notification.success({
            duration: 4,
            message: ErrorInfo.message(actionType.cancelTransfer_Succeeded),
          });
        }
        dispatch({
          type: actionType.cancelTransfer_Succeeded,
          payload: isBulkCancel ? { data: { data: {} } } : response[0].value,
        });
        if (isBulkCancel) {
          // dispatch(getTransferList(query, filter));
        } else {
          dispatch(getTransferDetail(transferId));
        }
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.cancelTransfer_Failed),
          description: isBulkCancel
            ? errorMessage
            : ErrorInfo.description(error[0].reason),
        });
        dispatch({
          type: actionType.cancelTransfer_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function deleteTransfer(transferId) {
  return (dispatch) => {
    dispatch({
      type: actionType.deleteTransfer_InProgress,
    });
    return http
      .deleteTransfer(transferId)
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.deleteTransfer_Succeeded),
        });
        dispatch({
          type: actionType.deleteTransfer_Succeeded,
          payload: response,
        });
        dispatch(getTransferDetail(transferId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.deleteTransfer_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.deleteTransfer_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function centralWarehouseOutgoingTransfer(
  transferId,
  domainType,
  directionType,
) {
  return (dispatch) => {
    dispatch({
      type: actionType.centralWarehouseOutgoingTransfer_InProgress,
    });
    http
      .centralWarehouseOutgoingTransfer(transferId, domainType, directionType)
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.centralWarehouseOutgoingTransfer_Succeeded,
          ),
        });
        dispatch({
          type: actionType.centralWarehouseOutgoingTransfer_Succeeded,
          payload: response,
        });
        dispatch(getTransferDetail(transferId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.centralWarehouseOutgoingTransfer_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.centralWarehouseOutgoingTransfer_Failed,
          payload: error,
        });
      });
  };
}

function centralWarehouseIncomingTransfer(
  transferId,
  domainType,
  directionType,
) {
  return (dispatch) => {
    dispatch({
      type: actionType.centralWarehouseIncomingTransfer_InProgress,
    });
    http
      .centralWarehouseIncomingTransfer(transferId, domainType, directionType)
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.centralWarehouseIncomingTransfer_Succeeded,
          ),
        });
        dispatch({
          type: actionType.centralWarehouseIncomingTransfer_Succeeded,
          payload: response,
        });
        dispatch(getTransferDetail(transferId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.centralWarehouseIncomingTransfer_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.centralWarehouseIncomingTransfer_Failed,
          payload: error,
        });
      });
  };
}

function departTransferVehicle(transferId, data) {
  return (dispatch) => {
    dispatch({
      type: actionType.departTransferVehicle_InProgress,
    });
    http
      .departTransferVehicle(data, transferId)
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.departTransferVehicle_Succeeded,
          ),
        });
        dispatch({
          type: actionType.departTransferVehicle_Succeeded,
          payload: response,
        });
        dispatch(getTransferDetail(transferId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.departTransferVehicle_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.departTransferVehicle_Failed,
          payload: error,
        });
      });
  };
}

function sendDepartedOrder(transferId, data) {
  return (dispatch) => {
    dispatch({
      type: actionType.sendDepartedOrder_InProgress,
    });
    http
      .sendDepartedOrder(data, transferId)
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(actionType.sendDepartedOrder_Succeeded),
        });
        dispatch({
          type: actionType.sendDepartedOrder_Succeeded,
          payload: response,
        });
        dispatch(getTransferDetail(transferId));
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.sendDepartedOrder_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.sendDepartedOrder_Failed,
          payload: error,
        });
      });
  };
}

function sendWarehousePurchaseOrders(purchaseOrderId, warehousePurchaseOrders) {
  return (dispatch) => {
    dispatch({
      type: actionType.sendWarehousePurchaseOrders_InProgress,
    });
    http
      .sendWarehousePurchaseOrders(warehousePurchaseOrders)
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.sendWarehousePurchaseOrders_Succeeded,
          ),
        });
        dispatch({
          type: actionType.sendWarehousePurchaseOrders_Succeeded,
          payload: response,
        });
        dispatch(
          purchaseOrderAction.getOperationPurchaseOrderDetail(purchaseOrderId),
        );
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.sendWarehousePurchaseOrders_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.sendWarehousePurchaseOrders_Failed,
          payload: error,
        });
      });
  };
}

function bulkSendToWmsTransfer(transferIds) {
  return (dispatch) => {
    dispatch({
      type: actionType.bulkSendToWmsTransfer_InProgress,
    });
    return http
      .bulkSendToWmsTransfer(transferIds)
      .then((response) => {
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.bulkSendToWmsTransfer_Succeeded,
          ),
        });
        dispatch({
          type: actionType.bulkSendToWmsTransfer_Succeeded,
          payload: response,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.bulkSendToWmsTransfer_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.bulkSendToWmsTransfer_Failed,
          payload: error,
        });
        throw error;
      });
  };
}

function exportToXlsxTransferList(query, filter, fileName, formatter) {
  return (dispatch) => {
    dispatch({ type: actionType.exportToXlsxTransferList_InProgress });
    getDataForExport(query, filter)
      .then((responses) => {
        ExportToExcel.fileSave({
          fileName,
          data: formatter(responses),
        });
        dispatch({
          type: actionType.exportToXlsxTransferList_Succeeded,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(
            actionType.exportToXlsxTransferList_Failed,
          ),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.exportToXlsxTransferList_Failed,
          payload: error,
        });
      });
  };
}

function getDataForExport(query, filter) {
  const limit = 50;
  const offset = 0;
  const total = query.limit;
  return getCallOneByOne(total, filter, limit, offset, []);
}

function getCallOneByOne(total, filter, limit, offset, acc = []) {
  if (offset >= total) {
    return http
      .getTransferList({ limit, offset }, filter)
      .then((res) => {
        acc.push(...res.data.data.transfers);
        return acc;
      })
      .catch((err) => acc);
  }
  return http
    .getTransferList({ limit, offset }, filter)
    .then((res) => {
      acc.push(...res.data.data.transfers);
      if (res.data.data.transfers.length !== 0) {
        return getCallOneByOne(total, filter, limit, offset + limit, acc);
      }
    })
    .catch((err) => getCallOneByOne(total, filter, limit, offset + limit, acc));
}

// equipment transfer
function getEquipmentTransferList(query, filter) {
  return (dispatch) => {
    dispatch({ type: actionType.getEquipmentTransferList_InProgress });
    http
      .getTransferList(query, filter)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.getEquipmentTransferList_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.getEquipmentTransferList_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.getEquipmentTransferList_Failed,
          payload: error,
        });
      });
  };
}

function createEquipmentTransfer(query, filter) {
  return (dispatch) => {
    dispatch({ type: actionType.createEquipmentTransfer_InProgress });
    const { redirectUrl, ...payload } = query;
    http
      .createEquipmentTransfer(payload, filter)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.createEquipmentTransfer_Succeeded,
          payload: data,
        });
        if (redirectUrl) {
          history.push(redirectUrl);
        }
        notification.success({
          duration: 4,
          message: ErrorInfo.message(
            actionType.createEquipmentTransfer_Succeeded,
          ),
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.createEquipmentTransfer_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.createEquipmentTransfer_Failed,
          payload: error,
        });
      });
  };
}


function changeTransferVisibility(transferId, isVisibilityEnabled) {
  return (dispatch) => {
    dispatch({ type: actionType.changeTransferVisibility_InProgress });
    http
      .changeTransferVisibility(transferId, isVisibilityEnabled)
      .then((response) => {
        const { data } = response.data;
        dispatch({
          type: actionType.changeTransferVisibility_Succeeded,
          payload: data,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.changeTransferVisibility_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.changeTransferVisibility_Failed,
          payload: error,
        });
      });
  };
}
