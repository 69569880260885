import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form, Button, Row, Col,
} from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import {
  TransferTypeMultiSelect,
  TransferStatusMultiSelect,
} from './filterFields';
/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class TransferListFilter extends Component {
  settings = {
    main: {
      className: 'TransferListFilter_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => ({
    ...this.emptyTableFilter(),
  });

  emptyTableFilter = () => ({
    statuses: [],
    integrationTypes: [],
  });

  render() {
    return (
      <div {...this.settings.main}>
        <this.TableFilterForm />
      </div>
    );
  }

  TableFilterForm = (props) => (
    <div>
      <Form>
        <Row>
          <Col span={12} style={{ padding: '0px 5px' }}>
            <this.TransferStatusSelect />
          </Col>
          <Col span={12} style={{ padding: '0px 5px' }}>
            <this.TransferTypeSelect />
          </Col>
        </Row>

        <Row>
          <Col span={2} style={{ padding: '0px 5px' }}>
            <this.ClearFilterButton />
          </Col>
          <Col offset={20} span={2} style={{ padding: '0px 5px' }}>
            <this.FilterButton />
          </Col>
        </Row>
      </Form>
    </div>
  );

  TransferTypeSelect = () => {
    const { integrationTypes } = this.state;
    return (
      <Form.Item>
        <TransferTypeMultiSelect
          onChange={(integrationTypes) => {
            this.setState({
              integrationTypes,
            });
          }}
          placeholder={this.props.t('pages.Stock.OutgoingTransfer.list.filter.transferType')}
          value={integrationTypes}
        />
      </Form.Item>
    );
  };

  TransferStatusSelect = () => {
    const { statuses } = this.state;
    return (
      <Form.Item>
        <TransferStatusMultiSelect
          onChange={(statuses) => {
            this.setState({
              statuses,
            });
          }}
          placeholder={this.props.t('pages.Stock.OutgoingTransfer.list.filter.transferStatus')}
          value={statuses}
        />
      </Form.Item>
    );
  };

  FilterButton = () => (
    <Form.Item>
      <Button
        type="primary"
        block
        onClick={() => {
          const filter = this.getTableFilter();
          this.filterChanged(filter);
        }}
      >
        {this.props.t('pages.Stock.OutgoingTransfer.list.filter.filter')}
      </Button>
    </Form.Item>
  );

  ClearFilterButton = () => (
    <Form.Item>
      <Button
        block
        onClick={() => {
          this.clearFilter();
        }}
      >
        {this.props.t('pages.Stock.OutgoingTransfer.list.filter.clean')}
      </Button>
    </Form.Item>
  );

  getTableFilter = () => {
    const { statuses, integrationTypes } = this.state;

    const filter = {};

    if (statuses && statuses.length) {
      filter.statuses = statuses.map((transferStatus) => transferStatus.key);
    }

    if (integrationTypes && integrationTypes.length) {
      filter.types = integrationTypes
        .map((transferType) => transferType.key)
        .join(',');
    }

    return filter;
  };

  filterChanged = (filter) => {
    this.props.onChange(filter);
  };

  clearFilter = () => {
    this.setState({ ...this.state, ...this.stateFactory() }, () => {
      this.props.onClear(this.getTableFilter());
    });
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse.id,
  currentWarehouse: state.auth.currentWarehouse,
});

const mapDispatchToProps = (dispatch) => ({});

const TransferListFilterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(TransferListFilter));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { TransferListFilterConnected as TransferListFilter };
