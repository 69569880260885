import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
// --------------------------------------------------
import CheckIfStartOfHour from '../../../components/CheckIfStartOfHour';
// --------------------------------------------------
import { history } from './../../../history';
import { heatingEstimationAction } from '../../../actions';
import { pageView, track } from '../../../services/segment';
import { pageLoadEvents } from '../../../services/events';

// --------------------------------------------------

class HeatingEstimationAlert extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => ({
    isHeatingEstimationPageOpened: false,
    hasEstimation: false,
  });

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.HOME_HEATING_ESTIMATION, props: { google: true } });
    const localStorageIsHeatingEstimationPageOpened = localStorage.getItem(
      'isHeatingEstimationPageOpened',
    );

    if (
      localStorageIsHeatingEstimationPageOpened === null ||
      localStorage.getItem('estimationDate') === null ||
      this.checkIfHoursAreDifferent() ||
      this.checkIfDaysAreDifferent()
    ) {
      this.props.onGetHeatingEstimationList();
      localStorage.setItem('isHeatingEstimationPageOpened', false);
      this.setState({ isHeatingEstimationPageOpened: false });
    } else {
      this.setState({
        isHeatingEstimationPageOpened: localStorageIsHeatingEstimationPageOpened,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { estimationProducts } = this.props;
    if (prevProps.estimationProducts !== estimationProducts) {
      const hasSomePositiveEstimation = (estimationProducts || []).some(
        ({ suggestion }) => suggestion > 0,
      );
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasEstimation: hasSomePositiveEstimation });
    }
  }

  addZeroBefore = (n) => (n < 10 ? '0' : '') + n;

  checkIfHoursAreDifferent = () =>
    this.addZeroBefore(new Date().getHours()) !==
    localStorage
      .getItem('estimationDate')
      .split(':')[0]
      .slice(-2);

  checkIfDaysAreDifferent = () =>
    new Date()
      .toString()
      .split(':')[0]
      .split(' ')[2] !==
    localStorage
      .getItem('estimationDate')
      .split(':')[0]
      .split(' ')[2];

  handleHourStart = () => {
    localStorage.setItem('isHeatingEstimationPageOpened', false);
    this.setState({ isHeatingEstimationPageOpened: false });

    this.props.onGetHeatingEstimationList();
  };

  handleClick = () => {
    const warehouseId = localStorage.getItem('warehouseId');
    history.push(`/r/${warehouseId}/stock/heating-estimation/list`);

    localStorage.setItem('isHeatingEstimationPageOpened', true);
    this.setState({ isHeatingEstimationPageOpened: true });

    localStorage.setItem('estimationDate', new Date().toString());
    track('HOME_HEATING_ESTIMATION_ALERT_CLICKED');
  };

  render() {
    const { t } = this.props;
    const isAlertShown =
      !this.state.isHeatingEstimationPageOpened && this.state.hasEstimation;
    return (
      <>
        <CheckIfStartOfHour onHourStart={this.handleHourStart} />
        {isAlertShown && (
          <span
            className="buttonAnimation"
            style={{
              zIndex: 10000,
              position: 'absolute',
              bottom: '5px',
              padding: '20px',
              margin: '8px',
              backgroundColor: 'white',
              border: '1px solid gray',
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              cursor: 'pointer',
              maxWidth: '350px',
            }}
            onClick={this.handleClick}
          >
            <div
              style={{
                color: 'red',
                fontSize: '18px',
                fontWeight: '700',
              }}
            >
              {t('pages.Home.alertMessageForHeatingEstimation')}
            </div>
            <div>{t('alertMessageForHeatingEstimationDescription')}</div>
            <div>{t('alertMessageForHeatingEstimationDescriptionSmall')}</div>
          </span>
        )}
      </>
    );
  }
}
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  estimationProducts: state.heatingEstimation.heatingEstimationList.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetHeatingEstimationList: () =>
    dispatch(heatingEstimationAction.getHeatingEstimationList()),
});

const HeatingEstimationAlertConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(HeatingEstimationAlert));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { HeatingEstimationAlertConnected as HeatingEstimationAlert };
