import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Table, Button, Dropdown, Icon, Menu } from 'antd';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './OutgoingTransferList.scss';
import { TransferListFilter } from './TransferListFilter';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard } from './../../../../components';
/*----------------------------------------------------------------------------*/
import { outgoingTransferAction } from './../../../../actions';
import {
  constants,
  getTranslation,
  getDateTimeFormatString,
} from './../../../../shared';
import { pageView } from '../../../../services/segment';
import { pageLoadEvents } from '../../../../services/events';
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class OutgoingTransferList extends Component {
  settings = {
    main: {
      className: 'OutgoingTransferList_main',
    },
    card: {
      title: this.props.t('pages.Stock.OutgoingTransfer.list.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    pageView({ pageName: pageLoadEvents.STOCK_OUTGOING_TRANSFER_LIST });
    this.getList();
  }

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      filter: this.getDefaultFilter(),
    };
  };

  getDefaultFilter = () => {
    return null;
  };

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageFilter />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    return (
      <Dropdown overlay={this.DropdownMenu}>
        <Button style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.t(
            'pages.Stock.OutgoingTransfer.list.extra.createSelfOutgoingTransfer',
          )}{' '}
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  DropdownMenu = () => {
    if (this.isWaterWarehouse()) {
      return (
        <Menu>
          <Menu.Item key="CreateEmptyCarboyTransfer">
            <Link to={`/r/${this.props.CWId}/stock/transfer/outgoing/create`}>
              <Button type="danger" style={{ marginLeft: 10 }} size="small">
                {this.props.t('pages.Stock.OutgoingTransfer.list.extra.create')}
              </Button>
            </Link>{' '}
          </Menu.Item>
          <Menu.Item key="CreateSafeAndPalletTransfer">
            <Link
              to={`/r/${this.props.CWId}/stock/transfer/outgoing/create-case-and-pallet-transfer`}
            >
              <Button type="danger" style={{ marginLeft: 10 }} size="small">
                {this.props.t(
                  'pages.Stock.OutgoingTransfer.list.extra.createCaseAndPalletTransfer',
                )}
              </Button>
            </Link>{' '}
          </Menu.Item>
        </Menu>
      );
    } else {
      return (
        <Menu>
          <Menu.Item key="CreateSafeAndPalletTransfer">
            <Link
              to={`/r/${this.props.CWId}/stock/transfer/outgoing/create-case-and-pallet-transfer`}
            >
              <Button type="danger" style={{ marginLeft: 10 }} size="small">
                {this.props.t(
                  'pages.Stock.OutgoingTransfer.list.extra.createCaseAndPalletTransfer',
                )}
              </Button>
            </Link>{' '}
          </Menu.Item>
        </Menu>
      );
    }
  };

  PageFilter = (props) => {
    return (
      <TransferListFilter
        onChange={this.setPageFilter}
        onClear={this.clearPageFilter}
      />
    );
  };

  PageTable = (props) => {
    return (
      <div>
        <Table
          size="small"
          dataSource={this.prepareData(this.props)}
          columns={this.prepareColumns(this.props)}
          rowKey={(record) => {
            return record._id;
          }}
          pagination={{
            ...this.state.pagination,
            total: this.props.totalCount,
          }}
          loading={{ indicator: <Spinner />, spinning: this.props.loading }}
          onChange={this.handleTableChange}
        />
      </div>
    );
  };

  isWaterWarehouse = () => {
    const { currentWarehouse } = this.props;
    return currentWarehouse.domainTypes.includes(constants.DOMAIN_TYPE.WATER);
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState((state) => {
      return {
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      };
    }, this.getList);
  };

  prepareColumns = (props) => {
    return [
      {
        title: this.props.t('pages.Stock.OutgoingTransfer.list.table.date'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (date) => {
          return moment(new Date(date))
            .local()
            .format(getDateTimeFormatString());
        },
      },
      {
        title: this.props.t('pages.Stock.OutgoingTransfer.list.table.id'),
        dataIndex: '_id',
        key: '_id',
        render: (_id) => <CopyToClipboard message={_id} />,
      },
      {
        title: this.props.t('pages.Stock.OutgoingTransfer.list.table.no'),
        dataIndex: 'no',
        key: 'no',
        render: (no) => <CopyToClipboard message={no} />,
      },
      {
        title: this.props.t(
          'pages.Stock.OutgoingTransfer.list.table.destination',
        ),
        dataIndex: 'toWarehouse.name',
        key: 'destination',
      },
      {
        title: this.props.t('pages.Stock.OutgoingTransfer.list.table.type'),
        dataIndex: 'integrationType',
        key: 'integrationType',
        render: (type) => getTranslation.transferIntegrationType(type),
      },
      {
        title: this.props.t('pages.Stock.OutgoingTransfer.list.table.status'),
        dataIndex: 'status',
        key: 'status',
        render: (status) => getTranslation.transferStatus(status),
      },
      {
        title: '',
        dataIndex: '_id',
        key: 'detail',
        render: (id) => {
          return <this.DetailButton id={id} />;
        },
      },
    ];
  };

  DetailButton = (props) => {
    const url = `/r/${this.props.CWId}/stock/transfer/outgoing/detail/${props.id}`;
    return (
      <Link to={url}>
        <Button size="small">
          {this.props.t('pages.Stock.OutgoingTransfer.list.table.detail')}
        </Button>
      </Link>
    );
  };

  prepareData = (props) => {
    const { outgoingTransferList } = this.props;
    if (!outgoingTransferList || !outgoingTransferList.length) {
      return null;
    }
    return outgoingTransferList.filter((transfer) => {
      return (
        transfer.status !== constants.TRANSFER_STATUS.CREATED &&
        transfer.status !== constants.TRANSFER_STATUS.DELETED
      );
    });
  };

  getList = () => {
    const query = this.getQuery();
    const filter = this.getFilter();
    this.props.onGetOutgoingTransferList({ ...query, ...filter });
  };

  getQuery = () => {
    return this.state.query;
  };

  getFilter = () => {
    const filter = this.state.filter || {};
    return filter;
  };

  setPageFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      };
    }, this.getList);
  };

  clearPageFilter = (filter) => {
    this.setState((state) => {
      return {
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      };
    }, this.getList);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.outgoingTransfer.list.loading,
  outgoingTransferList: state.outgoingTransfer.list.data,
  totalCount: state.outgoingTransfer.list.total,
  currentWarehouse: state.auth.currentWarehouse,
});

const mapDispatchToProps = (dispatch) => ({
  onGetOutgoingTransferList: (query) => {
    dispatch(outgoingTransferAction.getOutgoingTransferList(query));
  },
});

const OutgoingTransferListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(OutgoingTransferList));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { OutgoingTransferListConnected as OutgoingTransferList };
