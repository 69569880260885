import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Button, Card, Avatar } from 'antd';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './ListTab.scss';
import { ListTabFilter } from './ListTabFilter';
/*----------------------------------------------------------------------------*/
import { Spinner, CopyToClipboard } from './../../../../../../components';
import { blockedStockAction } from './../../../../../../actions';
import { getTranslation, getDateTimeFormatString } from './../../../../../../shared';

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class ListTab extends Component {
  settings = {
    main: {
      className: 'ListTab_main',
    },
    card: {
      title: this.props.t('pages.Stock.BlockedStock.list.tabs.ListTab.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  dateFormat = getDateTimeFormatString();

  constructor(props) {
    super(props);
    this.defaultDateRange = this.getDefaultDateRange();
    this.state = this.stateFactory();
  }

  componentDidMount() {
    this.getList();
  }

  getDefaultDateRange = () => {
    const today = new Date(Date.now());
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return {
      startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    };
  };

  stateFactory = () => {
    const pagination = {
      defaultPageSize: 20,
      pageSize: 20,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
    };
    const query = {
      limit: pagination.defaultPageSize,
      offset: 0,
    };
    return {
      pagination,
      query,
      filter: this.getDefaultFilter(),
    };
  };

  getDefaultFilter = () => null;

  render() {
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          <this.PageFilter />
          <this.PageTable />
        </Card>
      </div>
    );
  }

  CardExtra = (props) => <div />;

  PageFilter = () => (
    <ListTabFilter
      defaultDateRange={this.defaultDateRange}
      onChange={this.setTableFilter}
      onClear={this.clearTableFilter}
    />
  );

  PageTable = (props) => (
    <div>
      <Table
        size="small"
        dataSource={this.prepareData(this.props)}
        columns={this.prepareColumns(this.props)}
        rowKey={(record) => record.id}
        pagination={{
          ...this.state.pagination,
          total: this.props.totalCount,
        }}
        loading={{ indicator: <Spinner />, spinning: this.props.loading }}
        onChange={this.handleTableChange}
      />
    </div>
  );

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      (state) => ({
        ...state,
        query: {
          limit: pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1),
        },
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      }),
      this.getList,
    );
  };

  prepareData = (props) => {
    const { list } = this.props;
    if (!list || !list.length) {
      return [];
    }
    return list;
  };

  prepareColumns = (props) => [
    {
      dataIndex: 'product',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.ListTab.table.product',
      ),
      render: (product) => (
        <>
          <Avatar
            className="grow"
            shape="square"
            src={getTranslation.obj(product.picURL)}
            style={{ marginRight: 5 }}
          />
          <CopyToClipboard message={getTranslation.obj(product.fullName)} />
        </>
      ),
    },
    {
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.ListTab.table.supplier',
      ),
      dataIndex: 'product',
      key: 'table.supplier',
      width: 200,
      render: (product) => {
        if (!product.suppliers || !product.suppliers.length) {
          return '-';
        }
        return product.suppliers.map((supplier) => (
          <CopyToClipboard
            message={getTranslation.obj(supplier.name)}
            key={supplier.id}
            style={{ margin: 2 }}
          />
        ));
      },
    },
    {
      dataIndex: 'expiryDate',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.ListTab.table.expireDate',
      ),
      render: (date) =>
        date
          ? moment(new Date(date))
              .local()
              .format(getDateTimeFormatString())
          : '-',
    },
    {
      dataIndex: 'status',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.ListTab.table.status',
      ),
      render: (status) => getTranslation.blockedStockStatus(status),
    },
    {
      dataIndex: 'count',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.ListTab.table.quantity',
      ),
    },
    {
      dataIndex: 'reason.values',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.ListTab.table.reason',
      ),
    },
    {
      dataIndex: 'statusChangeLog[0].picker.name',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.ListTab.table.createdBy',
      ),
    },
    {
      dataIndex: 'conclusionType',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.ListTab.table.conclusionType',
      ),
      render: (code) => getTranslation.blockedStockConclusionType(code),
    },
    {
      dataIndex: 'createdAt',
      title: this.props.t(
        'pages.Stock.BlockedStock.list.tabs.ListTab.table.createdAt',
      ),
      render: (date) =>
        moment(new Date(date))
          .local()
          .format(getDateTimeFormatString()),
    },
  ];

  DetailButton = (props) => {
    const { CWId } = this.props;
    const { id } = props;
    return (
      <Link to={`/r/${CWId}/block-stock/detail/${id}`}>
        <Button size="small">
          {this.props.t(
            'pages.Stock.BlockedStock.list.tabs.ListTab.table.detail',
          )}
        </Button>
      </Link>
    );
  };

  getList = () => {
    const query = this.getQuery();
    const filter = this.getFilter();

    if (
      filter &&
      filter.createdAt &&
      filter.createdAt.startDate &&
      filter.createdAt.endDate
    ) {
      this.props.onGetListTab(query, filter);
    }
  };

  getQuery = () => this.state.query;

  getFilter = () => {
    const filter = this.state.filter || {};

    if (!filter.createdAt) {
      filter.createdAt = this.defaultDateRange;
    }

    filter.warehouses = [this.props.CWId];

    return filter;
  };

  setTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      }),
      this.getList,
    );
  };

  clearTableFilter = (filter) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        query: {
          ...this.state.query,
          offset: 0,
        },
        filter,
      }),
      this.getList,
    );
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.blockedStock.blockedStockList.loading,
  list: state.blockedStock.blockedStockList.data,
  totalCount: state.blockedStock.blockedStockList.total,
});

const mapDispatchToProps = (dispatch) => ({
  onGetListTab: (query, filter) => {
    dispatch(blockedStockAction.filterBlockedStock(query, filter));
  },
});

const ListTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(ListTab));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { ListTabConnected as ListTab };
