import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Table, Descriptions, Row, Col, Button } from 'antd';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
/*----------------------------------------------------------------------------*/
import './BlockedStockTransferDetail.scss';
import { WaybillTable } from './WaybillTable';
/*----------------------------------------------------------------------------*/
import {
  Spinner,
  CopyToClipboard,
  ActionTimeline,
} from './../../../../../components';
import { blockedStockAction } from './../../../../../actions';
import { getTranslation, getDateTimeFormatString } from './../../../../../shared';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class BlockedStockTransferDetail extends Component {
  settings = {
    main: {
      className: 'BlockedStockTransferDetail_main',
    },
    card: {
      title: this.props.t('pages.Operation.BlockedStock.transfer.detail.title'),
      className: 'no-padding-card',
      size: 'small',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    this.getDetail();
  }

  stateFactory = () => {
    return {};
  };

  render() {
    const { loading } = this.props;
    return (
      <div {...this.settings.main}>
        <Card {...this.settings.card} extra={<this.CardExtra />}>
          {loading ? <Spinner /> : <this.PageDetail />}
        </Card>
      </div>
    );
  }

  CardExtra = (props) => {
    return <this.SendToWMSButton />;
  };

  PageDetail = (props) => {
    const { detail } = this.props;
    if (!detail) {
      return null;
    }
    return (
      <div>
        <this.PageDescription />
        <Row>
          <Col span={12} style={{ padding: '5px' }}>
            <this.ProductTable />
          </Col>
          <Col span={12} style={{ padding: '5px' }}>
            <WaybillTable waybill={detail.waybill} />
          </Col>
        </Row>
        <ActionTimeline
          actions={detail}
          translate={getTranslation.blockedStockTransferStatus}
        />
      </div>
    );
  };

  getDetail = () => {
    const { id, onGetDetail } = this.props;
    onGetDetail(id);
  };

  PageDescription = (props) => {
    const { detail } = this.props;
    if (!detail) {
      return null;
    }

    return (
      <Descriptions column={2}>
        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.transfer.detail.description.id',
          )}
        >
          <CopyToClipboard message={detail.id} />
        </Descriptions.Item>

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.transfer.detail.description.no',
          )}
        >
          <CopyToClipboard message={detail.no} />
        </Descriptions.Item>

        {detail.sourceWarehouse ? (
          <Descriptions.Item
            label={this.props.t(
              'pages.Operation.BlockedStock.transfer.detail.description.sourceWarehouse',
            )}
          >
            <CopyToClipboard
              message={detail.sourceWarehouse.id}
              inner={detail.sourceWarehouse.name}
            />
          </Descriptions.Item>
        ) : null}

        {detail.destinationWarehouse ? (
          <Descriptions.Item
            label={this.props.t(
              'pages.Operation.BlockedStock.transfer.detail.description.destinationWarehouse',
            )}
          >
            <CopyToClipboard
              message={detail.destinationWarehouse.id}
              inner={detail.destinationWarehouse.name}
            />
          </Descriptions.Item>
        ) : null}

        <Descriptions.Item
          label={this.props.t(
            'pages.Operation.BlockedStock.transfer.detail.description.createdAt',
          )}
        >
          {moment(new Date(detail.createdAt))
            .local()
            .format(getDateTimeFormatString())}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  ProductTable = (props) => {
    return (
      <Table
        size="small"
        columns={this.prepareColumns()}
        dataSource={this.prepareData()}
        rowKey={(record) => {
          return record.product.id;
        }}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
    );
  };

  prepareColumns = (props) => {
    return [
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.detail.subTable.productId',
        ),
        dataIndex: 'product.id',
        key: 'productId',
        render: (productId) => <CopyToClipboard message={productId} />,
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.detail.subTable.fullName',
        ),
        dataIndex: 'product.fullName',
        key: 'fullName',
        render: (fullName) =>
          fullName && (
            <CopyToClipboard message={getTranslation.obj(fullName)} />
          ),
      },
      {
        title: this.props.t(
          'pages.Operation.BlockedStock.transfer.detail.subTable.count',
        ),
        dataIndex: 'count',
        key: 'count',
      },
    ];
  };

  prepareData = (props) => {
    const { detail } = this.props;
    return detail.products;
  };

  SendToWMSButton = () => {
    const { detail } = this.props;
    return (
      <Button
        disabled={!detail}
        size="small"
        block
        onClick={() => {
          this.sendToWMS();
        }}
      >
        {this.props.t(
          'pages.Operation.BlockedStock.transfer.detail.button.sendToWMS',
        )}
      </Button>
    );
  };

  sendToWMS = () => {
    const { detail, onSendToWMS } = this.props;
    onSendToWMS(detail.id);
  };
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse._id,
  loading: state.blockedStock.blockedStockTransferDetail.loading,
  detail: state.blockedStock.blockedStockTransferDetail.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetDetail: (id) => {
    return dispatch(blockedStockAction.getBlockedStockTransfer(id));
  },
  onSendToWMS: (transferId) => {
    dispatch(
      blockedStockAction.centralWarehouseIncomingBlockedStockTransfer(
        transferId,
      ),
    );
  },
});

const BlockedStockTransferDetailConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(BlockedStockTransferDetail));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { BlockedStockTransferDetailConnected as BlockedStockTransferDetail };
