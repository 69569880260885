import React, { Component } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { getDateFormatString } from './../../../../../shared';
/*----------------------------------------------------------------------------*/
const { RangePicker } = DatePicker;
/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export class DateRangeSelect extends Component {
  dateFormat = getDateFormatString();

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => {
    return {};
  };

  render() {
    return <this.DateRangeField />;
  }

  DateRangeField = () => {
    const { defaultDateRange, startDate, endDate } = this.props;
    return (
      <RangePicker
        defaultValue={[
          moment(defaultDateRange.startDate, this.dateFormat),
          moment(defaultDateRange.endDate, this.dateFormat),
        ]}
        value={[
          moment(startDate, this.dateFormat),
          moment(endDate, this.dateFormat),
        ]}
        onChange={this.onDateRangeChange}
        format={this.dateFormat}
      />
    );
  };

  onDateRangeChange = ([startDate, endDate]) => {
    let dateRange = this.props.defaultDateRange;
    if (!startDate || !endDate) {
      return this.props.onChange(dateRange);
    // eslint-disable-next-line no-else-return
    } else {
      dateRange = {
        startDate: new Date(startDate._d.setHours(0, 0, 0, 0)),
        endDate: new Date(endDate._d.setHours(23, 59, 59, 999)),
      };
    }
    return this.props.onChange(dateRange);
  };
}
