import { notification } from 'antd';
import { actionType } from './actionType';
import { http } from './../http';
import { ErrorInfo } from './../components';

export const warehouseLocationAction = {
  filterWarehouseLocations,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function filterWarehouseLocations(data) {
  return (dispatch) => {
    dispatch({
      type: actionType.filterWarehouseLocation_InProgress,
    });
    http
      .filterWarehouseLocation(data)
      .then((response) => {
        console.log(response);
        const locations = response.data.data;
        dispatch({
          type: actionType.filterWarehouseLocation_Succeeded,
          payload: locations,
        });
      })
      .catch((error) => {
        notification.error({
          duration: 4,
          message: ErrorInfo.message(actionType.filterWarehouseLocation_Failed),
          description: ErrorInfo.description(error),
        });
        dispatch({
          type: actionType.filterWarehouseLocation_Failed,
          payload: error,
        });
      });
  };
}
