import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form, Button, Row, Col,
} from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import {
  DateRangeSelect,
  ProductMultiSelect,
  SupplierSelect,
  SupplierAccountSelect,
} from './filterFields';
/*----------------------------------------------------------------------------*/
import { productAction, supplierAction } from './../../../../../../actions';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class RefundTabFilter extends Component {
  settings = {
    main: {
      className: 'RefundTabFilter_main',
    },
  };

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {
    const { onGetProductList, onGetSupplierList } = this.props;
    onGetProductList();
    onGetSupplierList();
  }

  stateFactory = () => ({
    ...this.emptyTableFilter(),
  });

  emptyTableFilter = () => ({
    startDate: this.props.defaultDateRange.startDate,
    endDate: this.props.defaultDateRange.endDate,
    products: [],
    supplier: null,
    account: null,
  });


  DateRangeField = () => (
    <Form.Item>
      <DateRangeSelect
        defaultDateRange={this.props.defaultDateRange}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        onChange={(dateRange) => {
          this.setState({
            ...this.state,
            ...dateRange,
          });
        }}
      />
    </Form.Item>
  );

  ProductField = () => {
    const { productList } = this.props;
    const { products } = this.state;
    const list = (productList && productList.data) || null;
    return (
      <Form.Item>
        <ProductMultiSelect
          list={list}
          onChange={(products) => {
            this.setState({
              products,
            });
          }}
          value={products}
          loading={productList.loading}
          placeholder={this.props.t('pages.Stock.BlockedStock.list.tabs.RefundTab.filter.product')}
        />
      </Form.Item>
    );
  };

  SupplierField = (props) => {
    const { supplierList } = this.props;
    return (
      <Form.Item>
        <SupplierSelect
          onChange={(supplier) => {
            this.setState({ ...this.state, supplier, account: null });
          }}
          value={this.state.supplier}
          placeholder={this.props.t('pages.Stock.BlockedStock.list.tabs.RefundTab.filter.supplier')}
          list={supplierList}
        />
      </Form.Item>
    );
  };

  SupplierAccountField = (props) => {
    const { supplier, account } = this.state;
    const accounts = (supplier && supplier.accounts) || null;
    return (
      <Form.Item>
        <SupplierAccountSelect
          onChange={(account) => {
            this.setState({ account });
          }}
          value={account}
          placeholder={this.props.t('pages.Stock.BlockedStock.list.tabs.RefundTab.filter.account')}
          list={accounts}
          disabled={!supplier || !supplier.accounts}
        />
      </Form.Item>
    );
  };

  FilterButton = () => (
    <Form.Item>
      <Button
        type="primary"
        block
        onClick={() => {
          const filter = this.getTableFilter();
          this.props.onChange(filter);
        }}
        disabled={!this.state.supplier &&
          !this.state.account}
      >
        {this.props.t('pages.Stock.BlockedStock.list.tabs.RefundTab.filter.filter')}
      </Button>
    </Form.Item>
  );

  ClearFilterButton = () => (
    <Form.Item>
      <Button
        block
        onClick={() => {
          this.clearFilter();
        }}
      >
        {this.props.t('pages.Stock.BlockedStock.list.tabs.RefundTab.filter.clean')}
      </Button>
    </Form.Item>
  );

  getTableFilter = () => {
    const {
      supplier, account, products, startDate, endDate,
    } = this.state;

    const filter = {};

    filter.createdAt = {
      startDate: startDate || this.props.defaultDateRange.startDate,
      endDate: endDate || this.props.defaultDateRange.endDate,
    };

    if (products && products.length) {
      filter.products = products.map((p) => p.id);
    }

    if (supplier) {
      filter.suppliers = [supplier._id];
    }

    if (account) {
      filter.account = account.code;
    }

    return filter;
  };

  clearFilter = () => {
    this.setState({ ...this.state, ...this.stateFactory() }, () => {
      this.props.onClear(this.getTableFilter());
    });
  };

  render() {
    return (
      <div {...this.settings.main}>
          <Form>
            <Row>
              <Col span={4} style={{ padding: '0px 5px' }}>
                <this.DateRangeField />
              </Col>
              <Col span={20} style={{ padding: '0px 5px' }}>
                <this.SupplierField />
                <this.SupplierAccountField />
              </Col>
            </Row>

            <Row>
              <Col span={24} style={{ padding: '0px 5px' }}>
                <this.ProductField />
              </Col>
            </Row>

            <Row>
              <Col span={2} style={{ padding: '0px 5px' }}>
                <this.ClearFilterButton />
              </Col>
              <Col offset={20} span={2} style={{ padding: '0px 5px' }}>
                <this.FilterButton />
              </Col>
            </Row>
          </Form>
      </div>
    );
  }
}

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  CWId: state.auth.currentWarehouse.id,
  currentWarehouse: state.auth.currentWarehouse,
  productList: state.product.productList,
  supplierList: state.supplier.list.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetProductList: () => {
    dispatch(
      productAction.filterProductList({
        fields: 'barcodes fullName picURL packagingInfo',
        includeDefaultFields: false,
      }),
    );
  },
  onGetSupplierList: () => {
    dispatch(supplierAction.getSupplierList());
  },
});

const RefundTabFilterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(RefundTabFilter));

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export { RefundTabFilterConnected as RefundTabFilter };
