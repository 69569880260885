import React, { Component } from 'react';
import { Select, Avatar, Spin } from 'antd';
import { uniqBy } from 'lodash';
/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/
import { getTranslation } from './../../../../../shared';
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

export class ProductMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  componentDidMount() {}

  stateFactory = () => ({
    filterInput: null,
  });

  render() {
    return <this.SelectField />;
  }

  SelectField = () => {
    const { disabled, loading, placeholder } = this.props;
    return (
      <Select
        mode="multiple"
        showSearch
        allowClear
        optionFilterProp="children"
        disabled={disabled}
        value={this.getValue()}
        filterOption={this.filterOption}
        placeholder={placeholder}
        onChange={this.onChange}
        onSearch={this.onSearch}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {this.getOptions()}
      </Select>
    );
  };

  getOptions = () => {
    const { list } = this.props;
    const { filterInput } = this.state;
    if (!list || !list.length) {
      return;
    }
    let options = uniqBy(list, `fullName.${getTranslation.local()}`).filter(
      (item) => item,
    );

    if (!filterInput) {
      options = options.slice(0, Math.min(50, options.length));
    } else {
      options = options.filter(
        (item) =>
          filterInput &&
          filterInput.length >= 3 &&
          item.fullName[getTranslation.local()]
            .toLowerCase()
            .includes(filterInput.toLowerCase()),
      );
    }

    return options.map((item) => (
      <Select.Option
        value={getTranslation.obj(item.fullName)}
        key={item.id}
        rawvalue={item}
      >
        <this.SelectOptionItem item={item} />
      </Select.Option>
    ));
  };

  SelectOptionItem = (props) => {
    const { item } = props;
    const settings = {
      label: getTranslation.obj(item.fullName),
      icon: {
        src: (item.picURL && getTranslation.obj(item.picURL)) || null,
        size: 20,
        className: 'product-icon',
      },
    };
    return (
      <span rawvalue={item}>
        <Avatar {...settings.icon} /> {settings.label}
      </span>
    );
  };

  getValue = () => {
    const { list, value } = this.props;
    if (!list || !list.length || !value || !value.length) {
      return [];
    }
    return value.map((item) => getTranslation.obj(item.fullName));
  };

  filterOption = (input, option) =>
    option.props.rawvalue.fullName[getTranslation.local()]
      .toLowerCase()
      .indexOf(input.toLowerCase()) >= 0;

  onChange = (value, options) => {
    let selectedItems = null;
    try {
      selectedItems = options.map((o) => o.props.rawvalue);
    } catch (error) {
      this.clearField();
    }
    setTimeout(() => {
      this.props.onChange(selectedItems);
    });
  };

  clearField = () => {
    this.setState(this.stateFactory());
  };

  onSearch = (value, option) => {
    this.setState({
      filterInput: value,
    });
  };
}
