import { QueryBuilder } from '../QueryBuilder';
import { createHttpService } from '../createHttpService';

const httpService = createHttpService();

export const inventoryCheck = {
  createInventoryCheck: (data) => httpService.post('/inventory-checks', data),
  getInventoryCheckTaskDetail: ({ inventoryCheckId, jobId, jobTaskId }) =>
    httpService.post(`/inventory-checks/${inventoryCheckId}/get-task`, {
      jobId,
      jobTaskId,
    }),
  getInventoryCheckDetail: (inventoryCheckId) =>
    httpService.get(`/inventory-checks/${inventoryCheckId}`),
  bulkIncludeInventoryChecksToSTS: (data) =>
    httpService.post('/inventory-checks/change-field', data),
  bulkRemoveInventoryChecksFromSTS: (data) =>
    httpService.post('/inventory-checks/change-field', data),
  bulkApproveInventoryCheck: (data) =>
    httpService.post('/inventory-checks/approve', data),
  deleteInventoryCheck: (inventoryCheckId) =>
    httpService.delete(`/inventory-checks/${inventoryCheckId}`),
  setInventoryCheckReady: (inventoryCheckId) =>
    httpService.post(`/inventory-checks/${inventoryCheckId}/approve`),
  includeToSTS: (data) =>
    httpService.post(`/inventory-checks/change-field`, data),
  removeFromSTS: (data) =>
    httpService.post(`/inventory-checks/change-field`, data),
  terminateInventoryCheck: (inventoryCheckId) =>
    httpService.post(`/inventory-checks/${inventoryCheckId}/terminate`),
  bulkTerminateInventoryCheck: (data) =>
    httpService.post('/inventory-checks/cancel', data),
  getInventoryCheckList: (data, query) =>
    httpService.post(
      `/inventory-checks/filter?${QueryBuilder.getQueryStr(query)}`,
      data,
    ),
  getWarehouseStatusControl: (warehouseId) => httpService.get(`/warehouse/${warehouseId}/control-operations`),
};
