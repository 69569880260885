import React, { Component } from 'react';
import { Timeline, Card, Icon } from 'antd';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/
import { CopyToClipboard } from './../../components';
import { getTranslation, getDateTimeFormatString, constants } from '../../shared';
import "./ActionTimeline.scss";
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class ActionTimeline extends Component {
  settings = {
    main: {
      className: 'ActionTimeline_main',
    },
    card: {
      title: this.props.t('components.ActionTimeline.timelineTitle'),
      size: 'small',
    },
  };

  render() {
    return (
      <Card
        bodyStyle={{
          paddingLeft: '10px',
          paddingTop: '10px',
        }}
        {...this.settings.card}
      >
        <this.Timeline />
      </Card>
    );
  }

  Timeline = () => {
    const { actions, translate } = this.props;

    if (!actions) {
      return null;
    }

    const statusChangeLog = actions.statusChangeLog.sort(
      (a, b) => new Date(b.occurredAt) - new Date(a.occurredAt),
    );

    return (
      <Timeline className="timeline">
        {statusChangeLog.map((log, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Timeline.Item key={index}>
            {log.supplierApiStatus ? (
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                }}
              >
                <Icon type="check-circle" theme="filled" />
                {'   '}
                {getTranslation.supplierApiStatus(log.supplierApiStatus)}
                <br />
              </p>
            ) : null}
            {log.status ? (
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                }}
              >
                <Icon type="check-circle" theme="filled" />
                {'   '}
                {translate(log.status)}
                <br />
              </p>
            ) : null}

            {log.action && log.action === constants.PALLET_ACTION_TYPES.PALLET_COMPLETED ? (
              <div>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                >
                  <Icon type="check-circle" theme="filled" />
                  {'   '}
                  {this.props.t('components.ActionTimeline.palletCompleted')}
                </p>
              </div>
            ) : null}
            {log.action && log.action === constants.PALLET_ACTION_TYPES.PALLET_ARRIVED ? (
              <div>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                >
                  <Icon type="check-circle" theme="filled" />
                  {'   '}
                  {this.props.t('components.ActionTimeline.palletArrived')}
                </p>
              </div>
            ) : null}
            {log.action && log.action === constants.PALLET_ACTION_TYPES.PALLET_SHIPPED ? (
              <div>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                >
                  <Icon type="check-circle" theme="filled" />
                  {'   '}
                  {this.props.t('components.ActionTimeline.palletShipped')}
                </p>
              </div>
            ) : null}
            {log.action && log.action === constants.PALLET_ACTION_TYPES.PALLET_CREATED ? (
              <div>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                >
                  <Icon type="check-circle" theme="filled" />
                  {'   '}
                  {this.props.t('components.ActionTimeline.palletCreated')}
                </p>
              </div>
            ) : null}
            {log.action && log.action === constants.PALLET_ACTION_TYPES.TRANSFER_DEPARTED ? (
              <div>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                >
                  <Icon type="check-circle" theme="filled" />
                  {'   '}
                  {this.props.t('components.ActionTimeline.transferDeparted')}
                </p>
              </div>
            ) : null}
            {log.action && log.action === constants.PALLET_ACTION_TYPES.PALLET_ACCEPTANCE_CANCELED ? (
              <div>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                >
                  <Icon type="check-circle" theme="filled" />
                  {'   '}
                  {this.props.t('components.ActionTimeline.palletCanceled')}
                </p>
              </div>
            ) : null}
            {log.errorMessage ? (
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                }}
              >
                <span style={{ color: 'red' }}>{
                  getTranslation.getChangeLogErrorCode(log.errorMessage)
                  || log.errorMessage
                }
                </span>
              </p>
            ) : null}

            {log.occurredAt ? (
              <p style={{ fontSize: '12px' }}>
                <Icon type="clock-circle" />
                {'   '}
                {moment(new Date(log.occurredAt))
                  .local()
                  .format(getDateTimeFormatString())}
              </p>
            ) : null}
            {log.picker ? (
              <div style={{ fontSize: '12px' }}>
                <div>
                  <Icon type="user" />{' '}
                  {(log.picker && log.picker.name) || log.user}
                </div>
                <div>
                  <CopyToClipboard
                    message={log.picker && log.picker.id}
                    style={{
                      fontSize: 10,
                      padding: 2,
                      lineHeight: '10px',
                      borderRadius: 5,
                      boxShadow: '1px 1px 1px grey',
                    }}
                  />
                </div>
              </div>
            ) : null}
            {log.plate ? (
              <div
                style={{
                  fontSize: '12px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Icon type="car" style={{ marginRight: '4px' }} />
                <span>{log.plate}</span>
              </div>
            ) : null}
            {log.pallets && log.pallets.length > 0 && (log.status === 400 || log.status === 500 || log.action) ? (
              <div style={{ fontSize: '12px' }}>
                <div style={{ fontWeight: '700', marginTop: '10px' }}>
                  {`${this.props.t('components.ActionTimeline.palletIds')}:`}
                </div>
                <div>
                  {' '}
                  {log.pallets.map((pallet, idx) => (
                    <p key={idx.toString()}>{pallet}</p>
                  ))}
                </div>
              </div>
            ) : null}
          </Timeline.Item>
        ))}
      </Timeline>
    );
  };
}

const ActionTimelineTranslated = withNamespaces('translation')(ActionTimeline);

export { ActionTimelineTranslated as ActionTimeline };
