import React, { Component } from 'react';
import { Select, notification } from 'antd';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*/
class TransferIdsSelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => {
    return {};
  };

  componentDidMount() {}

  render() {
    return <this.InputField />;
  }

  InputField = () => {
    const { placeholder } = this.props;
    return (
      <Select
        mode="tags"
        placeholder={placeholder}
        value={this.getValue()}
        allowClear
        onChange={this.onChange}
      />
    );
  };

  getValue = () => {
    const { value } = this.props;
    if (!value || !value.length) {
      return;
    }
    let transferIds = value
      .toString()
      .trim()
      .split(',')
      .map((transferId) => transferId.trim());

    if (!transferIds.length) {
      transferIds = [value.trim()];
    }

    return transferIds;
  };

  onChange = (value) => {
    let transferIds = value
      .toString()
      .trim()
      .split(',')
      .map((transfer) => transfer.trim());

    if (!transferIds.length) {
      transferIds = [value.trim()];
    }

    transferIds.forEach((transferId) => {
      if (transferId.length && transferId.length !== 24) {
        notification.error({
          duration: 4,
          message: this.props.t(
            'pages.Operation.Transfer.list.notification.invalidTransferId',
          ),
          description: transferId,
        });
      }
    });

    const validTransferIds = transferIds.filter(
      (transferId) => transferId.length && transferId.length === 24,
    );

    this.props.onChange(validTransferIds);
  };
}

const TransferIdsSelectTranslated = withNamespaces('translation')(TransferIdsSelect);

export { TransferIdsSelectTranslated as TransferIdsSelect };
