import { actionType } from './actionType';

export const timerAction = {
  timerTick,
};

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

function timerTick() {
  return (dispatch) => {
    dispatch({
      type: actionType.timerTick,
    });
  };
}
