import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { isEmpty, get } from 'lodash';
import { Row, Col } from 'antd';
import { Marker, Popup, Map, ZoomControl, LayersControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import './GoogleMap.scss';
import { config } from './../../../config';
import warehouseMarkerImage from './../../../assets/markers/warehouse.png';
import courierMarker from './../../../assets/markers/courier.png';
import orderMarker from './../../../assets/markers/client.png';
import {
  CourierBalloonContent,
  OrderBalloonContent,
} from './../../../components';
import '../../../components/BalloonContent/BalloonContent.scss';
import { getLangKey } from '../../../i18n';
import { createHashMap } from './../../../shared';
import { pageView } from '../../../services/segment';
import { pageLoadEvents } from '../../../services/events';

const warehouseIcon = new L.Icon({
  iconUrl: warehouseMarkerImage,
  iconRetinaUrl: warehouseMarkerImage,
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
});

const orderIcon = new L.Icon({
  iconUrl: orderMarker,
  iconRetinaUrl: orderMarker,
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(40, 40),
});

const courierIcon = new L.Icon({
  iconUrl: courierMarker,
  iconRetinaUrl: courierMarker,
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(40, 40),
});
class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFactory(props);
  }

  componentDidMount() {
    this.getCouriersMarkers(this.props.couriers, this.props.orders);
    this.getWarehouseMarker(this.props.warehouse);
    this.getOrdersMarkers(this.props.orders, this.props.couriers);
    pageView({ pageName: pageLoadEvents.HOME_MAP, props: { google: true } });

    this.updatePlacemarkInterval = setInterval(() => {
      this.getCouriersMarkers(this.props.couriers, this.props.orders);
      this.getWarehouseMarker(this.props.warehouse);
      this.getOrdersMarkers(this.props.orders, this.props.couriers);
    }, 4000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.orders !== prevProps.orders) {
      this.getOrdersMarkers(this.props.orders, this.props.couriers);
      this.getCouriersMarkers(this.props.couriers, this.props.orders);
    }
    if (this.props.couriers !== prevProps.couriers) {
      this.getCouriersMarkers(this.props.couriers, this.props.orders);
      this.getOrdersMarkers(this.props.orders, this.props.couriers);
    }
    if (this.props.warehouse !== prevProps.warehouse) {
      this.getWarehouseMarker(this.props.warehouse);
    }
  }

  componentWillUnmount() {
    clearInterval(this.updatePlacemarkInterval);
  }

  stateFactory = (props) => {
    if (!props.warehouse.location) return;
    const { coordinates } = props.warehouse.location;
    const newState = {
      config: {
        lang: getLangKey(),
        apikey: config.GOOGLE_API_KEY,
      },
      map: {
        center: [...coordinates].reverse(),
        zoom: 10,
      },
      couriersMarkers: null,
      warehouseMarker: null,
      ordersMarkers: null,
    };
    return newState;
  };

  getWarehouseMarker = (warehouse) => {
    if (!warehouse || !warehouse.location) {
      return null;
    }
    const warehouseLocation = [...warehouse.location.coordinates].reverse();
    const title = <div>{warehouse.name}</div>;

    const warehouseMarker = (
      <Marker
        position={warehouseLocation}
        id={warehouse._id}
        icon={warehouseIcon}
      >
        <Popup>{title}</Popup>
      </Marker>
    );
    this.setState({ warehouseMarker });
  };

  getCouriersMarkers = (couriers, orders) => {
    const couriersList = couriers.list.data;
    if (!couriersList) {
      return null;
    }

    const couriersMarkers = couriersList.map((courier) => {
      const ordersMap = createHashMap(orders.list.data || [], { field: 'id' });
      const courierOrder = courier.marketOrder
        ? ordersMap[courier.marketOrder]
        : {};
      const content = (
        <div>
          <div
            className={
              !isEmpty(courierOrder) && courierOrder
                ? 'large-header tooltip-header'
                : 'tooltip-header'
            }
          >
            {this.props.t('pages.Home.balloonContent.courierLabel')}
            {courier.marketOrder && (
              <>
                {'/'}
                {this.props.t('pages.Home.balloonContent.orderLabel')}
              </>
            )}
          </div>
          <Row>
            {isEmpty(courierOrder) ? (
              <Col span={24}>
                <CourierBalloonContent courier={courier} />
              </Col>
            ) : (
              <Row gutter={10}>
                <Col span={12}>
                  <CourierBalloonContent courier={courier} />
                </Col>

                <Col span={12}>
                  <OrderBalloonContent order={courierOrder} />
                </Col>
              </Row>
            )}
          </Row>
        </div>
      );
      const location = courier.location.coordinates.reverse();
      return (
        <Marker position={location} icon={courierIcon} id={courier.id}>
          <Popup>{content}</Popup>
        </Marker>
      );
    });
    this.setState({ couriersMarkers });
  };

  getOrdersMarkers = (orders, couriers) => {
    const couriersList = couriers && couriers.list.data;
    const orderList = orders.list.data;
    if (!orderList) {
      return null;
    }

    const ordersMarkers = orderList.map((order) => {
      const couriersMap = createHashMap(couriersList || []);
      const orderCourierId = get(order, ['courier', 'id']);
      const orderCourier = couriersMap[orderCourierId] || {};

      const content = (
        <>
          <div className="large-header tooltip-header">
            {this.props.t('pages.Home.balloonContent.courierLabel')}
            <>
              {'/'}
              {this.props.t('pages.Home.balloonContent.orderLabel')}
            </>
          </div>
          <Row gutter={10}>
            <Col span={12}>
              {!isEmpty(orderCourier) ? (
                <CourierBalloonContent courier={orderCourier} />
              ) : (
                <div>
                  <h4>
                    {this.props.t(
                      'pages.Home.balloonContent.courierNotAssigned',
                    )}
                  </h4>
                </div>
              )}
            </Col>
            <Col span={12}>
              <OrderBalloonContent order={order} />
            </Col>
          </Row>
        </>
      );
      const { coordinates: orderCoordinates } = order.deliveryAddress.location;

      return (
        <Marker
          position={orderCoordinates.reverse()}
          icon={orderIcon}
          id={order.id}
        >
          <Popup>{content}</Popup>
        </Marker>
      );
    });
    this.setState({ ordersMarkers });
  };

  render() {
    if (!this.props.warehouse.location) return null;
    return (
      <div>
        <Map
          center={this.state.map.center}
          zoom={this.state.map.zoom}
          style={{ height: `${window.innerHeight - 123}px`, width: '100%' }}
          zoomControl={false}
        >
          <ZoomControl
            zoomInTitle={this.props.t('pages.Home.zoomIn')}
            zoomOutTitle={this.props.t('pages.Home.zoomOut')}
          />
          <LayersControl position="topright">
            <LayersControl.BaseLayer
              checked
              name={this.props.t('pages.Home.layers.roadmap')}
            >
              <ReactLeafletGoogleLayer
                googleMapsLoaderConf={{
                  KEY: this.state.config.apikey,
                  LANGUAGE: this.state.config.lang,
                }}
                googleMapsAddLayers={[{ name: 'hybrid' }]}
                useGoogMapsLoader
                type="roadmap"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer
              name={this.props.t('pages.Home.layers.satellite')}
            >
              <ReactLeafletGoogleLayer
                googleMapsLoaderConf={{
                  KEY: this.state.config.apikey,
                  LANGUAGE: this.state.config.lang,
                }}
                googleMapsAddLayers={[{ name: 'hybrid' }]}
                useGoogMapsLoader
                type="satellite"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer
              name={this.props.t('pages.Home.layers.hybrid')}
            >
              <ReactLeafletGoogleLayer
                googleMapsLoaderConf={{
                  KEY: this.state.config.apikey,
                  LANGUAGE: this.state.config.lang,
                }}
                useGoogMapsLoader
                googleMapsAddLayers={[{ name: 'hybrid' }]}
                type="hybrid"
              />
            </LayersControl.BaseLayer>
          </LayersControl>

          {this.state.warehouseMarker}
          {this.state.couriersMarkers}
          {this.state.ordersMarkers}
        </Map>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
  orders: state.order,
  couriers: state.courier,
  warehouse: state.auth.currentWarehouse,
});

const GoogleMapConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(GoogleMap));

export { GoogleMapConnected as GoogleMap };
