import { createHttpService } from '../createHttpService';

const httpService = createHttpService();

export const user = {
  userLogin: (data) =>
    httpService.post('/user/login', data, { ignoreHeaders: true }),
  userLogout: () => httpService.post('/user/logout'),
  userGoogleLogin: (data) =>
    httpService.post('/user/login/google', data, { ignoreHeaders: true }),
  userOtpVerify: (data) =>
    httpService.post('/user/otp/verify', data, { ignoreHeaders: true }),
    userOtpResend: (data) =>
    httpService.post('/user/otp/resend', data, { ignoreHeaders: true }),
};
