import React, { Component } from 'react';
import {
  Button,
  Icon,
  Typography,
  Form,
  DatePicker,
  Upload,
  Input,
  Descriptions,
  List,
} from 'antd';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
/*----------------------------------------------------------------------------*/
import { getDateFormatString } from './../../../../../shared';
/*----------------------------------------------------------------------------*/

const { Text } = Typography;
/*----------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/

class WaybillUpload extends Component {
  dateFormat = getDateFormatString();

  constructor(props) {
    super(props);
    this.state = this.stateFactory();
  }

  stateFactory = () => ({});

  WaybillForm = Form.create({})((props) => {
    const requiredRule = {
      required: true,
      message: this.props.t('pages.Stock.OutgoingTransfer.create.UploadModal.required'),
    };
    return (
      <>
        <Form layout="inline">
          <Form.Item>
            {props.form.getFieldDecorator('serial', { rules: [requiredRule] })(
              <Input
                placeholder={this.props.t('pages.Stock.OutgoingTransfer.create.UploadModal.waybillForm.serial')}
                className="waybill-serial-input"
                autoComplete="off"
                disabled={this.props.disabled}
              />,
            )}
          </Form.Item>
          <Form.Item>
            {props.form.getFieldDecorator('order', { rules: [requiredRule] })(
              <Input
                placeholder={this.props.t('pages.Stock.OutgoingTransfer.create.UploadModal.waybillForm.order')}
                autoComplete="off"
                disabled={this.props.disabled}
              />,
            )}
          </Form.Item>
          <Form.Item>
            {props.form.getFieldDecorator('date', { rules: [requiredRule], initialValue: moment(Date.now()) })(
              <DatePicker disabled={this.props.disabled} format={this.dateFormat}/>,
            )}
          </Form.Item>
          <Form.Item>
            {props.form.getFieldDecorator('imageFile', {
              valuePropName: 'imageFile',
              getValueFromEvent: this.addWaybillImage,
              rules: [requiredRule],
            })(
              <Upload
                accept="image/*"
                beforeUpload={() => false}
                disabled={this.props.disabled}
                fileList={
                  props.form.getFieldValue('imageFile')
                    ? [props.form.getFieldValue('imageFile')]
                    : null
                }
              >
                <Button disabled={this.props.disabled}>
                  <Icon type="upload" />
                  {this.props.t('pages.Stock.OutgoingTransfer.create.UploadModal.waybillForm.selectWaybillImage')}
                </Button>
              </Upload>,
            )}
          </Form.Item>
          <Form.Item className="mr-0">
            <Button
              disabled={this.props.disabled}
              htmlType="submit"
              onClick={() => this.onSaveWaybill(props)}
            >
              {this.props.t('pages.Stock.OutgoingTransfer.create.UploadModal.waybillForm.add')}
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  });

  addWaybillImage = (event) => (event.fileList.length > 0
    ? event.fileList[event.fileList.length - 1]
    : null);

  onSaveWaybill = (props) => {
    props.form.validateFields((error, values) => {
      if (!error) {
        const { waybills } = this.props;
        const updatedWaybills = [
          ...waybills,
          {
            imageFile: values.imageFile,
            ss: `${values.serial.toUpperCase()}-${values.order}`,
            date: values.date.format(getDateFormatString()),
          },
        ];
        this.props.onChange(updatedWaybills);
        props.form.resetFields();
      }
    });
  };

  WaybillList = () => (
    <List
      size="small"
      dataSource={this.props.waybills}
      renderItem={(waybill, index) => (
        <List.Item>
          <Descriptions className="waybill-descriptions">
            <Descriptions.Item label={this.props.t('pages.Stock.OutgoingTransfer.create.UploadModal.serialOrder')}>
              {waybill.ss}
            </Descriptions.Item>
            <Descriptions.Item label={this.props.t('pages.Stock.OutgoingTransfer.create.UploadModal.date')}>
              {moment(waybill.date)
                .local()
                .format(getDateFormatString())}
            </Descriptions.Item>
          </Descriptions>
          <Button
            type="link"
            size="small"
            className="mr-0 ml-auto"
            onClick={() => this.handleRemoveWaybill(index)}
          >
            <Icon type="close" />
          </Button>
        </List.Item>
      )}
    />
  );

  handleRemoveWaybill = (index) => {
    const { waybills } = this.props;
    const waybillsWithoutRemoved = waybills;
    waybillsWithoutRemoved.splice(index, 1);
    this.props.onChange(waybillsWithoutRemoved);
  };

  render() {
    return (
      <div>
        <Text strong>{this.props.t('pages.Stock.OutgoingTransfer.create.UploadModal.waybills')}</Text>
        <this.WaybillForm />
        <this.WaybillList />
      </div>
    );
  }
}

const WaybillUploadTranslated = withNamespaces('translation')(WaybillUpload);
export { WaybillUploadTranslated as WaybillUpload };
/*------------------------------------------------------------------------------

------------------------------------------------------------------------------*/
