import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';

import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import GoogleLogin from 'react-google-login';
import { withNamespaces } from 'react-i18next';

// --------------------------------------------------
import './Login.scss';
// --------------------------------------------------
import { notification, Typography, Icon, Modal } from 'antd';
import { authAction } from './../../../actions';
import { actionType } from './../../../actions/actionType';

import { getLangKey } from '../../../i18n';
import { config } from '../../../config';
import { getErrorMessage } from '../../../shared';
import { OtpModalContent } from './OtpModalContent';
// --------------------------------------------------

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      validationError: '',
      recaptchaValidationError: '',
      recaptchaExpiredError: '',
      loginError: '',
      isOtpModalOpen: false,
    };
    this.recaptchaRef = React.createRef();
  }

  componentDidUpdate = () => {
    if (this.props.isOtpRequired && !this.state.isOtpModalOpen) {
      this.setState({ isOtpModalOpen: true });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      validationError: value ? '' : this.props.t('main.login_error'),
      loginError: '',
    });
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.login();
    }
  };

  isFormValid = (params) => {
    const { username, password, recaptchaValue } = params;
    const isUsernameValid = /^([\w-]+(?:\.[\w-]+)*)/i.test(username);
    const isPasswordValid = password.trim().length > 0;

    if (!recaptchaValue && !this.state.recaptchaExpiredError) {
      this.setState({
        recaptchaValidationError: this.props.t('main.recaptchaValidationError'),
      });
    }
    if (!isUsernameValid || !isPasswordValid) {
      this.setState({
        validationError: this.props.t('main.login_error'),
      });
    }
    return isUsernameValid && isPasswordValid && recaptchaValue;
  };

  handleRecaptchaChange = (value) => {
    console.log(value);
    if (!value) {
      this.setState({
        recaptchaValidationError: this.props.t('main.recaptchaValidationError'),
        recaptchaExpiredError: '',
        loginError: '',
      });
    } else {
      this.setState({
        recaptchaValidationError: '',
        recaptchaExpiredError: '',
        loginError: '',
      });
    }
  };

  handleRecaptchaExpired = () => {
    this.setState({
      recaptchaExpiredError: this.props.t('main.recaptchaErrorExpired'),
    });
  };

  handleRecaptchaErrored = () => {
    notification.error({
      description: this.props.t('common.tryAgain'),
      message: this.props.t('main.recaptchaVerificationFailed'),
    });
    this.recaptchaRef.current.reset();
  };

  login = () => {
    const { username, password } = this.state;
    const { isLoginPending } = this.props;
    const recaptchaValue = this.recaptchaRef.current.getValue();
    if (!isLoginPending) {
      if (
        this.isFormValid({
          username,
          password,
          recaptchaValue,
        })
      ) {
        this.props
          .onLogin({
            username,
            password,
            recaptchaValue,
          })
          .catch((error) => {
            const message = getErrorMessage(error);
            this.setState({ loginError: message });
            this.recaptchaRef.current.reset();
          });
      }
    }
  };

  googleLogin = (response) => {
    const { tokenId } = response;
    const { isLoginPending } = this.props;
    if (!isLoginPending) {
      this.props.onGoogleLogin({ tokenId });
    }
  };

  // handling google related errors
  handleGoogleVerificationFailure = (response) => {
    if (response.details) {
      notification.error({
        duration: 4,
        message: response.details,
      });
    }
  };

  render() {
    const {
      recaptchaValidationError,
      recaptchaExpiredError,
      validationError,
      loginError,
    } = this.state;
    const errMessage =
      validationError ||
      recaptchaValidationError ||
      recaptchaExpiredError ||
      loginError;
    const { t } = this.props;
    const { Text } = Typography;
    return (
      <div className="login-container">
        <Card style={{ padding: '0 100px' }}>
          <CardBody>
            <Row>
              <Col className="text-center">
                <img
                  src="/assets/img/getir_purple_logo.png"
                  alt=""
                  style={{ width: '160px' }}
                  className="pb-4"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="icon-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    autoComplete="username"
                    type="username"
                    name="username"
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    placeholder={t('main.username')}
                  />
                </InputGroup>
                <InputGroup className="mb-4">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="icon-lock" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    autoComplete="password"
                    type="password"
                    name="password"
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    placeholder={t('main.password')}
                  />
                </InputGroup>
                {errMessage ? (
                  <Row>
                    <Col className="text-center">
                      <p className="text-center text-danger">{errMessage}</p>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="recaptcha">
                  <ReCAPTCHA
                    sitekey={config.RECAPTCHA_SITEKEY}
                    onChange={this.handleRecaptchaChange}
                    ref={this.recaptchaRef}
                    onExpired={this.handleRecaptchaExpired}
                    hl={getLangKey()}
                    onErrored={this.handleRecaptchaErrored}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="separator">
                <Button
                  className="btn btn-purple btn-block width"
                  onClick={this.login}
                  disabled={this.props.isLoginPending}
                >
                  {t('main.login')}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="separator">
                <Typography className="separator-container">
                  {' '}
                  <div className="line-for-separator line-for-separator-left" />
                  <Text strong className="separator-text">
                    {this.props.t('main.or')}
                  </Text>{' '}
                  <div className="line-for-separator line-for-separator-right" />
                </Typography>

                <GoogleLogin
                  clientId={config.REACT_APP_GOOGLE_CLIENT_ID}
                  render={(renderProps) => (
                    <Button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      className="btn btn-purple btn-block google-login-button width"
                    >
                      <Icon type="google" className="google-icon" />
                      {this.props.t('main.loginWithGoogle')}
                    </Button>
                  )}
                  onSuccess={this.googleLogin}
                  onFailure={this.handleGoogleVerificationFailure}
                  cookiePolicy="single_host_origin"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Modal
          centered
          title={this.props.t('main.OtpModalContent.title')}
          zIndex={1000}
          visible={this.state.isOtpModalOpen}
          destroyOnClose
          footer={null}
          onCancel={() => {
            this.setState({
              isOtpModalOpen: false,
              recaptchaValidationError: '',
              recaptchaExpiredError: '',
            });
            this.props.onOtpReset();
            this.recaptchaRef.current.reset();
          }}
        >
          <OtpModalContent />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.auth.loginError,
  isLoginPending: state.auth.isLoginPending,
  isOtpRequired: state.auth.otp,
});

const mapDispatchToProps = (dispatch) => ({
  onLogin: ({ username, password, recaptchaValue }) =>
    dispatch(
      authAction.login({
        username,
        password,
        recaptchaValue,
      }),
    ),
  onGoogleLogin: ({ tokenId }) =>
    dispatch(
      authAction.googleLogin({
        tokenId,
      }),
    ),
  onOtpReset: () => dispatch({ type: actionType.login_Otp_Reset }),
});

const LoginConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translation')(Login));

export { LoginConnected as Login };
