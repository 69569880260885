import React, { Component } from 'react';
import { connect } from 'react-redux';
import { constants } from './../../shared';

const { ORDER_STATUS } = constants;

class VerifyingSound extends Component {
  constructor(props) {
    super(props);
    this.defaultSound = '/assets/sounds/ringtone.mp3';
    this.trackList = [];
    this.trackCount = 0;
    this.audio = null;
  }

  componentDidMount() {
    this.audio = document.querySelector('.VerifyingSound_inner');
    this.audio.loop = false;
    this.audio.preload = 'auto';
    this.audio.autoplay = false;
    this.audio.controls = false;
    this.audio.addEventListener('ended', this.changeTrack);
  }

  playSound = (track) => {
    this.audio.pause();
    this.audio.setAttribute('src', track);
    this.audio.load();
    this.audio
      .play()
      .then(() => {
        console.log('VerifyingSound');
      })
      .catch(() => {
        this.audio.pause();
        this.audio.setAttribute('src', this.defaultSound);
        this.audio.load();
        this.audio
          .play()
          .then(() => {
            console.log('VerifyingSound');
          })
          .catch((data) => {
            console.log(
              data,
              'check chrome://flags/#audio-worklet-realtime-thread \n',
              'it should be enabled \n',
              'info: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes \n',
            );
          });
      });
  };

  changeTrack = () => {
    this.trackCount++;
    const trackNum = this.trackCount % this.trackList.length;
    this.playSound(this.trackList[trackNum]);
  };

  soundProcessing = () => {
    const { orders } = this.props;
    if (!this.audio) {
      return null;
    }
    if (!orders || !orders.data || !orders.data.length) {
      this.audio.pause();
    } else {
      const ordersVerifying = orders.data.filter(
        (item) => item.status === ORDER_STATUS.VERIFYING,
      );
      if (ordersVerifying && ordersVerifying.length) {
        ordersVerifying.forEach((item) => {
          this.trackList.push(item.picker.alertTrack || this.defaultSound);
        });
        this.playSound(this.trackList[0]);
      } else {
        this.audio.pause();
        this.trackList = [];
      }
    }
  };

  render() {
    this.soundProcessing();
    return <audio className="VerifyingSound_inner" />;
  }
}

const mapStateToProps = (state) => ({
  orders: state.order.list,
});

const mapDispatchToProps = null;

const VerifyingSoundConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyingSound);

export { VerifyingSoundConnected as VerifyingSound };
